import promise from 'bluebird'
import {marketingInvoiceService} from "../_services";



export const  marketingInvoiceAction = {
    getInvoiceList
};


function getInvoiceList(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            marketingInvoiceService.getInvoiceList(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}



