import React, { useState,useEffect } from "react";
import { GrClose } from "react-icons/gr";
import { Link, Redirect,useLocation } from "react-router-dom";
import Select from 'react-select'

 const CheckoutService = (props) => {
  const [item,setItem]=useState({});
  const [staffError,setStaffError]=useState('');
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [servicePrice,setServicePrice]=useState(0);
  const [updatePrice,setUpdatePrice]=useState(0);
  const [priceError,setPriceError]=useState('');


  const handleClickService = (indexId) => {
    if(props?.selectedStaff?.value!==undefined){
      setStaffError('');
      const hairdresserId = props?.selectedStaff?.value;
      let currentPageUrl = props.propData.history.location.pathname;
      const stateObj = { pageLoadingStatus: 0, previousPageUrl:currentPageUrl};
      const url =  "/new-selectService/" + hairdresserId + "/" + indexId;
       props.propData.history.push({
        pathname: url,
        state: stateObj,
      });
    }else{
      setStaffError('Staff Member is required.');
    }
  };


  useEffect(() => {
    setItem(props?.item);
    console.log('check selected item',props?.item);
    setServicePrice(props?.item?.selectedService?.servicePrice);
    setSelectedStaff({label:props?.selectedStaff?.name,value:props?.selectedStaff?._id});
  }, [props]);


  const handleClient = (hairdresserId,indexId) => {
    if(hairdresserId && hairdresserId!==''&& indexId && indexId!==''){
      setStaffError('');
      let currentPageUrl = props.propData.history.location.pathname;
      const stateObj = { pageLoadingStatus: 0, previousPageUrl:currentPageUrl };
      const url =   `/select-client/${hairdresserId}/${indexId}`
       props.propData.history.push({
        pathname: url,
        state: stateObj,
      });
    }else{
      setStaffError('Staff Member is required.');
    }
  };

  const onChangeStaff = (e,id) =>{
    setStaffError('');
    // eslint-disable-next-line no-unused-expressions
    props?.onSelectStaff('service',e,id);
  }

  
  useEffect(()=>{
    var timerValue;
    let err = '',updatedPrice;
    setServicePrice(updatePrice);
    let testValidPrice = /^\d{0,8}(\.\d{1,2})?$/.test(updatePrice);
    if(updatePrice!==0){
      if(!isNaN(updatePrice) && updatePrice>=0.00 && testValidPrice){
        setPriceError('')
        err='';
      }else{
        setPriceError('Enter valid price.');
        err='Enter valid price.';
      }
    timerValue= setTimeout(()=>{
      props.changeItemPrice(item,'service',updatePrice,err);
    },2000);
    }
    return ()=>{
      clearTimeout(timerValue);
    }
   },[updatePrice]);


  return (
    <div className="row">
      <div className="col-lg-12">
      <div className="service_div bg-white rounded">
        <div className="row vertical-top">
          <div className="col-md-6 text-left mt-1 mb-3">
            <span className="service_1 ml-0">Service {item?.id}</span>
          </div>
          <div className="col-md-6 text-right mb-3">
         {(props?.isPaymentComplete===1)?null:<GrClose
              className="gr_close"
              onClick={()=>props.removeService('service',item?.id)}
            />}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6  ">
            <div className={item?.selectedStaff?.label===undefined?"input_group_u  _staff_member border_blue":'input_group_u  _staff_member'}>
            <span className="_field_label_vk">Staff Member</span>
            {props?.index===1&&props?.isPaymentComplete===0?<Select 
            options={props?.staffList} 
            value={item?.selectedStaff} 
            onChange={(e)=>onChangeStaff(e,item?.id)}/>:<div className="service_selected1 disabled_div">{props?.selectedStaff?.label!==undefined?props?.selectedStaff?.label:'Select staff member'}</div>}
            </div>
            {staffError!==''?<span className={'text-danger'}>{staffError}</span>:null}
          </div>
          <div className="col-md-6 pl-0">
          <div className={props?.selectedStaff?.label==undefined||props?.isPaymentComplete===1?'input_group_u __Client hide__arrow':'input_group_u __Client'}>
            <span className="_field_label_vk">Client</span>
            {(props?.index===1&&props?.isPaymentComplete===0)?
            <div className="service_selected1" onClick={()=>handleClient(props?.selectedStaff?.value,item?.id)}>
              {props?.selectedClient?.name!=undefined?props?.selectedClient?.name:'Select client'}</div>:<div className="service_selected1 disabled_div">{props?.selectedClient?.name!==undefined?props?.selectedClient?.name:'Select client'}</div>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 pr-0">
          <div className={'input_group_u'}>
          <span className="_field_label_vk">Price</span>
            <div className={`currency_div mt-0 ml-0 ${item?.selectedService?.serviceName?'currency_div_edit':''}`}>
              <div className="text-center px-3 currency_sml">£</div>
              {(item?.selectedService?.serviceName&&props?.isPaymentComplete!==1)?<input type="text" className="text-center pl-3 currency_sml1 currency_sml_input edit_price_input" placeholder={'00.00'}  onChange={(e)=>setUpdatePrice(e.target.value)} value={isNaN(servicePrice)?'':servicePrice!==undefined?Number.isInteger(servicePrice)?servicePrice+'.00':servicePrice:`0.00`} />:<input type="text" className="text-center pl-3 currency_sml1 currency_sml_input edit_price_input" value={servicePrice!==undefined?Number.isInteger(servicePrice)?servicePrice+'.00':servicePrice:`0.00`} disabled={true}/>
              }
               </div>
               {priceError!=''?<span className="text-danger">{priceError}</span>:null}
            </div>
          </div>
          <div className="col-md-9 ">
          {props?.isPaymentComplete===1?<div className={`input_group_u __service  ${props?.isPaymentComplete===1?'hide__arrow':''}`}>
          <span className="_field_label_vk">Service</span>
           <div
              className="mb-4 bg-white service_selected1 border-1 disabled_div"
            >
              {item?.selectedService?.serviceName!==undefined?item?.selectedService?.serviceName:'Select service...'}
            </div>
            </div>:<div className={`input_group_u __service  ${props?.selectedStaff?.label == undefined?'hide__arrow':''}`}>
              {console.log('check selected staff',props?.selectedStaff?.label===undefined)}
          <span className="_field_label_vk">Service</span>
           <div
              className={`mb-4 bg-white service_selected1 border-1 w-100 ${props?.selectedStaff?.label===undefined ? 'disabled_div':item?.selectedService?.serviceName !== undefined ? '':'border_blue' } `}
              onClick={(e)=>handleClickService(item?.id)}
            >
              {item?.selectedService?.serviceName!==undefined?item?.selectedService?.serviceName:'Select service...'}
            </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default CheckoutService;