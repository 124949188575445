import React, { Component } from "react";
import {Plus1 , Tick} from '../SvgIcon'
import siteSetting from "../../config/env";
import {paymentActions} from "../../_actions";
import { loadStripe } from '@stripe/stripe-js';
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import {Tab, Tabs} from 'react-bootstrap';
const stripePromise = loadStripe(siteSetting.stripeKey);

class Plan extends Component {
    constructor(props){
        super(props);
        this.state = {
            priceId:siteSetting.stripe.salonMonthlyPriceId,
            price:siteSetting.stripe.salonMonthlyPrice,
            selectedPlan:1, //1-salon,2-self emp
            togglePlan:false,
            plandetail:planDetailMonth
        }
        localStorage.setItem("userType",1)
    };



    goSignup=()=>{
        this.props.history.push('/signup');
    }
    goPayment=async (priceId,selectedPlan)=>{
        const stripe = await stripePromise;
        let self=this;
        let subscriptionData = {
            priceId: priceId,
            userType:selectedPlan
        }
        self.props.dispatch(paymentActions.subscriptionCheckout(subscriptionData))
            .then(function (response) {
                console.log("response",response);
                if (response.data.statusCode == 0) {
                    if (response.data.error.errorCode === 10) {
                        self.setState({
                            error: response.data.error.responseMessage
                        })
                    } else {
                        self.setState({
                            error: response.data.error.responseMessage
                        })
                    }
                    self.setState({
                        isFormLoader: false
                    });
                } else {
                    self.setState({
                        isFormLoader: false
                    });
                    let sessionId=response.data.responseData.result.id;
                    stripe.redirectToCheckout({
                        sessionId: sessionId,
                    }).then(function (res){
                        console.log("stripe res=",res);
                    }).catch(function (error){
                        console.log("stripe error=",error);
                    })
                }
            })
    }
    planChange=()=>{
        let self=this;
        this.setState({
            togglePlan:!this.state.togglePlan
        },function (){
            if(this.state.togglePlan===false){
                self.setState({
                    plandetail:planDetailMonth,
                })
                localStorage.setItem("userType",2)
            }else{
                self.setState({
                    plandetail:planDetailYear,
                })
                localStorage.setItem("userType",1)
            }
        })
    }
    render() {
        return (
            <div className="container-fluid pad-0 position-relative plan_parent py-3 py-md-5" id="homePlans-wrap">
                <div>
                    <img src={require('../../assets/images/web-img/bigdot.png')} className="img-fluid dot big_dot hide-mobile mb-3" />
                </div>
                <h4 className="heading text-center ">Plans</h4>
                <p className="text-center mt-5">Whether you own the premise, or hire a chair we have a solution for you.</p>
                <div className="mt-4">
                    <p  className="text-center toggle">
                        <span className={this.state.togglePlan===false?"mr-3 selected":"mr-3"}>Monthly</span>
                        <label className="switch">
                            <input type="checkbox" onChange={this.planChange} checked={this.state.togglePlan}/>
                            <span className="slider round"></span>
                        </label>
                        <span className={this.state.togglePlan===true?"ml-3 selected":"ml-3"}>Yearly</span>
                    </p>
                </div>
            <div className="container mt-5">
                <div className="plan_container_parent">
                    {
                        this.state.plandetail.map((item,i)=>{
                            return(
                                <>
                                    <div key={i} className="plan_container hide-mobile">
                                        <div className="header">
                                            <div><h3>{item.packName}</h3></div>
                                            <div><h3 className="mb-0">{item.cost}</h3><p className="mb-0">{item.costSubtitle}</p></div>
                                        </div>
                                        <div className="body">
                                            <h4>{item.bodyHeading}</h4>
                                            <h5 className="mb-5">{item.listHeading}</h5>
                                            <p><span>{item.listicon}</span>{item.listItem1}</p>
                                            <p><span>{item.listicon}</span>{item.listItem2}</p>
                                            <p><span>{item.listicon}</span>{item.listItem3}</p>
                                            <p><span>{item.listicon}</span>{item.listItem4}</p>
                                            <p><span>{item.listicon}</span>{item.listItem5}</p>
                                            <p><span>{item.listicon}</span>{item.listItem6}</p>
                                            <p><span>{item.listicon}</span>{item.listItem7}</p>
                                            <p><span>{item.listicon}</span>{item.listItem8}</p>
                                            <p><span>{item.listicon}</span>{item.listItem9}</p>
                                            <div className="text-center">
                                            <button className="btn btn-blue mt-3" onClick={()=>this.goPayment(item.priceId,item.selectedPlan)}>{"Sign Up"}</button>
                                            </div>

                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                    {
                        <div className="hide-desktop">
                            <Tabs defaultActiveKey="Salon" className=" plan_tab_parent">
                                {this.state.plandetail.map((item, i) => {
                                    return (
                                        <Tab eventKey={item.eventKey} title={item.title}>
                                            <div className="plan_container ">
                                                <div className="header">
                                                    <div><h3>{item.packName}</h3></div>
                                                    <div><h3
                                                        className="mb-0">{item.cost}</h3>
                                                        <p className="mb-0">{item.costSubtitle}</p></div>
                                                </div>
                                                <div className="body">
                                                    <h4>{item.bodyHeading}</h4>
                                                    <h5 className="mb-5 ">{item.listHeading}</h5>
                                                    <p><span>{item.listicon}</span>{item.listItem1}</p>
                                                    <p><span>{item.listicon}</span>{item.listItem2}</p>
                                                    <p><span>{item.listicon}</span>{item.listItem3}</p>
                                                    <p><span>{item.listicon}</span>{item.listItem4}</p>
                                                    <p><span>{item.listicon}</span>{item.listItem5}</p>
                                                    <p><span>{item.listicon}</span>{item.listItem6}</p>
                                                    <p><span>{item.listicon}</span>{item.listItem7}</p>
                                                    <p><span>{item.listicon}</span>{item.listItem8}</p>
                                                    <p><span>{item.listicon}</span>{item.listItem9}</p>
                                                    <br/>
                                                    <div className="text-center">
                                                        <button className="btn btn-blue mt-3"onClick={()=>this.goPayment(item.priceId,item.selectedPlan)} >Sign Up</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </Tab>

                                    )
                                })
                                }
                            </Tabs>
                            <br/>
                        </div>
                    }
                </div>
               <div className="why-bue-wrap py-4 py-md-5">
                <h4 className="heading text-center ">Why Beu?</h4>
                    <div className="plan_logo_parent">
                        <div>
                            <div className="logo_card">
                                <div className="img"><img src={require('../../assets/images/web-img/plan1.png')} className="img-fluid  " /></div>
                                <div className="text"><h3>Beu Pro</h3><p>£{this.state.price} a month (with unlimited staff)</p></div>
                            </div>
                        </div>
                        <div>
                            <p className="vs"><span>VS</span></p>
                        </div>
                        <div>
                            <div className="logo_card">
                                <div className="img"><img src={require('../../assets/images/web-img/plan2.png')} className="img-fluid  " /></div>
                                <div className="text"><h3>Booksy</h3><p>£130 a month (with unlimited staff)</p></div>
                            </div>
                            <div className="logo_card">
                                <div className="img"><img src={require('../../assets/images/web-img/plan3.png')} className="img-fluid  " /></div>
                                <div className="text"><h3>Squire</h3><p>£120 a month</p></div>
                            </div>
                            <div className="logo_card">
                                <div className="img"><img src={require('../../assets/images/web-img/plan4.png')} className="img-fluid  " /></div>
                                <div className="text"><h3>Treatwell</h3><p>£39 a month + 35% for each new booking</p></div>
                            </div>
                        </div>

                    </div>
               </div>

            </div>
            </div>
        )
    }
}
const planDetailMonth = [
    // {
    //     packName: 'Lite',
    //     cost: 'Free',
    //     costSubtitle: 'with restrictions',
    //     bodyHeading:'Best for those just starting out, or moving from pen and paper ',
    //     listHeading:'Plan Includes:',
    //     listicon: <Tick/>,
    //     listItem1:'Diary with appointment management',
    //     listItem2:'2 staff accounts with individual logins',
    //     listItem3:'Client management and notes',
    //     listItem4:'Featured on Beu Marketplace',
    //
    // },
    {
        packName: 'Salon Pro',
        cost: '£'+siteSetting.stripe.salonMonthlyPrice,
        priceId:siteSetting.stripe.salonMonthlyPriceId,
        selectedPlan:1,
        eventKey:"Salon",
        title:"Salon/ Barbershop",
        costSubtitle: '/month',
        bodyHeading:'All the features you need for a successful business',
        listHeading:'Plan Includes:',
        listicon: <Plus1/>,
        listItem1:'Unlimited staff accounts with individual logins',
        listItem2:'Diary with appointment management',
        listItem3:'Client management and notes',
        listItem4:'Featured on Beu Marketplace',
        listItem5:'Online bookings through website and socials',
        listItem6:'iOS and Android apps for you and your clients',
        listItem7:'No-show protection',
        listItem8:'Unlimited text & emails reminders',
        listItem9:'Fully Integrated POS',
    },
    {
        packName: 'Self Pro',
        cost: '£'+siteSetting.stripe.selfMonthlyPrice,
        priceId:siteSetting.stripe.selfMonthlyPriceId,
        selectedPlan:2,
        eventKey:"SelfEmp",
        title:"Self Employed",
        costSubtitle: '/month',
        bodyHeading:'All the features you need to go solo successfully',
        listHeading:'Plan Includes:',
        listicon: <Plus1/>,
        listItem1:'Single user account access ',
        listItem2:'Diary with appointment management',
        listItem3:'Client management and notes',
        listItem4:'Featured on Beu Marketplace',
        listItem5:'Online bookings through website and socials',
        listItem6:'iOS and Android apps for you and your clients',
        listItem7:'No-show protection',
        listItem8:'Unlimited text & emails reminders',
        listItem9:'Fully Integrated POS',
    }
]

const planDetailYear = [
    {
        packName: 'Salon Pro',
        cost: '£'+siteSetting.stripe.salonYearlyPrice,
        priceId:siteSetting.stripe.salonYearlyPriceId,
        selectedPlan:1,
        eventKey:"Salon",
        title:"Salon/ Barbershop",
        costSubtitle: '/year',
        bodyHeading:'All the features you need for a successful business',
        listHeading:'Plan Includes:',
        listicon: <Plus1/>,
        listItem1:'Unlimited staff accounts with individual logins',
        listItem2:'Diary with appointment management',
        listItem3:'Client management and notes',
        listItem4:'Featured on Beu Marketplace',
        listItem5:'Online bookings through website and socials',
        listItem6:'iOS and Android apps for you and your clients',
        listItem7:'No-show protection',
        listItem8:'Unlimited text & emails reminders',
        listItem9:'Fully Integrated POS',
    },
    {
        packName: 'Self Pro',
        cost: '£'+siteSetting.stripe.selfYearlyPrice,
        priceId:siteSetting.stripe.selfYearlyPriceId,
        selectedPlan:2,
        eventKey:"SelfEmp",
        title:"Self Employed",
        costSubtitle: '/year',
        bodyHeading:'All the features you need for a successful business',
        listHeading:'Plan Includes:',
        listicon: <Plus1/>,
        listItem1:'Single user account access ',
        listItem2:'Diary with appointment management',
        listItem3:'Client management and notes',
        listItem4:'Featured on Beu Marketplace',
        listItem5:'Online bookings through website and socials',
        listItem6:'iOS and Android apps for you and your clients',
        listItem7:'No-show protection',
        listItem8:'Unlimited text & emails reminders',
        listItem9:'Fully Integrated POS',
    }
]

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Plan);
