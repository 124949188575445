import { hairdresserConstants } from '../_constants';


const initialState = {};

export function hairdresserServices(state = initialState, action) {
  //console.log('action.type',action);
  switch (action.type) {

    case hairdresserConstants.HAIRDRESSER_SERVICE_SUCCESS:
      let getService = action.getHairdresserServiceSuccess;
      return getService;

    case hairdresserConstants.HAIRDRESSER_SERVICE_ERROR:
      return {};

    case hairdresserConstants.HAIRDRESSER_SERVICE_CLEAR:
      return {};

    default:
      return state
  }
}



export function selectedService(state = initialState, action) {
  switch (action.type) {
    case hairdresserConstants.SELECTED_SERVICE:
      let selectedServiceData = action.data;
      return selectedServiceData;
    default:
      return state
  }
}


export function salonOwnerDataInfo(state = initialState, action) {
  switch (action.type) {
    case hairdresserConstants.SALON_OWNER_INFO:
      console.log('check data 66',action.data.data)
      let ownerInfo = action.data;
      return ownerInfo;
    default:
      return state
  }
}