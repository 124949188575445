import { bookingConstants } from '../_constants';

const newCreateBookingStaff = {
};

export function hairdresserNewBookingStaff(state = newCreateBookingStaff, action) {
  // console.log('action.type',action);
  switch (action.type) {

    case bookingConstants.HAIRDRESSER_NEWBOOKING_SUCCESS:
      let bookingInfo = action.data;
      return bookingInfo;

    case bookingConstants.HAIRDRESSER_NEWBOOKING_REQUEST:
      return {};

    case bookingConstants.HAIRDRESSER_NEWBOOKING_ERROR:
      return {};

    default:
      return state
  }

}

const newCreateBookingService = [{}];

export function hairdresserNewBookingService(state = newCreateBookingService, action) {
  //console.log('action.type',action);
  switch (action.type) {

    case bookingConstants.HAIRDRESSER_NEW_BOOKING_SERVICE:
      let bookingInfo = action.data;
      return bookingInfo;

    case bookingConstants.HAIRDRESSER_NEWBOOKING_REQUEST:
      return [];

    case bookingConstants.HAIRDRESSER_NEWBOOKING_ERROR:
      return [];

    default:
      return state
  }
}

const newCreateBookingClient = {};

export function hairdresserNewBookingClient(state = newCreateBookingClient, action) {
  //console.log('action.type',action);
  switch (action.type) {

    case bookingConstants.HAIRDRESSER_NEW_BOOKING_CLIENT:
      let bookingInfo = action.data;
      return bookingInfo;

    case bookingConstants.HAIRDRESSER_NEWBOOKING_REQUEST:
      return {};

    case bookingConstants.HAIRDRESSER_NEWBOOKING_ERROR:
      return {};

    default:
      return state
  }
}

const newBookingData = [];

export function hairdresserNewBookingData(state = newBookingData, action) {
  //console.log('action.type',action);
  switch (action.type) {

    case bookingConstants.HAIRDRESSER_NEW_BOOKING_DATA:
      let bookingInfo = action.data;
      return bookingInfo;

    case bookingConstants.HAIRDRESSER_NEWBOOKING_REQUEST:
      return {};

    case bookingConstants.HAIRDRESSER_NEWBOOKING_ERROR:
      return {};

    default:
      return state
  }
}


const initialSelectDate = {};

export function selectHairdresserBookingDate(state = initialSelectDate, action) {

  switch (action.type) {
    case bookingConstants.SELECT_HAIRDRESSER_BOOKING_DATE:
      return action.data;

    default:
      return state
  }
}

const initialSelectTime = {};

export function selectHairdresserBookingTime(state = initialSelectTime, action) {

  switch (action.type) {
    case bookingConstants.SELECT_HAIRDRESSER_BOOKING_TIME:
      return action.data;

    default:
      return state
  }
}


const BOOKING_SLOTS = {};

export function clientBookingSlots(state = BOOKING_SLOTS, action) {

  switch (action.type) {
    case bookingConstants.SELECT_CLIENT_BOOKING_SLOTS:
      return action.data;

    default:
      return state
  }
}

const BOOKING_INFO = {};

export function updateBookingInfo(state = BOOKING_INFO, action) {

  switch (action.type) {
    case bookingConstants.UPDATE_BOOKING_INFO:
      return action.data;

    default:
      return state
  }
}


