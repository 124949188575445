import promise from 'bluebird'
import moment from 'moment';
// import {hairdresserConstants, alertConstants, bookingConstants, userConstants} from '../_constants';

import {bookingService, paymentService} from "../_services";
import { clientpaymentConstants } from "../_constants";

import { ResponseFilter } from "../config/response-handler";

export const paymentActions = {
    activationCode,
    subscriptionCheckout,
    subscriptionRetrieve,
    subscription,
    requestDemo,
    bookingPayment,
    bookingPaymentDane,
    paymentList,
    bankLink,
    getBank,
    deleteBankAccount,
    payoutList,
    payout,
    instantPayout,
    addTerminal,
    requestCardReader,
    fetchConnectionToken,
    cardReaderList,
    cardReaderDelete,
    paymentIntents,
    paymentIntentsCapture,
    subscriptionGet,
    updateTransactionFee,
    createLoginLink,
    makeCustomerPrimaryCard,
    setUpIntent
};

/************************************** Payment Action ******************************************************/

function activationCode(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            paymentService.activationCode(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function subscriptionCheckout(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {

            paymentService.subscriptionCheckout(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}
function subscriptionRetrieve(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {

            paymentService.subscriptionRetrieve(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}
function subscription(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            paymentService.subscription(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function requestDemo(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {

            //dispatch(request(params));
            paymentService.requestDemo(params)
                .then(response => {
                    resolve(response);

                    //let { serverResponseData } = ResponseFilter(response);
                    //dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    //dispatch(failure(err));
                })
        })
    };
    // function request(getHairdresserStaffRequest) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_REQUEST, getHairdresserStaffRequest } }
    // function success(getHairdresserStaffSuccess) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_SUCCESS, getHairdresserStaffSuccess } }
    // function failure(getHairdresserStaffFail) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_ERROR, getHairdresserStaffFail } }
}

function bookingPayment(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            paymentService.bookingPayment(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function bookingPaymentDane(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            paymentService.bookingPaymentDane(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}


function paymentList(params) {

    return function (dispatch) {
        return new promise(function (resolve, reject) {
            let apiParams = {};
            let urlQueryParamsData = new URLSearchParams(window.location.search);
            let search = urlQueryParamsData.get('search');
            let activePage = urlQueryParamsData.get('activePage');
            let limit = urlQueryParamsData.get('limit');
            let count = urlQueryParamsData.get('count');
            let hairdresserId = urlQueryParamsData.get('hairdresserId');
            let sortField = urlQueryParamsData.get('sortField');
            let sortType = urlQueryParamsData.get('sortType');
            let isDownload = urlQueryParamsData.get('isDownload');
            let startDate = urlQueryParamsData.get('startDate');
            let endDate = urlQueryParamsData.get('endDate');
            let paymentType = urlQueryParamsData.get('paymentType');
            let selectedBookingStatus = urlQueryParamsData.get('selectedBookingStatus');

            selectedBookingStatus  = selectedBookingStatus?.split(',')?.map((item)=>Number(item));
       
            if (params.search !== '' && params.search !== "undefined" || search!==null) {
                apiParams.search = search!==null?search:params.search;
                urlQueryParamsData.set('search', apiParams.search);
            }
            if (params.activePage !== '' || activePage!==null) {
                apiParams.pageNo = activePage!==null?+activePage:params.activePage
                urlQueryParamsData.set('activePage', apiParams.pageNo);
            }

            if (params.limit !== '' || limit!==null) {
                apiParams.limit = limit!==null?+limit:params.limit
                urlQueryParamsData.set('limit', apiParams.limit);
            }

            if (params.itemsCountPerPage !== '' || count!==null) {
                apiParams.count = count!==null?+count:params.itemsCountPerPage
                urlQueryParamsData.set('count', apiParams.count);
            }

            if (params.hairdresserId !== '' || hairdresserId!==null) {
                apiParams.hairdresserId = hairdresserId!==null?hairdresserId:params.hairdresserId;
                urlQueryParamsData.set('hairdresserId', apiParams.hairdresserId);
            }

            if (params.sortField !== '' && params.sortField !== 'undefined' && params.sortField !== undefined || sortField!==null) {
                apiParams.sortField =  sortField!==null?sortField:params.sortField;
                urlQueryParamsData.set('sortField', apiParams.sortField);
            }

            if (params.sortType !== '' || sortType!==null) {
                apiParams.sortType = sortType!==null?sortType:params.sortType;
                urlQueryParamsData.set('sortType', apiParams.sortType);
            }

            if(params.isDownload !== '' || isDownload!==null){
                apiParams.isDownload = isDownload!==null?+isDownload:params.isDownload;
                urlQueryParamsData.set('isDownload', apiParams.isDownload);
            }

            if(params.startDate !== ''&&params.startDate !==null || startDate!==null){
                let ___startDate = moment(params.startDate).format('YYYY-MM-DD');
                apiParams.startDate = startDate!==null?startDate:___startDate;
                urlQueryParamsData.set('startDate', apiParams.startDate);
            }

            if(params.endDate !== ''&&params.endDate !==null || endDate!==null){
                let ___endDate = moment(params.endDate).format('YYYY-MM-DD');
                apiParams.endDate = endDate!==null?endDate:___endDate;
                urlQueryParamsData.set('endDate', apiParams.endDate);
            }

            if(params.selectedValue.length !== 0 || paymentType!==null){
                let selectedValue = params.selectedValue.toString();
                apiParams.paymentType =  (paymentType!==null && paymentType!== undefined)?paymentType:selectedValue
                // paymentType!==null?paymentType:selectedValue;
                console.log('check datarrrrrrrrr',apiParams.paymentType);
                urlQueryParamsData.set('paymentType', apiParams.paymentType);
            }

            if(params.selectedBookingStatus.length !== 0 || selectedBookingStatus!==null){
                 apiParams.bookingStatus = (selectedBookingStatus!==null&&selectedBookingStatus!==undefined) ?selectedBookingStatus:params.selectedBookingStatus;
                 urlQueryParamsData.set('selectedBookingStatus', apiParams.bookingStatus.join(','));
            }

// need to work on booking status
            

            window.history.replaceState({}, '',`${window.location.pathname}?${urlQueryParamsData.toString()}`);
            console.log('check new data___',urlQueryParamsData.toString());
            //console.log(apiParams,'hiiiiiiiii');
            dispatch(request());
            paymentService.paymentList(apiParams)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    //console.log("salonProfile serverResponseData", serverResponseData);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(paymentListRequest) { return { type: clientpaymentConstants.CLIENT_PYAMENT_LIST_REQUEST, paymentListRequest } }
    function success(paymentListSuccess) { return { type: clientpaymentConstants.CLIENT_PYAMENT_LIST_SUCCESS, paymentListSuccess } }
    function failure(paymentListFail) { return { type: clientpaymentConstants.CLIENT_PYAMENT_LIST_ERROR, paymentListFail } }
}

function bankLink(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            paymentService.bankLink(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function getBank(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            paymentService.getBank(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    //console.log("salonProfile serverResponseData", serverResponseData);
                    dispatch(success(serverResponseData.responseData.result));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(Request) { return { type: clientpaymentConstants.GET_BANK_REQUEST, Request } }
    function success(Success) { return { type: clientpaymentConstants.GET_BANK_SUCCESS, Success } }
    function failure(Error) { return { type: clientpaymentConstants.GET_BANK_ERROR, Error } }
}

function deleteBankAccount(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            paymentService.deleteBankAccount(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function payoutList(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            paymentService.payoutList(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    //console.log("salonProfile serverResponseData", serverResponseData);
                    dispatch(success(serverResponseData.responseData.result.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(Request) { return { type: clientpaymentConstants.PAYOUT_LIST_REQUEST, Request } }
    function success(Success) { return { type: clientpaymentConstants.PAYOUT_LIST_SUCCESS, Success } }
    function failure(Error) { return { type: clientpaymentConstants.PAYOUT_LIST_ERROR, Error } }
}

function payout(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            paymentService.payout(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function instantPayout(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
             paymentService.instantPayout(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function addTerminal(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            paymentService.addTerminal(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function requestCardReader(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            paymentService.requestCardReader(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function fetchConnectionToken(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            paymentService.fetchConnectionToken(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function cardReaderList(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            paymentService.cardReaderList(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    // console.log("salonProfile serverResponseData", serverResponseData.responseData.result.data);
                    dispatch(success(serverResponseData.responseData.result.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(Request) { return { type: clientpaymentConstants.CARD_READER_LIST_REQUEST, Request } }
    function success(Success) { return { type: clientpaymentConstants.CARD_READER_LIST_SUCCESS, Success } }
    function failure(Error) { return { type: clientpaymentConstants.CARD_READER_LIST_ERROR, Error } }
}

function cardReaderDelete(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            paymentService.cardReaderDelete(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function paymentIntents(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            paymentService.paymentIntents(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function paymentIntentsCapture(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            paymentService.paymentIntentsCapture(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function subscriptionGet(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            paymentService.subscriptionGet(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function updateTransactionFee(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            paymentService.updateTransactionFee(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function createLoginLink(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            paymentService.createLoginLink(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}


function makeCustomerPrimaryCard(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            return paymentService.makeCustomerPrimaryCard(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                  return serverResponseData;
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function setUpIntent() {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            return paymentService.setUpIntent()
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                  return serverResponseData;
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}
