import React, { Component } from "react";
import { withRouter } from 'react-router-dom'; 
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import moment from 'moment';
import _ from "lodash";

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMobileRightPannel: false,
      selectedServices: [],
      isbookingSubmit:this.props.isbookingSubmit,
      userInfo:{},
      loginUserData:{},
    };

  }

  componentDidMount() {
    let self = this;
    console.log('booking Date',self.props);
    self.setState({
      userInfo:self.props?.hairdresserData?.userInfo,
    })

    // console.log('check the data',self.props?.hairdresserData?.userInfo);
    // document.body.classList.add("no-topbar");
     
  }

  componentDidUpdate(propsState,prveState){
    let self = this;

     if(propsState?.user?.userInfo!=undefined){
      if(propsState?.user?.userInfo!=prveState.loginUserData){
        self.setState({
          loginUserData:propsState?.user?.userInfo,
          isbookingSubmit:false
         })
      }
     }
   }
   
  
  // componentWillUnmount() {
  //   let self = this;
  //   document.body.classList.remove("no-topbar");
  // }


convertTime(totalDuration){
  if(totalDuration>59){
    const hours = Math.floor(totalDuration / 60); 
    const minutes = totalDuration % 60; 
    if(minutes==0){
      return hours+'h ';
    }else{
      return hours+'h '+ minutes+'m'
    }
   
  }else{
    return totalDuration+'m'
  }
}

continueWithService(){
  let salonId=this.props.params.salonId;
  let hairdresserId=this.props.params.hairdresserId;
  const stateObj = { salonId:salonId , 
    hairdresserId:hairdresserId,
    totalPrice:this?.props?.totalPrice,
    totalTime:this?.props?.totalTime,
    currencySymbol:this?.props?.currencySymbol,
    selectedServices:this?.props?.selectedServices,
    hairdresserData:this?.props?.hairdresserData,
    params:this?.props?.params,
    isBackArrow:this.props.history.location?.state?.isBackArrow?this.props.history.location?.state?.isBackArrow:false,
    ...this.props.history.location?.state
  };

   if(this.props?.page==='service'){
    this.props.history.push({
      pathname: `/salon/${salonId}/${hairdresserId}/booking-slot`,
      state: stateObj,
    });
   }else{
    this.props.createBooking();
   }
}

  getslotTimeSec=(time)=> {
    let hr = parseInt(time / 60);
    let min = time - (hr * 60);
    return (hr <= 9 ? '0' + hr : hr) + ':' + (min == 0 ? '00' : (min <= 9 ? '0' + min : min)) + ":00";
  }

  getBookingCancelTime=(time,bookingDate,beforeBookingHour)=>{
    let todayStr = moment(bookingDate).local().format('YYYY-MM-DD');
    let finalDate=todayStr + '-' + this.getslotTimeSec(time);
    return moment(finalDate,"YYYY-MM-DD-HH:mm:ss").add(-beforeBookingHour, 'hours').format("hh:mm A")
  }

  getBookingCancelDate=(bookingDate,beforeBookingHour)=>{
    return moment(bookingDate).add(-beforeBookingHour, 'hours').format("DD MMM YYYY")
  }

  getCancelPolicy=()=>{

    let self=this;
    let startTime=self.props.bookingState.slotInfo?.startTime;
    let bookingDate=self.props.bookingState.selectedDay;
    let cancelPolicy=self.props.hairdresser?.userInfo?.hairdresser?.cancellationPolicy;
    let noShowStatus =self.props.hairdresser?.userInfo?.hairdresser?.noShowStatus;

    if(self.props.hairdresser?.userInfo?.hairdresser?.employmentTypeId===1&&self.props.hairdresser?.userInfo?.hairdresser?.isSalonOwner==0){
      //emp case
      cancelPolicy = self.props.bookingState.salonOwnerInfo?.cancellationPolicy;
      noShowStatus = self.props.bookingState.salonOwnerInfo?.noShowStatus;

      console.log("owner data details",noShowStatus,cancelPolicy);
    }

    console.log("checkCancelPolicy",cancelPolicy,startTime,self.props.bookingState.slotInfo,startTime&&((cancelPolicy && cancelPolicy.length > 0)||noShowStatus))
    if (startTime&&((cancelPolicy && cancelPolicy.length > 0)||noShowStatus)) {

      let cancellationPolicy = _.sortBy(cancelPolicy, 'beforeBookingHour');
      let lastIndex = cancellationPolicy.length - 1;
      console.log("#####cancellationPolicy",cancellationPolicy);

      return (<div className='_cancel_policy'>
        <h6 className='mb-3'>Cancelation Policy</h6>

        <p>You have
          until <span>{this.getBookingCancelTime(startTime, bookingDate, cancellationPolicy[lastIndex].beforeBookingHour)}</span> on <span>{this.getBookingCancelDate(bookingDate, cancellationPolicy[lastIndex].beforeBookingHour)}</span> to
          cancel this appointment
          without being charged.</p>
        {
          cancellationPolicy.map((obj, i) => {
            return (<p key={'cp-' + i}>Cancellations
              after <span>{this.getBookingCancelTime(startTime, bookingDate, obj.beforeBookingHour)}</span>
              <span> on {this.getBookingCancelDate(bookingDate, obj.beforeBookingHour)}</span> will
              be charged {obj.amountInPercentage}% of the remaining cost of booking. </p>)
          })
        }
      </div>)
    }

  }

  checkContinueDisabled=()=>{
      if(this?.props?.page==="booking-slots") {
        console.log("his?.props?.startTime",this?.props?.startTime)
        if (this?.props?.startTime !==""&&this?.props?.startTime!==null) {
          return false;
        }else{
          return true;
        }
    }else{
        return this.state?.isbookingSubmit?(this.state?.isbookingSubmit===true?true:false):(this?.props?.selectedServices?.length > 0 ? false :true)
      }
  }


  render() {
// {console.log('this.props==== ',this.props)}
    return (
      <>
        {/* // <div className='__right  align-items-start _service __mobile ' > */}
        <div className="beu_logo hide-mobile text-center w-100">
          <img
            alt="_profile"
            src={require("../../../assets/images/beu-logo-rectangle.png")}
            className="img-fluid mb-3"
          />
        </div>

        <div className={`_right_box`}>
          <p className="_bar_togle" onClick={this.barOnClick}>
            <span></span>
          </p>
          <h3 className="_heading">Summary</h3>
          <div className="_staff_detail">
            <div className="img">
              <img
                alt="service"
                src={this?.state?.userInfo?.userImage?.circleImage?this?.state?.userInfo?.userImage?.circleImage:require("../../../assets/images/Pixie.png")}
                className="rounded-circle"
              />
            </div>
            <p className="mb-0 text-left">
             {this?.state?.userInfo?.name} 
              {/* --if one booking come then open this for mobile */}
              {/* {this?.props?.selectedServices.length == 1 ?
               <>
              {this?.props?.selectedServices?.map((service, i) => (
              <p className='m-0 ml-0 w-100 hide-desktop'>{service?.serviceName}</p>
              ))}
              </>
              : '' } */}
              <h6 className='m-0 ml-0 w-100 _datee'>{this?.props?.bookingDate!=undefined?<>{moment(this?.props?.bookingDate).format('ll')+ ((this.props?.startTime==null)?'':' at '+ this.props?.startTime)}</>:null}
              </h6>
            </p>
            
            {this?.props?.selectedServices?.length > 0 ? (
              <h4 className="mb-0">{this.props?.currencySymbol}{this?.props?.totalPrice}</h4>
            ) : null}
          </div>

          {this?.props?.selectedServices?.length > 0 ? (
            <hr className="__service_hr" />
          ) : null}

          <div className="selected__service table-responsive ">
            <table className="w-100">
              <tbody>
                {this?.props?.selectedServices?.map((service, i) => (
                  <tr key={"service_item_" + i}>
                    <td className="text-left">{service?.serviceName}</td>
                    <td>{this.convertTime(service?.serviceTime)}</td>
                    <td className="text-right">
                      {this.props?.currencySymbol}
                      {service?.servicePrice?.toFixed(2)}
                    </td>
                  </tr>
                ))}
                {this?.props?.selectedServices?.length > 0 ? (
                  <>
                    <tr>
                      <td colSpan={3}>
                        <hr className="__service_hr mt-1 mb-1" />
                      </td>
                    </tr>
                    <tr className="total">
                      <td className="text-left">Total</td>
                      <td>{this.convertTime(this?.props?.totalTime)}</td>
                      <td className="text-right">
                        {this.props?.currencySymbol}{this.props.totalPrice}
                      </td>
                    </tr>
                  </>
                ) : null}
              </tbody>
            </table>
          </div>
        
             

            {this?.props?.isLoading? <p
               className={`btn btn-info blue_btn px-5 mt-5 ${this?.props?.selectedServices?.length > 4 ? 'remove-absoute':'add-absoute' }`}
            >
            <Spinner animation="border" size="sm" />
            </p>:<button
             disabled={this.checkContinueDisabled()}
              onClick={()=>this.continueWithService()}
              className={`btn btn-info blue_btn px-5 mt-5 ${this?.props?.selectedServices?.length > 4 ? 'remove-absoute':'add-absoute' }`}
            >
             {this?.props?.totalDeposit>0?'Pay Deposit, '+this.props.currencySymbol+this.props.totalDeposit.toFixed(2):this?.props?.page==='booking-slots'?'Confirm':'Continue'} 
            </button>}
          {/*<div className={"summary_cancel_policy"}>*/}
          {/*  {this.getCancelPolicy()}*/}
          {/*</div>*/}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
      hairdresser
  } = state;
  return {
      hairdresser,
  };
}


export default withRouter(connect(mapStateToProps)(Summary));
