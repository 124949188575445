import React, { Component } from 'react';
import moment from 'moment';
import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';

import { connect } from "react-redux";
import { bookingActions, hairdresserActions } from "../../_actions";
import siteSetting from "../../config/env";
import Invoice from "./Invoice";
import NewInvoice from "./NewInvoice";

// let userIdHolder = [];
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

function getslotTime(time) {
    let hr = parseInt(time / 60);
    let min = time - (hr * 60)
    return hr + ':' + (min === 0 ? '00' : min);
}

class Booking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bookingDate: new Date(),
            selectSlot: '',
            showOptionsModal: false,
            showTipModal: false,
            latitude: siteSetting.default_latitude,
            longitude: siteSetting.default_longitude,
            hairdresserName: '',
            selectedService: [],
            finalBookingSlots: [],
            includeTimes: [],
            slotInfo: {},
            totalAmount: 0,
            totalTime: 0,
            hairdresserId: '',
            selectedClientId: '',
            selectedClient: {},
            isSaveBooking: 0,
            bookingSlots: [],
            paymentType: '',
            isBookingAllowed: false,
            searchKey: '',
            bookingType:'past', //upcoming
            pendingAmount:0,
            transaction:[],
            paymentAmount:0,
            totalPaymentAmount:0,
            isCheckout:0,
            userId:'',
            isInvoice:0,
            currencySymbol:"£",
            transactions:[],
            invoiceNumber:0,
            selectedPaymentType:2,
            selectedAmount:'',
            oldTransaction:[],
            checkOutAmountType:true,
            selectedFixedAmount:0,
            isPaymentComplete:0,
            products:[],
            bookingId:this.props.match.params.bookingId
        }
    }


    handleOptionModal = () => this.setState({ showOptionsModal: !this.state.showOptionsModal })

    handleTipModal = () => {
        this.setState({
            showOptionsModal: false,
            showTipModal: !this.state.showTipModal
        })
    }

    getslotTime=(time)=> {
        let hr = parseInt(time / 60);
        let min = time - (hr * 60)
        return hr + ':' + (min === 0 ? '00' : min);
    }

    componentDidMount() {
        var self = this;
        let params = {};
        
        if (localStorage.getItem('accessToken')&&this.props.match.params.bookingId!=='') {
            //self.props.dispatch(userActions.profile(params))
            let getparams = {
                bookingID:this.props.match.params.bookingId,
                getBookingsOfType:'updates'
            }

            self.props.dispatch(bookingActions.getHairdresserBooking(getparams))
                .then(function (response) {
                    
                    let totalTime=0,totalAmount=0;
                    let totalProductPrice = response.data.data.bookings[0].totalProductPrice;
                    const bookingInfoSlot = response.data.data.bookings[0]?.bookingSlot;
                    if(bookingInfoSlot!==undefined){
                        totalTime =bookingInfoSlot[1]-bookingInfoSlot[0];
                    }
                    
                    self.setState({
                        selectedClient:response.data.data.bookings[0].user,
                        selectedClientId:response.data.data.bookings[0].userId
                    })
                 
                    response.data.data.bookings[0].servicesBooked.map((services, i) => {
                        // totalTime=totalTime+services.serviceTime;
                        totalAmount=totalAmount+parseFloat(services.servicePrice);
                    })
                    totalProductPrice = (totalProductPrice>0&&totalProductPrice!==undefined)?totalProductPrice:0;
                    totalAmount=totalAmount+totalProductPrice;
                    let totalPaidAmount=0;
                    if(response.data.data.bookings[0].transaction&&response.data.data.bookings[0].transaction.length>0) {
                        response.data.data.bookings[0].transaction.map((transaction, i) => {
                            totalPaidAmount=totalPaidAmount+parseFloat(transaction.amount)
                        })
                    }
                    if(response.data.data.bookings[0].transactions&&response.data.data.bookings[0].transactions.length>0) {
                        response.data.data.bookings[0].transactions.map((transaction, i) => {
                            totalPaidAmount=totalPaidAmount+parseFloat(transaction.pgTransactionAmount);
                        })
                    }
                    let totalPaymentAmount=totalPaidAmount;
                    let pendingAmount=totalAmount-totalPaidAmount;
                    let paymentAmount=pendingAmount;
                    self.setState({
                        selectedService:response.data.data.bookings[0].servicesBooked,
                        slotInfo:{
                            startTime:self.getslotTime(response.data.data.bookings[0].bookingSlot[0]),
                            endTime:self.getslotTime(response.data.data.bookings[0].bookingSlot[1]),
                        },
                        invoiceNumber:response.data.data.bookings[0].invoiceNumber,
                        transaction:response.data.data.bookings[0].transaction,
                        transactions:response.data.data.bookings[0].transactions,
                        totalAmount: totalAmount.toFixed(2),
                        totalTime: totalTime,
                        pendingAmount:pendingAmount,
                        paymentAmount,
                        totalPaymentAmount,
                        oldTransaction:response.data.data.bookings[0].transaction,
                        hairdresserId:response.data.data.bookings[0].hairdresserId,
                        hairdresserName:response.data.data.bookings[0].hairdresser.name,
                        userId:response.data.data.bookings[0].userId,
                        isCheckout:response.data.data.bookings[0].isCheckout,
                        products:response.data.data.bookings[0].products,
                        currencySymbol:response.data.data.bookings[0].hairdresser.hairdresser&&response.data.data.bookings[0].hairdresser.hairdresser.currencySymbol&&response.data.data.bookings[0].hairdresser.hairdresser.currencySymbol!==""&&response.data.data.bookings[0].hairdresser.hairdresser.currencySymbol!=="undefined"?response.data.data.bookings[0].hairdresser.hairdresser.currencySymbol:"£"
                    })
                    self.props.dispatch(hairdresserActions.getHairdresserClientDetail({
                        'userId': response.data.data.bookings[0].userId,
                        'hairdresserId':response.data.data.bookings[0].hairdresserId,
                        'bookingType':self.state.bookingType
                    }))
                })
        } else {
            this.props.history.push('/')
        }
        document.body.classList.add('booking-temp');
    }

    getslotTime(time) {
        let hr = parseInt(time / 60);
        let min = time - (hr * 60)
        return hr + ':' + (min === 0 ? '00' : min);
    }

    changeClient() {
        this.setState({
            selectedClientId: '',
            selectedClient: {},
            isSaveBooking: 0,
            bookingSlots: [],
            paymentType: '',
            searchKey: ''
        })
    }

    handleEvent(e, d) {
        if (e === "eventStartTime") {
            let m = moment(d);
            let minutes = (m.hour() * 60) + m.minute();
            this.setState({ eventTime: d, eventStartTime: minutes })
        }
        if (e === "eventEndTime") {
            let m = moment(d);
            let minutes = (m.hour() * 60) + m.minute();
            this.setState({ endTime: d, eventEndTime: minutes })
        }
        if (typeof e === "object" && e.target.id === "eventTitle") {
            this.setState({ eventTitle: e.target.value })
        }
        if (typeof e === "object" && e.target.id === "isBookingAllowed") {
            this.setState({ isBookingAllowed: !this.state.isBookingAllowed })
        }
    }


    onChanged(e) {
        console.log("e",e.target);
        this.setState({ [e.target.name]: parseFloat(e.target.value)});

        if (e.target.name === 'pendingAmount') {
            let totalPaymentAmount=parseFloat(this.state.totalPaymentAmount)+parseFloat(e.target.value);
            let pendingAmount=parseFloat(this.state.totalAmount)-totalPaymentAmount;
            if (parseFloat(e.target.value) > pendingAmount) {
                this.setState({
                    paymentAmountError: 'Invalid Amount'
                })
            } else {
                this.setState({
                    paymentAmountError: '',
                })
            }
        }
    }

    noShowBooking(){
        let self=this;
        let params={}
        params.hairdresserId = this.state.hairdresserId;
        params.bookingID=this.props.match.params.bookingId;
        params.deviceToken = "dfdsfdsfsd";

        self.props.dispatch(bookingActions.hairdresserNoShowBooking(params))
            .then(function (response) {
                console.log('booking response', response);

                if (response.data.errorCode) {
                    self.setState({
                        error: response.data.messages
                    })
                } else {
                    //self.props.history.push('/diary/' + localStorage.getItem('salonId'))
                    window.location.reload();
                }
            })
    }

    componentWillUnmount() {
        document.body.classList.remove('booking-temp');
    }
    render() {
        // console.log("this.props",this.props);
        // console.log("this.state", this.state);

        return (
            <div className="h_n">
                    <div className="body_container bg_white newbooking-for-mobile booking-for-mobile">
                        <TopHeader title="Invoice"
                        headerButtonStatus={true}
                        cancelButton={true}
                        {...this.props} 
                        cross="false"/>
                        <SideNav {...this.props} />
                        <div className="booking-parent view-booking-parent">
                        {this.props.hairdresserBooking.bookings ?
                        <div className="new-invoice-parent __new_checkout">
                            <div className="tab_content_wrapper">
                                <NewInvoice transactionData={this.state.transaction} bookingState={this.state}/>
                            </div>
                              <Invoice bookingState={this.state} history={this.props.history}/>
                        </div>
                            : 
                            <div className="cm_loader"><div className="loader"></div></div>
                            }
                    </div>
                    </div>

            </div>

        )
    }

}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Booking);

