import React from 'react'
import FullCalendar, { formatDate } from '@fullcalendar/react'
import * as d3 from "d3";
import toast from "react-toast-notification";
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin,{Draggable} from '@fullcalendar/interaction'
import { withRouter, Redirect } from 'react-router-dom';
import {
  bookingActions,
  hairdresserActions,
  productAction,
} from "../../_actions";
import moment from 'moment';
import {eventActions} from "../../_actions";
import {connect} from "react-redux";
import dateFormat from "dateformat";
import promise from "bluebird";
import { BlockIcon, CardIcon } from '../../assets/SVG';
var _ = require('lodash');
const isMobile = (window.innerWidth <= 768);
const colors = ['#D6D5EC', '#C8EEEE', '#D6D5EC', '#f5dabf'];

function getslotTime(time) {
    let hr = parseInt(time / 60);
    let min = time - (hr * 60);
    //console.log("time",time,hr,min);
    return (hr <=9 ? '0'+hr : hr)+ ':' + (min == 0 ? '00' : (min <=9 ? '0'+min : min));
}

function getCalenderStartTime(time) {
    let hr = parseInt(time / 60);
    let min = time - (hr * 60);
    //console.log("time",time,hr,min);
    return (hr <=9 ? '0'+hr : hr)+':'+"00:00";
}


function getCalenderEndTime(time) {
    let hr = parseInt(time / 60);
    let min = time - (hr * 60);
    hr= min == 0 ? hr : hr+1;
    //console.log("time",time,hr,min);
    return (hr <=9 ? '0'+hr : hr)+':'+"00:00";
}

function getServiceName(item) {
    let serviceName = "";
    if (item.list[0].info.servicesBooked) {
        item.list[0].info.servicesBooked.map((service) => {
            serviceName = serviceName + (serviceName == "" ? "" : ", ");
            serviceName = serviceName + service.service.serviceName;
        })
    }
    return serviceName;
}
function getClenderHeight(startTime,EndTime){
    let totalHr=parseInt((EndTime-startTime)/60);
    let hourDiff=totalHr;
    let calenderHeight = (160*hourDiff)+5;
    if(isMobile){
        calenderHeight = (140*hourDiff)+15;
    }
    //console.log("totalHr",totalHr,calenderHeight);
    return calenderHeight;
}
function reverseString(str) {
    return str.split("-").reverse().join("/");
}
class CalenderModule extends React.Component {
            constructor(props){
              super(props)
              this.state = {
                  userIndex:props.userIndex,
                  showTime: this.props.showTime,
                  staff:props.staff,
                clClass: props.clClass,
                __id: props.__id,
                bookingDate:props.bookingDate,
                currentDate:new Date(),
                currentDay:new Date().getDate().toString(),
                currentMonth: new Date().getMonth().toString(),
                currentYear:new Date().getFullYear().toString(),
                hairdresserId:props?.staff?._id,
                 initial_events:props.initial_events,
                  calenderStartTime:props.calenderStartTime,
                  calenderEndTime:props.calenderEndTime,
                    todayStartWorking:props.workTime.todayStartWorking,
                  todayEndWorking:props.workTime.todayEndWorking,
                  selectable:props.workTime.selectable&&props.workTime.selectable==2?false:true,
                  scrollTimelineStatus:props.scrollTimelineStatus,
                  nowIndicator:props.bookingDate == moment().format('YYYY-MM-DD')?true:false,
                  zoomLevel:100,prevWidth: window.innerWidth,
                  screenWidth: window.innerWidth,
                  currentDragObj:{
                      time:[],
                      service:''
                  },
                  selectedServiceObj:{},
                  notPermissionToUpdate:false,
                  expandService:{}
                 }
              this.addAppointment = this.addAppointment.bind(this);
              this.getHairdresserTime = this.getHairdresserTime.bind(this);
              this.handleResize = this.handleResize.bind(this);
              this.resizeBooking = this.resizeBooking.bind(this);
              this.calendarRef = React.createRef();
            }
  state = {
    weekendsVisible: true,
    currentEvents: []
  }

   createEventId() {
    return String(this.eventGuid++)
  }


  // componentDidUpdate(prevProps) {
  //   console.log('check_new_data',prevProps.initial_events);
  //   if (prevProps.initial_events !== this.props.initial_events || prevProps.delta !== this.props.delta) {
  //     this.updateEvents();
  //   }
  // }


  updateEvents() {
    const { event, delta } = this.props;
    const calendarApi = this.calendarRef.current.getApi();
    console.log('calendarApi',calendarApi);
    const relatedEvents = calendarApi.clientEvents(evt => {
      console.log('check_related_event',evt);
      for (let i = 0; i < event.relatedEvents.length; i++) {
        if (evt.id === event.relatedEvents[i]) return true;
      }
      return false;
    });

    relatedEvents.forEach(relatedEvent => {
      relatedEvent.start = relatedEvent.start.add(delta);
      relatedEvent.end = relatedEvent.end.add(delta);
    });

    calendarApi.updateEvents(relatedEvents);
  }


  componentDidMount(){
    // const tmCls = document.querySelector('.fc-scrollgrid-shrink-cushion');
    // const tmcId = document.querySelector('.cldts');
    // tmcId.appendChild(tmCls)
    if(!this.state.scrollTimelineStatus){
      this.props.scrollFixTimeLine(this.state.calenderStartTime,this.state.calenderEndTime);
    }
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({
      screenWidth: window.innerWidth,
    });
  }

getslotTime(time) {
    let hr = parseInt(time / 60);
    let min = time - (hr * 60)
    //return hr + ':' + (min == 0 ? '00' : min);
    return (hr <=9 ? '0'+hr : hr)+ ':' + (min == 0 ? '00' : (min <=9 ? '0'+min : min));
}

  getHairdresserTime(schedule) {
    let self = this;
    // console.log(schedule);
    //console.log("schedule",schedule);
    if (schedule?.rota?.schedule) {
        //console.log("hairdresser.rotaInfo.rota",hairdresser.rotaInfo.rota.schedule[0],moment().format('ddd').toLowerCase());
        let selected = _.find(schedule.rota.schedule[0].profiles, function (o) {
            return o.day === moment(self.state.bookingDate).format('ddd').toLowerCase();
        })
        if (selected) {
            return this.getslotTime(selected.startTime) + '-' + this.getslotTime(selected.endTime);
        } else {
            return <span >Not Working </span>
        }

    } else {
        return <span >Not Working </span>
    }
}


    addAppointment(data) {
      let self = this;
      let crSlotDate = data.startStr.split('T');
      let client = {};
      self.props.dispatch(bookingActions?.hairdresserNewBookingClient(client));
      self.props.dispatch(productAction.selectedClient(client));
      self.props.dispatch(hairdresserActions.selectedService({}));
      self.props.dispatch(bookingActions?.hairdresserNewBookingStaff({}));
      self.props.dispatch(bookingActions?.hairdresserNewBookingData([]));
       self.props.history.push({
        pathname:'/newbooking/' + localStorage.getItem('salonId') + '/' + self.state.staff._id,
        search: `?query=staff`,
        state:  {
          end:data.end,
          passedData:self.state.staff,
          start: data.start,
          bookingDate:this.state.bookingDate
      }
    });
    }

    getHairdresserWorking=(schedule)=> {
        let self = this;
        if (schedule?.rota?.schedule) {
            let selected = _.find(schedule.rota.schedule[0].profiles, function (o) {
                return o.day === moment(self.state.bookingDate).format('ddd').toLowerCase();
            })
            if (selected) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    nowIndicatorContent=()=>{
                let self=this;
                if(self.state.userIndex===0) {
                    const d = new Date();
                    let min = d.getMinutes();
                    let hr = d.getHours();
                    return (<div className='new_indicator'>{(hr <=9 ? '0'+hr : hr)}:{(min == 0 ? '00' : (min <=9 ? '0'+min : min))}</div>)
                }
    }
    getBoxStyle = () => {
      // debugger;
      const numberOfBox = this.props.noOfBox;
      let containerStyle = {};
      let screenSize =this.state.screenWidth 
      const val=Math.round((this.state.screenWidth-336) / numberOfBox)+'px'
      const onlyVal=Math.round((this.state.screenWidth-336) / numberOfBox);
      if (  screenSize > 2400) { 
          if(onlyVal < 290 ){
            containerStyle = {
              'min-width': '290px',
              'max-width': '290px'
            };
            console.log(`hiiii1 =>screenWidth=${screenSize} , numberOfBox=${numberOfBox} ,onlyVal=${onlyVal} ,containerstyle = ${containerStyle}`)
            
          }
          else if(onlyVal > 290 ){
            containerStyle = {
              'min-width': val,
              'max-width': val
            };
            console.log(`hiiii1 =>screenWidth=${screenSize} , numberOfBox=${numberOfBox} ,onlyVal=${onlyVal} ,containerstyle = ${containerStyle}`)
            
          }
      } 
      else if (  screenSize >= 1900  &&  screenSize <= 2000) {
        
        if(numberOfBox<=7){
          let calculateWidth =screenSize - 350
          calculateWidth = calculateWidth/numberOfBox + 'px'
          containerStyle = {
            'min-width': calculateWidth,
            'max-width': calculateWidth
          };
        }
        else if (numberOfBox>=8){
          let calculateWidth =screenSize - 350
          calculateWidth = calculateWidth/8 + 'px'
          containerStyle = {
            'min-width': calculateWidth,
            'max-width': calculateWidth
          };
        }
       console.log("onlyValonlyValonlyVal===>",onlyVal,numberOfBox,screenSize)
      }
      return containerStyle;
    }; 

  

  render() {
 
     // console.log('check innnn',this.state.initial_events)
    return (
      <div className={`calendar_wrapper ${this.state.clClass}`} id={this.state.__id}  style={this.getBoxStyle()}>

         {/*{this.renderSidebar()} */}
        {/* {console.log("========",this.state)} */}
        <div className='demo-app-main'>
            {this.state.showTime===false?
            <div className={this?.getHairdresserWorking(this?.state?.staff?.rotaInfo)?"staff_user":"staff_user not--Working"}>
            <div>
            <h3>{this?.state?.staff?.name}</h3>
            <p>{this?.getHairdresserTime(this?.state?.staff?.rotaInfo)}</p>
            </div>
            <div className="userProfile">
              <img alt={this?.state?.staff?.name} src={this?.state?.staff?.userImage?.circleImage&&this?.state?.staff?.userImage?.circleImage!=""?this?.state?.staff?.userImage?.circleImage: require("../../assets/images/Pixie.png")} />
          </div>
            </div>:
                <div className={this?.getHairdresserWorking(this?.state?.staff?.rotaInfo)?"staff_user":"staff_user not--Working"}>
                    <div>
                        <h3>No Staff</h3>
                        
                        <p>{this?.getHairdresserTime(this?.state?.staff?.rotaInfo)}</p>
                    </div>
                    {/* <div className="userProfile">
                      <img alt={this?.state?.staff?.name} src={require("../../assets/images/Pixie.png")} />
                  </div> */}

                </div>
            }
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            timeZone={'local'}
            ref={this.calendarRef}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'timeGridDay'
            }}
            slotDuration= {'00:15:00'}
            initialView='timeGridDay'
            // views={{
            //   timeGridDay: {
            //     dayCount: 2, 
            //   },
            // }}
              slotLabelFormat={[{
                hour: 'numeric',
                minute: '2-digit',
                meridiem: false,
                hour12:false
              }]}
            // defaultTimedEventDuration={'00:90:00'}
            // forceEventDuration={true}
            // slotLabelFormat="HH:mm"
            eventTimeFormat={
              {
                hour: 'numeric',
                minute: '2-digit',
                meridiem: false,
                hour12:false
              }
            }
            // selectConstraint={{
            //     start: this.state.todayStartWorking,
            //     end: this.state.todayEndWorking
            // }}
            // eventResizableFromStart={true}
            eventStartEditable={true}
            slotLabelInterval={120}
            displayEventTime={true}
            allDaySlot={false}
            contentHeight={"auto"}
            editable={true}
            selectable={this.state.selectable}
            selectMirror={true}
            dayMaxEvents={true}
            eventOverlap={true}
            weekends={this.state.weekendsVisible}
            nowIndicator={this.state.nowIndicator}
            nowIndicatorContent={this.nowIndicatorContent}
            longPressDelay={500}
            eventContent={'some text'}
            initialEvents={this.state.initial_events} // alternatively, use the `events` setting to fetch from a feed
            select={this.addAppointment}
            eventResize={this.resizeBooking}
            eventContent={renderEventContent} // custom render function
            eventClick={this.handleEventClick}
            eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
            /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventRemove={function(){}}
            */
            eventChange={this.handleEventChange}
            eventDragStart={this.eventDragStart}
            // eventDragStop={this.handleEventDragStop}
            slotLabelInterval={"01:00"}
            slotMinTime={getCalenderStartTime(this.state.calenderStartTime)}
            slotMaxTime={getCalenderEndTime(this.state.calenderEndTime)}
          />
        </div>
      </div>
    )
  }

  renderSidebar() {
    return (
      <div className='demo-app-sidebar'>
        <div className='demo-app-sidebar-section'>
          <h2>Instructions</h2>
          <ul>
            <li>Select dates and you will be prompted to create a new event</li>
            <li>Drag, drop, and resize events</li>
            <li>Click an event to delete it</li>
          </ul>
        </div>
        <div className='demo-app-sidebar-section'>
          <label>
            <input
              type='checkbox'
              checked={this.state.weekendsVisible}
              onChange={this.handleWeekendsToggle}
            ></input>
            toggle weekends
          </label>
        </div>
        <div className='demo-app-sidebar-section'>
          <h2>All Events ({this.state.currentEvents.length})</h2>
          <ul>
            {this.state.currentEvents.map(renderSidebarEvent)}
          </ul>
        </div>
      </div>
    )
  }

  handleWeekendsToggle = () => {
    this.setState({
      weekendsVisible: !this.state.weekendsVisible
    })
  }

  handleDateSelect = (selectInfo) => {
                console.log("selectInfo",selectInfo);
    let title = prompt('Please enter a new title for your event')
    let calendarApi = selectInfo.view.calendar

    calendarApi.unselect() // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: this.createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      })
    }
  }

  handleEventClick = (clickInfo) => {
        if(clickInfo?.event?.extendedProps?.type==='booking'){
            window.location.href="/booking/" + clickInfo.event.extendedProps.eventId;
        }
        if(clickInfo.event.extendedProps.type==='event'){
             window.location.href="/event-view/" + clickInfo.event.extendedProps.eventId;
            //this.gotoEvent(clickInfo.event.extendedProps.info.list[0],this.state.staff)
        }
  }

    handleEventDragStop=(clickInfo)=>{
        let self = this;
        let startHr=new Date(clickInfo.event._instance.range.start).getUTCHours();
        let startMin=new Date(clickInfo.event._instance.range.start).getUTCMinutes();
        let endHr=new Date(clickInfo.event._instance.range.end).getUTCHours();
        let endtMin=new Date(clickInfo.event._instance.range.end).getUTCMinutes();

        let startTime=(startHr*60)+startMin;
        let endTime=(endHr*60)+endtMin;
        let bookingSlot=[startTime,endTime];
        console.log("handleEventDragStop clickInfo",clickInfo,startHr,":",startMin,"----",endHr,":",endtMin);
    }

    eventDragStart = (clickInfo)=>{
      let self = this;
      let startHr=new Date(clickInfo.event._instance.range.start).getUTCHours();
      let startMin=new Date(clickInfo.event._instance.range.start).getUTCMinutes();
      let endHr=new Date(clickInfo.event._instance.range.end).getUTCHours();
      let endtMin=new Date(clickInfo.event._instance.range.end).getUTCMinutes();
  

      let startTime=(startHr*60)+startMin;
      let endTime=(endHr*60)+endtMin;
      let bookingSlot=[startTime,endTime];

      let selectedData = self.props.initial_events.find(service=>service?.info?.startTime==bookingSlot[0]&&service?.info?.endTime==bookingSlot[1]);

      this.setState({
        currentDragObj:{
          time:bookingSlot,
          initial_events:JSON.parse(JSON.stringify(self.props.initial_events)),
          service:selectedData!=undefined?JSON.parse(JSON.stringify(selectedData)):{},
        }
      })
    }

    resizeBooking(bookingInfo){
       let self = this;
      let processingTime = bookingInfo?.event?.extendedProps?.info?.list[0]?.info?.servicesBooked[0]?.processingTime;

      let startHr=new Date(bookingInfo.event._instance.range.start).getUTCHours();
      let startMin=new Date(bookingInfo.event._instance.range.start).getUTCMinutes();
      let endHr=new Date(bookingInfo.event._instance.range.end).getUTCHours();
      let endtMin=new Date(bookingInfo.event._instance.range.end).getUTCMinutes();
      let startTime=(startHr*60)+startMin;
      let endTime=(endHr*60)+endtMin;
      let bookingSlot=[startTime,endTime];
      let initial_events=self.state.initial_events;
      // self.setState({
      //   expandService:bookingInfo?.event?.extendedProps?.info?.list[0]?.info?.servicesBooked[0]
      // })
      
      console.log('check infoffff',bookingInfo,bookingInfo.event._def.publicId);
     
     
      if(bookingInfo?.event?.extendedProps?.info?.list[0]?.info?.servicesBooked?.length>1){
        if(processingTime!==undefined || processingTime!==null){
          
         // let selectedData = bookingInfo.event.extendedProps.info.list[0]?.servicesBooked?.service;

          let selectedData = self.props.initial_events.find(service=>service?.id==bookingInfo.event._def.publicId);

            console.log('check data iii',selectedData);

            self.setState({
            currentDragObj:{
              time:bookingSlot,
              initial_events:JSON.parse(JSON.stringify(self.props.initial_events)),
              service:selectedData!=undefined?JSON.parse(JSON.stringify(selectedData)):{},
            }
          })

       
          // return;
          // bookingInfo.revert();
          // setTimeout(()=>{
          //   window.location.reload();
          // },1200);
        }else{
          self.setState({
            notPermissionToUpdate:false
          })
        }
      }
      
 }
 


    handleEventChange = (clickInfo) => {
        let self = this;
        // clickInfo.event.extendedProps.info.list[0].info;
         console.log("handleEventChange clickInfo",clickInfo.event.extendedProps);
        // console.log("handleEventChange clickInfo",new Date(clickInfo.event._instance.range.end).getUTCHours());
        let startHr=new Date(clickInfo.event._instance.range.start).getUTCHours();
        let startMin=new Date(clickInfo.event._instance.range.start).getUTCMinutes();
        let endHr=new Date(clickInfo.event._instance.range.end).getUTCHours();
        let endtMin=new Date(clickInfo.event._instance.range.end).getUTCMinutes();
        let startTime=(startHr*60)+startMin;
        let endTime=(endHr*60)+endtMin;
        let bookingSlot=[startTime,endTime];
        let bookingServices = clickInfo.event.extendedProps;
        let initial_events=self.state.initial_events;
        let statusOfDragBooking;

        if(clickInfo.event.extendedProps.type==='booking'){
         statusOfDragBooking = self.checkBookingPositions(clickInfo,initial_events,bookingSlot,clickInfo.event.extendedProps?.info.list[0].info._id);

         console.log('check finel data',statusOfDragBooking);
         if(self.state.notPermissionToUpdate){
          self.setState({
            notPermissionToUpdate:false
          })
          return;
         }
      }
       
        let eventArr=[];
        initial_events.map(function (obj){

            if(obj.eventId&&obj.id===statusOfDragBooking?.selectedService.id){
                obj.info.startTime=startTime;
                obj.info.endTime=endTime;
                obj.info.list[0].startTime=startTime;
                obj.info.list[0].endTime=endTime;
                // obj.start=clickInfo.event._instance.range.start;
                // obj.end=clickInfo.event._instance.range.end;
            }

            if(statusOfDragBooking?.selectedService.id===undefined){
              if(obj.eventId&&obj.eventId==clickInfo.event.extendedProps.info.list[0].info._id){
                obj.info.startTime=startTime;
                obj.info.endTime=endTime;
                obj.info.list[0].startTime=startTime;
                obj.info.list[0].endTime=endTime;
            }
            }
            eventArr.push(obj);
        })

        self.setState({
            initial_events:eventArr
        })

        let new_event_arr = self.props.initial_events;
        console.log('check processing time',eventArr);

        if(clickInfo.event.extendedProps.type==='booking'){
            let __test = new_event_arr.find(_eventObj=>_eventObj.eventId===clickInfo.event.extendedProps.info.list[0].info._id);

            // let bookingInfo=clickInfo.event.extendedProps.info.list[0].info;
            let bookingInfo = __test.info.list[0].info
            console.log('check processing time',bookingInfo, __test);
            //self.editBooking(bookingInfo,bookingSlot);
            self.editDairyBooking(statusOfDragBooking?.selectedService,bookingInfo,bookingSlot);
            // this.updateEvents();
        }

        if(clickInfo.event.extendedProps.type=='event'){
            let eventInfo=clickInfo.event.extendedProps.info.list[0].info;
            self.editEvent(eventInfo,startTime,endTime);
        }
    }


    checkBookingPositions=(clickInfo,bookingService,bookingSlot,bookingId)=>{
      let self = this;
      let prevData = self?.state?.currentDragObj;
      if(self.state.notPermissionToUpdate){
        self.setState({
          notPermissionToUpdate:false
        })
        return;
      }

      console.log('log 0',self.state.notPermissionToUpdate);
      let filteredBooking = bookingService?.filter((service)=>service.eventId===bookingId);
          if(filteredBooking?.length<2 ){
            return;
          }
          
        if(filteredBooking?.length>2 ){
          toast.error('Invalid');
          clickInfo.revert();
          return;
        }
        console.log('log 1',filteredBooking);

      let findSelectedBooking = filteredBooking.find((service)=>service.id===prevData.service.id);
      console.log('log 2',findSelectedBooking,prevData);

      if(findSelectedBooking?.id<filteredBooking[filteredBooking.length-1].id){
        let initialData = prevData.initial_events.find((bookingService)=>bookingService.id !==findSelectedBooking.id && bookingService.eventId === bookingId);
         if(bookingSlot[0]<=initialData.info.endTime){
         let processingTime = filteredBooking[filteredBooking?.length-1].info.startTime-bookingSlot[1];
         console.log('processing time 1',processingTime)
          findSelectedBooking.services.processingTime = processingTime<0?0:processingTime;
          findSelectedBooking.serviceNum = 1;
          self.setState({
            notPermissionToUpdate:false
          })

          findSelectedBooking.services.serviceTime = bookingSlot[1]-bookingSlot[0];

          console.log('check service ____data',findSelectedBooking.services.serviceTime,bookingSlot[1],bookingSlot[0])
          return {status:true,selectedService:findSelectedBooking};
         }else{
          self.setState({
            notPermissionToUpdate:true
          },()=>{
            toast.error('Not allowed.');
            clickInfo.revert();
            return false;
          })
         }
      }
      
      if(findSelectedBooking?.id===filteredBooking[filteredBooking?.length-1].id){
       let initialData = prevData.initial_events.find((bookingService)=>bookingService.id !==findSelectedBooking.id && bookingService.eventId === bookingId
       );

       console.log('check service time 3',filteredBooking) 

        if(bookingSlot[0]>=initialData?.info.startTime){
          let processingTime = bookingSlot[0]-filteredBooking[0]?.info?.endTime;
          console.log('processing time 2',processingTime)
          findSelectedBooking.services.processingTime = processingTime<0?0:processingTime;
          findSelectedBooking.serviceNum = 2;
          self.setState({
            notPermissionToUpdate:false
          })

          findSelectedBooking.services.serviceTime = bookingSlot[1]-bookingSlot[0];

          console.log('check service ____data',findSelectedBooking.services.serviceTime,bookingSlot[1]-bookingSlot[0])
          return {status:true,selectedService: findSelectedBooking};

        }else{
          self.setState({
            notPermissionToUpdate:true
          },()=>{
            clickInfo.revert();
            toast.error('Not allowed.');
            return false;
          })
        }
     }

    }

    editBooking=(bookingInfo,bookingSlot)=> {
        let self = this;
            console.log("bookingInfo",bookingInfo);
            let params = {};
            params.servicesToBook = _.map(bookingInfo.servicesBooked, function (o) {
                //return o.service.id;
                return {...o,
                    id:o?.service?.serviceId!=undefined?o?.service?.serviceId:o?.service?._id,
                    _id:o?.service?.serviceId!=undefined?o?.service?.serviceId:o?.service?._id,
                    serviceId:o?.service?.serviceId!=undefined?o?.service?.serviceId:o?.service?._id,
                    price:parseFloat(o?.service?.servicePrice!=undefined?o?.servicePrice:o?.service?.servicePrice)};
            })
            //params.userId = this.state.hairdresserId;
            params.bookingSlot = bookingSlot
            params.bookingDate = this.props.bookingDate
            params.deviceToken = "dfdsfdsfsd"
            params.isPayAtSalonBooking = 1;
            params.clientId = '';
            //params.paidType = self.state.paidType;
            params.hairdresserId = bookingInfo.hairdresserId;
            params.bookingID = bookingInfo._id;
            params.transaction = bookingInfo.transaction;
            params.totalAmount = bookingInfo.totalAmount;
            console.log("params eeeee",params);
            self.props.dispatch(bookingActions.hairdresserEditBooking(params))
                .then(function (response) {
                    console.log('booking response', response);
                    if (response.data.errorCode) {
                        self.setState({
                            bookingError: response.data.messages
                        })
                    } else {
                        //console.log('booking response self.props', self.props);
                        //self.getHairdresserScheduleList(bookingInfo.hairdresserId,self.state.staff,self.state.userIndex);
                    }
                })

    }

    // new api replace with editbooking api 
    editDairyBooking=(selectedServiceObj={},bookingInfo,bookingSlot)=> {
      let self = this;
          let params = {};
          
         // console.log('___booking', bookingInfo.servicesBooked);

          let processingTimeData = _.find( bookingInfo.servicesBooked,(item)=>item?.processingTime!=null&&item?.processingTime>0);

          if(processingTimeData!=undefined){
            params.isProcessingTime = 0;
          }else{
            params.isProcessingTime = 1;
          }

          params.servicesToBook = _.map(bookingInfo.servicesBooked, function (o) {

                            if(selectedServiceObj?.serviceNum===2){
                              if(o.service._id!==selectedServiceObj?.services.service._id){
                                o.processingTime =selectedServiceObj?.services.processingTime;
                              }
                            
                              if(o.service._id===selectedServiceObj?.services.service._id){
                                 o.serviceTime=selectedServiceObj?.services.serviceTime
                                 
                              }
                            }

                            if(selectedServiceObj?.serviceNum===1){
                              if(o.service._id===selectedServiceObj?.services.service._id){
                                o.processingTime =selectedServiceObj?.services.processingTime;
                                o.serviceTime=selectedServiceObj?.services.serviceTime
                              }
                            }

                     
              return {...o,
                  id:o?.service?.serviceId!=undefined?o?.service?.serviceId:o?.service?._id,
                  _id:o?.service?.serviceId!=undefined?o?.service?.serviceId:o?.service?._id,
                  serviceId:o?.service?.serviceId!=undefined?o?.service?.serviceId:o?.service?._id,
                  price:parseFloat(o?.service?.servicePrice!=undefined?o?.servicePrice:o?.service?.servicePrice)};
          })

            params.bookingSlot = bookingSlot;
            params.serviceNum = selectedServiceObj?.serviceNum?selectedServiceObj?.serviceNum:1;
    
          params.bookingID = bookingInfo._id;
          if(selectedServiceObj?.serviceNum===2 || selectedServiceObj?.serviceNum===1){
          self.props.loadProcessingTime(true);
          }
          self.props.dispatch(bookingActions.editDairyBooking(params))
              .then(function (response) {
                  console.log('booking response', response);
                  if(selectedServiceObj?.serviceNum===2 || selectedServiceObj?.serviceNum===1){
                    //self.props.loadProcessingTime(false);
                    }
                  if (response.data.errorCode) {
                      self.setState({
                          bookingError: response.data.messages
                      })
                  } else {
                      //console.log('booking response self.props', self.props);
                      //self.getHairdresserScheduleList(bookingInfo.hairdresserId,self.state.staff,self.state.userIndex);
                  }
                  self.getHairdresserScheduleList(bookingInfo.hairdresserId,self.state.staff,self.state.userIndex).then((res)=>{
                      self.props.loadProcessingTime(false);
                  })
              }).catch((error)=>{
                self.props.loadProcessingTime(false);
              })

  }

    editEvent=(eventInfo,startTime,endTime)=>{
        let self = this;
        let params = {
            'eventId':eventInfo._id,
            'eventTitle': eventInfo.title,
            'eventDate': this.props.bookingDate,
            'eventStartTime': startTime,
            'eventEndTime': endTime,
            'isBookingAllowed': eventInfo.isBookingAllowed?1:0,
            'hairdresserId': eventInfo.userId,
            'deviceToken': "dfdsfdsfsd",
            'repeatType': "doesNotRepeat"   //'doesNotRepeat', 'everyDay', 'everyWeek', 'everyMonth', 'everyYear'
        }
        self.props.dispatch(eventActions.editEvent(params))
            .then(function (response) {
                console.log('response',response);
                if (response.data.errorCode) {
                    self.setState({
                        eventError: response.data.messages
                    })
                } else {

                }
            })
    }

    getHairdresserScheduleList = (hairdresserId, hairdresser, i) => {
        let self = this;
        let params = `${hairdresserId}/calendar?calendarDate=${this.state.bookingDate}&deviceToken=fedfvcfdvfdvfdvfgvfgv`;
        return new promise(function (resolve, reject) {
            self.props.dispatch(hairdresserActions.getHairdresserSchedule(params, hairdresser, i))
                .then(function (result) {
                    resolve(result.data.data);
                })
        })
    }

    gotoEvent=(selectedEvent, hairdresser)=> {
        let self = this;
        //console.log("selectedEvent",selectedEvent,schedule);
        selectedEvent.hairdresser = hairdresser;
        self.props.dispatch(eventActions.viewEvent(selectedEvent))
            .then(function (response) {
                // console.log("response",response);
                self.props.history.push('/event');
            })
    }

  handleEvents = (events) => {
    // console.log('event handal',events);
    this.setState({
      currentEvents: events
    })
  }

}

function renderEventContent(eventInfo) {
    // console.log("eventInfo",eventInfo);
    if(eventInfo.event.extendedProps.type=='booking') {
        let totalPaidAmount = 0;
        let i=eventInfo.event.id
        let totalTime = 0, totalAmount = 0;
        let item = eventInfo.event.extendedProps.info;
        // console.log("item?.list[0]?.info?.stripePaymentMethodId",item.list[0].info._id,item?.list[0]?.info?.stripePaymentMethodId)
        
       let _transformedService = item.list[0].info.servicesBooked.map((services, i) => {
            totalTime = totalTime + services.serviceTime;
            totalAmount = totalAmount + parseFloat(services.servicePrice);
            services.bookingId = item.list[0].info?._id;
            services.serviceIndex = i;
            services.serviceId = services.service?.id;
            return services;
        })

        item.list[0].info.servicesBooked = _transformedService;

        if (item.list[0].info.transaction && item.list[0].info.transaction.length > 0) {
            item.list[0].info.transaction.map((transaction, i) => {
                totalPaidAmount = totalPaidAmount + parseFloat(transaction.amount)
            })
        }
        if (item.list[0].info.transactions && item.list[0].info.transactions.length > 0) {
            item.list[0].info.transactions.map((transaction, i) => {
                totalPaidAmount = totalPaidAmount + parseFloat(transaction.pgTransactionAmount);
            })
        }
        // let pendingAmount = totalAmount - totalPaidAmount;
        let bookingStatus = eventInfo?.event?.extendedProps?.info?.list[0]?.info?.bookingStatus;

        // to show complete booking if booking status 4 
  
        return (
            <>
                <div
                    className={`booking_card bkng-crd-dary-new ${bookingStatus == 4||bookingStatus == 6 ? 'complete-booking' : ''} ${(item.list[0].endTime - item.list[0].startTime) > 30 ? '' : 'cm_wrap_text'}`}
                    style={ item.list[0].info.isServiceProcessingTime===1?{background:"#C36464"}:{background: colors[i % 3]}}>
                    <h5>
                    {/*{(((item.list[0].endTime - item.list[0].startTime) < 30 )&& ((item?.list[0]?.info?.stripePaymentMethodId!='') && (item?.list[0]?.info?.stripePaymentMethodId!==undefined))) ?*/}
                    {/*  <span className='_card_icon_sm'><CardIcon/></span>:""*/}
                    {/*  // <BlockIcon/> blocksvgicon*/}
                    {/*  }*/}
                        {(((item.list[0].endTime - item.list[0].startTime) < 30 )&& (item?.list[0]?.info?.bookingStatus===6)) ?
                            <span className='_card_icon_sm'><BlockIcon/></span>:(((item.list[0].endTime - item.list[0].startTime) < 30 )&& ((item?.list[0]?.info?.stripePaymentMethodId!='') && (item?.list[0]?.info?.stripePaymentMethodId!==undefined))) ?
                                <span className='_card_icon_sm'><CardIcon/></span>:""
                            //  blocksvgicon
                        }
                      <span className='MobView'>{item.list[0].info.user ? item.list[0].info.user.name : ""}</span> {getServiceName(item)} <span className="mbv-none">with {item.list[0].info.user ? item.list[0].info.user.name : ""}</span>
                    </h5>
                    {(item.list[0].endTime - item.list[0].startTime) > 30&&item.list[0].info.user && item.list[0].info.user._id!=="64f5c7789a069cda0188fea3" ?
                        <img
                            src={item.list[0].info.user && item.list[0].info.user.avatar != "" ? item.list[0].info.user.avatar : require("../../assets/images/Pixie.png")}/>
                        : ""}
                       <p className={`${(((item?.list[0]?.info?.stripePaymentMethodId!='') && (item?.list[0]?.info?.stripePaymentMethodId!==undefined))||(item?.list[0]?.info?.bookingStatus===6))?"cm_sdule_time booking_capture_card_label":"cm_sdule_time"}
                        ${(((item?.list[0]?.info?.stripePaymentMethodId!==undefined)||(item?.list[0]?.info?.bookingStatus===6)) && (item.list[0].endTime - item.list[0].startTime) >= 30) ? '_p__full':''} `}>
                       
                       {/*{(((item.list[0].endTime - item.list[0].startTime) > 30) && (item?.list[0]?.info?.stripePaymentMethodId!='') && (item?.list[0]?.info?.stripePaymentMethodId!==undefined))?*/}
                       {/* <span className='_card_icon'><CardIcon/> </span>:null }*/}
                           {(((item.list[0].endTime - item.list[0].startTime) >= 30) && (item?.list[0]?.info?.bookingStatus===6))?
                               <span className='_card_icon'><BlockIcon/> </span>:((((item.list[0].endTime - item.list[0].startTime) >= 30) && ((item?.list[0]?.info?.stripePaymentMethodId!=='') && (item?.list[0]?.info?.stripePaymentMethodId!==undefined)))?
                                   <span className='_card_icon'><CardIcon/> </span>:"") }
                       <span>{getslotTime(item.list[0].startTime)}-{getslotTime(item.list[0].endTime)}</span></p>
                </div>
            </>
        )
    }
    if(eventInfo.event.extendedProps.type=='event') {
        let item = eventInfo.event.extendedProps.info;
        let i=eventInfo.event.id;
        return (
            <div className="booking_card pointer-event bkng-crd-dary-new black-event" style={{ background: colors[i % 3] }} >
                <h5>{item.list[0].info.title}</h5>
                <p className="cm_sdule_time">{getslotTime(item.list[0].startTime)}-{getslotTime(item.list[0].endTime)}</p>
            </div>
        )
    }
    if(eventInfo.event.extendedProps.type=='break') {
        let j=eventInfo.event.id;
        return (
            <div className="booking_card pointer-event bkng-crd-dary-new" style={{ background: colors[j % 3] }}>
                <h5>Break</h5>
                <p className="cm_sdule_time">{getslotTime(eventInfo.event.extendedProps.info.startTime)}-{getslotTime(eventInfo.event.extendedProps.info.endTime)}</p>
            </div>
        )
    }
}

function renderSidebarEvent(event) {
  return (
    <li key={event.id}>
      <b>{formatDate(event.start, {year: 'numeric', month: 'short', day: 'numeric'})}</b>
      <i>{event.title}</i>
    </li>
  )
}

 export default withRouter(CalenderModule);
