import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import SideNav from "../template/SideNav";
import TopHeader from "../template/TopHeader";
import { connect } from "react-redux";
import {
  bookingActions,
  hairdresserActions,
  productAction,
  paymentActions,
  userActions,
} from "../../_actions";
import siteSetting from "../../config/env";
import dateFormat from "dateformat";
import toast from "react-toast-notification";
import animationData from "../../assets/animation-data/loader.json";
import { ServiceCheckout } from "../newBooking/ServiceCheckout";
import ClientCheckout from "./ClientCheckout";


// selectHairdresserBookingDate,
 // selectHairdresserBookingTime

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

var _ = require("lodash");
const isMobile = window.innerWidth <= 768;
let userIdHolder = [];
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function getslotTime(time) {
  let hr = parseInt(time / 60);
  let min = time - hr * 60;
  return hr + ":" + (min === 0 ? "00" : min);
}

function debounce(func, delay) {
  let timer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(context, args), delay);
  };
}

class NewEditBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingDate: new Date(),
      // this.props.location.state&&this.props.location.state.bookingDate?new Date(this.props.location.state.bookingDate):
      selectSlot: this.props.location.state ? this.props.location.state.start : "",
      showOptionsModal: false,
      isSelected: false,
      bookingType:'past',
      showTipModal: false,
      latitude: siteSetting.default_latitude,
      longitude: siteSetting.default_longitude,
      //hairdresserName: "Select Staff Member",
      hairdresserName:"",
      selectedService: [],
      finalBookingSlots: [],
      includeTimes: [],
      includeEndTimes:[],
      selectEndTimeSlot:'',
      slotInfo: {},
      totalAmount: 0,
      totalTime: 0,
      isMailOff:0,
      durationErrorMessage:'Duration must be a multiple of 5.',
      //hairdresserId: this.props.match.params.hairdresserId ? this.props.match.params.hairdresserId : "",
      hairdresserId:"",
      selectedClientId: "",
      selectedClient: {},
      isSaveBooking: 0,
      bookingSlots: [],
      paymentType: "",
      isBookingAllowed: false,
      searchKey: "",
      pendingAmount: 0,
      transaction: [],
      paymentAmount: 0,
      totalPaymentAmount: 0,
      isInvoice: 0,
      durationError:'',
      eventError: "",
      startTimeError: "",
      serviceError: "",
      bookingError: "",
      eventStartTime: 0,
      eventEndTime: 0,
      eventTitle: "",
      eventTitleError: "",
      hairdresserIdEventError: "",
      currencySymbol: "£",
      isSelectClient: false,
      mobileSelectedStep: 0,
      selectedPaymentType: null,
      selectedAmount: "",
      checkOutAmountType: true,
      selectedFixedAmount: 0,
      isPaymentComplete: 0,
      bookingID: "",
      isMobileEnableState: true,
      locationId: "",
      selectedCardReader: {},
      bookingSelectedItems: [],
      staffList: [],
      productList: [],
      serviceList: [],
      selectedServices: [{}],
      allServices: [],
      selectedStaff: null,
      pageStatus:0,
      bookings:{},
      bookingResSlots:{}
    };

    this.editBooking = this.editBooking.bind(this);
    this.bookingSlotCreate = this.bookingSlotCreate.bind(this);
    this.selectTimeSlot = this.selectTimeSlot.bind(this);
    this.changeHairdresser = this.changeHairdresser.bind(this);
    this.setSelectedService = this.setSelectedService.bind(this);
    this.selectDay = this.selectDay.bind(this);
    this.resetBooking = this.resetBooking.bind(this);
    this.checkoutBooking = this.checkoutBooking.bind(this);
    this.addServiceItem = this.addServiceItem.bind(this);
    this.selectEndTime= this.selectEndTime.bind(this);
    this.changeServiceDuration = this.changeServiceDuration.bind(this);
    this.updateEmailBookingStatus = this.updateEmailBookingStatus.bind(this);
  }

  handleOptionModal = () =>
    this.setState({ showOptionsModal: !this.state.showOptionsModal });

    componentDidMount() {
      var self = this;
      let pageLoading = 0;
      let params = {
        defaultRedirectTo: localStorage.getItem("defaultRedirectTo"),
      };

      const { state } = this.props.location;
    
      if (state) {
      const { pageLoadingStatus } = state;
          pageLoading =pageLoadingStatus;

       
         
        if(pageLoadingStatus===1){
          // all redux clear here
          self.props.dispatch(hairdresserActions.selectedService({}));
          self.props.dispatch(bookingActions?.hairdresserNewBookingData([]));
          self.props.dispatch(bookingActions?.hairdresserNewBookingClient({})
          );
          self.props.dispatch(bookingActions?.selectHairdresserBookingDate({}));
          self.props.dispatch(bookingActions?.clientBookingSlots({}))
          self.props.dispatch(bookingActions?.hairdresserNewBookingStaff({}));
        } 
      }
     

 

      //self.setSelectedService();
      let selectedHairdresserId = self?.props?.hairdresserNewBookingStaff?._id!==undefined?self?.props?.hairdresserNewBookingStaff?._id:localStorage.getItem("userId");
      console.log('hairdresesser',selectedHairdresserId);
      let params1 = {
        searchKey: this.state.searchKey,
        hairdresserId: selectedHairdresserId,
        page: 1,
        listType: 1,
      };
  
      let selectedHairdresserService = [];
      if (
        localStorage.getItem("accessToken") &&
        this.props.match.params.bookingId !== ""
      ) {
        self.props
          .dispatch(userActions.profile(params))
          .then(function (response) {
            //console.log("response",response.data.data);
            if (response.data.data) {
              self.setState({
                locationId: response?.data?.data?.userInfo?.hairdresser?.stripeLocationId ? response?.data?.data?.userInfo?.hairdresser?.stripeLocationId : "",
              });
            }
          });
        if(self.props.match.params.bookingId!==undefined){
          let bookingParams = {
            bookingID: self.props.match.params.bookingId,
            getBookingsOfType: "updates",
          };
          self.props.dispatch(hairdresserActions.hairdresserStaffRota(params1))
              .then(function (response) {
                let staffData = response?.data?.data?.rota!=undefined?response?.data?.data?.rota:[];
                if(staffData?.length>0){
                  const staffOptionList =  staffData?.map(staff=>{
                    return {value:staff?._id,label:staff?.name}
                  })
                  self.setState({
                    staffList:staffOptionList,
                    //selectedStaff:_selectedStaff,
                  })
                }
              })

          self.props.dispatch(bookingActions?.getHairdresserBooking(bookingParams))
          .then(function (response) {
            let servicesBooked = [];
            let totalTime = 0;
            
            const { bookingDate, user, userId,hairdresser, hairdresserId,bookingSlot} = response?.data?.data?.bookings[0];
            totalTime = bookingSlot[1]-bookingSlot[0];
            const _selectedStaff = {_id:hairdresserId,name:hairdresser?.name};
            
            self.setState({
              bookingDate:new Date(bookingDate),
              newStartTime: bookingDate,
              bookingResSlots:response?.data?.data?.bookings[0]?.bookingSlot[0],
              slotInfo: {
                  startTime:response?.data?.data?.bookings[0]?.bookingSlot[0]
                },
              //selectedClient: user,
              selectedClientId: userId,
              // selectedStaff:_selectedStaff,
              bookings:response?.data?.data?.bookings[0],
              selectedClient:response?.data?.data?.bookings[0]?.user
            });

           
            
            let  totalAmount = 0;
            let bookingData = response?.data?.data?.bookings[0];
            let _selectedProductAndService = bookingData?.servicesBooked;
         
            let propBookingData = self?.props?.hairdresserNewBookingData?.length>0&&self?.props?.hairdresserNewBookingData.serviceId?self?.props?.hairdresserNewBookingData:_selectedProductAndService;
           
           
         //   self.props.dispatch(bookingActions?.selectHairdresserBookingDate({}));
            //console.log('propBookingData',propBookingData,self?.props?.hairdresserNewBookingData)
            let booked_services =  propBookingData?.map((services, i) => {
              let _serviceId = services?.service?.serviceId==undefined?services?.service?.id:services?.service?.serviceId;
              // totalTime=totalTime+services.serviceTime;
              totalAmount=totalAmount+parseFloat(services?.servicePrice);
              services.servicePrice= services?.servicePrice;
              services.serviceId=_serviceId;
              services.serviceName=services?.service?.serviceName
              servicesBooked.push(services)
              services.type = "service";
              services.indexId = i + 1;
              selectedHairdresserService.push(_serviceId)
              return services;
          })

            let incTimeSlot = new Date(bookingData.bookingDate);
            // let newDateTime = bookingData.bookingDate;
            let hr = parseInt(bookingData.bookingSlot[0] / 60);
            let min = bookingData.bookingSlot[0] - hr * 60;
            incTimeSlot.setHours(hr);
            incTimeSlot.setMinutes(min);
            console.log('check dddddd',response?.data?.data?.bookings[0]?.bookingSlot[0],bookingData.bookingSlot[0],incTimeSlot,pageLoading);
          if(pageLoading===1){
            self.setState({
              slotInfo: {
                startTime: bookingData.bookingSlot[0],
                endTime: bookingData.bookingSlot[1],
              },
              selectSlot: incTimeSlot,
              totalTime: totalTime,
              bookingSelectedItems:booked_services,
              hairdresserId: bookingData.hairdresserId,
              hairdresserName: bookingData.hairdresser.name,
              selectedStaff:_selectedStaff,
            },function (){
              self.selectEndTime(incTimeSlot,totalTime,'duration');
            })
            self.props.dispatch(bookingActions?.hairdresserNewBookingData(booked_services));
            self.selectDay(self.state.bookingDate);
            // console.log('check time',incTimeSlot,totalTime)

            self.props.dispatch(bookingActions?.hairdresserNewBookingStaff({_id:bookingData.hairdresserId,name:bookingData.hairdresser.name}));
          }
            self.serviceHairdresserList(
              bookingData?.salonId,
              selectedHairdresserService
            );
            let totalPaidAmount = 0;
            if (bookingData?.transaction && bookingData?.transaction?.length > 0) {
              bookingData.transaction.map((transaction, i) => {
                if (transaction.paymentType !== 5) {
                  totalPaidAmount =
                    totalPaidAmount + parseFloat(transaction.amount);
                }
              });
            }
            if (bookingData.transactions && bookingData.transactions.length > 0) {
              bookingData.transactions.map((transaction, i) => {
                totalPaidAmount =
                  totalPaidAmount + parseFloat(transaction.pgTransactionAmount);
              });
            }
            let pendingAmount = totalAmount - totalPaidAmount;
            let paymentAmount = pendingAmount;
            if (pendingAmount > 0) {
              self.setState({
                isServiceEdit: true,
              });
            }
  
            let totalPaymentAmount = totalPaidAmount;

  
            let slotInfoData = {
              startTime: bookingData.bookingSlot[0],
              endTime: bookingData.bookingSlot[1],
            }
  
            self.props.dispatch(bookingActions?.clientBookingSlots(slotInfoData));
            self.setState(
              {
                // slotInfo: {
                //   startTime: bookingData.bookingSlot[0],
                //   endTime: bookingData.bookingSlot[1],
                // },
                checkoutSelectedItems: _selectedProductAndService,
                salonId: bookingData.salonId,
                selectSlot: incTimeSlot,
                newStartTime: incTimeSlot,
                transaction: bookingData.transaction,
                bookingId: self.props.match.params?.bookingId,
                bookingDate: new Date(bookingData.bookingDate),
                selectedService: servicesBooked,
                totalAmount: totalAmount.toFixed(2),
                //totalTime: totalTime,
                pendingAmount: pendingAmount,
                paymentAmount,
                totalPaymentAmount,
                transactions: bookingData.transactions,
                oldTransaction: bookingData.transaction,
                // hairdresserId: bookingData.hairdresserId,
                // hairdresserName: bookingData.hairdresser.name,
                userId: bookingData.userId,
                currencySymbol:
                  bookingData.hairdresser.hairdresser &&
                  bookingData.hairdresser.hairdresser.currencySymbol &&
                  bookingData.hairdresser.hairdresser.currencySymbol !== "" &&
                  bookingData.hairdresser.hairdresser.currencySymbol !==
                    "undefined"
                    ? bookingData.hairdresser.hairdresser.currencySymbol
                    : "£",
              },
              function () {
                self.selectedHairdresser(
                  self.state.hairdresserId,
                  bookingData.hairdresser.name,
                  1,
                  bookingData.hairdresser
                );
                // self.props.dispatch(bookingActions?.hairdresserNewBookingData(booked_services));
                // self.selectDay(self.state.bookingDate);
                //self.selectEndTime(incTimeSlot,totalTime)
              }
            );
            self.props.dispatch(
              hairdresserActions.getHairdresserClientDetail({
                userId: bookingData.userId,
                hairdresserId: bookingData.hairdresserId,
                bookingType: self.state.bookingType,
              })
            );
          });
        }
          self.setSelectedCheckoutData();
          
      } else {
        this.props.history.push("/");
      }
  
      let { hairdresser, hairdresserBooking } = self.props;
      
      if (
        hairdresserBooking?.bookings &&
        hairdresserBooking?.bookings?.length > 0
      ) {
        let servicesData = [];
        let hairdresserServices = hairdresser?.hairdresserServices;
        for (let i = 0; i < hairdresserServices?.length; i++) {
          let category = hairdresserServices[i].hairdresserService;
          for (let j = 0; j < category.length; j++) {
            servicesData.push(category[j]);
          }
        }
  
        this.setState({
          allServices: servicesData,
        });
      }
    }

    componentDidUpdate(prevProps, prevState) {
      const { hairdresserNewBookingStaff, hairdresserNewBookingData } = this.props;
    
      if (hairdresserNewBookingStaff?.value !== undefined && prevProps.hairdresserNewBookingStaff !== hairdresserNewBookingStaff) {
        this.setState({
          selectedStaff: hairdresserNewBookingStaff,
        });
      }
    
    }


  changeHairdresser(hairdresserId, hairdresserName, hairdresser) {
    //need to blank selected service aaray if param hairdresser is undefined
    let params = {};
    let self = this;
    const {dispatch} = this.props?.dispatch;
    if(hairdresserId) {
      if (hairdresser === undefined) {
        console.log('checkRRRR',hairdresserId, hairdresserName, hairdresser)
        const newService = {
          id: 1,
          indexId: 1,
          type: "service",
        };
        self.setState({
          bookingSelectedItems: [newService],
        });
      }

      self.setState({
        isSelected: true,
        isLoaderEnabled: true,
        startTimeError: "",
        serviceError: "",
        hairdresserId: hairdresserId,
        hairdresserName,
        totalAmount:0,
        totalTime:0,
        selectedStaff: {_id: hairdresserId, name: hairdresserName},
      }, function () {
        console.log("statefffff", this.state, '+++', hairdresserId, "---", self.state.hairdresserId);
      });

      let _bookingDate = self.props?.selectHairdresserBookingDate?.date !== undefined ? self.props?.selectHairdresserBookingDate?.date : self.state.bookingDate;


      params.hairdresserId = hairdresserId;
      params.latitude = this.state.latitude;
      params.longitude = this.state.longitude;
      params.slotsForBookingDate = dateFormat(
          _bookingDate,
          "yyyy-mm-dd"
      );

      self.props
          .dispatch(hairdresserActions.getHairdresserInfo(params))
          .then(function (response) {
            if (response.data.errorCode) {
              self.setState({
                isLoaderEnabled: false,
                error: response.data.error.messages,
              });
            } else {
              self.setState(
                  {
                    isLoaderEnabled: false,
                    bookingSlots: response.data.data.bookingSlots,
                    // date: new Date(),
                    selectedService: [],
                    isBookingButton: false,
                    selectedDay: new Date(),
                    month: monthNames[new Date().getMonth()],
                    year: new Date().getFullYear(),
                    selectedBookingSlots: "",
                    selectedBookingSlotsIndex: "",
                    finalBookingSlots: [],
                    includeTimes: [],
                    includeEndTimes:[],
                    pendingAmount: 0,
                    transaction: [],
                    paymentAmount: 0,
                    totalPaymentAmount: 0,
                    currencySymbol:
                        hairdresser?.hairdresserInfo?.currencySymbol &&
                        hairdresser?.hairdresserInfo?.currencySymbol != "" &&
                        hairdresser?.hairdresserInfo?.currencySymbol != "undefined"
                            ? hairdresser?.hairdresserInfo?.currencySymbol
                            : "£",
                  },
                  function () {
                    self.bookingSlotCreate(0);

                  }
              );
            }
          });
    }
  }

  checkSelectedService(serviceId) {
    let selectedService = this.state.selectedService;
    let isCheck = _.find(selectedService, function (o) {
      return o._id === serviceId;
    });
    if (isCheck) {
      return true;
    } else {
      return false;
    }
  }


bookingSlotCreate(index) {
  //console.log("bookingSlotCreate",index);
  this.setState({
      startTimeError:'',
      serviceError:''
  })
  let self = this;
  let finalBookingSlots = [];
  let includeTimes = [];
  let pre;
  let next;
  let tempArr = []
  let flag = false;
  let k = index;
  let arrslots = [];
  let bookingSlots = self.state.bookingSlots;
  //bookingSlots.map(function(slots, i) {
  for (let i = index; i < bookingSlots.length; i++) {
      let check = false;
    
      if (bookingSlots[i].status === 0||bookingSlots[i].status === 3) {
          tempArr.push(bookingSlots[i])
          // console.log('tempArr', tempArr,bookingSlots[i]);
          let _totalSeviceTime = self.state.totalTime==0?5:isNaN(self.state.totalTime)?5:self.state.totalTime;
          if (((tempArr[tempArr.length - 1]["endTime"]) - (tempArr[0]["startTime"])) ===_totalSeviceTime) {
              finalBookingSlots.push(tempArr[0]);

              let hr = parseInt(tempArr[0].startTime / 60);
              let min = tempArr[0].startTime - (hr * 60);

              let incTimeSlot = new Date(self.state.bookingDate);
              //console.log("incTimeSlot",incTimeSlot);
              incTimeSlot.setHours(hr);
              incTimeSlot.setMinutes(min);


              includeTimes.push(incTimeSlot);
              //console.log("tempArr[0]",incTimeSlot,includeTimes)

              for (let m = 0; m < bookingSlots.length; m++) {
                  if (tempArr[0]["startTime"] === bookingSlots[m]["startTime"]) {
                      k = m + 1;
                      flag = true;
                      break;
                  }
              }
              tempArr = []
          }
      } else {
          tempArr = []
      }

      if (flag === true) {
          break
      }
  }

  if (flag && k < bookingSlots.length) {
      self.bookingSlotCreate(k)
  }
  if (finalBookingSlots[0]) {
      let finalslot = this.state.finalBookingSlots;
      finalslot.unshift(finalBookingSlots[0]);

      let incTimes = this.state.includeTimes;
      //console.log('incTimes', incTimes);
      incTimes.unshift(includeTimes[0]);
      // console.log('finalslot', finalslot);
      let morningCount = 0;
      let afternoonCount = 0;
      let eveningCount = 0;
      for (let i = 0; i < finalslot.length; i++) {
          let bookingSlot = finalslot[i];
          let bookingIncTimes = incTimes[i];
          if (bookingSlot) {
              if (bookingSlot.status === 0 && bookingSlot.startTime < 720) {
                  morningCount = 1
              }
              if (bookingSlot.status === 0 && (bookingSlot.startTime >= 720 && bookingSlot.startTime < 1020)) {
                  afternoonCount = 1
              }
              if (bookingSlot.status === 0 && bookingSlot.startTime >= 1020) {
                  eveningCount = 1
              }
          }

      }
     
      let selectedSlot = _.find(finalslot, function (slot) {
          let incTimeSlot = new Date(self.state.selectSlot);
       
          let hr =incTimeSlot.getHours();
          let min =incTimeSlot.getMinutes();
          return slot.startTime === hr*60+min;
      })
     // console.log("selectedSlot",selectedSlot,incTimes[0]);
      // if(!selectedSlot){
      //     selectedSlot=finalslot[0];
      //     self.setState({
      //         selectSlot: self.state.selectSlot?self.state.selectSlot:incTimes[0]
      //     })
      // }
    if(!selectedSlot){
      if(self.state.selectSlot&&self.state.selectSlot!=="") {
        let incTimeSlot = new Date(self.state.selectSlot);

        let hr = incTimeSlot.getHours();
        let min = incTimeSlot.getMinutes();
        selectedSlot = {
          startTime: hr * 60 + min,
          endTime: hr * 60 + min + 5,
          list: [{startTime: hr * 60 + min, endTime: hr * 60 + min + 5, info: {}, type: ""}]
        };
        self.setState({
          selectSlot: self.state.selectSlot ? self.state.selectSlot : incTimes[0]
        })
      }else{
        selectedSlot=finalslot[0];
        self.setState({
          selectSlot: self.state.selectSlot ? self.state.selectSlot : incTimes[0]
        })
      }
    }
     
      //console.log('check dddddd',selectedSlot)
      this.setState({
          finalBookingSlots: finalslot,
          includeTimes: incTimes,
          includeEndTimes:incTimes,
          morningCount,
          afternoonCount,
          eveningCount,
          slotInfo: selectedSlot,
          //selectSlot: incTimes[0]
      })
  }
}

  selectDay = (e) => {
    this.setState({
      startTimeError: "",
      serviceError: "",
    });
    console.log("selectDay", e);
    this.state.bookingDate = e;
    this.state.selectedDay = e;
    //console.log(this.state.bookingDate)
    //this.setState({bookingDate:e[0]})
    let params = {};
    let self = this;
    let s_date = new Date(e);
    self.props.dispatch(bookingActions?.selectHairdresserBookingDate({date:e}));
    self.setState({
      bookingDate: e,
      month: monthNames[s_date.getMonth()],
      year: s_date.getFullYear(),
    });
    let salonId = this.props.match.params.salonId;
    let hairdresserId = this.state.hairdresserId ? this.state.hairdresserId : localStorage.getItem("userId");
    params.hairdresserId = hairdresserId;
    params.latitude = this.state.latitude;
    params.longitude = this.state.longitude;
    params.slotsForBookingDate = dateFormat(e, "yyyy-mm-dd");
    self.props
      .dispatch(hairdresserActions.getHairdresserInfo(params))
      .then(function (response) {
        if (response.data.error) {
          self.setState({
            message: response.data.error.message,
          });
        } else {
          // console.log(response);
          self.setState(
            {
              bookingSlots: response.data.data.bookingSlots,
              selectedBookingSlotsIndex: "",
              finalBookingSlots: [],
              includeTimes: [],
              includeEndTimes:[]
            },
            function () {
              self.bookingSlotCreate(0);
            }
          );
        }
      });
  };

  unselectDay(e) {
    console.log("unselectDay", e);
  }

  getslotTime(time) {
    let hr = parseInt(time / 60);
    let min = time - hr * 60;
    return (
      (hr <= 9 ? "0" + hr : hr) +
      ":" +
      (min == 0 ? "00" : min <= 9 ? "0" + min : min) +
      ":00"
    );
  }

  selectTimeSlot(e) {
    this.setState({
        startTimeError:'',
        serviceError:''
    })

    console.log('cheddddddd',e);
    let self = this;
    this.setState({
        finalBookingSlots: [],
        includeTimes: [],
        includeEndTimes:[]
    }, function () {
        self.bookingSlotCreate(0);
        //console.log('slotInfo,index',slotInfo,index,isSelect);
        let includeTimes = self.state.includeTimes;
        let finalBookingSlots = self.state.finalBookingSlots;
        let totalTime = self.state.totalTime;

        _.remove(includeTimes, function (o) {
            return o <= moment(e).add(totalTime, 'm').toDate() && o > e
        });

        let m = moment(e);
        let minutes = (m.hour() * 60) + m.minute();
        let slotInfo = _.find(finalBookingSlots, function (o) {
            return o.startTime === minutes
        })
        self.setState({
            slotInfo: slotInfo,
            includeTimes,
            includeEndTimes:includeTimes,
            selectSlot: e,
            endTimeSlot: e,
        })

    })
}

  checkReadOnly = () => {
    if (
      this.props.user &&
      this.props.user.userInfo &&
      this.props.user.userInfo.hairdresser.isSalonOwner === 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  updateDuration = (event) => {
    const inputTime = event.target.value;
    const duration = parseInt(inputTime, 10);

    if (!isNaN(duration)) {
      this.setState({
        totalTime: duration,
      });
    }
  };

  calculateEndTime = () => {
    let endTime = new Date(this.state.selectSlot);
    endTime.setMinutes(endTime.getMinutes() + this.state.totalTime);
    let date = new Date(endTime.getTime());

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
     
    if(isNaN(hours) && isNaN(hours)){
      return '___';
    }
    return `${hours}:${minutes}`;
  };



  setSelectedCheckoutData() {
    let self = this;
    let selectedStaffData = self?.props?.hairdresserNewBookingStaff;
    // let selectedBookingItems = self?.props?.hairdresserNewBookingData;
    let selectedHairdresserId = self?.props?.match?.params?.hairdresserId!==undefined?self?.props?.match?.params?.hairdresserId:localStorage.getItem("userId");

      self.setState({
        // bookingSelectedItems: selectedBookingItems,
        selectedClient:self.state.selectedClient,
        selectedStaff: selectedStaffData?._id !== undefined ? selectedStaffData : null,
      });

      self.changeHairdresser(selectedStaffData._id,selectedStaffData.name,selectedStaffData);
      let currentDate = new Date(self.state.bookingDate);
      let selectedBookingDate = self?.props?.selectHairdresserBookingDate?.date!==undefined?new Date(self?.props?.selectHairdresserBookingDate?.date):currentDate;
      
    self.selectDay(selectedBookingDate);
    this.setSelectedClient();
    this.setSelectedService();
  }

  setSelectedClient = () => {
    let self = this;
    let selectedClientData = self.props.selectedClient?._id!==undefined?self.props.selectedClient:self.state.selectedClient;
    if (selectedClientData?._id !== undefined) {
      self.props.dispatch(
        bookingActions?.hairdresserNewBookingClient(selectedClientData)
      );
      self.setState({
        selectedClient: selectedClientData,
      });
    }
  };


  removeServiceItem = (type, selectedItemId, index) => {
    let self = this;
    let dispatch = self.props?.dispatch;
    if (type === "service") {
      let selectedService = self.props.hairdresserNewBookingService;
      selectedService.splice(index, 1);
      let updatedProductList = self.state.bookingSelectedItems?.filter(
        (productItem) => productItem?.id !== selectedItemId
      );
      dispatch(bookingActions?.hairdresserNewBookingData(updatedProductList));
      self.setState({
        bookingSelectedItems: updatedProductList,
      });
    }
  };

  resetBooking() {
    let self = this;
    let client = {};
    // self.props.dispatch(
    //   bookingActions?.hairdresserNewBookingClient(client)
    // );
    // self.props.dispatch(productAction.selectedClient(client));
    self.props.dispatch(hairdresserActions.selectedService({}));
    self.props.dispatch(bookingActions?.hairdresserNewBookingData([]));
    this.setState({
        bookingDate: this.props.location.state&&this.props.location.state.bookingDate?new Date(this.props.location.state.bookingDate):new Date(),
        selectedService: [],
        //hairdresserId: this.props.match.params.hairdresserId?this.props.match.params.hairdresserId:'',

    })
}

serviceHairdresserList = (salonId, selectedHairdresserService) => {
  let self = this;
  let hairdresserListParam = {
    salonId: salonId,
    selectedService: selectedHairdresserService,
  };

  self.props
    .dispatch(hairdresserActions.serviceHairdresserList(hairdresserListParam))
    .then(function (response) {
      console.log("response", response);
      if (response.data.errorCode) {
        self.setState({
          error: response.data.error.messages,
        });
      } else {
        self.setState({
          serviceHardresserList: response?.data?.data,
        });
      }
    });
};

selectedHairdresser(hairdresserId, hairdresserName, type, hairdresser) {
  console.log("====",hairdresserId, hairdresserName,type,hairdresser)
  this.setState({ isSelected: true });
  this.setState({
    startTimeError: "",
    serviceError: "",
    hairdresserId,
    hairdresserName,
  });
  // console.log("salonId",salonId);
  //this.props.history.push("/salon/" + salonId + "/professional/" + hairdresserId);
  let params = {};
  let self = this;
  params.hairdresserId = hairdresserId;
  params.latitude = this.state.latitude;
  params.longitude = this.state.longitude;
  params.slotsForBookingDate = dateFormat(this.state.bookingDate, "yyyy-mm-dd");
  //params.history = this.props.history;
  if (type === 1) {
    //self.props.dispatch(hairdresserActions.getHairdresserClient({ history: this.props.history, 'searchKey': this.state.searchKey, 'hairdresserId': hairdresserId, page: 1, listType: 1 }))
  }

  self.props
    .dispatch(hairdresserActions.getHairdresserInfo(params))
    .then(function (response) {
      if (response.data.errorCode) {
        self.setState({
          error: response.data.error.messages,
        });
      } else {
        self.setState(
          {
            currencySymbol:
              hairdresser.hairdresser.currencySymbol &&
              hairdresser.hairdresser.currencySymbol != "" &&
              hairdresser.hairdresser.currencySymbol != "undefined"
                ? hairdresser.hairdresser.currencySymbol
                : "£",
          }
        );
      }
    });
}

editBooking(param) {
  let self = this;
  let checkSelectedService = self?.props?.hairdresserNewBookingData.find(serviceData=>serviceData?.serviceName===undefined);

  if(self.state.totalAmount===0 && self.state.totalTime===0 && checkSelectedService==undefined){
    toast.error(`Service not selected.`);
    return;
  }

  if(self.state.totalTime===0){
    toast.error(`Please enter valid duration.`);
    return;
  }

  if(self.state.durationError!==''){
    return;
  }

  if(isNaN(self.state.totalTime)){
    self.setState({
      durationError:self.state.durationErrorMessage
    })
    return;
  }

  if(self.state.totalTime==='' || self.state.totalTime===0){
    self.setState({
      durationError:self.state.durationErrorMessage
    })
    return;
  }

  if(this?.state?.totalTime===0){
      this.setState({
          bookingError:'Please select service'
      })
  
  }else {
    // bookingDate

      let _bookingDate = self.props?.selectHairdresserBookingDate?.date!==undefined?self.props?.selectHairdresserBookingDate?.date:self.state.bookingDate;
      let params = {};
      params.servicesToBook = _.map(self?.props?.hairdresserNewBookingData, function (o) {
        return {...o,
          id:o?.service?.serviceId!=undefined?o?.service?.serviceId:o?.service?._id,
          _id:o?.service?.serviceId!=undefined?o?.service?.serviceId:o?.service?._id,
          serviceId:o?.service?.serviceId!=undefined?o?.service?.serviceId:o?.service?._id, 
          price:parseFloat(o?.service?.servicePrice!=undefined?o?.servicePrice:o?.service?.servicePrice)};
      });

    
      params.productIds=[]
      //need to fix chenge issue 
      //params.userId = this.state.hairdresserId;
      params.bookingSlot = [this.state?.slotInfo?.startTime, (this.state?.slotInfo?.startTime + parseInt(this.state?.totalTime))]
      params.bookingDate = dateFormat(_bookingDate, 'yyyy-mm-dd')
      params.deviceToken = "dfdsfdsfsd";
      params.isPayAtSalonBooking = 1;
      params.clientId = '';
      params.paidType = self.state.paidType;
      params.hairdresserId = self.state.selectedStaff?._id;
      params.bookingID = self.props.match.params.bookingId;
      params.transaction = this.state.transaction;
      params.isMailOff = this.state.isMailOff;
      params.totalAmount = (this.state.totalAmount!=''&&this.state.totalAmount!=0)?parseFloat(this.state.totalAmount):this.state.totalAmount;
  
      self.props.dispatch(bookingActions.hairdresserEditBooking(params))
          .then(function (response) {
              if (response.data.errorCode) {
                  self.setState({
                      bookingError: response.data.messages
                  })
                  toast.error(response.data.messages);
              } else {
                  // self.props.history.push('/booking/' + self.props.match.params.bookingId)
                  //self.props.history.push('/diary/' + localStorage.getItem('salonId'))
                  let info={
                      bookingDate:self.state.bookingDate
                  };
                 
                  if(param===1){
                      // self.props.history.push('/booking-checkout/'+self.props.match.params.bookingId)
                      const stateObj = { pageLoadingStatus:1};
                      self.props.history.push({
                       pathname: '/checkout/'+self.props.match.params.bookingId,
                       state: stateObj
                     });
                      // self.props.history.push('/checkout/'+self.props.match.params.bookingId)
                  }else {
                       
                      self.props.history.push({pathname: '/diary/' + localStorage.getItem('salonId'), state: info})
                  }
              }
          })
  }
}

checkoutBooking(){
  let self = this;
  if(self.state.durationError!==''){
    return;
  }

  if(isNaN(self.state.totalTime)){
    self.setState({
      durationError:self.state.durationErrorMessage
    })
    return;
  }

  if(self.state.totalTime==='' || self.state.totalTime===0 ){
    self.setState({
      durationError:self.state.durationErrorMessage
    })
    return;
  }

  this.editBooking(1);
  // this.props.history.push('/checkout/'+this.props.match.params.bookingId);
}

addServiceItem() {
  let self = this;

  let data = self.props?.hairdresserNewBookingData?.filter(service=>service?.serviceName==undefined);
    
  if(data?.length>0){
    self.setState({
      serviceSelectionError:`Please select Service ${data[0].indexId} before adding another service`
    })
    return;
  }

    if(self.state.totalTime===''){
      self.setState({
        durationError:self.state.durationErrorMessage
      })
      return;
    }

  if(self.state.durationError!==''){
    return;
  }

  if(isNaN(self.state.totalTime)){
    self.setState({
      durationError:self.state.durationErrorMessage
    })
    return;
  }

  if(self.state.totalTime==='' || self.state.totalTime===0 ){
    self.setState({
      durationError:self.state.durationErrorMessage
    })
    return;
  }

  let dispatch = self.props?.dispatch;
  let _selectedServices = self.props?.hairdresserNewBookingData;
  let lastIndex = _selectedServices.length + 1;

  const newService = {
    _id: lastIndex,
    id: lastIndex,
    indexId: lastIndex,
    type: "service",
  };

  let serviceData = [..._selectedServices, newService];
  dispatch(bookingActions?.hairdresserNewBookingData(serviceData));
  self.setState({
    bookingSelectedItems: serviceData,
  });

}

selectEndTime(e,totalSelectedMin=5,from){
  let self = this;
  let _includeTimes =self.state.includeEndTimes?.length>0?self.state.includeEndTimes:self.state.includeTimes;
  let endTimeData = '';
  if(from==='duration'){
    endTimeData=moment(e).add(totalSelectedMin, 'm').toDate();
  }else{
    endTimeData =e 
  }
  
  let isRelativeTime =0;
  let differenceValue =0;
  let totalDuration=totalSelectedMin;
 
  if(totalSelectedMin===5){
    if(e!==undefined){
      differenceValue =(e.getTime() - this.state.selectSlot.getTime()) / 1000;
      differenceValue /= 60;
      isRelativeTime = Math.round(differenceValue)
      totalDuration = Math.abs(Math.round(differenceValue))>0?Math.abs(Math.round(differenceValue)):totalSelectedMin;
    }
  }

  self.setState({
    includeEndTimes:_includeTimes,
    slotInfo: {
              startTime:self.state.bookingResSlots
        }
  })

  if(isRelativeTime<0){
    totalDuration ='';
  }


 // self.selectTimeSlot(self.state.selectSlot); 
  
  const bookingInfo = {
    selectEndTimeSlot:endTimeData,
    totalTime:totalDuration
    };

  self.props.dispatch(
    bookingActions?.updateBookingInfo(bookingInfo)
  );
   self.setState(bookingInfo)
}


changeServiceDuration(durationTime){
  let self = this;

  if(parseInt(durationTime)%5===0){
    self.setState({
      durationError:''
       })
       self.setState({
        totalTime:parseInt(durationTime)
         });
  }else{
     console.log('checkdddd',durationTime);
    self.setState({
       durationError:self.state.durationErrorMessage
       })   
  }


  self.selectEndTime(self.state.selectSlot,parseInt(durationTime),'duration');
}


setSelectedService() {
  let self = this;
  let dispatch = self.props.dispatch;
  let selectedServiceData = self.props?.selectedService;
  let totalTime =0;
  let totalAmount=0;
  let servicesBooked = [];
  if (selectedServiceData?._id !== undefined) {
    console.log("if",self.props?.hairdresserNewBookingData);
    let updatedList;
      if(self.props?.hairdresserNewBookingData.length > 1) {
        updatedList=self.props?.hairdresserNewBookingData.map((item) => {
          totalTime += isNaN(item.serviceTime) ? selectedServiceData.serviceTime : item.serviceTime;
          totalAmount += isNaN(item.servicePrice) ? selectedServiceData?.servicePrice : item.servicePrice;
          if (item?.indexId === selectedServiceData.indexId) {
            item = {
              ...item,
              ...selectedServiceData,
              service: selectedServiceData,
              id: selectedServiceData?.serviceId,
              _id: selectedServiceData?.serviceId,
              serviceId: selectedServiceData?.serviceId == undefined ? selectedServiceData?._id : selectedServiceData?.serviceId,
            };
          } else {
            item.serviceId = selectedServiceData?.serviceId == undefined ? selectedServiceData?._id : selectedServiceData?.serviceId;
          }
          servicesBooked.push(item)
          return item;
        })
      }else{
        let nServiceData={};
        nServiceData.id=1;
        nServiceData.indexId=1;
        nServiceData.type="service";
        nServiceData.serviceId=selectedServiceData.serviceId;
        nServiceData.serviceName=selectedServiceData.serviceName;
        nServiceData.servicePrice=selectedServiceData.servicePrice;
        nServiceData.serviceTime=selectedServiceData.serviceTime;
        nServiceData.depositPrice=selectedServiceData.depositPrice;
        nServiceData.service=selectedServiceData;
        // console.log("selectedServiceData",nServiceData);
        // console.log("selectedServiceData",selectedServiceData);
        updatedList=[nServiceData];
        totalAmount=selectedServiceData?.servicePrice;
        totalTime=selectedServiceData?.serviceTime;
      }
    self.selectEndTime(self.state.selectSlot,totalTime,'duration');
    self.setState({
      totalAmount,
      totalTime,
      bookingSelectedItems: updatedList,
      selectedService:servicesBooked,
      selectedServiceStatus:0
    },()=>{
      // dispatch(hairdresserActions.selectedService({}));
      dispatch(bookingActions?.hairdresserNewBookingData(updatedList));
    });
  }else{
    console.log("else");
    let servicesBooked = [];
    let serviceData =self.props?.hairdresserNewBookingData.length > 0?self.props?.hairdresserNewBookingData:self.state.bookingSelectedItems;


    let updatedList=  serviceData.map((item) => {
          totalTime += isNaN(item.serviceTime)?selectedServiceData.serviceTime:item.serviceTime;
          totalAmount += isNaN(item.servicePrice)?selectedServiceData?.servicePrice:item.servicePrice;
          if (item?.indexId === selectedServiceData.indexId) {
            item = {
              ...item,
              ...selectedServiceData,
              id: selectedServiceData?.serviceId,
            };
          }
          servicesBooked.push(item)
          return item;
        });


      self.selectEndTime(self.state.selectSlot,totalTime,'duration');
      self.setState({
        totalAmount,
        totalTime,
        bookingSelectedItems: updatedList,
        selectedService:servicesBooked,
        selectedServiceStatus:0
      },()=>{
        dispatch(bookingActions?.hairdresserNewBookingData(updatedList));
      });

  }
}

updateEmailBookingStatus(status){
   let self = this;
   self.setState({
    isMailOff:status
  })
}

onChangeProcessingTime = (data, selectedItemId) => {
  let self = this;
  let dispatch = self.props?.dispatch;
    let updatedProductList = self.state.bookingSelectedItems?.map(
      (productItem) =>{
        if(productItem?.id === selectedItemId){
          productItem.processingTime = data;
        }
        return productItem;
      } 
    );

    console.log('check pppp',updatedProductList,data);
    self.setState({
      durationError:'',
      bookingSelectedItems: updatedProductList,
    });
  
};

  render() {
    console.log("this.state",this.state);
    console.log("this.props",this.props);
    return (
      <div className="h_n">
        <div className="body_container cm_bg_white">
          <TopHeader 
            title="Edit Booking" 
            updateEmailBookingStatus={this.updateEmailBookingStatus}
            editPageStatus={true}
            isMailOff={this.state.isMailOff}
            headerButtonStatus={true}
            saveBookingButton={true}
            checkoutButton={true}
            checkoutBooking={this.checkoutBooking}
            saveBooking={this.editBooking}
          />
          <SideNav {...this.props} />
          <div className="service_flex_div new__edit_page">
            <div className="main_service_div">
              {this.state.bookingSelectedItems.length > 0
                ? this.state.bookingSelectedItems.map((service, i) => (
                    <div className="row" key={"service_" + i}>
                      <ServiceCheckout
                        index={i + 1}
                        removeService={this.removeServiceItem}
                        bookingDate={this.state.bookingDate}
                        //this.props.hairdresserNewBookingData?.bookingDate
                        selectEndTimeSlot={this.state.selectEndTimeSlot}
                        selectEndTime={this.selectEndTime}
                        changeServiceDuration={this.changeServiceDuration}
                        selectSlotFunc={this.selectTimeSlot}
                        includeTimes={this.state.includeTimes}
                        startTimeError={this.state.startTimeError}
                        durationError={this.state.durationError}
                        calculateEndTime={this.calculateEndTime}
                        totalTime={this.state.totalTime}
                        updateDuration={this.updateDuration}
                        selectedTime={this.state.selectSlot}
                        slotInfo={this.state.slotInfo}
                        staffList={this.state.staffList}
                        item={service}
                        propData={this.props}
                        selectedStaff={this.state.selectedStaff}
                        selectedClient={this.state.selectedClient}
                        changeHairdresser={this.changeHairdresser}
                        selectDay={this.selectDay}
                        resetBooking={this.resetBooking}
                        clientStatus={false}
                        finalBookingSlots={this.state.finalBookingSlots}
                        bookingSelectedItems={this.state.bookingSelectedItems}
                        serviceSelectionError={this.state.serviceSelectionError}
                        onChangeProcessingTime={this.onChangeProcessingTime}
                      />
                    </div>
                  ))
                : null}
                
                  <div className="row">
                    <div className="col-sm-12 pl-0">
                      <button
                        type="button"
                        className="btn btn-light b-s-1 mt-4"
                        onClick={this.addServiceItem}
                      >
                        Add Service
                      </button>
                    </div>
                  </div>
            </div>
            
            {(this?.state?.selectedClient?._id!== undefined && this?.state?.hairdresserId!=='')?
            <div className="checkout_page ">
             <ClientCheckout borderr="borderemove" 
                                    selectedClientId={this.state.selectedClientId}
                                    selectedClient={this.state.selectedClient}
                                    changeClient={this.changeClient}
                                    currencySymbol={this.state.currencySymbol}
                                    pendingAmount={this.state.pendingAmount}
                                    checkoutBooking={this.checkoutBooking}
                                    saveBooking={this.editBooking}
                                    userId={this.state.userId}
                                    hairdresserId={this.state.hairdresserId}
                                    bookings={this.state.bookings}
                                    bookingState={this.state}
                                />
            </div>
              :null}

  
          </div>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(NewEditBooking);
