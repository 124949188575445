import React, { Component } from 'react';
import { connect } from "react-redux";

import HomeLeft from './HairdresserLeft';
import HomeRight from './HairdresserRight';

import {hairdresserActions} from '../../_actions/hairdresser.actions'

import siteSetting from '../../config/env'

class HairdresserComponent extends Component{

    constructor(props) {
        super(props);
        this.state = {
            error: {},
            message: '',
            loader: true,
            currentLocation:{},
            latitude: siteSetting.default_latitude,
            longitude:siteSetting.default_longitude,
        };
        this.noLocation=this.noLocation.bind(this);
        this.foundLocation=this.foundLocation.bind(this);
    }

    componentDidMount() {
        // this.noLocation();
        let { dispatch } = this.props;
        let self = this;
        this.noLocation();
        document.body.classList.add('login-home-temp');
        navigator.geolocation.getCurrentPosition(this.foundLocation,this.noLocation)
    }

    foundLocation(location) {
        console.log("foundLocation",location);
        this.setState({
            currentLocation:location.coords,
            latitude: location.coords.latitude?location.coords.latitude:siteSetting.default_latitude,
            longitude:location.coords.longitude?location.coords.longitude:siteSetting.default_longitude,
        })
        let {dispatch} = this.props;
        let self = this;
        let params = {}
        let salonId=this.props.match.params.salonId;
        let hairdresserId=this.props.match.params.hairdresserId;

        if(salonId){
            params.salonId=salonId;
            params.latitude = location.coords.latitude?location.coords.latitude:siteSetting.default_latitude;
            params.longitude = location.coords.longitude?location.coords.longitude:siteSetting.default_longitude;
            self.props.dispatch(hairdresserActions.getSalonProfile(params))
                .then(function (response) {
                    if(!response.data.data.salons){
                        self.props.history.push("/404");
                    }
                    if(!hairdresserId){
                        self.props.history.push('/salon/'+salonId+'/professional/'+response.data.data.salons.hairdresser[0]._id);
                        params.hairdresserId=response.data.data.salons.hairdresser[0]._id;
                        self.props.dispatch(hairdresserActions.getHairdresserInfoWeb(params))
                            .then(function (response) {

                                if (response.data.error) {
                                    self.setState({
                                        message: response.data.error.message,

                                    })
                                } else {
                                }
                            });
                        self.props.dispatch(hairdresserActions.getHairdresserReview(params))
                            .then(function (response) {

                                if (response.data.error) {
                                    self.setState({
                                        message: response.data.error.message
                                    })
                                } else {
                                }
                            });
                    }
                    if (response.data.error) {
                        self.setState({
                            message: response.data.error.message
                        })
                    } else {
                    }
                });
            if (hairdresserId) {
                params.hairdresserId=hairdresserId;
                self.props.dispatch(hairdresserActions.getHairdresserInfoWeb(params))
                    .then(function (response) {

                        if (response.data.error) {
                            self.setState({
                                message: response.data.error.message
                            })
                        } else {

                        }
                    });
                self.props.dispatch(hairdresserActions.getHairdresserReview(params))
                    .then(function (response) {

                        if (response.data.error) {
                            self.setState({
                                message: response.data.error.message
                            })
                        } else {

                        }
                    });
            }
        }else{
            this.props.history.push("/404");
        }
    }

    noLocation() {
        console.log("noLocation");
        let {dispatch} = this.props;
        let self = this;
        let params = {}
        let salonId=this.props.match.params.salonId;
        let hairdresserId=this.props.match.params.hairdresserId;

        if(salonId){
                params.salonId=salonId;
                params.latitude = siteSetting.default_latitude;
                params.longitude = siteSetting.default_longitude;
                self.props.dispatch(hairdresserActions.getSalonProfile(params))
                    .then(function (response) {
                        if(!response.data.data.salons){
                            self.props.history.push("/404");
                        }
                        if(!hairdresserId){
                            self.props.history.push('/salon/'+salonId+'/professional/'+response.data.data.salons.hairdresser[0]._id);
                            params.hairdresserId=response.data.data.salons.hairdresser[0]._id;
                            self.props.dispatch(hairdresserActions.getHairdresserInfoWeb(params))
                                .then(function (response) {

                                    if (response.data.error) {
                                        self.setState({
                                            message: response.data.error.message,

                                        })
                                    } else {
                                    }
                                });
                            self.props.dispatch(hairdresserActions.getHairdresserReview(params))
                                .then(function (response) {

                                    if (response.data.error) {
                                        self.setState({
                                            message: response.data.error.message
                                        })
                                    } else {
                                    }
                                });
                        }
                        if (response.data.error) {
                            self.setState({
                                message: response.data.error.message
                            })
                        } else {
                        }
                    });
            if (hairdresserId) {
                params.hairdresserId=hairdresserId;
                self.props.dispatch(hairdresserActions.getHairdresserInfoWeb(params))
                    .then(function (response) {

                        if (response.data.error) {
                            self.setState({
                                message: response.data.error.message
                            })
                        } else {

                        }
                    });
                self.props.dispatch(hairdresserActions.getHairdresserReview(params))
                    .then(function (response) {

                        if (response.data.error) {
                            self.setState({
                                message: response.data.error.message
                            })
                        } else {

                        }
                    });
            }
        }else{
            this.props.history.push("/404");
        }
    }

    render(){
        // console.log('Hairdresser this.props',this.props);
        let {hairdresser, loader,salon}=this.props;
        return(
            <React.Fragment>
                {
                    salon.salonInfo&&hairdresser.userInfo ?
                <div className="container cm_bg roundborder">
                     {/* <div className={this.props.setting.booking==true?"row dashboard blure_bg":"row dashboard"}></div> */}
                    <div className="row dashboard">
                        <div className="col-lg-7 cm_box">
                            <HomeLeft match={this.props.match} history={this.props.history} latitude={this.state.latitude} longitude={this.state.longitude} bookingSlots={hairdresser.bookingSlots}/>
                        </div>
                        <div className={this.props.setting.booking==true? " col-lg-5 cm_box blure_bg " : " col-lg-5 cm_box "}>
                            <HomeRight match={this.props.match} history={this.props.history} latitude={this.state.latitude} longitude={this.state.longitude} bookingSlots={hairdresser.bookingSlots}/>
                        </div>
                    </div>

                </div>
                :
                // <div className="cm_loader"><div className="loader"></div></div>
                null
                }
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { hairdresser,salon,hairdresserReview,user,
        setting,createBooking } = state;
    return {
        hairdresser,
        salon,
        hairdresserReview,
        user,
        setting,createBooking
    };
}

export default connect(mapStateToProps)(HairdresserComponent);
