import React from "react";
import ReactDOM from "react-dom";
import { PersistGate } from "redux-persist/integration/react";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "./index.css";
import { Routes } from "./routes";
import { Provider } from "react-redux";

import * as serviceWorker from "./serviceWorker";
import { store, persistor } from "./config/store";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { hotjar } from "react-hotjar";

console.log("process.env.REACT_APP_ENV", process.env.REACT_APP_ENV);

const advancedMatching = { em: "charlie@beuapp.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};

if (
  process.env.REACT_APP_ENV === "prod" ||
  process.env.REACT_APP_ENV === "production"
) {
  ReactGA.initialize("UA-113180641-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  ReactPixel.init("3242802232608550", advancedMatching, options);
  hotjar.initialize(1902336, 6);
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Routes />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.register();
