import React, { Component } from "react";
import TopHeader from "../template/TopHeader";
import SideNav from "../template/SideNav";
import { productAction } from "../../_actions/product.action";
import { connect } from "react-redux";

class EditProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productData: {
        productName: "",
        retailPrice: "",
        stockPrice: "",
        commissionEnable: 0,
      },
      priceValidation:'',
      selectedProduct: null,
      stockPriceError:'',
      currencySymbol:'£',
    };
  }
  
  componentDidMount() {
    const { location } = this.props;
    if (location.state && location.state.product) {
      const { product } = location.state;
      const { productName, retailPrice, stockPrice, commissionEnable } = product;
      // console.log("product",product);
      this.setState({
        selectedProduct: product,
        productData: {
          productName: productName || "",
          retailPrice: retailPrice || "",
          stockPrice: stockPrice || "",
          commissionEnable: commissionEnable || 0,
        },
      });
    }
  }
  

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      productData: {
        ...prevState.productData,
        [name]: value,
      },
    }));
  };
  handleChangeCommission = (e) => {
    let { name, value } = e.target;
    value = Number(value);
    this.setState((prevState) => ({
      productData: {
        ...prevState.productData,
        [name]: value,
      },
    }));
  };

  handleChangePrice = (e) => {
    let self = this;
    const { name, value } = e.target;
   let _value = +value.split(this.state.currencySymbol)[1]

   if(isNaN(_value)){
    return;
   }
    
    this.setState((prevState) => ({
      productData: {
        ...prevState.productData,
        [name]: value.substring(1,value.length),
      },
    }));
  };

  handleClickCancel = () => {
    const { history } = this.props;
    history.push("/product");
  };



  updatedProduct = () => {
        const {productData} = this.state;
        const productId = this.props?.location?.state?.productId;
        let obj = {
        productId,
        ...productData
    }

    if( parseFloat(productData.stockPrice) > parseFloat(productData?.retailPrice)){
      return ;
    }
    
    // console.log("objjj",obj);
    this.props.dispatch(productAction.updateProduct(obj))
      .then((response) => {
        // console.log("Update response:", response);
        this.setState({ productData: response.data.requestParams });

        const { history } = this.props;
        history.push("/product");
      })
      .catch((error) => {
        console.log("Error updating product:", error);
      });
  };
  
  render() {
    const { productName, retailPrice, stockPrice, commissionEnable } = this.state.productData;
    return (
      <div>
        <div className="h_n">
          <TopHeader title="Edit Product" {...this.props} />
          <SideNav {...this.props} />
          <div className="product_div">
            <div className="product_main_div">
              <h3 className="py-4 pl-2">Product Info</h3>
              <h6 className="fw-bold ml-2">Product Name</h6>
              <input
                type="text"
                name="productName"
                className="ml-2 mb-4 product_input"
                value={productName}
                onChange={this.handleChange}
              />
              <h6 className="fw-bold ml-2">Retail Price</h6>
              <input
                type="text"
                name="retailPrice"
                className="ml-2 m2-4 product_input"
                value={`${this.state.currencySymbol}${retailPrice}`}
                onChange={this.handleChangePrice}
              />
              {(stockPrice>retailPrice)?<p className="text-danger">{'Retail price should be grater then stock price.'}</p>:null}
              <h6 className="fw-bold mt-2 ml-2">Stock Price</h6>
              <input
                type="text"
                name="stockPrice"
                className="ml-2 mb-4 product_input"
                value={`${this.state.currencySymbol}${stockPrice}`}
                onChange={this.handleChangePrice}
              />
              <h6 className="fw-bold ml-2">Commission Enabled</h6>
              <select
                name="commissionEnable"
                className="ml-2 mb-4 bg-white product_select"
                value={commissionEnable}
                onChange={this.handleChangeCommission}
              >
                <option value={0}>No</option>
                <option value={1}>Yes</option>
              </select>
              <div className="text-center">
              <button
                onClick={this.handleClickCancel}
                className="product_cancel"
              >
                Cancel
              </button>
              <button className="product_save" onClick={this.updatedProduct}>
                Save
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(EditProduct);
