import React, {Component} from 'react';
import {CardElement, CardNumberElement,CardExpiryElement,CardCvcElement} from '@stripe/react-stripe-js';
import {Link} from "react-router-dom";
import {hairdresserActions, paymentActions} from "../../_actions";
import {connect} from "react-redux";
import siteSetting from "../../config/env";
import {MySpinner} from '../SvgIcon'

class CheckoutForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            email:'',
            firstName:'',
            lastName:'',
            emailError:'',
            firstNameError:'',
            lastNameError:'',
            cardNumber:'',
            expiryNmumber:'',
            securityCode :'',
            applyCoupon: false,
            selectedPlan:2,     //1-month , 2-yearly,
            monthlyPrice:(localStorage.getItem("userType")&&localStorage.getItem("userType")==1)?siteSetting.stripe.salonMonthlyPrice:siteSetting.stripe.selfMonthlyPrice,
            yearlyPrice:(localStorage.getItem("userType")&&localStorage.getItem("userType")==1)?siteSetting.stripe.salonYearlyPrice:siteSetting.stripe.selfYearlyPrice,
            stripeMonthlyPriceId:(localStorage.getItem("userType")&&localStorage.getItem("userType")==1)?siteSetting.stripe.salonMonthlyPriceId:siteSetting.stripe.selfMonthlyPriceId,
            stripeYearlyPriceId:(localStorage.getItem("userType")&&localStorage.getItem("userType")==1)?siteSetting.stripe.salonYearlyPriceId:siteSetting.stripe.selfYearlyPriceId,
            priceId:(localStorage.getItem("userType")&&localStorage.getItem("userType")==1)?siteSetting.stripe.salonYearlyPriceId:siteSetting.stripe.selfYearlyPriceId,
            activationCode:"",
            source:"",
            subscriptionAmount:(localStorage.getItem("userType")&&localStorage.getItem("userType")==1)?siteSetting.stripe.salonYearlyPrice:siteSetting.stripe.selfYearlyPrice,
            discount:0,
            totalPayment:(localStorage.getItem("userType")&&localStorage.getItem("userType")==1)?siteSetting.stripe.salonYearlyPrice:siteSetting.stripe.selfYearlyPrice,
            activatationCodeError:"",
            error:"",
            activatationCodeSuccess:"",
            agree:false,
            agreeError:"",
            cardNumberError:"",
            expiryNumberError:"",
            securityCodeError:"",
            isFormLoader:false,
            isApplyedPromocode:false,
            saveText:(localStorage.getItem("userType")&&localStorage.getItem("userType")==1)?siteSetting.stripe.salonSave:siteSetting.stripe.selfempSave,
        }
    };
    planChange = (planId) => {
        this.setState({
            selectedPlan : planId,
            discount:0,
            activatationCodeError:"",
            error:"",
            activatationCodeSuccess:"",
            activationCode:""
        });
        if(planId==1){
            this.setState({
                priceId : this.state.stripeMonthlyPriceId,
                subscriptionAmount:this.state.monthlyPrice,
                totalPayment:this.state.monthlyPrice,
            });
        }else{
            this.setState({
                priceId : this.state.stripeYearlyPriceId,
                subscriptionAmount:this.state.yearlyPrice,
                totalPayment:this.state.yearlyPrice,
            });
        }
    }
    showApplyCoupon = () => {
        this.setState({applyCoupon: !this.state.applyCoupon})
    }
    validateEmail=(email)=> {
        const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const result = pattern.test(email);
        return result;
    }
    handleSubmit = async (event) => {
        event.preventDefault();
        let self = this;
        this.setState({
            activatationCodeError:"",
            error:"",
            emailError:'',
            firstNameError:'',
            lastNameError:'',
            agreeError:"",
            cardNumberError:"",
            expiryNumberError:"",
            securityCodeError:""
        });

         if (this.state.email === "" || this.state.email === undefined) {
            this.setState({
                emailError: "Please enter your email address"
            })
        } else if (!this.validateEmail(this.state.email)) {
            this.setState({
                emailError: 'Please enter a valid email address'
            })
        }else if (this.state.firstName === "" || this.state.firstName === undefined) {
            this.setState({
                firstNameError: 'Please enter first name'
            })
        }else if (this.state.lastName === "" || this.state.lastName === undefined) {
            this.setState({
                lastNameError: 'Please enter last name'
            })
        }
         // else if (this.state.agree === false||this.state.agree === "false") {
         //     this.setState({
         //         agreeError: 'Partner must agree before proceeding'
         //     })
         // }
         else {

            const {stripe, elements} = this.props;

            if (elements == null) {
                return;
            }
             this.setState({
                 isFormLoader:true
             });
             self.props.dispatch(hairdresserActions.checkEmailAvailability({email:self.state.email}))
                 .then(async function (response) {
                     console.log("response",response);
                     if (response.data.statusCode == 0) {
                         if (response.data.error.errorCode === 3) {
                             self.setState({
                                 emailError: response.data.error.responseMessage
                             })
                         } else {
                             self.setState({
                                 error: response.data.error.responseMessage
                             })
                         }
                         self.setState({
                             isFormLoader:false
                         });
                     } else {
                         const {error, paymentMethod} = await stripe.createPaymentMethod({
                             type: 'card',
                             card: elements.getElement(CardNumberElement),
                         });
                         console.log("paymentMethod", paymentMethod, error);
                         if (paymentMethod) {
                             if (self.state.agree === false||self.state.agree === "false") {
                                 self.setState({
                                     agreeError: 'Partner must agree before proceeding',
                                     isFormLoader:false
                                 })
                             } else {
                                 self.setState({
                                     source: paymentMethod.id,
                                 });
                                 let subscriptionData = {
                                     email: self.state.email,
                                     firstName: self.state.firstName,
                                     lastName: self.state.lastName,
                                     paymentMethod: paymentMethod.id,
                                     priceId: self.state.priceId,
                                 }
                                 if (self.state.activationCode !== "") {
                                     subscriptionData.activationCode = self.state.activationCode;
                                 }
                                 self.props.dispatch(paymentActions.subscription(subscriptionData))
                                     .then(function (response) {
                                         //console.log("response",response);
                                         if (response.data.statusCode == 0) {
                                             if (response.data.error.errorCode === 10) {
                                                 self.setState({
                                                     error: response.data.error.responseMessage
                                                 })
                                             } else {
                                                 self.setState({
                                                     error: response.data.error.responseMessage
                                                 })
                                             }
                                             self.setState({
                                                 isFormLoader: false
                                             });
                                         } else {
                                             self.setState({
                                                 isFormLoader: false
                                             });
                                             localStorage.setItem("email", self.state.email);
                                             localStorage.setItem("firstName", self.state.firstName);
                                             localStorage.setItem("lastName", self.state.lastName);
                                             localStorage.setItem("subscriptionId", response.data.responseData.result.id)
                                             localStorage.setItem("transactionId", response.data.responseData.result.metadata.transactionId);
                                             self.props.history.push('/signup');
                                         }
                                     })
                             }
                         } else {
                             self.setState({
                                 isFormLoader: false
                             });
                             console.log("error",error);
                             if (error.code && error.message) {
                                 if (error.code == "incomplete_number"||error.code == "invalid_number") {
                                     self.setState({
                                         cardNumberError: error.message
                                     })
                                 } else if (error.code == "incomplete_expiry"||error.code =="invalid_expiry_year_past") {
                                     self.setState({
                                         expiryNumberError: error.message
                                     })
                                 } else if (error.code == "incomplete_cvc") {
                                     self.setState({
                                         securityCodeError: error.message
                                     })
                                 } else {
                                     self.setState({
                                         error: error.message
                                     })
                                 }
                             }
                         }
                     }
                 })

        }
    };
    applyCoupan=()=>{
        this.setState({
            activatationCodeError:"",
            error:"",
            activatationCodeSuccess:""
        });
        let self = this;
        if(this.state.activationCode.trim()!="") {
            self.props.dispatch(paymentActions.activationCode({activationCode: this.state.activationCode.trim()}))
                .then(function (response) {
                    console.log("response", response);
                    if (response.data.statusCode == 0) {
                        if (response.data.error.errorCode === 10) {
                            self.setState({
                                activatationCodeError: response.data.error.responseMessage
                            })
                        } else {
                            self.setState({
                                activatationCodeError: response.data.error.responseMessage
                            })
                        }
                    } else {
                        let discount = self.state.discount, totalPayment = self.state.totalPayment;
                        if (response.data.responseData.result.percent_off) {
                            discount = (response.data.responseData.result.percent_off / 100) * totalPayment;
                            totalPayment = totalPayment - discount
                        }
                        if (response.data.responseData.result.amount_off) {
                            discount = response.data.responseData.result.amount_off;
                            totalPayment = totalPayment - discount
                        }
                        self.setState({
                            discount,
                            totalPayment,
                            isApplyedPromocode: true,
                            activatationCodeSuccess: "Activation code applied successfully"
                        })
                    }
                })
        }else{
            self.setState({
                activatationCodeError: "Please enter activation code"
            })
        }
    }
    onChanged=(e)=> {
        this.setState({
            [e.target.name]: e.target.value,
            activatationCodeError:"",
            error:"",
            emailError:'',
            firstNameError:'',
            lastNameError:'',
        });
        if(e.target.name==="activationCode"){
            this.setState({
                isApplyedPromocode:false,
                discount:0,
                activatationCodeSuccess:"",
                totalPayment:this.state.subscriptionAmount
            })
        }
    }
    clearError=()=> {
        this.setState({
            activatationCodeError:"",
            error:"",
            emailError:'',
            firstNameError:'',
            lastNameError:'',
            securityCodeError:'',
            cardNumberError:'',
            expiryNumberError:'',
            agreeError:''
        });

    }
    toggleCheckBox = (field,errorMsg) => {
        this.setState({
            [field]: !this.state[field],
            [errorMsg]:""
        });
    }
    render() {
        console.log("this.state",this.state);
        console.log("this.props",this.props);
        const {stripe} = this.props;
        return (
            <div className="payment-parent ">
                <div className="payment-form box-conteiner">
                    <div className="plan-package-parent">
                        <div className={this.state.selectedPlan===1?"plan-package selected":"plan-package cursorP"} onClick={(e)=>this.planChange(1)}>
                            <h2>£{this.state.monthlyPrice}</h2>
                            <p>Monthly </p>
                        </div>
                        <div className={this.state.selectedPlan===2?"plan-package selected":"plan-package cursorP"} onClick={(e)=>this.planChange(2)}>
                            <h2>£{this.state.yearlyPrice}</h2>
                            <p><span>Annual</span><span>{this.state.saveText}</span></p>
                        </div>

                        <div className="toltip-msz">
                            <p className="mb-0">Most popular option.</p>
                        </div>
                    </div>


                    <div className="form-body paymentFrm-label">
                        <br/>
                        <button className=" btn btn-blue applyCpn" onClick={this.showApplyCoupon}>Apply coupon</button>
                        {this.state.applyCoupon && <div className={this.state.activationCode ? 'form-input cm_active apply-coupon-wrp' : 'form-input apply-coupon-wrp'}>
                            <input type="text"
                                   className="textbox"
                                   name="activationCode" autoComplete="off required" onChange={this.onChanged} maxLength={50}/>
                            <label htmlFor="activationCode" className="form-label">
                                Apply coupon
                            </label>
                            <button className="btn btn-blue btn-block" onClick={this.applyCoupan} disabled={this.state.isApplyedPromocode}>Apply coupon</button>
                        </div>}

                        {this.state.activatationCodeError ?
                            <span className="cm_error_msg w-100 d-block mt-2">{this.state.activatationCodeError}</span> : ''}
                        {this.state.activatationCodeSuccess ?
                            <span className="cm_succ_msg w-100 d-block mt-2">{this.state.activatationCodeSuccess}</span> : ''}
                        <div className={this.state.email ? 'form-input cm_active' : 'form-input'}>
                            <input type="text"
                                   onChange={this.onChanged}
                                   className="textbox"
                                   name="email" autoComplete="off required" value={this.state.email} maxLength={80}/>
                            <label htmlFor="email" className="form-label">
                                Email
                            </label>
                            {this.state.emailError ?
                                <span className="cm_error_msg">{this.state.emailError}</span> : ''}
                        </div>
                        <div className={this.state.firstName ? 'form-input cm_active' : 'form-input'}>
                            <input type="text"
                                   className="textbox"
                                   name="firstName" autoComplete="off required" onChange={this.onChanged} value={this.state.firstName} maxLength={50}/>
                            <label htmlFor="firstName" className="form-label">
                                First Name
                            </label>
                            {this.state.firstNameError ?
                                <span className="cm_error_msg">{this.state.firstNameError}</span> : ''}
                        </div>
                        <div className={this.state.lastName ? 'form-input cm_active' : 'form-input'}>
                            <input type="text"
                                   onChange={this.onChanged}
                                   className="textbox"
                                   name="lastName" autoComplete="off required" value={this.state.lastName} maxLength={50} />
                            <label htmlFor="lastName" className="form-label">
                                Last Name
                            </label>
                            {this.state.lastNameError ?
                                <span className="cm_error_msg">{this.state.lastNameError}</span> : ''}
                        </div>
                        <div className={this.state.cardNumber ? 'form-input stripe-inputs cm_active' : 'form-input stripe-inputs'}>
                            <CardNumberElement
                                className="textbox stripe-elements-wrp"
                                options={{
                                    style: {
                                        base: {
                                            fontSize: '20px',
                                            color: '#000',
                                            lineHeight: '55px',
                                            '::placeholder': {
                                                color: '#ffffff00',
                                            },
                                        }
                                    }
                                }}
                                onChange={this.clearError}
                                id="cardNumber"
                            />
                            <label htmlFor="name" className="form-label">
                                Card Number
                            </label>
                            {this.state.cardNumberError ?
                                <span className="cm_error_msg">{this.state.cardNumberError}</span> : ''}
                        </div>
                        <div className={this.state.expiryNmumber ? 'form-input cm_active stripe-inputs' : 'form-input stripe-inputs'}>
                            <CardExpiryElement className="textbox stripe-elements-wrp"
                                options={{
                                    style: {
                                        base: {
                                            fontSize: '20px',
                                            color: '#000',
                                            lineHeight: '55px',
                                            '::placeholder': {
                                                color: '#ffffff00',
                                            },
                                        }
                                    }
                                }}
                                               onChange={this.clearError}
                                               id="expiry"
                            />
                            <label htmlFor="name" className="form-label">
                                Expiry date
                            </label>
                            {this.state.expiryNumberError ?
                                <span className="cm_error_msg">{this.state.expiryNumberError}</span> : ''}
                        </div>
                        <div className={this.state.securityCode ? 'form-input cm_active stripe-inputs' : 'form-input stripe-inputs'}>
                            <CardCvcElement className="textbox stripe-elements-wrp"
                                options={{
                                    style: {
                                        base: {
                                            fontSize: '20px',
                                            color: '#000',
                                            lineHeight: '55px',
                                            '::placeholder': {
                                                color: '#ffffff00',
                                            },
                                        }
                                    }
                                }}
                                            onChange={this.clearError}
                                            id="cvc"
                            />
                            <label htmlFor="name" className="form-label">
                                Security code
                            </label>
                            {this.state.securityCodeError ?
                                <span className="cm_error_msg">{this.state.securityCodeError}</span> : ''}
                        </div>
                    </div>
                </div>
                <div className="payment-bill box-conteiner">
                    <h2>{localStorage.getItem("userType")==1?"Salon / Babershop":"Self-Employed"} </h2>
                    <a href="/get-software">Switch Plan</a>
                    <div className="tble mt-5">
                        <p>{this.state.selectedPlan===2?"Annual":"Monthly"} Subscription</p>
                        <p>£{this.state.subscriptionAmount}</p>
                    </div>
                    {this.state.discount>0?
                    <div className="tble">
                        <p>Subscription Discount</p>
                        <p>£{this.state.discount.toFixed(2)}</p>
                    </div>:""}
                    <hr/>
                    <div className="tble total">
                        <p className="font-weight-bold">{this.state.selectedPlan===2?"Annual":"Monthly"} Payment</p>
                        <p className="font-weight-bold">£{this.state.totalPayment.toFixed(2)}</p>
                    </div>

                    <p className="d-flex mt-4 mb-4">
                        <div className="check_wrapper mt-1">
                            <input type="checkbox" checked={this.state.agree} onChange={()=>this.toggleCheckBox('agree','agreeError')} name="agree"/>
                            <span />
                        </div>
                        <div className="cm_fw_l m-0"> I agree to the <a className="" href="/hairdresser-terms-and-conditions" target="_blank">partner terms</a>,
                        <a className="" href="/terms-and-conditions" target="_blank">terms and conditions</a>,and <a className="" href="/privacy-policy" target="_blank">privacy policy</a>.</div>
                    </p>
                    {this.state.agreeError ?
                        <span className="cm_error_msg">{this.state.agreeError}</span> : ''}
                    {this.state.error ?
                        <span className="cm_error_msg">{this.state.error}</span> : ''}
                    <br/>
                    <div className="">
                        <button className="btn btn-blue btn-block" disabled={this.state.isFormLoader} onClick={this.handleSubmit}>
                            &nbsp;{this.state.isFormLoader?<MySpinner/> :""}&nbsp;
                            Get Beu
                        </button>
                    </div>
                </div>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(CheckoutForm);
