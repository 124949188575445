import axios from 'axios';
import API from "../config/API";

export const marketingInvoiceService = {
    getInvoiceList
};

  function getInvoiceList(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    // console.log("headers",headers, params);
    return axios.post(API.GET_MARKETING_INVOICE_LIST, params,{headers:headers})
}


