import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import TopHeader from "../template/TopHeader";
import SideNav from "../template/SideNav";
import toast from "react-toast-notification";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { marketingAction } from "../../_actions/marketing.actions";
import siteSetting from "../../config/env";

function UpdateSales(props) {
  const [isEdit, setIsEdit] = useState(false);
  const router = useHistory();
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [reminderTime, setReminderTime] = useState(null);
  const [reminderTriggerError, setReminderTriggerError] = useState(null);
  const [reminderTimeError, setReminderTimeError] = useState(null);
  const updateMarketingData = useSelector(state=>state?.updateMarketingData);
  const [reminder, setUpdateReminder] = useState({
    reminderTime: 0,
    statusSMSreminder: 0,
    reminderTimeType:null,
    statusEmailReminder: 0,
  });
  const options =siteSetting?.reminderTimeOptions;
  const dispatch = useDispatch();

  useEffect(() => {
    if (updateMarketingData?.reminder) {
      setUpdateReminder({ ...updateMarketingData?.reminder });
      console.log('checking logtype',updateMarketingData?.reminder);
      if(updateMarketingData?.reminder?.type===1){
        setReminderTime(updateMarketingData?.reminder?.reminderTime);
        let _setOption = {
          value:updateMarketingData?.reminder?.reminderTimeType,
          label:marketingAction?.reminderTimeType(updateMarketingData?.reminder?.reminderTimeType)
        }
        console.log('check updates',_setOption,updateMarketingData?.reminder);
        setSelectedOption(_setOption)
      }
    }

    return () => {
        if(timeoutId!=null){
            clearTimeout(timeoutId);
        }
    };

    /* eslint-disable-next-line */
  }, []);

  const onStatusChange = (type, data) => {
    setIsLoadingStatus(true);
    if (type === "reminderTimeType") {
      setSelectedOption(data);
      setReminderTimeError(null);
      data = data?.value;
    }else{
        data = data?1:0; 
    }
    setUpdateReminder({ ...reminder, [type]: data });
  };

  const updateSetting = () => {
    setIsLoadingStatus(false);
    if (updateMarketingData?.reminder) {
      if(reminder?.type===1){
          if(+reminderTime<=0){
            setReminderTriggerError('Trigger Time is required.')
          return;
        }
        
        if(reminder?.reminderTimeType==null){
          setReminderTimeError('This field is required.')
          return;
        }
        reminder.reminderTime=+reminderTime;
        reminder.reminderTimeType=reminder?.reminderTimeType;
      }
        const data = {
          reminders: [reminder],
          type: 4,
          salonId: updateMarketingData?.salonId,
        };

     dispatch(marketingAction.updateSetting(data)).then((res) => {
      if (res?.data?.statusCode === 1) {
        data.reminder = data.reminders[0];
        setIsLoadingStatus(true);
        dispatch(marketingAction.marketingUpdateSettingData(data));
        toast.success(res?.data?.responseData?.message);
        let timerId = setTimeout(()=>{
            router.push(updateMarketingData?.previousUrl);
        },500)
        setTimeoutId(timerId);
      }
    });
   }
  };

  const showPreview = (type) => {
    let message = '';
    if(reminder.type===1){
      if(type==='SMS'){
        message = siteSetting?.SMSText?.reminderToRebook;
        router.push({
          pathname: "/preview",
          state: {
            previousUrl: router?.location?.pathname,
            previewText: message,
            type: type,
          },
        });
      }else{
        if(type==='Email'){
          message = siteSetting?.SMSText?.reminder;
          router.push({
            pathname: "/email-preview",
            state: {
              previousUrl: router?.location?.pathname,
              previewText:  message?message:'',
              type:'Reminder to rebook',
            },
          });
        }
      }
    }
    console.log('check preview type',reminder)
    if(reminder.type===2){
      if(type==='SMS'){
        message = siteSetting?.SMSText?.happyBirthday;
        router.push({
          pathname: "/preview",
          state: {
            previousUrl: router?.location?.pathname,
            previewText: message,
            type: type,
          },
        });
      }else{
     
        if(type==='Email'){
          router.push({
            pathname: "/email-preview",
            state: {
              previousUrl: router?.location?.pathname,
              previewText: message?message:'',
              type:'Happy birthday',
            },
          });
        }
      }
    }
  };

  const addReminderTime = (e) => {
    const input = e.target.value;
    const regex = /^[0-9\b]+$/; 
    if (input === '' || regex.test(input)) {
        setReminderTriggerError(null);
        setReminderTime(input);
    }else{
      setReminderTriggerError('Enter valid time in number.');
    }
  };

  return (
    <div className="h_n">
      <div className="body_container cm_bg_white">
        <TopHeader 
        type='reminder'
        title={reminder?.title}
        isLoadingStatus={isLoadingStatus}
        previousPageUrl={updateMarketingData?.previousUrl}
        headerButtonStatus={true}
        cancelButton={true}
        saveReminderButton={true}
        saveReminder={updateSetting}
        cancelButtonText={"Back"}
        {...props} 
        />

        <SideNav {...props} />
        <div className="new-setting-wrapper __reminder">
          <div className="container">
            <div className="row">
              <div className="_reminder_section ">
                <div className="new-stng-cmn _remind">
                  <h5 className="">{reminder?.title}</h5>
                  <p className="mt-2 mb-3">
                   {reminder?.note}
                  </p>
                  {reminder?.type===1?<div className="row _update_custom">
                  <div className="col-sm-4">
                    <div className="input_group_u  _staff_member">
                      <span className="_field_label_vk">
                      Trigger Time
                      </span>
                      <input type="text" onChange={(e)=>addReminderTime(e)} value={reminderTime} className="form-control text-center"/>
                       {reminderTriggerError!=null?<p className="text-danger">{reminderTriggerError}</p>:null}
                      </div>
                
                  </div>
                  <div className="col-sm-8">
                  <div className={`input_group_u  _staff_member border_blue`}>
                  <span className="_field_label_vk">
                   Minutes, Hours, Days, Weeks, Month
                  </span>

                    <Select
                      defaultValue={selectedOption}
                      value={selectedOption}
                      options={options}
                      onChange={(e) => onStatusChange("reminderTimeType", e)}
                    />
                    {reminderTimeError!=null?<p className="text-danger">{reminderTimeError}</p>:null}
                </div>
                  </div>
                </div>:null}
                </div>
                <div className="new-stng-cmn _sms_reminder">
                  <div className="_image">
                    <img
                      src={require("../../assets/images/SMSPreviewSVG.svg")}
                      className="img-fluid mb-3"
                    />
                  </div>
                  <div className="_text">
                    <div className="_head d-flex">
                      <h4>SMS</h4>
                      <div className="salon_switch_container">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              onStatusChange(
                                "statusSMSreminder",
                                e?.target?.checked
                              )
                            }
                            checked={reminder?.statusSMSreminder}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <p className="mt-2 mb-4">£0.04/ message</p>
                    <p>
                    SMS messages are sent to <b>all clients</b> to the phone number you have saved on client record.
                    </p>
                    <p onClick={() => showPreview("SMS")} className="_link cursor-pointer">
                      Preview SMS
                    </p>
                  </div>
                </div>

                <div className="new-stng-cmn _sms_reminder">
                  <div className="_image">
                    <img
                      src={require("../../assets/images/email_imag00.svg")}
                      className="img-fluid mb-3"
                      alt="email"
                    />
                  </div>
                  <div className="_text">
                    <div className="_head d-flex">
                      <h4>Email</h4>
                      <div className="salon_switch_container">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              onStatusChange(
                                "statusEmailReminder",
                                e?.target?.checked
                              )
                            }
                            checked={reminder?.statusEmailReminder}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <p className="mt-2 mb-4">Free</p>
                    <p>
                    Emails are sent to <b>all clients</b> to the email address you have saved on their client record.
                    </p>
                    <p onClick={() => showPreview("Email")} className="_link cursor-pointer">
                    Preview Email
                  </p>
                  </div>
                </div>
              </div>
              <div className="cm_btns withcheckoutbtn mt-4 checkout__save_btn_mobile">
              <button className="btn btn-white" onClick={()=>{
                router.push(updateMarketingData?.previousUrl)
              }}>cancel</button>
              <button className="btn btn-dark-blue" onClick={()=>updateSetting()}>Save </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateSales;
