import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import toast from "react-toast-notification";
import TopHeader from "../template/TopHeader";
import SideNav from "../template/SideNav";
import siteSetting from "../../config/env";
import { useDispatch, useSelector } from "react-redux";
import { marketingAction } from "../../_actions/marketing.actions";
import { settingConstants } from "../../_constants";

function UpdateBooking(props) {
  const [isEdit, setIsEdit] = useState(false);
  const router = useHistory();
  const [timeoutId, setTimeoutId] = useState(null);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const updateMarketingData = useSelector(state=>state?.updateMarketingData);

  const [bookingData, setUpdateBookingReminder] = useState({
    reminderTime: 2,
    statusSMSreminder: false,
    statusEmailReminder: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (updateMarketingData?.bookingData) {
      setUpdateBookingReminder({ ...updateMarketingData?.bookingData});
    }

    return () => {
        if(timeoutId!=null){
            clearTimeout(timeoutId);
        }
    };
    /* eslint-disable-next-line */
  }, []);

  const onStatusChange = (type, data) => {
    setIsLoadingStatus(true);
    if (type === "reminderTime") {
      data = data?.value;
    }else{
        data = data?1:0; 
    }

    setUpdateBookingReminder({ ...bookingData, [type]: data });
  };

  const updateSetting = () => {
    
    setIsLoadingStatus(false);


    if (bookingData) {
        const data = {
          reminders: [bookingData],
          type: 3,
          salonId: updateMarketingData?.salonId,
        };
     dispatch(marketingAction.updateSetting(data)).then((res) => {
      if (res?.data?.statusCode === 1) {
        data.reminder = data.reminders[0];
        setIsLoadingStatus(true);
        dispatch(marketingAction.marketingUpdateSettingData(data));
        router.push(updateMarketingData?.previousUrl);
        toast.success(res?.data?.responseData?.message);
        let timerId = setTimeout(()=>{
            router.push(updateMarketingData?.previousUrl);
        },500)
        setTimeoutId(timerId);
      }
    });
   }
  };


  const showPreview = (type) => {
   let message = '';
   if(bookingData?.type===1){
      if(type==='SMS'){
        message = siteSetting?.SMSText?.newBooking;
        router.push({
          pathname: "/preview",
          state: {
            previousUrl: router?.location?.pathname,
            previewText: message,
            type: type,
          },
        });
      }else{
        if(type==='Email'){
          message = siteSetting?.EmailText?.newBooking;
          router.push({
            pathname: "/email-preview",
            state: {
              previousUrl: router?.location?.pathname,
              previewText: message,
              type:'New Booking',
            },
          });
        }
      }
   }

   if(bookingData?.type===2){
    if(type==='SMS'){
      message = siteSetting?.SMSText?.updatedBooking;
      router.push({
        pathname: "/preview",
        state: {
          previousUrl: router?.location?.pathname,
          previewText: message,
          type: type,
        },
      });
    }else{
     
      if(type==='Email'){
        message = siteSetting?.EmailText?.updatedBooking;
        router.push({
          pathname: "/email-preview",
          state: {
            previousUrl: router?.location?.pathname,
            previewText: message,
            type:'Update Booking',
          },
        });
      }
    }
 }

 if(bookingData?.type===3){
  if(type==='SMS'){
    message = siteSetting?.SMSText?.noShow;
    router.push({
      pathname: "/preview",
      state: {
        previousUrl: router?.location?.pathname,
        previewText: message,
        type: type,
      },
    });
  }else{
    if(type==='Email'){
      router.push({
        pathname: "/email-preview",
        state: {
          previousUrl: router?.location?.pathname,
          previewText: message?message:'',
          type:'No-show',
        },
      });
    }
    
  }
}

if(bookingData?.type===4){
  if(type==='SMS'){
    message = siteSetting?.SMSText?.cancelledBooking;
    router.push({
      pathname: "/preview",
      state: {
        previousUrl: router?.location?.pathname,
        previewText: message,
        type: type,
      },
    });
  }else{
    if(type==='Email'){
      router.push({
        pathname: "/email-preview",
        state: {
          previousUrl: router?.location?.pathname,
          previewText: message?message:'',
          type:'Cancelled booking',
        },
      });
    }
  }
}

if(bookingData?.type===5){
  if(type==='SMS'){
    message = siteSetting?.SMSText?.thankYouForVisiting;
    router.push({
      pathname: "/preview",
      state: {
        previousUrl: router?.location?.pathname,
        previewText: message,
        type: type,
      },
    });
  }else{
    if(type==='Email'){
      router.push({
        pathname: "/email-preview",
        state: {
          previousUrl: router?.location?.pathname,
          previewText: message?message:'',
          type:'Thank you after visiting',
        },
      });
    }
  }
}

if(bookingData?.type===6){
  if(type==='SMS'){
    message = siteSetting?.SMSText?.thankYouForTipping;
    router.push({
      pathname: "/preview",
      state: {
        previousUrl: router?.location?.pathname,
        previewText: message,
        type: type,
      },
    });
  }else{
    console.log('check tiping data',updateMarketingData?.bookingData)
    if(type==='Email'){
      router.push({
        pathname: "/email-preview",
        state: {
          previousUrl: router?.location?.pathname,
          previewText: message?message:'',
          type:'Thank you for tipping',
        },
      });
    }
  }
}
  };



  return (
    <div className="h_n">
      <div className="body_container cm_bg_white">
        <TopHeader 
        type='reminder'
        isLoadingStatus={isLoadingStatus}
        previousPageUrl={updateMarketingData?.previousUrl}
        headerButtonStatus={true}
        cancelButton={true}
        saveReminderButton={true}
        saveReminder={updateSetting}
        cancelButtonText={"Back"}
        title={bookingData?.title} 
        {...props} />
        <SideNav {...props} />
        <div className="new-setting-wrapper __reminder">
          <div className="container">
            <div className="row">
              <div className="_reminder_section ">
                <div className="new-stng-cmn _remind">
                  <h5 className="">{bookingData?.title}</h5>
                  <p className="mt-2 mb-3">
                   {bookingData?.note}
                  </p>
                  
                </div>
                <div className="new-stng-cmn _sms_reminder">
                  <div className="_image">
                    <img
                      src={require("../../assets/images/SMSPreviewSVG.svg")}
                      className="img-fluid mb-3"
                    />
                  </div>
                  <div className="_text">
                    <div className="_head d-flex">
                      <h4>SMS</h4>
                      <div className="salon_switch_container">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              onStatusChange(
                                "statusSMSreminder",
                                e?.target?.checked
                              )
                            }
                            checked={bookingData?.statusSMSreminder}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <p className="mt-2 mb-4">£0.04/ message</p>
                    <p>SMS messages are sent to <b>all clients</b> to the phone number you have saved on client record.</p>
                    <p onClick={() =>showPreview("SMS")} className="_link cursor-pointer">
                      Preview SMS
                    </p>
                  </div>
                </div>

                <div className="new-stng-cmn _sms_reminder">
                  <div className="_image">
                  <img
                  src={require("../../assets/images/email_imag00.svg")}
                  className="img-fluid mb-3"
                  alt="email"
                />
                  </div>
                  <div className="_text">
                    <div className="_head d-flex">
                      <h4>Email</h4>
                      <div className="salon_switch_container">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              onStatusChange(
                                "statusEmailReminder",
                                e?.target?.checked
                              )
                            }
                            checked={bookingData?.statusEmailReminder}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <p className="mt-2 mb-4">Free</p>
                    <p>
                    Emails are sent to <b>all clients</b> to the email address you have saved on their client record.
                    </p>
                    <p onClick={() => showPreview("Email")} className="_link cursor-pointer">
                    Preview Email
                  </p>
                  </div>
                </div>
              </div>

              <div className="cm_btns withcheckoutbtn mt-4 checkout__save_btn_mobile">
              <button className="btn btn-white" onClick={()=>{
                router.push(updateMarketingData?.previousUrl)
              }}>cancel</button>
              <button className="btn btn-dark-blue" onClick={()=>updateSetting()}>Save </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateBooking;
