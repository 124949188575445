import { clientpaymentConstants } from '../_constants';


const initialState = {
  bankDetails: {},
};

export function bankDetails(state = initialState, action) {
  //console.log('action.type',action);
  switch (action.type) {
      case clientpaymentConstants.GET_BANK_SUCCESS:
      let bank = action.Success;
      return bank;

    case clientpaymentConstants.GET_BANK_ERROR:
      return {};

    default:
      return state
  }
}

