import React, { useState, useEffect } from "react";
import { GrClose } from "react-icons/gr";
import Select from 'react-select'
import { Link, Redirect,useLocation } from "react-router-dom";

 const CheckoutProduct = (props) => {
  const [item,setItem]=useState({});
  const location = useLocation();
  const [staffError,setStaffError]=useState('');
  const [selectedStaff,setSelectedStaff]=useState('');
  const [servicePrice,setServicePrice]=useState(0);
  const [updatePrice,setUpdatePrice]=useState(0);
  const [priceError,setPriceError]=useState('');


  const handleClickProduct = (indexId) => {
    if(props?.selectedStaff?.value!==undefined){
    setStaffError('');
    const queryParams = location.pathname.split('/');
    const salonId = queryParams[queryParams?.length-1];
    let currentPageUrl = props.propData.history.location.pathname;
    const stateObj = { pageLoadingStatus: 0, previousPageUrl:currentPageUrl };
    const url = `/SelectProduct/${salonId}/${indexId}`
     props.propData.history.push({
      pathname: url,
      state: stateObj,
    });
  }else{
    setStaffError('Staff Member is required.');
  }
  };

  useEffect(()=>{
   setItem(props?.item);
   setServicePrice(props?.item?.retailPrice);
  },[props])

  const checkKey = (key) =>{
    console.log(key)
    return item[key]!==undefined?true:false;
  }

  const handleClient = (hairdresserId,indexId) => {
    if(hairdresserId && hairdresserId!==''&& indexId && indexId!==''){
      setStaffError('');
      let currentPageUrl = props.propData.history.location.pathname;
      const stateObj = { pageLoadingStatus: 0, previousPageUrl:currentPageUrl };
      const url =   `/select-client/${hairdresserId}/${indexId}`
       props.propData.history.push({
        pathname: url,
        state: stateObj,
      });
    }else{
      setStaffError('Staff Member is required.');
    }
  };

  const onChangeStaff = (e,id) =>{
    setStaffError('');
    // eslint-disable-next-line no-unused-expressions
    props?.onSelectStaff('product',e,id);
  }
  
  useEffect(()=>{
    var timerValue;
    let err = '';
    setServicePrice(updatePrice);
    let testValidPrice = /^\d{0,8}(\.\d{1,2})?$/.test(updatePrice);
    if(updatePrice!==0){
      if(!isNaN(updatePrice) && updatePrice>=0.00 && testValidPrice){
        setPriceError('');
        err='';
      }else{
        err='Enter valid price.';
        setPriceError('Enter valid price.');
      }
    timerValue= setTimeout(()=>{
      props.changeItemPrice(item,'product',updatePrice,err);
    },2000);
    }
    return ()=>{
      clearTimeout(timerValue);
    }
   },[updatePrice]);

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="service_div bg-white rounded">
        <div className="row vertical-top">
          <div className="col-md-6 text-left mt-1 mb-3">
            <span className="service_2 ml-0">Product {item?.id}</span>
          </div>
          {props?.isPaymentComplete==1?null:
            <div className="col-md-6 text-right mb-3">
            <GrClose
              className="gr_close"
              onClick={()=>props.removeProduct('product',item?.id)}
            />
          </div>}
        </div>
        <div className="row">
          <div className="col-md-6  ">
            <div className={item?.selectedStaff?.label===undefined?"input_group_u  _staff_member border_blue":'input_group_u  _staff_member'}>
            <span className="_field_label_vk">Staff Member</span>
            {(props?.index===1&&props?.isPaymentComplete==0)?<Select 
            options={props?.staffList}
            value={item?.selectedStaff} 
            onChange={(e)=>onChangeStaff(e,item?.id)}/>:<div className="service_selected1 disabled_div">{props?.selectedStaff?.label!==undefined?props?.selectedStaff?.label:'Select staff member'}</div>}
            {staffError!==''?<span className={'text-danger'}>{staffError}</span>:null}
            </div>
          </div>
          <div className="col-md-6 pl-0">
          <div className={`input_group_u __Client ${props?.isPaymentComplete===1?'hide__arrow':''}`}>
            <span className="_field_label_vk __Client">Client</span>
            {(props?.index===1&&props?.isPaymentComplete==0)?
            <div className="service_selected1" onClick={()=>handleClient(props?.selectedStaff?.value,item?.id)}>
              {props?.selectedClient?.name!=undefined?props?.selectedClient?.name:'Select client'}</div>
              :
              <div className={`service_selected1 disabled_div ${props?.selectedStaff?.label==undefined?'hide__arrow':''}`}>{props?.selectedClient?.name!==undefined?props?.selectedClient?.name:'Select client'}</div>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 pr-0">
          <div className={'input_group_u'}>
          <span className="_field_label_vk">Price</span>
            <div className={`currency_div mt-0 ml-0 ${item?.productName?'currency_div_edit':''}`}>
              <div className="text-center px-3 currency_sml">£</div>
              {(item?.productName&&props?.isPaymentComplete!==1)?<input type="text" className="text-center pl-3 currency_sml1 currency_sml_input edit_price_input" placeholder={'00.00'} onChange={(e)=>setUpdatePrice(e.target.value)} value={isNaN(servicePrice)?'':servicePrice!==undefined?Number.isInteger(servicePrice)?servicePrice+'.00':servicePrice:`0.00`} />:<input type="text" className="text-center pl-3 currency_sml1 currency_sml_input edit_price_input"  value={servicePrice!==undefined?Number.isInteger(servicePrice)?servicePrice+'.00':servicePrice:`0.00`} disabled={true}/>
              }
             </div>
             {priceError!==''?<span className="text-danger">{priceError}</span>:null}
            </div>
          </div>
          
          <div className="col-md-9 ">
          <div className={`input_group_u __service ${props?.isPaymentComplete===1?'hide__arrow':''}`}>
          <span className="_field_label_vk">Product Name</span>
          {props?.isPaymentComplete===1?<div
              className={`mb-4 bg-white service_selected1 border-1  ${props?.selectedStaff?.label===undefined ? 'disabled_div':item?.productName !== undefined ? '':'border_blue' } `}
            >
              {checkKey('productName')?item?.productName:'Select product...'}
            </div>:<div
              className={`mb-4 bg-white service_selected1 border-1  ${props?.selectedStaff?.label===undefined ? 'disabled_div':item?.productName !== undefined ? '':'border_blue' } `}
              onClick={()=>handleClickProduct(item?.id)}
            >  
              {checkKey('productName')?item?.productName:'Select product...'}
            </div>}
           
            </div>
          </div>
        </div>
      </div>
     </div>
    </div>
  );
};

export default CheckoutProduct;