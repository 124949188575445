import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import siteSetting from "../../config/env";
import toast from "react-toast-notification";
import TopHeader from "../template/TopHeader";
import SideNav from "../template/SideNav";
import Select from "react-select";
import {  useDispatch, useSelector } from "react-redux";
import { marketingAction } from "../../_actions/marketing.actions";

function CreateReminder(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [reminderTriggerError, setReminderTriggerError] = useState(null);
  const [reminderTimeError, setReminderTimeError] = useState(null);
  const [reminderTime, setReminderTime] = useState(null);
  const router = useHistory();
  const updateMarketingData = useSelector(
    (state) => state?.updateMarketingData
  );

  const [reminder, setCreateReminder] = useState({
    reminderTime: 0,
    reminderTimeType:null,
    statusSMSreminder: 0,
    statusEmailReminder: 0,
  });

  const dispatch = useDispatch();
  const options =siteSetting?.reminderTimeOptions;
  

  useEffect(() => {
    return () => {
        if(timeoutId!=null){
            clearTimeout(timeoutId);
        }
    };
    /* eslint-disable-next-line */
  }, []);
 
  const onStatusChange = (type, data) => {
    if (type === "reminderTimeType") {
      setReminderTimeError(null);
      data = data?.value;
    } else {
      data = data ? 1 : 0;
    }
    setIsLoading(true);
    setCreateReminder({ ...reminder, [type]: data });
  };


  const showPreview = (type) => {
    let message = '';
      if(type==='SMS'){
        message = siteSetting?.SMSText?.reminder;
        router.push({
          pathname: "/preview",
          state: {
            previousUrl: router?.location?.pathname,
            previewText: message,
            type: type,
          },
        });
      }else{
        if(type==='Email'){
          message = siteSetting?.SMSText?.reminder;
          router.push({
            pathname: "/email-preview",
            state: {
              previousUrl: router?.location?.pathname,
              previewText: message,
              type:'Reminder',
            },
          });
        }
      }
  };

  const addReminder = () => {
    setIsLoading(false);
    let newReminder;
    if(+reminderTime<=0){
      setReminderTriggerError('Trigger Time is required.')
    return;
  }

  console.log('check reminder type',reminder?.reminderTimeType);
  if(reminder?.reminderTimeType==null){
    setReminderTimeError('This field is required.')
    return;
  }

    newReminder = {reminder:{
      ...reminder,
      reminderTime:+reminderTime,
      reminderTimeType:reminder?.reminderTimeType,
       },
     }
   
  
   newReminder.salonId=localStorage.getItem('salonId');
   newReminder.type=2; // 2= for creating reminder 5= for create custom reminder

   dispatch(marketingAction.addReminder(newReminder)).then((res) => {
    if (res?.data?.statusCode === 1) {
        setIsLoading(true);
      router.push(router.location.state.previousUrl);
      toast.success(res?.data?.responseData?.message);
      let timerId = setTimeout(()=>{
          router.push(updateMarketingData?.previousUrl);
      },500)
      setTimeoutId(timerId);
    }
  });
};

const addReminderTime = (e) => {
  const input = e.target.value;
  const regex = /^[0-9\b]+$/; 
  if (input === '' || regex.test(input)) {
      setReminderTriggerError(null);
      setReminderTime(input);
  }else{
    setReminderTriggerError('Enter valid time in number.');
  }
};

  return (
    <div className="h_n">
      <div className="body_container cm_bg_white">
        <TopHeader
        type='reminder'
        isLoadingStatus={isLoading}
        previousPageUrl={updateMarketingData?.previousUrl}
        headerButtonStatus={true}
        cancelButton={true}
        saveReminderButton={true}
        saveReminder={addReminder}
        cancelButtonText={"Back"}
        title={'Create Reminder'}
        {...props} 
        />
        <SideNav {...props} />
        <div className="new-setting-wrapper __reminder">
          <div className="container">
            <div className="row">
              <div className="_reminder_section ">
                <div className="new-stng-cmn _remind">
                  <h5 className="">{'Create Reminder'}</h5>
                  <p className="mt-2 mb-3">
                    Set when the reminders are sent before the clients
                    appointment.
                  </p>
                  <div className="row _update_custom">
                  <div className="col-sm-4">
                    <div className="input_group_u  _staff_member">
                      <span className="_field_label_vk">
                      Trigger Time
                      </span>
                      <input type="number" onChange={(e)=>addReminderTime(e)} value={reminderTime} className="form-control text-center"/>
                      {reminderTriggerError!=null?<p className="text-danger">{reminderTriggerError}</p>:null}
                      </div>
                
                  </div>
                  <div className="col-sm-8">
                  <div className={`input_group_u  _staff_member border_blue`}>
                  <span className="_field_label_vk">
                   Minutes, Hours, Days, Weeks, Month
                  </span>

                    <Select
                      defaultValue={null}
                      options={options}
                      onChange={(e) => onStatusChange("reminderTimeType", e)}
                    />
                    {reminderTimeError!=null?<p className="text-danger">{reminderTimeError}</p>:null}
                </div>
                  </div>
                </div>
               
                </div>
                <div className="new-stng-cmn _sms_reminder">
                  <div className="_image">
                    <img
                      src={require("../../assets/images/SMSPreviewSVG.svg")}
                      className="img-fluid mb-3"
                    />
                  </div>
                  <div className="_text">
                    <div className="_head d-flex">
                      <h4>SMS Reminder</h4>
                      <div className="salon_switch_container">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              onStatusChange(
                                "statusSMSreminder",
                                e?.target?.checked
                              )
                            }
                            checked={reminder?.statusSMSreminder?true:false}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <p className="mt-2 mb-4">£0.04/ message</p>
                    <p>
                      SMS reminders are sent to <b>all clients</b> before their
                      appointment to cut down on no-shows.
                    </p>
                    <p onClick={() => showPreview("SMS")} className="_link">
                      Preview SMS
                    </p>
                  </div>
                </div>

                <div className="new-stng-cmn _sms_reminder">
                  <div className="_image">
                  <img
                  src={require("../../assets/images/email_imag00.svg")}
                  className="img-fluid mb-3"
                  alt="email"
                />
                  </div>
                  <div className="_text">
                    <div className="_head d-flex">
                      <h4>Email Reminder</h4>
                      <div className="salon_switch_container">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              onStatusChange(
                                "statusEmailReminder",
                                e?.target?.checked
                              )
                            }
                            checked={reminder?.statusEmailReminder?true:false}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <p className="mt-2 mb-4">Free</p>
                    <p>
                      Email reminders are sent to <b>all clients</b> before
                      their appointment to cut down on no-shows.
                    </p>
                    <p onClick={() => showPreview("Email")} className="_link">
                      Preview Email
                    </p>
                  </div>
                </div>
              </div>
              <div className="cm_btns withcheckoutbtn mt-4 checkout__save_btn_mobile">
              <button className="btn btn-white" onClick={()=>{
                router.push(updateMarketingData?.previousUrl)
              }}>cancel</button>
              <button className="btn btn-dark-blue" onClick={()=>addReminder()}>Save </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateReminder;
