import React, {Component} from 'react';
import {Tab, Tabs, Accordion, Card, Button, Dropdown, Modal} from 'react-bootstrap'

import TopHeader from '../template/TopHeader';
import SideNav from '../template/SideNav';
import {connect} from "react-redux";
import {hairdresserActions, userActions} from "../../_actions";
import moment from 'moment';
var _ = require('lodash');
    class ClientDetail extends Component {
        constructor(props) {
            super(props);

            this.state = {
                bookingType:'past', //upcoming
                deleteClientModal:false,
                rescheduledCount:0,
                cancelledCount:0,
                noShowCount:0
            }
            this.getServiceName=this.getServiceName.bind(this);
            this.getBookingTime=this.getBookingTime.bind(this);
            this.getTotalPrice=this.getTotalPrice.bind(this);
            this.deleteClient=this.deleteClient.bind(this);
            this.checkReadOnly=this.checkReadOnly.bind(this);
        }

        checkEmail=(email)=>{
            let emailArr=email.split('@');
            if(emailArr[1]==='beuapp.com'){
                return false;
            }else{
                return true;
            }
        }

        componentDidMount() {
            var self = this;
            let params = {
                defaultRedirectTo:localStorage.getItem('defaultRedirectTo')
            };
            if (localStorage.getItem('accessToken')) {
                self.props.dispatch(userActions.profile(params))

                let {dispatch} = this.props;
                let hairdresserId = localStorage.getItem('userId');
                self.props.dispatch(hairdresserActions.getHairdresserClientDetail({
                    'userId': this.props.match.params.id,
                    'hairdresserId':localStorage.getItem('userId'),
                    'bookingType':self.state.bookingType
                }))
                    .then(function (){
                        let rescheduledCount=self.props.clientDetail?.count?_.find(self.props.clientDetail?.count,function (o){
                            return o._id===5;
                        }):{};
                        let cancelledCount=self.props.clientDetail?.count?_.find(self.props.clientDetail?.count,function (o){
                            return o._id===3;
                        }):{};
                        let noShowCount=self.props.clientDetail?.count?_.find(self.props.clientDetail?.count,function (o){
                            return o._id===6;
                        }):{};
                        self.setState({
                            rescheduledCount:rescheduledCount&&rescheduledCount.count?rescheduledCount.count:0,
                            cancelledCount:cancelledCount&&cancelledCount.count?cancelledCount.count:0,
                            noShowCount:noShowCount&&noShowCount.count?noShowCount.count:0
                        })
                    })
            }else{
                this.props.history.push('/')
            }
        }

        getServiceName(booking){
            let serviceName="";
            booking.servicesBooked.map((service,i) => {
                serviceName=serviceName+(serviceName==""?"":", ");
                serviceName=serviceName+service.service.serviceName;
            })
            return serviceName;
        }

        getslotTime(time) {

            let hr = parseInt(time / 60);
            let min = time - (hr * 60);
            //console.log("time",time,hr,min);
            return hr + ':' + (min === 0 ? '00' : min);
        }

        getBookingTime(booking){
            //console.log("booking",booking.bookingSlot);
            let totalTime=booking.bookingSlot[1]-booking.bookingSlot[0];
            let hr = parseInt(totalTime / 60);
            let min = totalTime - (hr * 60);
            let showTme=totalTime>60?hr+' hr '+min+' min':min+' min'
            return this.getslotTime(booking.bookingSlot[0])+" - "+this.getslotTime(booking.bookingSlot[1])+", "+showTme
        }

        getTotalPrice(booking){
            let serviceTotalPrice=0;
            booking.servicesBooked.map((service,i) => {
                serviceTotalPrice=serviceTotalPrice+service.servicePrice;
            })
            return serviceTotalPrice;
        }

        newBooking(){
            this.props.history.push('/newbooking/'+localStorage.getItem('salonId'));
        }

        deleteClient(){
            let self=this;
            // this.props.history.push('/clients/'+this.props.match.params.id)
            console.log('deleteClient');
            let params={};
            params.hairdresserId = localStorage.getItem('userId');
            params.userId = this.props.match.params.id;
            self.props.dispatch(hairdresserActions.deleteClient(params))
                .then(function (response) {
                    console.log('client response', response);

                    if (response.data.errorCode) {
                        if(response.data.errorCode==51){
                            self.setState({
                                emailError: response.data.messages
                            })
                        }else{
                            self.setState({
                                error: response.data.messages
                            })
                        }

                    } else {
                        self.props.history.push('/clients')
                    }
                })
        }

        checkReadOnly(){
            // console.log('check data ffffff',this.props.user.userInfo);
            if(this.props.user&&this.props.user.userInfo&&this.props.user.userInfo.hairdresser.isSalonOwner===1||this.props.user&&this.props.user.userInfo&&this.props.user.userInfo.hairdresser.isSalonOwner===0 && this.props.user.userInfo.hairdresser?.employmentTypeId===2){
                return true;
            }else{
                return false;
            }
        }

        handleClose = () => this.setState({
            deleteClientModal: false,
        })

        handleRemoveClientMember=()=>{
            this.setState({
                deleteClientModal:true
            })
        }

        render() {
            console.log("this.prop", this.props);
            return (
                <div className="h_n">
                    <div className="body_container  cm_bg_white client_detail_wrapper">
                        <TopHeader title={this.props.clientDetail.clientDetail?this.props.clientDetail.clientDetail.name:""} {...this.props}/>
                        <SideNav {...this.props}/>

                        <div className="container">

                            <div className="row">
                                {this.props.clientDetail.clientDetail?
                                <div className="col-lg-4">
                                    <div className={`d-flex justify-content-${this.checkReadOnly() ? 'between' : 'end'} mb-3 full_btn_with`}>
                                        {this.checkReadOnly()?
                                        <Dropdown className="drp-wrp">
                                            <Dropdown.Toggle variant="" className="btn btn-outline-dark" id="dropdown-basic">Options</Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href={"/clients/edit/"+this.props.match.params.id} className="text-center">Edit</Dropdown.Item>

                                                <Dropdown.Item href="#/action-2" style={{color: 'red'}} className="text-center" onClick={this.handleRemoveClientMember}>Delete</Dropdown.Item>

                                            </Dropdown.Menu>

                                        </Dropdown>
                                         :""}

                                        <button className="btn btn-dark  w-50" onClick={this.newBooking.bind(this)}>New Booking</button>
                                    </div>

                                    <div className="cm_right_panal client_checkout w-100 m-0 clnt-dtl-chkut">
                                        <div className="cm_bao clnt-ckout-head">
                                            <img
                                                src={this.props.clientDetail.clientDetail.localAvatar?this.props.clientDetail.clientDetail.localAvatar: require('../../assets/images/client-plachoder.jpg')} />
                                            <h3>{this.props.clientDetail.clientDetail.name}</h3>
                                            <p className="cm_fw_l">{this.checkEmail(this.props.clientDetail.clientDetail.email)?this.props.clientDetail.clientDetail.email:''}</p>
                                        </div>

                                        <ul className="some_detail">
                                            <li>
                                                <p>Mobile Number</p>
                                                <p className="cm_fw_l">{this.props.clientDetail.clientDetail.phoneNumber?this.props.clientDetail.clientDetail.phoneNumber:"-"}</p>
                                            </li>
                                            <li className={`cm_bg_date ${this.props.clientDetail.clientDetail.dob!=null&&this.props.clientDetail.clientDetail.dob!=""? '': 'na-brthday'}`}>
                                                {this.props.clientDetail.clientDetail.dob!=null&&this.props.clientDetail.clientDetail.dob!=""?(
                                                        <div>
                                                            <h4>{moment(this.props.clientDetail.clientDetail.dob,"DD-MM-YYYY").format('DD')}</h4>
                                                            <p className="cm_fw_l">{moment(this.props.clientDetail.clientDetail.dob,"DD-MM-YYYY").format('MMM')}</p>
                                                        </div>
                                                    )
                                                   : <div className="d-none">
                                                        <h4></h4>
                                                        <p></p>
                                                    </div>}

                                                <p>Birthday</p>
                                                <p className="cm_fw_l">{this.props.clientDetail.clientDetail.dob!=null&&this.props.clientDetail.clientDetail.dob!=""?this.props.clientDetail.clientDetail.dob:"-"}</p>
                                            </li>
                                            <li>
                                                <p>Notes</p>
                                                <p className="cm_fw_l">{this.props.clientDetail.clientDetail.note?this.props.clientDetail.clientDetail.note:"-"}</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                :""}

                                <div className="col-lg-8">
                                    <div className="cm_right_panal client_checkout w-100 m-0">
                                        <h3>Booking History</h3>

                                        <div className="d-flex justify-content-center cm_client_val cm_fw_l bkng-hstry-erp">
                                            <div><h2 className="cm_fw_b">{(this.props.clientDetail.pastBooking?this.props.clientDetail.pastBooking.length:0)+(this.props.clientDetail.upcomingBookings?this.props.clientDetail.upcomingBookings.length:0)}</h2>All Bookings</div>
                                            <div><h2 className="cm_fw_b">{this.state.rescheduledCount}</h2>Rescheduled</div>
                                            <div><h2 className="cm_fw_b">{this.state.cancelledCount}</h2>Cancelled</div>
                                            <div><h2 className="cm_fw_b">{this.state.noShowCount}</h2>No-Show</div>
                                        </div>

                                        <Accordion defaultActiveKey="1">
                                            <Card>

                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">Upcoming
                                                        ({this.props.clientDetail.upcomingBookings?this.props.clientDetail.upcomingBookings.length:0})</Accordion.Toggle>
                                                </Card.Header>

                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        <ul>
                                                            {this.props.clientDetail.upcomingBookings&&this.props.clientDetail.upcomingBookings.length>0?
                                                                this.props.clientDetail.upcomingBookings.map((booking,i) => (
                                                                    <li className="d-flex justify-content-between" key={booking._id}>
                                                                        <div className="cm_bg_uniq">
                                                                            <h4>{moment(booking.bookingDate).format('DD')}</h4>
                                                                            <p className="cm_fw_l">{moment(booking.bookingDate).format('MMM')}</p>
                                                                        </div>
                                                                        <div>
                                                                            <p>{this.getServiceName(booking)}</p>
                                                                            <p className="cm_fw_l">{this.getBookingTime(booking)}</p>
                                                                        </div>
                                                                        <div className="cm_fw_l text-right">
                                                                            <p>with {booking.hairdresser.name}</p>
                                                                            <p>£{this.getTotalPrice(booking)}</p>
                                                                        </div>
                                                                    </li>)):""}
                                                        </ul>
                                                    </Card.Body>
                                                </Accordion.Collapse>

                                            </Card>
                                            <Card>

                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">Past
                                                        ({this.props.clientDetail.pastBooking?this.props.clientDetail.pastBooking.length:0})</Accordion.Toggle>
                                                </Card.Header>

                                                <Accordion.Collapse eventKey="1">
                                                    <Card.Body>
                                                        <ul>
                                                            {this.props.clientDetail.pastBooking&&this.props.clientDetail.pastBooking.length>0?
                                                                this.props.clientDetail.pastBooking.map((booking,i) => (
                                                            <li className="d-flex justify-content-between" key={booking._id}>
                                                                <div className="cm_bg_uniq">
                                                                    <h4>{moment(booking.bookingDate).format('DD')}</h4>
                                                                    <p className="cm_fw_l">{moment(booking.bookingDate).format('MMM')}</p>
                                                                </div>
                                                                <div>
                                                                    <p>{this.getServiceName(booking)}</p>
                                                                    <p>{this.getBookingTime(booking)}</p>
                                                                </div>
                                                                <div className="cm_fw_l text-right">
                                                                    <p>with {booking.hairdresser.name}</p>
                                                                    <p>£{this.getTotalPrice(booking)}</p>
                                                                </div>
                                                            </li>)):""}
                                                        </ul>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </div>
                                </div>


                            </div>

                        </div>

                    </div>

                    <Modal show={this.state.deleteClientModal} onHide={this.handleClose} className="bg_white_modal text-center">
                        <i className="fa fa-times cm_hide_mod" onClick={this.handleClose} />
                        <Modal.Body>
                            <div className="p-5">
                                <img src={require('../../assets/images/delete-salon-img.png')} className="img-fluid mb-3" />
                                <h3 style={{fontSize: '38px', fontWeight: '400'}} className="mb-4">Delete Client</h3>
                                <p style={{fontSize: '23px', fontWeight: '300'}}>Are you sure you want to delete the client? This will remove the client from the salon database.</p>

                                <div className="stap-action mt-5">
                                    <button className="btn btn-outline-dark mr-3" onClick={this.handleClose}>Cancel</button>
                                    <button className="btn btn-pink" onClick={this.deleteClient.bind('this')}>Delete Client</button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                </div>
            )
        }
    }

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(ClientDetail);
