import promise from 'bluebird'
import { productConstants } from '../_constants/productConstants';

import {productService} from "../_services";

export const productAction = {
 addProduct,
 listProduct,
 updateProduct,
 deleteProduct,
 getProduct,
 selectCheckoutItem,
 selectedCheckoutList,
 selectedClient,
 getProductsList,
};

/************************************** Product Action ******************************************************/

function addProduct(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            productService.addProduct(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function listProduct(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            productService.listProduct(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}


function getProductsList(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            productService.getProductsList(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function getProduct(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            productService.getProduct(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function deleteProduct(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            productService.deleteProduct(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function updateProduct(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            productService.updateProduct(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}



function selectCheckoutItem(data){
    return function (dispatch) {
        dispatch({ type: productConstants.SELECT_CHECKOUT_ITEM, data })
    };
  };


  function selectedCheckoutList(data){
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            resolve(dispatch({type: productConstants.SELECTED_CHECKOUT_LIST, data}))
        })
    };
  };

  function selectedClient(data){
    return function (dispatch) {
        dispatch({ type: productConstants.SELECTED_CLIENT, data })
    };
  };
