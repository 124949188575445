

import React, { useEffect, useState, } from 'react'

function SettingInputControls(props) {
       const [currentSetting,setCurrentSetting] = useState(props?.data);

       const updateMarketingSetting =(type,status)=>{
        let updatedData = {...currentSetting,[type]:status?1:0};
         setCurrentSetting(updatedData)
         props.updateMarketingSetting(updatedData);
    }

  return(
    <>
    {props?.loginType===1? 
    <>
     <div className='switch_conatiner pl-4 sssssssssssssss'>
        <div className='switch_box _setting_Name_label'>
           {/* <h5 className='_mt_35'>{currentSetting?.selfEmpName}</h5> */}
        </div>
        <div className='switch_box'>
          {props?.indexNum===0?<h5>Salon Consent</h5>:null} 
        </div>
        <div className='switch_box'>
           {props?.indexNum===0?<h5>Self-Employed Consent</h5>:null}
        </div>
    </div>



    <div className='switch_conatiner pl-4'>
    <div className='switch_box _setting_Name_label'>
    <h5 className='_mt_35'>{currentSetting?.selfEmpName}</h5>
    </div>
    <div className='switch_box'>
       {/* {props?.indexNum===0?<h5>Salon Consent</h5>:null}  */}
        <div className={props?.indexNum>0?'salon_switch_container pl-0 _mt_35':'salon_switch_container pl-0'}>
        <label className="switch" >
            <input type="checkbox" checked={currentSetting.salonConsent===1?true:false} onChange={(e)=>updateMarketingSetting('salonConsent',e.target.checked)}/>
            <span className="slider round"></span>
        </label>
    </div>
    </div>
    <div className='switch_box'>
       {/* {props?.indexNum===0?<h5>Self-Employed Consent</h5>:null} */}
        <div className={props?.indexNum>0?'salon_switch_container pl-0 _mt_35':'salon_switch_container pl-0'}>
            <label className={props?.loginType===1?"switch checkbox_disabled":"switch"} >
                <input type="checkbox" checked={currentSetting.selfEmpConsent===1?true:false} disabled={props?.loginType===1?true:false}/>
                <span className="slider round"></span>
            </label>
        </div>
    </div>
    </div>
    </>:
    <div className='switch_conatiner pl-4'>
    <div className='switch_box'>
      <h5>Salon Consent</h5>
        <div className='salon_switch_container pl-0'>
        <label className={props?.loginType===0?"switch checkbox_disabled":"switch"} >
            <input type="checkbox"  checked={currentSetting.salonConsent===1?true:false}/>
            <span className="slider round"></span>
        </label>
    </div>
    </div>
    <div className='switch_box'>
        <h5>Your Consent</h5>
        <div className='salon_switch_container pl-0'>
            <label className="switch" >
                <input type="checkbox" onChange={(e)=>updateMarketingSetting('selfEmpConsent',e.target.checked)}  checked={currentSetting.selfEmpConsent===1?true:false} />
                <span className="slider round"></span>
            </label>
        </div>
    </div>
    </div>
}
   
    </>
)

}

export default SettingInputControls;
