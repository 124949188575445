import promise from 'bluebird'
import {marketingService} from "../_services";
import { marketingConstants } from '../_constants';


export const marketingAction = {
    createMarketingSetting,
    getSettings,
    getMarketingSetting,
    updateSetting,
    marketingUpdateSettingData,
    addReminder,
    removeReminder,
    reminderTimeType,
    updateMarketingSetting,
    setSalonMarketingSetting,
    marketingSettingNavigation
};

/************************************** Marketing Action ******************************************************/

function createMarketingSetting() {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            marketingService.createMarketingSetting()
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function addReminder(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            marketingService.addReminder(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}


function updateSetting(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            marketingService.updateSetting(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function removeReminder(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            marketingService.removeReminder(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}


function getSettings() {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            marketingService.getSettings()
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}


function getMarketingSetting() {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            marketingService.getMarketingSetting()
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}


function updateMarketingSetting(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            marketingService.updateMarketingSetting(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}


function marketingUpdateSettingData(data) {
    return function (dispatch) {
        dispatch({ type: marketingConstants.UPDATE_MARKETING_DATA, data })
    }
};


function setSalonMarketingSetting(data) {
    return function (dispatch) {
        dispatch({ type: marketingConstants.SALON_MARKETING_SETTING, data })
    }
};

function marketingSettingNavigation(data) {
    return function (dispatch) {
        dispatch({ type: marketingConstants.NAVIGATION_MARKETING_SETTING, data })
    }
};




function reminderTimeType(type){
    switch (type) {
        case 1:
            return 'minutes';
        case 2:
            return 'hours';
        case 3:
            return 'days';
        case 4:
            return 'weeks';
        case 5:
            return 'months';
        default:
            return ''; 
    }
  }


