import React, { Component } from "react";
import {connect} from "react-redux";
class FooterBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //bookingType:'past' //upcoming
        }
    }
    render() {
        return (
            <div className="container">
                  <div className="row">
                    <div className="col-sm-12 d-flex footer-btn fix-footer">
                        <button className="btn btn-bhite btn-block" onClick={() => this.props.mobileCancel()} >Cancel</button>
                        <button className="btn btn-blue btn-block" onClick={() => this.props.mobileContinue()} disabled={this.props.bookingState.isMobileEnableState}>Continue</button>
                    </div>
                  </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(FooterBtn);