import React, { Component } from 'react';

class LoaderSetting extends Component {

    constructor(props){
        super(props);
        this.state = {

        }
    }



    
    render() {
        return (
            <>
                <div className={`lds-spinner ${this.props.clsName}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </>
        )
    }

}




export default LoaderSetting;





