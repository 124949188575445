import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import siteSetting from "../../config/env";
import toast from "react-toast-notification";
import TopHeader from "../template/TopHeader";
import SideNav from "../template/SideNav";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { marketingAction } from "../../_actions/marketing.actions";

function CreateCustomReminder(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [reminderTimeTypeOption, setReminderTimeTypeOption] = useState(siteSetting?.reminderTimeOptions);
  const [triggerTypeOptions, setTriggerTypeOptions] = useState(siteSetting?.triggerTypeOptions);
  const [timeoutId, setTimeoutId] = useState(null);
  const [customReminderError, setCustomReminderError] = useState({});
  const [customReminderData, setCustomReminderData] = useState({
    automationText:siteSetting?.SMSText?.customReminderText
  });
  const router = useHistory();
  const updateMarketingData = useSelector(
    (state) => state?.updateMarketingData
  );

  const dispatch = useDispatch();
  const editContent = useRef();

  useEffect(() => {
    return () => {
      if (timeoutId != null) {
        clearTimeout(timeoutId);
      }
    };
    /* eslint-disable-next-line */
  }, []);

  const showPreview = (type) => {
    let message = "";
    if (type === "SMS") {
      
      message = customReminderData?.automationText;
      router.push({
        pathname: "/preview",
        state: {
          previousUrl: router?.location?.pathname,
          previewText: message,
          type: type,
        },
      });
    } else {
      if (type === "Email") {
        message = siteSetting?.SMSText?.reminder;
        router.push({
          pathname: "/email-preview",
          state: {
            previousUrl: router?.location?.pathname,
            previewText: message,
            type: "Reminder",
          },
        });
      }
    }
  };


  const onChange = (data, type) => {

    console.log('check automation test dsfsdfsfs',type,data);

    setCustomReminderError({
      ...customReminderError,
      [type]:``,
    });

    setIsLoading(true);
    switch (type) {
      case "title":
        setCustomReminderData({
          ...customReminderData,
          title: data?.target?.value,
        });
        break;

      case "description":
        setCustomReminderData({
          ...customReminderData,
          description: data?.target?.value,
        });
        break;

      case "automationText":
        setCustomReminderData({
          ...customReminderData,
          automationText: data?.target?.value,
        });
        break;

      case "triggerType":
        setCustomReminderData({
          ...customReminderData,
          triggerType: data,
        });
        break;
      case "reminderTime":
        const regex = /^[0-9\b]+$/;
        if (data?.target?.value === "" || regex.test(data?.target?.value)) {
          setCustomReminderError({
            ...customReminderError,
            reminderTime: null,
          });
          setCustomReminderData({
            ...customReminderData,
            reminderTime: data?.target?.value,
          });
        } else {
          setCustomReminderError({
            ...customReminderError,
            reminderTime: "Enter valid time in number.",
          });
        }

        break;
      case "reminderTimeType":
        setCustomReminderData({ ...customReminderData, reminderTimeType: data });
        break;

      default:
        data = data ? 1 : 0;
        setCustomReminderData({ ...customReminderData, [type]: data });
        break;
    }
  };

  function validate() {
    let data = customReminderData;
    const requiredFields = ["title", "description", "automationText", "triggerType", "reminderTime", "reminderTimeType", "statusSMSreminder"];
    for (const field of requiredFields) {
      if (!(field in data)) {
        console.error();
        setCustomReminderError({
          ...customReminderError,
          [field]:`Field is required.`,
        });
        return false;
      }
    }

    if (typeof data.title !== "string" || data.title.length === 0 ) {
      return false;
    }
  
    if (typeof data.description !== "string" || data.description.length === 0) {
      return false;
    }
  
    if (typeof data.automationText !== "string" || data.automationText.length === 0 ) {
      return false;
    }
  
    if (!data.triggerType || typeof data.triggerType !== "object" || typeof data.triggerType.value !== "number" ) {
      return false;
    }
  
    if (!/^[1-9][0-9]*$/.test(data.reminderTime)) {
      return false;
    }
  
    if (!data.reminderTimeType || typeof data.reminderTimeType !== "object" || typeof data.reminderTimeType.value !== "number") {
      return false;
    }
  
    if (typeof data.statusSMSreminder !== "number" || ![0, 1].includes(data.statusSMSreminder)) {
      return false;
    }
  
    return true;
  }

  const addReminder = () => {
  
    setIsLoading(false);
    let newReminder;
  
    if(!validate()){
      return;
    }

    newReminder = {
      reminder: {
        ...customReminderData,
        reminderTime: +customReminderData?.reminderTime,
        reminderTimeType: customReminderData?.reminderTimeType?.value,
        title: customReminderData?.title,
        description: customReminderData?.description,
        smsText: customReminderData?.automationText,
        statusSMSreminder: customReminderData?.statusSMSreminder?customReminderData?.statusSMSreminder:0,
        statusEmailReminder: customReminderData?.statusEmailReminder?customReminderData?.statusEmailReminder:0,
        triggerType:customReminderData?.triggerType?.value,
      },
    };

 
    newReminder.salonId = localStorage.getItem("salonId");
    newReminder.type = 5; // 2= for creating reminder 5= for create custom reminder

    dispatch(marketingAction.addReminder(newReminder)).then((res) => {
      if (res?.data?.statusCode === 1) {
        setIsLoading(true);
        router.push(router.location.state.previousUrl);
        toast.success(res?.data?.responseData?.message);
        let timerId = setTimeout(() => {
          router.push(updateMarketingData?.previousUrl);
        }, 500);
        setTimeoutId(timerId);
      }
    });
  };


 const addVariable =(text)=>{
    let prevText =  customReminderData.automationText?customReminderData.automationText:'';
    setCustomReminderData({
      ...customReminderData,
      automationText:prevText+' '+text+' '
    });

    editContent.current.focus();
  }


  return (
    <div className="h_n">
      <div className="body_container cm_bg_white">
        <TopHeader
          type="reminder"
          isLoadingStatus={isLoading}
          previousPageUrl={updateMarketingData?.previousUrl}
          headerButtonStatus={true}
          cancelButton={true}
          saveReminderButton={true}
          saveReminder={addReminder}
          cancelButtonText={"Back"}
          title={"Custom Automation"}
          {...props}
        />
        <SideNav {...props} />
        <div className="new-setting-wrapper __reminder">
          <div className="container">
            <div className="row">
              <div className="_reminder_section ">
                <div className="new-stng-cmn _remind">
                  <h5 className="">Create Custom Automation</h5>
                  <p className="mt-2 mb-3">
                    Create a custom automation that sends a SMS or email based
                    on your requirements.
                  </p>
             
                  <form className="">
                    <div className={`form-group input_group_u  _staff_member`}>
                      <span className="_field_label_vk">Automation name</span>
                      <input
                        type="text"
                        onChange={(e) => onChange(e, "title")}
                        value={customReminderData?.title}
                        className="form-control"
                      />
                      {(customReminderError?.title!==''&&customReminderError?.title!==undefined)? (
                        <p className="text-danger">
                          {customReminderError?.title}
                        </p>
                      ) : null}
                    </div>
                    <div className={`form-group input_group_u  _staff_member`}>
                      <span className="_field_label_vk">
                        Automation Description
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => onChange(e, "description")}
                        value={customReminderData?.description}
                      />
                      {(customReminderError?.description!==''&&customReminderError?.description!==undefined)? (
                        <p className="text-danger">
                          {customReminderError?.description}
                        </p>
                      ) : null}
                    </div>
                    <div className={`form-group input_group_u  _staff_member`}>
                    <span className="_field_label_vk">Automation Text</span>
                    <div className="mt-2 editable-border">
                      <div className="editable_span_prent"> 
                      <span className="editableContentButton bg_1" onClick={()=>addVariable("{{SalonName}}")} >Salon Name</span>
                    <span className="editableContentButton bg_2" onClick={()=>addVariable("{{Client}}")}>Client’s Name</span> 
                    <span className="editableContentButton bg_3" onClick={()=>addVariable("{{StylistName}}")}>Stylist’s Name</span> 
                    <span className="editableContentButton bg_4" onClick={()=>addVariable("{{DateTime}}")}>Next Appointment Date</span>
                    {/*<span className="editableContentButton bg_5" onClick={()=>addVariable("{{BookingLink}}")}>Booking Link</span>*/}
                      </div>
                    <textarea
                    contentEditable={true}
                    ref={editContent}
                    value={customReminderData?.automationText}
                    onChange={(e) => onChange(e, "automationText")}
                    className={'newEditableContent'}
                    />
                    </div>
            
                    {(customReminderError?.automationText!==''&&customReminderError?.automationText!==undefined)? (
                      <p className="text-danger">
                        {customReminderError?.automationText}
                      </p>
                    ) : null}
                  </div>
                    <div
                      className={`form-group input_group_u  _staff_member border_blue`}
                    >
                      <span className="_field_label_vk">Trigger</span>
                      <Select
                        defaultValue={null}
                        options={triggerTypeOptions}
                        value={customReminderData?.triggerType}
                        onChange={(e) =>onChange(e, "triggerType")}
                      />
                      {(customReminderError?.triggerType!==''&&customReminderError?.triggerType!==undefined)? (
                        <p className="text-danger">
                          {customReminderError?.triggerType}
                        </p>
                      ) : null}
                    </div>
                    <div className="row _update_custom">
                      <div className="col-sm-4">
                        <div className="input_group_u  _staff_member">
                          <span className="_field_label_vk">Trigger Time</span>
                          <input
                            type="number"
                            onChange={(e) => onChange(e, "reminderTime")}
                            value={customReminderData?.reminderTime}
                            className="form-control text-center"
                          />
                          {(customReminderError?.reminderTime!==''&&customReminderError?.reminderTime!==undefined)? (
                            <p className="text-danger">
                              {customReminderError?.reminderTime}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div
                          className={`input_group_u  _staff_member border_blue`}
                        >
                          <span className="_field_label_vk">
                            Minutes, Hours, Days, Weeks, Month
                          </span>
                          <Select
                            defaultValue={null}
                            value={customReminderData?.reminderTimeType}
                            options={reminderTimeTypeOption}
                            onChange={(e) => onChange(e, "reminderTimeType")}
                          />
                          {(customReminderError?.reminderTimeType!==''&&customReminderError?.reminderTimeType!==undefined)? (
                            <p className="text-danger">
                              {customReminderError?.reminderTimeType}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="new-stng-cmn _sms_reminder">
                  <div className="_image">
                    <img
                      src={require("../../assets/images/SMSPreviewSVG.svg")}
                      className="img-fluid mb-3"
                    />
                  </div>
                  <div className="_text">
                    <div className="_head d-flex">
                      <h4>SMS</h4>
                      <div className="salon_switch_container">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              onChange(e?.target?.checked, "statusSMSreminder")
                            }
                            checked={customReminderData?.statusSMSreminder ? true : false}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <p className="mt-2 mb-4">£0.04/ message</p>
                    <p>
                    SMS messages are sent to <b>all clients</b> to the phone number you have saved on client record.
                    </p>
                    <p onClick={() => showPreview("SMS")} className="_link">
                      Preview SMS
                    </p>
                  </div>
                </div>

                {/*  <div className="new-stng-cmn _sms_reminder">
                //   <div className="_image">
                //  <img
                //       src={require("../../assets/images/email_imag00.svg")}
                //       className="img-fluid mb-3"
                //       alt="email"
                //     />
                //   </div>
                //  <div className="_text">
                //     <div className="_head d-flex">
                //       <h4>Email</h4>
                //       <div className="salon_switch_container">
                //         <label className="switch">
                //           <input
                //             type="checkbox"
                //             onChange={(e) =>
                //               onChange(
                //                 e?.target?.checked,
                //                 "statusEmailReminder"
                //               )
                //             }
                //             checked={
                //               customReminderData?.statusEmailReminder ? true : false
                //             }
                //           />
                //           <span className="slider round"></span>
                //         </label>
                //       </div>
                //     </div>
                //     <p className="mt-2 mb-4">Free</p>
                //     <p>
                //     Emails are sent to <b>all clients</b> to the email address you have saved on their client record.
                //     </p>
                //     <p  className="_link disabled">
                //       Preview Email
                //     </p>
                //           </div> 
                        
                     </div> */}
              </div>
              <div className="cm_btns withcheckoutbtn mt-4 checkout__save_btn_mobile">
              <button className="btn btn-white" onClick={()=>{
                router.push(updateMarketingData?.previousUrl)
              }}>cancel</button>
              <button className="btn btn-dark-blue" onClick={()=>addReminder()}>Save </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateCustomReminder;
