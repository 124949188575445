import React, {Component} from 'react';
import {connect} from "react-redux";
import {userActions} from "../../_actions";
import StarRatings from "react-star-ratings";
import dateFormat from "dateformat";

function getslotTime(time){
    let hr=parseInt(time/60);
    let min=time-(hr*60)
    return hr+':'+(min===0?'00':min);
}

class Review extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currencySymbol:'£'
        }
        this.getDate=this.getDate.bind(this);
    }

    componentDidMount() {
        document.body.classList.add('login-home-temp');
        var self = this;
        let params = {
            userId:localStorage.getItem('userId')
        };
        if (localStorage.getItem('accessToken')) {
            self.props.dispatch(userActions.review(params))
                .then(function (response) {

                })
        }
    }

    getDate(info) {
        if (info) {
            var date = new Date(info)
            //return date.toLocaleString()
            return dateFormat(date, "dddd, dd mmm yyyy").toLocaleString();
        }
        return null;
    }

    render() {
        let {userReview}=this.props;
        console.log('userReview',userReview);
        return (
            <React.Fragment>
                <h2 className="myprofile_h2">Reviews </h2>
                {userReview.reviews&&userReview.reviews.length >0 ? userReview.reviews.map((review, i) => (
            <div className="contentBg" key={'user_review_'+review._id}>
                <div className="review-section">
                    <div className="client_review_first">
                        <div><img src={review.hairdresser.userImage?review.hairdresser.userImage.circleImage:require('../../assets/images/client_dp.png')} className="review-circle-img"/></div>
                        <div><span>{review.hairdresser.name}</span><br/><span>{review.bookingInfo.salonId?review.bookingInfo.salonId.salonName:''}</span></div>
                        <div className="push star-size">
                        <span>
                             <StarRatings
                                 rating={review.rating?parseInt(review.rating):0}
                                 starRatedColor="#aa9567"
                                 numberOfStars={5}
                                 name='rating'
                                 starDimension="30px"
                                 starSpacing="0px"
                             />
                        </span>
                        </div>
                    </div>
                    <div className="booking_dateinfo" style={{alignItems: 'center'}}>
                        <div className="review_star">
                            <h6>{review.bookingInfo.servicesBooked.map((service, index) => (
                                <h6 key={'service_'+index}>{service.service.serviceName}, {review.bookingInfo.currencySymbol&&review.bookingInfo.currencySymbol!==''?review.bookingInfo.currencySymbol:"£"}{service.servicePrice.toFixed(2)} </h6>
                            ))}</h6>
                        </div>
                        <div className="date_info">
                            <h5 className="text-right">{this.getDate(review.bookingInfo.bookingDate)}</h5>
                            <h5 className="text-right">{getslotTime(review.bookingInfo.bookingSlot[0])} - {getslotTime(review.bookingInfo.bookingSlot[1])}</h5>
                        </div>
                    </div>
                    <div className="">
                        <p>{review.review}</p>
                    </div>
                    <div className="review-btn">
                        {/*<button type="submit" className="btn btn-edit btn-sm ">Edit</button>*/}
                        {/*<button type="submit" className="btn btn-sm btn-edit delete">Delete</button>*/}
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
                )):<div className="contentBg">
                    <h3 className="text-center">No Reviews</h3>
                </div>}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { hairdresser,salon,hairdresserReview,user,
        setting,userReview } = state;
    return {
        hairdresser,
        salon,
        hairdresserReview,
        user,
        setting,
        userReview
    };
}

export default connect(mapStateToProps)(Review);
