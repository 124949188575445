import axios from 'axios';
import API from "../config/API";
import siteSetting from "../config/env";

export const settingService = {
    getCancellationPolicy,
    updateCancellationPolicy,
    updateNoShowProtectionFee
};


function updateCancellationPolicy(params) {
    let accessToken=localStorage.getItem('accessToken')
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    return axios.put(API.UPDATE_CANCELLATION_POLICY,params, {headers:headers})
}

function updateNoShowProtectionFee(params) {
    let accessToken=localStorage.getItem('accessToken')
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    return axios.put(API.UPDATE_NO_SHOW_PROTECTION_FEE,params, {headers:headers})
}


function getCancellationPolicy(params) {
    let accessToken=localStorage.getItem('accessToken')
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };

    return axios.post(API.GET_CANCELLATION_POLICY,params,{headers:headers})
}

