import { clientpaymentConstants } from '../_constants';


const initialState = {
  paymentList: {},
};

export function paymentList(state = initialState, action) {
  //console.log('action.type',action);
  switch (action.type) {
      case clientpaymentConstants.CLIENT_PYAMENT_LIST_SUCCESS:
      let clientPaymentList = action.clientPaymentList;
      return clientPaymentList;

    case clientpaymentConstants.CLIENT_PYAMENT_LIST_ERROR:
      return {};

    case clientpaymentConstants.CLIENT_PYAMENT_LIST_CLEAR:
      return {};

    default:
      return state
  }
}

const payoutListInitialState = {
    paymentList: {},
};

export function payoutList(state = payoutListInitialState, action) {
    //console.log('action.type',action);
    switch (action.type) {
        case clientpaymentConstants.PAYOUT_LIST_SUCCESS:
            let payoutList = action.Success;
            return payoutList;

        case clientpaymentConstants.PAYOUT_LIST_REQUEST:
            return {};

        case clientpaymentConstants.PAYOUT_LIST_ERROR:
            return {};

        default:
            return state
    }
}

const cardReaderListState = {
    cardReaderList: [],
};

export function cardReaderList(state = cardReaderListState, action) {
    //console.log('action.type',action);
    switch (action.type) {
        case clientpaymentConstants.CARD_READER_LIST_SUCCESS:
            let cardReaderList = action.Success;
            return cardReaderList;

        case clientpaymentConstants.CARD_READER_LIST_REQUEST:
            return {};

        case clientpaymentConstants.CARD_READER_LIST_ERROR:
            return {};

        default:
            return state
    }
}
