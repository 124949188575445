import { userConstants } from '../_constants';
import { productConstants } from '../_constants/productConstants';


const initialState = {};
const products = [];

export function selectCheckoutItem(state = initialState, action) {
  switch (action.type) {
    case productConstants.SELECT_CHECKOUT_ITEM:
      let productData = action.data;
      return productData;
    default:
      return state
  }
}


export function selectedCheckoutList(state = products, action) {
  switch (action.type) {
    case productConstants.SELECTED_CHECKOUT_LIST:
      let productData = action.data;
      return productData;
    default:
      return state
  }
}


