export const clientpaymentConstants = {
    CLIENT_PYAMENT_LIST_SUCCESS: 'CLIENT_PYAMENT_LIST_SUCCESS',
    CLIENT_PYAMENT_LIST_ERROR: 'CLIENT_PYAMENT_LIST_ERROR',
    CLIENT_PYAMENT_LIST_CLEAR: 'CLIENT_PYAMENT_LIST_CLEAR',
    CLIENT_PYAMENT_LIST_REQUEST:'CLIENT_PYAMENT_LIST_REQUEST',
    GET_BANK_REQUEST:'GET_BANK_REQUEST',
    GET_BANK_SUCCESS:'GET_BANK_SUCCESS',
    GET_BANK_ERROR:'GET_BANK_ERROR',
    PAYOUT_LIST_REQUEST:'PAYOUT_LIST_REQUEST',
    PAYOUT_LIST_SUCCESS:'PAYOUT_LIST_SUCCESS',
    PAYOUT_LIST_ERROR:'PAYOUT_LIST_ERROR',
    CARD_READER_LIST_REQUEST:'CARD_READER_LIST_REQUEST',
    CARD_READER_LIST_SUCCESS:'CARD_READER_LIST_SUCCESS',
    CARD_READER_LIST_ERROR:'CARD_READER_LIST_ERROR',
};
