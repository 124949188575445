import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import TopHeader from '../template/TopHeader';
import SideNav from '../template/SideNav';
import {hairdresserActions, userActions} from "../../_actions";
import siteSetting from "../../config/env";
import {connect} from "react-redux";
import {Addicon} from "../SvgIcon";
import {hairdresserService} from "../../_services";
import Lottie from 'react-lottie';
import animationData from '../../assets/animation-data/loader.json';
import { Modal } from 'react-bootstrap';
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

var _ = require('lodash');
let userIdHolder = [];
const newCalendar = [];
let timeSlot=[];
for(let i=0;i<24*60;i+=5){
    timeSlot.push(i)
}
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
class Services extends Component {
    constructor(props) {
        super(props);

        this.state = {
            latitude : siteSetting.default_latitude,
            longitude : siteSetting.default_longitude,
            hairdresserList:[],
            selectedHairdresserId:'',
            selectedHairdresser:{},
            isEditBookingInterval:false,
            hairdresserBookingInterval:5,
            hairdresserBookingIntervalError:''
        }
        this.deleteService=this.deleteService.bind(this);
    }
    componentDidMount() {
        var self = this;
        let params = {};
        if (localStorage.getItem('accessToken')) {
            //self.props.dispatch(userActions.profile(params))
            document.body.classList.add('login-home-temp');

        let { dispatch } = this.props;

        let hairdresserId=localStorage.getItem('userId');
        let params1 = {
            searchKey:this.state.searchKey,
            'hairdresserId':hairdresserId,
            page:1,
            listType:1
        };
        // self.props.dispatch(hairdresserActions.hairdresserStaffService(params1))
            self.props.dispatch(hairdresserActions.hairdresserStaffRota(params1))
            .then(function (response) {
                console.log("response######",response)
                //self.state.selectedHairdresserId===''||
                if(self.props.match.params.hairdresserId&&self.props.match.params.hairdresserId!==""){
                    let selectedHairdresser= _.find(response.data.data.rota, function (o) {
                        return o._id === self.props.match.params.hairdresserId;
                    })
                    self.setState({
                        selectedHairdresserId:self.props.match.params.hairdresserId,
                        selectedHairdresser:selectedHairdresser,
                        hairdresserBookingInterval:selectedHairdresser.hairdresserInfo.hairdresserBookingInterval?selectedHairdresser.hairdresserInfo.hairdresserBookingInterval:5
                    })
                    params.hairdresserId=self.props.match.params.hairdresserId
                    self.props.dispatch(hairdresserActions.getHairdresserService(params))
                        .then(function (response) {

                        });
                }else{
                    // self.setState({
                    //     selectedHairdresserId:response.data.data.total[0]._id,
                    //     selectedHairdresser:response.data.data.total[0],
                    //     hairdresserBookingInterval:response.data.data.total[0].hairdresserInfo.hairdresserBookingInterval?response.data.data.total[0].hairdresserInfo.hairdresserBookingInterval:5
                    // })
                    // params.hairdresserId=response.data.data.total[0]._id
                    self.setState({
                        selectedHairdresserId:response.data.data.rota[0]._id,
                        selectedHairdresser:response.data.data.rota[0],
                        hairdresserBookingInterval:response.data.data.rota[0].hairdresserInfo.hairdresserBookingInterval?response.data.data.rota[0].hairdresserInfo.hairdresserBookingInterval:5
                    })
                    params.hairdresserId=response.data.data.rota[0]._id
                    self.props.dispatch(hairdresserActions.getHairdresserService(params))
                        .then(function (response) {

                        });
                }
            })
        }else{
            this.props.history.push('/')
        }
    }

    changeHairdresser(hairdresserId, Hairdresser) {
        this.setState({
            hairdresserId,
            selectedHairdresserId:hairdresserId,
            selectedHairdresser:Hairdresser,
            hairdresserBookingInterval:Hairdresser.hairdresserInfo.hairdresserBookingInterval?Hairdresser.hairdresserInfo.hairdresserBookingInterval:5
        })

        let params = {};
        let self = this;
        params.hairdresserId = hairdresserId;

        self.props.dispatch(hairdresserActions.getHairdresserService(params))
            .then(function (response) {

            });
    }

    checkSelectedService(serviceId) {
        let selectedService = this.state.selectedService;
        let isCheck = _.find(selectedService, function (o) {
            return o._id === serviceId;
        })
        //console.log('isCheck',serviceId,isCheck)
        if (isCheck) {
            return true;
        } else {
            return false;
        }
    }

    selectService(service) {

        let serviceId=service.serviceId?service.serviceId:service._id;
        this.props.history.push('/services/'+this.state.selectedHairdresserId+'/'+serviceId)
    }

    deleteService(categoryId,service){
        // console.log("service",service);
        let self=this;
        let serviceId=service.serviceId?service.serviceId:service._id;
        let params={
            categoryId:categoryId,
            serviceId:serviceId,
            hairdresserId:this.state.selectedHairdresser.hairdresserInfo._id,
            deviceToken:'dfsdfdsf'
        };
        hairdresserService.deleteService(params)
            .then(function (response) {
                console.log('client response', response);
                if(response.data.errorCode==0){
                    self.changeHairdresser(self.state.selectedHairdresserId,self.state.selectedHairdresser)
                    self.setState({
                        successMessage:'Service has been delete successfully!'
                    })
                }
            })
    }
    openEditInterval = ()=>{
        this.setState({
            isEditBookingInterval: true,
        })

    }
    handleClose = () => this.setState({
        isEditBookingInterval: false,
    })

    onChanged=(e)=>{
        this.setState({[e.target.name]: e.target.value});
        if (e.target.name === 'hairdresserBookingInterval') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    hairdresserBookingIntervalError: 'Please enter valid time'
                })
            } else if(e.target.value%5!==0){
                this.setState({
                    hairdresserBookingIntervalError: 'Booking interval has to be a multiple of 5 minutes.',
                })
            }else{
                this.setState({
                    hairdresserBookingIntervalError: '',
                })
            }
        }
    }

    updateInterval=()=>{
        let self=this;
        // console.log("this.state",this.state);
        let params={
            hairdresserBookingInterval:this.state.hairdresserBookingInterval,
            hairdresserId:this.state.selectedHairdresser.hairdresserInfo._id,
        };
        hairdresserService.updateInterval(params)
            .then(function (response) {
                console.log('client response', response);
                if(response.data.statusCode==1){
                    self.setState({
                        successMessage:'Booking Interval updated successfully',
                        isEditBookingInterval: false,
                    })
                    let hairdresserId=localStorage.getItem('userId');
                    let params1 = {
                        searchKey:self.state.searchKey,
                        'hairdresserId':hairdresserId,
                        page:1,
                        listType:1
                    };
                    self.props.dispatch(hairdresserActions.hairdresserStaffRota(params1))
                }
            })
    }

    render(){
        console.log("this.props",this.props);
        console.log("this.state",this.state);
        let { hairdresserServices, salon } = this.props;
        return(
            <div className="h_n">
            <div className="body_container cm_bg_white">

                <TopHeader title="Services" {...this.props}/>
                <SideNav {...this.props}/>
                {this.state.selectedHairdresser.name&&hairdresserServices.hairdresserSelectedServices && hairdresserServices.hairdresserSelectedServices.length >0 ?
                <div className="container">
                <div className="cm_box_view">

                    <div className="row d-flex flex-nowrap cm_over_auto">
                        {this.props.staffRota&&this.props.staffRota.rota&&this.props.staffRota.rota.length>0?
                            this.props.staffRota.rota.map((hairdresser,i)=>(
                            <div className="col-lg-3 col-md-4 col-sm-6" key={hairdresser._id} onClick={this.changeHairdresser.bind(this,hairdresser._id,hairdresser)}>
                                <div className={this.state.selectedHairdresserId===hairdresser._id||(this.state.selectedHairdresserId===''&&i===0)?"cm_staff_catd active":"cm_staff_catd"}>
                                    <img src={hairdresser.userImage.circleImage ? hairdresser.userImage.circleImage : require("../../assets/images/Pixie.png")} width="50px" />
                                    <h3>{hairdresser.name}</h3>
                                </div>
                            </div>
                        )):""}

                    </div>

                    <h3 className="cm_fw_b">{this.state.selectedHairdresser.name}’s Services</h3>
                    <div className='service_topbar'>
                            <div className="cm_search_wrapper client_search srv_search mb-0 mt-0">
                                {/*<input type="text" className="form-control" placeholder="Search for service…"/>*/}
                                {/*<i className="fa fa-search"/>*/}
                           
                            </div>
                        <div className='booking__interval'>
                            <div className=" mb-0">
                                <label>Booking Interval <small>(minutes)</small></label>
                                <p className="form-control mb-0"  onClick={this.openEditInterval}>{this.state.hairdresserBookingInterval}</p>
                            </div>
                        </div>
                    </div>

                  

                    <div className="table-responsive srvc_table">
                    {hairdresserServices.hairdresserSelectedServices && hairdresserServices.hairdresserSelectedServices.length > 0 ?
                        <div className="table-responsive srvc_table">
                            <table className="table">
                                <tbody>
                                {hairdresserServices.hairdresserSelectedServices.map((category, index) => (
                                    <React.Fragment key={index}>
                                        {index===0?<tr>
                                                    <th><h3 className="cm_fw_l">{category.categoryName}</h3></th>
                                                    <th>Duration</th>
                                                    <th>Processing Time</th>
                                                    <th>Online Booking</th>
                                                    <th>Deposit</th>
                                                    <th>Cost</th>
                                                    <th></th>
                                                </tr>:
                                    <tr>
                                        <td colSpan="4"><h3 className="cm_fw_l">{category.categoryName}</h3></td>
                                    </tr>}
                                {category.hairdresserService.map((services, i) => (
                                    <tr className="left_bg" key={services._id} >
                                    <td className="pointer-event" onClick={this.selectService.bind(this,services)}>{services.serviceName}</td>
                                    <td className="pointer-event" onClick={this.selectService.bind(this,services)}>{services.serviceTime!==0?services.serviceTime+"m":"--"}</td>
                                    <td className="pointer-event" onClick={this.selectService.bind(this,services)}>{services.processingTime&&services.processingTime!==0?services.processingTime+"m":"--"}</td>
                                    <td className="pointer-event" onClick={this.selectService.bind(this,services)}>{services.serviceTime!==0?services.onlineBooking===true?'Yes':"No":"--"}</td>
                                    <td className="pointer-event" onClick={this.selectService.bind(this,services)}>{services.depositPrice&&services.depositPrice!==0?hairdresserServices.userInfo.hairdresser.currencySymbol&&hairdresserServices.userInfo.hairdresser.currencySymbol!==""&&hairdresserServices.userInfo.hairdresser.currencySymbol!=="undefined"?hairdresserServices.userInfo.hairdresser.currencySymbol:"£":""}{services.depositPrice&&services.depositPrice!==0?services.depositPrice:"--"}</td>
                                    <td onClick={this.selectService.bind(this,services)} className="position-relative cross_wrap pointer-event">
                                    {console.log('check the data ')}
                                    {(services.servicePrice!==0 || services?.serviceTime!=0) ?hairdresserServices.userInfo.hairdresser.currencySymbol&&hairdresserServices.userInfo.hairdresser.currencySymbol!==""&&hairdresserServices.userInfo.hairdresser.currencySymbol!=="undefined"?hairdresserServices.userInfo.hairdresser.currencySymbol+parseFloat(services.servicePrice).toFixed(2):"£"+parseFloat(services.servicePrice).toFixed(2):"--"}</td>
                                    {/*<td onClick={this.serviceSelect.bind(this, services, !this.checkSelectedService(services._id))}>*/}
                                    <td className="i_width pointer-event">
                                    {services.serviceTime!==0 ? (<i className="fa fa-times"  onClick={this.deleteService.bind(this,category._id,services)}/>):""}
                                    </td>
                                    </tr>
                                    ))}

                                    </React.Fragment>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                        :""
                    }

                    </div>
                    <div className="text-center mt-3">
                        <div className="mb-3">
                        Can't see your service? Request it here and we will add it.
                        </div>
                        <button onClick={()=>{window.location = "mailto:info@beuapp.com?subject=New Service Requested from "+this.props.user.userInfo.hairdresser.salon.salonName}} className="btn btn-dark">Request service</button>
                    </div>
                    </div>
                </div>
                :
                <div className="text-center mt-3">
                <Lottie options={defaultOptions}
                        height={50}
                        width={200}
                        />
                        <div className="mb-3">
                        <h4 className="">Loading Data...</h4>
                            </div>
                        </div>
                        }
            </div>

            <Modal show={this.state.isEditBookingInterval} onHide={this.handleClose} className="bg_white_modal text-center __service_edit_modal">
                        <i className="fa fa-times cm_hide_mod" onClick={this.handleClose} />
                        <Modal.Body>
                            <div className="p-1 pt-5 pl-3 pr-3 pb-5">
                            <img src={require('../../assets/images/service-edit.png')} className="img-fluid mb-3" />
                            <h3 style={{fontSize: '23px', fontWeight: '800'}} className="mb-4 mdl-hdkng">Edit booking interval for {this.state.selectedHairdresser.name}?</h3>
                            <p style={{fontSize: '15px', fontWeight: '300'}}>This will edit what slots are available to clients booking online. The first available slot will be based on the stylists start time for the day.</p>
                           
                                <div className="form-group _modal_booking">
                                    <label className="mb-1">Booking Interval <small>(minutes)</small></label>
                                    <input type="text" className="form-control mb-0" name="hairdresserBookingInterval" value={this.state.hairdresserBookingInterval} onChange={this.onChanged}/>
                                    {this.state.hairdresserBookingIntervalError ?<span className="cm_error_msg">{this.state.hairdresserBookingIntervalError}</span>:""}
                                </div>
                          
                            <div className="stap-action mt-5">
                                <button className="btn btn-outline-dark mr-2" onClick={this.handleClose}>Cancel</button>
                                <button className="btn btn-dark" onClick={this.updateInterval} >Update Interval</button>
                            </div>
                            </div>
                        </Modal.Body>
            </Modal>


        </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Services);
