import React, { Component } from "react";
import { Pending ,Bue} from '../SvgIcon'
export default class BusinessManagement extends Component {
    render() {
        return (
            <div className="container pad-0">
                        <div className=" happy_business feature pt-0 pb-0">
                            <div className="img">
                                <img src={require('../../assets/images/web-img/BusinessManagement.png')} className="img-fluid mb-3" />
                            </div>
                            <div className="text">
                                <h4>Business Management</h4>
                                <p>Beu manages your business, so you can focus on what matters - giving your clients the best experience.  </p>
                                <div className="feature-list">
                                    <p>Each stylist in the salon has their own rota, service duration and price list</p>
                                    <p>Set deposits for certain services, or request payment in advance</p>
                                    <p>Take online payments through your website and our apps</p>
                                    <p>Checkout your clients with our complete point of sale features</p>
                                </div>
                            </div>
                        </div>
                     </div>
        )
    }
}
