import { marketingConstants } from '../_constants';


const initialData = {};
export function updateMarketingData(state = initialData, action) {
    //console.log('action.type',action);
    switch (action.type) {
  
      case marketingConstants.UPDATE_MARKETING_DATA:
        let bookingInfo = action.data;
        return bookingInfo;
  
      default:
        return state
    }
  }


  const initialValue = [];
export function getSalonMarketingSetting(state = initialValue, action) {
    //console.log('action.type',action);
    switch (action.type) {
      case marketingConstants.SALON_MARKETING_SETTING:
        let bookingInfo = action.data;
        return bookingInfo;
  
      default:
        return state
    }
  }

  const initialConf= true;
  export function   marketingSettingNavigation(state = initialConf, action) {
      //console.log('action.type',action);
      switch (action.type) {
        case marketingConstants.NAVIGATION_MARKETING_SETTING:
          let bookingInfo = action.data;
          return bookingInfo;
    
        default:
          return state
      }
    }
  

