
import React, { Component } from "react";
import {Instagram} from '../SvgIcon';
export default class WebFooter extends Component {
    render() {
        return (
                  <div className="row">
                     <div className="col-sm-4 offset-sm-4 order-2 text-center copyright">
                         <p>Ⓒ Beutech Ltd, all rights reserved</p>
                         <a href={"https://www.instagram.com/beuapp/"} target="_blank"><p className="hide-mobile"><span><Instagram/></span></p></a>
                     </div>
                     <div className="col-sm-4 text-right order-1 menu">
                            <ul>
                                <li>FAQ</li>
                                <li><a href="/privacy-policy" style={{color:"#ffffff"}}>Privacy</a></li>
                                <li><a href={"mailto:info@beuapp.com"} style={{color:"#ffffff"}} target="_blank">info@beuapp.com</a></li>
                            </ul>
                            <a href={"https://www.instagram.com/beuapp/"} target="_blank"><p className="hide-desktop top-position"><span><Instagram/></span></p></a>
                     </div>
                  </div>
        )
    }
}
