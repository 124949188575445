import React, {Component} from 'react';
import {Tab, Tabs, Accordion, Card, Button} from 'react-bootstrap';
import {connect} from "react-redux";
import {userActions} from "../../_actions";
import moment from "moment";

class ClientCheckout  extends Component {

    constructor(props) {
        super(props);

        this.state = {
            bookingType:'past', //upcoming
            showMore:false,
            rescheduleRequestModal: false,
            cancelBookingModal: false,
            noShowModal: false,
            deleteSalonModal: false,
            removeStaffMemberMobcoder: false,
            editBookingModal:false
        }
        this.getServiceName=this.getServiceName.bind(this);
        this.getBookingTime=this.getBookingTime.bind(this);
        this.getTotalPrice=this.getTotalPrice.bind(this);
        this.showMore=this.showMore.bind(this);
    }


    handleClose = () => this.setState({
        rescheduleRequestModal: false,
        cancelBookingModal: false,
        noShowModal: false,
        deleteSalonModal: false,
        removeStaffMemberMobcoder: false,
        editBookingModal:false
    })

    componentDidMount() {
        var self = this;
        let params = {
            defaultRedirectTo:localStorage.getItem('defaultRedirectTo')
        };
        if (localStorage.getItem('accessToken')) {
            console.log("#####",self.props);
            self.props.dispatch(userActions.profile(params))
        }else{
            this.props.history.push('/')
        }
    }

    getServiceName(booking){
        let serviceName="";
        booking.servicesBooked.map((service,i) => {
            serviceName=serviceName+(serviceName==""?"":", ");
            serviceName=serviceName+service.service.serviceName;
        })
        return serviceName;
    }

    getslotTime(time) {

        let hr = parseInt(time / 60);
        let min = time - (hr * 60);
        //console.log("time",time,hr,min);
        return hr + ':' + (min === 0 ? '00' : min);
    }

    getBookingTime(booking){
        //console.log("booking",booking);
        let totalTime=booking.bookingSlot[1]-booking.bookingSlot[0];
         let hr = parseInt(totalTime / 60);
        let min = totalTime%60 ;
        let showTme="";
        if( totalTime >60){
            showTme=hr+' hr '+min+' min'
        }else{
            showTme=min+' min';
        }
        //console.log("showTme",showTme);
        return this.getslotTime(booking.bookingSlot[0])+" - "+this.getslotTime(booking.bookingSlot[1])+", "+showTme
    }

    getTotalPrice(booking){
        let serviceTotalPrice=0;
        booking.servicesBooked.map((service,i) => {
            serviceTotalPrice=serviceTotalPrice+service.servicePrice;
        })
        return serviceTotalPrice;
    }

    showMore(){
        this.setState({showMore:!this.state.showMore})
    }

    handleReschedule=()=>{
        this.setState({rescheduleRequestModal:true})
    }

    handleCancel=()=>{
        this.setState({cancelBookingModal:true})
    }

    handleNoShow=()=>{
        this.setState({noShowModal:true})
    }

    handleEditBooking=()=>{
        this.setState({
            rescheduleRequestModal:false,
            editBookingModal:true
        })
    }

    checkEmail=(email)=>{
        if(email) {
            let emailArr = email.split('@');
            if (emailArr[1] === 'beuapp.com') {
                return false;
            } else {
                return true;
            }
        }else{
            return false;
        }
    }

    render() {
        let propes=this.props;
        let {
            rescheduleRequestModal,
            cancelBookingModal,
            noShowModal,
            editBookingModal
        } = this.state;
        let { borderr } =this.props;
        return (
            propes.selectedClient ?
                (

                <div
                className={`cm_right_panal client_checkout ${borderr ? 'remove-brder' : ''}`}
                >
                    <div className="cm_bao">

                        <img
                            src={propes.selectedClient.localAvatar != propes.selectedClient.localAvatar ? "" : require('../../assets/images/client-plachoder.jpg')}/>
                        <h3>{propes.selectedClient.name}</h3>
                        {this.checkEmail(propes.selectedClient.email)?<p>{propes.selectedClient.email}</p>:""}
                        <p>{propes.selectedClient.phoneNumber}</p>
                    </div>


                    {(propes?.selectedClient?._id==='64f5c7789a069cda0188fea3')?null:
                    <Tabs defaultActiveKey="Booking" className="new-booking-tab ss">
                        <Tab eventKey="Booking" title="Booking">

                            <Accordion defaultActiveKey="0">
                                <Card>

                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="0">Upcoming
                                            ({this.props.clientDetail.upcomingBookings?this.props.clientDetail.upcomingBookings.length:0})</Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <ul>
                                                {this.props.clientDetail.upcomingBookings&&this.props.clientDetail.upcomingBookings.length>0?
                                                    this.props.clientDetail.upcomingBookings.map((booking,i) => (
                                                        <li className="d-flex justify-content-between" key={booking._id}>
                                                            <div className="cm_bg_uniq">
                                                                <h4>{moment(booking.bookingDate).format('DD')}</h4>
                                                                <p className="cm_fw_l">{moment(booking.bookingDate).format('MMM')}</p>
                                                            </div>
                                                            <div>
                                                                <p>{this.getServiceName(booking)}</p>
                                                                <p className="cm_fw_l">{this.getBookingTime(booking)}</p>
                                                            </div>
                                                            <div className="cm_fw_l">
                                                                <p>with {booking.hairdresser.name}</p>
                                                                <p className="text-right">{propes.currencySymbol}{this.getTotalPrice(booking)}</p>
                                                            </div>
                                                        </li>)):""}
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>

                                </Card>
                                <Card>

                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="1">Past
                                            ({this.props.clientDetail.pastBooking?this.props.clientDetail.pastBooking.length:0})</Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                            <ul>
                                                {this.props.clientDetail.pastBooking&&this.props.clientDetail.pastBooking.length>0?
                                                    this.props.clientDetail.pastBooking.map((booking,i) => (
                                                        <li className="d-flex justify-content-between" key={booking._id}>
                                                            <div className="cm_bg_uniq">
                                                                <h4>{moment(booking.bookingDate).format('DD')}</h4>
                                                                <p className="cm_fw_l">{moment(booking.bookingDate).format('MMM')}</p>
                                                            </div>
                                                            <div>
                                                                <p>{this.getServiceName(booking)}</p>
                                                                <p>{this.getBookingTime(booking)}</p>
                                                            </div>
                                                            <div className="cm_fw_l">
                                                                <p>with {booking.hairdresser.name}</p>
                                                                <p>{propes.currencySymbol}{this.getTotalPrice(booking)}</p>
                                                            </div>
                                                        </li>)):""}
                                            </ul>

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>

                        </Tab>
                        <Tab eventKey="notes" title="Notes">
                            <p>{propes.selectedClient.note?propes.selectedClient.note:"No notes"}</p>
                        </Tab>

                    </Tabs>}

                    <div className={`cm_btns m_revers`}>

                        {parseFloat(propes.pendingAmount).toFixed(2)>0?
                        <button className="btn btn-dark btn-block" onClick={() => propes.checkoutBooking()}>Checkout
                        </button>:""}
                        {/*<button className="btn btn-primary btn-block mb-3" onClick={() => propes.editBooking()}>Save Booking</button>*/}
                    </div>

                </div>) : ""
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(ClientCheckout);
