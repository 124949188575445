import React, { Component } from "react";
import TopHeader from "../template/TopHeader";
import SideNav from "../template/SideNav";
import toast from 'react-toast-notification';
import { productAction } from "../../_actions/product.action";
import { connect } from "react-redux";
class AddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
        productName: "",
        retailPrice: "",
        stockPrice: "",
        commissionEnable: "no",
        currencySymbol:'£'
    };
  }

  addProduct = () => {
    const { productName, retailPrice, stockPrice, commissionEnable} = this.state;
    const { history } = this.props; 
    
    if (!productName || !retailPrice || !stockPrice || !commissionEnable) {
        toast.error("Fill all fields")
        return;
    }

    if(parseFloat(stockPrice)>parseFloat(retailPrice)){
      console.log('price error',stockPrice>retailPrice);
      return;
    }
  
    const productDetails = {
      productName: productName,
      retailPrice: parseFloat(retailPrice),
      stockPrice: parseFloat(stockPrice),
      commissionEnable: commissionEnable === "no" ? 0 : 1,
    };
    console.log('productDetails', productDetails);
    this.props.dispatch(productAction.addProduct(productDetails))
      .then((response) => {
        console.log('Add response', response);
        this.setState({
          productName: "",
          retailPrice: "", 
          stockPrice: "", 
          commissionEnable: "no",
        })
        toast.success("Product Added Successfully")
        history.push("/product");
      })
      .catch((err)=>{
        console.log(err);
      })
  }

  handleCancelClick = () => {
    const { history } = this.props; 
    history.push("/product");
  };

  render() {
    const {stockPrice,retailPrice} = this?.state;
    console.log(this.state);
    return (
      <div>
        <div className="h_n">
          {/* <div className="body_container cm_bg_white"> */}
            <TopHeader title="Add New Products" {...this.props} />
            <SideNav {...this.props} />
            <div className="product_div">
              <div className="product_main_div ">
              <h3 className="py-4 pl-2">Product Info</h3>
                <h6 className="fw-bold ml-2">Product Name</h6>
                <input
                  type="text"
                  name="productName"
                  className="ml-2 mb-4 product_input"
                  value={this.state.productName}
                  onChange={(e) =>
                     this.setState({ productName: e.target.value })
                  }
                />
                <h6 className="fw-bold ml-2">Retail Price</h6>
                <input
                  type="text"
                  name="retailPrice"
                  className="ml-2 mb-2 product_input"
                  value={this.state.retailPrice}
                  onChange={(e) =>
                    !isNaN(e.target.value)?this.setState({ retailPrice: e.target.value }):null
                  }
                />
                {(+stockPrice> +retailPrice)?<p className="text-danger">{'Retail price should be grater then stock price.'}</p>:null}
                <h6 className="fw-bold mt-2 ml-2">Stock Price</h6>
                <input
                  type="text"
                  name="stockPrice"
                  className="ml-2 mb-4 product_input"
                  value={this.state.stockPrice}
                  onChange={(e) =>
                    !isNaN(e.target.value)?this.setState({ stockPrice: e.target.value }):null
                  }
                />
                <h6 className="fw-bold ml-2">Commission Enabled</h6>
                <select
                  className="ml-2 mb-4 bg-white product_select"
                  value={this.state.commissionEnable}
                  name="commissionEnable"
                  onChange={(e) =>
                    this.setState({ commissionEnable: e.target.value })
                  }
                >
    
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </select>

                <div className="text-center">
                  <button
                    onClick={this.handleCancelClick}
                    className="product_cancel"
                  >
                    Cancel
                  </button>
                  <button className="product_save" onClick={this.addProduct}>
                    Save
                  </button>
                </div>
              </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(AddProduct);
