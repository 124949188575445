import { combineReducers } from 'redux';

import { alert } from './alert.reducer';
import { hairdresser } from './hairdresser.reducer';
import { salon,salonList } from './salon.reducer';
import { hairdresserReview } from './review.reducer';
import { user } from './user.reducer';
import { setting } from './setting.reducer';
import {booking, createBooking, hairdresserBooking, userBookingState} from './booking.reducer';
import {userReview} from "./userreview.reducer";
import {upcomingOrPastBooking} from "./upcoming&pastBooking.reducer";
import {services} from "./services.reducer";
import {termsAndConditions,privacyPolicy,gdpr,hairdresserTermsAndConditions} from "./page.reducer";
import { calender } from './calender.reducer';
import { hairdresserClient,hairdresserClientCsv,selectedClient } from './client.reducer';
import { clientDetail } from './clientDetail.reducer';
import { staff,staffService,staffServiceDetail,staffRota,dairyFilterData,dairyStaffOrderList,activeFilterStatus } from './staff.reducer';
import { categories } from './category.reducer';
import { hairdresserServices,selectedService,salonOwnerDataInfo } from './hairdresserServices.reducer';
import { event } from './event.reducer';
import { bankDetails } from './bank.reducer';
import { selectCheckoutItem,selectedCheckoutList } from './product.reducer';
import { selectService,selectedServiceList } from './service.reducer';
import { updateMarketingData,getSalonMarketingSetting, marketingSettingNavigation } from './marketing.reducer';

import {
  hairdresserNewBookingStaff,
  hairdresserNewBookingClient,
  hairdresserNewBookingService,
  hairdresserNewBookingData,
  selectHairdresserBookingDate,
  selectHairdresserBookingTime,
  clientBookingSlots,
  updateBookingInfo
} from './createbooking.reducer';

import {payoutList,cardReaderList} from "./paymentList.reducer";

const rootReducer = combineReducers({
  user,
  alert,
  hairdresser,
  salon,
  hairdresserReview,
  setting,
  booking,createBooking,
  userReview,
  salonList,
  upcomingOrPastBooking,
  services,
  termsAndConditions,privacyPolicy,gdpr,
  calender,
  hairdresserClient,
  hairdresserClientCsv,
  clientDetail,
  staff,
  hairdresserTermsAndConditions,
  categories,
  hairdresserServices,
  staffService,
  staffServiceDetail,
  staffRota,
  dairyFilterData,
  activeFilterStatus,
  hairdresserBooking,
  event,
  bankDetails,
  payoutList,
  cardReaderList,
  dairyStaffOrderList,
  selectCheckoutItem,
  selectService,
  selectedServiceList,
  selectedCheckoutList,
  selectedClient,
  selectedService,
  hairdresserNewBookingStaff,
  hairdresserNewBookingService,
  hairdresserNewBookingClient,
  hairdresserNewBookingData,
  selectHairdresserBookingDate,
  selectHairdresserBookingTime,
  clientBookingSlots,
  userBookingState,
  updateBookingInfo,
  updateMarketingData,
  getSalonMarketingSetting,
  marketingSettingNavigation,
  salonOwnerDataInfo
});

export default rootReducer;
