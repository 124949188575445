import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'; // Import withRouter
import { connect } from "react-redux";
import { hairdresserActions,bookingActions } from '../../../_actions';
import Staff from './Staff';
import dateFormat from "dateformat";
import siteSetting from '../../../config/env';
import { loadStripe } from '@stripe/stripe-js';
import config from "../../../config/env";
import { StaffLoader } from '../SkletonLoader';
import { BackArrow } from '../../SvgIcon';
var _ = require('lodash');
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];


class StaffWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStaff: false,
            loading: false,
            prevUserId:sessionStorage.getItem('prevUserId')
        }

        this.changeHairdresser = this.changeHairdresser.bind(this);
        this.activeAnimation = this.activeAnimation.bind(this);
    }
    componentDidMount() {
        let self = this;
        console.log('checkDataUrl',self?.props?.location?.state);
        document.body.classList.add('no-topbar');
        self.props.dispatch(bookingActions.userBookingState({}));
        this.getHairdressers();
    }


    changeHairdresser(salonId, hairdresserId, hairdresser) {
        let self = this;
        const stateObj = {
            salonId: salonId,
            hairdresserId: hairdresserId,
            selectedHairderesser:hairdresser,
            isBackArrow:this.props.history.location?.state?.isBackArrow?this.props.history.location?.state?.isBackArrow:false,
            ...this.props.history.location?.state
        };

        // Push the state to the router
        this.props.history.push({
            pathname: "/salon/" + salonId + "/" + hairdresserId + "/service",
            state: stateObj,
        });
    }

    getHairdressers() {
        let params = {};
        let self = this;
        params.salonId = this.props.match.params.salonId;
        params.latitude = siteSetting.default_latitude;
        params.longitude = siteSetting.default_longitude;
        self.setState({ loading: true });
        self.props.dispatch(hairdresserActions.getSalonProfile(params))
            .then(function (response) {
                // salonOwnerInfo
                self.props.dispatch(hairdresserActions.selectedService({a:3}));
                self.props.dispatch(hairdresserActions.salonOwnerInfo({data:response.data.data.salons?.hairdresser[0]}));
                
                self.setState({ loading: false });
            });
    }

    activeAnimation(callback) {
        let timeProcess = setTimeout(() => {
            callback();
        }, 500)

        return function () {
            clearTimeout(timeProcess);
        }
    }

    render() {
        let { salon } = this.props;
        let { loading } = this.state;
   

        return (
            <div className='home_main'>
            {     console.log('check data',this.props,this.state)}
                <div className='__left p-4'>
                    <ul className="__left_breadcrumb mb-5">
                    <li className="arrow_li" onClick={()=>{
                        this.props.history.push({
                                pathname:`/salon/${this.props.match.params.salonId}/professional/${this.state.prevUserId}`,
                                state:{...this.props.history.location?.state}
                      });
                      }}>
                      <BackArrow/>
                       {/* <img alt="img" src={require('../../../assets/images/down-arrow-icon1.png')} className="img-fluid mb-3 client-booking-back-arrow" /> */}
                       </li>
                        <li>Staff</li>
                    </ul>
                    <div className='_staff_parent '>
                        <div className='_staff_parent_display '>
                            {
                                loading ? <StaffLoader />
                                    : <>
                                        {salon?.salonInfo?.salons?.hairdresser.map((hairdresser, i) =>
                                            <Staff
                                                key={hairdresser._id}
                                                salonId={salon?.salonInfo?.salons?._id}
                                                changeHairdresser={this.changeHairdresser}
                                                activeAnimation={this.activeAnimation}
                                                hairdresserData={hairdresser}
                                            />
                                        )}
                                    </>
                            }

                        </div>
                    </div>

                </div>
                <div className='__right '>
                    <div className='beu_logo hide-mobile text-center w-100'>
                        <img src={require('../../../assets/images/beu-logo-rectangle.png')} className="img-fluid mb-3" />
                    </div>

                </div>
            </div>
        );
    };
}

function mapStateToProps(state) {
    const {
        hairdresser, salon, hairdresserReview, user,
        setting,
        booking,
        createBooking,
        salonList
    } = state;

    console.log('check data 66',state);
    return {
        hairdresser,
        salon,
        hairdresserReview,
        user,
        setting,
        booking,
        salonList,
        createBooking

    };
}


export default withRouter(connect(mapStateToProps)(StaffWrapper));
