import React, { useState } from 'react';
import {Tab, Tabs, Modal, Dropdown, Accordion, Card, Button} from 'react-bootstrap';
import {Close} from '../SvgIcon';
import moment from "moment";
import LoaderSetting from '../settings/Loader';
import siteSetting from "../../config/env";
import {paymentActions} from "../../_actions";
const isMobile = (window.innerWidth <= 768);
const Checkout = (props) => {

    const getslotTime=(time)=> {
        let hr = parseInt(time / 60);
        let min = time - (hr * 60)
        return hr + ':' + (min === 0 ? '00' : min);
    }
    const checkEmail=(email)=>{
        if(email) {
            let emailArr = email.split('@');
            if (emailArr[1] === 'beuapp.com') {
                return false;
            } else {
                return true;
            }
        }else{
            return false;
        }
    }

    const [showDiscoversModal, setShowDiscoversModal] = useState(false)

    const handleDiscoverModal  = ()=>{
        setShowDiscoversModal(true)
    }
    const handleClose = ()=>{
        setShowDiscoversModal(false)
    }
    const [isCardReaderRequested, setIsCardReaderRequested] = useState(false)
    const [cardReaderRequestError, setCardReaderRequestError] = useState('')
    const [registrationCode, setRegistrationCode] = useState('')
    const [cardReaderConnectError, setCardReaderConnectError] = useState('')
    const [locationId, setLocationId] = useState('')
    const [isCardConnected, setIsCardConnected] = useState(true)
    const [connectDevice, setConnectDevice] = useState(false)
    const [cardReaderFound, setCardReaderFound] = useState(false)
    const [discoverResult, setDiscoverResult] = useState([])
    const [noDevice, setNoDevice] = useState(false)
    const [selectedDevice, setSelectedDevice] = useState({})
    const [isSearchReader, setIsSearchReader] = useState(false)

    const handleCancel = ()=>{
        setRegistrationCode('');
        setCardReaderConnectError('');
        setIsSearchReader(false);
    }

    const discoverReaders=()=>{
        let self=this;
        setIsSearchReader(true);
    }

    const addStripeTerminal=()=>{
        let self=this;
        self.props.dispatch(paymentActions.addTerminal({registrationCode:registrationCode}))
            .then(function (response) {
                console.log("response", response.data);
                if (response.data.statusCode===1) {
                    //cardReaderList();
                    setIsSearchReader(false);
                    setIsCardReaderRequested(true);
                }else{
                    setCardReaderConnectError(response.data.error.responseMessage)
                }
            });
    }
    
    const disconnectCardReader=async (deviceId)=>{
        let self=this;
        // await terminal.disconnectReader();
        // this.setState({
        //     isCardConnected: false
        // })
        self.props.dispatch(paymentActions.cardReaderDelete({deviceId:deviceId}))
            .then(function (response) {
                console.log("response", response.data);
                if (response.data.statusCode===1) {
                    this.setState({
                        isCardConnected: false
                    })
                }else{

                }
            })
    }

    return(
        (props.selectedClient?<div className="cm_right_panal client_checkout newclient-checkout ">
                {/* with selected client */}
               {props.selectedClient?<div className="backgroun-shadow">
                    <div className="cm_bao">
                        <img src={props.selectedClient.localAvatar!=props.selectedClient.localAvatar?"": require('../../assets/images/client-plachoder.jpg')} />
                        <h3>{props.selectedClient.name}</h3>
                        {checkEmail(props.selectedClient.email)?<p>{props.selectedClient.email}</p>:""}
                        <p>{(props.selectedClient._id==='64f5c7789a069cda0188fea3')?null:props.selectedClient.phoneNumber}</p>
                   
                    </div>
                    <div className="cm_body checkout-parent">
                            <div className="bobking_detail ">
                                {props.bookingState.isPaymentComplete==1?
                                <div className="text-center">
                                    <img src={require('../../assets/images/payment-taken.png')} className="img-fluid"/>

                                    <h4 className="mt-4">Payment Taken</h4>
                                    <p>Complete checkout to generate an invoice and finish this booking.</p>
                                </div>
                                    :
                                    <>
                                    {isMobile?
                                <div className="three_box three_boxthree">
                                    <div className="cm_b_details">
                                        <p>Start Time</p>
                                        <h5>{getslotTime(props.bookingState.slotInfo.startTime)}</h5>
                                    </div>
                                    <div className="cm_b_details">
                                        <p>Duration</p>
                                        <h5>{props.bookingState.totalTime}</h5>
                                    </div>
                                    <div className="cm_b_details">
                                        <p>Cost</p><h5>{props.currencySymbol}{props.totalAmount.toFixed(2)}</h5>
                                    </div>
                                </div>:""}
                                <div className="payment-bill m-0 p-0">
                                    <div className="tble">
                                        <p>Total</p>
                                        <p>{props.currencySymbol}{props.totalAmount.toFixed(2)}</p>
                                    </div>
                                    <div className="tble green disabled">
                                    {/* <div className="tble green disabled"> */}
                                        <p>Deposit Paid</p>
                                        <p>£0.00</p>
                                    </div>
                                    {props.bookingState.transaction.map((transaction, index) => (
                                        <div className="tble" key={"t_"+index}>
                                            <p><span className="mr-3" onClick={()=>props.removeTransaction(transaction)}><Close/></span>{transaction.paymentType === 1 ? 'Pay by App' : ''}{transaction.paymentType === 2 ? 'Card' : ''}{transaction.paymentType === 3 ? 'Cash' : ''}{transaction.paymentType === 4 ? 'Other' : ''}</p>
                                            <p>{props.currencySymbol}{transaction.amount.toFixed(2)}</p>
                                        </div>
                                    ))}
                                </div>

                                <div className="border-div detl flex-just-align-center pointer-event" onClick={props.selectBookingAmount.bind(this,1)}>
                                    <div >
                                        <label className="radio-container">Balance Remaining
                                            <input type="radio" onChange={() => {}} checked={props.bookingState.checkOutAmountType} name="radi1" />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <h5>{props.currencySymbol}{props.bookingState.pendingAmount.toFixed(2)}</h5>
                                </div>
                                <div className="border-div detl flex-just-align-center pointer-event" onClick={props.selectBookingAmount.bind(this,2)}>
                                    <div >
                                        <label className="radio-container">Custom Amount
                                            <input type="radio" name="radi" onChange={() => {}} checked={!props.bookingState.checkOutAmountType} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>

                                    <div>
                                        <span className="currency-input">{props.currencySymbol}</span>
                                        <input type="number" name="selectedAmount" value={props.bookingState.selectedAmount} disabled={props.bookingState.checkOutAmountType} placeholder="0.00" className="" onChange={props.onChangedValue}/>

                                    </div>

                                </div>
                                    </>}
                                {props.paymentAmountError ?
                                    <span className="cm_error_msg">{props.paymentAmountError}</span> : ''}

                            </div>

                        </div>
                </div>:<div className="backgroun-shadow">
                    <div className="cm_bao">
                      <p>Walk In</p>
                    </div>
                    <div className="cm_body checkout-parent">
                            <div className="bobking_detail ">
                                {props?.bookingState?.isPaymentComplete==1?
                                <div className="text-center">
                                    <img src={require('../../assets/images/payment-taken.png')} className="img-fluid"/>

                                    <h4 className="mt-4">Payment Taken</h4>
                                    <p>Complete checkout to generate an invoice and finish this booking.</p>
                                </div>
                                    :
                                    <>
                                    {isMobile?
                                <div className="three_box three_boxthree">
                                    <div className="cm_b_details">
                                        <p>Start Time </p>
                                        <h5>{getslotTime(props.bookingState.slotInfo.startTime)}</h5>
                                    </div>
                                    <div className="cm_b_details">
                                        <p>Duration</p>
                                        <h5>{props.bookingState.totalTime}</h5>
                                    </div>
                                    <div className="cm_b_details">
                                        <p>Cost</p><h5>{props?.currencySymbol}{props?.totalAmount.toFixed(2)}</h5>
                                    </div>
                                </div>:""}
                                <div className="payment-bill m-0 p-0">
                                    <div className="tble">
                                        <p>Total</p>
                                        <p>{props?.currencySymbol}{props?.totalAmount.toFixed(2)}</p>
                                    </div>
                                    <div className="tble green disabled">
                                    {/* <div className="tble green disabled"> */}
                                        <p>Deposit Paid</p>
                                        <p>£0.00</p>
                                    </div>
                                    {props.bookingState.transaction.map((transaction, index) => (
                                        <div className="tble" key={"t_"+index}>
                                            <p><span className="mr-3" onClick={()=>props.removeTransaction(transaction)}><Close/></span>{transaction.paymentType === 1 ? 'Pay by App' : ''}{transaction.paymentType === 2 ? 'Card' : ''}{transaction.paymentType === 3 ? 'Cash' : ''}{transaction.paymentType === 4 ? 'Other' : ''}</p>
                                            <p>{props.currencySymbol}{transaction.amount.toFixed(2)}</p>
                                        </div>
                                    ))}
                                </div>

                                <div className="border-div detl flex-just-align-center pointer-event" onClick={props.selectBookingAmount.bind(this,1)}>
                                    <div >
                                        <label className="radio-container">Balance Remaining
                                            <input type="radio" onChange={() => {}} checked={props.bookingState.checkOutAmountType} name="radi1" />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <h5>{props.currencySymbol}{props.bookingState.pendingAmount.toFixed(2)}</h5>
                                </div>
                                <div className="border-div detl flex-just-align-center pointer-event" onClick={props.selectBookingAmount.bind(this,2)}>
                                    <div >
                                        <label className="radio-container">Custom Amount
                                            <input type="radio" onChange={() => {}} name="radi" checked={!props.bookingState.checkOutAmountType} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>

                                    <div>
                                        <span className="currency-input">{props.currencySymbol}</span>
                                        <input type="number" name="selectedAmount" value={props.bookingState.selectedAmount} disabled={props.bookingState.checkOutAmountType} placeholder="0.00" className="" onChange={props.onChangedValue}/>

                                    </div>

                                </div>
                                    </>}
                                {props.paymentAmountError ?
                                    <span className="cm_error_msg">{props.paymentAmountError}</span> : ''}

                            </div>
                        </div>
                </div>}
         
                {props.bookingState.isPaymentComplete==0? <>
                <div className="backgroun-shadow checkout-parent">
                    <div className='payMnt-methdo d-flex align-items-center'>
                        <h5 className='mb-0'>Payment Method </h5>
                        <button className='btn ml-auto' onClick={()=>handleDiscoverModal()}><i className='fa fa-plus'></i></button>
                    </div>
                    {props.cardReaderList?.length>0?props.cardReaderList.map((data)=>(
                        <div key={'cr-'+data.id} className="border-div detl pointer-event" onClick={() => props.setPaymentCardReader(data)}>
                            <div>
                                <label className="radio-container">{data.label}
                                    <input type="radio" onChange={() => {}}  name={"radio"+data.id} checked={props?.bookingState?.selectedCardReader?.id==data.id?true:false} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    )):null}
                    <div className="border-div detl pointer-event" onClick={() => props.setPaymentType(2)}>
                       <div>
                            <label className="radio-container">Card
                                <input type="radio" onChange={() => {}} name="radio3" checked={props.bookingState.selectedPaymentType==2?true:false} />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div className="border-div detl pointer-event" onClick={() => props.setPaymentType(3)}>
                       <div>
                            <label className="radio-container">Cash
                                <input type="radio" onChange={() => {}} name="radio2" checked={props.bookingState.selectedPaymentType==3?true:false} />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div className="border-div detl pointer-event" onClick={() => props.setPaymentType(4)}>
                       <div>
                            <label className="radio-container">Other
                                <input type="radio" onChange={() => {}} name="radio1" checked={props.bookingState.selectedPaymentType==4?true:false} />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <Modal
                    size="lg"
                    show={showDiscoversModal}
                    onHide={handleClose}
                    className="bg_white_modal discoverd-modalWrp"
                >
                    <i className="fa fa-times cm_hide_mod" onClick={handleClose} />
                    <Modal.Body>
                       <div className='card-reader-modal-view-wrpp'>
                            <div className='card-reader-list'>
                                <div className='card-discover-top card-discoversDes'>
                                    <h4>Discovered Card Readers</h4>
                                    {isSearchReader===true?<p>Searching for card readers… <LoaderSetting  /></p>:""}
                                    <div className='search-discoveed-reader d-flex flex-wrap align-items-center'>
                                        {isSearchReader===false&&noDevice===true?<p className='mb-0'>No card readers found</p>:""}
                                        {isSearchReader===false?<button className='btn btn-inf ml-auto' onClick={()=>discoverReaders()}>Search for Card Reader</button>:""}
                                    </div>
                                </div>
                                {isSearchReader===true?
                                    <div className={`discover-card-reader-lst active-card`}>
                                        <div className='row align-items-center'>

                                            <div className='col-md-3 col-5'>
                                                <div className='disccover-crd-redr-img'>
                                                    <img className='img-responsive' src={require('../../assets/images/card-readerImg.png')}  />
                                                </div>
                                            </div>
                                            <div className='col-md-9 col-7'>
                                                <div className='disccover-crd-redr-descr'>
                                                    <h4>BBPOS WisePOS E</h4>
                                                    <p>Device 2316</p>
                                                </div>
                                            </div>

                                            <div className='col-md-12 col-12'>
                                                <div className='card-discover-access-code pt-3 pt-md-4'>
                                                    <p>Turn on your BBPOS WisePOS E and connect it to the same WIFI network as the device running Beu. Once connected, please enter the access code displayed on BBPOS WisePOS E.</p>
                                                    <div className='form-group'>
                                                        <label>Access Code</label>
                                                        <input className='form-control' type="text" value={registrationCode} name="registrationCode" onChange={(e)=>setRegistrationCode(e.target.value)}/>
                                                    </div>
                                                    <div className='form-group mb-0 text-center text-lg-right btn-Readerss pt-md-3'>
                                                        <button className='btn mr-md-3 btn-line-blck' onClick={()=>handleCancel()}>Cancel </button>
                                                        <button className='btn btn-inf px-md-5' onClick={()=>addStripeTerminal()}>Confirm</button>
                                                    </div>
                                                </div>
                                                {cardReaderConnectError ?
                                                    <span className="cm_error_msg">{cardReaderConnectError}</span> : ''}
                                            </div>
                                        </div>
                                    </div>:""}
                            </div>
                       </div>
                    </Modal.Body>
                </Modal>
                 </>
                :""}
                <div className="backgroun-shadow checkout-parent noshadow">
                    <div className="d-flex footer-btn">
                        {/*<button className="btn btn-bhite btn-block" onClick={() =>props.resetBooking()} >Cancel</button>*/}
                        <button className="btn btn-blue btn-block" onClick={() =>props.completeSale()} disabled={props.checkCompleteSale()}>
                            {/*Continue*/}
                            Confirm 
                        </button>
                    </div>
            </div>







           {/* ------ new design end ---- */}



            {/* <div className="payment_detail">
                {props.selectedService.map((service, index) => (
                    <p key={service._id}>{service.serviceName} - {props.currencySymbol}{service.servicePrice.toFixed(2)}</p>
                ))}

                {/*<p>Total - £42</p>*/}
                {/* <h3>Total - {props.currencySymbol}{props.totalAmount.toFixed(2)}</h3>
            </div> */}

                {/* {props.selectedService.length>0?
                    props.pendingAmount!==0?
                    (<><div className="cm_ammount">
                <p>Pay ({props.currencySymbol})</p>
                <input type="number" name="pendingAmount" className="form-control" value={props.pendingAmount.toFixed(2)} onChange={props.onChangedValue}/>
                {props.paymentAmountError ?
                    <span className="cm_error_msg">{props.paymentAmountError}</span> : ''}
            </div>

               <div className="cm_btns ">

                <button className="btn btn-dark btn-block mb-3" onClick={() => props.serPaymentType(1)}>Pay by App</button>
                <button className="btn btn-dark btn-block mb-3" onClick={() => props.serPaymentType(2)}>Card</button>
                <button className="btn btn-dark btn-block mb-3" onClick={() => props.serPaymentType(3)}>Cash</button>
                <button className="btn btn-dark btn-block" onClick={() => props.serPaymentType(4)}>Other</button>

               </div></>)
                    :<div className="cm_btns">

                        <button className="btn btn-dark btn-block mb-3" onClick={() =>props.completeSale()}>Complete Sale</button>
                        <button className="btn btn-dark btn-block mb-3" onClick={() =>props.resetBooking()}>Cancel</button>
                    </div>
               :"" } */}

            </div>
    :null))
}

export default Checkout;
