import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';

const loggerMiddleware = createLogger();

const persistConfig = {
    key: 'root',
    storage,
  }
   
  const persistedReducer = persistReducer(persistConfig, rootReducer)

    let store = createStore(
        persistedReducer,
        applyMiddleware(
            thunkMiddleware,
            // loggerMiddleware
        )
    )
    let persistor = persistStore(store)
    export { store, persistor };


// export const store = createStore(
//     rootReducer,
//     applyMiddleware(
//         thunkMiddleware,
//         //loggerMiddleware
//     )
// );
