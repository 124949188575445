import axios from 'axios';
import API from "../config/API";
import siteSetting from "../config/env";

export const eventService = {
    getEvent,
    editEvent,
    deleteEvent
};

var instance = axios.create({
    baseURL: siteSetting.api.url,
    headers: {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    }
});

function getEvent(params) {
    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    }
    return axios.get(API.EVENT+params.hairdresserId+'/events/'+params.eventId, {headers:headers})
}

function editEvent(params) {
    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    }
    return axios.post(API.EVENT+params.hairdresserId+'/events/'+params.eventId,params,{headers:headers})
}

function deleteEvent(params) {
   let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    }
    return axios.post(API.EVENT+params.hairdresserId+'/events/delete/'+params.eventId,params, {headers:headers,data:params})
}

