import React, { useState, useEffect } from "react";
import moment from "moment";
import SettingSidebar from "./SettingSidebar";
import SideNav from "../template/SideNav";
import TopHeader from "../template/TopHeader";
import animationData from "../../assets/animation-data/loader.json";
import Lottie from "react-lottie";
import { connect, useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { marketingInvoiceAction } from "../../_actions/marketingInvoice.actions";
import { DownloadtableIcon } from "../SvgIcon";


const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function Invoices(props) {
  const dispatch = useDispatch();
  const [invoiceList, setInvoiceList] = useState([]);
  const userInfo = useSelector((state) => state?.user?.userInfo);
  const getSalonMarketingSetting = useSelector(
    (state) => state?.getSalonMarketingSetting
  );
  const [isLoader, setIsLoader] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [itemPerPageLimit, setItemPerPageLimit] = useState(10);
  const [salonData, setSalonData] = useState([]);

  useEffect(() => {
    setSalonData(getSalonMarketingSetting);
    /* eslint-disable-next-line */
  }, [getSalonMarketingSetting]);

  useEffect(() => {
    const salonId = localStorage.getItem("salonId");
    getInvoiceList({
      salonId: salonId,
      currentPage: activePage,
      limit: itemPerPageLimit,
    });
    /* eslint-disable-next-line */
  }, [activePage]);

  const formateDate = (date, type) => {
    if (type === "billing") {
      let inputDate = moment(date, "YYYYMMDD");
      return inputDate.format("MMMM YYYY");
    }
    if (type === "expected") {
      //const newDate = inputDate.add(36, 'days');
      let newDate = moment(date);
      return newDate.format("D MMMM YYYY");
    }
  };

  const getInvoiceList = (params) => {
    dispatch(marketingInvoiceAction?.getInvoiceList(params)).then((res) => {
      if (res?.data?.statusCode === 1) {
        // res?.data?.responseData?.result?.result
        setInvoiceList(res?.data?.responseData?.result);
        setTotalCount(res?.data?.responseData?.totalCount);
        setIsLoader(false);
      } else {
        setIsLoader(false);
      }
    });
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  return (
             <>
              {isLoader ? (
               
                  <div className="salon-body-main-wrp booking-urlpage">
                    <div className="new-stng-cmn">    
                      <div className="text-center pr-md-5">
                        <Lottie
                          options={defaultOptions}
                          height={50}
                          width={200}
                        />
                        <h4 className="text-center">Loading Data...</h4>
                      </div>
                    </div>
                  </div>
                
              ) : (
             
                  <div className="salon-body-main-wrp account--password-temp new-stng-cmn __marketing_invoice">
                    <div className="widrawal-history marketing-widrawal-history pb-0 px-0">
                      <h3 className="heading-30 pl-3">Invoices</h3>
                      <div className="table-responsive payout_table">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              {/* <th>&nbsp;</th> */}
                              <th className="text-left W-date">
                                Billing Period
                              </th>
                              <th className="text-left W-refr">
                                Expected Billing Date
                              </th>
                              <th className="text-left W-refr status">
                                Automated SMS used
                              </th>
                              <th className="text-left W-refr status">
                                SMS Fees
                              </th>
                              <th className="text-left W-refr status">
                                Card Capture Fees
                              </th>
                              <th className="text-left W-refr status">
                                Total Amount
                              </th>
                              <th
                                className="text-left W-refr "
                                style={{ width: "60px" }}
                              ></th>
                            </tr>
                          </thead>
                          <tbody>
                            {invoiceList?.length > 0 ? (
                              invoiceList?.map((invoice, i) => (
                                <tr key={"invoice_" + invoice?._id}>
                                  <td className=" wd-1-td text-left">
                                    <span className="d-lg-block">
                                      {formateDate(
                                        invoice?.invoiceMonth,
                                        "billing"
                                      )}
                                    </span>
                                  </td>
                                  <td className="wd-2-td fw-m-300  text-left">
                                    {formateDate(
                                      invoice?.createDate,
                                      "expected"
                                    )}
                                  </td>
                                  <td className="wd-2-td fw-m-300  text-left cap-text">
                                    {invoice?.smsCount}
                                  </td>
                                  <td className="wd-2-td fw-m-300  text-left cap-text">
                                  {invoice?.currencySymbol}
                                  {invoice?.smsFees>0?invoice?.smsFees.toFixed(2):0}
                                  </td>
                                 
                                  <td className="wd-2-td fw-m-300  text-left cap-text">
                                    {invoice?.currencySymbol}
                                    {invoice?.cardCaptureFees>0?invoice?.cardCaptureFees.toFixed(2):0}
                                  </td>
                                  <td className="wd-2-td fw-m-300  text-left cap-text">
                                    {invoice?.currencySymbol}
                                    {invoice?.amount.toFixed(2)}
                                  </td>

                                  {/* <td className='wd--td text-center text-blue-light'>{this.state.currencySymbol}{(data.amount / 100).toFixed(2)}</td>
                                                        <td className='wd--td text-center text-blue-light' onClick={()=>this.feeModal(data)}>{this.state.currencySymbol}{(data.payoutFee / 100).toFixed(2)}</td> */}
                             
                                  <td className="wd-2-td fw-m-300  text-left cap-text">
                                    <span className="_download__icon" style={invoice?.stripeInvoicePdf?{'cursor':'pointer'}:{'cursor':'not-allowed','opacity':0.3}} onClick={()=>{
                                        if(invoice?.stripeInvoicePdf){
                                            window.open(invoice?.stripeInvoicePdf);
                                        }
                                    }}>
                                      <DownloadtableIcon />
                                    </span>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={7}>
                                  <p className="text-center">
                                    Record not found.
                                  </p>
                                </td>
                              </tr>
                            )}

                            {/* <tr>
                                                                <td colSpan={6} className="text-center">No data available
                                                                </td>
                                                            </tr> */}
                          </tbody>
                        </table>
                      </div>
                      {totalCount >= itemPerPageLimit ? (
                        <div className="text-right pagination-wrapper px-2">
                          <h6 className="d-none d-md-block">
                            Row per page :{itemPerPageLimit}
                          </h6>
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={itemPerPageLimit}
                            totalItemsCount={totalCount}
                            pageRangeDisplayed={3}
                            onChange={handlePageChange}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
              )}
              </>    
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(Invoices);
