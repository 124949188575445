import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";

 const BookingNavigation = (props) => {
    const [id,setId] = useState('');
    const [salonId,setSalonId] = useState('');

    useEffect(()=>{
        let _saloonId = localStorage.getItem('salonId');
        setSalonId(_saloonId);
        if(props.data.match.params.salonId!=undefined){
            setId(props.data.match.params.salonId);
        }else{
            setId(props.data.match.params.bookingId);
        }
    },[])

    const navigateTabs = (navName) =>{
      let currentPageUrl = props.data.history.location.pathname;
      console.log('check navigation tab 1',currentPageUrl,props.location.state);
      const stateObj = { ...props.location.state,pageLoadingStatus: 1, previousPageUrl:currentPageUrl};
      const url =  `/${navName}/` + props.data.match.params.salonId;
       props.data.history.push({
        pathname: url,
        state: stateObj,
      });
    }


    return(
        <nav className="nav nav-tabs _newCheckout_wrapper navbar__space">
        <span
          className="nav-item nav-link"
          onClick={()=>navigateTabs('newBooking')}
        >
          Booking
        </span>
        <span
          className="nav-item nav-link"
          onClick={()=>navigateTabs('event')}
        >
          Event
        </span>
        <span 
        className="nav-item nav-link active"
        onClick={()=>navigateTabs('new-checkout')}
        >
         Checkout
        </span>
      </nav>
    )
}

export default BookingNavigation