import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab, Modal } from 'react-bootstrap';

import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import { Link } from 'react-router-dom';
import siteSetting from "../../config/env";
import {userActions} from "../../_actions";
import {NotificationManager} from "react-notifications";
import SettingSidebar from './SettingSidebar';
import SettingHeadingTitle from './SettingHeadingTitle';


class EditPassword extends Component {

    constructor(props){
        super(props);
        this.state = {
            errors: {},
            newconfirmPassword: '',
            oldPassword: '',
            newPassword: '',
        }
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
    }

    componentDidMount() {
        let employmentType={};
        var self = this;
        if (localStorage.getItem('accessToken')) {
            let params={
                defaultRedirectTo:localStorage.getItem('defaultRedirectTo')
            }
            params.latitude = siteSetting.default_latitude;
            params.longitude = siteSetting.default_longitude;
            self.props.dispatch(userActions.profile(params))
                .then(function (response) {

                })

        }else{
            this.props.history.push('/')
        }
    }

    changePassword() {
        var self = this
        let errors = {};
        if (this.handleValidation()) {
            if (self.state.newPassword != self.state.newconfirmPassword) {
                // document.getElementById('error').style.display = 'block'
                // document.getElementById('error').innerHTML = 'Password did not match'
                errors["newconfirmPassword"] ='Password did not match';
                self.setState({errors: errors});
            } else {
                // if (document.getElementById('error'))
                //     document.getElementById('error').style.display = 'none'
                let {newPassword, oldPassword} = self.state
                let params = {
                    newPassword: newPassword,
                    oldPassword: oldPassword,
                }
                self.props.dispatch(userActions.changePassword(params))
                    .then(function (response) {
                        // console.log('response', response);
                        if (response.data.errorCode) {
                            if(response.data.errorCode==51){
                                self.setState({
                                    error: response.data.messages
                                })
                            }else if(response.data.errorCode==57){
                                self.setState({
                                    error: response.data.messages
                                })
                            }else{
                                self.setState({
                                    error: response.data.messages
                                })
                            }

                        } else {
                            self.props.history.push('/settings/account-password')
                            self.setState({
                                successMessage:'Your password has been updated successfully!',
                                errors: {},
                                newconfirmPassword: '',
                                oldPassword: '',
                                newPassword: '',
                            })
                        }
                    })
            }

        }
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;
        var self = this;
        if (!self.state.newPassword) {
            formIsValid = false;
            errors["newPassword"] = "New password can not be empty";
        }else if (self.state.newPassword.length<6) {
            formIsValid = false;
            errors["newPassword"] = "Password must have more than 6 characters";
        }
        if (!self.state.oldPassword) {
            formIsValid = false;
            errors["oldPassword"] = "Old password can not be empty";
        }else if (self.state.oldPassword.length<6) {
            formIsValid = false;
            errors["oldPassword"] = "Password must have more than 6 characters";
        }
        if (!self.state.newconfirmPassword) {
            formIsValid = false;
            errors["newconfirmPassword"] = "Confirm password can not be empty";
        }else if (self.state.newconfirmPassword.length<6) {
            formIsValid = false;
            errors["newconfirmPassword"] = "Password must have more than 6 characters";
        }

        self.setState({errors: errors});
        return formIsValid;
    }

    handlePasswordChange(event) {
        this.setState({
            error:'',
            errors:{},
            successMessage:''
        })
        if (event.target.id == 'oldPassword') {
            this.setState({oldPassword: event.target.value})
        }
        if (event.target.id == 'newPassword') {
            this.setState({newPassword: event.target.value})
        }
        if (event.target.id == 'newconfirmPassword') {
            this.setState({newconfirmPassword: event.target.value})
        }
    }

    closeEditPass=()=>{
        this.props.history.push('/settings/account-password')
    }

    componentWillUnmount(){
        document.body.classList.remove('setting-temps-pages');
    }



    render() {
        console.log("this.props",this.props);
        return (
            <div className="h_n">
            <div className="body_container cm_bg_white setting_page">

                <TopHeader title="Edit Password" {...this.props}/>
                <SideNav {...this.props}/>
                <div className='new-setting-wrapper'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <SettingHeadingTitle title="" />
                                <SettingSidebar />
                            </div>
                            <div className='col-md-8'>
                                <div className='salon-body-main-wrp account--password-temp'>
                                    <div className='new-stng-cmn saloon-settings-wrp'>
                                        <div className='text-center img-accnt-password mb-3 mb-md-5'>
                                            <img src={require('../../assets/images/password-bg.png')} width="250px" />
                                        </div>
                                        <div className="text-center desct-accnt-desc link_img">
                                            <h2 className="heading-40 mb-4 mb-md-4">Edit Password</h2>
                                        </div>
                                        <div className='editPassword-form'>
                                            <div className="form-group">
                                                <label>Old Password</label>
                                                <input type="password" className="form-control" name="oldPassword"
                                                        placeholder=""
                                                        id="oldPassword"
                                                        value={this.state.oldPassword}
                                                        onChange={this.handlePasswordChange}
                                                        autocomplete="off"/>
                                                <span className="cm_error_msg">{this.state.errors["oldPassword"]}</span>
                                            </div>

                                            <div className="form-group">
                                                <label>New Password</label>
                                                <input type="password" className="form-control" name="newPassword"
                                                        id="newPassword"
                                                        placeholder=""
                                                        value={this.state.newPassword}
                                                        onChange={this.handlePasswordChange}
                                                        autocomplete="off"/>
                                                <span className="cm_error_msg">{this.state.errors["newPassword"]}</span>
                                            </div>

                                            <div className="form-group">
                                                <label>Confirm New Password</label>
                                                <input type="password" className="form-control" name="newconfirmPassword"
                                                        id="newconfirmPassword"
                                                        placeholder=""
                                                        value={this.state.newconfirmPassword}
                                                        onChange={this.handlePasswordChange}
                                                        autocomplete="off"/>
                                                <span className="cm_error_msg">{this.state.errors["newconfirmPassword"]}</span>
                                            </div>

                                                {this.state.error ?
                                                <div className="form-group"><span className="cm_error_msg">{this.state.error}</span> </div> : ''}
                                                {this.state.successMessage ?
                                                    <div className="form-group"><span className="cm_succ_msg">{this.state.successMessage}</span></div> : ''}

                                            <div className="text-right btn-Readerss">
                                                <button className="btn btn-outline-dark pl-4 pr-4 ml-lg-3 ml-0" onClick={this.closeEditPass.bind(this)}>Cancel</button>
                                                <button type="button" className="btn btn-dark ml-3 pl-4 pr-4" onClick={this.changePassword.bind(this)}>Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        )
    }

}



function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(EditPassword);





