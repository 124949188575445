import React, { Component } from "react";
export default class Feature extends Component {
    render() {
        return (
            <div className="container pad-0">
                        <div className=" happy_business feature">
                        <div className="img">
                                <img src={require('../../assets/images/web-img/feature.png')} className="img-fluid mb-3" />
                            </div>
                            <div className="text">
                                <h4>Appointment Scheduling</h4>
                                <p>Intuitive calendar tools designed for  the demands of your business. Keep on top of your appointments, from any internet connected device or phone with our easy to use platform. </p>
                                <div className="feature-list">
                                    <p>Receive bookings from your website, social media and our app</p>
                                    <p>Salons provided with complete overview of all stylists bookings</p>
                                    <p>Spot quiet times in advance, so you can market accordingly</p>
                                    <p>Book, reschedule and cancel appointments with a touch</p>
                                </div>
                            </div>
                          
                            
                        </div>
                     </div>
        )
    }
}
