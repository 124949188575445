export const settingConstants = {

    TOGGLE_LOGIN            : 'TOGGLE_LOGIN',
    TOGGLE_SIGNUP           : 'TOGGLE_SIGNUP',
    TOGGLE_APPLE_SIGNUP     : 'TOGGLE_APPLE_SIGNUP',
    TOGGLE_FORGOT          : 'TOGGLE_FORGOT',
    TOGGLE_RESET           : 'TOGGLE_RESET',
    TOGGLE_THANKU           : 'TOGGLE_THANKU',
    TOGGLE_BOOKING          : 'TOGGLE_BOOKING',
    TOGGLE_BOOKING_SERVICE          : 'TOGGLE_BOOKING_SERVICE',
    TOGGLE_CONF_BOOKING     : 'TOGGLE_CONF_BOOKING',
    TOGGLE_LOGIN_BOOKING    : 'TOGGLE_LOGIN_BOOKING',
    TOGGLE_RESET_SUCCESS           : 'TOGGLE_RESET_SUCCESS',
    TOGGLE_EDIT_PROFILE :'TOGGLE_EDIT_PROFILE',
    TOGGLE_CHANGE_PASSWORD:'TOGGLE_CHANGE_PASSWORD',
    TOGGLE_SERVICE_POP:'TOGGLE_SERVICE_POP',
    TOGGLE_LOCATION_POP:'TOGGLE_LOCATION_POP',
    TOGGLE_DATE_POP:'TOGGLE_DATE_POP',
    TOGGLE_SOCIAL_LOGIN            : 'TOGGLE_SOCIAL_LOGIN',

};
