
import React, { Component } from 'react';
var _ = require('lodash');
  class ServiceBox extends Component {
    constructor(props) {
      super(props);
      this.state={
        activeStaff:false,
        hairdresser:{},
        activeStatus:false,
        loading:false
       }

       this.selectService = this.selectService.bind(this);
    }

    componentDidMount(){

      let self = this;
      self.setState({
        activeStatus:this.props.serviceStatus
      })
      
    }

 selectService(service){
    let self = this;
    if(this.state.activeStatus){
        this.props.selectService(service,'remove');
        self.setState({
            activeStatus:false
        })
    }else{
        this.props.selectService(service,'add');
        self.setState({
            activeStatus:true
        })
    }
    }

  convertTime(totalDuration){
      if(totalDuration>59){
        const hours = Math.floor(totalDuration / 60); 
        const minutes = totalDuration % 60; 
        if(minutes==0){
          return hours+'h ';
        }else{
          return hours+'h '+ minutes+'m'
        }
      }else{
        return totalDuration+'m'
      }
    }



  render(){
    return (<>
        <div  className={this.state.activeStatus?'____service active':'____service'}  onClick={()=>{this.selectService(this.props.service)}}>
                          <h6>{this.props?.service?.serviceName}</h6>
                          <div className='_bottom'>
                            <p className='_time mb-0'>{this.convertTime(this.props.service?.serviceTime)}</p>
                            <p className='_price mb-0'>
                            {this.props.currencySymbol}
                              {this?.props?.service?.servicePrice.toFixed(2)}</p>
                          </div>
                        </div>
        </>
       
     
  )
};
}


export default ServiceBox;
