import React, { useEffect } from 'react';
import {connect} from "react-redux";
import moment from 'moment';




const NewInvoice = (props) => {
    // console.log("props.bookingState11",props.bookingState);
    const getslotTime=(time)=> {
        let hr = parseInt(time / 60);
        let min = time - (hr * 60)
        return (hr>0?(hr + ' h ' ):"")+ (min === 0 ? '00' : min) +' min';
    }

    useEffect(()=>{
        console.log('checkSSSSSSSSSS',props.bookingState)
    },[])

const transactionDate=(client)=>{
        console.log('check new data',client);
        if(client?.transaction[0]?._id){
          //let dateInfo=ObjectId(client?.transaction[0]?._id).getTimestamp();
           // console.log(moment(dateInfo).format("DD.MM.YYYY HH:mm"));
          let timeStamp1 = parseInt(client?.transaction[0]?._id.toString().substr(0,8), 16)*1000
          let dateInfo = new Date(timeStamp1)
          return moment(dateInfo).format("dddd, MMM Do YY");
        }else{
          return "__";
        }
      }

    return(
    <>
    <div className="invoice_card_wrapper new_invoice">
        <div className="cm_head black_head">
            <div className="user-dtls">
                <div className="text">
                    <h3>{props.bookingState.selectedClient.name}</h3>
                   {props.bookingState.selectedClient._id==='64f5c7789a069cda0188fea3'?null:<p>{props.bookingState.selectedClient.email}</p>}
                </div>
                <img src={props.bookingState.selectedClient.localAvatar&&props.bookingState.selectedClient.localAvatar!=""?props.bookingState.selectedClient.localAvatar:require('../../assets/images/client-plachoder.jpg')} className=""/>
            </div>
        </div>
        <div className="cm_body">
            <div className="bobking_detail">
                <h5>{transactionDate(props.bookingState)}</h5>
                <>
                <div className="cm_b_details">
                  <p>Staff member</p><h5>{props.bookingState.hairdresserName}</h5>
                </div>
                    {props.bookingState.selectedService.map((service, index) => (
                <div className="cm_b_details" key={"s-"+index} >
                  <p>Service</p>
                        <h5 className="d-flex justify-content-between"><span>{service.service.serviceName} </span><span> {props.bookingState.currencySymbol}{service.servicePrice.toFixed(2)}</span></h5>
                </div>
                    ))}
                    {props.bookingState?.products?.map((product, index) => (
                        <div className="cm_b_details" key={"p-"+index} >
                            <p>Product</p>
                            <h5 className="d-flex justify-content-between"><span>{product.productName} </span><span> {props.bookingState.currencySymbol}{product.retailPrice.toFixed(2)}</span></h5>
                        </div>
                    ))}
                    {props.bookingState.isCheckout!==1?
                <div className="three_box">
                    <div className="cm_b_details">
                    <p>Start Time</p><h5>{props.bookingState.slotInfo.startTime}</h5>
                    </div> 
                    <div className="cm_b_details">
                    <p>Duration</p><h5>{props.bookingState.totalTime}</h5>
                    </div> 
                    {/*<div className="cm_b_details">*/}
                    {/*<p>Cost</p><h5>$99</h5>*/}
                    {/*</div> */}
                </div>:""}
                </>
                {/*<h5 className="text-center m-4">Invoice  - {props.bookingState.invoiceNumber?props.bookingState.invoiceNumber:moment().format("YYYYMMDDHHmmSS")}</h5>*/}
                <h5 className="text-center m-4">Invoice  - {props.bookingState.bookingId}</h5>
            </div>
            <div className="invoice-bill-parent">
                {console.log('check booking state',props.bookingState)}
                   <div className="invoice-bill-detls"><p>Subtotal</p><p>{props.bookingState.currencySymbol}{parseFloat(props.bookingState.totalAmount).toFixed(2)}</p></div>
                   <div className="invoice-bill-detls totls"><p>Total</p><p>{props.bookingState.currencySymbol}{parseFloat(props.bookingState.totalAmount).toFixed(2)}</p></div>
                   {parseFloat(props?.bookingState?.transactions[0]?.tipAmount)>0?<div className="invoice-bill-detls"><p>Tip</p><p>{props.bookingState.currencySymbol}{parseFloat(props?.bookingState?.transactions[0]?.tipAmount).toFixed(2)}</p></div>:null}
                    {props.bookingState.transactions.map((transaction, index) => (
                        <div key={"t-"+index} className="invoice-bill-detls"><p>{transaction.pgObjectType} </p><p> {props.bookingState.currencySymbol}{transaction.pgTransactionAmount.toFixed(2)}</p></div>
                    ))}
                   
                   {/* ({transaction.paymentType === 1 ? 'Pay by App' : ''}{transaction.paymentType === 2 ||transaction.paymentType=== 5? 'Card' : ''}{transaction.paymentType === 3 ? 'Cash' : ''}{transaction.paymentType === 4 ? 'Other' : ''}) */}
                    {props.bookingState.transaction.map((transaction, index) => (
                       <div key={"sp-"+index} className="invoice-bill-detls"><p><b>Paid ({transaction.paymentType === 1 ? 'Pay by App' : ''}{transaction.paymentType === 2 ||transaction.paymentType=== 5? 'Card' : ''}{transaction.paymentType === 3 ? 'Cash' : ''}{transaction.paymentType === 4 ? 'Other' : ''}{transaction.paymentType === 10 ? 'Saved card' : ''}) </b></p><p> {props.bookingState.currencySymbol}{(transaction.amount).toFixed(2)}</p></div>
                    ))}
                   {/*<div className="invoice-bill-detls"><p>Paid Card</p><p>£40</p></div>*/}
            </div>
          
        </div>
       
    </div>
    
    </>)
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(NewInvoice);