import React, { useState, useEffect } from "react";
import { GrClose } from "react-icons/gr";
import Select from 'react-select'
import { Link, Redirect,useLocation } from "react-router-dom";

 const BookingProduct = (props) => {
  const [item,setItem]=useState({});
  const location = useLocation();
  const [staffError,setStaffError]=useState('');
  const [selectedStaff,setSelectedStaff]=useState('');
  const [servicePrice,setServicePrice]=useState(0);
  const [updatePrice,setUpdatePrice]=useState(0);
  const [priceError,setPriceError]=useState('');

  const handleClickProduct = (indexId) => {
    const queryParams = location.pathname.split('/');
    const salonId = queryParams[queryParams?.length-1];
    let currentPageUrl = props.propData.history.location.pathname;
    const stateObj = { pageLoadingStatus: 0, previousPageUrl:currentPageUrl };
    const url =   `/SelectProduct/${salonId}/${indexId}`
     props.propData.history.push({
      pathname: url,
      state: stateObj,
    });
  };

  useEffect(()=>{
   setItem(props?.item);
   setServicePrice(props?.item?.retailPrice);
   console.log('check data',props?.item)
   setSelectedStaff(props?.selectedStaff);
  },[props])

  const checkKey = (key) =>{
    console.log(key)
    return item[key]!==undefined?true:false;
  }

  useEffect(()=>{
    var timerValue;
    let err = '';
    setServicePrice(updatePrice);
    let testValidPrice = /^\d{0,8}(\.\d{1,2})?$/.test(updatePrice);
    if(updatePrice!==0){
      if(!isNaN(updatePrice) && updatePrice>=0.00 && testValidPrice){
        setPriceError('');
        err='';
      }else{
        setPriceError('Enter valid price.');
        err='Enter valid price.';
      }
    timerValue= setTimeout(()=>{
      props.changeItemPrice(item,'product',updatePrice,err);
    },2000);
    }
    return ()=>{
      clearTimeout(timerValue);
    }
   },[updatePrice]);

  const handleClient = (hairdresserId,indexId) => {
    if(hairdresserId && hairdresserId!==''&& indexId && indexId!==''){
      setStaffError('');
      let currentPageUrl = props.propData.history.location.pathname;
      const stateObj = { pageLoadingStatus: 0, previousPageUrl:currentPageUrl };
      const url =   `/select-client/${hairdresserId}/${indexId}`
       props.propData.history.push({
        pathname: url,
        state: stateObj,
      });
    }else{
      setStaffError('Staff Member is required.');
    }
  };


  return (
    <div className="row">
      <div className="col-md-11">
        <div className="service_div bg-white rounded">
        <div className="row vertical-top">
          <div className="col-md-6 text-left mt-1 mb-3">
            <span className="service_2">Product {item?.id}</span>
          </div>
          <div className="col-md-6 text-right mb-3">
            {(props?.isPaymentComplete==0)?<GrClose
              className="gr_close"
              onClick={()=>props.removeProduct('product',item?.id)}
            />:null}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 pr-2 ">
            <div className={'input_group_u'}>
            <span className="_field_label_vk">Staff Member</span>
            <div className="service_selected1 _disabled_input">{selectedStaff?.name
!==undefined?selectedStaff?.name:'Select staff'}</div>
            {staffError!==''?<span className={'text-danger'}>{staffError}</span>:null}
          </div>
          </div>
          <div className="col-md-6 pl-2">
          <div className={'input_group_u'}>
            <span className="_field_label_vk">Client</span>
             <div className="service_selected1 _disabled_input">{props?.clientDetail?.name}</div>
          </div>
        </div>
        </div>
        <div className="row">
          <div className="col-md-3">
          <div className={'input_group_u'}>
          <span className="_field_label_vk">Price</span>
            <div className={`currency_div mt-0 ml-0 ${item?.productName?'currency_div_edit':''}`}>
              <div className="text-center px-3 currency_sml">£</div>
              {/*<input type="text" className="text-center pl-3 currency_sml1 currency_sml_input" value={item?.servicePrice!==undefined?Number.isInteger(item?.servicePrice)?item?.servicePrice+'.00':item?.servicePrice:`0.00`} />*/}
              {(item?.productName&&props?.isPaymentComplete!==1)?<input type="text" className="text-center pl-3 currency_sml1 currency_sml_input edit_price_input" placeholder={'00.00'} onChange={(e)=>setUpdatePrice(e.target.value)} value={servicePrice!==undefined?Number.isInteger(servicePrice)?servicePrice+'.00':servicePrice:`0.00`} />:<input type="text" className="text-center pl-3 currency_sml1 currency_sml_input edit_price_input"  value={servicePrice!==undefined?Number.isInteger(servicePrice)?servicePrice+'.00':servicePrice:`0.00`} disabled={true}/>
              }
          </div>
          {priceError!==''?<span className="text-danger">{priceError}</span>:null}
            </div>
          </div>
          <div className="col-md-9">
          <div className={`input_group_u ${props?.isPaymentComplete===1?'hide__arrow':''}`}>
          <span className="_field_label_vk">Product Name</span>
        {(props?.isPaymentComplete==0)?<div
              className="mb-4 bg-white service_selected1 border-1"
              onClick={()=>handleClickProduct(item?.id)}
            >
              {checkKey('productName')?item?.productName:'Select product...'}
            </div>:<div
              className="mb-4 bg-white service_selected1 border-1 disabled_div"
            >
              {checkKey('productName')?item?.productName:'Select product...'}
            </div>}
           
            </div>
          </div>
        </div>
      </div>
     </div>
    </div>
  );
};

export default BookingProduct;