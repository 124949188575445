import React, { Component } from "react";
import TopHeader from "../template/TopHeader";
import SideNav from "../template/SideNav";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BiCart } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";

class ViewProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editDeleteModal: false,
      selectedProduct: null,
      currencySymbol: "£",
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location.state && location.state.product) {
      const { product } = location.state;
      //   console.log("productttt",product);
      this.setState({ selectedProduct: product });
    }
  }

  handleCloseModal = () => {
    this.setState({
      editDeleteModal: false,
    });
  };

  handleEditDelete = () => {
    this.setState({ editDeleteModal: true });
  };

  handleClick = () => {
    const { history } = this.props;
    const { selectedProduct } = this.state;
    if (selectedProduct) {
      history.push({
        pathname: "/product/editProduct",
        state: { productId: selectedProduct._id, product: selectedProduct },
      });
    }
  };

  handleClickDelete = () => {
    const { history } = this.props;
    const { selectedProduct } = this.state;
    if (selectedProduct) {
      history.push({
        pathname: "/product/deleteProduct",
        state: { productId: selectedProduct._id },
      });
    }
  };

 
  handleCheckout = () => {
    const { history } = this.props;
    const { selectedProduct } = this.state;
    if (selectedProduct) {
      history.push({
        pathname: "/product/productCheckout",
        state: { productId: selectedProduct._id, product: selectedProduct },
      });
    }
  };

  render() {
    const { editDeleteModal, selectedProduct, currencySymbol } = this.state;
    return (
      <div>
        <div className="h_n">
          <div
            className="body_container cm_bg_white"
            style={{ background: "none" }}
          >
            <TopHeader title="View Product" {...this.props} />
            <SideNav {...this.props} />
            <div className="product_div">
              <div className="product_main_div">
                <h3 className="py-4 pl-2">Product Info</h3>
                <BsThreeDotsVertical
                  style={{ cursor: "pointer" }}
                  size={30}
                  className="menu_button"
                  onClick={this.handleEditDelete}
                />
                {/* <div className="cart_div">
                  <BiCart
                    size={30}
                    className="cart_show"
                    onClick={this.handleCheckout}
                  />
                </div> */}

                <h6 className="fw-bold ml-2">Product Name</h6>
                <div className="ml-2 mb-4 product_input">
                  {selectedProduct?.productName}
                </div>
                <h6 className="fw-bold ml-2">Retail Price</h6>
                <div className="ml-2 mb-4 product_input">
                  {currencySymbol}
                  {selectedProduct?.retailPrice.toFixed(2)}
                </div>
                <h6 className="fw-bold ml-2"> Stock Price</h6>
                <div className="ml-2 mb-4 product_input">
                  {currencySymbol}
                  {selectedProduct?.stockPrice.toFixed(2)}
                </div>
                <h6 className="fw-bold ml-2">Commission Enabled</h6>
                <div className="ml-2 mb-4 product_input">
                  {selectedProduct?.commissionEnable === 1 ? "yes" : "no"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          blurClass="blure_bg"
          show={editDeleteModal}
          onHide={this.handleCloseModal}
          className="product_modal1 bg_white_modal view_product_modal"
        >
          <Modal.Body>
            <h4 onClick={this.handleClick} className="text-center pb-2">
              Edit Product
            </h4>
            <h4
              className="text-center text_h3 pt-3"
              onClick={this.handleClickDelete}
            >
              Delete Product
            </h4>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withRouter(ViewProduct);
