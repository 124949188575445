import React, { Component } from "react";
import TopHeader from "../template/TopHeader";
import { hairdresserActions } from "../../_actions";
import SideNav from "../template/SideNav";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

var _ = require("lodash");

function debounce(func, delay) {
  let timer;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(context, args), delay);
  };
}
class NewSelectService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hairdresserList: [],
      search: "",
      selectedService: null,
      fromServices: [],
      serviceNumber: "",
      startTime: "",
      serviceDuration: 0,
      previousPrice: 0,
      hairdresserServices:[],
      tempHairdresserServices:[],
      searchText:'',
      currentLoadingStatus:0,
      previousPage:'',
    };

   
    this.searchService = this.searchService.bind(this);
    this.localDataFilter =  this.localDataFilter.bind(this);
    this.debouncedSearch = debounce(this.localDataFilter, 1000);
  }

  componentDidMount() {
    var self = this;
    if (localStorage.getItem("accessToken")) {
      if(self.props?.hairdresser?.hairdresserSelectedServices){
        self.setState({
          hairdresserServices:self.props?.hairdresser?.hairdresserSelectedServices,
          tempHairdresserServices:self.props?.hairdresser?.hairdresserSelectedServices,
        })
      }
      const { state } = self.props.location;
      if (state) {
        const { pageLoadingStatus , previousPageUrl, start} = state;
        if(pageLoadingStatus===0){
          self.setState({
            currentLoadingStatus:pageLoadingStatus,
            previousPage:previousPageUrl,
            startTime:start,
          })
        }
      }
      //self.props.dispatch(userActions.profile(params))
      document.body.classList.add("login-home-temp");

      let { dispatch } = this.props;

      let params = {
        hairdresserId: self?.props?.match?.params?.hairdresserId,
      };

        dispatch(hairdresserActions.getHairdresserService(params))
        .then(function (response) {
          self.setState({
            hairdresserServices:response?.data?.data?.hairdresserSelectedServices,
            tempHairdresserServices:response?.data?.data?.hairdresserSelectedServices,
          })
        });
    } else {
      this.props.history.push("/");
    }
  }

  _searchWord=(line,word)=>{
    // console.log("=====",line,word,line.indexOf(word));
    if (line.indexOf(word) > -1) {
      return true;
    } else {
      return false;
    }
  }

  localDataFilter = () => {
    let self=this;
    const { tempHairdresserServices, searchText } = this.state;
  
    if (searchText === '') {
      // If searchText is empty, reset hairdresserServices to its original state
      this.setState({
        hairdresserServices: tempHairdresserServices,
      });
    } else {
      // Filter the hairdresserServices based on the searchText
      const filteredServices = tempHairdresserServices.map((service) => {
        const newData = service.hairdresserService.filter((__service) =>
          __service.serviceName.toLowerCase().startsWith(searchText.toLowerCase())||self._searchWord(__service.serviceName.toLowerCase(),searchText.toLowerCase())
        );
  
        // Update the service object with the filtered data
        return { ...service, hairdresserService: newData };
      });
  
      this.setState({
        hairdresserServices: filteredServices,
      });
    }
  };

  searchService(e) {
    let self = this;
       self.setState({
        searchText: e.target.value,
      },
      function () {
        this.debouncedSearch();
      }
    )
}

handleClick(selectedService){
  const { dispatch,history } = this.props;
  selectedService.indexId = +this?.props?.match?.params?.indexId;
  let currentPageUrl = history.location.pathname;
  selectedService.servicePrice = selectedService.servicePrice.toFixed(2);
  dispatch(hairdresserActions.selectedService(selectedService));
  const stateObj = { pageLoadingStatus: this.state.previousPage?2:this.state.currentLoadingStatus, 
    previousPageUrl:currentPageUrl,
    start:this.state.startTime};
  const url =  this.state.previousPage;
   history.push({
    pathname: url,
    state: stateObj,
  });
 }


  render() {
    let { hairdresser } = this.props;
    console.log("this.props",this.props);
    console.log("this.state",this.state);
    return (
      <div className="h_n">
        <div className="body_container cm_bg_white select__service">
          <TopHeader title="Select a Service" 
          headerButtonStatus={true}
          cancelButton={true}
         {...this.props} 
          />
          <SideNav {...this.props} />
          <div className="container">
            <div className="row ">
              <div className="col-md-1"></div>
              <div className="cm_search_wrapper client_search m-0 col-md-10 mt-4">
                <input
                  type="text"
                  className="form-control "
                  placeholder="Search…"
                  name="search"
                  value={this.state.searchText}
                  onChange={this.searchService}
                  autoComplete="off"
                />
                <i className="fa fa-search mr-4" />
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
          <div className="container">
            <div className="row black_heading">
              <div className="col-md-1"></div>
              <div className="col-md-10 d-flex product">
                <div className="col-md-6 col-xs-6">
                  <h5>Service</h5>
                </div>
                <div className="col-md-2 col-xs-2 text-middle">
                  <h5>Duration</h5>
                </div>
                <div className="col-md-2 col-xs-2 text-middle">
                  <h5>Processing Time</h5>
                </div>
                <div className="col-md-2 col-xs-2 text-right">
                  <h5>Pricing</h5>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>

          <div className="container">
            <div className="row black_heading">
              <div className="col-md-1"></div>
              <div className="col-md-10 col-xs-12 product_data p-0 overflow-scroll">
                <table className="table table-striped border-0 w-100">
                  <tbody className="">
                    {this.state.hairdresserServices?.length>0?this.state.hairdresserServices?.map((hairdresserService, i) => (
                      <React.Fragment key={i}>
                        {hairdresserService?.hairdresserService?.filter(serFl=>serFl?.servicePrice!==0).length>0?<tr key={'cat_title_'+i+hairdresserService?.categoryName}><td colSpan={4} className="border-0">
                          <h3  className="">{hairdresserService?.categoryName}</h3>
                        </td></tr>:null}
                        {/* this.filterRestServices(services?.serviceId) ===
                          false ? */}
                        {hairdresserService?.hairdresserService?.filter(serFl=>serFl?.servicePrice!==0 || serFl?.serviceTime !== 0)?.map((services,k) =>
                          (
                            <tr key={'services_'+k+'_'+i+hairdresserService?.categoryName} onClick={()=>this.handleClick(services)}>
                              <td
                                className="align-middle border-0 serviceTable"
                              >
                                <span className="text-dark pl-4">
                                  {services?.serviceName !== ""
                                    ? services?.serviceName
                                    : "Select Service..."}
                                </span>
                              </td>
                              <td className="text-middle border-0 m-0">
                                <span className="text-dark pr-4">
                                  {services?.serviceTime !== 0
                                    ? services?.serviceTime + "m"
                                    : "--"}
                                </span>
                              </td>
                              <td className="text-middle border-0 m-0">
                                <span className="text-dark pr-4">
                                  {services?.processingTime&&services?.processingTime !== 0
                                      ? services?.processingTime + "m"
                                      : "--"}
                                </span>
                              </td>
                              <td className="text-right border-0 m-0 pr-4">
                                <span className="text-dark ">
                                  {(services.servicePrice!=''&& services?.serviceTime !== 0)||services?.serviceTime !== 0
                                    ? hairdresser?.userInfo?.hairdresser?.currencySymbol &&
                                      hairdresser?.userInfo?.hairdresser.currencySymbol !== ""
                                      ? hairdresser.userInfo.hairdresser.currencySymbol + services.servicePrice.toFixed(2) : "£" + services.servicePrice.toFixed(2) : "--"}
                                </span>
                              </td>
                            </tr>
                          )
                        )}
                      </React.Fragment>
                    )):null}
                  </tbody>
                </table>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(withRouter(NewSelectService));
