import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs, Tab, Modal, Dropdown } from "react-bootstrap";
import Select from "react-select";
import SideNav from "../template/SideNav";
import TopHeader from "../template/TopHeader";
import AddCard from "../addCardPopup/addCard";
import toast from "react-toast-notification";
import siteSetting from "../../config/env";
import { userActions, settingActions } from "../../_actions";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { salonService,paymentService } from "../../_services";
import SettingSidebar from "./SettingSidebar";
import SettingHeadingTitle from "./SettingHeadingTitle";
import Lottie from "react-lottie";
import animationData from "../../assets/animation-data/loader.json";
import { Close, Dots } from "../SvgIcon";

const getSuggestionValue = (suggestion) => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;
const searchOptions = {
  //componentRestrictions: { country: ['GB'] },
  //types: ['city']
};
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

class BookingUrl extends Component {
  constructor(props) {
    super(props);

    this.state = {
      salonImagePrev: require("../../assets/images/salonpic.png"),
      salonImage: "",
      salonName: "",
      allow: false,
      salonUrl: "",
      selfEmpUrl: "",
      deviceToken: "asfasfasfas",
      isShow: localStorage.getItem("isShow")=='true'?true:false,
      isLoader: true,
      isCopyText: false,
      noShowProtectionText: "Client does not show up",
      noShowProtectionFeePercentage: 100,
      noShowProtectionStatus: true,
      cancellationPolicies: [],
      cancellationOptions: siteSetting?.cancellationPolicyOptions,
        onlineBookingUptoBeforeOptions:siteSetting?.onlineBookingUptoBeforeOptions,
        onlineBookingUptoOptions:siteSetting?.onlineBookingUptoOptions,
        noShowFeeChargeBy: 2,
      // noShowFeeChargeByEdit: false,
      // editCancellationPolicy: false,
      noShowChangeDetect:false,
      isShowDetect:false,
      isShowOld:null,
      cancellationChangeDetect:false,
      policyType: ["noShowProtectionFee"],
      showCardPopup:false,
      inputErrorStatus:false,
      noShowProtectionFeeError:false,
        onlineBookingUptoBefore:{value:0, label:'Anytime before appointment start time'},
        onlineBookingUpto:{value:12,  label:'No more than 12 months in advance'},
        onlineBookingUptoBeforeDefault:{value:0, label:'Anytime before appointment start time'},
        onlineBookingUptoDefault:{value:12,  label:'No more than 12 months in advance'},
    };
    // this.onChanged = this.onChanged.bind(this);
    this.checkReadOnly = this.checkReadOnly.bind(this);
    this.getCancellationPolicy = this.getCancellationPolicy.bind(this);
    this.onSavePaidBy = this.onSavePaidBy.bind(this);
  }

  componentDidMount() {
    let employmentType = {};
    var self = this;
    if (localStorage.getItem("accessToken")) {
      let params = {
        defaultRedirectTo: localStorage.getItem("defaultRedirectTo"),
      };
      this.getCancellationPolicy();
      params.latitude = siteSetting.default_latitude;
      params.longitude = siteSetting.default_longitude;
      self.props
        .dispatch(userActions.profile(params))
        .then(function (response) {
          // console.log("response", response.data.data);
          if (response.data.data) {
            if (self.props.user.userInfo.hairdresser.salon) {
              self.setState(
                {
                  salonUrl:
                    siteSetting.api.weburl +
                    "salon/" +
                    self.props.user.userInfo.hairdresser.salon._id,
                  selfEmpUrl:
                    siteSetting.api.weburl +
                    "salon/" +
                    self.props.user.userInfo.hairdresser.salon._id +
                    "/" +
                    self.props.user.userInfo.hairdresser.userId +
                    "/service",
                  salonName:
                    self.props.user.userInfo.hairdresser.salon.salonName,
                  formattedAddress:
                    self.props.user.userInfo.hairdresser.salon.formattedAddress,
                  administrativeAreaLevel1:
                    self.props.user.userInfo.hairdresser.salon
                      .administrativeAreaLevel1,
                  administrativeAreaLevel2:
                    self.props.user.userInfo.hairdresser.salon
                      .administrativeAreaLevel2,
                  countryLongName:
                    self.props.user.userInfo.hairdresser.salon.countryLongName,
                  countryShortName:
                    self.props.user.userInfo.hairdresser.salon.countryShortName,
                  latitude: self.props.user.userInfo.hairdresser.salon.loc[1],
                  longitude: self.props.user.userInfo.hairdresser.salon.loc[0],
                  salonId: self.props.user.userInfo.hairdresser.salon._id,
                  isShow: self.props.user.userInfo.hairdresser.salon.isShow,
                    isShowOld:self.props.user.userInfo.hairdresser.salon.isShow,
                  postalCode:
                    self.props.user.userInfo.hairdresser.salon.postalCode,
                  salonImagePrev: self.props.user.userInfo.hairdresser.salon
                    .imageUrl
                    ? self.props.user.userInfo.hairdresser.salon.imageUrl
                    : require("../../assets/images/salonpic.png"),
                },
                function () {
                  self.setState({
                    isLoader: false,
                  });
                }
              );
            }
          } else {
            self.props.history.push("/");
          }
        })
        .catch((err) => {
          self.props.history.push("/");
        });
    } else {
      self.props.history.push("/");
    }
  }

  toggleChange = () => {
    this.setState({
      isShow: !this.state.isShow,
        isShowDetect:true,
    });
  };
 
  clip = (event) => {
    event.target.select();
    navigator.clipboard.writeText(this.state.salonUrl);
  };

  copyUrl = (url) => {
    //event.target.select();
    navigator.clipboard.writeText(url);
    this.setState({
      isCopyText: true,
    });
  };

  updateSalon=()=> {
    this.setState({
      error: "",
    });
    let self = this;

      let data = new FormData();
      data.append("salonId", this.state.salonId);
      data.append("salonName", this.state.salonName);
      data.append("formattedAddress", this.state.formattedAddress);
      data.append("latitude", parseFloat(this.state.latitude));
      data.append("longitude", parseFloat(this.state.longitude));
      data.append("countryShortName", this.state.countryShortName);
      data.append("countryLongName", this.state.countryLongName);
      data.append("postalCode", this.state.postalCode);
      data.append(
        "administrativeAreaLevel1",
        this.state.administrativeAreaLevel1
      );
      data.append(
        "administrativeAreaLevel2",
        this.state.administrativeAreaLevel2
      );
      data.append("deviceToken", this.state.deviceToken);
      // data.append("image", this.state.salonImage);
      data.append("isShow", this.state.isShow);

      salonService
        .updateSalon(data)
        .then(function (response) {
          console.log("client response", response);

          if (response.data.errorCode) {
            if (response.data.errorCode == 51) {
              self.setState({
                salonNameError: response.data.messages,
              });
            } else {
              self.setState({
                error: response.data.messages,
              });
            }
          } else {
            localStorage.setItem("isShow", self.state.isShow);
            //self.props.history.push('/setting')
            self.setState({
                isShowDetect:false,
                isShowOld:self.state.isShow,
              successMessage: "Your salon has been updated successfully!",
            });
              toast.success("Your salon has been updated successfully!");
          }
        })
        .catch((err) => {
          console.log("errr", err);
          //dispatch(failure(err));
        });
    // }
  }

  // gotoHome = () => {
  //   this.props.history.push("/diary/" + localStorage.getItem("salonId"));
  // };


  checkReadOnly() {
    //console.log("this.props.user.userInfo.hairdresser.isSalonOwner",this.props.user.userInfo.hairdresser.isSalonOwner)
    if (
      this.props.user &&
      this.props.user.userInfo &&
      this.props.user.userInfo.hairdresser.isSalonOwner === 1
    ) {
      return true;
    } else {
      return false;
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("setting-temps-pages");
  }

  getCancellationPolicy = () => {
    let self = this;
    let params = {
      isActiveIntend: 0,
    };

    self.props
      .dispatch(settingActions.getCancellationPolicy(params))
      .then((policyData) => {
      const responsePolicyData = policyData?.data?.responseData?.result[0];
      const noShowStatusData = responsePolicyData?.noShowStatus == 1 ? true : false;
        let updatedArr = responsePolicyData?.cancellationPolicy?.map((item) => {
          item.selectedOption = self?.state?.cancellationOptions.find(
            (option) => option.value == item.beforeBookingHour
          );
          return item;
        });
          let onlineBookingUptoBefore=self.state.onlineBookingUptoBeforeOptions?.find((item)=>{
             if(item.value == responsePolicyData?.onlineBookingUptoBefore){
                 return item
             }
        })
          let onlineBookingUpto=self.state.onlineBookingUptoOptions?.find((item)=>{
              if(item.value == responsePolicyData?.onlineBookingUpto){
                  return item
              }
          })


          self.setState({
          cancellationPolicies: updatedArr?.length>0?updatedArr:[],
          noShowProtectionFeePercentage:
            responsePolicyData?.noShowFeeInPercentage
              ? responsePolicyData?.noShowFeeInPercentage
              : 100,
          noShowFeeChargeBy: responsePolicyData?.noShowFeePaidBy?responsePolicyData?.noShowFeePaidBy:self.state.noShowFeeChargeBy,
          noShowProtectionStatus:noShowStatusData,
            onlineBookingUptoBefore:onlineBookingUptoBefore?onlineBookingUptoBefore:{value:0,label:'Anytime before appointment start time'},
            onlineBookingUpto:onlineBookingUpto?onlineBookingUpto:{value:12,label:'No more than 12 months in advance'},
              onlineBookingUptoDefault:onlineBookingUpto?onlineBookingUpto:{value:12,label:'No more than 12 months in advance'},
              onlineBookingUptoBeforeDefault:onlineBookingUptoBefore?onlineBookingUptoBefore:{value:0,label:'Anytime before appointment start time'},
        });
        localStorage.setItem('noShowStatus',noShowStatusData);
      });

  };

  onChangeSetting=(e,type)=>{
      // console.log("e,type",e,type)
      let self = this;
      //let dataValue = e?.value === undefined ? e?.target?.value : e;
      self.setState({
          [type]:e,
          cancellationChangeDetect:true
      })
  }

  onChangePolicy = (selectedItem, input, type) => {
    let self = this;
    let dataValue = input?.value === undefined ? input?.target?.value : input;
    let inputErrorStatus = false;
    if(self.state.cancellationPolicies?.length>1 && type==='cancellationPolicySelect'){
      let filterInCludedData = self.state.cancellationPolicies.filter(item=>item?.beforeBookingHour==dataValue?.value);
      if(filterInCludedData?.length>0){
        self.setState({
          inputErrorStatus:true,
        })
        inputErrorStatus= true;
        toast.error("Option already selected.");
      }
    
    }

   

    let updatedData = self.state.cancellationPolicies.map((item) => {
      if (item?._id == selectedItem._id) {
        if (dataValue?.value == undefined) {
            dataValue = dataValue===''?'':+dataValue;
            item.amountInPercentage = dataValue;
        } else {
          item.selectedOption = inputErrorStatus?null:dataValue;
          item.beforeBookingHour = dataValue?.value;
        }
      }
      return item;
    });

    self.setState({
      cancellationPolicies: [...updatedData],
      policyType:type,
      cancellationChangeDetect:true,
    });
  };

 addCancellationRow = () => {
    let self = this;
    let optionContent = {
      _id: self.state.cancellationPolicies?.length - 1 + 1,
    };

    if(!this.isPolicyValidate()){
      return;
    }
   

    self.setState({
      cancellationPolicies: [...self.state.cancellationPolicies, optionContent],
    });
  };


 isPolicyValidate = () =>{
  let self = this;
  let updatedValidation =[];
  let valueErrorArr = self.state.cancellationPolicies.map(
    (item) =>{
        if(item.beforeBookingHour <= 0 ||
          item?.amountInPercentage <= 0 ||
          item.beforeBookingHour === undefined ||
          item?.amountInPercentage === undefined ||
          item?.amountInPercentage > +100 
        ){
            updatedValidation.push(item);
            if(item.beforeBookingHour === undefined){
              toast.error("Please select client cancels online.");
            }
            console.log("item",item);
            if(item?.amountInPercentage === undefined || item?.amountInPercentage === ""||
                item?.amountInPercentage > 100 ||item?.amountInPercentage <=0 ){
                toast.error("Please input valid percentage amount.");
            }
            item.errorStatus= true;
            return item;
          }else{
            item.errorStatus= false;
            return item;
          }
    });

    self.setState({
      cancellationPolicies:valueErrorArr,
    })


    if(self.state.noShowProtectionFeePercentage>100){
      toast.error("Please input valid percentage amount.");
      self.setState({
        noShowProtectionFeeError:true
      })
      return false;
     }else{
      self.setState({
        noShowProtectionFeeError:false
      })
     }
// console.log("item",updatedValidation);
  if (updatedValidation?.length > 0) {
    //toast.error("Please select client cancels online.");
    return false;
  }else{
    return true;
  }
 }

  updateCancellationPolicy = () => {
    let self = this;
    let params = {};

    if(!this.isPolicyValidate()){
      return;
    }

     params.noShowPolicy = {
      noShowFeeInPercentage:self.state.noShowProtectionFeePercentage,
      noShowStatus:self.state?.noShowProtectionStatus ? 1 : 0,
     }

      params.onlineBookingUptoBefore=self.state.onlineBookingUptoBefore.value;
          params.onlineBookingUpto=self.state.onlineBookingUpto.value;

     params.cancellationPolicies = self.state.cancellationPolicies?.map((policyData)=>{
            return {amountInPercentage:policyData?.amountInPercentage,beforeBookingHour:policyData?.beforeBookingHour};
        })
    
    self.props
      .dispatch(settingActions.updateCancellationPolicy(params))
      .then((policyData) => {
        // console.log("update policy data", policyData);
        if (policyData?.data?.statusCode == 1) {
          self.setState({
            // editCancellationPolicy: false,
            // noShowFeeChargeByEdit:false,
            noShowChangeDetect:false,
            cancellationChangeDetect:false,
          });
          toast.success(policyData?.data.responseData?.message);
          this.getCancellationPolicy();
        }
      })
      .catch((err) => {
        toast.error(err.messages);
      });
  };


  deleteCancellationPolicy = (item) => {
    let self = this;
    let filteredData = self.state.cancellationPolicies.filter(
      (policyData) =>policyData?._id!=item._id);
      self.setState({
        cancellationPolicies: filteredData,
         cancellationChangeDetect:true
      });

  };



  onChangeProtectionFee = (type, value) => {
    let self = this;
    if (type === "status") {
        self.setState({ 
          noShowProtectionStatus: value, 
          cancellationChangeDetect:true
        });
        let noShowStatus = value?true:false;
        localStorage.setItem('noShowStatus',noShowStatus);
    }
    if (type === "percentage") {
      self.setState({ 
        noShowProtectionFeePercentage: value,
        cancellationChangeDetect:true,
      });
    }
    
    self.setState({
      policyType: [...this.state?.policyType, "noShowProtectionFee"],
    });
  };

  noChangePaidBy = (data) => {
    let self = this;
    // if (self.state.noShowFeeChargeByEdit) {
      self.setState({
        noShowFeeChargeBy: data,
        noShowChangeDetect:true
      });
    // }
  };

  onSavePaidBy = () => {
    let self = this;
    if (self.state.noShowChangeDetect) {
      self.setState({
        noShowChangeDetect: false,
      });

      let params = {
        noShowFeePaidBy: self?.state?.noShowFeeChargeBy,
      };

      self.props
        .dispatch(settingActions.updateNoShowProtectionFee(params))
        .then((policyData) => {
          if (policyData?.data?.statusCode == 1) {
            self.setState({
              // editCancellationPolicy: false,
              // noShowFeeChargeByEdit:false,
              noShowChangeDetect: false,
            });
            toast.success(policyData?.data.responseData?.message);
          }
        })
        .catch((err) => {
          toast.error(err.messages);
        });
    }
  };

  saveChanges = () => {
    let self = this;

    if (self?.state?.cancellationChangeDetect) {
        self.updateCancellationPolicy();
    }
  
    if (self?.state?.noShowChangeDetect) {
      this.onSavePaidBy();
    }
    if(self?.state?.isShowDetect){
        this.updateSalon();
    }
  };


 resetAllSetting =()=>{
   this.getCancellationPolicy();
   this.setState({
    // editCancellationPolicy: false,
    // noShowFeeChargeByEdit:false,
       isShowDetect:false,
    noShowChangeDetect:false,
       isShow:this.state.isShowOld,
    cancellationChangeDetect:false,
    onlineBookingUptoBefore:this.state.onlineBookingUptoBeforeDefault,
       onlineBookingUpto:this.state.onlineBookingUptoDefault
   });
 }
 // noShowChangeDetect
 // cancellationChangeDetect

  render() {
    // console.log("settings",this.state,this.checkReadOnly());
    // console.log("settings props",this.props);
      console.log("settings state",this.state);
    return (
      <div className="h_n">
        <div className="body_container cm_bg_white setting_page">
          <TopHeader
            title="Booking Settings"
            isLoadingStatus={false}
            headerButtonStatus={true}
            cancelRefreshPage={
              this.state.noShowChangeDetect ||
              this.state.cancellationChangeDetect||
              this.state.isShowDetect
            }
            resetAllSetting={this.resetAllSetting}
            saveCancellationButtonStatus={
              this.state.noShowChangeDetect ||
              this.state.cancellationChangeDetect||
                this.state.isShowDetect
            }
            saveCancellationPolicy={this.saveChanges}
            isPolicySubmit={false}
            {...this.props}
          />
          <SideNav {...this.props} />
          <AddCard />
          <div className="new-setting-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <SettingHeadingTitle title="Online Bookings" />
                  <SettingSidebar 
                  />
                </div>
                <div className="col-md-8">
                  <div className="salon-body-main-wrp booking-urlpage">
                    <div className="new-stng-cmn  sln-fmr-dtls-mob _booking_setting saloon-settings-wrp">
                      <h5>Online Booking Link</h5>
                      <p>
                        Embed this booking link in your website, social media
                        and and outbound marketing to take bookings online.
                      </p>
                      <label className="mb-0" style={{ color: "#4e4e4e" }}>
                        Booking Link
                      </label>
                      <div className="form-group clipBorad __newclipBorad">
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.salonUrl}
                          onClick={this.clip.bind(this)}
                          disabled={this.checkReadOnly() ? "" : "disabled"}
                        />
                        <button
                          className={
                            this.state.isCopyText === false
                              ? "btn btn-info"
                              : "btn btn-black"
                          }
                          type="button"
                          onClick={() => this.copyUrl(this.state.salonUrl)}
                        >
                          {this.state.isCopyText === false
                            ? "Copy Link"
                            : "Link Copied"}
                        </button>
                      </div>
                      <div className="form-group pl-0">
                        <div className="check_wraper check-wrp-settings para-30">
                          <input
                            type="checkbox"
                            checked={this.state.isShow}
                            name="allow"
                            onChange={this.toggleChange}
                            disabled={this.checkReadOnly() ? "" : "disabled"}
                          />
                          <span />
                          {/*Allow clients to book directly through your website, social media and the Beu marketplace.*/}
                          Allow clients to book directly through the Beu
                          marketplace.
                        </div>
                      </div>
                    </div>

                    <div className="new-stng-cmn  sln-fmr-dtls-mob _booking_setting saloon-settings-wrp pos-relative">
                     {/* {!this?.state?.editCancellationPolicy ? (
                        <Dropdown className="transaction_Dropdown">
                          <Dropdown.Toggle id="dropdown-basic">
                            <span>
                              {" "}
                              <Dots />
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                this.setState({
                                  editCancellationPolicy: true,
                                  noShowFeeChargeByEdit:false,
                                });
                              }}
                            >
                              Edit
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : null} */} 
                      <h5>Capture Card Details</h5>
                      <p>
                        Set fees for clients cancelling online and not showing
                        up. If the client cancels within the threshold they will
                        be charged. If a deposit has been set, then the charge will be the percentage of the remaining amount.
                      </p>
                      <div className="">
                        <div className="_row _first">
                          <div className="_card">
                            <div
                              className={`form-group input_group_u  _staff_member`}
                            >
                              <span className="_field_label_vk">
                                No Show Protection
                              </span>
                              <input
                                type="text"
                                placeholder="Client does not show up "
                                value={this.state.noShowProtectionText}
                                onChange={(e) => {
                                  this.setState({
                                    noShowProtectionText: e.target.value,
                                  });
                                }}
                                disabled={true}
                                className="form-control inactive-input-field"
                              />
                            </div>
                          </div>
                          <div className="_fee">
                            <div
                              className={`form-group input_group_u  _staff_member`}
                            >
                              <span className="_field_label_vk">Fee</span>
                              <input
                                type="number"
                                className={this.state.noShowProtectionFeeError?'form-control text-center active-input-field redBorder':'form-control text-center active-input-field'}
                                value={
                                  this?.state?.noShowProtectionFeePercentage
                                }
                                onChange={(e) => {
                                  this.onChangeProtectionFee(
                                    "percentage",
                                    e.target.value
                                  );
                                }}
                                // disabled={!this.state.editCancellationPolicy}
                                // placeholder="100"
                              />
                              <span className="_persantge">%</span>
                            </div>
                          </div>
                          <div className="_icon">
                            <div className="salon_switch_container pl-0 ">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={this?.state?.noShowProtectionStatus}
                                  onChange={(e) => {
                                    this.onChangeProtectionFee(
                                      "status",
                                      e.target.checked
                                    );
                                  }}
                                  // disabled={!this.state.editCancellationPolicy}
                                />
                                <span className={"slider round"}></span>
                              </label>
                            </div>
                          </div>
                        </div>

                        {this.state?.cancellationPolicies?.length > 0
                          ? this.state?.cancellationPolicies?.map(
                              (policy, i) => (
                                <div className="_row" key={"_treshold_" + i}>

                                  <div className="_card">
                                    <div
                                      className={'form-group input_group_u  _staff_member'}
                                    >
                                      <span className="_field_label_vk">
                                        Client cancels online
                                      </span>
                                        {console.log("policy",policy)}
                                      <Select
                                        options={this.state.cancellationOptions}
                                        value={policy?.selectedOption?.value?policy?.selectedOption:null}
                                        defaultValue={null}
                                        className={policy?.selectedOption?.value==undefined && policy?.errorStatus?'redBorder':''}
                                        onChange={(e) =>
                                          this.onChangePolicy(
                                            policy,
                                            e,
                                            "cancellationPolicySelect"
                                          )
                                        }
                                        // isDisabled={
                                        //   !this.state.editCancellationPolicy
                                        // }
                                      />
                                    </div>
                                  </div>
                                  <div className="_fee">

                                    <div
                                      className={`form-group input_group_u  _staff_member`}
                                    >
                                      <span className="_field_label_vk">
                                        Fee
                                      </span>
                                     
                                      <input
                                        type="number"
                                        className={policy?.errorStatus?'form-control text-center active-input-field redBorder':'form-control text-center active-input-field'}
                                        value={policy?.amountInPercentage}
                                        onChange={(e) => {
                                          this.onChangePolicy(
                                            policy,
                                            e,
                                            "cancellationPolicy"
                                          );
                                        }}
                                        // placeholder="100"
                                        // disabled={
                                        //   !this.state.editCancellationPolicy
                                        // }
                                      />
                                      <span className="_persantge">%</span>
                                    </div>
                                  </div>
                                 
                                  <div className="_icon" style={{cursor:'pointer'}}>
                                  <span
                                    className="_cross"
                                    onClick={(e) =>
                                      this.deleteCancellationPolicy(policy)
                                    }
                                  >
                                    <Close />
                                  </span>
                                </div>
                                </div>
                              )
                            )
                          : null}
                      {this?.state?.cancellationPolicies?.length<1?<>
                          <div
                            className="_row _add_btn"
                            style={{
                              display: "inline-flex",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              this.addCancellationRow();
                            }}
                          >
                            <span>
                              <Close />
                            </span>{" "}
                            Add Cancellation Threshold
                          </div>
                      </>:null}
                      </div>
                    </div>

                    {this.checkReadOnly()?
                    <div className="new-stng-cmn card-reader-list mb-3 mb-md-4 order-3 pos-relative">

                      <div className="card-discoversDes card-discover-top mb-0">
                        <h4>No Show Protection Fee</h4>
                        <p className="mb-0">
                          There is a fee of 1.25% + 20p for no-show protection,
                          which will be charged for each online booking made
                          with no show enabled. However, this fee will not apply
                          if you process the payment through Beu.
                        </p>
                      </div>
                         {/*  {this.state.noShowFeeChargeByEdit ? ( 
                        <> */}
                          <div
                            className={
                              this.state.noShowFeeChargeBy === 1
                                ? "transaction_option selected"
                                : "transaction_option"
                            }
                          >
                            <p>Self Employed </p>
                            <label className="radio-container ">
                              <input
                                type="radio"
                                name="radi1"
                                onChange={(e) => this.noChangePaidBy(1)}
                                checked={
                                  this.state.noShowFeeChargeBy === 1
                                    ? true
                                    : false
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>

                          <div
                            className={
                              this.state.noShowFeeChargeBy === 2
                                ? "transaction_option selected"
                                : "transaction_option"
                            }
                          >
                            <p>Salon</p>
                            <label className="radio-container">
                              <input
                                type="radio"
                                checkedname="radi2"
                                onChange={(e) => this.noChangePaidBy(2)}
                                checked={
                                  this.state.noShowFeeChargeBy === 2
                                    ? true
                                    : false
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        {/* </>
                     ) : (
                        <>
                          <div
                            className={
                              this.state.noShowFeeChargeBy === 1
                                ? "transaction_option selected"
                                : "transaction_option"
                            }
                            style={{cursor:'default'}}
                          >
                            <p>Self Employed </p>
                            <label className="radio-container ">
                              <input
                                type="radio"
                                name="radi1"
                                onChange={(e) => {}}
                                checked={
                                  this.state.noShowFeeChargeBy === 1
                                    ? true
                                    : false
                                }
                              />
                              <span className="checkmark inactive-input-field"></span>
                            </label>
                          </div>

                          <div
                            className={
                              this.state.noShowFeeChargeBy === 2
                                ? "transaction_option selected"
                                : "transaction_option"
                            }
                            style={{cursor:'default'}}
                          >
                            <p>Salon</p>
                            <label className="radio-container">
                              <input
                                type="radio"
                                checkedname="radi2"
                                onChange={(e) => {}}
                                checked={
                                  this.state.noShowFeeChargeBy === 2
                                    ? true
                                    : false
                                }
                              />
                              <span className="checkmark inactive-input-field"></span>
                            </label>
                          </div>
                        </>
                      )}
                    */}

                    </div>
                    :""}

                      <div className="new-stng-cmn  sln-fmr-dtls-mob _booking_setting saloon-settings-wrp pos-relative">
                          <h5>Online Bookings Open</h5>
                          <div className="">
                              <div className="">
                                  <div className="_card">
                                      <div
                                          className={'form-group input_group_u  _staff_member'}
                                      >
                                      <span className="_field_label_vk">
                                        Clients can book appointments
                                      </span>

                                          <Select
                                             menuPlacement="top"
                                              options={this.state.onlineBookingUptoBeforeOptions}
                                              value={this.state.onlineBookingUptoBefore}
                                              onChange={(e) => this.onChangeSetting(e, "onlineBookingUptoBefore")}
                                          />
                                      </div>
                                  </div>
                              </div>
                              <div className="">
                                  <div className="_card">
                                      <div
                                          className={'form-group input_group_u  _staff_member'}
                                      >
                                      <span className="_field_label_vk">
                                        Clients can book appointments
                                      </span>
                                          <Select
                                              menuPlacement="top"
                                              options={this.state.onlineBookingUptoOptions}
                                              value={this.state.onlineBookingUpto}
                                              onChange={(e) => this.onChangeSetting(e, "onlineBookingUpto")}
                                          />
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>

            {this.state.noShowChangeDetect || this.state.isShowDetect||
              this.state.cancellationChangeDetect?<div className="cm_btns withcheckoutbtn checkout__save_btn_mobile">
              <button className="btn btn-white button-shadow" onClick={()=>{this.resetAllSetting()}}>Cancel</button>
              <button className="btn btn-dark-blue ml-3" onClick={()=>{this.saveChanges()}}>Save</button>
            </div>:null}
          </div>
          </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(BookingUrl);
