import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { GrClose } from "react-icons/gr";
import Select from "react-select";
import { bookingActions } from "../../_actions";

export const ServiceCheckout = (props) => {
  const [item, setItem] = useState({});
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [staffError, setStaffError] = useState("");
  const [includeEndMaxTime,setIncludeEndMaxTime] = useState(new Date());
  const [includeEndMinTime,setIncludeEndMinTime] = useState(new Date());
  const [processingTime, setProcessingTime] = useState(0);

  
  
 // console.log("props",props,props?.bookingSelectedItems[props.index-2]?.processingTime,props.index!==1&&props?.bookingSelectedItems[props.index-2]?.processingTime !==0&&props?.bookingSelectedItems[props.index-2]?.processingTime !==null);
  useEffect(()=>{
    let _newDate = props.includeEndTimes;
    let _selectedEndSlot = props?.selectEndTimeSlot;
    if(_newDate!==undefined && _newDate!=='' && _newDate.length>0){
      setIncludeEndMaxTime(_newDate[_newDate.length-1]);
    }
    if(_selectedEndSlot!==undefined && _selectedEndSlot!==''){
      setIncludeEndMinTime(props?.selectedTime)
    }
  },[props.includeEndTimes,props?.selectEndTimeSlot,props?.selectedTime]);


  const handleClickService = (indexId) => {
    let hairdresserId = props?.selectedStaff?._id;
    if (hairdresserId !== undefined) {
      setStaffError("");

      let currentPageUrl = props.propData.history.location.pathname;
      const stateObj = { pageLoadingStatus: 0, previousPageUrl:currentPageUrl, start:props?.selectedTime};
      const url =  "/new-selectService/" + hairdresserId + "/" + indexId;
       props.propData.history.push({
        pathname: url,
        state: stateObj,
      });
    } else {
      setStaffError("Staff Member is required.");
    }
  };

  useEffect(() => {
    setItem(props?.item);
    if(item?.processingTime!=undefined && item?.processingTime!=null){
    setProcessingTime(item?.processingTime);
    }
    setSelectedStaff({label:props?.selectedStaff?.name,value:props?.selectedStaff?._id});
    //  window.scrollTo(0, 0);
  }, [props]);

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
      }
  }, [])

  const handleClient = (indexId) => {
      if(!props?.clientStatus){
        return false;
      }
      let hairdresserId = props?.selectedStaff?._id;
      if (hairdresserId && hairdresserId !== "") {
        setStaffError("");
        let currentPageUrl = props.propData.history.location.pathname;
        const stateObj = { pageLoadingStatus: 0, previousPageUrl:currentPageUrl,start:props?.selectedTime };
        const url =   `/select-client/${hairdresserId}/${indexId}`
         props.propData.history.push({
          pathname: url,
          state: stateObj,
        });
      } else {
        setStaffError("Staff Member is required.");
    }
  };

  const onChangeStaff = (e) => {
    setStaffError("");
    // eslint-disable-next-line no-unused-expressions
    props.propData.dispatch(bookingActions?.hairdresserNewBookingStaff({_id:e?.value,name:e?.label}));
    props.propData.dispatch(bookingActions?.hairdresserNewBookingData([]));
    props.changeHairdresser(e.value, e.label);
    setSelectedStaff({label:e.label,value:e.value});
    props.resetBooking();
  };

  const onChangeProcessingTime = (e)=>{
    let processingValue = e.target.value;
    props.onChangeProcessingTime(+processingValue,item?.id);
  }

  return (
    <>
      {/* <div className="col-md-1"></div> */}
      <div
        className={
          `col-md-12 col-lg-12 service_div ${props.index!==1&&props?.bookingSelectedItems[props.index-2]?.processingTime !==0&&props?.bookingSelectedItems[props.index-2]?.processingTime !==null ?'mt-0':"mt-4"} bg-white rounded`
        }
      >
        <div className="row vertical-top">
          <div className="col-md-6 text-left mt-1 mb-2">
            <span className="service_1 ml-0">Service {item.indexId}</span>
              {(item?.processingTime&&item?.processingTime!==0&&((props.index)!==props?.bookingSelectedItems.length))?
                  <span
                      className="service_1 processing_time ml-3">Processing Time</span>
                  :""}
          </div>
          <div className="col-md-6 text-right mb-2 grClose fix_mb_cross">
           {props.index > 1?<GrClose
              className="gr_close"
              onClick={() =>
                props.removeService("service", item?.id, item?.indexId)
              }
            />:null} 
          </div>
        </div>
        {props.index === 1 ? (
          <>
          <div className="row">
          <div className="col-sm-12">
          {props.startTimeError ? (
            <span className="cm_error_msg">{props.startTimeError}</span>
          ) : (
            ""
          )}
          {(props.durationError!=='')?<span className={'d-block text-danger text-right mb-2'}>{props.durationError}</span>:null}
          {(props?.serviceSelectionError!==''&&item?.serviceName === undefined)?<span className={'d-block text-danger text-right mb-2'}>{props.serviceSelectionError}</span>:null}</div></div>
          <div className="row service_row">
            <div className="col-md-6">
              <div className="pt-0 bg-white service_select ___service border-1 w-100 p-0">
                <div className="service_checkout_datepicker _datepickr">
                  <DatePicker
                    selected={props.bookingDate}
                    minDate={new Date()}
                    pre
                    className="__datepicker"
                    onChange={(e) => props?.selectDay(e)}
                  />
                  <p className="show__date">{moment(props.bookingDate).format( "dddd, D MMM YYYY")}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 pl-0">
              <div className="fix_time_box" style={{ display: "flex" }}>
                <div className={item?.serviceName !== undefined?"__time time_box active":"__time time_box" }>
                  <h6 className="start_time mb-0 __datepicker_input_height">Start Time</h6>
                  {props?.selectedTime!==undefined?<DatePicker
                  className="start_time1 w-100"
                  selected={props?.selectedTime}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  includeTimes={props.includeTimes}
                  onChange={(e) => props.selectSlotFunc(e)}
                />:<h6 style={{ fontWeight: 400, fontSize: "17px" }}>_ _</h6>}
               
                </div>
               {/*<div className="__time time_box">
                 <h6 className="start_time">End Time</h6>
                   <h6 style={{ fontWeight: 400, fontSize: "17px" }}>{props.calculateEndTime()}</h6>
                   </div> */}
                <div className={item?.serviceName !== undefined?"__time time_box active":"__time time_box" }>
                <h6 className="start_time mb-0 __datepicker_input_height">End Time</h6>
                {item?.serviceName !== undefined?<DatePicker
                className="start_time1 w-100"
                selected={props?.selectEndTimeSlot}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={5}
                timeCaption="Time"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                minTime={moment(props?.selectedTime).add(5,'m').toDate()}
                maxTime={includeEndMaxTime}
                includeTimes={props.includeEndTimes}
                onChange={(e) => props.selectEndTime(e)}
                disabled={props.isEndDisable}
              />:<h6 style={{ fontWeight: 400, fontSize: "17px" }}>_ _</h6>}
             
              </div>
                <div className={item?.serviceName !== undefined?"__time time_box active" : "__time time_box" }>
                  <h6 className="start_time" style={{ marginBottom: '4px'}}>Duration</h6>
                  {item?.serviceName !== undefined?<input type="text" className="duration__input"  disabled={props.isEndDisable} onChange={(e)=>props?.changeServiceDuration(e.target.value)}
                  value={isNaN(props.totalTime)?'':props?.totalTime}/>:<h6 style={{ fontWeight: 400, fontSize: "17px" }}>_ _</h6>}
                 {/*<h6  onChange={(e) => props.updateDuration(e)} style={{ fontWeight: 400, fontSize: "17px" }} className="text-center w-100 mb-0" >{isNaN(props.totalTime)?'_ _':props?.totalTime>0?props?.totalTime + " m":'_ _'} </h6>*/}
                </div>
              </div>
            </div>
          </div>
          </>
        ) : null}
        <div className="row">
        {(item.indexId>1&& item?.serviceName === undefined)?<div className="col-md-12 text-right">
         <span className={'d-block text-danger text-right mb-2'}>{props.serviceSelectionError}</span>
         </div>:null}
          <div className="col-md-6  ">
          <div className={selectedStaff?.label===undefined?"input_group_u  _staff_member border_blue":'input_group_u  _staff_member'}>
              <span className="_field_label_vk">Staff Member</span>
              {props?.index === 1 ? (
                <Select
                  options={props?.staffList}
                  value={selectedStaff?.label===undefined?{label:'Select staff member', value:''}:selectedStaff}
                  // props?.propData?.hairdresserNewBookingStaff
                  onChange={(e) => onChangeStaff(e)}
                />
              ) : (
                <div className="service_selected1 disabled_div __custom_gray ">
                  {selectedStaff?.label !==
                  undefined
                    ? selectedStaff?.label
                    : "Select Staff Member"}
                </div>
              )}
            </div>
            

            {staffError !== "" ? (
              <span className={"text-danger"}>{staffError}</span>
            ) : null}
          </div>
          <div className="col-md-6 pl-0">
            <div className={selectedStaff?.label === undefined?'input_group_u __Client hide__arrow ':'input_group_u __Client'}>
              <span className="_field_label_vk">Client</span>
              {props?.index === 1 && props?.clientStatus ? (
                <div
                  className={`service_selected1 mt-0 w-100 ${selectedStaff?.label===undefined ? 'disabled_div':item?.serviceName !== undefined ? '':'border_blue' } `}
                  onClick={() =>
                    handleClient(item?.indexId)
                  }
                >
                  {props?.selectedClient?.name != undefined
                    ? props?.selectedClient?.name
                    : "Choose Client"}
                </div>
              ) : (
                <div className="service_selected1   mt-0 disabled_div __custom_gray">
                  {props?.selectedClient?.name !== undefined
                    ? props?.selectedClient?.name
                    : "Choose Client"}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-3 pr-0">
            <div className={"input_group_u"}>
              <span className="_field_label_vk">Price</span>
              <div className="currency_div mt-0 ml-0">
                <div className="text-center px-3 currency_sml">£</div>
                <div className="text-center pl-3 currency_sml1">
                  {item?.servicePrice !== undefined ? Number.isInteger(item?.servicePrice)?item?.servicePrice+'.00':item?.servicePrice:`0.00`}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9 ">
           {props.finalBookingSlots?.length===0?<div className={`input_group_u __service  ${selectedStaff?.label === undefined?'hide__arrow':''}`}>
              <span className="_field_label_vk">Service</span>
              <div
              className={`mt-0 mb-4 bg-white service_selected1 border-1 w-100 ${selectedStaff?.label===undefined ? 'disabled_div':props.finalBookingSlots?.length===0? '':'border_blue' } `}
                // className="mb-4 bg-white service_selected1 border-1"
              >
                {item?.serviceName !== undefined
                  ? item?.serviceName
                  : "Select service..."}
              </div>
            </div>:
            <div className={`input_group_u __service  ${selectedStaff?.label === undefined?'hide__arrow':''}`}>
            <span className="_field_label_vk">Service</span>
            <div
            className={`mt-0 mb-4 bg-white service_selected1 border-1 w-100 ${selectedStaff?.label===undefined ? 'disabled_div':props.finalBookingSlots?.length===0? '':'border_blue' } `}
              // className="mb-4 bg-white service_selected1 border-1"
              onClick={(e) => handleClickService(item?.indexId)}
            >
              {item?.serviceName !== undefined
                ? item?.serviceName
                : "Select service..."}
            </div>
          </div>}
            {props?.hairdresserError!=="" ? (
                <span className={"text-danger"}>{props?.hairdresserError}</span>
            ) : null}
          </div>
        </div>
      </div>
      <div className='_processing_time'>
        {(item?.processingTime!=undefined&&item?.processingTime>=0 &&((props.index)!==props?.bookingSelectedItems.length))?
            <div className='processing_time_msz'> <div className="processingInputBorder"><input type="text" onChange={(e)=>onChangeProcessingTime(e)} className="proceesing_input" value={`${processingTime}`}/> mins</div> processing time</div>
            :""}
      </div>
    </>
  );
};
