import React, { Component } from 'react';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import Footer from '../template/Footer';
import Review from "./Reviews";
import Booking from "./Booking";
import Legal from './Legal';
import Myaccount from './Myaccount'
import {connect} from "react-redux";


class Dashboard extends Component{

    constructor(props) {
        super(props);
        this.state = {
            error: {},
            message: '',
            loader: true
        };
        this.bookingClick=this.bookingClick.bind(this);
        this.reviewClick=this.reviewClick.bind(this);
        this.myaccountClick=this.myaccountClick.bind(this);
        this.legalClick=this.legalClick.bind(this);
    }


    bookingClick(){
        this.props.history.push("/booking");
    }

    reviewClick(){
        this.props.history.push("/review");
    }

    myaccountClick(){
        this.props.history.push("/myaccount");
    }

    legalClick(){
        this.props.history.push("/legal");
    }

    render(){
        //console.log(this.props,"HGFRDFTYGHUJK");
        
        let {location}=this.props;
        return(
            <React.Fragment>
            <div className="container-fluid footer-section">
                <div className=" dashboard booking-dash">

                <Tabs className="row">

                    <div className="col-md-4 col-lg-3 col-xl-2 offset-xl-1 mT80 hide_smallscreen">
                        <TabList>
                            <ul className="sidenavbar">
                                <Tab><li className={this.props.match.path=='/booking'?"active":''} onClick={this.bookingClick.bind(this)}>Bookings</li></Tab>
                                {/*<Tab><li className={this.props.match.path=='/review'?"active":''} onClick={this.reviewClick.bind(this)}>Reviews</li></Tab>*/}
                                <Tab><li className={this.props.match.path=='/myaccount'?"active":''} onClick={this.myaccountClick.bind(this)}>My Account</li></Tab>
                                <Tab><li className={this.props.match.path=='/legal'?"active":''} onClick={this.legalClick.bind(this)}>Legal</li></Tab>
                            </ul>
                        </TabList>
                    </div>
                    <br/>
                    <div className="col-md-8 col-lg-8 col-xl-6">
                    {/* <h2 className="cm_bookin_deatils"><span  className="active">Upcoming</span> <span>Past</span></h2>
                    <h2 className="cm_bookin_deatils"><span  className="active"> Myaccount  </span></h2> */}

                        <Switch>
                            <Route path="/booking" component={Booking} />
                            {/*<Route path="/review"  component={Review} />*/}
                            <Route path="/myaccount"  component={Myaccount} />
                            <Route path="/legal"  component={Legal} />
                        </Switch>
                    </div>
                </Tabs>
                </div>

            </div>
            <Footer/>
            </React.Fragment>


        )
    }
}

function mapStateToProps(state) {
    const { hairdresser,salon,hairdresserReview,user,
        setting } = state;
    return {
        hairdresser,
        salon,
        hairdresserReview,
        user,
        setting
    };
}

export default connect(mapStateToProps)(Dashboard);
