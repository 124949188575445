import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab, Modal } from 'react-bootstrap';

import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import { Link, Redirect } from 'react-router-dom';
import siteSetting from "../../config/env";
import {userActions} from "../../_actions";
import SettingSidebar from './SettingSidebar';
import SettingHeadingTitle from './SettingHeadingTitle';


class AcountPassword extends Component {

    constructor(props){
        super(props);

        this.state = {
            isProfileSettingTab: false
        }

    }


    onTabChange = e => {
        if(e == 'profileSettings'){
            this.setState({isProfileSettingTab: true})
        }
    }

    componentDidMount() {
        let employmentType={};
        var self = this;
        if (localStorage.getItem('accessToken')) {
            let params={
                defaultRedirectTo:localStorage.getItem('defaultRedirectTo')
            }
            params.latitude = siteSetting.default_latitude;
            params.longitude = siteSetting.default_longitude;
            self.props.dispatch(userActions.profile(params))
                .then(function (response) {
                    console.log("response",response.data.data);
                    if(response.data.data) {
                        if(response.data.data.userInfo.hairdresser.isSalonOwner===1){
                            employmentType={ value: '1', label: 'Owner'}
                        }else if(response.data.data.userInfo.hairdresser.isSalonOwner===2){
                            employmentType={ value: '2', label: 'Account Manager'}
                        }else if(response.data.data.userInfo.hairdresser.isSalonOwner===0) {

                            if (response.data.data.userInfo.hairdresser.employmentTypeId === 1) {
                                employmentType = {value: '3', label: 'Employee'}
                            } else {
                                employmentType = {value: '4', label: 'Self Employee'}
                            }
                        }
                        self.setState(
                            {
                                employmentType:employmentType,
                                first_name: response.data.data.userInfo.first_name&&response.data.data.userInfo.first_name!==""?response.data.data.userInfo.first_name:response.data.data.userInfo.name,
                                last_name:response.data.data.userInfo.last_name,
                                email: response.data.data.userInfo.email,
                                phoneNumber: response.data.data.userInfo.phoneNumber.toString(),
                                jobTitle: response.data.data.userInfo.hairdresser.jobTitle,
                                shortBio: response.data.data.userInfo.hairdresser.shortBio,
                                circleImagePrev:response.data.data.userInfo.userImage.circleImage?response.data.data.userInfo.userImage.circleImage:require('../../assets/images/user-placeholder.png')
                            }
                        )

                    }
                })
        }else{
            this.props.history.push('/')
        }
    }


    checkReadOnly(){
        if(this.props.user&&this.props.user.userInfo&&this.props.user.userInfo.hairdresser.isSalonOwner===1){
            return true;
        }else{
            return false;
        }
    }
    componentWillUnmount(){
        document.body.classList.remove('setting-temps-pages');
    }

    render() {
        // console.log("this.props",this.props);
        let { isProfileSettingTab } = this.state;
        return (
            <div className="h_n">
            <div className="body_container cm_bg_white setting_page">

                <TopHeader title="Password" {...this.props}/>
                <SideNav {...this.props}/>

                <div className='new-setting-wrapper'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <SettingHeadingTitle title="Salon Details" />
                                <SettingSidebar />
                            </div>
                            <div className='col-md-8'>
                                <div className='salon-body-main-wrp account--password-temp'>
                                    <div className='new-stng-cmn saloon-settings-wrp'>
                                        <div className='text-center img-accnt-password mb-4 mb-md-5'>
                                            <img src={require('../../assets/images/password-bg.png')} />
                                        </div>
                                        <div className='desct-accnt-desc text-center mb-4 mb-md-5'>
                                            <h2 className="heading-40 mb-md-4 mb-4">Edit Password</h2>
                                            <h3 className="">If you forget your password you can reset or <br/> manage your password here.</h3>
                                        </div>

                                        <div className="form-group anctPassword">
                                            <label>Account Password</label>
                                            <Link to="edit-password">
                                                <div className="form-control d-flex align-items-center px-md-5"><span>***********************</span></div>
                                            </Link>
                                        </div>
                                        <div className='form-group text-right'>
                                            <Link to="edit-password" className='btn btn-inf'>Edit Password</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        )
    }

}



function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(AcountPassword);





