import React from 'react';
import {Tab, Tabs, Dropdown, Accordion, Card, Button} from 'react-bootstrap';
import Lottie from 'react-lottie';
import animationData from '../../assets/animation-data/tick.json';
const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
const Invoice = (props) => {

    const goHome=()=>{
        let self=this
        let info={
            bookingDate:props.bookingState.bookingDate
        };
        props.history.push({pathname:'/diary/' + localStorage.getItem('salonId'),state:info})
    }

    return(
        <>
            <div className="cm_right_panal client_checkout phone-payemnet-taken-change iinvoice">
                <div className="text-center">
                <Lottie options={defaultOptions}
                    height={100}
                    width={100}
                    />
               <h4 className="">Checkout Completed</h4>
                    <p>Full payment received.</p>
                </div>
                <div className="text-center complete__btn">
                    <div className="cm_btns mobile-change-btn">
                    <button className="btn btn-blue btn-block" onClick={goHome}>Complete</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Invoice;
