
import React, { Component } from 'react';
import { connect } from "react-redux";
var _ = require('lodash');
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

  class Staff extends Component {
    constructor(props) {
      super(props);
      this.state={
        activeStaff:false,
        hairdresser:{},
        loading:false
       }

    this.selectedStaff = this.selectedStaff.bind(this);
    }

componentDidMount(){
    let self = this;
    self.setState({
        hairdresser:this.props.hairdresserData
    })
}

selectedStaff(salonId, hairdresserId,hairdresser,selectedHairDresser){
    let self = this;
    self.setState({
        activeStaff: !self.state.activeStaff
       })
       self.props.activeAnimation(self.props.changeHairdresser.bind(salonId, hairdresserId,hairdresser,selectedHairDresser));
}


  render(){
    
    return (
        <div className={`_staff ${this.state.activeStaff? 'active':''}`} key={this.state.hairdresser._id}  onClick={()=>this.selectedStaff(this, this.props?.salonId, this.state.hairdresser._id,this.state.hairdresser , this.props.selectedHairDresser)}>
        
          <img src={this.state.hairdresser?.userImage?.circleImage ? this.state.hairdresser?.userImage?.circleImage : require('../../../assets/images/Pixie.png')} className="circle-img" alt="staff" />
         
        <h6>{this.state.hairdresser.name}</h6>
         {/* <p className='hide-desktop'>Available</p>
         <p className='hide-desktop'>Thu 02 October</p>
         <p className='hide-mobile'>Available Thu 02 October</p> */}
       </div>
     
  );
};
}


function mapStateToProps(state) {
  const {
      hairdresser, salon, hairdresserReview, user,
      setting,
      booking,
      createBooking,
      salonList
  } = state;
  return {
      hairdresser,
      salon,
      hairdresserReview,
      user,
      setting,
      booking,
      salonList,
      createBooking
  };
}

export default connect(mapStateToProps)(Staff);
