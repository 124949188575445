import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import {connect} from "react-redux";
import Autosuggest from 'react-autosuggest';
import DatePicker from 'react-datepicker';
import PhoneInput,{isValidPhoneNumber} from 'react-phone-number-input';
import {salonService} from '../../_services'
import {hairdresserActions, paymentActions, marketingAction} from "../../_actions";
import moment from 'moment';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import {loadStripe} from "@stripe/stripe-js";
import siteSetting from "../../config/env";
import queryString from "query-string";

const options = [
    { value: '1', label: 'Owner'},
    // { value: '2', label: 'Account Manager'},
    // { value: '3', label: 'Employed' },
    { value: '4', label: 'Self-Employed' },
];


const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
    <>
        <div className="salonName">
            {suggestion.name}
        </div>
        <span className="salonAddress">{suggestion.salonData.formattedAddress}</span>
    </>
);


var parseGooglePlace = require('parse-google-place');

const searchOptions = {
    //componentRestrictions: { country: ['GB'] },
    //types: ['city']
}
const stripePromise = loadStripe(siteSetting.stripeKey);

class Signup extends Component {

    constructor(props){
        super(props);

        this.state = {
            name:'',
            first_name:(localStorage.getItem("firstName")&&localStorage.getItem("firstName")!=="")?localStorage.getItem("firstName"):'',
            last_name:(localStorage.getItem("lastName")&&localStorage.getItem("lastName")!=="")?localStorage.getItem("lastName"):'',
            email:(localStorage.getItem("email")&&localStorage.getItem("email")!=="")?localStorage.getItem("email"):'',
            phoneNumber:'',
            password:'',
            jobTitle:'',
            shortBio:'',
            circleImagePrev:require('../../assets/images/user-placeholder.png'),
            circleImage:require('../../assets/images/user-placeholder.png'),
            countryId:'217',
            salonId:'',
            employmentTypeId:(localStorage.getItem("userType")&&(localStorage.getItem("userType")==1||localStorage.getItem("userType")==2)?(localStorage.getItem("userType")==1?1:4):0),
            employmentType:(localStorage.getItem("userType")&&(localStorage.getItem("userType")==1||localStorage.getItem("userType")==2)?(localStorage.getItem("userType")==1?{ value: '1', label: 'Owner'}:{ value: '4', label: 'Self-Employed' }):{}),
            salonName:'',
            formattedAddress:'',
            disableAddress:true,
            dob:'',
            value: '',
            suggestions: [],
            salonList : [
            ],
            selectedSalon:{},
            isSalonOwner:1,
            deviceToken:'dsfsdf',
            deviceTypeId:'2',
            latitude:'',
            longitude:'',
            countryShortName:'',
            countryLongName:'',
            postalCode:'',
            administrativeAreaLevel1:'',
            administrativeAreaLevel2:'',
            agree:false,
            ownerId:'',

            formattedAddressError:'',
            salonNameError:'',

            createSalaonName:'',
            isCreateSalon:0,
            isSignup:false,
            subscriptionId:'',
            transactionId:'',
            stripeCustomerId:''
            //isSalonCreated:false

        }
        this.onDataChange=this.onDataChange.bind(this);
        this.onChanged=this.onChanged.bind(this);
        this.validateEmail=this.validateEmail.bind(this);
        this.onSuggestionSelected=this.onSuggestionSelected.bind(this);
        this.signup=this.signup.bind(this);
        this.salonCreate=this.salonCreate.bind(this);
        this.signupNext=this.signupNext.bind(this);
    }
    async componentDidMount(){
        let self=this;
        document.body.classList.remove('login-home-temp');
        const stripe = await stripePromise;
        console.log("this.props.location",this.props.history.location.search);
        let params = queryString.parse(this.props.history.location.search);
        console.log("params",params)
        if(params.session_id) {
            let subscriptionData={
                sessionId:params.session_id
            }
            self.props.dispatch(paymentActions.subscriptionRetrieve(subscriptionData))
                .then(function (response) {
                    console.log("response", response);
                    if (response.data.statusCode == 0) {
                        if (response.data.error.errorCode === 10) {
                            self.setState({
                                error: response.data.error.responseMessage
                            })
                        } else {
                            self.setState({
                                error: response.data.error.responseMessage
                            })
                        }
                        self.setState({
                            isFormLoader: false
                        });
                    } else {
                        let name=response.data?.responseData?.result?.customer_details?.name;
                        let userType=response.data?.responseData?.result?.metadata?.userType;
                        if(userType) {
                            let employmentTypeId = userType===1||userType==='1' ? 1 : 4;
                            let employmentType = userType===1||userType==='1'? {value: '1',label: 'Owner'} : {value: '4', label: 'Self-Employed'};
                            self.setState({
                                employmentTypeId,
                                employmentType
                            })
                        }
                        if(name){
                            let nameArr=name.split(' ');
                            //console.log("nameArr",nameArr);
                            let last_name;
                            if(nameArr.length>1){
                                last_name=nameArr[nameArr.length-1];
                            }
                            let first_name=nameArr[0];
                            self.setState({
                                first_name: first_name,
                                last_name:last_name,
                                email: response.data?.responseData?.result?.customer_details?.email,
                                subscriptionId:response.data?.responseData?.result?.subscription,
                                transactionId:response.data?.responseData?.result?.metadata.transactionId,
                                stripeCustomerId:response.data?.responseData?.result?.customer,
                            })
                        }else{
                            self.setState({
                                first_name: name,
                                email: response.data?.responseData?.result?.customer_details?.email,
                                subscriptionId:response.data?.responseData?.result?.subscription,
                                transactionId:response.data?.responseData?.result?.metadata.transactionId,
                                stripeCustomerId:response.data?.responseData?.result?.customer,
                            })
                        }
                    }
                })
        }
    }

    onChanged(e) {
        this.setState({ [e.target.name]: e.target.value, copied: false });
        this.setState({
            emailError: '',
            passwordError:'',
            salonNameError:'',
            employmentTypeError:'',
            agreeError:'',
            formattedAddressError:''
        })
        if (e.target.name === 'email') {
            if (!this.validateEmail(e.target.value)) {
                this.setState({
                    emailError: 'Please enter a valid email address'
                })
            } else {
                this.setState({
                    emailError: ''
                })
            }
        }

        if (e.target.name === 'first_name') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    fNameError: ''
                })
            } else {
                this.setState({
                    fNameError: '',
                })
            }
        }

        if (e.target.name === 'last_name') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    fNameError: ''
                })
            } else {
                this.setState({
                    lNameError: '',
                })
            }
        }

        if (e.target.name === 'phoneNumber') {
            if (e.target.value.length!==10) {
                this.setState({
                    phoneNumberError: 'Please enter a valid phone number'
                })
            } else {
                this.setState({
                    phoneNumberError: '',
                })
            }
        }

        if (e.target.name === 'jobTitle') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    jobError: ''
                })
            } else {
                this.setState({
                    jobError: '',
                })
            }
        }

        if (e.target.name === 'shortBio') {
            if (e.target.value === '' || e.target.value === null||e.target.value.length<10) {
                this.setState({
                    shortBioError: 'The shortBio must be at least 10 characters.'
                })
            } else {
                this.setState({
                    shortBioError: '',
                })
            }
        }

        if (e.target.name === 'circleImage') {
            // console.log("e",e);
            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {
                this.setState({
                    circleImagePrev: reader.result
                });
            }
            reader.readAsDataURL(file)


            this.setState({
                circleImagePrev: file
            })
            this.setState({
                circleImage: e.target.files[0]
            });
        }
    }

    onDataChange(value, action){
        this.setState({
            [action.name]: value,
            employmentTypeId:value.value
        })
    }

    validateEmail(email) {
        const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const result = pattern.test(email);
        return result;
    }

    onChange = (event, { newValue }) => {
        if(newValue.substr(newValue.length - 15)==="(Add New Salon)"){
            newValue=newValue.slice(0, -16)
        }
        //this.state.createSalaonName!=''?this.state.createSalaonName:
        //console.log("event",event.target.value,newValue)
        this.setState({
            value: newValue,
            salonName:newValue,
            latitude:'',
            longitude:'',
            countryShortName:'',
            countryLongName:'',
            postalCode:'',
            administrativeAreaLevel1:'',
            administrativeAreaLevel2:'',
            formattedAddress:''
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        let params = {
            keyword: value,
        }
        if(value.length>2) {
            salonService.searchSalon(params)
                // .then(response => response.json())
                .then(response => {
                    // console.log("response",response);
                    let salonList = []
                    salonList.push(
                        {
                            name: value + ' (Add New Salon)',
                            salonName: value,
                            isCreateSalon: 1,
                            salonData: {}
                        }
                    )
                    if (response.data.data.salons.length > 0) {

                        response.data.data.salons.map((salon, i) => (
                            salonList.push(
                                {
                                    name: salon.salonName,
                                    salonName: salon.salonName,
                                    isCreateSalon: 0,
                                    salonData: salon
                                }
                            )
                        ))

                    }
                    this.setState({suggestions: salonList})
                })
        }
    }

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    onSuggestionSelected(e,{ suggestion}){
        //console.log("selected Salon",suggestion);
        if(suggestion.isCreateSalon===1){
            this.setState({
                disableAddress:false,
                isCreateSalon:1,
                selectedSalon: {},
                salonId: "",
                createSalaonName: suggestion.salonName,
                ownerId: suggestion.salonData.salonOwner ? suggestion.salonData.salonOwner : '',
            })
        }else {
            this.setState({
                formattedAddress: suggestion.salonData.formattedAddress,
                isCreateSalon:0,
                selectedSalon: suggestion.salonData,
                salonId: suggestion.salonData._id,
                createSalaonName: suggestion.salonName,
                ownerId: suggestion.salonData.salonOwner ? suggestion.salonData.salonOwner : '',
            })
        }
    }

    signup(){
        // console.log("isValidPhoneNumber(this.state.phoneNumber)",isValidPhoneNumber(this.state.phoneNumber))
        this.setState({
            error:'',
            fNameError:'',
            lNameError:'',
            emailError: '',
            passwordError:'',
            salonNameError:'',
            employmentTypeError:'',
            agreeError:'',
            formattedAddressError:'',
            phoneNumberError:'',
        })
        let self = this;
        let params = {};
        params.name=this.state.first_name+" "+this.state.last_name;
        params.first_name=this.state.first_name;
        params.last_name=this.state.last_name;
        params.email=this.state.email;
        params.phoneNumber=this.state.phoneNumber;
        params.jobTitle=this.state.jobTitle;
        params.shortBio=this.state.shortBio;
        params.employmentTypeId=this.state.employmentTypeId;
        params.salonId=this.state.salonId;
        params.countryId=this.state.countryId;

        if (this.state.first_name === "" || this.state.first_name === undefined) {
            this.setState({
                fNameError: 'Please enter first name'
            })
        }else if (this.state.first_name.length<3) {
            this.setState({
                fNameError: 'Name must have more than 3 characters'
            })
        } else if (this.state.last_name === "" || this.state.last_name === undefined) {
            this.setState({
                lNameError: 'Please enter last name'
            })
        } else if (this.state.email === "" || this.state.email === undefined) {
            this.setState({
                emailError: "Please enter your email address"
            })
        } else if (!this.validateEmail(this.state.email)) {
            this.setState({
                emailError: 'Please enter a valid email address'
            })
        } else if (this.state.password === "" || this.state.password === undefined) {
            this.setState({
                passwordError: 'Please enter any password'
            })
        }else if (this.state.password.length<6) {
            this.setState({
                passwordError: 'Password must have more than 6 characters'
            })
        }else if (this.state.phoneNumber === "" || this.state.phoneNumber === undefined ) {
            //isValidPhoneNumber(this.state.phoneNumber)
            this.setState({
                phoneNumberError: 'Please enter a valid phone number'
            })
        } else if (this.state.salonName === "" || this.state.salonName === undefined) {
            this.setState({
                salonNameError: 'Please select or create any salon'
            })
        } else if (this.state.jobTitle === "" || this.state.jobTitle === undefined) {
            this.setState({
                jobError: 'Please enter a job title'
            })
        }else if (this.state.employmentTypeId === 0 || this.state.employmentTypeId === undefined) {
            this.setState({
                employmentTypeError: 'Please select Employment Type'
            })
        }
        else if (this.state.agree === false||this.state.agree === "false") {
            this.setState({
                agreeError: 'Partner must agree before proceeding'
            })
        }
        else {
            //console.log("debusdfdsfsdfdsfsdf");
            if(self.state.salonId&&self.state.salonId!==""){
                self.signupNext()
            }else{
                self.salonCreate()
            }
            // if(self.state.isCreateSalon===1||!self.state.salonId||(self.state.salonId&&self.state.salonId==="")) {
            //     console.log("11111111111111111111");
            //
            // }else {
            //     console.log("22222222222222222222");
            //
            // }

        }
    }

    salonCreate(){
        this.setState({
            error:''
        })
        let self = this;

            if ((this.state.createSalaonName === "" || this.state.createSalaonName === undefined)&&this.state.salonName==="") {
                this.setState({
                    salonNameError: 'Please enter salon name'
                })
            } else if (this.state.formattedAddress === "" || this.state.formattedAddress === undefined) {
                this.setState({
                    formattedAddressError: 'Please enter salon address'
                })
            } else {
                let data = new FormData();
                let salonName=this.state.createSalaonName!==""?this.state.createSalaonName:this.state.salonName
                data.append('salonName', salonName);
                data.append('formattedAddress', this.state.formattedAddress);
                data.append('latitude', this.state.latitude);
                data.append('longitude', this.state.longitude);
                data.append('countryShortName', this.state.countryShortName);
                data.append('countryLongName', this.state.countryLongName);
                data.append('postalCode', this.state.postalCode);
                data.append('administrativeAreaLevel1', this.state.administrativeAreaLevel1);
                data.append('administrativeAreaLevel2', this.state.administrativeAreaLevel2);

                    salonService.createSalon(data)
                    .then(function (response) {
                        console.log('client response', response);

                        if (response.data.errorCode) {
                            if (response.data.errorCode == 51) {
                                self.setState({
                                    salonNameError: response.data.messages
                                })
                            }
                            else {
                                self.setState({
                                    error: response.data.messages
                                })
                            }

                        } else {
                            if(response.data.data.salon){
                                self.setState({
                                    salonId:response.data.data.salon.id,
                                    //isSalonCreated:true
                                },function () {
                                    self.signupNext()
                                })
                            }
                        }
                    })
                    .catch((err) => {
                        console.log("errr", err);
                        self.setState({
                            formattedAddressError: 'Please enter a valid address'
                        })
                        //dispatch(failure(err));
                    })
            }

    }

    signupNext(){
        let self = this;
        let data = new FormData();
        let isSalonOwner=0;
        data.append('name', this.state.first_name+" "+this.state.last_name);
        data.append('first_name', this.state.first_name);
        data.append('last_name', this.state.last_name);
        data.append('email', this.state.email);
        data.append('phoneNumber', this.state.phoneNumber);
        data.append('jobTitle', this.state.jobTitle);
        data.append('shortBio', this.state.shortBio);

        data.append('salonId', this.state.salonId);
        data.append('countryId', this.state.countryId);
        // data.append('circleImage', this.state.circleImage);
        // data.append('rectangleImage', this.state.circleImage);
        data.append('password', this.state.password);
        data.append('deviceToken', this.state.deviceToken);
        data.append('deviceTypeId', this.state.deviceTypeId);
        data.append('stripeSubscriptionId', this.state.subscriptionId);
        data.append('transactionId',  this.state.transactionId);
        data.append('stripeCustomerId',this.state.stripeCustomerId);
        if(this.state.employmentTypeId!==0){
            let employmentTypeId=1;
            if(this.state.employmentTypeId==4||this.state.employmentTypeId=="4"){
                employmentTypeId=2
            }
            if(this.state.employmentTypeId==3||this.state.employmentTypeId=="3"){
                if(this.state.ownerId!=''){
                    data.append('ownerId', this.state.ownerId);
                }
            }
                isSalonOwner=0;
            if(this.state.employmentTypeId===1||this.state.employmentTypeId==="1"){
                isSalonOwner=1;
            }else if(this.state.employmentTypeId===2||this.state.employmentTypeId==="2"){
                isSalonOwner=2;
            }
            data.append('employmentTypeId', employmentTypeId);
            data.append('isSalonOwner', isSalonOwner);
        }


        if(this.state.dob!=''){
            data.append('dob', moment(this.state.dob).format('YYYYMMDD'));
        }

        self.setState({
            isSignup:true
        })
        self.props.dispatch(hairdresserActions.signup(data))
            .then(function (response) {
                self.setState({
                    isSignup:false
                })
                // console.log('client response', response);

                if (response.data.errorCode) {
                    if(response.data.errorCode===51||response.data.errorCode==="51"){
                        self.setState({
                            emailError: response.data.messages
                        })
                    }else if(response.data.errorCode===57||response.data.errorCode==="57"){
                        self.setState({
                            phoneNumberError: response.data.messages
                        })
                    }else if (response.data.errorCode == 1) {
                        self.setState({
                            passwordError: response.data.messages
                        })
                    }else{
                        self.setState({
                            error: response.data.messages
                        })
                    }

                } else {
                    if(isSalonOwner===1){
                        const userId = response?.data?.data?.userInfo?.userId;
                        console.log('checkHairdresserId',userId);
                        // const marketingSettingParams = {
                        //     salonId:self.state.salonId,
                        //     hairdresserId:userId,
                        //     reminders:[{
                        //          reminderTime:2,
                        //          statusSMSreminder:1,
                        //          statusEmailReminder:0,
                        //         }
                        //     ],
                        //     bookingUpdate:[
                        //         {
                        //             statusSMSreminder:1,
                        //             statusEmailReminder:1,
                        //         },
                        //         {
                        //             statusSMSreminder:0,
                        //             statusEmailReminder:0,
                        //         },
                        //         {
                        //             statusSMSreminder:0,
                        //             statusEmailReminder:0,
                        //         },
                        //         {
                        //             statusSMSreminder:1,
                        //             statusEmailReminder:1,
                        //         },
                        //         {
                        //             statusSMSreminder:0,
                        //             statusEmailReminder:0,
                        //         },
                        //         {
                        //             statusSMSreminder:0,
                        //             statusEmailReminder:0,
                        //         }
                        //     ],
                        //     salesUpdate:[
                        //         {
                        //             statusSMSreminder:1,
                        //             statusEmailReminder:1,
                        //         },
                        //         {
                        //             statusSMSreminder:0,
                        //             statusEmailReminder:0,
                        //         },
                        //         {
                        //             statusSMSreminder:1,
                        //             statusEmailReminder:1,
                        //         },
                        //         {
                        //             statusSMSreminder:0,
                        //             statusEmailReminder:0,
                        //         },
                        //         {
                        //             statusSMSreminder:0,
                        //             statusEmailReminder:0,
                        //         },
                        //     ],
                        //     customUpdate:[
                        //          {
                        //          reminderTime:2,
                        //          statusSMSreminder:1,
                        //          statusEmailReminder:1,
                        //          }
                        //     ]
                        // }

                        // self.props.dispatch(marketingAction.createMarketingSetting(marketingSettingParams)).then(res=>{
                        // })
                        if(response.data.data.userInfo&&(response.data.data.userInfo.hairdresser.isSalonOwner===1||response.data.data.userInfo.hairdresser.employmentTypeId===2)) {
                            window.location.href="/steps";
                            //window.location.href="/diary/"+localStorage.getItem('salonId');
                            // self.props.history.push('/steps');
                        }else{
                            self.props.history.push('/coming-soon')
                        }

                    }
                    //console.log('client response', response);
                    //if(response.data.data.userInfo){
                   
                }
            })
            .catch((err) => {
                self.setState({
                    isSignup:false
                })
                console.log("errr",err);
                //dispatch(failure(err));

            })
    }

    close=()=>{
        this.props.history.push('/get-software');
    }

    selectDate = date => {
        this.setState({
            dob: date
        });
    };

    handleChange = address => {
        this.setState({
            formattedAddress:address,
            error:'',
            fNameError:'',
            lNameError:'',
            emailError: '',
            passwordError:'',
            salonNameError:'',
            employmentTypeError:'',
            agreeError:'',
            formattedAddressError:'',
            phoneNumberError:'',
        });
    };

    handleSelect = address => {
        geocodeByAddress(address)
            .then(results => {
                parseGooglePlace(results)
                //console.log("results===>>",results[0]);
                let countryShortName='';
                let countryLongName='';
                let postalCode='';
                let administrativeAreaLevel1='';
                let administrativeAreaLevel2='';

                const address_components = results[0].address_components;
                for (let i = 0; i < address_components.length; i++) {
                    const component = address_components[i];
                    if (component['types'][0]) {
                        if (component['types'][0] === 'administrative_area_level_2') {

                                administrativeAreaLevel2 = component['long_name'];

                        }
                        if (component['types'][0] === 'administrative_area_level_1') {

                                administrativeAreaLevel1 = component['short_name'];

                        }
                        if (component['types'][0] === 'postal_code') {

                                postalCode = component['long_name'];

                        }
                        if (component['types'][0] === 'country') {

                                countryShortName = component['short_name'];
                                countryLongName= component['long_name'];

                        }
                    }
                }
                this.setState({
                    formattedAddress:results[0].formatted_address,
                    countryShortName:countryShortName,
                    countryLongName:countryLongName,
                    postalCode:postalCode,
                    administrativeAreaLevel1:administrativeAreaLevel1,
                    administrativeAreaLevel2:administrativeAreaLevel2,
                });

                return getLatLng(results[0])
            })
            .then(latLng => {
                console.log('Success', latLng);
                this.setState({
                    latitude: latLng.lat,
                    longitude: latLng.lng,
                })
            })
            .catch(error => console.error('Error', error));
    };



    handlePhoneChange = val => {
        this.setState({phoneNumber: val})
        // if(!isValidPhoneNumber(val)) {
        //     this.setState({
        //         phoneNumberError: 'Please enter a valid phone number'
        //     })
        // }
    }

    toggleCheckBox = (field,errorMsg) => {
        this.setState({
            [field]: !this.state[field],
            [errorMsg]:""
        });
    }

    render(){
        console.log("this.state",this.state);
        // console.log("this.props",this.props);

        const {  suggestions } = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'Search Salon',
            value:this.state.value,
            onChange: this.onChange
        };

        return(
            <div className="h_n auth_wrapper" style={{backgroundImage: `url(${require('../../assets/images/auth-wrapper.jpg')})`}}>
                <div className="modal_header">
                    <h3 className="m-0 text-center">Join</h3>
                    <i className="fa fa-times cm_close" onClick={this.close}/>
                </div>


                <div className="hairdress_modal_body">

                    <div className="row">

                        <div className="col-md-6 form-group">
                            <label>First Name</label>
                            <input type="text" className="form-control" name="first_name" value={this.state.first_name} onChange={this.onChanged} maxLength={50}/>
                            {this.state.fNameError ?
                                <span className="cm_error_msg">{this.state.fNameError}</span> : ''}
                        </div>

                        <div className="col-md-6 form-group">
                            <label>Surname</label>
                            <input type="text" className="form-control" name="last_name" value={this.state.last_name} onChange={this.onChanged} maxLength={50}/>
                            {this.state.lNameError ?
                                <span className="cm_error_msg">{this.state.lNameError}</span> : ''}
                        </div>

                    </div>


                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label>Email</label>
                            <input type="email" className="form-control" name="email" value={this.state.email}
                                   maxLength={80}
                                   //onChange={this.onChanged}
                                    disabled={localStorage.getItem("userType")&&(localStorage.getItem("userType")==1||localStorage.getItem("userType")==2)?true:false}
                                   isDisabled={localStorage.getItem("userType")&&(localStorage.getItem("userType")==1||localStorage.getItem("userType")==2)?true:false}
                            />
                            {this.state.emailError ?
                                <span className="cm_error_msg">{this.state.emailError}</span> : ''}
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Password</label>
                            <input type="password" className="form-control" name="password" value={this.state.password} onChange={this.onChanged}/>
                            {this.state.passwordError ?
                                <span className="cm_error_msg">{this.state.passwordError}</span> : ''}
                        </div>

                    </div>

                    <div className="row">
                        {/*<div className="col-md-6 form-group full_date_picker">*/}
                        {/*    <label>Date of Birth</label>*/}

                        {/*    /!*<input type="text" className="form-control" name="dob" value={this.state.dob} onChange={this.onChanged}/>*!/*/}
                        {/*    <DatePicker*/}
                        {/*        selected={this.state.dob}*/}
                        {/*        onChange={this.selectDate}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className="col-md-6 form-group cm_PhoneInput">
                            <label>Mobile Number</label>

                            <PhoneInput
                                international={true}
                                defaultCountry="GB"
                                country={"GB"}
                                className="form-control"
                                placeholder="Phone Number"
                                name="phoneNumber"
                                value={this.state.phoneNumber}
                                onChange={this.handlePhoneChange}/>

                            {/* <input type="number" className="form-control" name="phoneNumber" value={this.state.phoneNumber} onChange={this.onChanged} max={10} min={10}/> */}
                            {this.state.phoneNumberError ?
                                <span className="cm_error_msg">{this.state.phoneNumberError}</span> : ''}

                        </div>

                    </div>

                    <div className="row">

                        <div className="col-md-6 form-group">
                            <label>Salon Name</label>
                            <Autosuggest
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={inputProps}
                                onSuggestionSelected={this.onSuggestionSelected}
                            />
                            {/*<input type="email" className="form-control" name="salonName" value={this.state.salonName} onChange={this.onChanged}/>*/}
                            {this.state.salonNameError ?
                                <span className="cm_error_msg">{this.state.salonNameError}</span> : ''}
                        </div>

                        <div className="col-md-6 form-group">
                            <label>Address</label>
                            {/*<input type="text" className="form-control" name="formattedAddress" value={this.state.formattedAddress}  onChange={this.onChanged} disabled={this.state.disableAddress}/>*/}
                            <div className="address_input_wrap">
                            <PlacesAutocomplete
                                searchOptions={searchOptions}
                                // disabled={this.state.disableAddress}
                                value={this.state.formattedAddress}
                                onChange={this.handleChange}
                                onSelect={this.handleSelect}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Search Places ...',
                                                className: 'location-search-input',
                                            })}
                                        />
                                        {
                                            suggestions.length ? <div className="autocomplete_location">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#ffffff', cursor: 'pointer' }
                                                    : { backgroundColor: '#e4e4e4', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>


                                                );


                                            })}

                                        </div> : ''
                                        }

                                    </div>
                                )}
                            </PlacesAutocomplete>

                            {/*<div className={`cm_check_btn ${this.state.isSalonCreated ? 'active' : ''}`}>*/}
                            {/*    <i className="fa fa-check" onClick={this.salonCreate.bind(this)}/>*/}
                            {/*</div>*/}


                            </div>
                            {this.state.formattedAddressError ?
                                <span className="cm_error_msg">{this.state.formattedAddressError}</span> : ''}
                        </div>

                    </div>

                    <div className="row">


                        <div className="col-md-6 form-group">
                        <label>Job Title (viewable when making online booking)</label>
                        <input type="text" className="form-control" value={this.state.jobTitle} name="jobTitle" onChange={this.onChanged} maxLength={50}/>
                        {this.state.jobError ?
                            <span className="cm_error_msg">{this.state.jobError}</span> : ''}
                        </div>

                        <div className="col-md-6 form-group">
                            <label>Employment Type</label>
                            <Select
                                value={this.state.employmentType}
                                options={options}
                                classNamePrefix="custom"
                                name="employmentType"
                                onChange={this.onDataChange}
                                isDisabled={localStorage.getItem("userType")&&(localStorage.getItem("userType")==1||localStorage.getItem("userType")==2)?true:false}
                                />
                            {this.state.employmentTypeError ?
                                <span className="cm_error_msg">{this.state.employmentTypeError}</span> : ''}
                        </div>

                    </div>


                    <div className="form-group">
                        <div className="d-flex">
                            <div className="check_wrap">
                                <input type="checkbox" checked={this.state.agree} onChange={()=>this.toggleCheckBox('agree','agreeError')} name="agree"/>
                                <span />
                            </div>
                            <p className="cm_fw_l m-0">I agree to the <a className="cm_fw_b" href="/hairdresser-terms-and-conditions" target="_blank">partner terms</a>, <a className="cm_fw_b" href="/terms-and-conditions" target="_blank">terms and conditions</a>,and <a className="cm_fw_b" href="/privacy-policy" target="_blank">privacy policy</a>.</p>
                        </div>
                        <div className="pl-4">{this.state.agreeError ? <span className="cm_error_msg">{this.state.agreeError}</span> : ''}</div>
                    </div>




                    {this.state.error ?
                        <span className="cm_error_msg">{this.state.error}</span> : ''}

                    <div className="text-center">
                        <button type="button" className="btn btn-dark pl-5 pr-5" onClick={this.signup.bind(this)} disabled={this.state.isSignup}>Create Account</button>
                    </div>


                </div>

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Signup);
