import React, { Component } from 'react';
import TopHeader from '../template/TopHeader';
import SideNav from '../template/SideNav';
import {hairdresserActions, userActions} from "../../_actions";
import {connect} from "react-redux";
import siteSetting from "../../config/env";
import {hairdresserService} from "../../_services";
import {Modal} from 'react-bootstrap';
import {Addicon, Close, InfoIcon, MySpinner} from '../SvgIcon';
import Select from 'react-select';
import Lottie from "react-lottie";
import animationData from "../../assets/animation-data/loader.json";
const options = [
    { value: true, label: 'Yes'},
    { value: false, label: 'No' },
];
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};
var _ = require('lodash');
// let userIdHolder = [];
// const newCalendar = [];
let timeSlot=[];
for(let i=0;i<24*60;i+=5){
    timeSlot.push(i)
}
class AddService extends Component {
    constructor(props){
        super(props);

        this.state = {
            latitude : siteSetting.default_latitude,
            longitude : siteSetting.default_longitude,
            BankDetailedRequiredPopup:false,
            hairdresserList:[],
            selectedHairdresserId:'',
            selectedHairdresser:{},
            mainDuration:'',
            mainPrice:'',
            mainDepositPrice:'',
            oldMainDuration:'',
            oldMainPrice:'',
            oldmainDepositPrice:'',
            mainDepositPriceError:'',
            mainDurationError:'',
            serviceTimeError:'',
            staffService:[],
            showTooltip:false,
            mainProcessingTime:'',
            mainProcessingTimeError:'',
            onlineBookingTypeError:'',
            mainOnlineBooking:true,
            oldmainProcessingTime:'',
            oldmainOnlineBooking:'',
            loader:true
        }
        this.addService=this.addService.bind(this);
        this.onChanged=this.onChanged.bind(this);
        this.deleteService=this.deleteService.bind(this);
        this.cancleService=this.cancleService.bind(this);
        this.mainStaffService=this.mainStaffService.bind(this);
        this.openBankDetailedRequiredPopup=this.openBankDetailedRequiredPopup.bind(this);
    }

    componentDidMount() {
        var self = this;
        let params = {
            defaultRedirectTo:localStorage.getItem('defaultRedirectTo')
        };
        if (localStorage.getItem('accessToken')) {
            self.props.dispatch(userActions.profile(params))
            let params2 = {
                hairdresserId:this.props.match.params.hairdresserId,
                serviceId:this.props.match.params.serviceId,
            };
            let hairdresserId=this.props.match.params.hairdresserId
            self.props.dispatch(hairdresserActions.hairdresserStaffServiceDetail(params2))
                .then(function (response) {
                    let selectedHairdresser=_.find(response.data.data.total, function(obj) {
                        //console.log("userService",obj,services);
                        return obj._id === hairdresserId;
                    })
                    // console.log("selectedHairdresser",selectedHairdresser);
                    self.setState({
                        loader:false,
                        selectedHairdresserId:hairdresserId,
                        selectedHairdresser:selectedHairdresser,
                        mainDuration:selectedHairdresser.hairdresserSelectedServices.serviceTime,
                        mainPrice:selectedHairdresser.hairdresserSelectedServices.servicePrice,
                        mainDepositPrice:selectedHairdresser.hairdresserSelectedServices.depositPrice,
                        mainProcessingTime:selectedHairdresser.hairdresserSelectedServices.processingTime,
                        mainOnlineBooking:selectedHairdresser.hairdresserSelectedServices.onlineBooking?selectedHairdresser.hairdresserSelectedServices.onlineBooking===true?{value: true, label: 'Yes'}:{value: false, label: 'No'}:{value: false, label: 'No'},
                        oldMainDuration:selectedHairdresser.hairdresserSelectedServices.serviceTime?selectedHairdresser.hairdresserSelectedServices.serviceTime:"",
                        oldMainPrice:selectedHairdresser.hairdresserSelectedServices.servicePrice?selectedHairdresser.hairdresserSelectedServices.servicePrice:"",
                        oldmainDepositPrice:selectedHairdresser.hairdresserSelectedServices.depositPrice?selectedHairdresser.hairdresserSelectedServices.depositPrice:"",
                        oldmainProcessingTime:selectedHairdresser.hairdresserSelectedServices.processingTime?selectedHairdresser.hairdresserSelectedServices.processingTime:"",
                        oldmainOnlineBooking:selectedHairdresser.hairdresserSelectedServices.onlineBooking?selectedHairdresser.hairdresserSelectedServices.onlineBooking:{value: true, label: 'Yes'},
                    })
                    //let otherServiceData=[];
                    let staffService=self.state.staffService;
                    response.data.data.total.map(item => {
                        if (item.hairdresserSelectedServices.serviceTime) {
                            //otherServiceData.push({[item._id]:true})

                            staffService.push({
                                [item._id]:{
                                    [item._id]:true,
                                    [item._id + "_old_time"]: item.hairdresserSelectedServices.serviceTime,
                                    [item._id + "_old_price"]: item.hairdresserSelectedServices.servicePrice,
                                    [item._id + "_time"]: item.hairdresserSelectedServices.serviceTime,
                                    [item._id + "_price"]: item.hairdresserSelectedServices.servicePrice,
                                    [item._id + "_deposit"]: item.hairdresserSelectedServices.depositPrice,
                                    [item._id + "_processingTime"]: item.hairdresserSelectedServices.processingTime,
                                    [item._id + "_onlineBooking"]: item.hairdresserSelectedServices.onlineBooking?item.hairdresserSelectedServices.onlineBooking===true?{value: true, label: 'Yes'}:{value: false, label: 'No'}:{value: false, label: 'No'},
                                }
                            })
                            self.setState({
                                [item.hairdresserInfo._id]:true,
                                [item.hairdresserInfo._id + "_old_time"]: item.hairdresserSelectedServices.serviceTime,
                                [item.hairdresserInfo._id + "_old_price"]: item.hairdresserSelectedServices.servicePrice,
                                [item.hairdresserInfo._id + "_time"]: item.hairdresserSelectedServices.serviceTime,
                                [item.hairdresserInfo._id + "_price"]: item.hairdresserSelectedServices.servicePrice,
                                [item.hairdresserInfo._id + "_deposit"]: item.hairdresserSelectedServices.depositPrice,
                                [item.hairdresserInfo._id + "_processingTime"]: item.hairdresserSelectedServices.processingTime,
                                [item.hairdresserInfo._id + "_onlineBooking"]: item.hairdresserSelectedServices.onlineBooking?item.hairdresserSelectedServices.onlineBooking===true?{value: true, label: 'Yes'}:{value: false, label: 'No'}:{value: false, label: 'No'},
                            })
                        }
                    })
                    self.setState(staffService)

                });
        }else{
            this.props.history.push('/')
        }
    }

    handleEditDetail = (otherStaffId,time,status)=> {
        if(!time) {
            if (status) {
                this.setState({
                    [otherStaffId]: true,
                    [otherStaffId + "_time"]: this.state.mainDuration,
                    [otherStaffId + "_price"]: this.state.mainPrice,
                    [otherStaffId + "_deposit"]: this.state.mainDepositPrice,
                    [otherStaffId + "_processingTime"]: this.state.mainProcessingTime,
                    [otherStaffId + "_onlineBooking"]: this.state.mainOnlineBooking
                })
            } else {
                this.setState({
                    [otherStaffId]: false,
                    [otherStaffId + "_time"]: "",
                    [otherStaffId + "_price"]: "",
                    [otherStaffId + "_deposit"]: "",
                    [otherStaffId + "_processingTime"]: "",
                    [otherStaffId + "_onlineBooking"]: ""
                })
            }
        }
    }
    closeModal() {
        this.setState({
            BankDetailedRequiredPopup: false,
        })
    }
    openBankDetailedRequiredPopup() {
        this.setState({
            BankDetailedRequiredPopup: !this.state.BankDetailedRequiredPopup
        })
    }

    addService(){
        let self=this;
        var arr = [];
        console.log('abc 3',this.state);
        _.map(this.state, function (value,key) {
            if(value===true&&key!=="oldmainOnlineBooking"){
                console.log('abc 4',value,key);
                arr.push(key) ;
            }
        });

        console.log('abc 5',arr);
    
       // staffServiceDetail.serviceDetail.categoryID
        this.setState({
            mainPriceError:'',
            mainDurationError:'',
            mainDepositPriceError:''
        })
        let params={
            categoryId:this.props.staffServiceDetail.serviceDetail.categoryID,
            serviceId:this.props.match.params.serviceId,
            hairdresserId:this.state.selectedHairdresser.hairdresserInfo._id,
            servicePrice:this.state.mainPrice,
            serviceTime:this.state.mainDuration,
            depositPrice:+this.state.mainDepositPrice,
            onlineBooking:this.state.mainOnlineBooking?.value,
            processingTime:this.state.mainProcessingTime,
            deviceToken:'dfsdfdsf'
        };

        if (this.state.mainDuration === "" || this.state.mainDuration === undefined) {
            this.setState({
                mainDurationError: 'Please enter time'
            })
        }else  if (this.state.mainPrice === "" || this.state.mainPrice === undefined) {
            this.setState({
                mainPriceError: 'Please enter price'
            })
        }else if (this.state.mainDepositPrice != "" && this.state.mainDepositPrice != undefined&&parseFloat(this.state.mainDepositPrice)>parseFloat(this.state.mainPrice)) {
                this.setState({
                    mainDepositPriceError: 'The deposit amount should be less than the service cost'
                })
        }
        else if (this.state.mainDepositPrice != "" && this.state.mainDepositPrice != undefined&&parseFloat(this.state.mainDepositPrice)<1) {
            this.setState({
                mainDepositPriceError: 'The deposit amount should be greater than 1'
            })
        }
        // else if (this.state.mainDepositPrice != "" && this.state.mainDepositPrice != undefined&&parseFloat(this.state.mainDepositPrice)==0) {
        //     this.setState({
        //         mainDepositPriceError: 'The deposit amount can not be 0'
        //     })
        // }
        else {
            if(arr.length>0) {
                _.map(arr, function (obj) {
                    let params1 = {
                        categoryId: self.props.staffServiceDetail.serviceDetail.categoryID,
                        serviceId: self.props.match.params.serviceId,
                        hairdresserId: obj,
                        servicePrice: self.state[obj + "_price"],
                        serviceTime: self.state[obj + "_time"],
                        depositPrice:+self.state[obj + "_deposit"],
                        processingTime:self.state[obj + "_processingTime"],
                        onlineBooking:self.state[obj + "_onlineBooking"]?.value,
                        deviceToken: 'dfsdfdsf'
                    };
                    if(self.state[obj + "_price"]!==""&&self.state[obj + "_price"]!==undefined) {
                        if (self.state[obj + "_old_time"]) {
                            hairdresserService.updateService(params1)
                                .then(function (response) {
                                    // console.log('client response', response);
                                    // if(response.data.errorCode==0){
                                    //     self.setState({
                                    //         successMessage:'Service has been saved successfully!'
                                    //     })
                                    // }
                                })
                        } else {
                           
                            hairdresserService.addService(params1)
                                .then(function (response) {
                                    // console.log('client response', response);
                                    // if(response.data.errorCode==0){
                                    //     self.setState({
                                    //         successMessage:'Service has been saved successfully!'
                                    //     })
                                    // }
                                })
                        }
                    }
                })
                self.mainStaffService(params);
            }else{
                self.mainStaffService(params);
            }

        }

    }

    mainStaffService(params){
        //console.log("mainStaffService==================");
        let self=this;
        if (this.state.oldMainPrice === '' && this.state.oldMainDuration === '') {
            //console.log('if');
            hairdresserService.addService(params)
                .then(function (response) {
                    // console.log('client response', response);
                    if(response.data.errorCode===0||response.data.errorCode==="0"){
                        self.setState({
                            successMessage:'Service has been saved successfully!'
                        })
                        self.props.history.push('/services/'+self.props.match.params.hairdresserId);
                    }
                    //self.addStaffService();
                })
        } else {
            //console.log('dsadasdas');
            hairdresserService.updateService(params)
                .then(function (response) {
                    // console.log('client response', response);
                    if(response.data.errorCode===0){
                        self.setState({
                            successMessage:'Service has been saved successfully!'
                        })
                        self.props.history.push('/services/'+self.props.match.params.hairdresserId);
                    }
                    //self.addStaffService();
                })
        }
        //self.props.history.push('/services/'+self.props.match.params.hairdresserId);
    }

    onChanged(e) {
        console.log('abc 1',{[e.target.name]: e.target.value})
        this.setState({[e.target.name]: e.target.value, copied: false});
        if (e.target.name === 'mainDuration') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    mainDurationError: 'Please enter valid time'
                })
            } else if(e.target.value%5!==0){
                this.setState({
                    mainDurationError: 'The service time must be a multiple of 5',
                })
            }else{
                this.setState({
                    mainDurationError: '',
                })
            }
        }
        if (e.target.name === 'mainProcessingTime') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    mainProcessingTimeError: 'Please enter valid time'
                })
            } else if(e.target.value%5!==0){
                this.setState({
                    mainProcessingTimeError: 'The service time must be a multiple of 5',
                })
            }else{
                this.setState({
                    mainProcessingTimeError: '',
                })
            }
        }
    }

    deleteService(id,hairdresserId,serverServiceId){
        console.log("id",serverServiceId,hairdresserId)
        let self = this;
        if(serverServiceId) {
            // staffServiceDetail.serviceDetail.categoryID
            this.setState({
                mainPriceError: '',
                mainDurationError: ''
            })
            let params = {
                categoryId: this.props.staffServiceDetail.serviceDetail.categoryID,
                serviceId: this.props.match.params.serviceId,
                hairdresserId: hairdresserId,
                servicePrice: this.state.mainPrice,
                serviceTime: this.state.mainDuration,
                depositPrice: this.state.mainDepositPrice,
                onlineBooking: this.state.mainOnlineBooking?.value,
                processingTime: this.state.mainProcessingTime,
                deviceToken: 'dfsdfdsf'
            };
            let hairdresser_id=hairdresserId;
            hairdresserService.deleteService(params)
                .then(function (response) {
                    //console.log('client response', response);
                    if (response.data.errorCode === 0) {
                        self.setState({
                            successMessage: 'Service has been delete successfully!'
                        })
                        //console.log("id",serverServiceId,hairdresser_id)
                        self.handleEditDetail(hairdresser_id, "", false)
                        let params2 = {
                            hairdresserId: self.props.match.params.hairdresserId,
                            serviceId: self.props.match.params.serviceId,
                        };
                        let hairdresserId = self.props.match.params.hairdresserId
                        self.props.dispatch(hairdresserActions.hairdresserStaffServiceDetail(params2))
                            .then(function (response) {
                                let selectedHairdresser = _.find(response.data.data.total, function (obj) {
                                    //console.log("userService",obj,services);
                                    return obj._id === hairdresserId;
                                })
                                self.setState({
                                    selectedHairdresserId: hairdresserId,
                                    selectedHairdresser: selectedHairdresser,
                                    mainDuration: selectedHairdresser.hairdresserSelectedServices.serviceTime,
                                    mainPrice: selectedHairdresser.hairdresserSelectedServices.servicePrice,
                                    mainDepositPrice: selectedHairdresser.hairdresserSelectedServices.depositPrice,
                                    mainProcessingTime:selectedHairdresser.hairdresserSelectedServices.processingTime,
                                    mainOnlineBooking:selectedHairdresser.hairdresserSelectedServices.onlineBooking?selectedHairdresser.hairdresserSelectedServices.onlineBooking===true?{value: true, label: 'Yes'}:{value: false, label: 'No'}:null,
                                    oldMainDuration: selectedHairdresser.hairdresserSelectedServices.serviceTime ? selectedHairdresser.hairdresserSelectedServices.serviceTime : "",
                                    oldMainPrice: selectedHairdresser.hairdresserSelectedServices.servicePrice ? selectedHairdresser.hairdresserSelectedServices.servicePrice : "",
                                    oldmainDepositPrice: selectedHairdresser.hairdresserSelectedServices.depositPrice ? selectedHairdresser.hairdresserSelectedServices.depositPrice : "",
                                    oldmainProcessingTime:selectedHairdresser.hairdresserSelectedServices.processingTime?selectedHairdresser.hairdresserSelectedServices.processingTime:"",
                                    oldmainOnlineBooking:selectedHairdresser.hairdresserSelectedServices.onlineBooking?selectedHairdresser.hairdresserSelectedServices.onlineBooking:{value: true, label: 'Yes'},
                                })
                            });
                    }
                })
        }else{
            self.handleEditDetail(hairdresserId, "", false)
        }
    }

    cancleService(){
        this.props.history.push('/services');
    }
    showTooltip=()=>{
        this.setState({
            showTooltip:true
        })
     }
     hideTooltip=()=>{
        this.setState({
            showTooltip:false
        })
     }

    onDataChange=(value, action)=>{
        this.setState({
            [action.name]: value,
        })

        console.log('abc 2',{[action.name]: value})
    }
    render(){
         console.log("this.props",this.props);
         console.log("this.state",this.state);
        return(
            <div className="h_n body_container cm_bg_white">

                <TopHeader title={this.state.selectedHairdresser&&this.state.selectedHairdresser.name!==""&&this.state.selectedHairdresser.name!==undefined?"Add Service for "+this.state.selectedHairdresser?.name:"Add Service for ... "} {...this.props}/>
                <SideNav {...this.props}/>

                {!this.state.loader?
                <div className="container addservice">

                    <div className="cm_box_view mt-0 pb-0">

                        <h2 className='d-flex'>
                            {/*<span className='show__color mr-4' style={{background: '#E3BEEB'}}/>*/}
                            {this.props.staffServiceDetail.serviceDetail?this.props.staffServiceDetail.serviceDetail.serviceName:""}
                        <div className='tooltip_parent  _add_service_tooltip'>
                                                <span className='info' onClick={() =>this.showTooltip()}><InfoIcon/></span>
                                                {this.state.showTooltip===true?
                                                <div className='tooltip-body'>
                                                  <span className='close' onClick={() =>this.hideTooltip()}><Close/></span>
                                                  <h6>Service Tips</h6>
                                                  <p className="about_comm">
                                                    <b>Service Duration</b>  - how long the service takes. This will be shown to any clients booking online, and in the stylists diary 
                                                  </p>
                                                  {/*<p className="about_comm">  */}
                                                  {/*  <b>Processing Time</b>  - some services such as colour require a processing time after the service, before another service can take place.<br/><br/> e.g. if a 30 minute processing time is applied to Full Head Colour, and Full Head Colour is booked in with a cut and finish, then there will be 30 minutes between the appointments to fit in another service. */}
                                                  {/*</p>*/}
                                                  <p className="about_comm">
                                                    <b>Online Bookings</b>  - this will toggle if clients can book this service online or not. Deposit - if a deposit is set, then the client will have to pay a deposit when booking an appointment. This can be set the same as the cost, allowing full prepayment. 
                                                  </p>
                                                  <p className="about_comm">
                                                        <b>Cost</b>  - this is how much the service costs
                                                  </p>
                                                 
                                               
                                                </div>:''}
                                             </div>
                        </h2>

                        <div className="row mt-2 mt-md-5 _add_service_container">
                            <div className="_add_service_box form-group">
                                <label>Service Duration</label>
                                <input type="number" className="form-control" name="mainDuration" value={this.state.mainDuration} onChange={this.onChanged} min="5" max="100" step="5"/>
                                {this.state.mainDurationError ?
                                    <span className="cm_error_msg">{this.state.mainDurationError}</span> : ''}
                            </div>
                            <div className="_add_service_box form-group">
                                <label>Processing Time</label>
                                <input type="number" className="form-control" name="mainProcessingTime" value={this.state.mainProcessingTime} onChange={this.onChanged} min="5" max="100" step="5"/>
                                {this.state.mainProcessingTimeError ?
                                    <span className="cm_error_msg">{this.state.mainProcessingTimeError}</span> : ''}
                            </div>
                            <div className="_add_service_box form-group">
                            <label>Online Booking</label>
                            <Select
                                value={this.state.mainOnlineBooking}
                                options={options}
                                classNamePrefix="custom"
                                name="mainOnlineBooking"
                                onChange={this.onDataChange}
                               />
                            {this.state.onlineBookingTypeError ?
                                <span className="cm_error_msg">{this.state.onlineBookingTypeError}</span> : ''}
                           </div>

                            {/* <div className="_add_service_box form-group">
                                <label></label>
                                <input type="number" className="form-control" name="mainDuration" value={this.state.mainDuration} onChange={this.onChanged} min="5" max="100" step="5"/>
                                {this.state.mainDurationError ?
                                    <span className="cm_error_msg">{this.state.mainDurationError}</span> : ''}
                            </div> */}
                            <div className="_add_service_box form-group">
                                <label>Deposit ({this.state.selectedHairdresser.hairdresserInfo&&this.state.selectedHairdresser.hairdresserInfo.currencySymbol&&this.state.selectedHairdresser.hairdresserInfo.currencySymbol!==""?this.state.selectedHairdresser.hairdresserInfo.currencySymbol:"£"})</label>
                                <input type="number" className="form-control" name="mainDepositPrice" value={this.state.mainDepositPrice} onChange={this.onChanged} min="0"/>
                                {this.state.mainDepositPriceError ?
                                    <span className="cm_error_msg">{this.state.mainDepositPriceError}</span> : ''}
                            </div>
                            <div className="_add_service_box form-group">
                                <label>Price ({this.state.selectedHairdresser.hairdresserInfo&&this.state.selectedHairdresser.hairdresserInfo.currencySymbol&&this.state.selectedHairdresser.hairdresserInfo.currencySymbol!==""?this.state.selectedHairdresser.hairdresserInfo.currencySymbol:"£"})</label>
                                <input type="number" className="form-control" name="mainPrice" value={this.state.mainPrice} onChange={this.onChanged} min="0"/>
                                {this.state.mainPriceError ?
                                    <span className="cm_error_msg">{this.state.mainPriceError}</span> : ''}
                            </div>

                        </div>

                        {this.props.staffServiceDetail&&this.props.staffServiceDetail.total&&this.props.staffServiceDetail.total.length>1?
                            (<div className="other_staff mt-md-4 addservice-popup">
                            <div className="cm_tab_row cm_fw_b" style={{background: '#fff'}}>
                                <div className="cm_main_col pl-4 hideSmallscreen">Choose other staff who provide service</div>
                                <div className="cm_main_col pl-4 hideBigscreen text-left">Staff </div>
                                <div className="cm_main_col_2">Duration</div>
                                <div className="cm_main_col_2">Processing Time</div>
                                <div className="cm_main_col_2">Online Booking</div>
                                <div className="cm_main_col_2">Deposit</div>
                                <div className="cm_main_col_3">Cost</div>
                            </div>

                            {
                                this.state.selectedHairdresserId!==""&&this.props.staffServiceDetail&&this.props.staffServiceDetail.total&&this.props.staffServiceDetail.total.length>1?
                                this.props.staffServiceDetail.total.map(item => (
                                    this.state.selectedHairdresserId!==item._id?
                                    <div key={item._id} className={`cm_tab_row serv-tab-mobl ${this.state[item.hairdresserInfo._id]||item.hairdresserSelectedServices.serviceTime ? 'selected' : ''}`} >
                                        <div className="cm_main_col" onClick={() => this.handleEditDetail(item.hairdresserInfo._id,item.hairdresserSelectedServices.serviceTime,true)}>
                                            <img src={item.userImage.circleImage?item.userImage.circleImage: require('../../assets/images/client-plachoder.jpg')} width="50px" />
                                            <p>{item.name}  
                                            {/* <input type="color" id="color-picker" name="color-picker"/> */}
                                            </p>
                                           
                                        </div>
                                        <div className="cm_main_col_2">
                                            {
                                                this.state[item.hairdresserInfo._id]||item.hairdresserSelectedServices.serviceTime ? <input type="number" className="form-control cm_table_input" value={this.state[item.hairdresserInfo._id+"_time"]} name={item.hairdresserInfo._id+"_time"} onChange={this.onChanged} min="5" max="100" step="5"/> : <span className="cm_b_span" onClick={() => this.handleEditDetail(item.hairdresserInfo._id,item.hairdresserSelectedServices.serviceTime,true)}>--</span>
                                            }
                                        </div>


                                        <div className="cm_main_col_2">
                                            {
                                                this.state[item.hairdresserInfo._id]||item.hairdresserSelectedServices.processingTime ? <input type="number" className="form-control cm_table_input" value={this.state[item.hairdresserInfo._id+"_processingTime"]} name={item.hairdresserInfo._id+"_processingTime"} onChange={this.onChanged} min="5" max="100" step="5"/> : <span className="cm_b_span" onClick={() => this.handleEditDetail(item.hairdresserInfo._id,item.hairdresserSelectedServices.processingTime,true)}>--</span>
                                            }
                                        </div>
                                        <div className="cm_main_col_2">
                                            {
                                                this.state[item.hairdresserInfo._id]||item.hairdresserSelectedServices.onlineBooking ?
                                                    <Select
                                                        value={this.state[item.hairdresserInfo._id+"_onlineBooking"]}
                                                        options={options}
                                                        classNamePrefix="custom"
                                                        className="__drop_down"
                                                        name={item.hairdresserInfo._id+"_onlineBooking"}
                                                        onChange={this.onDataChange}
                                                    />
                                                    // <input type="number" className="form-control cm_table_input" value={this.state[item.hairdresserInfo._id+"_onlineBooking"]} name={item.hairdresserInfo._id+"_onlineBooking"} onChange={this.onChanged} min="5" max="100" step="5"/>
                                                    : <span className="cm_b_span" onClick={() => this.handleEditDetail(item.hairdresserInfo._id,item.hairdresserSelectedServices.onlineBooking,true)}>--</span>
                                            }
                                        </div>
                                     
                                        <div className="cm_main_col_2">
                                            {
                                                this.state[item.hairdresserInfo._id]||item.hairdresserSelectedServices.serviceTime ? <input type="number" className="form-control cm_table_input" value={this.state[item.hairdresserInfo._id+"_deposit"]} name={item.hairdresserInfo._id+"_deposit"} onChange={this.onChanged} min="5" max="100" step="5"/> : <span className="cm_b_span" onClick={() => this.handleEditDetail(item.hairdresserInfo._id,item.hairdresserSelectedServices.depositPrice,true)}>--</span>
                                            }
                                        </div>
                                       
                                        <div className="cm_main_col_3">
                                            {
                                                this.state[item.hairdresserInfo._id]||item.hairdresserSelectedServices.serviceTime ?
                                                    <React.Fragment>
                                                        <input type="number" className="form-control cm_table_input" value={this.state[item.hairdresserInfo._id+"_price"]} name={item.hairdresserInfo._id+"_price"} onChange={this.onChanged}/>
                                                        <i className="fa fa-times" onClick={() => (this.deleteService(item._id,item.hairdresserInfo._id,item.hairdresserSelectedServices._id))} />
                                                    </React.Fragment> : <span className="cm_b_span" onClick={() => this.handleEditDetail(item.hairdresserInfo._id,item.hairdresserSelectedServices.serviceTime,true)}>--</span>
                                            }
                                        </div>
                                    </div>
                                :""))
                            :""}
                        </div>):""}


                    </div>

                    <div className="text-center text-md-right mt-4">
                        <span className="btn btn-outline-dark pl-4 pr-4" onClick={this.cancleService.bind(this)}>Cancel</span>
                        <button type="button" className="btn btn-dark ml-3 pl-4 pr-4" onClick={this.addService.bind(this)}>Save</button>
                        <br/>
                        <br/>
                        {this.state.successMessage ?
                            <span className="cm_succ_msg">{this.state.successMessage}</span> : ''}
                    </div>

                </div>
                :<div className="text-center mt-3">
                <Lottie options={defaultOptions}
                        height={50}
                        width={200}
                />
                <div className="mb-3">
                    <h4 className="">Loading Data...</h4>
                </div>
            </div>}
                {/* <---payment failed modal--> */}
                <Modal   blurClass="blure_bg"
                    contentClassName="p-0 payment-failed"
                    show={this.state.BankDetailedRequiredPopup}>
                       <div className="cm_modal booking_bg popupscroll ">
                                <div className="modal-header closesvg" style={{ borderBottom: 'none' }}>
                                    <p className="text-right w-100"><Close  onClick={this.closeModal}/></p>

                                </div>
                            <div className="pt-0 pl-5 pr-5 pb-5 text-center">
                            <img src={require('../../assets/images/bankdetails.png')} className="img-fluid mb-3" />
                            <h3 style={{fontSize: '38px', fontWeight: '200'}} className="mb-4">Bank Details Required</h3>
                            <p style={{fontWeight: '200'}}>
                            In order for you to take online deposits, please add your bank details so we can payout into your account.
                            </p>

                            <div className="stap-action mt-5">
                                <button className="btn btn-info btn-block" onClick={()=>this.openBankDetailedRequiredPopup()}>Continue</button>
                            </div>
                            </div>

                            </div>
                        </Modal>
                {/* <---payment failed modal--> */}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(AddService);

const loop = [1,2,3,4,5,6,7,8]
