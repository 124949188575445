import React, { Component } from "react";
export default class BeuMarketplace extends Component {
    render() {
        return (
            <div className="container pad-0 beumarket">
                        <div className=" happy_business feature pt-0">
                       
                            <div className="text mr-5">
                                <h4>Beu Marketplace</h4>
                                <p>Get discovered by local clients with the Beu marketplace and grow your business</p>
                                <div className="feature-list">
                                    <p>Receive bookings from  your website, social media and our app</p>
                                    <p>No fees for clients booking through our marketplace</p>
                                    <p>Get discovered by clients looking for services in their local area</p>
                                    <p>Showcase your portfolio and verified reviews to attract new business</p>
                                </div>
                            </div>
                            <div className="img mr-0 bue-market">
                                <div>
                                  <img src={require('../../assets/images/web-img/Beu-Marketplace1.png')} className="img-fluid mb-3" />
                                </div>
                                <div className="d-flex">
                                <img src={require('../../assets/images/web-img/Beu-Marketplace2.png')} className="img-fluid mb-3" />
                                <img src={require('../../assets/images/web-img/Beu-Marketplace3.png')} className="img-fluid mb-3" />
                                </div>
                            </div>
                          
                            
                        </div>
                     </div>
        )
    }
}
