import React, { Component } from "react";
import FooterBtn from './FooterBtn'
import {connect} from "react-redux";
class NewAppointment extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>
            <div className="container tab_content_wrapper new-booking-mid">
                  <div className="row new-appointment-page">
                    {/*<div className="col-sm-12">*/}
                    {/*    <p  className="text-center toggle small-toggle">*/}
                    {/*    <span className="mr-3 selected">Client</span>*/}
                    {/*        <label  className="switch">*/}
                    {/*        <input type="checkbox" />*/}
                    {/*        <span  className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*        <span className="ml-3 ">Walk-in</span>*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                  
                    <div className="col-sm-12">
                        <button className="btn btn-blue btn-block" onClick={()=>this.props.selectExistingClient()}>Existing Client</button>
                        <p className="mt-3 mb-3 text-center">Or</p>
                        <button className="btn btn-blue btn-block" onClick={()=>this.props.selectNewClient()} >New Client</button>
                    </div>

                  </div>
            </div>
           </>
        )
    }
}
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(NewAppointment);
