import React, {Component} from 'react';
import {Tab, Tabs, Modal, Accordion, Card, Button} from 'react-bootstrap';
import {connect} from "react-redux";
import {hairdresserActions, userActions} from "../../_actions";
import moment from "moment";
import { Close, MySpinner } from '../SvgIcon';

class ClientCheckout  extends Component {

    constructor(props) {
        super(props);

        this.state = {
            bookingType:'past', //upcoming
            showMore:false,
            rescheduleRequestModal: false,
            cancelBookingModal: false,
            noShowModal: false,
            deleteSalonModal: false,
            removeStaffMemberMobcoder: false,
            editBookingModal:false,
        }
        this.getServiceName=this.getServiceName.bind(this);
        this.getBookingTime=this.getBookingTime.bind(this);
        this.getTotalPrice=this.getTotalPrice.bind(this);
        this.showMore=this.showMore.bind(this);
    }


    handleClose = () => this.setState({
        rescheduleRequestModal: false,
        cancelBookingModal: false,
        noShowModal: false,
        deleteSalonModal: false,
        removeStaffMemberMobcoder: false,
        editBookingModal:false
    })

    componentDidMount() {
        var self = this;
        let params = {
            defaultRedirectTo:localStorage.getItem('defaultRedirectTo')
        };
        
        if (localStorage.getItem('accessToken')) {
            console.log("#####",self?.props?.noShowModal);
            
            // self.props.dispatch(userActions.profile(params))
        }else{
            this.props.history.push('/')
        }
    }

    componentDidUpdate(prevState) {
        let self = this;
        if (this.props.cancelBookingModal !== prevState.cancelBookingModal) {
            self.setState({
                cancelBookingModal:this.props.cancelBookingModal
            })
        }

        if (this.props.noShowModal !== prevState.noShowModal) {
            console.log('check noshow',this.props.noShowModal);
            self.setState({
                noShowModal:this.props.noShowModal
            })
        }

        if (this.props.rescheduleRequestModal !== prevState.rescheduleRequestModal) {
            self.setState({
                rescheduleRequestModal:this.props.rescheduleRequestModal
            })
        }

        if (this.props.editBookingModal !== prevState.editBookingModal) {
            self.setState({
                editBookingModal:this.props.editBookingModal
            })
        }
    }

    getServiceName(booking){
        let serviceName="";
        booking.servicesBooked.map((service,i) => {
            serviceName=serviceName+(serviceName==""?"":", ");
            serviceName=serviceName+service?.service?.serviceName;
        })
        return serviceName;
    }

    getslotTime(time) {

        let hr = parseInt(time / 60);
        let min = time - (hr * 60);
        //console.log("time",time,hr,min);
        return hr + ':' + (min === 0 ? '00' : min);
    }

    getBookingTime(booking){
        //console.log("booking",booking);
        let totalTime=booking.bookingSlot[1]-booking.bookingSlot[0];
        let hr = parseInt(totalTime / 60);
        let min = totalTime%60 ;
        let showTme="";
        if( totalTime >60){
            showTme=hr+' hr '+min+' min'
        }else{
            showTme=min+' min';
        }
        //console.log("showTme",showTme);
        return this.getslotTime(booking.bookingSlot[0])+" - "+this.getslotTime(booking.bookingSlot[1])+", "+showTme
    }

    getTotalPrice(booking){
        let serviceTotalPrice=0;
        booking.servicesBooked.map((service,i) => {
            serviceTotalPrice=serviceTotalPrice+service.servicePrice;
        })
        return serviceTotalPrice.toFixed(2);
    }

    showMore(){
        this.setState({showMore:!this.state.showMore})
    }

    handleReschedule=()=>{
        this.setState({rescheduleRequestModal:true})
    }

    handleCancel=()=>{
        this.setState({cancelBookingModal:true})
    }

    handleNoShow=()=>{
        console.log('checkkkkk',22)
       this.props.noShowPopup();
       this.setState({showMore:false})
       
    }

    handleEditBooking=()=>{
        // this.setState({
        //     rescheduleRequestModal:false,
        //     editBookingModal:true
        // })
        const stateObj = { pageLoadingStatus: 1,};
        const url = '/new-booking-edit/' + this.props.bookings._id;
        this.props.history.push({
            pathname: url,
            state: stateObj,
        });
    }

    editBooking=()=>{
        //console.log("editBooking",this.props)

        const stateObj = { pageLoadingStatus: 1,};
        const url = '/new-booking-edit/' + this.props.bookings._id;
        this.props.history.push({
            pathname: url,
            state: stateObj,
        });

        // this.props.history.push()
    }

    viewInvoice=()=>{
        this.props.history.push('/invoice/'+this.props.bookings._id)
    }

    checkoutBooking=()=>{
        // this.props.history.push('/booking-checkout/'+this.props.bookings._id)
        const stateObj = { pageLoadingStatus:1};
        this.props.history.push({
            pathname: '/checkout/'+this.props.bookings._id,
            state: stateObj
        });

    }

    checkEmail=(email)=>{
        if(email) {
            let emailArr = email.split('@');
            if (emailArr[1] === 'beuapp.com') {
                return false;
            } else {
                return true;
            }
        }else{
            return false;
        }
    }

    showBookingDateTime = () =>{
        const originalDate = moment(this.props.bookings?.createdTime);
        const formattedDate = originalDate.format('h:mmA, DD MMMM YYYY');
        return formattedDate;
    }

    disableNoShowInput=()=>{
            let self =this;
            let stripePaymentMethodId = (self?.props?.bookings?.stripePaymentMethodId!=''&& self?.props?.bookings?.stripePaymentMethodId!=undefined)?true:false;
            let noShowAmountWithPolicyStatus = (self.props.noShowPolicyStatus && +self.props.noShowFeeAmount>0) && self.props?.noShowSelectedOption==1? true: false;
            if(stripePaymentMethodId && noShowAmountWithPolicyStatus){
                return "transaction_option"
            }else{
                return "transaction_option disabled";
            }
    }

    render() {
        console.log("this?.props",this?.props);
        let {
            rescheduleRequestModal,
            cancelBookingModal,
            noShowModal,
            editBookingModal
        } = this.state;
        let { borderr } =this.props;
        return (
            this.props?.bookings?.user?.email!==undefined ?
                (
                    <>
                        <div className="d-md-none show-bkng-wrp">
                            <div className="clnt-booking-view-mblvwe mb-4">
                                <div className="cm_bao">
                                    <img src={this.props?.bookings?.user?.localAvatar !== this.props.bookings?.user.localAvatar ? "" : require('../../assets/images/client-plachoder.jpg')} alt='profile'/>
                                    <h3>{ this.props?.bookings?.user?.name}</h3>

                                    {this.checkEmail(this.props?.bookings?.user?.email)?<p>{this.props?.bookings?.user?.email}</p>:""}
                                    <p>{(this?.props?.selectedClientId!=='64f5c7789a069cda0188fea3')?this.props?.bookings?.user?.phoneNumber:""}</p>
                                </div>
                                <div className="clnt-booking-view-body bg-white p-3">
                                    <div className="clnt-booking-date">
                                        <p className="m-0">{moment(this.props.hairdresserBooking.bookings[0].bookingDate).format('dddd DD MMM YYYY')}</p>
                                    </div>
                                    <div className="cmn-listbkng-dtls">
                                        <span>Staff Member</span>
                                        <p className="m-0">{this.props.hairdresserBooking.bookings[0].hairdresser.name}</p>
                                    </div>

                                    {this.props.hairdresserBooking.bookings[0].servicesBooked.map((services, i) => (
                                        <div className="cmn-listbkng-dtls" key={services._id}>
                                            <span>Services</span>
                                            <p className="m-0">{services.service?.serviceName}</p>
                                        </div>
                                    ))}
                                    <div className="time-cast-bkng">
                                        <div className="cmn-listbkng-dtls">
                                            <span>Start Time</span>
                                            <p className="m-0">{ this.props?.bookingState.slotInfo.startTime}</p>
                                        </div>
                                        <div className="cmn-listbkng-dtls">
                                            <span>Duration</span>
                                            <p className="m-0">{ this.props?.bookingState.totalTime}</p>
                                        </div>
                                        <div className="cmn-listbkng-dtls">
                                            <span>Cost</span>
                                            <p className="m-0">{ this.props?.currencySymbol}{parseFloat( this.props?.totalAmount).toFixed(2)}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {console.log('bbbbbb',this.props?.bookings.bookingStatus)}
                            <div className={`cm_btns justify-content-center d-flex m_revers ${parseFloat( this.props?.pendingAmount).toFixed(2)>0? 'withcheckoutbtn' : ''}`}>
                                {this.props.noShowButtonStatus || this?.props?.bookings?.bookingStatus==3?null:<>
                                    {parseFloat( this.props?.pendingAmount).toFixed(2)>0 || (this?.props?.bookings?.bookingStatus!=4 && parseFloat( this.props?.pendingAmount).toFixed(2)==0)?
                                        <button className="btn btn-dark btn-block" onClick={() => this.checkoutBooking()}>Checkout
                                        </button>:""}
                                    <button className="btn btn-primary btn-block m-0" onClick={this.showMore.bind(this)}>More Options
                                    </button>
                                    </>}
                            </div>
                            {this.state.showMore?
                                <ul
                                    className={`buttons_border_wrap mt-2 ${parseFloat( this.props?.pendingAmount).toFixed(2)>0? 'withcheckoutbtndropdown' : ''}`}
                                >
                                    {this?.props?.bookings?.bookingStatus==4?
                                        <>
                                            <li onClick={this.viewInvoice}>View Invoice</li>
                                        </>:
                                        <>
                                            {this.props?.bookings.bookingStatus!=5 && this.props?.bookings.bookingStatus!="5"?
                                                <>
                                                   <li onClick={this.handleEditBooking}>Edit Booking</li>
                                                    {/*{this.props?.selectedClientId!=='64f5c7789a069cda0188fea3'?<li onClick={this.handleReschedule}>Reschedule Booking</li>:""}  */} 
                                                </>
                                                :""}
                                            <li className="cm_red_c" onClick={this.handleCancel}>Cancel Booking</li>
                                            { this.props.bookingState.selectedClient?._id==='64f5c7789a069cda0188fea3'?null: this.props?.bookings.bookingStatus === 1 ||  this.props?.bookings.bookingStatus === "1" ||  this.props?.bookings.bookingStatus === 0 ||  this.props?.bookings.bookingStatus === "0" ?
                                                <li className="cm_red_c" onClick={this.handleNoShow}>No Show</li> : ""
                                            }
                                        </>
                                    }
                                </ul>
                                :""}
                        </div>

                        <div className={`cm_right_panal d-none d-md-inline-block client_checkout ${borderr ? 'remove-brder' : ''}`}>
                            <div className="cm_bao">
                                <img src={this.props?.bookings?.user?.localAvatar !== this.props?.bookings?.user?.localAvatar ? "" : require('../../assets/images/client-plachoder.jpg')}/>
                                <h3>{this.props?.bookings?.user?.name}</h3>
                                {this.checkEmail(this.props?.bookings?.user?.email)?<p>{this.props?.bookings?.user?.email}</p>:""}
                                {this.props?.selectedClientId!=='64f5c7789a069cda0188fea3'?<p>{this.props?.bookings?.user?.phoneNumber}</p>:""}
                            </div>

                            {(this?.props?.selectedClientId!=='64f5c7789a069cda0188fea3')?
                                <Tabs defaultActiveKey="Booking" className="new-booking-tab">
                                    <Tab eventKey="Booking" title="Booking">

                                        <Accordion defaultActiveKey="0">
                                            <Card>

                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">Upcoming
                                                        ({this.props.clientDetail.upcomingBookings?this.props.clientDetail.upcomingBookings.length:0})</Accordion.Toggle>
                                                </Card.Header>

                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        <ul>
                                                            {this.props.clientDetail.upcomingBookings&&this.props.clientDetail.upcomingBookings.length>0?
                                                                this.props.clientDetail.upcomingBookings.map((booking,i) => (
                                                                    <li className="d-flex justify-content-between" key={booking._id}>
                                                                        <div className="cm_bg_uniq">
                                                                            <h4>{moment(booking.bookingDate).format('DD')}</h4>
                                                                            <p className="cm_fw_l">{moment(booking.bookingDate).format('MMM')}</p>
                                                                        </div>
                                                                        <div>
                                                                            <p>{this.getServiceName(booking)}</p>
                                                                            <p className="cm_fw_l">{this.getBookingTime(booking)}</p>
                                                                        </div>
                                                                        <div className="cm_fw_l text-right">
                                                                            <p>with {booking.hairdresser.name}</p>
                                                                            <p className="text-right">{this.props?.currencySymbol}{this.getTotalPrice(booking)}</p>
                                                                        </div>
                                                                    </li>)):""}
                                                        </ul>
                                                    </Card.Body>
                                                </Accordion.Collapse>

                                            </Card>
                                            <Card>

                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">Past
                                                        ({this.props.clientDetail.pastBooking?this.props.clientDetail.pastBooking.length:0})</Accordion.Toggle>
                                                </Card.Header>

                                                <Accordion.Collapse eventKey="1">
                                                    <Card.Body>
                                                        <ul>
                                                            {this.props.clientDetail.pastBooking&&this.props.clientDetail.pastBooking.length>0?
                                                                this.props.clientDetail.pastBooking.map((booking,i) => (
                                                                    <li className="d-flex justify-content-between" key={booking._id}>
                                                                        <div className="cm_bg_uniq">
                                                                            <h4>{moment(booking.bookingDate).format('DD')}</h4>
                                                                            <p className="cm_fw_l">{moment(booking.bookingDate).format('MMM')}</p>
                                                                        </div>
                                                                        <div>
                                                                            <p>{this.getServiceName(booking)}</p>
                                                                            <p>{this.getBookingTime(booking)}</p>
                                                                        </div>
                                                                        <div className="cm_fw_l text-right">
                                                                            <p>with {booking.hairdresser.name}</p>
                                                                            <p>{this.props?.currencySymbol}{this.getTotalPrice(booking)}</p>
                                                                        </div>
                                                                    </li>)):""}
                                                        </ul>

                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>

                                    </Tab>
                                    <Tab eventKey="notes" title="Notes">
                                        <div className="nts-desc-wrp">
                                            <p>{this.props?.bookings?.user?.note?this.props?.bookings?.user?.note:"No notes"}</p>
                                        </div>
                                    </Tab>
                                </Tabs>:<Tabs defaultActiveKey="Booking" className="new-booking-tab">
                                    <Tab eventKey="Booking" title="Booking">

                                        <Accordion defaultActiveKey="0">
                                            <Card>

                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">Upcoming
                                                        (0)</Accordion.Toggle>
                                                </Card.Header>

                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        <ul>

                                                        </ul>
                                                    </Card.Body>
                                                </Accordion.Collapse>

                                            </Card>
                                            <Card>

                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">Past
                                                        (0)</Accordion.Toggle>
                                                </Card.Header>

                                                <Accordion.Collapse eventKey="1">
                                                    <Card.Body>
                                                        <ul>

                                                        </ul>

                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>

                                    </Tab>
                                    <Tab eventKey="notes" title="Notes">
                                        <div className="nts-desc-wrp">
                                            <p>{"No notes"}</p>
                                        </div>
                                    </Tab>
                                </Tabs>}



                            <Modal show={editBookingModal} onHide={this.handleClose} className="bg_white_modal text-center">
                                <i className="fa fa-times cm_hide_mod" onClick={this.handleClose} />
                                <Modal.Body>
                                    <div className="p-2 p-md-5">
                                        <img src={require('../../assets/images/booking_edit.png')} className="img-fluid mb-3" />
                                        <h3 style={{fontSize: '38px', fontWeight: '400'}} className="mb-4 mdl-hdkng">Edit Booking</h3>
                                        <p style={{fontSize: '23px', fontWeight: '300'}}>Your client will be notified by push notification and email of any changes.</p>

                                        <div className="stap-action mt-5">
                                            <button className="btn btn-outline-dark mr-3" onClick={this.handleClose}>Back</button>
                                            <button className="btn btn-dark" onClick={this.editBooking}>Confirm</button>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>

                            <Modal show={rescheduleRequestModal} onHide={this.handleClose} className="bg_white_modal text-center">
                                <i className="fa fa-times cm_hide_mod" onClick={this.handleClose} />
                                <Modal.Body>
                                    <div className="p-1 p-md-5">
                                        <img src={require('../../assets/images/reschedule-request-img.png')} className="img-fluid mb-3" />
                                        <h3 style={{fontSize: '38px', fontWeight: '400'}} className="mb-4 mdl-hdkng">Reschedule Request</h3>
                                        <p style={{fontSize: '23px', fontWeight: '300'}}>This will send an email to your client to reschedule their booking at a time convenient for them. If you want to amend the booking yourself, choose edit booking.</p>

                                        <div className="stap-action mt-5">
                                            <button className="btn btn-outline-dark mr-2" onClick={this.handleEditBooking}>Edit Booking</button>
                                            <button className="btn btn-dark" onClick={()=>this.props?.rescheduleBooking()}>Confirm</button>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>

                            <Modal show={cancelBookingModal} onHide={this.handleClose} className="bg_white_modal text-center">
                                <i className="fa fa-times cm_hide_mod" onClick={this.handleClose} />
                                <Modal.Body>
                                    <div className="p-1 p-md-5">
                                        <img src={require('../../assets/images/cancel-booking-img.png')} className="img-fluid mb-3" />
                                        <h3 style={{fontSize: '38px', fontWeight: '400'}} className="mb-4 mdl-hdkng">Cancel Booking</h3>
                                        <p style={{fontSize: '23px', fontWeight: '300'}}>This will cancel the booking. Your client will be notified and sent an email. If your client payed in advance they will automatically be refunded.</p>

                                        <div className="stap-action mt-5">
                                            <button className="btn btn-outline-dark mr-2 mr-md-3" onClick={this.handleClose}>Cancel</button>
                                            <button className="btn btn-dark" onClick={() =>this.props?.cancelBooking()}>Confirm</button>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>

                            <Modal show={this?.props?.noShowModal} onHide={this?.props?.noShowPopup} className="no_show_modal text-center"
                            centered
                            size="lg">
                                <span className="cm_hide_mod pt-1 pr-3" onClick={this?.props?.noShowPopup} style={{top:'5px'}} ><Close/></span>
                                <Modal.Body>
                                    {/* <div className="p-1 p-md-5">
                                        <img src={require('../../assets/images/no-show-img.png')} className="img-fluid mb-3" />
                                        <h3 style={{fontSize: '38px', fontWeight: '400'}} className="mb-4 mdl-hdkng">No Show</h3>
                                        <p style={{fontSize: '23px', fontWeight: '300'}}>Client didn’t show? Sorry to hear that. This has been flagged on our system and we will take steps to ensure this client doesn’t fail to show up again.</p>

                                        <div className="stap-action mt-5">
                                            <button className="btn btn-outline-dark mr-2 mr-md-3" onClick={this.handleClose}>Cancel</button>
                                            <button className="btn btn-dark" onClick={() =>this.props?.noShowBooking()}>Confirm</button>
                                        </div>
                                       </div> */}
                                       <div className='p-1 p-md-3 _no_show_parent text-left'>
                                         <h3>Client No Show</h3>
                                        <h6>This appointment was booked online by <span>{this.props.bookings?.createdBy?.name}</span> at {this.showBookingDateTime()}.</h6>
                                        {
                                        (this?.props?.bookings?.stripePaymentMethodId!=undefined && this?.props?.bookings?.stripePaymentMethodId!='')?
                                        <p className='_paragrap'>The client accepted that if they did not show up for their appointment then they may be charged up to {this.props?.bookingState?.noShowFeeInPercentage?this.props?.bookingState?.noShowFeeInPercentage:0}% of the remaining appointment value.</p>:<p className='_paragrap'>Beu has a feature called no show protection,which requires a client to save their card details when making a booking. Enable this in booking settings to avoid loss of revenue for no shows.</p>}
                                        <h5 className={this.props?.bookingState?.totalPaymentAmount>0?'mb-2':""}>Service cost - {this.props?.currencySymbol}{parseFloat( this.props?.totalAmount).toFixed(2)}</h5>
                                           {this.props?.bookingState?.totalPaymentAmount>0?<h5>Deposit Already Paid - {this.props?.currencySymbol}{parseFloat( this.props?.bookingState?.totalPaymentAmount).toFixed(2)}</h5>:""}
                                      
                                        <div className={this.disableNoShowInput()}
                                         onClick={(e)=>(this?.props?.bookings?.stripePaymentMethodId!=undefined && this?.props?.bookings?.stripePaymentMethodId!='')?this?.props?.noShowOnChangeNoShow(1,'checkBox'):null
                                            }>
                                           <label className="radio-container ">
                                             <input type="radio" name="radi1"  checked={this.props?.noShowSelectedOption===1?true:false}/>
                                            <span className="checkmark"></span>
                                          </label>
                                          <div className='editable_currncy'>
                                                <p>Charge clients card </p>
                                                {/*{this.disableNoShowInput()=='transaction_option'?*/}
                                                    <div className={`currency_div mt-0 ml-0 `}>
                                                    <div className="text-center px-3 currency_sml">£</div>
                                                    <input type="number"
                                                      value={this?.props?.noShowFeeAmount}
                                                      onChange={(e)=>this?.props?.noShowOnChangeNoShow(e,'amount')}
                                                      className="text-center pl-3 currency_sml1 currency_sml_input edit_price_input" 
                                                      disabled={this.props?.noShowSelectedOption==2?true:false}
                                                      placeholder={'00.00'} 
                                                    />
                                                </div>
                                              {/*:null}*/}
                                          </div>
                                        </div>
                                        {this.props.noShowErrorStatus?<p className={'text-danger text-right mb-0 mr-4 noshow_error'}>Enter valid amount</p>:null}
                                        <div className={"transaction_option"}  onClick={(e)=>this?.props?.noShowOnChangeNoShow(2,'checkBox')}>
                                           
                                           <label className="radio-container ">
                                             <input type="radio" name="radi1" checked={this.props?.noShowSelectedOption===2?true:false}/>
                                            <span className="checkmark"></span>
                                            {/* <p>Mark no show, no charge </p> */}
                                          </label>
                                          <div className='editable_currncy'>
                                                <p>Mark no show, no charge</p>
                                          </div>
                                        </div>
                                        {this.props.noShowErrorStatus?<div className='text-right mt-4' style={{width:'95%'}}>
                                           <button class="btn btn-dark-blue disabled" >Confirm</button>
                                        </div>:<div className='text-right mt-4' style={{width:'95%'}}>
                                        {this.props?.noShowBookingProcess?<button className="btn btn-dark-blue" ><MySpinner /> </button>:<button className="btn btn-dark-blue" onClick={() =>this.props?.noShowBooking()}> Confirm</button>}
                                     </div>}
                                       </div>
                                </Modal.Body>
                            </Modal>


                        </div></>) : ""
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(ClientCheckout);

