import React, { Component } from "react";
import TopHeader from "../template/TopHeader";
import SideNav from "../template/SideNav";
import deleteImage from "../../assets/images/delete-product.png";
import { productAction } from "../../_actions/product.action";
import { connect } from "react-redux";

class DeleteProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClickCancel = () => {
    const { history } = this.props;
    history.push("/product");
  };

  deleteProduct = () => {
    const { history } = this.props;
    const productId = this.props?.location?.state?.productId;
    // console.log("productId",productId);
    if (productId) {
        this.props.dispatch(productAction.deleteProduct({productId: productId}))
        .then((response) => {
        //   console.log("response", response);
          history.push("/product");
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
  

  render() {
    return (
      <div>
        <div className="h_n">
          <TopHeader title="Edit Product" {...this.props} />
          <SideNav {...this.props} />
          <div className="product_div">
            <div className="product_main_div ">
              <div className="text-center py-5 mt-5">
                <img src={deleteImage} width={300} alt="Delete Product" />
              </div>
              <h3 className="py-3 pl-2">Delete Product</h3>
              <p className="pl-2 pr-2">
                Are you sure you want to delete this product? This will delete
                all data associated with this product, and once done this action
                cannot be undone.
              </p>
              <div className="text-center">
              <button
                onClick={this.handleClickCancel}
                className="product_cancel"
              >
                Cancel
              </button>
              <button className="product_delete" onClick={this.deleteProduct}>
                Delete
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(DeleteProduct);
