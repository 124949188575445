import React, { Component } from "react";
import {connect} from "react-redux";
import {paymentActions} from "../../_actions";

class RequestDemo extends Component {
    constructor(props){
        super(props);
        this.state = {
            email:'',
            name:'',
            phoneNo:'',
            message:'',
            demoSuccess:""
        }
    };
    onChanged=(e)=> {
        this.setState({
            [e.target.name]: e.target.value,
            demoSuccess:"",
            error:'',
            emailError:'',
            nameError:'',
            phoneNoError:'',});
    }
    requestDemo=()=>{
        this.setState({
            error:'',
            emailError:'',
            nameError:'',
            phoneNoError:'',
            demoSuccess:""
        })
        let self = this;
        let params = {};
        params.name=this.state.name;
        params.email=this.state.email;
        params.phoneNo=this.state.phoneNo;

        if (this.state.name === "" || this.state.name === undefined) {
            this.setState({
                nameError: 'Please enter name'
            })
        } else if (this.state.email === "" || this.state.email === undefined) {
            this.setState({
                emailError: "Please enter your email address"
            })
        } else if (!this.validateEmail(this.state.email)) {
            this.setState({
                emailError: 'Please enter a valid email address'
            })
        } else if (this.state.phoneNo === "" || this.state.phoneNo === undefined ) {  //|| !isValidPhoneNumber(this.state.phoneNumber)
            this.setState({
                phoneNoError: 'Please enter a valid phone number'
            })
        }
        else {
            self.props.dispatch(paymentActions.requestDemo(params))
                .then(function (response) {
                    //console.log('client response', response);

                    if (response.data.statusCode==0) {
                        if(response.data.error.errorCode===10){
                            self.setState({
                                error: response.data.error.responseMessage
                            })
                        }else{
                            self.setState({
                                error: response.data.error.responseMessage
                            })
                        }
                    } else {
                        self.setState({
                            email:'',
                            name:'',
                            phoneNo:'',
                            message:'',
                            demoSuccess:"The request has been sent successfully"
                        })
                    }
                })
                .catch((err) => {
                    console.log("errr",err);
                    //dispatch(failure(err));
                })
        }
    }
    validateEmail=(email)=> {
        const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const result = pattern.test(email);
        return result;
    }
    render() {
        return (
            <div className="container">
                  <div className="row">
                        <div className="col-sm-6 offset-sm-3">
                            <div className="request-form">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Name"
                                            name="name" autoComplete="off"  onChange={this.onChanged} value={this.state.name} />
                                    {this.state.nameError ?
                                        <span className="cm_error_msg">{this.state.nameError}</span> : ''}
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Phone Number"
                                            name="phoneNo" autoComplete="off"  onChange={this.onChanged} value={this.state.phoneNo} />
                                    {this.state.phoneNoError ?
                                        <span className="cm_error_msg">{this.state.phoneNoError}</span> : ''}
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Email"
                                            name="email" autoComplete="off"  onChange={this.onChanged} value={this.state.email} />
                                    {this.state.emailError ?
                                        <span className="cm_error_msg">{this.state.emailError}</span> : ''}
                                </div>
                                {this.state.error ?
                                    <span className="cm_error_msg">{this.state.error}</span> : ''}
                                {this.state.demoSuccess ?
                                    <span className="cm_succ_msg w-100 d-block mt-2">{this.state.demoSuccess}</span> : ''}
                                <br/>
                                <button className="btn btn-blue btn-block" onClick={this.requestDemo} >Request a Demo</button>
                            </div>
                     </div>
                  </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(RequestDemo);