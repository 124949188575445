import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import moment from 'moment';
import { Tab, Tabs, Dropdown, Modal } from 'react-bootstrap';
import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import ClientWalkIn from './ClientWalkIn';
import ClientCheckout from './ClientCheckout';
import Checkout from './Checkout';
import { connect } from "react-redux";
import {bookingActions, hairdresserActions, paymentActions, userActions, productAction} from "../../_actions";
import siteSetting from "../../config/env";
import { Addicon } from "../SvgIcon";
import dateFormat from "dateformat";
import toast from 'react-toast-notification';
import FooterBtn from './FooterBtn';
import NewAppointment from './NewAppointment';
import NewInvoice from './NewInvoice';
import Lottie from 'react-lottie';
import animationData from '../../assets/animation-data/loader.json';
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

var _ = require('lodash');
const isMobile = (window.innerWidth <= 768);
let userIdHolder = [];
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

function getslotTime(time) {
    let hr = parseInt(time / 60);
    let min = time - (hr * 60)
    return hr + ':' + (min === 0 ? '00' : min);
}

function debounce(func, delay) {
    let timer;
    return function() {
      const context = this;
      const args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay);
    };
  }


class NewBooking extends Component {

    constructor(props) {
        super(props);
// console.log("moment(this.props.location.state.bookingDate, 'yyyy-mm-dd')",new Date(this.props.location.state.bookingDate),this.props.location.state.bookingDate)
        this.state = {
            bookingDate: this.props.location.state&&this.props.location.state.bookingDate?new Date(this.props.location.state.bookingDate):new Date(),
            selectSlot: this.props.location.state?this.props.location.state.start:'',
            showOptionsModal: false,
            isSelected: false,
            showTipModal: false,
            latitude: siteSetting.default_latitude,
            longitude: siteSetting.default_longitude,
            hairdresserName: 'Select Staff Member',
            selectedService: [],
            finalBookingSlots: [],
            includeTimes: [],
            slotInfo: {},
            totalAmount: 0,
            totalTime: 0,
            hairdresserId: this.props.match.params.hairdresserId?this.props.match.params.hairdresserId:'',
            selectedClientId: '',
            selectedClient: {},
            isSaveBooking: 0,
            bookingSlots: [],
            paymentType: '',
            isBookingAllowed: false,
            searchKey: '',
            selectTab:1,
            pendingAmount:0,
            transaction:[],
            paymentAmount:0,
            totalPaymentAmount:0,
            isInvoice:0,
            eventError:'',
            startTimeError:'',
            serviceError:'',
            bookingError:'',
            eventStartTime:0,
            eventEndTime:0,
            eventTitle:'',
            eventTitleError:'',
            hairdresserIdEventError:'',
            currencySymbol:"£",
            isSelectClient:false,
            mobileSelectedStep:0,
            selectedPaymentType:null,
            selectedAmount:'',
            checkOutAmountType:true,
            selectedFixedAmount:0,
            isPaymentComplete:0,
            bookingID:'',
            isMobileEnableState:true,
            locationId:'',
            selectedCardReader:{}
        }
        this.foundLocation = this.foundLocation.bind(this);
        this.selectClient = this.selectClient.bind(this);
        this.changeClient = this.changeClient.bind(this);
        this.saveBooking = this.saveBooking.bind(this);
        this.selectDay = this.selectDay.bind(this);
        this.bookingSlotCreate = this.bookingSlotCreate.bind(this);
        this.selectSlot = this.selectSlot.bind(this);
        this.changeHairdresser = this.changeHairdresser.bind(this);
        this.createBooking = this.createBooking.bind(this);
        this.serPaymentType = this.serPaymentType.bind(this);
        this.resetBooking = this.resetBooking.bind(this);
        this.searchClient=this.searchClient.bind(this);
        this.selectTab=this.selectTab.bind(this);
        this.checkoutBooking=this.checkoutBooking.bind(this);
        this.onChanged=this.onChanged.bind(this);
        this.selectService=this.selectService.bind(this);
        this.completeSale=this.completeSale.bind(this);
        this.checkReadOnly=this.checkReadOnly.bind(this);
        this.debouncedFunction = this.debouncedFunction.bind(this);
       this.debouncedSearch = debounce(this.debouncedFunction, 1000);
    }

    

    onChanged(e) {
        console.log("e",e.target.name,e.target.value);
        this.setState({ [e.target.name]: parseFloat(e.target.value)});

        if (e.target.name === 'pendingAmount') {
            let totalPaymentAmount=parseFloat(this.state.totalPaymentAmount)+parseFloat(e.target.value);
            let pendingAmount=parseFloat(this.state.totalAmount)-totalPaymentAmount;
            if (parseFloat(e.target.value) > pendingAmount) {
                this.setState({
                    paymentAmountError: 'Invalid Amount'
                })
            } else {
                this.setState({
                    paymentAmountError: '',
                })
            }
        }
    }


    handleOptionModal = () => this.setState({ showOptionsModal: !this.state.showOptionsModal })

    handleTipModal = () => {
        this.setState({
            showOptionsModal: false,
            showTipModal: !this.state.showTipModal
        })
    }

    componentDidMount() {
        var self = this;
        let params = {
            defaultRedirectTo:localStorage.getItem('defaultRedirectTo')
        };

        let params1 = {
            searchKey:this.state.searchKey,
            'hairdresserId':localStorage.getItem('userId'),
            page:1,
            listType:1
        };

        self.props.dispatch(productAction?.selectedClient({}));
        self.props.dispatch(productAction?.selectedCheckoutList([]));
      
        if (localStorage.getItem('accessToken')) {
            self.props.dispatch(userActions.profile(params))
            .then(function (response) {
                //console.log("response",response.data.data);
                if(response.data.data) {
                    self.setState({
                        locationId:response.data.data.userInfo.hairdresser.stripeLocationId?response.data.data.userInfo.hairdresser.stripeLocationId:""
                    })
                }
            })
            self.cardReaderList();
            self.props.dispatch(hairdresserActions.getHairdresserClient({ history: this.props.history, 'searchKey': this.state.searchKey, 'hairdresserId': localStorage.getItem('userId'), page: 1, listType: 1 }))
            self.props.dispatch(hairdresserActions.hairdresserStaffRota(params1))
                .then(function (response) {
                    // console.log("response",response);
                    // response.data.data.rota.map((hairdresser,i)=>{
                    //     self.getHairdresserScheduleList(hairdresser._id,hairdresser,i);
                    // })
                    if(self.props.match.params.hairdresserId){
                        let selectedHairdresser = _.find(response.data.data.rota, function (hairdresser) {
                            return hairdresser._id === self.props.match.params.hairdresserId;
                        })
                        self.setState({
                            hairdresserName:selectedHairdresser.name
                        })
                        self.changeHairdresser(self.props.match.params.hairdresserId,selectedHairdresser.name,1,selectedHairdresser);
                        self.selectDay(self.state.bookingDate);
                    }
                })

                // console.log("self props",self.props);
                // console.log("self state",self.state);
            
        } else {
            this.props.history.push('/')
        }

        let { dispatch } = this.props;
        //navigator.geolocation.getCurrentPosition(this.foundLocation, this.noLocation.bind(this))
        // window.scrollTo(0, 0);
    }

    foundLocation(location) {

        this.setState({
            currentLocation: location.coords,
            latitude: location.coords.latitude ? location.coords.latitude : siteSetting.default_latitude,
            longitude: location.coords.longitude ? location.coords.longitude : siteSetting.default_longitude,
        })
        let { dispatch } = this.props;
        let self = this;
        let params = {}
        let salonId = this.props.match.params.salonId;

        if (salonId) {
            params.salonId = salonId;
            params.latitude = location.coords.latitude ? location.coords.latitude : siteSetting.default_latitude;
            params.longitude = location.coords.longitude ? location.coords.longitude : siteSetting.default_longitude;
            self.props.dispatch(hairdresserActions.getSalonProfile(params))
                .then(function (response) {
                    if (!response.data.data.salons) {
                        self.props.history.push("/404");
                    }

                        params.hairdresserId = response.data.data.salons.hairdresser[0]._id;
                        self.props.dispatch(hairdresserActions.getHairdresserInfo(params))
                            .then(function (response) {

                                if (response.data.error) {
                                    self.setState({
                                        message: response.data.error.message,

                                    })
                                } else {
                                }
                            });

                    if (response.data.error) {
                        self.setState({
                            message: response.data.error.message
                        })
                    } else {
                    }
                });

        } else {
            this.props.history.push("/404");
        }
    }

    noLocation() {
        let { dispatch } = this.props;
        let self = this;
        let params = {}
        let salonId = this.props.match.params.salonId;
        if (salonId) {
            params.salonId = salonId;
            params.latitude = siteSetting.default_latitude;
            params.longitude = siteSetting.default_longitude;
            self.props.dispatch(hairdresserActions.getSalonProfile(params))
                .then(function (response) {
                    if (!response.data.data.salons) {
                        self.props.history.push("/404");
                    }

                        params.hairdresserId = response.data.data.salons.hairdresser[0]._id;
                        self.props.dispatch(hairdresserActions.getHairdresserInfo(params))
                            .then(function (response) {

                                if (response.data.error) {
                                    self.setState({
                                        message: response.data.error.message,

                                    })
                                } else {
                                }
                            });

                    if (response.data.error) {
                        self.setState({
                            message: response.data.error.message
                        })
                    } else {
                    }
                });

        } else {
            this.props.history.push("/404");
        }
    }

    selectTab(selectTab){
        let self=this;
        this.setState({
            selectTab:selectTab==="Booking"?1:2,
            bookingDate: this.props.location.state&&this.props.location.state.bookingDate?new Date(this.props.location.state.bookingDate):new Date(),
            selectSlot: '',
            showOptionsModal: false,
            isSelected: false,
            showTipModal: false,
            latitude: siteSetting.default_latitude,
            longitude: siteSetting.default_longitude,
            hairdresserName: 'Select Staff Member',
            selectedService: [],
            finalBookingSlots: [],
            includeTimes: [],
            slotInfo: {},
            totalAmount: 0,
            totalTime: 0,
            hairdresserId: this.props.match.params.hairdresserId?this.props.match.params.hairdresserId:'',
            selectedClientId: '',
            selectedClient: {},
            isSaveBooking: 0,
            bookingSlots: [],
            paymentType: '',
            isBookingAllowed: false,
            searchKey: '',
            pendingAmount:0,
            transaction:[],
            paymentAmount:0,
            totalPaymentAmount:0,
            isInvoice:0,
            eventError:'',
            startTimeError:'',
            serviceError:'',
            bookingError:'',
            eventStartTime:0,
            eventEndTime:0,
            eventTitle:'',
            eventTitleError:'',
            hairdresserIdEventError:'',
            currencySymbol:"£",
            isSelectClient:false,
            mobileSelectedStep:0,
            selectedPaymentType:2,
            selectedAmount:0,
            checkOutAmountType:true,
            selectedFixedAmount:0,
            isPaymentComplete:0,
            isLoaderEnabled:false
        });



    }

    changeHairdresser(hairdresserId, hairdresserName,type,hairdresser) {
        this.setState({isLoaderEnabled: true})
        this.setState({isSelected: true})
        this.setState({ startTimeError:'',serviceError:'',hairdresserId, hairdresserName })
        // console.log("salonId",salonId);
        //this.props.history.push("/salon/" + salonId + "/professional/" + hairdresserId);
        let params = {};
        let self = this;
        params.hairdresserId = hairdresserId;
        params.latitude = this.state.latitude;
        params.longitude = this.state.longitude;
        // params.history = this.props.history;
        params.slotsForBookingDate=dateFormat(this.state.bookingDate?this.state.bookingDate:new Date(),"yyyy-mm-dd")
        if(type===1){
            //self.props.dispatch(hairdresserActions.getHairdresserClient({ history: this.props.history, 'searchKey': this.state.searchKey, 'hairdresserId': hairdresserId, page: 1, listType: 1 }))
        }

        self.props.dispatch(hairdresserActions.getHairdresserInfo(params))
            .then(function (response) {

                if (response.data.errorCode) {
                    self.setState({
                        isLoaderEnabled: false,
                        error: response.data.error.messages
                    })
                } else {
                    self.setState({
                        isLoaderEnabled: false,
                        bookingSlots: response.data.data.bookingSlots,
                        date: new Date(),
                        selectedService: [],
                        isBookingButton: false,
                        selectedDay: new Date(),
                        month: monthNames[new Date().getMonth()],
                        year: new Date().getFullYear(),
                        totalAmount: 0,
                        totalTime: 0,
                        selectedBookingSlots: '',
                        selectedBookingSlotsIndex: '',
                        finalBookingSlots: [],
                        includeTimes: [],
                        pendingAmount:0,
                        transaction:[],
                        paymentAmount:0,
                        totalPaymentAmount:0,
                        currencySymbol:hairdresser.hairdresserInfo.currencySymbol&&hairdresser.hairdresserInfo.currencySymbol!=""&&hairdresser.hairdresserInfo.currencySymbol!="undefined"?hairdresser.hairdresserInfo.currencySymbol:"£"
                    }, function () {
                        self.bookingSlotCreate(0);
                    })
                }
            });
    }

    checkSelectedService(serviceId) {
        let selectedService = this.state.selectedService;
        let isCheck = _.find(selectedService, function (o) {
            return o._id === serviceId;
        })
        //console.log('isCheck',serviceId,isCheck)
        if (isCheck) {
            return true;
        } else {
            return false;
        }
    }

    selectService(value, service, selection) {
        this.setState({
            serviceError:''
        })
        //console.log('value', value, service, selection);
        let self = this;
        let totalAmount;
        let totalTime;
        let selectedService = this.state.selectedService;
        //console.log('selectedService', selectedService);
        if (value === 1) {

            if (selection === true) {
                selectedService.push(service);
                totalAmount = service.servicePrice + this.state.totalAmount;
                totalTime = this.state.totalTime + service.serviceTime
            } else {
                //selectedService.pop(service);
                _.remove(selectedService, item => item === service);
                totalAmount = this.state.totalAmount - service.servicePrice;
                totalTime = this.state.totalTime - service.serviceTime
            }
            let pendingAmount=totalAmount;
            //console.log('totalAmount',totalAmount);
            this.setState({
                selectedService,
                totalAmount,
                totalTime,
                pendingAmount,
                selectedFixedAmount:pendingAmount,
                //selectedBookingSlotsIndex: '',
                //finalBookingSlots: [],
                //includeTimes: [],
                transaction:[],
                totalPaymentAmount:0
            }, function () {
                self.bookingSlotCreate(0);
                self.isMobileEnable();
                if (self.state.selectedService.length > 0 && self.state.slotInfo.startTime) {
                    self.setState({
                        isBookingButton: true,
                    })
                } else {
                    self.setState({
                        isBookingButton: false,
                    })
                }
            })
        } else {
            self.setState({
                finalBookingSlots: [],
                includeTimes: [],
                selectedService: [],
                totalAmount: 0,
                totalTime: 0,
            },function (){
                self.isMobileEnable();
            })
        }
    }

    selectClient(clientId, client) {
        let self=this;
        if(this.state.selectSlot===''){
            this.setState({
                startTimeError:'Please select start time'
            })
        }else if(this.state.totalTime===0){
            this.setState({
                serviceError:'Please select service'
            })
        }else{
            this.setState({ selectedClientId: clientId, selectedClient: client },function (){
                self.isMobileEnable();
            })
        }
        // console.log("selectCLient",clientId)

    }

    saveBooking() {
        if(this.state.selectSlot===''){
            this.setState({
                startTimeError:'Please select start time'
            })
        }else if(this.state.totalTime===0){
            this.setState({
                serviceError:'Please select service'
            })
        }else {
            this.setState({
                startTimeError:'',
                serviceError:''
            })
            this.createBooking(0);
        }
    }

    completeBooking=()=>{
        let self=this;
        let params={
            bookingID:self.state.bookingID
        };
        self.props.dispatch(bookingActions.completeBooking(params))
            .then(function (response) {
                console.log('booking complete response', response);
                let info={
                    bookingDate:self.state.bookingDate
                };
                self.props.history.push({pathname:'/diary/' + localStorage.getItem('salonId'),state:info})
            })
    }


    bookingSlotCreate(index) {
        //console.log("bookingSlotCreate",index);
        this.setState({
            startTimeError:'',
            serviceError:''
        })
        let self = this;
        let finalBookingSlots = [];
        let includeTimes = [];
        let pre;
        let next;
        let tempArr = []
        let flag = false;
        let k = index;
        let arrslots = [];
        let bookingSlots = self.state.bookingSlots;
        //bookingSlots.map(function(slots, i) {
        for (let i = index; i < bookingSlots.length; i++) {
            // console.log(index,bookingSlots.length,i, self.state.totalTime, bookingSlots[i],bookingSlots[i].status);
            let check = false;
            if (bookingSlots[i].status === 0||bookingSlots[i].status === 3) {
                tempArr.push(bookingSlots[i])
                // console.log('tempArr', tempArr,bookingSlots[i]);
                if (((tempArr[tempArr.length - 1]["endTime"]) - (tempArr[0]["startTime"])) === self.state.totalTime) {

                    finalBookingSlots.push(tempArr[0]);

                    let hr = parseInt(tempArr[0].startTime / 60);
                    let min = tempArr[0].startTime - (hr * 60);

                    let incTimeSlot = new Date(self.state.bookingDate);
                    //console.log("incTimeSlot",incTimeSlot);
                    incTimeSlot.setHours(hr);
                    incTimeSlot.setMinutes(min);


                    includeTimes.push(incTimeSlot);
                    //console.log("tempArr[0]",incTimeSlot,includeTimes)

                    for (let m = 0; m < bookingSlots.length; m++) {
                        if (tempArr[0]["startTime"] === bookingSlots[m]["startTime"]) {
                            k = m + 1;
                            flag = true;
                            break;
                        }
                    }
                    tempArr = []
                }
            } else {
                tempArr = []
            }

            if (flag === true) {
                break
            }
        }
        if (flag && k < bookingSlots.length) {
            self.bookingSlotCreate(k)
        }
        //console.log('finalBookingSlots', finalBookingSlots);
        if (finalBookingSlots[0]) {
            let finalslot = this.state.finalBookingSlots;
            finalslot.unshift(finalBookingSlots[0]);

            let incTimes = this.state.includeTimes;
            //console.log('incTimes', incTimes);
            incTimes.unshift(includeTimes[0]);
            // console.log('finalslot', finalslot);
            let morningCount = 0;
            let afternoonCount = 0;
            let eveningCount = 0;
            for (let i = 0; i < finalslot.length; i++) {
                let bookingSlot = finalslot[i];
                let bookingIncTimes = incTimes[i];
                if (bookingSlot) {
                    if (bookingSlot.status === 0 && bookingSlot.startTime < 720) {
                        morningCount = 1
                    }
                    if (bookingSlot.status === 0 && (bookingSlot.startTime >= 720 && bookingSlot.startTime < 1020)) {
                        afternoonCount = 1
                    }
                    if (bookingSlot.status === 0 && bookingSlot.startTime >= 1020) {
                        eveningCount = 1
                    }
                }

            }

            let selectedSlot = _.find(finalslot, function (slot) {
                let incTimeSlot = new Date(self.state.selectSlot);
                //console.log("incTimeSlot",incTimeSlot);
                let hr =incTimeSlot.getHours();
                let min =incTimeSlot.getMinutes();
                return slot.startTime === hr*60+min;
            })
            if(!selectedSlot){
                selectedSlot=finalslot[0];
                self.setState({
                    selectSlot: self.state.selectSlot?self.state.selectSlot:incTimes[0]
                })
            }
            //console.log("selectedHairdresser",selectedHairdresser);
            this.setState({
                finalBookingSlots: finalslot,
                includeTimes: incTimes,
                morningCount,
                afternoonCount,
                eveningCount,
                slotInfo: selectedSlot,
                //selectSlot: incTimes[0]
            })
        }
    }

    selectDay(e) {
        this.setState({
            startTimeError:'',
            serviceError:''
        })
        console.log('selectDay', e);
        this.state.bookingDate = e[0];
        this.state.selectedDay = e[0];
        //console.log(this.state.bookingDate)
        //this.setState({bookingDate:e[0]})
        let params = {};
        let self = this;
        let sdate = new Date(e[0])
        self.setState({
            bookingDate: e,
            month: monthNames[sdate.getMonth()],
            year: sdate.getFullYear()
        })
        let salonId = this.props.match.params.salonId;
        let hairdresserId = this.state.hairdresserId?this.state.hairdresserId:localStorage.getItem('userId');
        params.hairdresserId = hairdresserId;
        params.latitude = this.state.latitude
        params.longitude = this.state.longitude
        params.slotsForBookingDate = dateFormat(e, 'yyyy-mm-dd');
        self.props.dispatch(hairdresserActions.getHairdresserInfo(params))
            .then(function (response) {

                if (response.data.error) {
                    self.setState({
                        message: response.data.error.message
                    })
                } else {
                    console.log(response);
                    self.setState({
                        bookingSlots: response.data.data.bookingSlots,
                        selectedBookingSlotsIndex: '',
                        finalBookingSlots: [],
                        includeTimes: []
                    }, function () {
                        self.bookingSlotCreate(0);
                    })
                }
            });
    }

    unselectDay(e) {
        console.log('unselectDay', e);
    }

    getslotTime(time) {
        let hr = parseInt(time / 60);
        let min = time - (hr * 60)
        return (hr <= 9 ? '0' + hr : hr) + ':' + (min == 0 ? '00' : (min <= 9 ? '0' + min : min)) + ":00";
    }

    setHours(date, hr) {

    }

    setMinutes(date, min) {

    }

    selectSlot(e) {
        this.setState({
            startTimeError:'',
            serviceError:''
        })
        console.log("e", e);
        let self = this;
        this.setState({
            finalBookingSlots: [],
            includeTimes: []
        }, function () {
            self.bookingSlotCreate(0);
            //console.log('slotInfo,index',slotInfo,index,isSelect);
            let includeTimes = self.state.includeTimes;
            let finalBookingSlots = self.state.finalBookingSlots;
            let totalTime = self.state.totalTime;

            _.remove(includeTimes, function (o) {
                return o <= moment(e).add(totalTime, 'm').toDate() && o > e
            });

            let m = moment(e);
            let minutes = (m.hour() * 60) + m.minute();
            let slotInfo = _.find(finalBookingSlots, function (o) {

                return o.startTime === minutes
            })
            //finalBookingSlots=res;
            // console.log('finalBookingSlots',res,slotInfo,minutes);
            self.setState({
                slotInfo: slotInfo,
                includeTimes,
                selectSlot: e
            }, function () {

            })

        })
    }

    createBooking(param) {
        let self = this;
        console.log('createBooking');
        // console.log(localStorage.getItem('accessToken'))

        console.log("================>",this.state.selectedService);

        let params = {};
        params.servicesToBook = _.map(this.state.selectedService, function (o) {
            return o.serviceId;
        })
        params.userId = this.state.hairdresserId;
        params.bookingSlot = [this.state?.slotInfo?.startTime, (this.state?.slotInfo?.startTime + this.state?.totalTime)]
        params.bookingDate = dateFormat(this.state?.bookingDate, 'yyyy-mm-dd')
        params.deviceToken = "dfdsfdsfsd"
        params.isPayAtSalonBooking = 1;
        params.clientId = this.state.selectedClientId;
        params.paidType = this.state.paidType;
        params.transaction = this.state.transaction;
        params.totalAmount = this.state.totalAmount;
        params.invoiceNumber = localStorage.getItem("invoiceNumber")
        self.props.dispatch(bookingActions.createBooking(params))
            .then(function (response) {
                console.log('booking response', response);


                if (response.data.errorCode) {
                    toast.error(response.data.messages)
                    // self.setState({
                    //     bookingError: response.data.messages
                    // })
                } else {
                    if (param === 0) {
                        let info={
                            bookingDate:self.state.bookingDate
                        };
                        self.props.history.push({pathname:'/diary/' + localStorage.getItem('salonId'),state:info})
                    } else if (param === 2) {
                        self.setState({isInvoice: 1, mobileSelectedStep: 5,bookingID:response.data.data.bookingInfo._id})
                    }else if (param === 5) {
                        //self.props.history.push('/checkout/'+response.data.data.bookingInfo._id)
                    const stateObj = { pageLoadingStatus:1};
                    self.props.history.push({
                       pathname: '/checkout/'+response.data.data.bookingInfo._id,
                       state: stateObj
                     });
                        // self.props.history.push('/booking-checkout/'+response.data.data.bookingInfo._id)
                    } else {
                        //self.props.history.push('/newbooking/' + localStorage.getItem('salonId'))
                        window.location.reload();
                    }
                }
            })
    }

    serPaymentType(paymentType) {
        if(this.state.selectSlot===''){
            this.setState({
                startTimeError:'Please select start time'
            })
        }else if(this.state.totalTime===0){
            this.setState({
                serviceError:'Please select service'
            })
        }else {
            let transaction = this.state.transaction;

            transaction.push({
                paymentType: paymentType,
                amount: parseFloat(this.state.pendingAmount)
            })
            let totalPaymentAmount = parseFloat(this.state.totalPaymentAmount) + parseFloat(this.state.pendingAmount);
            let pendingAmount = parseFloat(this.state.totalAmount) - totalPaymentAmount;
            this.setState({
                totalPaymentAmount,
                transaction: transaction,
                pendingAmount: pendingAmount,
                selectedPaymentType:paymentType
            })
        }
    }

    setPaymentType=(paymentType)=> {
        if(this.state.selectSlot===''){
            this.setState({
                startTimeError:'Please select start time'
            })
        }else if(this.state.totalTime===0){
            this.setState({
                serviceError:'Please select service'
            })
        }else {
            this.setState({
                selectedCardReader:{},
                selectedPaymentType: paymentType
            })
        }
    }

    resetBooking() {
        this.setState({
            bookingDate: this.props.location.state&&this.props.location.state.bookingDate?new Date(this.props.location.state.bookingDate):new Date(),
            selectSlot: '',
            showOptionsModal: false,
            isSelected: false,
            showTipModal: false,
            latitude: siteSetting.default_latitude,
            longitude: siteSetting.default_longitude,
            hairdresserName: 'Select Staff Member',
            selectedService: [],
            finalBookingSlots: [],
            includeTimes: [],
            slotInfo: {},
            totalAmount: 0,
            totalTime: 0,
            hairdresserId: this.props.match.params.hairdresserId?this.props.match.params.hairdresserId:'',
            selectedClientId: '',
            selectedClient: {},
            isSaveBooking: 0,
            bookingSlots: [],
            paymentType: '',
            isBookingAllowed: false,
            searchKey: '',
            selectTab:1,
            pendingAmount:0,
            transaction:[],
            paymentAmount:0,
            totalPaymentAmount:0,
            isInvoice:0,
            eventError:'',
            startTimeError:'',
            serviceError:'',
            bookingError:'',
            eventStartTime:0,
            eventEndTime:0,
            eventTitle:'',
            eventTitleError:'',
            hairdresserIdEventError:'',
            currencySymbol:"£",
            isSelectClient:false,
            mobileSelectedStep:0,
            selectedPaymentType:2,
            selectedAmount:0,
            checkOutAmountType:true,
            selectedFixedAmount:0,
            isPaymentComplete:0,
        })
    }

    changeClient() {
        this.setState({
            selectedClientId: '',
            selectedClient: {},
            isSaveBooking: 0,
            paymentType: '',
            searchKey: ''
        })
    }

    handleEvent(e, d) {
        if (e === "eventStartTime") {
            let m = moment(d);
            let minutes = (m.hour() * 60) + m.minute();
            this.setState({ eventTime: d, eventStartTime: minutes })
        }
        if (e === "eventEndTime") {
            let m = moment(d);
            let minutes = (m.hour() * 60) + m.minute();
            this.setState({ endTime: d, eventEndTime: minutes })
        }
        if (typeof e === "object" && e.target.id === "eventTitle") {
            this.setState({ eventTitle: e.target.value })
        }
        if (typeof e === "object" && e.target.id === "isBookingAllowed") {
            this.setState({ isBookingAllowed: !this.state.isBookingAllowed })
        }
    }

    submitEvent = () => {
        this.setState({
            eventStartTimeError:'',
            eventEndTimeError:'',
            eventTitleError:'',
            hairdresserIdEventError:''
        })
        let self = this;
        let params = {
            'eventTitle': self.state.eventTitle,
            'eventDate': dateFormat(self.state.bookingDate, 'yyyy-mm-dd'),
            'eventStartTime': self.state.eventStartTime,
            'eventEndTime': self.state.eventEndTime,
            'isBookingAllowed': self.state.isBookingAllowed ? 1 : 0,
            'userId': self.state.hairdresserId,
            'deviceToken': "dfdsfdsfsd",
            'repeatType': "doesNotRepeat"   //'doesNotRepeat', 'everyDay', 'everyWeek', 'everyMonth', 'everyYear'
        }
        if(self.state.eventStartTime===''||self.state.eventStartTime===0){
            this.setState({
                eventStartTimeError:'Please select start time'
            })
        }else if(self.state.eventEndTime===0||self.state.eventEndTime===''){
            this.setState({
                eventEndTimeError:'Please select end time'
            })
        }else if(self.state.eventTitle===''){
            this.setState({
                eventTitleError:'Please enter event title'
            })
        }else if(self.state.hairdresserId===''){
            this.setState({
                hairdresserIdEventError:'Please select any hairdresser'
            })
        }else {
            self.props.dispatch(bookingActions.createEvent(params))
                .then(function (response) {
                    console.log('response', response);
                    if (response.data.errorCode) {
                        self.setState({
                            eventError: response.data.messages
                        })
                    } else {
                        let info={
                            bookingDate:self.state.bookingDate
                        };
                        self.props.history.push({pathname:'/diary/' + localStorage.getItem('salonId'),state:info})
                        //self.props.history.push('/diary/' + localStorage.getItem('salonId'))
                        //window.location.reload();
                    }
                })
        }
    }

    debouncedFunction() {
        let self = this;
        self.props.dispatch(hairdresserActions.getHairdresserClient({ history: this.props.history, 'searchKey': this.state.searchKey, 'hairdresserId': localStorage.getItem('userId'), page: 1, listType: 1 }));
      }

    searchClient(e){
        let self=this;
        self.setState({
            searchKey: e.target.value
        },function () {
            this.debouncedSearch();
        })
    }

    
    checkoutBooking(){
        if(this.state.selectSlot===''){
            this.setState({
                startTimeError:'Please select start time'
            })
        }else if(this.state.totalTime===0){
            this.setState({
                serviceError:'Please select service'
            })
        }else {
            // this.setState({
            //     isSaveBooking: 1,
            //     mobileSelectedStep:4
            // })
            //this.saveBooking(1);
            this.createBooking(5);
        }
    }

    checkCompleteSale=()=>{
        if(this.state.pendingAmount==0){
            return false;
        }
        if(this.state.selectedPaymentType!==null){
            return false;
        }
        return true;
    }

    completeSale(){
        this.setState({
            paymentAmountError: ''
        })
        let self=this;
        if(this.state.selectSlot===''){
            this.setState({
                startTimeError:'Please select start time'
            })
        }else if(this.state.totalTime===0){
            this.setState({
                serviceError:'Please select service'
            })
        }
        // else if(parseFloat(this.state.selectedAmount)<=0){
        //     console.log("parseFloat(this.state.selectedAmount)",parseFloat(this.state.selectedAmount))
        //     this.setState({
        //         paymentAmountError:'Please enter some amount'
        //     })
        // }
        else {
            if(this.state.pendingAmount===0) {
                if(this.state.isPaymentComplete==0){
                    this.setState({
                        isPaymentComplete:1
                    })
                }else {
                    localStorage.setItem("invoiceNumber", moment().formatals("YYYYMMDDHHmmSS"));
                        this.createBooking(2);
                    this.setState({isInvoice: 1, mobileSelectedStep: 5})
                }
            }else{
                let transaction = this.state.transaction;
                console.log('checkpendingAmount 3');
                if(this.state.checkOutAmountType==true){
                    transaction.push({
                        paymentType: self.state.selectedPaymentType,
                        amount: parseFloat(this.state.pendingAmount)
                    })
                    let totalPaymentAmount = parseFloat(this.state.totalPaymentAmount) + parseFloat(this.state.pendingAmount);
                    let pendingAmount = parseFloat(this.state.totalAmount) - totalPaymentAmount;
                    let isPaymentComplete=0;
                    if(pendingAmount===0){
                        isPaymentComplete=1;
                    }

                    this.setState({
                        totalPaymentAmount,
                        transaction: transaction,
                        pendingAmount: pendingAmount,
                        selectedPaymentType:null,
                        isPaymentComplete
                    })
                }else {
                    if (parseFloat(this.state.selectedAmount) > (parseFloat(this.state.totalAmount)-parseFloat(this.state.totalPaymentAmount))) {
                        this.setState({
                            paymentAmountError: 'Please enter valid amount'
                        })
                    }else if (this.state.selectedAmount ==0) {
                        this.setState({
                            paymentAmountError: 'Please enter valid amount'
                        })
                    }else if (this.state.selectedAmount > 0) {
                        transaction.push({
                            paymentType: self.state.selectedPaymentType,
                            amount: parseFloat(this.state.selectedAmount)
                        })
                        let totalPaymentAmount = parseFloat(this.state.totalPaymentAmount) + parseFloat(this.state.selectedAmount);
                        let pendingAmount = parseFloat(this.state.totalAmount) - totalPaymentAmount;
                        let isPaymentComplete=0;
                        if(pendingAmount===0){
                            isPaymentComplete=1;
                        }
                        this.setState({
                            totalPaymentAmount,
                            transaction: transaction,
                            pendingAmount: pendingAmount,
                            selectedAmount:'',
                            selectedPaymentType:null,
                            checkOutAmountType:true,
                            isPaymentComplete
                        })
                    }
                }
            }
        }
    }

    checkReadOnly(){
        if(this.props.user&&this.props.user.userInfo&&this.props.user.userInfo.hairdresser.isSalonOwner===1){
            return true;
        }else{
            return false;
        }
    }
    mobileCancel=()=>{
        let self=this;
        if(this.state.mobileSelectedStep>0){
            this.setState({
                mobileSelectedStep:this.state.mobileSelectedStep-1
            },function (){
                self.isMobileEnable();
            })
        }else{
            self.props.history.push('/diary/' + localStorage.getItem('salonId'))
        }
    }
    mobileContinue=()=>{
        let self=this;
        this.setState({
            mobileSelectedStep:this.state.mobileSelectedStep+1
        },function (){
            self.isMobileEnable();
        })
    }
    isMobileEnable=()=>{
        let self=this;
        if(this.state.mobileSelectedStep===0){
            if(this.state.selectedService.length>0){
                self.setState({
                    isMobileEnableState:false
                })
            }else{
                self.setState({
                    isMobileEnableState:true
                })
            }

        }else if(this.state.mobileSelectedStep===2){
            if(this.state.selectedClientId!==''){
                self.setState({
                    isMobileEnableState:false
                })
            }else{
                self.setState({
                    isMobileEnableState:true
                })
            }
        }
        // self.setState({
        //     isMobileEnableState:true
        // })
    }
    selectExistingClient=()=>{
        let self=this;
        this.setState({
            mobileSelectedStep: 2
        },function (){
            self.isMobileEnable();
        })
    }
    selectNewClient=()=>{
        this.props.history.push('/clients/booking/add');
        // this.props.history.push('/clients/add');
        // this.setState({
        //     mobileSelectedStep: 3
        // })
    }
    selectBookingAmount=(type)=>{
        let self=this;
        if(type==1){
            self.setState({
                checkOutAmountType:true
            })
        }else{
            self.setState({
                checkOutAmountType:false
            })
        }
    }
    removeTransaction=(transaction)=>{
        let transactionList=this.state.transaction;
        let newList=_.pull(transactionList,transaction);
        let totalPaymentAmount = parseFloat(this.state.totalPaymentAmount) - parseFloat(transaction.amount);
        let pendingAmount = parseFloat(this.state.totalAmount) - totalPaymentAmount;
        this.setState({
            totalPaymentAmount,
            pendingAmount,
            transaction:newList
        })
    }

     cardReaderList=()=>{
        let self=this;
        self.props.dispatch(paymentActions.cardReaderList({}))
     }

    setPaymentCardReader=(cardReader)=>{
        this.setState({
            selectedPaymentType:null,
            selectedCardReader:cardReader
        })
    }
    componentWillUnmount() {
      
        // window.scrollTo(0, 0);
    }

    render() {
        console.log("this.props. from new Booking", this.props);
        console.log("this.props",this.props,isMobile);
        console.log("this.state", this.state);
        let { bookingDate, showOptionsModal, showTipModal, hairdresserName } = this.state;
        let { hairdresser, salon } = this.props;

        let displayData = [];

        return (
            <div className="h_n">

                    <div className="body_container bg_white newbooking-for-mobile">
                        <TopHeader title="New Appointment" {...this.props} />
                        <SideNav {...this.props} />
                        {this.props.staffRota.rota&&this.props.staffRota.rota.length>0?
                        <div className="booking-parent">
                            <div className={this.state.isInvoice===1?'new_appointment':''}>
                            {isMobile?this.state.mobileSelectedStep==0&&
                            <div className="tab_content_wrapper new-tab-wrapper">
                                   {(this?.props?.selectedClient?._id==='64f5c7789a069cda0188fea3')?null:<Tabs defaultActiveKey="Booking" className="inset-shdow-booking new-booking-tab" onSelect={this.selectTab.bind(this)}>
                                        <Tab eventKey="Booking" title="Booking"
                                    //     {
                                    //     <>
                                    //         <p  className="text-center toggle">
                                    //             <span className="mr-3 ">Booking</span>
                                    //                 <label className="switch">
                                    //                 <input type="checkbox" />
                                    //                 <span className="slider round"></span>
                                    //                 </label>
                                    //         </p>
                                    //     </>
                                    //  }
                                     >

                                            <div className="cm_picker_wrap">
                                                <p>{moment(bookingDate).format('dddd DD MMM YYYY')} <i className="fa fa-angle-down" /></p>
                                                <DatePicker
                                                    selected={this.state.bookingDate}
                                                    //minDate={new Date()}
                                                    onChange={this.selectDay.bind(this)}
                                                />
                                            </div>


                                            <div className="booking_form_wrapper">

                                                <div className="cm_row m-0">
                                                <div className="cm_25 ">
                                                    <div className="form-group">
                                                        <label>Duration</label>
                                                        <input type="text" className={`form-control ${this.state.selectedService.length>0 ? '' : 'is_readonly'}`} value={this.state.totalTime + " m"}  readOnly={this.state.selectedService.length>0?false:true}/>
                                                        {this.state.serviceError ?
                                                            <span className="cm_error_msg">{this.state.serviceError}</span> : ''}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Start Time</label>
                                                        <div className="cm_picker_wrap pad-0">
                                                        <input type="text" value={this.state.selectSlot ? moment(this.state.selectSlot).format('HH:mm') : ""} placeholder="00:00" className={`form-control ${this.state.selectedService.length>0 ? '' : 'is_readonly'}`} readOnly={this.state.selectedService.length>0?false:true}/>
                                                        <DatePicker
                                                            selected={this.state.selectSlot}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={5}
                                                            timeCaption="Time"
                                                            dateFormat="HH:mm"
                                                            timeFormat="HH:mm"
                                                            includeTimes={this.state.includeTimes}
                                                            onChange={this.selectSlot.bind(this)}
                                                            readOnly={this.state.selectedService.length>0?false:true}
                                                        />
                                                        {this.state.startTimeError ?
                                                            <span className="cm_error_msg">{this.state.startTimeError}</span> : ''}
                                                    </div>
                                                    </div>
                                                </div>

                                                    <div className="cm_75">
                                                        <div className="form-group">
                                                            <label>Staff Member</label>

                                                            <Dropdown className="cm_select_wrapper">
                                                                <Dropdown.Toggle className="cm_select" id="dropdown-basic" >{hairdresserName}</Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    {this.props.staffRota.rota && this.props.staffRota.rota.length>0 ? (
                                                                        <ul className="cm_select_ul">
                                                                            {this.props.staffRota.rota.map((hairdresser, i) => (
                                                                                hairdresser.name !== 'Unknown' && hairdresser.email !== 'Unknown' ?
                                                                                    (<li key={hairdresser._id} onClick={this.changeHairdresser.bind(this, hairdresser._id, hairdresser.name,1,hairdresser)}><Dropdown.Item>{hairdresser.name}</Dropdown.Item></li>)
                                                                                    : ""))}

                                                                        </ul>
                                                                    ) : ""}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        <div className="mt-16">
                                                           <label>Service</label>
                                                            <div className="table-responsive srvc_table">
                                                            {hairdresser.hairdresserServices && hairdresser.hairdresserServices.length > 0 ?
                                                                <div className="table-responsive srvc_table">
                                                                    <table className="table">
                                                                        <tbody>
                                                                        {hairdresser.hairdresserServices.map((category, i) => (
                                                                            <React.Fragment key={category._id}>
                                                                                {i===0?<tr>
                                                                                        <th colSpan="4"><h3 className="cm_fw_l">{category.categoryName}</h3></th>
                                                                                        {/*<th>Duration</th>*/}
                                                                                        {/*<th>Cost</th>*/}
                                                                                    </tr>:
                                                                                    <tr>
                                                                                        <td colSpan="4"><h3 className="cm_fw_l">{category.categoryName}</h3></td>
                                                                                    </tr>}
                                                                                {category.hairdresserService.map((services, i) => (
                                                                                    <tr className="left_bg" key={services._id} onClick={this.selectService.bind(this, 1, services, !this.checkSelectedService(services._id))}>
                                                                                        <td className="pointer-event" >{services.serviceName}</td>
                                                                                        <td>{services.serviceTime!==0?services.serviceTime+"m":"--"}</td>
                                                                                        <td className="position-relative cross_wrap">{services.serviceTime!==0?hairdresser.userInfo.hairdresser.currencySymbol&&hairdresser.userInfo.hairdresser.currencySymbol!==""?hairdresser.userInfo.hairdresser.currencySymbol+services.servicePrice.toFixed(2):"£"+services.servicePrice.toFixed(2):"--"}</td>
                                                                                        {/*<td onClick={this.serviceSelect.bind(this, services, !this.checkSelectedService(services._id))}>*/}
                                                                                        <td className="i_width">
                                                                                            {this.checkSelectedService(services._id) ? (
                                                                                                <i className="fa fa-check-circle"></i>
                                                                                            ) : (
                                                                                                    <Addicon />
                                                                                                )}
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}

                                                                            </React.Fragment>
                                                                        ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                :<div className="rating-header nodiv">
                                                                                <p>No Services Offered </p>
                                                                  </div>
                                                            }
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </Tab>
                                        <Tab eventKey="Event"  title="Event"
                                        // {
                                        // <>
                                        //     <p  className="text-center toggle event-tg">
                                        //         <label className="switch">
                                        //             <input type="checkbox" />
                                        //             <span className="slider round"></span>
                                        //             </label>
                                        //             <span className="mr-3 ">Event</span>
                                        //     </p>
                                        // </>
                                        // }
                                         >
                                            <div className="cm_picker_wrap">
                                                <p>{moment(bookingDate).format('dddd DD MMM YYYY')} <i className="fa fa-angle-down" /></p>
                                                <DatePicker
                                                    selected={this.state.bookingDate}
                                                    minDate={new Date()}
                                                    onChange={this.selectDay.bind(this)}
                                                />
                                            </div>

                                            <div className="booking_form_wrapper">

                                                <div className="cm_row m-0">
                                                    <div className="cm_25">
                                                        <div className="form-group">

                                                            <label>Start Time</label>
                                                            <div className="cm_picker_wrap pad-0">
                                                                <input type="text" value={this.state.eventTime ?moment (this.state.eventTime).format('HH:mm') : ""} className="form-control" placeholder="00:00" />
                                                                <DatePicker
                                                                    selected={this.state.eventTime}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={5}
                                                                    timeCaption="Time"
                                                                    dateFormat="HH:mm"
                                                                    timeFormat="HH:mm"
                                                                    onChange={this.handleEvent.bind(this, 'eventStartTime')}
                                                                />
                                                                {this.state.eventStartTimeError ?
                                                                    <span className="cm_error_msg">{this.state.eventStartTimeError}</span> : ''}
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Finish Time</label>
                                                            <div className="cm_picker_wrap pad-0">
                                                                <input type="text" value={this.state.endTime ? moment(this.state.endTime).format('HH:mm') : ""} className="form-control" placeholder="00:00" />
                                                                <DatePicker
                                                                    selected={this.state.endTime}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={5}
                                                                    timeCaption="Time"
                                                                    dateFormat="HH:mm"
                                                                    timeFormat="HH:mm"
                                                                    onChange={this.handleEvent.bind(this, 'eventEndTime')}
                                                                />
                                                                {this.state.eventEndTimeError ?
                                                                    <span className="cm_error_msg">{this.state.eventEndTimeError}</span> : ''}
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="cm_75">
                                                        <div className="form-group">
                                                            <label>Event Title</label>

                                                            <input type="text" id="eventTitle" value={this.state.eventTitle} onChange={this.handleEvent.bind(this)} placeholder="Event Title" className="form-control" />
                                                            {this.state.eventTitleError ?
                                                                <span className="cm_error_msg">{this.state.eventTitleError}</span> : ''}
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Staff Member</label>

                                                            <Dropdown className="cm_select_wrapper">
                                                                <Dropdown.Toggle className="cm_select" id="dropdown-basic" >{hairdresserName}</Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    {this.props.staffRota.rota && this.props.staffRota.rota.length>0 ? (
                                                                        <ul className="cm_select_ul">
                                                                            {this.props.staffRota.rota.map((hairdresser, i) => (
                                                                                hairdresser.name !== 'Unknown' && hairdresser.email !== 'Unknown' ?
                                                                                    (<li key={hairdresser._id} onClick={this.changeHairdresser.bind(this, hairdresser._id, hairdresser.name,1,hairdresser)}><Dropdown.Item>{hairdresser.name}</Dropdown.Item></li>)
                                                                                    : ""))}

                                                                        </ul>
                                                                    ) : ""}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                            {this.state.hairdresserIdEventError ?
                                                                <span className="cm_error_msg">{this.state.hairdresserIdEventError}</span> : ''}
                                                        </div>

                                                    </div>
                                                </div>

                                                {/* <div className="cm_row">
                                                    <div className="cm_25">
                                                    </div>
                                                    <div className="cm_75">
                                                         </div>
                                                </div> */}

                                                {this.state.eventError ?
                                                    <span className="cm_error_msg">{this.state.eventError}</span> : ''}


                                            </div>

                                            <div className="text-center cm_right_check">
                                                <p><div className="check_wrap">
                                                    <input type="checkbox" onChange={this.handleEvent.bind(this)} id="isBookingAllowed" checked={this.state.isBookingAllowed} />
                                                    <span />
                                                </div>Still available for booking </p>

                                                <button onClick={this.submitEvent} className="btn btn-dark">Add Event</button>
                                            </div>

                                        </Tab>

                                    </Tabs>}

                                {this.state.isInvoice === 0 &&this.state.selectTab===1?
                                <div className="cm_width_25">
                                    <div className="cm_body">
                                        <br/>
                                        {this.state.bookingError ?
                                            <span className="cm_error_msg">{this.state.bookingError}</span> : ''}
                                        <ul>

                                            {this.state.transaction.map((transaction, index) => (
                                                <li key={"t_"+index}>{transaction.paymentType === 1 ? 'Pay by App' : ''}{transaction.paymentType === 2 ? 'Card' : ''}{transaction.paymentType === 3 ? 'Cash' : ''}{transaction.paymentType === 4 ? 'Other' : ''} - £{transaction.amount.toFixed(2)}</li>
                                            ))}
                                            {/*<li>Tip - £4.20</li>*/}
                                            <li>Total - {this.state.currencySymbol}{this.state.totalAmount.toFixed(2)}</li>

                                        </ul>
                                    </div>
                                    <div />

                                    <div className="cm_footer">
                                        <h2>Balance - {this.state.currencySymbol}{this.state.pendingAmount.toFixed(2)}</h2>
                                    </div>
                                </div>:""}

                                {/*<div className="text-right mt-3">*/}
                                {/*    <span onClick={this.handleOptionModal}><i className="fa fa-plus" /> Add service, voucher or tip</span>*/}
                                {/*</div>*/}

                            </div>
                            :<div className="tab_content_wrapper">

                            {this.state.isInvoice===1 ? (
                               <NewInvoice transactionData={this.state.transaction} bookingState={this.state}/>
                            //     <div className="invoice_card_wrapper">
                            //     <div className="cm_head">
                            //     <h3>Invoice - {moment().format("YYYYMMDDHHmmSS")}</h3>
                            //     <p className="cm_fw_b">{moment().format("dddd, MMM Do YY")}</p>
                            //     </div>

                            //     <div className="cm_body">
                            //     <ul>

                            // {this.state.transaction.map((transaction, index) => (
                            //     <li key={"t_"+index}>{transaction.paymentType === 1 ? 'Pay by App' : ''}{transaction.paymentType === 2 ? 'Card' : ''}{transaction.paymentType === 3 ? 'Cash' : ''}{transaction.paymentType === 4 ? 'Other' : ''} - {this.state.currencySymbol}{transaction.amount.toFixed(2)}</li>
                            //     ))}
                            // {/*<li>Tip - £4.20</li>*/}
                            //     <li>Total - {this.state.currencySymbol}{this.state.totalAmount.toFixed(2)}</li>

                            //     </ul>
                            //     </div>

                            //     <div className="cm_footer">
                            //     <h2>Balance - {this.state.currencySymbol}{this.state.pendingAmount.toFixed(2)}</h2>
                            //     </div>
                            //     </div>
                            ) :


                                (this?.props?.selectedClient?._id==='64f5c7789a069cda0188fea3')?null:<Tabs defaultActiveKey="Booking" className="new-booking-tab" onSelect={this.selectTab.bind(this)}>
                                <Tab eventKey="Booking" title={
                                <>
                                <p  className="text-center toggle">
                                <span className="mr-3 ">Booking</span>
                                <label className="switch">
                                <input type="checkbox" />
                                <span className="slider round"></span>
                                </label>
                                </p>
                                </>
                            } >

                                <div className="cm_picker_wrap">
                                <p>{moment(bookingDate).format('dddd DD MMM YYYY')} <i className="fa fa-angle-down" /></p>
                                <DatePicker
                                selected={this.state.bookingDate}
                                //minDate={new Date()}
                                onChange={this.selectDay.bind(this)}
                                />
                                </div>


                                <div className="booking_form_wrapper">

                                <div className="cm_row m-0">
                                <div className="cm_25">
                                <div className="form-group">
                                <label>Duration</label>
                                <input type="text" className={`form-control ${this.state.selectedService.length>0 ? '' : 'is_readonly'}`} value={this.state.totalTime + " m"}  readOnly={this.state.selectedService.length>0?false:true}/>
                            {this.state.serviceError ?
                                <span className="cm_error_msg">{this.state.serviceError}</span> : ''}
                                </div>
                                <div className="form-group">
                                <label>Start Time</label>
                                <div className="cm_picker_wrap pad-0">
                                <input type="text" value={this.state.selectSlot ? moment(this.state.selectSlot).format('HH:mm') : ""} placeholder="00:00" className={`form-control ${this.state.selectedService.length>0 ? '' : 'is_readonly'}`} readOnly={this.state.selectedService.length>0?false:true}/>
                                <DatePicker
                                selected={this.state.selectSlot}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={5}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                includeTimes={this.state.includeTimes}
                                onChange={this.selectSlot.bind(this)}
                                readOnly={this.state.selectedService.length>0?false:true}
                                />
                            {this.state.startTimeError ?
                                <span className="cm_error_msg">{this.state.startTimeError}</span> : ''}
                                </div>
                                </div>


                                </div>

                                <div className="cm_75">
                                <div className="form-group">
                                <label>Staff Member</label>

                                <Dropdown className="cm_select_wrapper cm-selectNt-relative">
                                    <Dropdown.Toggle className="cm_select" id="dropdown-basic" >{hairdresserName}</Dropdown.Toggle>

                                    <Dropdown.Menu>
                                {this.props.staffRota.rota && this.props.staffRota.rota.length>0 ? (
                                    <ul className="cm_select_ul">
                                {this.props.staffRota.rota.map((hairdresser, i) => (
                                    hairdresser.name !== 'Unknown' && hairdresser.email !== 'Unknown' ?
                                    (<li key={hairdresser._id} onClick={this.changeHairdresser.bind(this, hairdresser._id, hairdresser.name,1,hairdresser)}><Dropdown.Item>{hairdresser.name}</Dropdown.Item></li>)
                                    : ""))}

                                    </ul>
                                    ) : ""}
                                    </Dropdown.Menu>
                                </Dropdown>
                                </div>
                                <div className="mt-16">
                                <label>Service</label>
                                <div className="table-responsive srvc_table">
                                    {/* {console.log(hairdresser, 'loader enabled..', hairdresserName)} */}

                            {hairdresser.hairdresserServices && hairdresser.hairdresserServices.length > 0 ?

                            (
                                !this.state.isLoaderEnabled ?
                                <div className="table-responsive srvc_table">
                                <table className="table">
                                <tbody>
                            {hairdresser.hairdresserServices.map((category, i) => (
                                <React.Fragment key={category._id}>
                            {i===0?<tr>
                                <th colSpan="4"><h3 className="cm_fw_l">{category.categoryName}</h3></th>
                            {/*<th>Duration</th>*/}
                            {/*<th>Cost</th>*/}
                                </tr>:
                                <tr>
                                <td colSpan="4"><h3 className="cm_fw_l">{category.categoryName}</h3></td>
                                </tr>}
                            {category.hairdresserService.map((services, i) => (
                                <tr className="left_bg" key={services._id} onClick={this.selectService.bind(this, 1, services, !this.checkSelectedService(services._id))}>
                                <td className="pointer-event" >{services.serviceName}</td>
                                <td>{services.serviceTime!==0?services.serviceTime+"m":"--"}</td>
                                <td className="position-relative cross_wrap">{services.serviceTime!==0?hairdresser.userInfo.hairdresser.currencySymbol&&hairdresser.userInfo.hairdresser.currencySymbol!==""?hairdresser.userInfo.hairdresser.currencySymbol+services.servicePrice.toFixed(2):"£"+services.servicePrice.toFixed(2):"--"}</td>
                            {/*<td onClick={this.serviceSelect.bind(this, services, !this.checkSelectedService(services._id))}>*/}
                                <td className="i_width">
                            {this.checkSelectedService(services._id) ? (
                                <i className="fa fa-check-circle"></i>
                                ) : (
                                <Addicon />
                                )}
                                </td>
                                </tr>
                                ))}

                                </React.Fragment>
                                ))}
                                </tbody>
                                </table>
                                </div>
                                :
                                <div className="">
                                <Lottie options={defaultOptions}
                                height={50}
                                width={200}
                            />
                            <h4 className="text-center">Loading Data...</h4>
                            </div>
                            )
                                :
                                (
                                    this.state.isLoaderEnabled ?
                                    <div className="">
                                <Lottie options={defaultOptions}
                                height={50}
                                width={200}
                            />
                            <h4 className="text-center">Loading Data...</h4>
                            </div>:
                                    <div className="rating-header nodiv">
                                <p>No Services Offered </p>
                                </div>
                                )

                            }
                                </div>
                                </div>
                                </div>
                                </div>
                              </div>

                                </Tab>
                                <Tab eventKey="Event"  title={
                                <>
                                <p  className="text-center toggle event-tg">
                                <label className="switch">
                                <input type="checkbox" />
                                <span className="slider round"></span>
                                </label>
                                <span className="mr-3 ">Event</span>
                                </p>
                                </>
                            } >

                        
                                <div className="cm_picker_wrap">
                                <p>{moment(bookingDate).format('dddd DD MMM YYYY')} <i className="fa fa-angle-down" /></p>
                                <DatePicker
                                selected={this.state.bookingDate}
                                minDate={new Date()}
                                onChange={this.selectDay.bind(this)}
                                />
                                </div>

                                <div className="booking_form_wrapper">

                                <div className="cm_row m-0">
                                <div className="cm_25">
                                <div className="form-group">

                                <label>Start Time</label>
                                <div className="cm_picker_wrap pad-0">
                                <input type="text" value={this.state.eventTime ?moment (this.state.eventTime).format('HH:mm') : ""} className="form-control" placeholder="00:00" />
                                <DatePicker
                                selected={this.state.eventTime}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={5}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                onChange={this.handleEvent.bind(this, 'eventStartTime')}
                                />
                            {this.state.eventStartTimeError ?
                                <span className="cm_error_msg">{this.state.eventStartTimeError}</span> : ''}
                                </div>
                                </div>
                                <div className="form-group">
                                <label>Finish Time</label>
                                <div className="cm_picker_wrap pad-0">
                                <input type="text" value={this.state.endTime ? moment(this.state.endTime).format('HH:mm') : ""} className="form-control" placeholder="00:00" />
                                <DatePicker
                                selected={this.state.endTime}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={5}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                onChange={this.handleEvent.bind(this, 'eventEndTime')}
                                />
                            {this.state.eventEndTimeError ?
                                <span className="cm_error_msg">{this.state.eventEndTimeError}</span> : ''}
                                </div>
                                </div>

                                </div>
                                <div className="cm_75">
                                <div className="form-group">
                                <label>Event Title</label>

                                <input type="text" id="eventTitle" value={this.state.eventTitle} onChange={this.handleEvent.bind(this)} placeholder="Event Title" className="form-control" />
                            {this.state.eventTitleError ?
                                <span className="cm_error_msg">{this.state.eventTitleError}</span> : ''}
                                </div>
                                <div className="form-group mt-38">
                                <label>Staff Member</label>

                                <Dropdown className="cm_select_wrapper">
                                <Dropdown.Toggle className="cm_select" id="dropdown-basic" >{hairdresserName}</Dropdown.Toggle>

                                <Dropdown.Menu>
                            {this.props.staffRota.rota && this.props.staffRota.rota.length>0 ? (
                                <ul className="cm_select_ul">
                            {this.props.staffRota.rota.map((hairdresser, i) => (
                                hairdresser.name !== 'Unknown' && hairdresser.email !== 'Unknown' ?
                                (<li key={hairdresser._id} onClick={this.changeHairdresser.bind(this, hairdresser._id, hairdresser.name,1,hairdresser)}><Dropdown.Item>{hairdresser.name}</Dropdown.Item></li>)
                                : ""))}

                                </ul>
                                ) : ""}
                                </Dropdown.Menu>
                                </Dropdown>
                            {this.state.hairdresserIdEventError ?
                                <span className="cm_error_msg">{this.state.hairdresserIdEventError}</span> : ''}
                                </div>

                                </div>
                                </div>

                            {/* <div className="cm_row">
                                                    <div className="cm_25">
                                                    </div>
                                                    <div className="cm_75">
                                                         </div>
                                                </div> */}

                            {this.state.eventError ?
                                <span className="cm_error_msg">{this.state.eventError}</span> : ''}


                                </div>

                                <div className="text-right cm_right_check">
                                <p>Still available for booking <div className="check_wrap">
                                <input type="checkbox" onChange={this.handleEvent.bind(this)} id="isBookingAllowed" checked={this.state.isBookingAllowed} />
                                <span />
                                </div></p>

                                <button onClick={this.submitEvent} className="btn btn-dark">Add Event</button>
                                </div>
                                </Tab>

                                <Tab eventKey="Checkout"  title={
                                <>
                                <p  className="text-center toggle event-tg">
                                <label className="switch">
                                <input type="checkbox" />
                                <span className="slider round"></span>
                                </label>
                                <Link to={`/new-checkout/${this.props.match.params.salonId}`} >Checkout</Link>
                                </p>
                                </>
                            } >
                              </Tab>
                                </Tabs>}

                            {this.state.isInvoice === 0 &&this.state.selectTab===1?
                                <div className="cm_width_25">
                                <div className="cm_body">
                                <br/>
                            {this.state.bookingError ?
                                <span className="cm_error_msg">{this.state.bookingError}</span> : ''}
                            {/*    <ul>*/}

                            {/*{this.state.transaction.map((transaction, index) => (*/}
                            {/*    <li key={"t_"+index}>{transaction.paymentType === 1 ? 'Pay by App' : ''}{transaction.paymentType === 2 ? 'Card' : ''}{transaction.paymentType === 3 ? 'Cash' : ''}{transaction.paymentType === 4 ? 'Other' : ''} - £{transaction.amount.toFixed(2)}</li>*/}
                            {/*    ))}*/}
                            {/*/!*<li>Tip - £4.20</li>*!/*/}
                            {/*    <li>Total - {this.state.currencySymbol}{this.state.totalAmount.toFixed(2)}</li>*/}

                            {/*    </ul>*/}
                                </div>
                                <div />

                                {/*<div className="cm_footer">*/}
                                {/*<h2>Balance - {this.state.currencySymbol}{this.state.pendingAmount.toFixed(2)}</h2>*/}
                                {/*</div>*/}
                                </div>:""}

                            {/*<div className="text-right mt-3">*/}
                            {/*    <span onClick={this.handleOptionModal}><i className="fa fa-plus" /> Add service, voucher or tip</span>*/}
                            {/*</div>*/}
                                </div>}
                            {this.state.selectTab==1?
                            <>
                            {isMobile&&this.state.mobileSelectedStep==1&&<NewAppointment selectExistingClient={this.selectExistingClient} selectNewClient={this.selectNewClient}/>}

                            {isMobile?this.state.mobileSelectedStep==2&&
                            <ClientWalkIn currencySymbol={this.state.currencySymbol} checkReadOnly={this.checkReadOnly} searchClient={this.searchClient} selectClient={this.selectClient} history={this.props.history} />
                            :
                            this.state.totalTime>0 && this.state.selectedClientId === '' && this.state.isSaveBooking === 0 && this.state.paymentType === '' &&this.state.isInvoice === 0&&this.state.selectTab===1 ? (
                               <> <ClientWalkIn currencySymbol={this.state.currencySymbol} checkReadOnly={this.checkReadOnly} searchClient={this.searchClient} selectClient={this.selectClient} history={this.props.history} /></>
                            ) : ""}
                            {isMobile?this.state.mobileSelectedStep==3&&
                                <ClientCheckout borderr="borderemove" currencySymbol={this.state.currencySymbol} hairdresserId={this.state.hairdresserId} checkoutBooking={this.checkoutBooking} selectedClient={this.state.selectedClient} changeClient={this.changeClient} saveBooking={this.saveBooking}  />
                            :
                            this.state.selectedClientId !== '' && this.state.isSaveBooking === 0 && this.state.paymentType === '' &&this.state.isInvoice === 0 ? (
                                   <>
                                <ClientCheckout  borderr="borderemove" currencySymbol={this.state.currencySymbol} hairdresserId={this.state.hairdresserId} checkoutBooking={this.checkoutBooking} selectedClient={this.state.selectedClient} changeClient={this.changeClient} saveBooking={this.saveBooking}  /></>) : ""}

                            {isMobile?this.state.mobileSelectedStep==4&&
                                <Checkout checkCompleteSale={this.checkCompleteSale}  removeTransaction={this.removeTransaction} selectBookingAmount={this.selectBookingAmount} bookingState={this.state} currencySymbol={this.state.currencySymbol} completeSale={this.completeSale} saveBooking={this.saveBooking} onChangedValue={this.onChanged} pendingAmount={this.state.pendingAmount} paymentAmountError={this.state.paymentAmountError} selectedClient={this.state.selectedClient} selectedService={this.state.selectedService} totalAmount={this.state.totalAmount} paymentType={this.state.paymentType} serPaymentType={this.serPaymentType} setPaymentType={this.setPaymentType} resetBooking={this.resetBooking} changeClient={this.changeClient} />
                                :
                                this.state.isSaveBooking === 1 && this.state.paymentType === ''  &&this.state.isInvoice === 0 ? (
                                <Checkout checkCompleteSale={this.checkCompleteSale} cardReaderList={this.props.cardReaderList} removeTransaction={this.removeTransaction} selectBookingAmount={this.selectBookingAmount} bookingState={this.state} currencySymbol={this.state.currencySymbol} completeSale={this.completeSale} saveBooking={this.saveBooking} onChangedValue={this.onChanged} pendingAmount={this.state.pendingAmount} paymentAmountError={this.state.paymentAmountError} selectedClient={this.state.selectedClient} selectedService={this.state.selectedService} totalAmount={this.state.totalAmount} paymentType={this.state.paymentType} serPaymentType={this.serPaymentType} setPaymentType={this.setPaymentType} setPaymentCardReader={this.setPaymentCardReader} resetBooking={this.resetBooking} changeClient={this.changeClient} />
                            ) : ""}
                            {this.state.isInvoice === 1 ?
                            <>
                            {isMobile&&
                           <><NewInvoice transactionData={this.state.transaction} bookingState={this.state} />
                            {/*<div className="invoice_card_wrapper">*/}
                            {/*    */}
                            {/*    <div className="cm_head">*/}
                            {/*        <h3>Invoice - {moment().format("YYYYMMDDHHmmSS")}</h3>*/}
                            {/*        <p className="cm_fw_b">{moment().format("dddd, MMM Do YY")}</p>*/}
                            {/*    </div>*/}

                            {/*    <div className="cm_body">*/}
                            {/*        <ul>*/}

                            {/*            {this.state.transaction.map((transaction, index) => (*/}
                            {/*                <li key={"t_"+index}>{transaction.paymentType === 1 ? 'Pay by App' : ''}{transaction.paymentType === 2 ? 'Card' : ''}{transaction.paymentType === 3 ? 'Cash' : ''}{transaction.paymentType === 4 ? 'Other' : ''} - {this.state.currencySymbol}{transaction.amount.toFixed(2)}</li>*/}
                            {/*            ))}*/}
                            {/*            <li>Total - {this.state.currencySymbol}{this.state.totalAmount.toFixed(2)}</li>*/}

                            {/*        </ul>*/}
                            {/*    </div>*/}

                            {/*    <div className="cm_footer">*/}
                            {/*        <h2>Balance - {this.state.currencySymbol}{this.state.pendingAmount.toFixed(2)}</h2>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            </>
                            }
                                {/*<Invoice currencySymbol={this.state.currencySymbol} completeBooking={this.completeBooking} saveBooking={this.saveBooking} selectedClient={this.state.selectedClient} selectedService={this.state.selectedService} totalAmount={this.state.totalAmount} createBooking={this.createBooking} paymentType={this.state.paymentType} resetBooking={this.resetBooking} />*/}
                            </> : ""}
                            {isMobile&&this.state.mobileSelectedStep<=2&&this.state.selectTab==1&&
                            <div className="footerbtn-parent mt-5 mb-3">
                                <FooterBtn mobileCancel={this.mobileCancel} mobileContinue={this.mobileContinue} isMobileEnable={this.isMobileEnable} bookingState={this.state} />
                            </div>}
                            </>:""}
                            </div>
                        </div>
                        :  <div className="cm_loader"><div className="loader"></div></div>}
                    </div>

                <Modal className="cm_popup" show={showOptionsModal} onHide={this.handleOptionModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Item</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="cm_btns">
                            <button className="btn btn-dark btn-block mb-3">Service</button>
                            <button className="btn btn-dark btn-block mb-3">Voucher</button>
                            <button onClick={this.handleTipModal} className="btn btn-dark btn-block">Tip</button>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal className="cm_popup" show={showTipModal} onHide={this.handleTipModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Tip</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="form-group">
                                <label>Staff Member</label>
                                <select className="form-control">
                                    <option>Katherine Balza</option>
                                    <option>Katherine Balza</option>
                                    <option>Katherine Balza</option>
                                </select>
                            </div>
                            <div className="cm_btns">
                                <button className="btn btn-dark btn-block">Confirm</button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(NewBooking);

