import _ from "lodash";

import DevSetting from './development';
import ProdSetting from './production';
import StagSetting from './staging';
import localhostSetting from './localhost';
import ProdRushSetting from './rush';
import BetaSetting from './beta';

let defaultSetting = {
  api: {
    url: 'http://localhost:3001/api/',
    mode: 'cors',
    backend:'http://localhost:3001/',
    v2: 'http://localhost:5001/api/v2/',
  },
  socket: '',
  google_map_key:'AIzaSyAxx2cDzRRwbsY99ey2-G6gHVQpTwA4N_Y',
  api_clientId:'mobileV1',
  api_clientSecret:'abc123456',
  default_latitude:'51.5070624',
  default_longitude:'-0.1278038',
  fb_appId:'191495858050164',
  fb_field:'name,email,picture',
  fb_version:'7.0',
  apple_clientId:"app.beuappweb.com",
  apple_redirectURI:"https://devapp.beublog.com/redirect",
  stripeKey:"pk_test_w1pHZqeBNrtrQkeIfZhOJlpQ",
  stripe:{
    salonMonthlyPriceId:'price_1JjN90Ju70D4HRltddT90qlf',
    salonYearlyPriceId:'price_1Jkt25Ju70D4HRltfyrKbt7E',
    selfMonthlyPriceId:'price_1JjNA7Ju70D4HRltWWhHbUjo',
    selfYearlyPriceId:'price_1Jkt4VJu70D4HRltDRXQZZVQ',
    salonMonthlyPrice:60,
    salonYearlyPrice:540,
    selfMonthlyPrice:25,
    selfYearlyPrice:240,
    salonSave:'Save 33%',
    selfempSave:'Save 33%',
    oneSelfEmpPrice:5,
  },

  payout:{
    instantPaymentFee:0.02,   //2%
    standardPaymentFee:0.0025,  //0.25%
    extraStandardPaymentFee:0.1,  //0.1p
  },

  simulated:true,
  defaultSMS:250, // if new salon create free SMS limit
  employees:1, // Number of employ  defaultSMS divide by number of employee available free SMS. 

  SMSText:{
    reminder:"Hi {{Client}}, this is {{SalonName}}. Just a reminder for your appointment with {{StylistName}} at {{DateTime}}. See you soon.", 
    newBooking:"Hi {{Client}}, this is {{SalonName}}. Your appointment with {{StylistName}} is confirmed for {{DateTime}}. Can't wait to see you.",
    updatedBooking:"Hi {{Client}}, this is {{SalonName}}. Your appointment with {{StylistName}} has been rescheduled to {{DateTime}}. Thank you.",
    noShow:"Hi {{Client}}, this is {{SalonName}}. We missed you at your appointment with {{StylistName}}. Please reschedule at your earliest convenience. Thank you.",
    cancelledBooking:"Hi {{Client}}, this is {{SalonName}}. Your appointment with {{StylistName}} on [Date & Time] has been canceled. Feel free to reschedule. Thank you.",
    thankYouForVisiting:"Hi {{Client}}, this is {{SalonName}}. Thank you for visiting! We hope you enjoyed your time with {{StylistName}}. Looking forward to seeing you again soon.",
    thankYouForTipping:"Hi {{Client}}, this is {{StylistName}} from {{SalonName}}. Thank you for your generous tip! We appreciate your support. Looking forward to serving you again soon.",
    reminderToRebook:"Hi {{Client}}, this is {{SalonName}}. Friendly reminder to rebook your next appointment with {{StylistName}}. We look forward to seeing you again soon.",
    happyBirthday:"Hi {{Client}}, this is {{SalonName}}. Happy birthday! Hope you have a great day. If you'd like to book, we're here for you.",
    customReminderText:"Hi {{Client}}, this is {{SalonName}}. Just a reminder for your appointment with {{StylistName}} at {{DateTime}}",
      googleReview:"We hope you enjoyed your booking at {{SalonName}}.If you had a great experience please leave us a 5* review - {{ReviewUrl}}" },

  EmailText:{
    newBooking:"Hi {{Client}}, this is {{SalonName}}. Your appointment with {{StylistName}} is confirmed for [Date & Time]. Can't wait to see you!",
    updatedBooking:"Hi {{Client}}, this is {{SalonName}}. Your appointment with {{StylistName}} has been rescheduled to {{New Date}} & {{Time}}. Thank you!",
  },

  marketingPreviewText:[{
    SMSText:"SMS messages are sent to all clients to the phone number you have saved on client record.",
    emailText:"Emails are sent to all clients to the email address you have saved on their client record.",
  }],

 reminderTimeOptions: [
    { value: 1, label: 'Minutes' },
    { value: 2, label: 'Hours' },
    { value: 3, label: 'Days' },
    { value: 4, label: 'Weeks' },
    { value: 5, label: 'Months' },
  ],
  triggerTypeOptions: [
     { value: 1, label: 'Booking Reminder' },
     { value: 2, label: 'Reminder to rebook' },
     { value: 3, label: 'Birthday' },
   ],

   cancellationPolicyOptions:[
    {value:0, label:'Anytime'},
    {value:1,  label:'Up to 1 hour before'},
    {value:2,  label:'Up to 2 hour before'},
    {value:3,  label:'Up to 3 hour before'},
    {value:4,  label:'Up to 4 hour before'},
    {value:5,  label:'Up to 5 hour before'},
    {value:6,  label:'Up to 6 hour before'},
    {value:12, label:'Up to 12 hour before'},
    {value:24, label:'Up to 24 hour before'},
    {value:48, label:'Up to 48 hour before'},
    {value:72, label:'Up to 72 hour before'},
    {value:168, label:'Up to 1 week before'},
    ],

    onlineBookingUptoBeforeOptions:[
        {value:0, label:'Anytime before appointment start time'},
        {value:0.25,  label:'Up to 15 minutes before'},
        {value:0.50,  label:'Up to 30 minutes before'},
        {value:1,  label:'Up to 1 hour before'},
        {value:2,  label:'Up to 2 hours before'},
        {value:3,  label:'Up to 3 hours before'},
        {value:4,  label:'Up to 4 hours before'},
        {value:5,  label:'Up to 5 hours before'},
        {value:6,  label:'Up to 6 hours before'},
        {value:12, label:'Up to 12 hours before'},
        {value:24, label:'Up to 24 hours before'},
        {value:48, label:'Up to 48 hours before'},
        {value:72, label:'Up to 72 hours before'},
        {value:168, label:'Up to 1 week before'},
    ],

    onlineBookingUptoOptions:[
        {value:12,  label:'No more than 12 months in advance'},
        {value:11,  label:'No more than 11 months in advance'},
        {value:10,  label:'No more than 10 months in advance'},
        {value:9,  label:'No more than 9 months in advance'},
        {value:8,  label:'No more than 8 months in advance'},
        {value:7,  label:'No more than 7 months in advance'},
        {value:6,  label:'No more than 6 months in advance'},
        {value:5, label:'No more than 5 months in advance'},
        {value:4, label:'No more than 4 months in advance'},
        {value:3, label:'No more than 3 months in advance'},
        {value:2, label:'No more than 2 months in advance'},
        {value:1, label:'No more than 1 month in advance'},
    ],

}






let siteSetting = defaultSetting;

// switch ('stag') {
switch (process.env.REACT_APP_ENV) {
  case "prod":
  case "production":
    siteSetting = _.extend(defaultSetting, ProdSetting);
    break;
  case "rush":
  case "rush_prod":
    siteSetting = _.extend(defaultSetting, ProdRushSetting);
    break;
  case "stag":
  case "staging":
    siteSetting = _.extend(defaultSetting, StagSetting);
    break;
  case "dev":
  case "development":
    siteSetting = _.extend(defaultSetting, DevSetting);
    break;
  case "local":
  case "localhost":
    siteSetting = _.extend(defaultSetting, localhostSetting);
    break;
  case "beta":
    siteSetting = _.extend(defaultSetting, BetaSetting);
    break;
  default:
    siteSetting = _.extend(defaultSetting, DevSetting);
}

export default siteSetting;
