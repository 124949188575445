import React, {Component} from 'react';
import Popup from "reactjs-popup";
import {connect} from "react-redux";
import {userActions} from "../../_actions";
import {settingConstants} from "../../_constants";

class Reset extends Component {

    constructor(props) {
        super(props);
        this.state = {
            resetPopup:true,
            resetSuccessPopup:false,
            password:'',
            confPassword:'',
            passwordError:'',
            confPassError:'',
            error:'',
            resetDisabled:false
        }
        this.reset=this.reset.bind(this);
        this.onChanged=this.onChanged.bind(this);
        this.login=this.login.bind(this);
    }

    componentDidMount() {
        this.props.dispatch({ type: settingConstants.TOGGLE_FORGOT, value: 2 });
        this.props.dispatch({ type: settingConstants.TOGGLE_LOGIN, value: 2 });
        this.props.dispatch({ type: settingConstants.TOGGLE_SOCIAL_LOGIN, value: 2 });
        //this.props.dispatch({ type: settingConstants.TOGGLE_THANKU, value: false });
    }

    onChanged(e) {
        this.setState({[e.target.name]: e.target.value, copied: false});

        // if(this.state.password.length<6){
        //     this.setState({
        //         resetDisabled:true
        //     }) 
        // }else{
        //     this.setState({
        //         resetDisabled:false
        //     })
        // }

        if (e.target.name === 'password') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    passwordError: ''
                })
            } else {
                this.setState({
                    passwordError: ''
                })
            }
        }
        if (e.target.name === 'confPassword') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    confPassError: ''
                })
            } else {
                this.setState({
                    confPassError: '',
                })
            }
        }
    }

    login(){
        this.props.history.push("/");
    }

    reset(){
        let self = this;
        let password = this.state.password;
        let token = this.props.match.params.token;
        let params = {
            password: password,
            deviceToken: 'sdadsadsada',
            deviceTypeId: 1,
            resetToken:token
        }
        if (this.state.password === "" || this.state.password === undefined) {
            this.setState({
                passwordError: 'Please enter password'
            })
        } else if (this.state.confPassword === "" || this.state.confPassword === undefined) {
            this.setState({
                confPassError: 'Please enter confirm password'
            })
        }else if (this.state.password !== this.state.confPassword ) {
            this.setState({
                confPassError: "Password mismatch"
            })
        }else if (this.state.password.length<6) {
            this.setState({
                passwordError: 'The password must be at least 6 characters'
            })
        } else {
            self.props.dispatch(userActions.reset(params))
                .then(function (response) {
                    //console.log('response',response);
                    if (response.data.errorCode === 0) {
                        self.setState({
                            resetPopup:false,
                            resetSuccessPopup:true
                        })
                    } else {
                        self.setState({
                            error: response.data.messages
                        })
                    }
                })
        }
    }

    render() {
        return (
            <React.Fragment>
                {/* --------------------------------reset password Popup---------------------------------------- */}
                <Popup open={this.state.resetPopup} closeOnDocumentClick={false} closeOnEscape={false}>
                    <div className="cm_modal popupscroll">
                        <div className="modal-header" style={{borderBottom: 'none'}}>
                            {/*<button type="button" className="close">&times;</button>*/}
                        </div>
                        <div className="modal-body loginPopup">
                            <div className="text-center" style={{marginBottom: '20px'}}><img
                                src={require('../../assets/images/loginLogo.png')} className="popupLogo"/></div>
                            <h3 className="text-center">Reset Password</h3>
                            <br/>
                            <br/>
                            {this.state.error ? <span className="cm_error_msg">{this.state.error}</span> : ''}
                            <br/>
                            <div className="form-group">
                                <input type="password" className="form-control" placeholder="New Password..." name="password"
                                       autoComplete="off" value={this.state.password}  onChange={this.onChanged} />
                                {this.state.passwordError ?
                                    <span className="cm_error_msg">{this.state.passwordError}</span> : ''}
                            </div>

                            <div className="form-group">
                                <input type="password" className="form-control" placeholder="Confirm New Password..."
                                       name="confPassword" autoComplete="off" value={this.state.confPassword}  onChange={this.onChanged}/>
                                {this.state.confPassError ?
                                    <span className="cm_error_msg">{this.state.confPassError}</span> : ''}

                            </div>

                            <br/><br/>
                            <button type="button" className="btn btn-primary btn-block" disabled={this.state.resetDisabled} onClick={this.reset.bind(this)}>
                                Reset Password
                            </button>
                            <br/>

                            <br/>
                        </div>
                    </div>
                </Popup>
                {/* --------------------------------Reset Success Popup---------------------------------------- */}
                <Popup
                    open={this.state.resetSuccessPopup}
                    closeOnDocumentClick={false} closeOnEscape={false}
                >
                    <div className="cm_modal popupscroll">
                        <div className="modal-header" style={{borderBottom: 'none'}}>
                            {/*<button type="button" className="close">&times;</button>*/}
                        </div>
                        <div className="modal-body loginPopup">
                            <div className="text-center" style={{marginBottom: '20px'}}><img
                                src={require('../../assets/images/loginLogo.png')} className="popupLogo"/></div>
                            <h2 className="text-center">Password Reset Successfully</h2>
                            <br/>
                            <h5 className="text-center"></h5>
                            <br/>
                            <button type="button" className="btn btn-primary btn-block "  onClick={this.login.bind(this)}>
                                Sign in
                            </button>
                            <br/>

                            <br/>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const {hairdresser, salon, hairdresserReview, user, setting} = state;
    return {
        hairdresser,
        salon,
        hairdresserReview,
        user,
        setting
    };
}

export default connect(mapStateToProps)(Reset);
