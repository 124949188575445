import { hairdresserConstants } from '../_constants';


const initialState = {
  hairdresserSchedule: []
};

export function calender(state = initialState, action) {
  // console.log('action.type',action);
  switch (action.type) {

    case hairdresserConstants.HAIRDRESSER_SCHEDULE_SUCCESS:
      let res = action.getHairdresserScheduleSuccess;
        state.hairdresserSchedule[res.pos]= res;
      return {...state};
      case hairdresserConstants.UPDATE_HAIRDRESSER_SCHEDULE:
        // console.log("resfffffffffffffff",action?.getHairdresserScheduleSuccess);
        state.hairdresserSchedule = action?.getHairdresserScheduleSuccess
        return {...state};

     case hairdresserConstants.HAIRDRESSER_INFO_ERROR:
      return {};

    case hairdresserConstants.HAIRDRESSER_INFO_CLEAR:
      return {hairdresserSchedule:[]};

    default:
      return state
  }
}


export function updateHairdresserScheduleCalender(state = initialState, action) {
  // console.log('action.type',action);
  switch (action.type) {

    case hairdresserConstants.HAIRDRESSER_SCHEDULE_SUCCESS:
      let res = action.getHairdresserScheduleSuccess;
      // console.log("res",res,state);
        state.hairdresserSchedule[res.pos]= res;
      return {...state};

    case hairdresserConstants.HAIRDRESSER_INFO_ERROR:
      return {};

    case hairdresserConstants.HAIRDRESSER_INFO_CLEAR:
      return {hairdresserSchedule:[]};

    default:
      return state
  }
}

