import React, { Component } from "react";
import { Pending ,Bue, EventSeat} from '../SvgIcon';
import Slider from "react-slick";
export default class Stats extends Component {
    render() {
        const settings = {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            prevArrow: false,
            nextArrow: false,
            autoplay: false,
            speed: 500,
            responsive: [
                {
                  breakpoint: 991,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                }
            ]
          };
        return (
            <div className="conatiner">
                <div className="hide-mobile">
                <div className="row stats_parent ">
                    <div className="col-sm-4">
                        <div className="stats text-center">
                            <p className="mb-5"><span className="icon"><Pending/></span></p>
                            <h4>42%</h4>
                            <p>Bookings made</p>
                            <p> out of business hours</p>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="stats text-center">
                            <p className="mb-5 "><span className="icon"><Bue/></span></p>
                            <h4 className="">30%</h4>
                            <p>Drop in client</p>
                            <p> no-shows</p>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="stats text-center seatIcn">
                            <p className="mb-5"><span className="icon"><EventSeat/></span></p>
                            <h4>20%</h4>
                            <p>Reduction in empty</p>
                            <p> seat time</p>
                        </div>
                    </div>
                </div>
                </div>
                <div className="hide-desktop">
                <Slider {...settings}>
                    <div className="">
                        <div className="stats text-center">
                            <p className="mb-3 mb-md-5 "><span className="icon"><Pending/></span></p>
                            <h4>42%</h4>
                            <p>Bookings made</p>
                            <p> out of business hours</p>
                        </div>
                    </div>
                    <div className="">
                        <div className="stats text-center">
                            <p className="mb-3 mb-md-5"><span className="icon"><Bue/></span></p>
                            <h4 className="text-center">30%</h4>
                            <p>Drop in client</p>
                            <p> no-shows</p>
                        </div>
                    </div>
                    <div className="">
                        <div className="stats text-center seatIcn">
                            <p className="mb-3 mb-md-5"><span className="icon"><EventSeat/></span></p>
                            <h4>20%</h4>
                            <p>Reduction in empty</p>
                            <p> seat time</p>
                        </div>
                    </div>
                
            </Slider>
                </div>
            </div>
            
        )
    }
}
