import { userConstants } from '../_constants';


const initialState = {

};

export function user(state = initialState, action) {

  switch (action.type) {

    case userConstants.LOGIN_SUCCESS:
      let userInfo = action.loginSuccess;
      return userInfo;

    case userConstants.PROFILE_SUCCESS:
      let profileInfo = action.profileSuccess;
      return profileInfo;

    case userConstants.SIGNUP_SUCCESS:
      let signupInfo = action.signupSuccess;
      return signupInfo;

    case userConstants.CHANGEPASSWORD_SUCCESS:
      return {};

    case userConstants.CHANGEPASSWORD_ERROR:
      return {};

    case userConstants.LOGOUT_SUCCESS:
      return {};

    case userConstants.LOGIN_ERROR:
      return {};

    case userConstants.USER_CLEAR:
      return {};

    default:
      return state
  }
}

