import React, { Component } from "react";
import { Pending ,Bue} from '../SvgIcon'
export default class SelfEmployed extends Component {
    render() {
        return (
            <div className="container pad-0">
                        <div className=" happy_business feature self-emp pt-0">
                       
                            <div className="text">
                                <h4>For Salons and Self-Employed</h4>
                                <p>Beu is designed for both salons and self-employed professionals, allowing them to work seamlessly together, with self-employed stylists getting paid directly into their own accounts. </p>
                                <div className="feature-list">
                                    <p>Suitable for businesses with employed and self-employes staff</p>
                                    <p>Every stylist has a unique booking link</p>
                                    <p>Each stylist can build their online presence with a personal portfolio</p>
                                    <p>Self-employed stylists own their data </p>
                                </div>
                            </div>
                            <div className="img mr-0">
                                <img src={require('../../assets/images/web-img/self-employee.png')} className="img-fluid mb-3" />
                            </div>
                          
                            
                        </div>
                     </div>
        )
    }
}
