import React, { Component } from "react";
import { Link } from 'react-router-dom';
import {BackArrow} from '../SvgIcon';
import {connect} from "react-redux";
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import config from '../../config/env';

import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(config.stripeKey);

class Payment extends Component {
    constructor(props){
        super(props);
        this.state = {
            email:'',
            firstName:'',
            lastName:'',
            cardNumber:'',
            expiryNmumber:'',
            securityCode :'',
        }
        
        };
        componentDidMount(){
            document.body.classList.add('login-home-temp');
          }
       handleonChange = (e) => {
            this.setState({
                email : e.target.value,
                firstName:    e.target.value,
                lastName:     e.target.value,
                cardNumber:   e.target.value,
                expiryNmumber:e.target.value,
                securityCode :e.target.value
            }); 
            }


    render() {
        // console.log("this.props##",this.props);
      return (
        <div className="container-fluid pad-0 position-relative payment-body">
        <div className="back-arrow">
          <span className="cursorP" onClick={(e)=>this.props.history.push('/get-software')}><BackArrow/></span>
        </div>
            <Elements stripe={stripePromise}>
                <ElementsConsumer>
                    {({stripe, elements}) => (
                        <CheckoutForm stripe={stripe} elements={elements} history={this.props.history} />
                    )}
                </ElementsConsumer>
            </Elements>
        
         <img src={require('../../assets/images/web-img/bigdot1.png')} className="img-fluid dot big_dot hide-mobile mb-3" />
          
        </div>
      
      );
    }
  }

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Payment);