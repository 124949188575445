import React, {useEffect, useState} from 'react';
import {Tab, Tabs, Dropdown, Accordion, Card, Button, Modal, Spinner} from 'react-bootstrap';
import moment from "moment";
import {Close, MySpinner} from "../SvgIcon";
import {paymentActions} from "../../_actions";
import LoaderSetting from "../settings/Loader";
import {ADDCARDREADER} from "../../assets/SVG";
import { components } from 'react-select/dist/react-select.cjs.prod';
const isMobile = (window.innerWidth <= 768);
const Checkout = (propes) => {
    // console.log("client",propes);
    const getslotTime=(time)=> {
        let hr = parseInt(time / 60);
        let min = time - (hr * 60)
        return (hr <= 9 ? '0' + hr : hr) + ':' + (min == 0 ? '00' : (min <= 9 ? '0' + min : min));
    }
    const checkEmail=(email)=>{
        if(email) {
            let emailArr = email.split('@');
            if (emailArr[1] === 'beuapp.com') {
                return false;
            } else {
                return true;
            }
        }else{
            return false;
        }
    }

    // const [showDiscoversModal, setShowDiscoversModal] = useState(false)
    // const handleDiscoverModal  = ()=>{
    //     setShowDiscoversModal(true)
    // }
    // const handleClose = ()=>{
    //     setShowDiscoversModal(false)
    // }
    const [isCardReaderRequested, setIsCardReaderRequested] = useState(false)
    const [cardReaderRequestError, setCardReaderRequestError] = useState('')
    const [registrationCode, setRegistrationCode] = useState('')
    const [cardReaderConnectError, setCardReaderConnectError] = useState('')
    const [locationId, setLocationId] = useState('')
    const [isCardConnected, setIsCardConnected] = useState(true)
    const [connectDevice, setConnectDevice] = useState(false)
    const [cardReaderFound, setCardReaderFound] = useState(false)
    const [discoverResult, setDiscoverResult] = useState([])
    const [noDevice, setNoDevice] = useState(false)
    const [selectedDevice, setSelectedDevice] = useState({})
    const [isSearchReader, setIsSearchReader] = useState(true)
    const [loaders,setLoaders] = useState(true)

    const handleCancel = ()=>{
        setRegistrationCode('');
        setCardReaderConnectError('');
        setIsSearchReader(false);
    }

    const discoverReaders=()=>{
        let self=this;
        setIsSearchReader(true);
    }
    useEffect(()=>{
        const timeoutId = setTimeout(() => {
            setLoaders(false);
          }, 3000);
          return () => {
            clearTimeout(timeoutId);
          };
    },[])
    return(
        propes.selectedClient?
            (<div className="cm_right_panal client_checkout newclient-checkout ">
                <div className="backgroun-shadow">
                    <div className="cm_bao">
                        <img
                            src={propes.selectedClient.localAvatar != propes.selectedClient.localAvatar ? "" : require('../../assets/images/client-plachoder.jpg')}/>
                        <h3>{propes?.selectedClient?.name}</h3>
                        {checkEmail(propes?.selectedClient?.email)?<p>{propes?.selectedClient?.email}</p>:""}
                        <p>{(propes.selectedClient._id==='64f5c7789a069cda0188fea3')?null:propes?.selectedClient?.phoneNumber}</p>
                    </div>
                    <div className="cm_body checkout-parent">
                        <div className="bobking_detail ">
                            {propes?.bookingState?.isPaymentComplete==1?
                                <div className="text-center">
                                    <img src={require('../../assets/images/tick-check.png')} style={{maxWidth:"25%"}} className="img-fluid"/>

                                    <h4 className="mt-4">Payment Taken</h4>
                                    <p>Complete checkout to generate an invoice and finish this booking.</p>
                                </div>
                                :
                                <>
                                {/* {isMobile?
                                    <div className="three_box three_boxthree">
                                        <div className="cm_b_details">
                                            <p>Start Time</p>
                                            <h5>{getslotTime(propes.bookingState.slotInfo.startTime)}</h5>
                                        </div>
                                        <div className="cm_b_details">
                                            <p>Duration</p>
                                            <h5>{propes.bookingState.totalTime}</h5>
                                        </div>
                                        <div className="cm_b_details">
                                            <p>Cost</p><h5>{propes.currencySymbol}{parseFloat(propes.totalAmount).toFixed(2)}</h5>
                                        </div>
                                    </div>
                                    :""} */}
                                    <div className="payment-bill m-0 p-0">
                                        <div className="tble">
                                            <p>Total</p>
                                            <p>{propes?.currencySymbol}{parseFloat(propes?.totalAmount)?.toFixed(2)}</p>
                                        </div>
                                        {propes?.bookingState?.transactions?.length>0?propes?.bookingState?.transactions.map((transaction, index) => (

                                            transaction?.pgObjectType=='deposit'?
                                                <div className="tble green" key={"t_"+index}>
                                                    <p>Deposit Paid</p>
                                                    <p>{propes?.currencySymbol}{transaction?.pgTransactionAmount?.toFixed(2)}</p>
                                                </div>
                                                :""
                                        )):<div className="tble green disabled">
                                            <p>Deposit Paid</p>
                                            <p>£0.00</p>
                                        </div>}
                                        {propes.bookingState.transaction.map((transaction, index) => (
                                            <div className="tble" key={"t_"+index}>
                                                <p><span className="mr-3" onClick={()=>propes.removeTransaction(transaction)}><Close/></span>{transaction.paymentType === 1 ? 'Pay by App' : ''}{transaction.paymentType === 2 ? 'Card' : ''}{transaction.paymentType === 3 ? 'Cash' : ''}{transaction.paymentType === 4 ? 'Other' : ''}</p>
                                                <p>{propes.currencySymbol}{transaction?.amount?.toFixed(2)}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="border-div detl flex-just-align-center pointer-event" onClick={propes.selectBookingAmount.bind(this,1)}>
                                        <div >
                                            <label className="radio-container">Balance Remaining
                                                <input type="radio" onChange={()=>{}} checked={propes.bookingState.checkOutAmountType} name="radi1" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                       
                                        <h5>{propes.currencySymbol}{propes?.bookingState?.pendingAmount?.toFixed(2)}</h5>
                                    </div>
                                    <div className={(parseFloat(propes?.totalAmount)!=0&& parseFloat(propes?.totalAmount)<0)?"border-div detl flex-just-align-center pointer-event":"border-div detl flex-just-align-center pointer-event disabled"} onClick={(parseFloat(propes?.totalAmount)!=0&& parseFloat(propes?.totalAmount)>0)&&propes.selectBookingAmount.bind(this,2)}>
                                        <div >
                                            <label className="radio-container">Custom Amount
                                                <input type="radio" onChange={()=>{}} name="radi" checked={!propes.bookingState.checkOutAmountType} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>

                                        <div>
                                            <span className="currency-input">{propes.currencySymbol}</span>
                                            <input type="number" name="selectedAmount" value={propes.bookingState.selectedAmount} disabled={propes.bookingState.checkOutAmountType} placeholder="0.00" className="" onChange={propes.onChangedValue}/>

                                        </div>

                                    </div>
                                    {(propes.bookingState.isCardReaderConnected===true&&propes?.totalAmount<1)?<p className='text-danger'>When using a Beu card reader the total amount needs to exceed {propes.currencySymbol}1.</p>:null}
                                </>}
                            {propes.paymentAmountError ?
                                <span className="cm_error_msg">{propes.paymentAmountError}</span> : ''}
                        </div>

                    </div>
                </div>
                {propes.bookingState.isPaymentComplete==0?<>
                    <div className={(parseFloat(propes?.totalAmount)==0 || parseFloat(propes?.totalAmount)<0)?"backgroun-shadow checkout-parent disabled":"backgroun-shadow checkout-parent"}>
                        <div className='payMnt-methdo d-flex align-items-center'>
                            <h5 className='mb-0'>Payment Method </h5>
                            <button className='btn ml-auto bg-black' onClick={()=>propes.handleDiscoverModal()}>
                                <i className='fa fa-plus'></i>
                                {/*<ADDCARDREADER/>*/}
                            </button>
                        </div>
                        <div className="border-div detl pointer-event" onClick={() =>{ 
                            (parseFloat(propes?.totalAmount)!=0&& parseFloat(propes?.totalAmount)>0)&& propes.setPaymentType(2)}}>
                            <div>
                                <label className="radio-container">Card
                                    <input type="radio" onChange={()=>{}} name="radio3" checked={propes.bookingState.selectedPaymentType==2?true:false} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div className="border-div detl pointer-event" onClick={() =>{ (parseFloat(propes?.totalAmount)!=0&& parseFloat(propes?.totalAmount)>0)&& propes.setPaymentType(3)}}>
                            <div>
                                <label className="radio-container">Cash
                                    <input type="radio" onChange={()=>{}} name="radio2" checked={propes.bookingState.selectedPaymentType==3?true:false} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div className="border-div detl pointer-event" onClick={() =>{ (parseFloat(propes?.totalAmount)!=0&& parseFloat(propes?.totalAmount)>0)&& propes.setPaymentType(4)}}>
                            <div>
                                <label className="radio-container">Other
                                    <input type="radio" onChange={()=>{}} name="radio1" checked={propes.bookingState.selectedPaymentType==4?true:false} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        {propes.cardReaderList.length>0?propes.cardReaderList.map((data)=>(
                            <div key={'cr-'+data.id} className="border-div detl pointer-event" onClick={() =>{ 
                                (parseFloat(propes?.totalAmount)!=0&& parseFloat(propes?.totalAmount)>0)&& propes.setPaymentCardReader(data)}}>
                                <div>
                                    <label className={`radio-container ${propes?.bookingState?.isShowConnectionLoader===true? 'inputLoader' : ''}`}>{data.label}
                                        <input type="radio" name={"radio"+data.id} onChange={()=>{}} checked={propes?.bookingState?.selectedCardReader?.id==data.id?true:false} disabled={true} />
                                        {propes?.bookingState?.isShowConnectionLoader===true&&propes?.bookingState?.selectedCardReader?.id==data.id?<LoaderSetting clsName="Inpldr" />:""}
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        )):""}
                        {
                            propes.paymentMethodInfo?.card||propes.paymentMethodInfo?.link?
                            <div className="border-div detl pointer-event" onClick={() =>{
                            (parseFloat(propes?.totalAmount)!=0&& parseFloat(propes?.totalAmount)>0)&& propes.setPaymentType(10)}}>
                            <div>
                            {/*<label className={`radio-container ${propes?.bookingState?.isShowConnectionLoader===true? 'inputLoader' : ''}`}>{propes.paymentMethodInfo?.card?(propes.paymentMethodInfo?.card?.brand +" ****"+propes.paymentMethodInfo?.card?.last4):"Link Card"}*/}
                                <label className={`radio-container ${propes?.bookingState?.isShowConnectionLoader===true? 'inputLoader' : ''}`}>{propes.paymentMethodInfo?.card?("Card on File ("+propes.paymentMethodInfo?.card?.last4+")"):"Card on File (Link.)"}
                            <input type="radio" name={"radio-card"} onChange={()=>{}} checked={propes.bookingState.selectedPaymentType==10?true:false} disabled={true} />
                            <span className="checkmark"></span>
                            </label>
                            </div>
                            </div>:""
                        }
                        {propes.bookingState.cardReaderConnectError ?
                            <span className="cm_error_msg">{propes.bookingState.cardReaderConnectError}</span> : ''}
                    </div>
                    <Modal
                        size="lg"
                        show={propes.bookingState.showDiscoversModal}
                        onHide={propes.handleClose}
                        className="bg_white_modal discoverd-modalWrp"
                    >
                        <i className="fa fa-times cm_hide_mod" onClick={()=>propes.handleClose()} />
                        <Modal.Body>
                            <div className='card-reader-modal-view-wrpp'>
                                <div className='card-reader-list'>
                                    <div className='card-discover-top card-discoversDes'>
                                        <h4>Discovered Card Readers</h4>
                                        {/*{isSearchReader===true?<p>Searching for card readers… <LoaderSetting /></p>:""}*/}
                                        <div className='search-discoveed-reader d-flex flex-wrap align-items-center'>
                                            {/*{isSearchReader===false&&noDevice===true?<p className='mb-0'>No card readers found</p>:""}*/}
                                            {isSearchReader===false?<button className='btn btn-inf ml-auto' onClick={()=>discoverReaders()}>Search for Card Reader</button>:""}
                                        </div>
                                    </div>
                                    {isSearchReader===true?
                                        <div className={`discover-card-reader-lst active-card`}>
                                            <div className='row align-items-center'>

                                                <div className='col-md-3 col-5'>
                                                    <div className='disccover-crd-redr-img'>
                                                        <img className='img-responsive' src={require('../../assets/images/card-readerImg.png')}  />
                                                    </div>
                                                </div>
                                                <div className='col-md-9 col-7'>
                                                    <div className='disccover-crd-redr-descr'>
                                                        <h4>BBPOS WisePOS E</h4>
                                                        <p>Device 2316</p>
                                                    </div>
                                                </div>

                                                <div className='col-md-12 col-12'>
                                                    <div className='card-discover-access-code pt-3 pt-md-4'>
                                                             <p> Turn on your BBPOS WisePOS E and connect it to the same WIFI network as the device running Beu.</p>
                                                                <p className='li-tag'><b>1.)</b> <div>Once connected, swipe from the left and go into setting</div>s</p>
                                                                <p className='li-tag'><b>2.)</b> <div>Enter this admin pin onto the reader <b>07139</b> and then select generate pairing code</div> </p>
                                                                <p className='li-tag mb-5'><b>3.)</b> <div>Enter the 3 word pairing code displayed on your BBPOS WisePOS E</div></p>
                                                        {/* <p>Turn on your BBPOS WisePOS E and connect it to the same WIFI network as the device running Beu. Once connected, please enter the access code displayed on BBPOS WisePOS E.</p> */}
                                                        <div className='form-group'>
                                                            <label>Pairing Code</label>
                                                            <input className='form-control' placeholder='frog-kiss-settle' type="text" value={propes.bookingState.registrationCode} name="registrationCode" onChange={propes.onChangedValue}/>
                                                        </div>
                                                        <div className='form-group'>
                                                            <label>Device Name</label>
                                                            <input className='form-control' type="text" value={propes.bookingState.label} name="label" onChange={propes.onChangedValue}/>
                                                        </div>
                                                        <div className='form-group mb-0 text-center text-lg-right btn-Readerss pt-md-3'>
                                                            <button className='btn mr-md-3 btn-line-blck' onClick={()=>propes.handleClose()}>Cancel </button>
                                                            <button className='btn btn-inf px-md-5' onClick={()=>propes.addStripeTerminal()}>Confirm </button>
                                                        </div>
                                                    </div>
                                                    {cardReaderConnectError ?
                                                        <span className="cm_error_msg">{cardReaderConnectError}</span> : ''}
                                                </div>
                                            </div>
                                        </div>:""}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal></>
                    :""}

                  {(propes?.selectedStaff?.name!=undefined||propes?.selectedStaff?.label!=undefined)?<div className="backgroun-shadow checkout-parent noshadow">
                      {propes.bookingState.isTrStart==false?
                      <div className="d-flex footer-btn">
                        {/*<button className="btn btn-bhite btn-block" onClick={() =>propes.resetBooking()} >Cancel</button>*/}
                        {propes.bookingState.isCardReaderConnected===true||propes.bookingState.selectedPaymentType===10?
                            (propes.bookingState.isCardReaderConnected===true&&propes?.totalAmount<1)?<button className="btn btn-blue btn-block" disabled={true}>
                            Confirm
                        </button>:<button className="btn btn-blue btn-block" onClick={() =>propes.checkout()} disabled={propes.checkCompleteSale()}>
                            Confirm  {propes.bookingState.isCheckoutPayment===true?<LoaderSetting clsName="Inpldr button-loader" />:""}
                        </button>:
                            <button className="btn btn-blue btn-block" onClick={() =>propes.completeSale()} disabled={propes.checkCompleteSale()}>
                                Confirm 
                            </button>}
                    </div>
                      :
                    <div className='d-flex footer-btn'>
                         <button className={`btn btn-blue ${loaders? 'loder_start': 'cancel_start'}`}  onClick={()=>propes.cancelTerminalTransaction()}>{loaders?<MySpinner/>:'Cancel Transaction'}</button>
                     </div>}
                    {propes.bookingState.payoutError ?
                        <span className="cm_error_msg">{propes.bookingState.payoutError}</span> : ''}
                </div>:null}
                
            </div>):""
    )
}

export default Checkout;

