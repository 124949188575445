import React, { Component } from 'react';
import { connect } from 'react-redux';

import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import siteSetting from "../../config/env";
import {hairdresserActions, userActions} from "../../_actions";
import PhoneInput,{isValidPhoneNumber} from 'react-phone-number-input';
import ReactCountryFlagsCurrencySelect from 'react-country-flag-currency-picker'
import 'react-country-flag-currency-picker/css/react-flag-currency-select.css';
import SettingSidebar from './SettingSidebar';
import SettingHeadingTitle from './SettingHeadingTitle';
import heic2any from "heic2any";
import LoaderSetting from "./Loader";
import { MySpinner } from '../SvgIcon';

const options = [
    { value: '1', label: 'Owner'},
    // { value: '2', label: 'Account Manager'},
    { value: '3', label: 'Employed' },
    { value: '4', label: 'Self-Employed' },
];

class ProfileSettings extends Component {

    constructor(props){
        super(props);

        this.state = {
            name:'',
            first_name:'',
            last_name:'',
            email:'',
            phoneNumber:'',
            jobTitle:'',
            shortBio:'',
            circleImagePrev:require('../../assets/images/user-placeholder.png'),
            circleImage:require('../../assets/images/user-placeholder.png'),
            countryId:'217',
            salonId:localStorage.getItem('salonId'),
            employmentTypeId:1,
            employmentType:{ value: '1', label: 'Employee' },
            deviceToken:'dsfsdf',
            deviceTypeId:'2',
            successMessage:'',
            currency:"",
            currencySymbol:"",
            currencyCountry:"",
            isEdit:false,
            loader:false
        }
        this.editProfile=this.editProfile.bind(this);
        this.onDataChange=this.onDataChange.bind(this);
        this.cancel=this.cancel.bind(this);
        this.getPhoneNumber=this.getPhoneNumber.bind(this);
    }

    componentDidMount() {
        let employmentType={};
        var self = this;
        if (localStorage.getItem('accessToken')) {
            let params={
                defaultRedirectTo:localStorage.getItem('defaultRedirectTo')
            }
            params.latitude = siteSetting.default_latitude;
            params.longitude = siteSetting.default_longitude;
            self.props.dispatch(userActions.profile(params))
                .then(function (response) {
                    //console.log("response",response.data.data);
                    if(response.data.data) {
                        if(response.data.data.userInfo.hairdresser.isSalonOwner===1){
                            employmentType={ value: '1', label: 'Owner'}
                        }else if(response.data.data.userInfo.hairdresser.isSalonOwner===2){
                            employmentType={ value: '4', label: 'Self-Employed'}
                        }else if(response.data.data.userInfo.hairdresser.isSalonOwner===0) {

                            if (response.data.data.userInfo.hairdresser.employmentTypeId === 1) {
                                employmentType = {value: '3', label: 'Employed'}
                            } else {
                                self.setState({employmentTypeId:4},()=>{
                                    employmentType = {value: '4', label: 'Self-Employed'}
                                })
                              
                            }
                        }
                        let phoneNumber=response.data.data.userInfo.phoneNumber?self.getPhoneNumber(response.data.data.userInfo.phoneNumber):"";
                        console.log("phoneNumber",response.data.data.userInfo.hairdresser.employmentTypeId);
                        self.setState(
                            {
                                isSalonOwner:response.data.data.userInfo.hairdresser.isSalonOwner,
                                employmentTypeId:employmentType.value,
                                employmentType:employmentType,
                                first_name: response.data.data.userInfo.first_name&&response.data.data.userInfo.first_name!==""?response.data.data.userInfo.first_name:response.data.data.userInfo.name,
                                last_name:response.data.data.userInfo.last_name,
                                email: response.data.data.userInfo.email,
                                phoneNumber: phoneNumber,
                                jobTitle: response.data.data.userInfo.hairdresser.jobTitle,
                                shortBio: response.data.data.userInfo.hairdresser.shortBio,
                                circleImagePrev:response.data.data.userInfo.userImage.circleImage?response.data.data.userInfo.userImage.circleImage:require('../../assets/images/user-placeholder.png'),
                                currency:response.data.data.userInfo.hairdresser.currency?response.data.data.userInfo.hairdresser.currency:"",
                                currencySymbol:response.data.data.userInfo.hairdresser.currencySymbol?response.data.data.userInfo.hairdresser.currencySymbol:"",
                                currencyCountry:response.data.data.userInfo.hairdresser.currencyCountry?response.data.data.userInfo.hairdresser.currencyCountry:"GB"
                            }
                        )
                        let currencyCountry=response.data.data.userInfo.hairdresser.currencyCountry?response.data.data.userInfo.hairdresser.currencyCountry:"GB"
                        // console.log("currencyCountry",currencyCountry);
                        self.refs.userCurrency.updateSelected(currencyCountry)
                    }
                })
        }else{
            this.props.history.push('/')
        }
    }

    editProfile(){
        this.setState({
            error:'',
            emailError:'',
            lNameError:'',
            fNameError:'',
            phoneNumberError:'',
            jobError:''
        })
        let self = this;
        let params = {};
        params.name=this.state.first_name+" "+this.state.last_name;
        params.first_name=this.state.first_name;
        params.last_name=this.state.last_name;
        params.email=this.state.email;
        params.phoneNumber=this.state.phoneNumber;
        params.jobTitle=this.state.jobTitle;
        params.shortBio=this.state.shortBio;
        params.employmentTypeId=this.state.employmentTypeId;
        params.salonId=this.state.salonId;
        params.countryId=this.state.countryId;

        if (this.state.first_name === "" || this.state.first_name === undefined) {
            this.setState({
                fNameError: 'Please enter first name'
            })
        } else if (this.state.last_name === "" || this.state.last_name === undefined) {
            this.setState({
                lNameError: 'Please enter last name'
            })
        } else if (this.state.email === "" || this.state.email === undefined) {
            this.setState({
                emailError: "Please enter your email address"
            })
        } else if (!this.validateEmail(this.state.email)) {
            this.setState({
                emailError: 'Please enter a valid email address'
            })
            //|| !isValidPhoneNumber(this.state.phoneNumber)
        } else if (this.state.phoneNumber === "" || this.state.phoneNumber === undefined ) {
            this.setState({
                phoneNumberError: 'Please enter a valid phone number'
            })
        } else if (this.state.jobTitle === "" || this.state.jobTitle === undefined) {
            this.setState({
                jobError: 'Please enter a job title'
            })
        }
        // else if (this.state.shortBio === "" || this.state.shortBio === undefined) {
        //     this.setState({
        //         shortBioError: 'Please enter a short bio'
        //     })
        // }
        else {
            self.setState({
                loader:true
            })
            let data = new FormData();
            data.append('hairdresserId', localStorage.getItem('userId'));
            data.append('name', this.state.first_name+" "+this.state.last_name);
            data.append('first_name', this.state.first_name);
            data.append('last_name', this.state.last_name);
            data.append('email', this.state.email);
            data.append('phoneNumber', this.state.phoneNumber);
            data.append('jobTitle', this.state.jobTitle);
            data.append('shortBio', this.state.shortBio);
            // data.append('employmentTypeId', this.state.employmentTypeId);
            data.append('salonId', this.state.salonId);
            data.append('countryId', this.state.countryId);
            data.append('circleImage', this.state.circleImage);
            data.append('rectangleImage', this.state.circleImage);
            data.append('deviceToken', this.state.deviceToken);
            data.append('deviceTypeId', this.state.deviceTypeId);
            data.append('defaultRedirectTo', 1);
            data.append('currency', this.state.currency);
            data.append('currencySymbol', this.state.currencySymbol);
            data.append('currencyCountry', this.state.currencyCountry);

            if(this.state.employmentTypeId!==0){
                let employmentTypeId=1;
                if(this.state.employmentTypeId===4||this.state.employmentTypeId==="4"){
                    employmentTypeId=2
                }
                if(this.state.employmentTypeId===3||this.state.employmentTypeId==="3"){
                    if(this.state.ownerId!==''){
                        data.append('ownerId', this.state.ownerId);
                    }
                }
                let isSalonOwner=0;
                if(self.state.isSalonOwner===1){
                    if(this.state.employmentTypeId===1||this.state.employmentTypeId==="1"){
                        isSalonOwner=1;
                    }else if(this.state.employmentTypeId===2||this.state.employmentTypeId==="2"){
                        isSalonOwner=2;
                    }
                }
                data.append('employmentTypeId', employmentTypeId);
                data.append('isSalonOwner', isSalonOwner);
            }

            self.props.dispatch(hairdresserActions.updateHairdresser(data,localStorage.getItem('userId')))
                .then(function (response) {
                    //console.log('client response', response);

                    if (response.data.errorCode) {
                        if(response.data.errorCode===51){
                            self.setState({
                                emailError: response.data.messages
                            })
                        }else if(response.data.errorCode===57){
                            self.setState({
                                phoneNumberError: response.data.messages
                            })
                        }else{
                            self.setState({
                                error: response.data.messages
                            })
                        }

                    } else {
                        //self.props.history.push('/setting')
                        self.setState({
                            isEdit:false,
                            successMessage:'Your profile has been updated successfully!'
                        })
                    }
                })
                .catch((err) => {
                    console.log("errr",err);
                    //dispatch(failure(err));

                })
        }
    }

    onChanged=async (e)=>{
        let self=this;
        this.setState({ [e.target.name]: e.target.value, copied: false });

        if (e.target.name === 'email') {
            if (!this.validateEmail(e.target.value)) {
                this.setState({
                    emailError: 'Please enter a valid email address'
                })
            } else {
                this.setState({
                    emailError: ''
                })
            }
        }

        if (e.target.name === 'first_name') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    fNameError: ''
                })
            } else {
                this.setState({
                    fNameError: '',
                })
            }
        }

        if (e.target.name === 'last_name') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    fNameError: ''
                })
            } else {
                this.setState({
                    lNameError: '',
                })
            }
        }

        if (e.target.name === 'phoneNumber') {
            if (e.target.value.length!==10) {
                this.setState({
                    phoneNumberError: 'Please enter a valid phone number'
                })
            } else {
                this.setState({
                    phoneNumberError: '',
                })
            }
        }

        if (e.target.name === 'jobTitle') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    jobError: ''
                })
            } else {
                this.setState({
                    jobError: '',
                })
            }
        }

        // if (e.target.name === 'shortBio') {
        //     if (e.target.value === '' || e.target.value === null||e.target.value.length<10) {
        //         this.setState({
        //             shortBioError: 'The shortBio must be at least 10 characters.'
        //         })
        //     } else {
        //         this.setState({
        //             shortBioError: '',
        //         })
        //     }
        // }

        if (e.target.name === 'circleImage') {
            // console.log("e",e);
            let reader = new FileReader();
            let file = e.target.files[0];

            var fileType = file["type"]; // get file MIME type
            console.log("fileType",file,fileType);
            var ValidImageTypes = ["image/jpg", "image/jpeg", "image/png", "image/gif", "image/bmp"];

            if (file && file.type === "image/heic") {
                let fileDate=await self.convertToJpeg(file);

                let {url,blob}=fileDate;

                // console.log("url,blob",url,blob);
                this.setState({
                    circleImagePrev: url
                })
                this.setState({
                    circleImage: blob
                });
            }else{
                reader.onloadend = () => {
                    this.setState({
                        circleImagePrev: reader.result
                    });
                }
                reader.readAsDataURL(file)

                this.setState({
                    circleImagePrev: file
                })
                this.setState({
                    circleImage: e.target.files[0]
                });
            }
        }
    }

     convertToJpeg = async (file) => {
        try {
            const blob = await heic2any({ blob: file, toType: "image/jpeg" });
            const url = URL.createObjectURL(blob);
            //console.log("blob",blob);
            return {url,blob};
        } catch (error) {
            console.error("Conversion error:", error);
        }
    };

    onDataChange(value, action){
        this.setState({
            [action.name]: value,
            employmentTypeId:value.value
        })
    }

    validateEmail(email) {
        const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const result = pattern.test(email);
        return result;
    }

    cancel(){
        this.props.history.push('/settings')
    }

    handlePhoneChange = val => {
        this.setState({phoneNumber: val})
        // if(!isValidPhoneNumber(val)) {
        //     this.setState({
        //         phoneNumberError: 'Please enter a valid phone number'
        //     })
        // }
    }

    getPhoneNumber(phoneNumber){
        let phone=phoneNumber.toString()
        //console.log("phone",phone);
        if(phone.length===10){
            //console.log("phone1",phone);
            if (phone[0] === 0||phone[0] === "0") {
                //console.log("phone1.1",phone);
                return "+44"+phone.substr(1)
            }else{
                //console.log("phone1.2",phone);
                return "+44"+phone
            }
        }else {
            //console.log("phone2",phone);
            if (phone[0] === 0||phone[0] === "0") {
                //console.log("phone2.1",phone);
                return "+44"+phone.substr(1)
            } else if (phone[0] === 4||phone[0] === "4") {
                //console.log("phone2.2",phone);
                return "+" + phone
            } else {
                //console.log("phone2.3",phone);
                return "+44"+phone
            }
        }
    }

    checkReadOnly(){
        if(this.props.user&&this.props.user.userInfo&&this.props.user.userInfo.hairdresser.isSalonOwner===1){
            return false;
        }else{
            return true;
        }
    }

    onSelectCurrency=(country)=>{
        //console.log("country",country);
        this.setState({
            currency:country.currency,
            currencySymbol:country.symbol,
            currencyCountry:country.countryCode,
        })
    }
    componentWillUnmount(){
        document.body.classList.remove('setting-temps-pages');
    }

    editStatusChange=(status)=>{
        this.setState({
            isEdit:status
        })
    }

    render() {
        console.log("this.state",this.state);
        return (
            <div className="h_n">
            <div className="body_container cm_bg_white setting_page">

                <TopHeader title="Profile Settings" {...this.props}/>
                <SideNav {...this.props}/>

                <div className='new-setting-wrapper'>
                    <div className='container'>
                        <div className='row '>
                            <div className='col-md-4'>
                                <SettingHeadingTitle title="Profile SalonSettings" />
                                <SettingSidebar />
                            </div>
                            <div className='col-md-8'>
                                <div className='salon-body-main-wrp profile-setting-temp'>
                                    <div className='new-stng-cmn saloon-settings-wrp'>
                                        <div className="row align-items-center">
                                            <div className='col-md-6'>
                                                <div className='form-group'>
                                                    <div className="file_upload" >
                                                        {this.state.isEdit===true?
                                                        <><img src={this.state.circleImagePrev} alt="staff" onClick={(e) => this.circleImage.click()}/>
                                                        <input ref={(ref) => this.circleImage = ref} aria-hidden="true" type="file" name="circleImage" id="circleImage" onChange={this.onChanged} accept="image/*, .heic"/>
                                                        <i className="fa fa-pencil"  /></>
                                                            :<img src={this.state.circleImagePrev} alt="staff" />}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6 d-lg-block d-none'>
                                                <div className='form-group'>
                                                    <label>Currency</label>
                                                    <ReactCountryFlagsCurrencySelect className="form-control CurrencySelect"
                                                        defaultCountry={this.state.currencyCountry}
                                                        showOptionLabel={false}
                                                        showCurrency={true}
                                                        showCurrencySymbol={true}
                                                        showSelectedLabel={false}
                                                        onSelect={this.onSelectCurrency}
                                                        ref="userCurrency"
                                                        //disabled={this.state.isEdit===true?false:"disabled"}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='form-group'>
                                                    <label>First Name</label>
                                                    <input type="text" className="form-control" name="first_name" value={this.state.first_name} onChange={this.onChanged} disabled={this.state.isEdit===true? "":"disabled"}/>
                                                    {this.state.fNameError ?
                                                        <span className="cm_error_msg">{this.state.fNameError}</span> : ''}
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='form-group'>
                                                    <label>Surname</label>
                                                    <input type="text" className="form-control" name="last_name" value={this.state.last_name} onChange={this.onChanged} disabled={this.state.isEdit===true? "":"disabled"}/>
                                                        {this.state.lNameError ?
                                                            <span className="cm_error_msg">{this.state.lNameError}</span> : ''}
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='form-group'>
                                                    <label>Email</label>
                                                    <input type="email" className="form-control" name="email" value={this.state.email} onChange={this.onChanged} disabled={this.state.isEdit===true? "":"disabled"}/>
                                                        {this.state.emailError ?
                                                            <span className="cm_error_msg">{this.state.emailError}</span> : ''}
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='form-group'>
                                                    <label>Mobile Number</label>
                                                    {}
                                                    <PhoneInput
                                                        international={true}
                                                         defaultCountry={this?.state?.currencyCountry!=''?this?.state?.currencyCountry:"GB"}
                                                         country={this?.state?.currencyCountry!=''?this?.state?.currencyCountry:"GB"}
                                                        className="form-control"
                                                        placeholder="Phone Number"
                                                        name="phoneNumber"
                                                        value={this.state.phoneNumber?this.state.phoneNumber:""}
                                                        disabled={this.state.isEdit===true? "":"disabled"}
                                                        onChange={this.handlePhoneChange}/>
                                                    {/*<input type="number" className="form-control" name="phoneNumber" value={this.state.phoneNumber} onChange={this.onChanged} max={10} min={10}/>*/}
                                                    {this.state.phoneNumberError ?
                                                        <span className="cm_error_msg">{this.state.phoneNumberError}</span> : ''}
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='form-group'>
                                                    <label>Job Title <span className='text-grey'>(viewable when making online booking)</span></label>
                                                    <input type="text" className="form-control" value={this.state.jobTitle} name="jobTitle" onChange={this.onChanged} disabled={this.state.isEdit===true? "":"disabled"}/>
                                                    {this.state.jobError ?
                                                        <span className="cm_error_msg">{this.state.jobError}</span> : ''}
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='form-group'>
                                                    <label>Employment Type</label>
                                                        {/*<input type="text" className="form-control" />*/}
                                                    <Select
                                                        isDisabled={true}
                                                        options={options}
                                                        classNamePrefix="custom selct-stng"
                                                        name="employmentType"
                                                        //onChange={this.onDataChange}
                                                        value={this.state.employmentType}
                                                    />
                                                </div>
                                            </div>
                                            {/*<div className='col-md-6 d-lg-none d-block'>*/}
                                            {/*    <div className='form-group'>*/}
                                            {/*        <label>Currency</label>*/}
                                            {/*        <ReactCountryFlagsCurrencySelect className="form-control CurrencySelect"*/}
                                            {/*            defaultCountry={this.state.currencyCountry}*/}
                                            {/*            showOptionLabel={false}*/}
                                            {/*            showCurrency={true}*/}
                                            {/*            showCurrencySymbol={true}*/}
                                            {/*            showSelectedLabel={false}*/}
                                            {/*            onSelect={this.onSelectCurrency}*/}
                                            {/*            //disabled={this.state.isEdit===true? "":"disabled"}*/}
                                            {/*            ref="userCurrency"*/}
                                            {/*        />*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className='col-md-12'>
                                                <div className='form-group'>
                                                    <label>Bio</label>
                                                    <textarea className="form-control" value={this.state.shortBio} name="shortBio" onChange={this.onChanged} disabled={this.state.isEdit===true? "":"disabled"}/>
                                                    {this.state.shortBioError ?
                                                        <span className="cm_error_msg">{this.state.shortBioError}</span> : ''}
                                                </div>
                                            </div>
                                            {this.state.isEdit===false? <div className='col-md-12'>
                                                <div className='form-group text-right'>
                                                    <button className='btn btn-inf' onClick={()=>this.editStatusChange(true)}>Edit</button>
                                                </div>
                                            </div> : ''}
                                            <div className='col-md-12'>
                                                {this.state.isEdit===true?
                                                    <div className='form-group text-right editSaloon pt-md-2'>
                                                <button className="btn btn-outline-dark pl-4 pr-4 ml-3" onClick={()=>this.editStatusChange(false)}>Cancel</button>
                                                <button type="button" className="btn btn-dark ml-3 pl-4 pr-4" onClick={this.editProfile.bind(this)}>Save
                                                    {this.state.loader===true?<MySpinner clsName="button-loader" />:""}
                                                </button>
                                                </div>
                                                    : ''}
                                                <div className='form-group'>
                                                {this.state.error ?
                                                 <span className="cm_error_msg">{this.state.error}</span> : ''}
                                                {this.state.successMessage ?
                                                 <span className="cm_succ_msg">{this.state.successMessage}</span> : ''}
                                                </div>
                                            </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </div>
        </div>
        )
    }

}



function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(ProfileSettings);





