import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { Link, useHistory } from "react-router-dom";

export const history = createBrowserHistory();


class SettingHeadingTitle extends Component {

    constructor(props){
        super(props);
        this.state = {

        }
    }

    handleOpenNavbar = ()=>{
        // document.querySelector('body').classList.toggle('setting-temps-pages')
        if(document.body.classList.contains('setting-temps-pages')){
            // document.querySelector('body').classList.remove('setting-temps-pages')
            // document.body.classList.toggle('nav_menu_open')
        }
        history.push('/settings')
    }



    
    render() {
        return (
            <>
                <div className='top-header-setting-title d-lg-none'>
                    <Link className='btn' to="/settings">
                        <i className='fa fa-chevron-left'></i>
                    </Link>
                    <h5>{this.props.title}</h5>
                </div>
            </>
        )
    }

}




export default SettingHeadingTitle;





