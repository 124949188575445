import React, { Component } from "react";
import TopHeader from "../template/TopHeader";
import SideNav from "../template/SideNav";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { hairdresserActions, userActions } from "../../_actions";
import { productAction } from "../../_actions/product.action";
import { Plus } from "../SvgIcon";



function debounce(func, delay) {
    let timer;
    return function() {
      const context = this;
      const args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay);
    };
  }
class SelectClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startTime:this.props.location.state&&this.props.location?.state?.pageLoadingStatus===0&&this.props.location.state.start&&this.props.location.state.start!==""?this.props.location.state.start:"",
            hairdresserClient:[],
          isLoading:true,
          searchQuery:this.props.location.state&&this.props.location.state.searchQuery?this.props.location.state.searchQuery:"",
          tempClientRecord:[],
          currentLoadingStatus:0,
          previousPage:this.props.location.state&&this.props.location?.state?.pageLoadingStatus===0&&this.props.location.state.previousPageUrl&&this.props.location.state.previousPageUrl!==""?this.props.location.state.previousPageUrl:"",
        }
        this.debouncedSearch = debounce(this.localDataFilter, 100);
        this.searchClient = this.searchClient.bind(this);
    }

    searchClient(e) {
        let self = this;
           self.setState({
            searchQuery: e.target.value,
          },
          function () {
            this.debouncedSearch();
          }
        )
    }

    componentDidMount() {
      let self = this;
      const {hairdresserClient } = this.props;
      const { state } = self?.props?.location;
      if (state) {
        console.log("state",state);
        const { pageLoadingStatus , previousPageUrl, start} = state;
        if(pageLoadingStatus===0){
          self.setState({
            currentLoadingStatus:pageLoadingStatus,
            previousPage:previousPageUrl,
            startTime:start,
          })
        }
      }
      if (localStorage.getItem("accessToken")) {
        //const hairdresserId = this?.props?.match?.params?.hairdresserId!== undefined?this?.props?.match?.params?.hairdresserId:localStorage.getItem("userId");
        const hairdresserId = localStorage.getItem("userId");

        let params = {
          searchKey: '',
          hairdresserId: hairdresserId,
          page: 1,
          listType: 1,
          isDownload: 0,
        };
      

        if(hairdresserClient?.client?.length<0 || hairdresserClient?.client?.length===undefined){
           this.getHairdresserClient(params);
        }else{
          this.setState({
            hairdresserClient:hairdresserClient?.client,
            tempClientRecord:hairdresserClient?.client,
            isLoading:false
          })
        }
        if(self.state.searchQuery!==""){
            self.setState({
                isLoading:true
            })
            self.getHairdresserClient(params)
                .then(function (res) {
                    self.localDataFilter();
                })
        }
      } else {
        this.props.history.push("/");
      }
    }


    getHairdresserClient(params) {
      var self = this;
      return self.props.dispatch(hairdresserActions.getHairdresserClient(params))
    }
  
    componentDidUpdate(prevProps, prevState) {
      const _hairdresser =this.props.hairdresserClient;
      if (_hairdresser !== prevProps.hairdresserClient) {
        if(_hairdresser.client !==undefined){
        const sortedClient =  _hairdresser?.client?.sort((a, b) => a.name - b.name);
          this.setState({
            hairdresserClient:sortedClient,
            tempClientRecord:sortedClient,
            isLoading:false
          })

        }else{
          this.setState({
            hairdresserClient:[],
            tempClientRecord:[],
            isLoading:false
          })
        }
      }
    }

     _searchWord=(line,word)=>{
        // console.log("=====",line,word,line.indexOf(word));
         if (line.indexOf(word) > -1) {
             return true;
         } else {
             return false;
         }
    }
    localDataFilter = () =>{
      const { hairdresserClient, searchQuery, tempClientRecord } = this.state;
      const filteredClient = tempClientRecord?.length > 0 ? tempClientRecord?.filter(person =>
        person.name.toLowerCase().startsWith(searchQuery.toLowerCase())||this._searchWord(person.name.toLowerCase(),searchQuery.toLowerCase())
      ) : [];
        //console.log("searchQuery",searchQuery);
      if(searchQuery===''){
        this.setState({
          hairdresserClient:tempClientRecord
        })
      }else{
        this.setState({
          hairdresserClient:filteredClient
        })
      }
    }

     handleClick(selectedClient){
      const { dispatch,history } = this.props;
      selectedClient.indexId = +this?.props?.match?.params?.indexId;
      let currentPageUrl = history.location.pathname;
      //selectedClient.indexId = +this?.props?.match?.params?.indexId;
      dispatch(productAction.selectedClient(selectedClient));
      const stateObj = { pageLoadingStatus: this.state.currentLoadingStatus, previousPageUrl:currentPageUrl, start:this.state.startTime};
      const url =  this.state.previousPage;
       history.push({
        pathname: url,
        state: stateObj,
      });
     }

     handleShowAddClientModal = () =>  {
        let self=this;
        // propes.history.push('/clients')
         let { state } = self?.props?.location;
         if (state) {
             let {pageLoadingStatus, previousPageUrl, start} = state;
             let stateObj=self.props?.location?.state;
             // self.props.history.push('/clients/booking/add',{pageLoadingStatus, previousPageUrl, start});
             self.props.history.push({
                 pathname: '/clients/booking/add',
                 state: stateObj,
             });
         }else{
             self.props.history.push('/clients/booking/add',);
         }
    }

    checkEmail = (email) => {
        let emailArr = email.split("@");
        if (emailArr[1] === "beuapp.com") {
            return false;
        } else {
            return true;
        }
    };

  render() {
        console.log("this.state",this.state);
      console.log("this.props",this.props);
    return (
        <div className="h_n">
          <div className="body_container cm_bg_white">
            <TopHeader 
            headerButtonStatus={true}
            cancelButton={true}
            saveBookingButton={false}
            checkoutButton={false}
            title="Clients" 
               {...this.props}
            previousPageUrl={this.state.previousPage}
            />
            <SideNav {...this.props} />
            <div className="container">
              <div className="checkout-client">
                <div className="cm_search_wrapper client_search  mt-3 mb-3">
                <input
                    type="text"
                    className="form-control"
                    value={this.state.searchQuery}
                    placeholder="Search…"
                    name="searchKey"
                    onChange={this.searchClient}
                />
                <i className="fa fa-search" />
                </div>
                <button className="btn btn_full_plus mb-3" onClick={()=>this.handleShowAddClientModal()}><span><Plus/></span>Add New Client</button>
                <div className="table-responsive client_table mt-0">
                        <table className="table">
                            {/* <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead> */}
                            <tbody>
                              {this.state.hairdresserClient?.length>0?this.state.hairdresserClient.map((client,i)=>(<tr  key={i+'_client'} className="pointer-event" onClick={()=>this?.handleClick(client)}>
                                    <td className="alfa_text">
                                      <h3 className="cm_fw_b">{(this.state.hairdresserClient[i - 1] && this.state.hairdresserClient[i]?.nameLower?.[0] !== this.state.hairdresserClient[i - 1]?.nameLower?.[0]) || i == 0 ? client?.nameLower?.[0]?.toUpperCase() : ""}</h3>
                                    </td>
                                    <td><p>{client?.name}</p><p>{this.checkEmail(client.email)
                                        ? client.email
                                        : "--"}</p></td>
                                </tr>)):<tr>{(!this.state.isLoading && this.state.hairdresserClient?.length===0)?<td colSpan={3}><p className="text-center">Client not found.</p></td>:<td colSpan={3}><p className="text-center">Loading...</p></td>}</tr>}
                            </tbody>
                        </table>
                </div>
              </div>
  
             
            </div>
          </div>
        </div>
      );
  }
}
function mapStateToProps(state) {
    return {
      ...state,
    };
  }
  
  export default connect(mapStateToProps)(SelectClient);