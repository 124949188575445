import React, { Component } from "react";
import TopHeader from "../template/TopHeader";
import SideNav from "../template/SideNav";
import { withRouter } from "react-router-dom";
import { productAction } from "../../_actions/product.action";
import { connect } from "react-redux";

class SelectProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      productList: [],
      limit: 20,
      search: "",
      currencySymbol:'£',
      currentLoadingStatus:0,
      previousPage:'',
    };
  }

  searchProduct = (event) => {
    this.setState({ search: event.target.value }, this.listProduct);
  };

  componentDidMount() {
    let self = this;
    const { state } = self.props.location;
    if (state) {
      const { pageLoadingStatus , previousPageUrl} = state;
      if(pageLoadingStatus===0){
        self.setState({
          currentLoadingStatus:pageLoadingStatus,
          previousPage:previousPageUrl,
        })
      }
    }
    this.listProduct();
  }

  listProduct = () => {
    const { search, page, limit } = this.state;
    // const salonId = this?.props?.match?.params?.salonId;
    const salonId = localStorage.getItem("salonId");
    const requestData = {
      salonId:salonId
    };

    this.props
      .dispatch(productAction.getProductsList(requestData))
      .then((response) => {
        let responseList = response?.data?.responseData.result;
        if (search) {
          responseList = responseList?.filter((product) =>
            product.productName.toLowerCase().includes(search.toLowerCase())
          );
        }
        this.setState({
          productList :responseList ,page,limit
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  handleClick(product){
   const { dispatch,history } = this.props;
   product.indexId = +this?.props?.match?.params?.indexId;
   let currentPageUrl = history.location.pathname;
   product.retailPrice = product.retailPrice.toFixed(2);
   dispatch(productAction.selectCheckoutItem(product));
   const stateObj = { pageLoadingStatus: this.state.currentLoadingStatus, previousPageUrl:currentPageUrl};
   const url =  this.state.previousPage;
    history.push({
     pathname: url,
     state: stateObj,
   });
   
  }
  
  
  render() {
    const { search, productList ,currencySymbol} = this.state;
    return (
      <div className="h_n">
        <div className="body_container cm_bg_white">
          <TopHeader title="Select a Product" 
          headerButtonStatus={true}
          cancelButton={true}
           {...this.props} 
           
           />
          <SideNav {...this.props} />
          <div className="container">
            <div className="row ">
              <div className="col-md-1"></div>
              <div className="cm_search_wrapper client_search m-0 col-md-10 mt-4">
                <input
                  type="text"
                  className="form-control "
                  placeholder="Search…"
                  name="search"
                  value={search}
                  onChange={this.searchProduct}
                />
                <i className="fa fa-search mr-4" />
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
          <div className="container">
            <div className="row black_heading">
              <div className="col-md-1"></div>
              <div className="col-md-10 d-flex product">
                <div className="col-md-6 ">
                  <h5>Product Name</h5>
                </div>
                <div className="col-md-6 text-right">
                  <h5>Pricing</h5>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>

          <div className="container">
            <div className="row black_heading">
              <div className="col-md-1"></div>
              <div className="col-md-10 product_data p-0 overflow-scroll">
                <table className="table table-striped border-0">
                  <tbody className="">
                    {productList?.map((product,i) => (
                      <tr key={'product_'+i} className="product_row" onClick={() => this.handleClick(product)}>
                        <td className="align-middle border-0">
                          <span className="text-dark pl-4">{product.productName}</span>
                        </td>
                        <td className="text-right border-0 m-0">
                          <span className="text-dark pr-4">{currencySymbol}{(product.retailPrice).toFixed(2)}</span> <br />{" "}
                          <span className="text-info pr-4 font_design">{currencySymbol}{(product.stockPrice).toFixed(2)}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(withRouter(SelectProduct));
