import React, { useEffect, useState } from "react";
import { connect} from "react-redux";
import animationData from '../../assets/animation-data/loader.json'
import { Modal } from "react-bootstrap";
import {paymentService} from "../../_services";
import { loadStripe } from '@stripe/stripe-js';
import siteSetting from "../../config/env";
import toast from "react-toast-notification";

const stripePromise = loadStripe(siteSetting.stripeKey);

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
  }
};

function AddCard(props) {
  const[showmodal,setShowModal]=useState(false)

  useEffect(() => {
    // Add or remove class from body based on modal state
    if (showmodal) {
      document.body.classList.add('add_card_modal_open');
    } else {
      document.body.classList.remove('add_card_modal_open');
    }
  }, [showmodal]);


  useEffect(() => {
      checkpaymentMethodAdded();
   /* eslint-disable-next-line */
  }, []);

  const checkpaymentMethodAdded = () => {
    let params={};
    paymentService.checkCardAdded(params)
        .then(function(res){
          if(res.data.statusCode===1) {
            console.log('check saved card', res.data.responseData?.result?.data);
            if(res?.data?.responseData?.result?.data?.length>0){
              //return true;
              setShowModal(false);
            }else{
              //return false;
              setShowModal(true);
            }
          }else{
            //return false;
            setShowModal(true);
          }
        })
  };

  const paymentMethodAdd = async () => {
    let params={};
    const stripe = await stripePromise;
    paymentService.paymentMethodAdd(params)
        .then(function(res){
          if(res.data.statusCode===1) {
            console.log("res", res.data.responseData?.result);
            let sessionId=res.data.responseData?.result.id;
            if(sessionId!=="") {
              stripe.redirectToCheckout({
                sessionId: sessionId,
              }).then(function (res) {
                console.log("stripe res=", res);

              }).catch(function (error) {
                toast.error(error.messages);
                console.log("stripe error=", error);
              })
            }else{
              toast.error("Payment gateway error");
            }
          }else{
            //return false;
          }
        })
  };

  return (
       <Modal
            size="lg"
            show={showmodal}
            centered
            backdropClassName="show_sidenav_topbar"
            className="discoverd-modalWrp menus_open_modal"
             >
                        <Modal.Body>
                        <div className="new-stng-cmn _sms_reminder shadow-none" style={{marginBottom:"0px !important"}}>
                          <div className="_image">
                            <img
                              alt={'sms_preview'}
                              src={require("../../assets/images/card_svg.svg")}
                              className="img-fluid mb-3"
                            />
                          </div>
                          <div className="_text">
                            <div className="_head d-flex">
                              <h4 className="mb-4">Add Card Details</h4>
                            </div>
                            <p>
                            Please add your card details to access the Beu marketing suite. This is required for any SMS used outside your free allocation
                            </p>
                            <button className="btn btn-dark-blue px-5 mt-4" onClick={paymentMethodAdd}>
                            Add Card Details
                            </button>
                          </div>
                        </div>
                        </Modal.Body>
           </Modal>)
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(AddCard);
