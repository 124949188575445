import React, { Component } from 'react';
import { connect } from "react-redux";
import Banner from './Banner';
import Stats from './Stats';
import Feature from './Feature';
import SelfEmployed from './SelfEmployed';
import BusinessManagement from './BusinessManagement';
import BeuMarketplace from './BeuMarketplace';
import Plan from './Plan';
import RequestDemo from './RequestDemo';
import WebFooter from '../template/webFooter';
import {Helmet} from "react-helmet";
import ReactPixel from 'react-facebook-pixel';

import { TraingleShape } from '../SvgIcon'
class Home extends Component {
  constructor(props){
    super(props);
    this.state = {
      loader: false,
    }
    // window.location.assign('https://www.beuapp.com');
    }

    componentDidMount(){
      // setTimeout(()=>{
      //   this.setState({loader: false})
      // },2500)
      ReactPixel.pageView(); // For tracking page view
      document.body.classList.add('login-home-temp');
    }

    render() {
      if(this.state.loader){
        return <div className="cm_loader"><div className="loader"></div></div>
      }
        return (
          <>
              <Helmet>
                  <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/6678918.js"></script>
              </Helmet>
            <div className="container-fluid pad-0">
                <Banner/>
            </div>
            <div className="container management_platform ">
             <p>The best management platform for your business, <br/>as seen in…</p>
             <div className="row">
                 <div className="col-sm-3 col-6">
                   <img src={require('../../assets/images/web-img/bussiness1.png')} className="img-fluid mb-3" />
                 </div>
                 <div className="col-sm-3 col-6">
                   <img src={require('../../assets/images/web-img/bussiness2.png')} className="img-fluid mb-3" />
                 </div>
                 <div className="col-sm-3 col-6">
                   <img src={require('../../assets/images/web-img/bussiness3.png')} className="img-fluid mb-3" />
                 </div>
                 <div className="col-sm-3 col-6">
                   <img src={require('../../assets/images/web-img/bussiness4.png')} className="img-fluid mb-3" />
                 </div>
             </div>
            </div>
            <div className="container-fluid pad-0 happy_business_parent">
                    <div className="container">
                        <div className=" happy_business text-center text-md-left">
                            <div className="text">
                                <h4 className="text-center text-md-left">Your <span className="midfont">one-stop-shop</span> for a <br/>happy business</h4>
                                <p>Beu provides you with the insight and analytics that you need to grow your business. Available on all internet connected devices, and working 24/7, Beu is the front of house that never stops.</p>
                            </div>
                            <div className="img">
                                <img src={require('../../assets/images/web-img/happy_business.png')} className="img-fluid mb-3" />
                            </div>

                        </div>
                    <img src={require('../../assets/images/web-img/2nd-dot.png')} className="img-fluid dot mb-3" />
                    </div>
                    <div className="container mt-4 pb-5">
                     <Stats/>
                    </div>
            </div>
            <div className="container-fluid pad-0">
              <p className="traingleshap"></p>
            </div>
            <div className="container-fluid pad-0 position-relative ">
               <h4 className="heading text-center">Features</h4>
               <Feature/>
               <img src={require('../../assets/images/web-img/2nd-dot.png')} className="img-fluid dot-left feature-parnt mb-3" />
            </div>
            <div className="container-fluid pad-0">
              <p className="traingleshap top mb-0"></p>
            </div>
            <div className="container-fluid pad-0 happy_business_parent position-relative">
               <SelfEmployed/>
               <BusinessManagement/>
               <img src={require('../../assets/images/web-img/2nd-dot.png')} className="img-fluid dot emp_dot hide-mobile mb-3" />
               <img src={require('../../assets/images/web-img/2nd-dotmobile.png')} className="img-fluid dot emp_dot hide-desktop mb-3" />
               <img src={require('../../assets/images/web-img/2nd-dotmobile.png')} className="img-fluid happy-business-dot mb-3" />
            </div>
            <div className="container-fluid pad-0">
              <p className="traingleshap"></p>
            </div>
            <div className="container-fluid pad-0 position-relative">
             <BeuMarketplace/>
            </div>
                <Plan history={this.props.history}/>
            <div className="container-fluid pad-0 ">
                <p className="traingleshap_reqestdemo mb-0"></p>
                <div className="request-demo">
                    <h2>Request a Demo</h2>
                    <p className="mb-0 pb-5">One of the Beu team will reach out to you to show how Beu can transform your business</p>
                </div>
                <p className="traingleshap req_demo"></p>
                <RequestDemo/>
            </div>
            <div className="container-fluid pad-0">
                <div className="footer">
                <WebFooter/>
                </div>
            </div>

            </>
        )

    }
}
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Home);
