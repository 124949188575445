import axios from 'axios';
import API from "../config/API";
import siteSetting from "../config/env";
import { param } from 'jquery';

export const paymentService = {
    activationCode,
    subscriptionRetrieve,
    subscriptionCheckout,
    subscription,
    requestDemo,
    bookingPayment,
    bookingPaymentDane,
    paymentList,
    bankLink,
    getBank,
    deleteBankAccount,
    payoutList,
    payout,
    instantPayout,
    addTerminal,
    requestCardReader,
    fetchConnectionToken,
    cardReaderList,
    cardReaderDelete,
    paymentIntents,
    paymentIntentsCapture,
    subscriptionGet,
    updateTransactionFee,
    createLoginLink,
    cancelTerminalTransaction,
    checkCardAdded,
    paymentMethodAdd,
    cardRemove,
    cardMakePrimery,
    makeCustomerPrimaryCard,
    setUpIntent
};

function activationCode(params) {
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA=="
    };
    return axios.post(API.PAYMENT_ACTIVATATION_CODE, params,{headers:headers})
}

function subscriptionCheckout(params) {
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA=="
    };
    return axios.post(API.PAYMENT_SUBSCRIPTION_CHECKOUT,params, {headers:headers})
}

function subscriptionRetrieve(params) {
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA=="
    };
    return axios.post(API.PAYMENT_SUBSCRIPTION_RETRIEVE,params, {headers:headers})
}

function subscription(params) {
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA=="
    };
    return axios.post(API.PAYMENT_SUBSCRIPTION,params, {headers:headers})
}

function requestDemo(params) {
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA=="
    };
    return axios.post(API.REQUEST_DEMO,params, {headers:headers})
}

function bookingPayment(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };

    // console.log("headers",headers);
    return axios.post(API.BOOKING_PAYMENT, params,{headers:headers})
}

function bookingPaymentDane(params) {
    let accessToken=localStorage.getItem('accessToken')
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    // console.log("headers",headers);
    return axios.post(API.BOOKING_PAYMENT_DONE, params,{headers:headers})
}

function makeCustomerPrimaryCard(params) {
    let accessToken=localStorage.getItem('accessToken')
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    // console.log("headers",headers);
    return axios.post(API.MAKE_CUSTOMER_DEFAULT_CARD, params,{headers:headers})
}




function paymentList(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    //console.log("headers",headers);
    return axios.post(API.PAYMENT_LIST, params,{headers:headers})
}


function bankLink(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    console.log("headers",headers);
    return axios.post(API.BANK_LINK, params,{headers:headers})
}

function getBank(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    //console.log("headers",headers);
    return axios.post(API.BANK_DETAIL, params,{headers:headers})
}

function deleteBankAccount(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    //console.log("headers",headers);
    return axios.post(API.BANK_ACCOUNT_DELETE, params,{headers:headers})
}

function payoutList(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    //console.log("headers",headers);
    return axios.post(API.PAYOUT_LIST, params,{headers:headers})
}

function payout(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    //console.log("headers",headers);
    return axios.post(API.PAYOUT, params,{headers:headers})
}

function instantPayout(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    console.log("headers instant =====>",headers,params);   
    return axios.post(API.INSTANT_PAYOUT, params,{headers:headers})
}

function addTerminal(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    //console.log("headers",headers);
    return axios.post(API.ADD_TERMINAL, params,{headers:headers})
}

function requestCardReader(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    //console.log("headers",headers);
    return axios.post(API.REQUEST_CARD_READER, params,{headers:headers})
}

function fetchConnectionToken(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    //console.log("headers",headers);
    return axios.post(API.FETCH_CONNECTION_TOKEN, params,{headers:headers})
}

function cardReaderList(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    //console.log("headers",headers);
    return axios.post(API.CARD_READER_LIST, params,{headers:headers})
}

function cardReaderDelete(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    //console.log("headers",headers);
    return axios.post(API.CARD_READER_DELETE, params,{headers:headers})
}

function paymentIntents(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    //console.log("headers",headers);
    return axios.post(API.CARD_READER_PAYMENTINTENTS, params,{headers:headers})
}


function paymentIntentsCapture(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    //console.log("headers",headers);
    return axios.post(API.PAYMENTINTENT_CAPTURE, params,{headers:headers})
}

function subscriptionGet(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    //console.log("headers",headers);
    return axios.post(API.SUBSCRIPTION_GET, params,{headers:headers})
}

function updateTransactionFee(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    //console.log("headers",headers);
    return axios.post(API.UPDATE_TRANSACTION_FEE, params,{headers:headers})
}

function createLoginLink(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    //console.log("headers",headers);
    return axios.post(API.CREATE_LOGIN_LINK, params,{headers:headers})
}

function cancelTerminalTransaction(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    //console.log("headers",headers);
    return axios.post(API.CANCEL_TERMINAL_TRANSACTION, params,{headers:headers})
}

function checkCardAdded(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    //console.log("headers",headers);
    return axios.post(API.CHECK_CARD_ADDED, params,{headers:headers})
}


function paymentMethodAdd(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    //console.log("headers",headers);
    return axios.post(API.PAYMENT_METHOD_ADD, params,{headers:headers})
}

function cardRemove(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    //console.log("headers",headers);
    return axios.post(API.CARD_REMOVE, params,{headers:headers})
}

function cardMakePrimery(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    //console.log("headers",headers);
    return axios.post(API.CARD_MAKE_PRIMERY, params,{headers:headers})
}


function setUpIntent() {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    //console.log("headers",headers);
    return axios.post(API.SETUP_PAYMENT_INTENT,{},{headers:headers})
}