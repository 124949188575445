import { hairdresserConstants } from '../_constants';
import { productConstants } from '../_constants/productConstants';


const initialState = {
};


export function hairdresserClientCsv(state = initialState, action) {
  // console.log('action.type',action);
  switch (action.type) {
    case hairdresserConstants.HAIRDRESSER_CLIENT_CSV_DOWNLOAD:
      let data = action.getHairdresserClientSuccess;
      return data;

    case hairdresserConstants.HAIRDRESSER_CLIENT_ERROR:
      return {};

    case hairdresserConstants.HAIRDRESSER_CLIENT_CLEAR:
      return {};

    default:
      return state
  }
}

export function hairdresserClient(state = initialState, action) {
  // console.log('action.type',action);
  switch (action.type) {
    case hairdresserConstants.HAIRDRESSER_CLIENT_SUCCESS:
      let data = action.getHairdresserClientSuccess;
      return data;


    case hairdresserConstants.HAIRDRESSER_CLIENT_ERROR:
      return {};

    case hairdresserConstants.HAIRDRESSER_CLIENT_CLEAR:
      return {};

    default:
      return state
  }
}


export function selectedClient(state = initialState, action) {
  switch (action.type) {
    case productConstants.SELECTED_CLIENT:
      let clientData = action.data;
      return clientData;
    default:
      return state
  }
}
