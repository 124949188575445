import React, { Component } from 'react';
import moment from 'moment';
import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import ClientCheckout from './ClientCheckout';
import { connect } from "react-redux";
import { bookingActions, hairdresserActions } from "../../_actions";
import siteSetting from "../../config/env";
import Checkout from "./Checkout";
import {bookingService} from "../../_services";
var _ = require('lodash');
const isMobile = (window.innerWidth <= 768);
let userIdHolder = [];
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

function getslotTime(time) {
    let hr = parseInt(time / 60);
    let min = time - (hr * 60)
    return (hr <= 9 ? '0' + hr : hr) + ':' + (min == 0 ? '00' : (min <= 9 ? '0' + min : min)) + ":00";
}

class Booking extends Component {

    constructor(props) {
        super(props);

        this.state = {
            bookingDate: new Date(),
            selectSlot: '',
            showOptionsModal: false,
            showTipModal: false,
            latitude: siteSetting.default_latitude,
            longitude: siteSetting.default_longitude,
            hairdresserName: '',
            selectedService: [],
            finalBookingSlots: [],
            includeTimes: [],
            slotInfo: {},
            totalAmount: 0,
            totalTime: 0,
            hairdresserId: '',
            selectedClientId: '',
            selectedClient: {},
            isSaveBooking: 0,
            bookingSlots: [],
            paymentType: '',
            isBookingAllowed: false,
            searchKey: '',
            bookingType:'past', //upcoming
            pendingAmount:0,
            transaction:[],
            paymentAmount:0,
            totalPaymentAmount:0,
            isCheckout:0,
            userId:'',
            isInvoice:0,
            currencySymbol:"£",
            transactions:[],
            invoiceNumber:0,
            selectedPaymentType:null,
            selectedAmount:'',
            oldTransaction:[],
            checkOutAmountType:true,
            selectedFixedAmount:0,
            isPaymentComplete:0,
            beuFeeAmount:0,
            booking:{},
            cancelBookingModal:false,
            noShowModal:false,
            rescheduleRequestModal:false,
            editBookingModal:false
        }
        this.resetBooking = this.resetBooking.bind(this);
        this.checkoutBooking=this.checkoutBooking.bind(this);
        this.serPaymentType=this.serPaymentType.bind(this);
        this.onChanged=this.onChanged.bind(this);
        this.updateBooking=this.updateBooking.bind(this);
        this.completeSale=this.completeSale.bind(this);
        this.cancelBooking=this.cancelBooking.bind(this);
        this.noShowBooking=this.noShowBooking.bind(this);
        this.rescheduleBooking=this.rescheduleBooking.bind(this);
        this.showModalHendler = this.showModalHendler.bind(this);
        this.editBooking = this.editBooking.bind(this);
    }


    handleOptionModal = () => this.setState({ showOptionsModal: !this.state.showOptionsModal })

    handleTipModal = () => {
        this.setState({
            showOptionsModal: false,
            showTipModal: !this.state.showTipModal
        })
    }

    getslotTime=(time)=> {
        let hr = parseInt(time / 60);
        let min = time - (hr * 60)
        return (hr <= 9 ? '0' + hr : hr) + ':' + (min == 0 ? '00' : (min <= 9 ? '0' + min : min));
    }


    componentDidMount() {
        var self = this;
        let params = {};

        if (localStorage.getItem('accessToken')&&this.props.match.params.bookingId!=='') {
            //self.props.dispatch(userActions.profile(params))
            let getparams = {
                bookingID:this.props.match.params.bookingId,
                getBookingsOfType:'updates'
            }
            self.props.dispatch(bookingActions.getHairdresserBooking(getparams))
                .then(function (response) {
                    console.log("response",response.data.data);
                    self.setState({
                        selectedClient:response.data.data.bookings[0].user,
                        selectedClientId:response.data.data.bookings[0].userId,
                        booking:response.data.data.bookings[0],
                    })
                    let totalTime=0,totalAmount=0;
                    response.data.data.bookings[0].servicesBooked.map((services, i) => {
                        totalTime=totalTime+services.serviceTime;
                        totalAmount=totalAmount+parseFloat(services.servicePrice);
                    })
                    let totalPaidAmount=0;
                    if(response.data.data.bookings[0].transaction&&response.data.data.bookings[0].transaction.length>0) {
                        response.data.data.bookings[0].transaction.map((transaction, i) => {
                            if(transaction.paymentType!==5) {
                                totalPaidAmount = totalPaidAmount + parseFloat(transaction.amount)
                            }
                        })
                    }
                    if(response.data.data.bookings[0].transactions&&response.data.data.bookings[0].transactions.length>0) {
                        response.data.data.bookings[0].transactions.map((transaction, i) => {
                            totalPaidAmount=totalPaidAmount+parseFloat(transaction.pgTransactionAmount);
                        })
                    }
                    let totalPaymentAmount=totalPaidAmount;
                    let totalTipAmount = response.data.data.bookings[0]?.transactions[0]?.tipAmount;
                    let beuFeeAmount=response.data.data.bookings[0].beuFeeAmount;
                    let pendingAmount=(totalTipAmount!=undefined && totalTipAmount!=0)?(totalAmount+totalTipAmount)-(totalPaidAmount):totalAmount-(totalPaidAmount);
                    let paymentAmount=pendingAmount;

                    self.setState({
                        slotInfo:{
                            startTime:self.getslotTime(response.data.data.bookings[0].bookingSlot[0]),
                            endTime:self.getslotTime(response.data.data.bookings[0].bookingSlot[1]),
                        },
                        invoiceNumber:response.data.data.bookings[0].invoiceNumber,
                        transaction:response.data.data.bookings[0].transaction,
                        transactions:response.data.data.bookings[0].transactions,
                        totalAmount: totalAmount.toFixed(2),
                        totalTime: totalTime,
                        pendingAmount:pendingAmount,
                        beuFeeAmount:response.data.data.bookings[0].beuFeeAmount,
                        paymentAmount,
                        totalPaymentAmount,
                        oldTransaction:response.data.data.bookings[0].transaction,
                        hairdresserId:response.data.data.bookings[0].hairdresserId,
                        hairdresserName:response.data.data.bookings[0].hairdresser.name,
                        userId:response.data.data.bookings[0].userId,
                        currencySymbol:response.data.data.bookings[0].hairdresser.hairdresser&&response.data.data.bookings[0].hairdresser.hairdresser.currencySymbol&&response.data.data.bookings[0].hairdresser.hairdresser.currencySymbol!==""&&response.data.data.bookings[0].hairdresser.hairdresser.currencySymbol!=="undefined"?response.data.data.bookings[0].hairdresser.hairdresser.currencySymbol:"£"
                    })
                    self.props.dispatch(hairdresserActions.getHairdresserClientDetail({
                        'userId': response.data.data.bookings[0].userId,
                        'hairdresserId':response.data.data.bookings[0].hairdresserId,
                        'bookingType':self.state.bookingType
                    }))
                })
        } else {
            this.props.history.push('/')
        }
        document.body.classList.add('booking-temp');
    }

    resetBooking() {
        window.location.reload();
        // this.setState({
        //     bookingDate: new Date(),
        //     selectSlot: new Date(),
        //     showOptionsModal: false,
        //     showTipModal: false,
        //     latitude: siteSetting.default_latitude,
        //     longitude: siteSetting.default_longitude,
        //     hairdresserName: 'Select Staff Member',
        //     selectedService: [],
        //     finalBookingSlots: [],
        //     includeTimes: [],
        //     slotInfo: {},
        //     totalAmount: 0,
        //     totalTime: 0,
        //     hairdresserId: '',
        //     selectedClientId: '',
        //     selectedClient: {},
        //     isSaveBooking: 0,
        //     bookingSlots: [],
        //     paymentType: '',
        //     searchKey: ''
        // })
    }

    changeClient() {
        this.setState({
            selectedClientId: '',
            selectedClient: {},
            isSaveBooking: 0,
            bookingSlots: [],
            paymentType: '',
            searchKey: ''
        })
    }

    handleEvent(e, d) {
        if (e === "eventStartTime") {
            let m = moment(d);
            let minutes = (m.hour() * 60) + m.minute();
            this.setState({ eventTime: d, eventStartTime: minutes })
        }
        if (e === "eventEndTime") {
            let m = moment(d);
            let minutes = (m.hour() * 60) + m.minute();
            this.setState({ endTime: d, eventEndTime: minutes })
        }
        if (typeof e === "object" && e.target.id === "eventTitle") {
            this.setState({ eventTitle: e.target.value })
        }
        if (typeof e === "object" && e.target.id === "isBookingAllowed") {
            this.setState({ isBookingAllowed: !this.state.isBookingAllowed })
        }
    }

    checkoutBooking(){
        this.setState({
            isCheckout:1
        })
    }

    serPaymentType(paymentType) {
        let transaction=this.state.transaction;
        transaction.push({
            paymentType:paymentType,
            amount:parseFloat(this.state.paymentAmount)
        })
        let totalPaymentAmount=parseFloat(this.state.totalPaymentAmount)+parseFloat(this.state.paymentAmount);
        let pendingAmount=parseFloat(this.state.totalAmount)-totalPaymentAmount;
        this.setState({
            totalPaymentAmount,
            transaction: transaction,
            pendingAmount:pendingAmount,
            paymentAmount:pendingAmount
        })
    }

    onChanged(e) {
        console.log("e",e.target);
        this.setState({ [e.target.name]: parseFloat(e.target.value)});

        if (e.target.name === 'pendingAmount') {
            let totalPaymentAmount=parseFloat(this.state.totalPaymentAmount)+parseFloat(e.target.value);
            let pendingAmount=parseFloat(this.state.totalAmount)-totalPaymentAmount;
            if (parseFloat(e.target.value) > pendingAmount) {
                this.setState({
                    paymentAmountError: 'Invalid Amount'
                })
            } else {
                this.setState({
                    paymentAmountError: '',
                })
            }
        }

        // if(e.target.name ==="selectedAmount"){
        //     let oldTr=this.state.oldTransaction;
        //     let pendingAmount = parseFloat(this.state.totalAmount) - this.state.totalPaymentAmount;
        //     console.log("this.state.oldTransaction",this.state.oldTransaction,pendingAmount,e.target.value);
        //     if(e.target.value>0) {
        //         if (parseFloat(e.target.value) > pendingAmount) {
        //             this.setState({
        //                 paymentAmountError: 'Invalid Amount',
        //                 pendingAmount: parseFloat(this.state.totalAmount) - this.state.totalPaymentAmount
        //             })
        //         } else {
        //             let transaction2={
        //                 paymentType: this.state.selectedPaymentType,
        //                 amount: parseFloat(e.target.value)
        //             }
        //             this.setState({
        //                 paymentAmountError: '',
        //                 pendingAmount: parseFloat(this.state.totalAmount) - this.state.totalPaymentAmount - parseFloat(e.target.value),
        //                 transaction: [...oldTr,transaction2]
        //             })
        //         }
        //     }else{
        //         this.setState({
        //             paymentAmountError: '',
        //             pendingAmount: parseFloat(this.state.totalAmount) - this.state.totalPaymentAmount,
        //             transaction:[...oldTr]
        //         })
        //     }
        //     //console.log("this.state.oldTransaction",this.state.oldTransaction);
        // }
    }

    updateBooking() {
        let self=this;
        let params = {};
        params.servicesToBook = _.map(this.state.selectedService, function (o) {
            return o.serviceId;
        })
        params.userId = this.state.hairdresserId;
        params.transaction = this.state.transaction;
        //params.totalAmount = parseFloat(this.state.totalAmount).toFixed(2);
        params.bookingID=this.props.match.params.bookingId;
        bookingService.updateBooking(params)
            .then(function (response) {
                console.log('client response', response);
                window.location.reload();
            })
    }

    completeSale(){
        this.setState({
            paymentAmountError: ''
        })
        let self=this;
        // if(parseFloat(this.state.selectedAmount)<=0){
        //     this.setState({
        //         paymentAmountError:'Please enter some amount'
        //     })
        // }else {
        //     this.setState({isInvoice: 1})
        // }
        if(this.state.pendingAmount===0) {
            if(this.state.isPaymentComplete==0){
                this.setState({
                    isPaymentComplete:1
                })
            }else {
                this.setState({isInvoice: 1})
            }
        }else{
            let transaction = this.state.transaction;
            if(this.state.checkOutAmountType==true){
                transaction.push({
                    paymentType: self.state.selectedPaymentType,
                    amount: parseFloat(this.state.pendingAmount)
                })
                let totalPaymentAmount = parseFloat(this.state.totalPaymentAmount) + parseFloat(this.state.pendingAmount);
                let pendingAmount = parseFloat(this.state.totalAmount) - totalPaymentAmount;
                let isPaymentComplete=0;
                if(pendingAmount===0){
                    isPaymentComplete=1;
                }
                this.setState({
                    totalPaymentAmount,
                    transaction: transaction,
                    pendingAmount: pendingAmount,
                    selectedPaymentType:null,
                    isPaymentComplete
                })
            }else {
                if (parseFloat(this.state.selectedAmount) > (parseFloat(this.state.totalAmount)-parseFloat(this.state.totalPaymentAmount))) {
                    this.setState({
                        paymentAmountError: 'Please enter valid amount'
                    })
                }else if (this.state.selectedAmount ==0) {
                    this.setState({
                        paymentAmountError: 'Please enter valid amount'
                    })
                }else if (this.state.selectedAmount > 0) {
                    transaction.push({
                        paymentType: self.state.selectedPaymentType,
                        amount: parseFloat(this.state.selectedAmount)
                    })
                    let totalPaymentAmount = parseFloat(this.state.totalPaymentAmount) + parseFloat(this.state.selectedAmount);
                    let pendingAmount = parseFloat(this.state.totalAmount) - totalPaymentAmount;
                    let isPaymentComplete=0;
                    if(pendingAmount===0){
                        isPaymentComplete=1;
                    }
                    this.setState({
                        totalPaymentAmount,
                        transaction: transaction,
                        pendingAmount: pendingAmount,
                        selectedAmount:'',
                        selectedPaymentType:null,
                        checkOutAmountType:true,
                        isPaymentComplete
                    })
                }
            }
        }
    }

    noShowBooking(){
        let self=this;
        let params={}
        params.hairdresserId = this.state.hairdresserId;
        params.bookingID=this.props.match.params.bookingId;
        params.deviceToken = "dfdsfdsfsd";

        self.props.dispatch(bookingActions.hairdresserNoShowBooking(params))
            .then(function (response) {
                console.log('booking response', response);
                if (response.data.errorCode) {
                    self.setState({
                        error: response.data.messages
                    })
                } else {
                    //self.props.history.push('/diary/' + localStorage.getItem('salonId'))
                    window.location.reload();
                }
            })
    }

    cancelBooking(){
        let self=this;
        let params={}
        params.hairdresserId = self.state.hairdresserId;
        params.bookingID=this.props.match.params.bookingId;
        params.deviceToken = "dfdsfdsfsd";

        self.props.dispatch(bookingActions.hairdresserCancelBooking(params))
            .then(function (response) {
                console.log('booking response', response);

                if (response.data.errorCode) {
                    self.setState({
                        error: response.data.messages
                    })
                } else {
                    self.props.history.push('/diary/' + localStorage.getItem('salonId'))
                    //window.location.reload();
                }
            })
    }

    rescheduleBooking(){
        let self=this;
        let params={}
        params.hairdresserId = this.state.hairdresserId;
        params.bookingID=this.props.match.params.bookingId;
        params.deviceToken = "dfdsfdsfsd";

        self.props.dispatch(bookingActions.hairdresserRescheduleBooking(params))
            .then(function (response) {
                console.log('booking response', response);

                if (response.data.errorCode) {
                    self.setState({
                        error: response.data.messages
                    })
                } else {
                    //self.props.history.push('/diary/' + localStorage.getItem('salonId'))
                    window.location.reload();
                }
            })
    }

    setPaymentType=(paymentType)=> {
        this.setState({
            cardReaderConnectError:"",
            payoutError:"",
            selectedPaymentType: paymentType
        })
    }

    selectBookingAmount=(type)=>{
        let self=this;
        if(type==1){
            self.setState({
                checkOutAmountType:true
            })
        }else{
            self.setState({
                checkOutAmountType:false
            })
        }
    }
    removeTransaction=(transaction)=>{
        let transactionList=this.state.transaction;
        let newList=_.pull(transactionList,transaction);
        let totalPaymentAmount = parseFloat(this.state.totalPaymentAmount) - parseFloat(transaction.amount);
        let pendingAmount = parseFloat(this.state.totalAmount) - totalPaymentAmount;
        this.setState({
            totalPaymentAmount,
            pendingAmount,
            transaction:newList
        })
    }
    componentWillUnmount() {

        document.body.classList.remove('booking-temp');
    }

    checkCompleteSale=()=>{
        if(this.state.pendingAmount==0){
            return false;
        }
        if(this.state.selectedPaymentType!==null){
            return false;
        }
        return true;
    }

    showModalHendler(type){
        let self=this;
        switch(type){
            case 'cancelBookingModal':
                if(self.state.selectedClient?._id!='64f5c7789a069cda0188fea3'){
                    self.setState({
                        cancelBookingModal:true
                    })
                }else{
                    this.cancelBooking();
                }
             break;
             case 'rescheduleRequestModal':
                self.setState({
                    rescheduleRequestModal:true
                })
             break;
             case 'editBookingModal':
                if(self.state.selectedClient?._id!='64f5c7789a069cda0188fea3'){
                    self.setState({
                        editBookingModal:true
                    })
                }else{
                    this.editBooking();
                }
                
             break;
             default:
                self.setState({
                    noShowModal:true
                })
        } 
    }

    editBooking=()=>{
        const stateObj = { pageLoadingStatus: 1};
        const url = '/new-booking-edit/' + this.props.hairdresserBooking.bookings[0]?._id;
        this.props.history.push({
            pathname: url,
            state: stateObj,
            });
    }

    render() {
        // console.log("this.props",this.props);
        // console.log("this.state", this.state);
        return (
            <div className="h_n">
                    <div className="body_container bg_white newbooking-for-mobile booking-for-mobile">
                    {this?.state?.selectedClient?<TopHeader 
                        title={parseFloat(this.state.pendingAmount).toFixed(2)<=0?"Completed Booking":"View Booking"}
                        viewMoreOption={true}
                        checkoutButton={true}
                        propData={this.props}
                        headerButtonStatus={true}
                        noShowBooking={this.noShowBooking}
                        cancelBooking={this.cancelBooking}
                        rescheduleBooking={this.rescheduleBooking}
                        selectedClient={this?.state?.selectedClient}
                        pendingAmount={this?.state?.pendingAmount}
                        bookingId={this.props.match.params.bookingId}
                        booking={this.state.booking}
                        history={this?.props?.history}
                        showModalHendler={this?.showModalHendler}
                         />:null}
                        <SideNav {...this.props} />
                        <div className="booking-parent view-booking-parent">

                            <div className={this.state.isInvoice===1?'new_appointment':''}>
                        {this.props.hairdresserBooking.bookings ?
                        <div className="">

                            <div className="tab_content_wrapper d-none d-md-inline-block">

                                {this.state.isInvoice===1 ?<></>

                                :(<div>

                                    <div className="cm_picker_wrap">
                                        <p>{moment(this.props.hairdresserBooking.bookings[0].bookingDate).format('dddd DD MMM YYYY')}</p>
                                        <div></div>
                                    </div>

                                    <div className="booking_form_wrapper">

                                        <div className="cm_row m-0">
                                            <div className="cm_25 equal-input">
                                                <div className="form-group">

                                                    <label>Start Time</label>
                                                    <div className="cm_picker_wrap">
                                                        <input type="text" value={this?.props?.hairdresserBooking?.bookings[0]?.bookingSlot[0] ? this?.getslotTime(this?.props?.hairdresserBooking?.bookings[0]?.bookingSlot[0]) : ""} className="form-control"  />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cm_75">
                                                <div className="form-group">
                                                    <label>Staff Member</label>

                                                    <div className="cm__boxs">
                                                        <div className="cm__box" >

                                                        <div className="table-responsive  cm_table">
                                                            <table>
                                                                <tbody>

                                                                        <tr >
                                                                            <td className="t_width">{this.props.hairdresserBooking.bookings[0].hairdresser.name}</td>
                                                                        </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="cm_row">
                                            <div className="cm_25 equal-input">
                                                <div className="form-group">
                                                    <label>End Time</label>
                                                    <div className="cm_picker_wrap">
                                                    <input type="text" value={this?.props?.hairdresserBooking?.bookings[0]?.bookingSlot[1] ? this?.getslotTime(this?.props?.hairdresserBooking?.bookings[0]?.bookingSlot[1]) : ""} className="form-control"  />
                                                </div>
                                            {/* <input type="text" className="form-control" value={this.state.totalTime + " m"}  /> */}
                                                </div>
                                            </div>
                                            <div className="cm_75">
                                                <label>Service</label>
                                                <div className="cm__boxs">
                                                    <div className="cm__box" >
                                                        <div className="table-responsive  cm_table">
                                                            <table>
                                                                <tbody>
                                                                {this.props.hairdresserBooking.bookings && this.props.hairdresserBooking.bookings[0].servicesBooked.length > 0 ?
                                                                    this.props.hairdresserBooking.bookings[0].servicesBooked?.map((services, i) => (
                                                                        <tr key={services?._id}>
                                                                            <td className="t_width">{services.service?.serviceName}</td>
                                                                          {/* <td>{services.serviceTime}m</td> */}
                                                                          
                                                                            <td>{this.state.currencySymbol}{parseFloat(services?.servicePrice).toFixed(2)}</td>

                                                                        </tr>
                                                                    ))
                                                                    :
                                                                    <div className="rating-header nodiv">
                                                                        <p>No Services Offered </p>
                                                                    </div>
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {this.state.transaction.length > 0?
                                        <div className="cm_width_25">
                                            <div className="cm_body">
                                                <ul>
                                                    {this.state.transactions.map((transaction, index) => (
                                                        <li key={"t_"+index}>{transaction.pgObjectType?.charAt(0).toUpperCase() + transaction.pgObjectType?.slice(1)} - {this.state.currencySymbol}{(parseFloat(transaction.pgTransactionAmount)-parseFloat(this.state?.transactions[0]?.tipAmount)).toFixed(2)}</li>
                                                    ))}
                                                    {this.state.transaction.map((transaction, index) => (
                                                        transaction.paymentType!==5?
                                                        <li key={"t_"+index}>{transaction.paymentType === 1 ? 'Pay by App' : ''}{transaction.paymentType === 2 ? 'Card' : ''}{transaction.paymentType === 3 ? 'Cash' : ''}{transaction.paymentType === 4 ? 'Other' : ''} - {this.state.currencySymbol}{transaction.amount.toFixed(2)}</li>
                                                        :""
                                                    ))}
                                                    {(this.state?.transactions[0]?.tipAmount!=undefined&&this.state?.transactions[0]?.tipAmount!=0)?<li>Tip - {this.state.currencySymbol}{parseFloat(this.state?.transactions[0]?.tipAmount).toFixed(2)}</li>:null}
                                                    <li>Total - {this.state.currencySymbol}{(parseFloat(this.state.totalAmount)+parseFloat(this.state?.transactions[0]?.tipAmount?this.state?.transactions[0]?.tipAmount:0)).toFixed(2)}</li>

                                                </ul>
                                            </div>
                                            <div />

                                            <div className="cm_footer">
                                                <h2>Balance - {this.state.currencySymbol}{parseFloat(this.state.pendingAmount).toFixed(2)}</h2>
                                            </div>
                                        </div>:<div className="cm_width_25">
                                            <div className="cm_body">
                                                <ul>
                                                    {this.state.transactions.map((transaction, index) => (
                                                        <li key={"t_"+index}>{transaction.pgObjectType?.charAt(0).toUpperCase() + transaction.pgObjectType?.slice(1)} - {this.state.currencySymbol}{transaction.pgTransactionAmount.toFixed(2)}</li>
                                                    ))}
                                                    {this.props.hairdresserBooking.bookings[0].transaction&&this.props.hairdresserBooking.bookings[0].transaction.length>0?
                                                        this.props.hairdresserBooking.bookings[0].transaction.map((transaction, index) => (
                                                            <li key={"t_"+index}>{transaction.paymentType === 1 ? 'Pay by App' : ''}{transaction.paymentType === 2 ? 'Card' : ''}{transaction.paymentType === 3 ? 'Cash' : ''}{transaction.paymentType === 4 ? 'Other' : ''} - {this.state.currencySymbol}{parseFloat(transaction.amount).toFixed(2)}</li>
                                                        )):""}

                                                    {/*<li>Tip - £4.20</li>*/}
                                                    
                                                    {this.state.beuFeeAmount!=0?<li>Beu Fee - {this.state.currencySymbol}{parseFloat(this.state.beuFeeAmount).toFixed(2)}</li>:null}
                                                    <li>Total - {this.state.currencySymbol}{parseFloat(this.state.totalAmount).toFixed(2)}</li>

                                                </ul>
                                            </div>
                                            <div/>

                                            <div className="cm_footer">
                                                <h2>Balance - {this.state.currencySymbol}{this.state.pendingAmount?parseFloat(this.state.pendingAmount).toFixed(2):0}</h2>
                                            </div>
                                        </div>}
                                </div>)}
                            </div>

                            {
                                this.state.isInvoice === 0 ?
                                this.state.isCheckout === 1 ? (
                                    <Checkout
                                        checkCompleteSale={this.checkCompleteSale}
                                        removeTransaction={this.removeTransaction}
                                        selectBookingAmount={this.selectBookingAmount}
                                        bookingState={this.state}
                                        setPaymentType={this.setPaymentType}
                                            saveBooking={this.saveBooking} onChangedValue={this.onChanged}
                                              pendingAmount={this.state.pendingAmount}
                                              currencySymbol={this.state.currencySymbol}
                                              paymentAmountError={this.state.paymentAmountError}
                                              selectedClient={this.state.selectedClient}
                                              selectedService={this.state.selectedService}
                                              totalAmount={this.state.totalAmount} paymentType={this.state.paymentType}
                                              serPaymentType={this.serPaymentType} resetBooking={this.resetBooking}
                                              paymentAmount={this.state.paymentAmount}
                                              completeSale={this.completeSale}
                                              hairdresserId={this.state.hairdresserId}
                                              bookings={this.props.hairdresserBooking.bookings[0]}
                                    />
                                ) :
                                <ClientCheckout
                                    bookingState={this.state}
                                    borderr="borderemove"
                                    cancelBookingModal={this.state.cancelBookingModal}
                                    noShowModal={this.state.noShowModal}
                                    editBookingModal={this.state.editBookingModal}
                                    rescheduleRequestModal={this.state.rescheduleRequestModal}
                                    selectedClientId={this.state.selectedClientId}
                                    selectedClient={this.props.hairdresserBooking.bookings[0].user}
                                    changeClient={this.changeClient}
                                    currencySymbol={this.state.currencySymbol}
                                    pendingAmount={this.state.pendingAmount}
                                    checkoutBooking={this.checkoutBooking}
                                    userId={this.state.userId}
                                    hairdresserId={this.state.hairdresserId}
                                    noShowBooking={this.noShowBooking}
                                    cancelBooking={this.cancelBooking}
                                    rescheduleBooking={this.rescheduleBooking}
                                    bookings={this.props.hairdresserBooking.bookings[0]}
                                    history={this.props.history}
                                    totalAmount={this.state.totalAmount} 
                                    paymentType={this.state.paymentType}
                                />
                                :
                                    (
                                        <></>// <Invoice updateBooking={this.updateBooking} selectedClient={this.state.selectedClient} selectedService={this.state.selectedService} totalAmount={this.state.totalAmount} paymentType={this.state.paymentType} resetBooking={this.resetBooking} />
                                    )
                            }

                        </div>
                            : <div className="cm_loader"><div className="loader"></div></div>}
                            </div>
                    </div>
                    </div>

            </div>

        )
    }

}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Booking);

