import React, { Component } from 'react';
import moment from 'moment';
import moment2 from 'moment-timezone';
import DatePicker from "react-datepicker";
import { BackArrow } from '../SvgIcon';
// import { DragIcon, ExpandICon, FilterIcon } from '../SvgIcon';
// import { Modal} from 'react-bootstrap';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const oneDayMiliscond = 24 * 60 * 60 * 1000;

class CalendarCard extends Component {
    constructor(props){
        super(props);
        let currentDate=new Date(props?.bookingDate);
        console.log('booking Date',currentDate);
        var seconds = currentDate.getSeconds();
        var minutes = currentDate.getMinutes();
        var hour = currentDate.getHours();
        var getMilliseconds =currentDate.getMilliseconds()
        var getTimezone = currentDate.toString().match(/([A-Z]+[\+-][0-9]+)/)[1];
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const targetTimeZone = timeZone;
        const currentDateInTimeZone = moment2.tz(props?.bookingDate, targetTimeZone);
        let formattedDate = new Date(currentDateInTimeZone);
        formattedDate.setHours(hour);
        formattedDate.setMinutes(minutes);
        formattedDate.setSeconds(seconds);
        formattedDate.setMilliseconds(getMilliseconds);
        // const isIPad = /iPad/i.test(navigator.userAgent);
        // const isIPhone = /iPhone/i.test(navigator.userAgent);
        let today=moment().format('yyyy-MM-DD');

        // console.log("@@@@@",formattedDate,currentDateInTimeZone,new Date(this.props.bookingDate+" "+hour+":"+minutes+":"+seconds+"."+getMilliseconds+" "+getTimezone),getTimezone)
        this.state = {
            currentDate: currentDate.getTime(),
            dates: [],
            optionList:[
                { id: 'item-1', content: 'Item 1' },
                { id: 'item-2', content: 'Item 2' },
                { id: 'item-3', content: 'Item 3' },
              ],
            //selectDate: this.props.bookingDate,

            today:new Date().getTime(),
            selectDate:new Date(formattedDate).getTime(),
            //selectDate:new Date(this.props.bookingDate+" "+hour+":"+minutes+":"+seconds+"."+getMilliseconds+" "+getTimezone).getTime(),
            holidays: [],
            timebarStatus:false,
            current_date_class:'current_date',
            show:false,
            // isCalendarOpen:false,
        }


        this.handleDateClick=this.handleDateClick.bind(this);

    }
   
    handlePreviousDate = () => this.setState({
                                    currentDate: this.state.currentDate - (7 * oneDayMiliscond)
                                }, () => this.renderDates())

    handleNextDate = () => this.setState({
                                    currentDate: this.state.currentDate + (7 * oneDayMiliscond)
                                }, () => this.renderDates())

    renderDates = () => {
        let { currentDate } = this.state;
        this.setState({
            dates: [
                currentDate - (3 * oneDayMiliscond),
                currentDate - (2 * oneDayMiliscond),
                currentDate - oneDayMiliscond,
                currentDate,
                currentDate + oneDayMiliscond,
                currentDate + (2 * oneDayMiliscond),
                currentDate + (3 * oneDayMiliscond),
            ]
        })
    }

    componentDidMount(){
        let self=this;
       this.renderDates();
       this.setState({holidays: this.state.holidays.map( holiday => moment(parseInt(holiday)).format('YYYYMMDD'))})
    }

    handleDateClick(selectDate) {
        // console.log("selectDate",selectDate);
         this.setState({
            selectDate:selectDate,
        });
        this.props.setCalenderDate(selectDate)
    }

    selectCurrentDate(currentSelectedDate){
        this.setState({
            currentDate:currentSelectedDate,
            selectDate:currentSelectedDate
        }, () => this.renderDates())
        this.props.setCalenderDate(currentSelectedDate)
    }

    setShow= () =>{
        this.props.mobFilterHideShow();
        
    }

    handleOnDragEnd = (result) => {
        let self = this;
        if (!result.destination) return;
        const newItems = Array.from(self.state.optionList);
        const [reorderedItem] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, reorderedItem);
        self.setState({
            optionList:newItems
        })
      };

    handleDateCalenderClick=(selectDate)=> {
        // console.log("selectDate",selectDate);
        let timeInSec=new Date(selectDate).getTime();
        // console.log("timeInSec",timeInSec);
        this.setState({
            selectDate:timeInSec,
            currentDate:timeInSec,
            dates: [
                timeInSec - (3 * oneDayMiliscond),
                timeInSec - (2 * oneDayMiliscond),
                timeInSec - oneDayMiliscond,
                timeInSec,
                timeInSec + oneDayMiliscond,
                timeInSec + (2 * oneDayMiliscond),
                timeInSec + (3 * oneDayMiliscond),
            ],
            isCalendarOpen:false
        });
        this.props.setCalenderDate(selectDate)
    }

    handleOnBlur=()=>{
        console.log("Hiii")
    }

    render(){
        // console.log("this.props",this.props,moment().format('yyyy-MM-DD'));
        // console.log("this.state",this.state);
        const { currentDate, dates, today, selectDate, holidays } = this.state;
        return(
            <React.Fragment>
            <div className="calendar_card calendar-card-mobile">

                <div className="d-flex justify-content-center align-items-center mb-0 mb-md-3 cldr-date-mobile">
                    <i className="fa fa-chevron-left" onClick={this.handlePreviousDate} />
                    <h4 className="month-date-picker ml-4 mr-4 mb-0 cur-point" onClick={()=>{this.setState({
                        isCalendarOpen:!this.state.isCalendarOpen
                    })}}>{moment(currentDate).format("MMM  YYYY")}
                    {this.state.isCalendarOpen ?
                    <span className="icon open"><BackArrow/></span>:
                    <span className="icon"><BackArrow/></span>
                    } 
                    </h4>
                    <DatePicker
                        selected={currentDate}
                        className="month-date-hiden_picker"
                        open={this.state.isCalendarOpen}
                        onChange={(date) =>this.handleDateCalenderClick(date)}
                        onBlur={()=>this.handleOnBlur()}
                        shouldCloseOnSelect={true}
                    />
                    <i className="fa fa-chevron-right" onClick={this.handleNextDate} />
                </div>

                <div className="d-flex justify-content-between cldr-dayview-mob">
                    {
                        dates.map(date => (
                            <div key={date} className={`animated fadeInRight ${(moment(today).format("YYYYMMDD") === moment(date).format("YYYYMMDD")) ? this.state.current_date_class : ''} ${moment(selectDate).format("YYYYMMDD") === moment(date).format("YYYYMMDD")?'sb_selected':''} ${holidays.includes(moment(parseInt(date)).format('YYYYMMDD')) ? 'cm_holiday' : ''}`} onClick={() => this.handleDateClick(date)}>
                                <span>{moment(date).format("ddd")}</span>
                                <p className={`${moment(dates[3]).format("MM") === moment(date).format("MM") ? '' : 'cm_gary'}`}>{moment(date).format("DD")}</p>
                            </div>
                        ))
                    }

                </div>
                <div className='current-date-icon'>
                    <span className="menu_icon" onClick={()=>this.selectCurrentDate(today)}>
                        <svg viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20 3h-1V2c0-.55-.45-1-1-1s-1 .45-1 1v1H7V2c0-.55-.45-1-1-1s-1 .45-1 1v1H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 18H5c-.55 0-1-.45-1-1V8h16v12c0 .55-.45 1-1 1z"/></svg>
                    </span>
                </div>

                {/* For the mobile calender hairdresser issue */}
                {/* <div className='date-filter-icon-body_mobile'>
                   <span className='icon filter' onClick={() => this.setShow()}><FilterIcon/></span>
                   <div className='icon calender'>
                    <span className="menu_icon" onClick={()=>this.selectCurrentDate(today)}>
                        <svg viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20 3h-1V2c0-.55-.45-1-1-1s-1 .45-1 1v1H7V2c0-.55-.45-1-1-1s-1 .45-1 1v1H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 18H5c-.55 0-1-.45-1-1V8h16v12c0 .55-.45 1-1 1z"/></svg>
                    </span>
                </div>
               </div> */}
               
            </div>

    
            

                {/* <h1>{selectDate}  => {moment(selectDate).format("DD, MM, YYYY")}</h1> */}
            </React.Fragment>
        )
    }
}

export default CalendarCard;
