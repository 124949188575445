import React, { Component } from "react";
import Slider from "react-slick";
export default class Banner extends Component {
  componentDidMount(){
    
  }

  btnclick = ()=>{
    document.getElementById('homePlans-wrap').scrollIntoView({behavior: "smooth"});
  }
    render() {
      const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        prevArrow: false,
        nextArrow: false,
        autoplay: true,
        fade: true,
        speed: 100,
      };
      return (
      
        <div>
          <Slider {...settings}>
            <div className="banner-container">
                <img src={require('../../assets/images/web-img/banner1.png')} className="img-fluid hide-mobile-banner mb-3" />
                <img src={require('../../assets/images/web-img/mob-banner1.png')} className="img-fluid mb-3 hide-desktop-banner" />
                
                <div className="text">
                <h3>The best</h3><h3><b>barbershops</b></h3><h3> use <b>Beu</b></h3>
                <div className="mobile">
                <p className="mb-0 mt-4">Software that works for </p>
                <p>clients, salons and the self-employed</p>
                <button onClick={this.btnclick} className="btn btn-white mt-4 plns-btn-1" >Get Beu</button>
                </div>
                </div>
            </div>
            <div className="banner-container">
                <img src={require('../../assets/images/web-img/banner2.png')} className="img-fluid hide-mobile-banner mb-3" />
                <img src={require('../../assets/images/web-img/mob-banner1.png')} className="img-fluid mb-3 hide-desktop-banner" />
                <div className="text">
                <h3>The best</h3><h3>  <b>beauty salons</b></h3><h3> use <b>Beu</b></h3>
                <div className="mobile">
                <p className="mb-0 mt-4">Software that works for </p>
                <p>clients, salons and the self-employed</p>
                <button onClick={this.btnclick} className="btn btn-white mt-4 plns-btn" >Get Beu</button>
                </div>
                </div>
            </div>
            <div className="banner-container">
                <img src={require('../../assets/images/web-img/banner3.png')} className="img-fluid hide-mobile-banner mb-3" />
                <img src={require('../../assets/images/web-img/mob-banner1.png')} className="img-fluid mb-3 hide-desktop-banner" />
                <div className="text">
                <h3>The best</h3><h3>  <b>hair salons</b></h3><h3> use <b>Beu</b></h3>
                <div className="mobile">
                <p className="mb-0 mt-4">Software that works for </p>
                <p>clients, salons and the self-employed</p>
                <button onClick={this.btnclick} className="btn btn-white mt-4 plns-btn" >Get Beu</button>
                </div>
                </div>
            </div>
            {/* <div className="banner-container">
                <img src={require('../../assets/images/web-img/banner4.png')} className="img-fluid hide-mobile-banner mb-3" />
                <img src={require('../../assets/images/web-img/mob-banner1.png')} className="img-fluid mb-3 hide-desktop-banner" />
                <div className="text">
                <h3>The best</h3><h3>  <b>barbershops</b></h3><h3> use <b>Beu</b></h3>
                <div className="mobile">
                <p className="mb-0 mt-4">Free software that works for </p>
                <p>clients, salons and the self-employed</p>
                <button onClick={this.btnclick} className="btn btn-white mt-4 plns-btn" >Get Beu</button>
                </div>
                </div>
            </div> */}
            
          </Slider>
          </div>
       
      );
    }
  }

