import React, { useState } from 'react';
import {Modal} from 'react-bootstrap';
import { Plus } from '../SvgIcon';
import {connect} from "react-redux";
const isMobile = (window.innerWidth <= 768);
const ClientWalkIn = (propes) => {
    // console.log("propes",propes);
    const [showAddClientModal, setShowAddClientModal] = useState(false);

  const handleCloseAddClientModal = () => setShowAddClientModal(false);
  const handleShowAddClientModal = () =>  {
      let self=this;
      // propes.history.push('/clients')
      propes.history.push('/clients/booking/add');
  }


    return(
        propes.hairdresserClient.client?
                (
                    <React.Fragment>
        <div className="cm_right_panal">
            <div className="d-flex justify-content-between">
                <h3 className="cm_fw_b cm_bottom_line">Client</h3>
                {/*<h3 className="cm_fw_l">Walk-in</h3>*/}
            </div>

            <div className="cm_search_wrapper ">
                <input type="text" className="form-control" placeholder="Search…" name="searchKey" onChange={propes.searchClient}/>
                <i className="fa fa-search" />
            </div>

            <ul className="walk_client">
                {!isMobile&&propes.checkReadOnly()?
                <li className="add_client_btn cm_fw_b" onClick={handleShowAddClientModal}><span className=""><Plus /> Add new client</span></li>
                    :""}
                {propes.hairdresserClient.client.map((client, i) => (
                    client.name!=="Unknown"?
                <li key={client._id}  onClick={() => propes.selectClient(client._id,client)}>
                    <b>{(propes.hairdresserClient.client[i-1]&&(propes.hairdresserClient.client[i].name[0]!==propes.hairdresserClient.client[i-1].name[0])||i==0)?client.name[0]:""}</b>
                    <p className="cm_fw_m">{client.name}</p>
                    <p className="cm_fw_l">{client.email}</p>
                </li>:""

                ) )}
            </ul>
        </div>
                        <Modal show={showAddClientModal} onHide={handleCloseAddClientModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add New Client</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Name..." />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Email..." />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="DOB..." />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Phone Number..." />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Note..." />
                                </div>

                                <button className="btn btn-primary btn-block">Add</button>

                            </Modal.Body>
                        </Modal>
                    </React.Fragment>)     :""
    )
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(ClientWalkIn);
