import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { EllipseIcon } from '../../assets/SVG';
import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import SettingSidebar from './SettingSidebar';
import Pagination from "react-js-pagination";
import Dropdown from 'react-bootstrap/Dropdown';
import siteSetting from "../../config/env";
import {paymentActions, userActions} from "../../_actions";
import {connect} from "react-redux";
import {Button} from "react-bootstrap";
import SettingHeadingTitle from './SettingHeadingTitle';
import DollerIco from '../../assets/images/Dollar.svg';
import animationData from '../../assets/animation-data/loader.json'
import {InfoIcon , Close} from '../../component/SvgIcon';
import moment from 'moment';
import Lottie from 'react-lottie';
import { Modal } from "react-bootstrap";
import toast from 'react-toast-notification';
import coinAnimationData from '../../assets/animation-data/coin.json';
const defaultOptionsloti = {
    loop: true,
    autoplay: true, 
    animationData: coinAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


class PayoutAccount extends Component{
    constructor(props){
        super(props);

        this.state = {
            isProfileSettingTab: false,
            bankAccount:'',
            bankName:'',
            bankAccountId:'',
            currencySymbol:'£',
            balance:0,
            availablePayout:0,
            totalBlance:0,
            availableSoon:0,
            transitBank:0,
            payoutList:[],
            totalCount:0,
            activePage: 1,
            payoutError:'',
            successMessage:'',
            isEditDropdownOpen: false,
            isLoader:true,
            showTooltip:false,
            exportPopup: false,
            selectedPayoutType:"instant",
            instantPaymentFee: 0,
            standardPaymentFee: 0,
            instantPayoutSelected:0,
            loading:false,
            paymentPopUp : false,
            instant_available : 0,
            allFeePopUp : false,
            selectedPayoutFee:{}
        }

        this.feeModal = this.feeModal.bind(this);

    }
    
    componentDidMount() {
        // var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        // if(isSafari){
        //     document.body.classList.add('is_safari')
        // }
        var self = this;
        if (localStorage.getItem('accessToken')) {
            
            self.payoutList();
           self.getData();
            
        }else{
            this.props.history.push('/')
        }
        // document.body.classList.remove('setting-temps-pages');
    }

    getData = () =>{
        let employmentType={};
        let params={
            defaultRedirectTo:localStorage.getItem('defaultRedirectTo')
        }
        params.latitude = siteSetting.default_latitude;
        params.longitude = siteSetting.default_longitude;
        var self = this;
        self.props.dispatch(paymentActions.getBank({}))
                .then(function (res){
                    // console.log('ppppppppppresponse',res?.data?.responseData?.result)
                    self.setState({
                        bankAccount:self.props.bankDetails&&self.props.bankDetails.external_accounts&&self.props.bankDetails.external_accounts.data?self.props.bankDetails.external_accounts.data[0]?.last4:'',
                        bankName:self.props.bankDetails?.external_accounts?.data[0]?.bank_name,
                        bankAccountId:self.props.bankDetails?.external_accounts?.data[0]?.id,
                        availablePayout:self.props.bankDetails?.balance?.available[0]?.amount,
                        availableSoon:self.props.bankDetails?.balance?.pending[0]?.amount,
                        totalBlance:self.props.bankDetails?.balance?.available[0]?.amount+self.props.bankDetails?.balance?.pending[0]?.amount,
                        currencySymbol:self.props.user?.userInfo?.hairdresser?.currencySymbol,
                        instant_available:res?.data?.responseData?.result?.balance?.instant_available[0]?.amount,
                    },function (){
                        self.setState({
                            isLoader:false
                        })
                    })
                })
            self.props.dispatch(userActions.profile(params))
                .then(function (response) {
                    console.log("response",response.data.data);
                    if(response.data.data) {
                        if(response.data.data.userInfo.hairdresser.isSalonOwner===1){
                            employmentType={ value: '1', label: 'Owner'}
                        }else if(response.data.data.userInfo.hairdresser.isSalonOwner===2){
                            employmentType={ value: '2', label: 'Account Manager'}
                        }else if(response.data.data.userInfo.hairdresser.isSalonOwner===0) {

                            if (response.data.data.userInfo.hairdresser.employmentTypeId === 1) {
                                employmentType = {value: '3', label: 'Employee'}
                            } else {
                                employmentType = {value: '4', label: 'Self Employee'}
                            }
                        }
                        self.setState(
                            {
                                employmentType:employmentType,
                                first_name: response.data.data.userInfo.first_name&&response.data.data.userInfo.first_name!==""?response.data.data.userInfo.first_name:response.data.data.userInfo.name,
                                last_name:response.data.data.userInfo.last_name,
                                email: response.data.data.userInfo.email,
                                phoneNumber: response.data.data.userInfo.phoneNumber.toString(),
                                jobTitle: response.data.data.userInfo.hairdresser.jobTitle,
                                shortBio: response.data.data.userInfo.hairdresser.shortBio,
                                circleImagePrev:response.data.data.userInfo.userImage.circleImage?response.data.data.userInfo.userImage.circleImage:require('../../assets/images/user-placeholder.png')
                            }
                        )

                    }
                })
    }


    componentWillUnmount(){
        document.body.classList.remove('setting-temps-pages');
    }

    checkReadOnly(){
        if(this.props.user&&this.props.user.userInfo&&this.props.user.userInfo.hairdresser.isSalonOwner===1){
            return true;
        }else{
            return false;
        }
    }

    addbank=()=>{
        let self=this;
        self.props.dispatch(paymentActions.bankLink({}))
            .then(function (response) {
                console.log("response", response.data);
                if (response.data.statusCode===1) {
                    window.open(response.data.responseData.result.url,"_self");
                }else{

                }
            });
    }

    deleteBankAccount=()=>{
        let self=this;
        self.props.dispatch(paymentActions.deleteBankAccount({bankAccountId:this.state.bankAccountId}))
            .then(function (response) {
                console.log("response", response.data);
                // if (response.data.statusCode===1) {
                //     window.open(response.data.responseData.result.url,"_self");
                // }else{
                //
                // }
            });
    }

    payoutList=()=>{
        let self=this;
        self.props.dispatch(paymentActions.payoutList({}))
            .then(function (response) {
                console.log("payoutList", response.data);
                if (response.data.statusCode===1) {
                    self.setState({
                        totalCount:0,
                        payoutList:response.data?.responseData?.result?.data,
                    })
                }else{

                }
            });
    }

    handlePageChange=(eve)=> {
        var self = this;
        self.setState({ activePage: eve }, () => { self._getDataVal(self.state) });
    }
    
    showTooltip=()=>{
        this.setState({
            showTooltip:true
        })
     }
     hideTooltip=()=>{
        this.setState({
            showTooltip:false
        })
     }
    // payout=()=>{
    //     let self=this;
    //     self.setState({
    //         payoutError:'',
    //         successMessage:''
    //     });
    //     self.props.dispatch(paymentActions.payout({}))
    //         .then(function (response) {
    //             console.log("response", response.data);
    //             if (response.data.statusCode===1) {
    //                 self.setState({
    //                     successMessage:'Payout request has been created successfully'
    //                 })
    //             }else{
    //                 self.setState({
    //                     payoutError:response.data.error.errors.responseMessage
    //                 })
    //             }
    //         });
    // }

    payPopUp = ()=>{
        let self = this;
        const instantFee = siteSetting.payout.instantPaymentFee;
        self.setState({
            payoutAmount: this.state?.instant_available/100,
            instantPaymentFee: (Number(this.state?.instant_available)/100 * instantFee).toFixed(2),
            exportPopup: true,
            loading:false
        })
      }

      successPayment = ()=>{
        const { selectedPayoutType } = this.state;
        const payoutAmount = parseFloat(document.getElementById("payoutAmount").value);
      
        if (!selectedPayoutType || isNaN(payoutAmount) || payoutAmount <= 0 || payoutAmount === 0) {
            toast.error("Please enter a valid amount.")
          this.setState({
            successMessage: "",
          });
          return;
        }
        const availableAmount = this.state?.availablePayout/100; 
            if (payoutAmount > availableAmount) {
            toast.error("Amount exceeds available funds.");
            this.setState({
            successMessage: "",
            });
                return;
            }
        let self = this;
        self.payoutList()
        self.setState({
            loading:false,
            exportPopup:false
        })
        
      }
    
      instantPayout = () => {
       
        const { selectedPayoutType } = this.state;
        const payoutAmount = parseFloat(document.getElementById("payoutAmount").value);
      
        if (!selectedPayoutType || isNaN(payoutAmount) || payoutAmount <= 0 || payoutAmount === 0) {
            toast.error("Please enter a valid amount.")
          this.setState({
            successMessage: "",
          });
          return;
        }

        const availableAmount = this.state?.availablePayout/100; 
            // if (payoutAmount > availableAmount) {
            // toast.error("Amount exceeds available funds.");
            // this.setState({
            // successMessage: "",
            // });
            //     return;
            // }
      
        let data = {
            amount:payoutAmount,
            paymentType:selectedPayoutType,
          }
          
      
        let self = this;
        self.setState({
          payoutError: "",
          successMessage: "",
          loading:true  ,
        });
        self.props.dispatch(paymentActions.instantPayout(data))
          .then(function (response) {
            if (response.data.statusCode === 1) {
              self.setState({
                payoutError: "", 
                paymentPopUp:true,
                exportPopup:false
              });
            } else {
                toast.error(response.data.error.errors.responseMessage)
              self.setState({
                successMessage: "",
                loading:false
              });
            }
          })
          .catch(function (error) {
            toast.error("An error occurred while processing the payout.")
            self.setState({
              successMessage: "", 
            }).finally(function(){
                self.setState({
                    loading:false
                })
            })
          });
      };
      
      handleInstantPayout = (e) => {
        const instantFee = siteSetting.payout.instantPaymentFee;
        const availablePayout = (this.state.instant_available).toFixed(2);
        const errorMessageElement = document.getElementById("error_message");
        const enteredAmount = Number(e.target.value);
        const instantPaymentFee = (enteredAmount * instantFee).toFixed(2);
        this.setState({
          payoutAmount: enteredAmount ? enteredAmount : "",
          instantPaymentFee: instantPaymentFee,
        });
    
        if (errorMessageElement === null) {
          console.error("Error message element not found");
          return;
        }
    
        if (enteredAmount < 1) {
            e.preventDefault();
            toast.error("Payout amount must be greater than 0");
          } else if (availablePayout === 0) {
            e.preventDefault();
            toast.error("No funds available for payout.");
          } else if (enteredAmount > availablePayout) {
            e.preventDefault();
            toast.error("Payout amount exceeds available funds");
          } else {
            errorMessageElement.textContent = "Payment success";
          }
    
        setTimeout(() => {
          errorMessageElement.textContent = "";
        }, 3000);
      };

      handleStandardPayout = (e) => {
        const instantFee = siteSetting.payout.standardPaymentFee;
        const extraStandardPaymentFee = siteSetting.payout.extraStandardPaymentFee;
        const availablePayout = this.state.availablePayout;
        const errorMessageElement = document.getElementById("error_message");
        const enteredAmount = Number(e.target.value);
        const standardPaymentFee =((enteredAmount * instantFee) + extraStandardPaymentFee).toFixed(2);
        const standardPayoutAmount = (enteredAmount - parseFloat(standardPaymentFee)).toFixed(2);
        this.setState({
          payoutAmount: enteredAmount ? enteredAmount : "",
          standardPaymentFee: standardPaymentFee,
          standardPayoutAmount : standardPayoutAmount,
        });
    
        if (errorMessageElement === null) {
          console.error("Error message element not found");
          return;
        }
    
        if (enteredAmount < .01) {
            e.preventDefault();
            toast.error("Payout amount must be greater than 0");
          } else if (availablePayout === 0) {
            e.preventDefault();
            toast.error("No funds available for payout.");
          } else if (enteredAmount > availablePayout) {
            e.preventDefault();
            toast.error("Payout amount exceeds available funds");
          } else {
            errorMessageElement.textContent = "Payment success";
          }
    
        setTimeout(() => {
          errorMessageElement.textContent = "";
        }, 3000);
      };

      
    openEditMobileDropdown = ()=>{
        this.setState({
            isEditDropdownOpen: !this.state.isEditDropdownOpen
        })
    }

    createLoginLink=()=>{
        let self=this;
        self.props.dispatch(paymentActions.createLoginLink({}))
            .then(function (response) {
                console.log("response", response.data);
                if (response.data.statusCode===1) {
                    window.open(response.data.responseData.result.url,"_blank");
                }else{

                }
            });
    }
     handleminus = (value) =>{
        const standardFee = siteSetting.payout.standardPaymentFee;
        const extraStandardPaymentFee = siteSetting.payout.extraStandardPaymentFee;
        const result = (value - ((value * standardFee) + extraStandardPaymentFee))  
        return result >= 0 ? result.toFixed(2) : "0.00";
    }

    
    onExportCancelled = () => {
        this.setState({ exportPopup: false});
      };

      onPaymentContinue = () =>{
        let self = this;
        self.setState({ paymentPopUp: false ,exportPopup: false});
         self.payoutList()
         self.getData()
      }
    
      handlePayoutTypeChange = (clickedPayoutType) => {
        const { selectedPayoutType,availablePayout } = this.state;
      
        if (clickedPayoutType !== selectedPayoutType) {
          this.setState({ 
            selectedPayoutType: clickedPayoutType,
            payoutAmount: clickedPayoutType === "standard"?this.state?.availablePayout/100:this.state?.instant_available/100,
            instantPaymentFee: this.state?.instantPaymentFee,
            standardPaymentFee: this.state?.standardPaymentFee,
          });
          if(clickedPayoutType === "standard"){
            const standardPayoutAmount = (availablePayout - parseFloat(this.state.standardPaymentFee)) / 100;
            this.setState({ payoutAmount: this.handleminus(standardPayoutAmount )});
          }
        }
    };

    closeFeePopup = () =>{
        let self = this;
        self.setState({selectedPayoutFee:{}, allFeePopUp: false});
    }
    feeModal = (data) =>{
        let self = this;
        self.setState({ selectedPayoutFee:data,allFeePopUp: true});
    }
   

    render(){
        const { selectedPayoutType } = this.state;
        const isStandardSelected = selectedPayoutType === "standard";
        // console.log("this.props",this.props);
        console.log("this.state",this.state);
        return(
            <>
            <div className="h_n">
                <div className="body_container cm_bg_white setting_page">
                    <TopHeader title="Payout Account" {...this.props}/>
                    <SideNav {...this.props}/>
                    <div className='new-setting-wrapper'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <SettingHeadingTitle title="Payout Account" />
                                        <SettingSidebar/>
                                    </div>
                                    {this.state.isLoader?
                                        <div className='col-md-8'>
                                            <div className='salon-body-main-wrp booking-urlpage'>
                                                <div className='new-stng-cmn'>
                                                <div className="text-center pr-md-5">
                                                    <Lottie 
                                                      options={defaultOptions}
                                                      height={50}
                                                      width={200}
                                                    />
                                                    <h4 className="text-center">Loading Data...</h4>
                                                </div>
                                                </div>
                                            </div>
                                        </div>:
                                    this.state.bankAccountId === ''|| this.state.bankAccountId === undefined ?
                                    <div className='col-md-8'>
                                        <div className='salon-body-main-wrp account--password-temp'>
                                            <div className='new-stng-cmn saloon-settings-wrp'>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className='addAcnt-img'>
                                                            <img src={require('../../assets/images/addAcnt-bg.jpg')}
                                                                 className="img-responsive"/>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div
                                                            className='payout-balance-crd-desc addBank-Accnount pl-md-2'>
                                                            <h4 className='heading-30'>Add Bank Account</h4>
                                                            <p>Add your bank account to unlock Beu’s full functionality,
                                                                take deposits and receive payment with the Beu card
                                                                reader.</p>
                                                            <p className='text-center'>
                                                                <Button className='btn btn-inf w-100' onClick={()=>this.addbank()}>Add Bank
                                                                    Account</Button>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='col-md-8'>
                                        <div className={`salon-body-main-wrp account--payout-wrp ${this.state.isEditDropdownOpen ? 'mobile-view-blurr-wrp' : ''}`}>

                                            <div className='mobile-view-accont-payble'>
                                                <div className='new-stng-cmn account-pauout-balance mb-3 mb-md-4 pos-relative'>
                                                                <div className='tooltip_parent'>
                                                                  <span className='info' onClick={() =>this.showTooltip()}><InfoIcon/></span>
                                                                  {this.state.showTooltip===true?
                                                                  <div className='tooltip-body'>
                                                                    <span className='close' onClick={() =>this.hideTooltip()}><Close/></span>
                                                                    <h5>Payout Info</h5>
                                                                    <p>Your first payout is typically available 7 days after you add your bank account and take your first successful payment on Stripe. This waiting period can be up to 14 days for businesses in certain industries, and up to 30 days in countries like Brazil. This delay allows Stripe to mitigate some of the risks inherent in providing credit services.</p>
                                                                   <p className='text-center'> <a href="https://support.stripe.com/topics/payouts" target="_blank">More Info</a></p>
                                                                  </div>:''}
                                                               </div>
                                                    <div className='row'>
                                                        <div className='col-lg-7'>
                                                            <div className='payout-balance-crd'>
                                                                <button className='btn d-block d-lg-none'
                                                                        onClick={() => this.openEditMobileDropdown()}>
                                                                    <i className="fa fa-ellipsis-v"></i>
                                                                </button>
                                                                <Dropdown className='d-none d-lg-block'>
                                                                    <Dropdown.Toggle id="dropdown-basic">
                                                                        <i className="fa fa-ellipsis-v"></i>
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item onClick={() => this.addbank()}>Edit Payout Account</Dropdown.Item>
                                                                        {/*<Dropdown.Item className='text-del' onClick={() => this.deleteBankAccount()}>Delete Payout Account</Dropdown.Item>*/}
                                                                        <Dropdown.Item onClick={() => this.createLoginLink()}>View payouts on Stripe</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>


                                                                <div className='balance-crd-top'>
                                                                    <p>Available to Payout</p>
                                                                    <h4>{this.state.currencySymbol}{this.handleminus(this.state.availablePayout/100)}</h4>
                                                                    <h6>{this.state.currencySymbol}{(this.state.instant_available/100).toFixed(2)} available instantly</h6>
                                                                </div>
                                                                <div className='balance-crd-bottom d-flex flex-wrap'>
                                                                    <div className='btm-crd-left'>
                                                                        <p className='mb-0 text-capitalize'>{this.state.bankName?.toLowerCase()}</p>
                                                                    </div>
                                                                    <div className='btm-crd-rgt ml-auto'>
                                                                        <p className='mb-0'><i
                                                                            className="fa fa-circle"></i><i
                                                                            className="fa fa-circle"></i><i
                                                                            className="fa fa-circle"></i><i
                                                                            className="fa fa-circle"></i> {this.state.bankAccount}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-5'>
                                                            <div className='payout-balance-crd-desc pl-xl-2 '>
                                                               
                                                                <h4 className='heading-30'>Payout Balance</h4>
                                                                <p>This will redirect you to Stripe Connect, where you
                                                                    will
                                                                    be able to withdraw your balance</p>
                                                                {/*<button className='btn btn-inf w-100'*/}
                                                                {/*        onClick={() => this.payPopUp()}>Withdraw*/}
                                                                {/*</button>*/}
                                                                <button className='btn btn-inf w-100'
                                                                        onClick={() => this.payPopUp()}>Withdraw
                                                                </button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <Modal
                                                             blurClass="blure_bg" 
                                                             centered
                                                             show={this.state.exportPopup}
                                                             onHide={this.onExportCancelled}
                                                             size="lg"
                                                             className='payout_modal'
                                                           >
                                                             <Modal.Header closeButton>
                                                               <Modal.Title className="payout_title">
                                                                 Payout Funds
                                                               </Modal.Title>
                                                             </Modal.Header>
                                                             <Modal.Body>

                                                               <div className="row">
                                                                   <div className={`col-md-5 instant ${!isStandardSelected ? 'instant_selected' : ''}`} onClick={() => this.handlePayoutTypeChange("instant")} >
                                                                       <input type="radio" value="instant" name="payoutType" onClick={() => this.handlePayoutTypeChange("instant")} className="radio_input" checked={!isStandardSelected} id="instantRadio" /> <label for="instantRadio" className="instant_input">Instant</label>
                                                                       <h6 className="instant_head">Up to {this.state.currencySymbol}{(this.state.instant_available/100).toFixed(2)} available and arrives instantly</h6>
                                                                   </div>
                                                                   {this.props.bankDetails?.payout_schedule?.interval==="manual"?
                                                                   <div className={`col-md-5 standard ${isStandardSelected ? 'instant_selected' : ''}`} onClick={() => this.handlePayoutTypeChange("standard")} >
                                                                       <input type="radio" value="standard" name="payoutType" className="radio_input" onClick={() => this.handlePayoutTypeChange("standard")} checked={isStandardSelected} /> <span className="instant_input">Standard</span> 

                                                                       <h6 className="instant_head">Up to {this.state.currencySymbol}{this.handleminus(this.state.availablePayout/100)} available and arrives in 2-3 business days</h6>
                                                                   </div>:""}
                                                               </div>

                                                             {selectedPayoutType === "instant" && (
                                                            
                                                               <div className="modal_div cm_modal_view">
                                                                    <div className='row'>
                                                                        <div className='col-6 text-right pr-lg-5 pr-4'>
                                                                            <h6>Available instantly</h6>
                                                                        </div>
                                                                        <div className='col-6 pl-lg-5 pl-4'>
                                                                        <h6 className="instant_detail_data">{this.state.currencySymbol}{(this.state.instant_available/100).toFixed(2)}</h6>
                                                                        </div>
                                                                    </div>

                                                                    <div className='row'>
                                                                        <div className='col-6 text-right pr-lg-5 pr-4'>
                                                                            <h6>Amount to pay out</h6>
                                                                        </div>
                                                                        <div className='col-6 pl-lg-5 pl-4'>
                                                                        
                                                                        <h6 className="standard_input_data">
                                                                             <span className="amount_to_pay" >{this.state.currencySymbol}</span>
                                                                             <input type="number"  id="payoutAmount" value={(this.state.payoutAmount)} onChange={this.handleInstantPayout} className="amount_to_pay2 mb-0" /></h6>
                                                                       
                                                                        </div>
                                                                    </div>

                                                                    <div className='row'>
                                                                        <div className='col-6 text-right pr-lg-5 pr-4'>
                                                                            <h6>Instant payout fee</h6>
                                                                        </div>
                                                                        <div className='col-6 pl-lg-5 pl-4'>
                                                                        <h6 className="instant_details_data">{this.state.currencySymbol}{this.state.instantPaymentFee}</h6>
                                                                        </div>
                                                                    </div>

                                                                    <div className='row'>
                                                                        <div className='col-6 text-right pr-lg-5 pr-4 mt-lg-2'>
                                                                            <h6>Send payment to</h6>
                                                                        </div>
                                                                        <div className='col-6 pl-lg-5 pl-4 mt-lg-2'>
                                                                        <h6 className="send_payment">{this.state.bankName}<span className="content_strong">&#9679; &#9679; &#9679; &#9679;</span>{this.state.bankAccount}</h6>
                                                                        </div>
                                                                    </div>
                                                                 
                                                               </div>
                                                               
                                                             )}

                                                             {selectedPayoutType === "standard" && (
                                                               <div className="modal_div2 cm_modal_view">
                                                                    <div className='row'>
                                                                        <div className='col-6 text-right pr-lg-5 pr-4'>
                                                                            <h6>Available balance</h6>
                                                                        </div>
                                                                        <div className='col-6 pl-lg-5 pl-4'>
                                                                            <h6>{this.state.currencySymbol}{this.handleminus(this.state.availablePayout/100)}</h6>
                                                                        </div>
                                                                    </div>

                                                                    <div className='row'>
                                                                        <div className='col-6 text-right pr-lg-5 pr-4'>
                                                                            <h6>Amount to pay out</h6>
                                                                        </div>
                                                                        <div className='col-6 pl-lg-5 pl-4'>
                                                                        <h6 className="standard_input_data">
                                                                            <span className="amount_to_pay">{this.state.currencySymbol}</span>
                                                                            <input type="number" className="amount_to_pay2 mb-0" id="payoutAmount" value={this.state.payoutAmount} onChange={this.handleStandardPayout} />
                                                                        </h6>
                                                                        </div>
                                                                    </div>

                                                                    <div className='row'>
                                                                        <div className='col-6 text-right pr-lg-5 pr-4'>
                                                                            <h6>Send payment to</h6>
                                                                        </div>
                                                                        <div className='col-6 pl-lg-5 pl-4'>
                                                                        <h6 className="send_payment">{this.state.bankName}<span className="content_strong">&#9679; &#9679; &#9679; &#9679;</span>{this.state.bankAccount}</h6>
                                                                        </div>
                                                                    </div>
                                                               </div>
                                                             )}


                                                            <Modal.Footer className='p-0 '>
                                                               <button
                                                                 className="payout_cancel_btn"
                                                                 onClick={this.onExportCancelled}
                                                               >
                                                                 Cancel
                                                               </button>
                                                               <button
                                                                 className={`payout_btn ${this.state.loading ? 'loading' : ''}`}
                                                                 onClick={() => this.instantPayout()}
                                                                 disabled={this.state.loading}
                                                               >
                                                               {this.state.loading ? (
                                                                <div className='d-flex '><img src='/spinner.gif' alt='spinner' height='27' className='mr-3 spinner'/>
                                                                <div>
                                                                    Pay out {this.state.currencySymbol}
                                                                    {this.state.selectedPayoutType === "instant" ? (
                                                                      <>
                                                                        {this.state.payoutAmount !== 0
                                                                          ? Number(this.state.payoutAmount).toFixed(2)
                                                                          : 0}
                                                                      </>
                                                                    ) : (
                                                                      <>
                                                                        {this.state.payoutAmount !== 0 ? Number(this.state.payoutAmount).toFixed(2) : 0}
                                                                      </>
                                                                    )}
                                                                 </div>
                                                                </div>
                                                                ) : (
                                                                 <div>
                                                                    Pay out {this.state.currencySymbol}
                                                                    {this.state.selectedPayoutType === "instant" ? (
                                                                      <>
                                                                        {this.state.payoutAmount !== 0
                                                                          ? Number(this.state.payoutAmount).toFixed(2)
                                                                          : 0}
                                                                      </>
                                                                    ) : (
                                                                      <>
                                                                        {this.state.payoutAmount !== 0 ? Number(this.state.payoutAmount).toFixed(2) : 0}
                                                                      </>
                                                                    )}
                                                                 </div>
                                                               )}
                                                               </button>
                                                               {this.state.payoutError && (
                                                                     <div className="error_message">{this.state.payoutError}</div>
                                                                   )}
                                                                   {this.state.successMessage && (
                                                                     <div className="success_message">{this.state.successMessage}</div>
                                                                   )}
                                                               {/* <div id="error_message"></div> */}
                                                             </Modal.Footer>
                                                             </Modal.Body>
                                                           
                                                         </Modal>


                                                         <Modal
                                                             blurClass="blure_bg" 
                                                             centered
                                                             show={this.state.paymentPopUp}
                                                             size="lg"
                                                             className='payout_modal'
                                                           >
                                                        
                                                             <Modal.Body>
                                                             
                                                             <div className='text-center'>
                                                             {/* <img src="/making_money.gif" width={364} height={364} alt="making_money gif" /> */}
                                                             <Lottie options={defaultOptionsloti}
                                                                    height={364}
                                                                    width={364}
                                                                    />


                                                             <h1 className='mt-5'>Payout Successful</h1>
                                                                    <h6 className='my-5 pb-2'>
                                                                     {this.state.selectedPayoutType === "instant" ? (
                                                                      <><p>
                                                                      {this.state.currencySymbol}{this.state.payoutAmount !== 0
                                                                          ? Number(this.state.payoutAmount).toFixed(2)
                                                                          : 0}
                                                                         &nbsp;instantly paid out to your account </p>
                                                                      </>
                                                                    ) : (
                                                                      <>  <p>
                                                                      {this.state.currencySymbol}{this.state.payoutAmount !== 0 ? Number(this.state.payoutAmount).toFixed(2) : 0}
                                                                        &nbsp;is on the way to your account and will arrive in 2-3 business days  </p>
                                                                      </>
                                                                    )}
                                                                      </h6>

                                                                      <button className='continue_btn  m-auto mt-4' onClick={() => this.onPaymentContinue()}>Continue</button>
                                                             </div>
                                                             </Modal.Body>
                                                         </Modal>


                                                    <div className='row'>
                                                        <div className='col-md-12'>
                                                            {this.state.payoutError ?
                                                                <span
                                                                    className="cm_error_msg">{this.state.payoutError}</span> : ''}
                                                            {this.state.successMessage ?
                                                                <span
                                                                    className="cm_succ_msg">{this.state.successMessage}</span> : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='new-stng-cmn stripe-balance-history mb-0 mb-lg-4'>
                                                    {this.state.isEditDropdownOpen &&
                                                        <div className='mob-view-drp d-lg-none'>
                                                            <a href="javascript:void(0)" onClick={() => this.addbank()}>Edit
                                                                Payout Account</a>
                                                            <a href="javascript:void(0)" className='text-del'
                                                               onClick={() => this.addbank()}>Delete Payout Account</a>
                                                        </div>}
                                                    <div className='row'>
                                                        <div className='col-md-6 col-6'>
                                                            <div className='stripe-blance text-center'>
                                                                <h4>Total balance</h4>
                                                                <p className='mb-0'>{this.state.currencySymbol}{(this.state.totalBlance / 100).toFixed(2)}</p>
                                                            </div>
                                                        </div>
                                                        {/*<div className='col-md-4 col-4'>*/}
                                                        {/*    <div className='stripe-blance text-center'>*/}
                                                        {/*        <h4>Available soon</h4>*/}
                                                        {/*        <p className='mb-0'>{this.state.currencySymbol}{(this.state.availableSoon / 100).toFixed(2)}</p>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                        <div className='col-md-6 col-6'>
                                                            <div className='stripe-blance text-center'>
                                                                <h4>In transit to bank</h4>
                                                                <p className='mb-0'>{this.state.currencySymbol}{(this.state.transitBank / 100).toFixed(2)}</p>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 d-lg-none bl-m text-center pt-4'>
                                                            <button
                                                                className={`btn btn-black px-5 ${this.state.isEditDropdownOpen ? 'mt-5' : ''}`}
                                                                // onClick={() => this.payPopUp()}>Withdraw
                                                                onClick={() => this.payPopUp()}>Withdraw
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='new-stng-cmn widrawal-history mb-3 mb-md-4 pb-0 px-0'>
                                                <h3 className='heading-30 pl-3 pl-md-5'>Withdrawal History</h3>
                                                <div className='table-responsive payout_table'>
                                                    <table className='table table-borderless'>
                                                        <thead>
                                                        <tr>
                                                            {/* <th>&nbsp;</th> */}
                                                            <th className='text-left W-date'>Withdrawal Date</th>
                                                            <th className='text-left W-refr'>Arrival Date</th>
                                                            <th className='text-left W-refr status'>Status </th>
                                                            <th className='text-left W-refr '>Withdrawal Type </th>
                                                            <th className='text-center W-amt'>Amount</th>
                                                            <th className='text-center W-amt'>Fee</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.payoutList.length > 0 ?
                                                            this.state.payoutList.map((data, i) => (
                                                                <tr>
                                                                {/*{console.log("dddddddddddd",data)}*/}
                                                                    {/* <td>
                                                                <span className='wd-icon'>
                                                                    <img src={require('../../assets/images/Dollar.svg')} className="img-responsive" alt="Dollar"/></span> */}

                                                                        {/* <span className='wd-icon'></span> */}
                                                                    {/* </td> */}
                                                                    <td className=' wd-1-td text-left'>
                                                                        <span
                                                                            className='d-lg-block'>{moment.unix(data.created).format("ddd, DD MMM YY")}</span>
                                                                     
                                                                        {/*Saturday, 24 September 22*/}
                                                                    </td>
                                                                    <td className='wd-2-td fw-m-300  text-left'>{moment.unix(data.arrival_date).format("ddd, DD MMM YY")}</td>

                                                                    <td className='wd-2-td fw-m-300  text-left cap-text'>{data?.status==='in_transit'?'In transit':data?.status}</td>
                                                                    <td className='wd-2-td fw-m-300  text-left cap-text'>{data?.method}</td>

                                                                    <td className='wd--td text-center text-blue-light'>{this.state.currencySymbol}{(data.amount / 100).toFixed(2)}</td>
                                                                    <td className='wd--td text-center text-blue-light' onClick={()=>this.feeModal(data)}>{this.state.currencySymbol}{(data.payoutFee / 100).toFixed(2)}</td>
                                                                </tr>
                                                            ))
                                                            :
                                                            <tr>
                                                                <td colSpan={6} className="text-center">No data available
                                                                </td>
                                                            </tr>
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {this.state.totalCount > 0 ?
                                                    <div className='pagination-wrap'>
                                                        <Pagination
                                                            activePage={this.state.activePage}
                                                            itemsCountPerPage={10}
                                                            totalItemsCount={this.state.totalCount}
                                                            pageRangeDisplayed={3}
                                                            onChange={this.handlePageChange}
                                                        />
                                                    </div>
                                                    : ""}
                                            </div>

                                            <Modal
                                                     blurClass="blure_bg" 
                                                     centered
                                                     show={this.state.allFeePopUp}
                                                     onHide={this.closeFeePopup}
                                                     size="lg"
                                                     className='fee_modal'
                                                   >
                                                     <Modal.Header closeButton>
                                                             </Modal.Header>
                                                     <Modal.Body>
                                                     <Modal.Title className="text-center">
                                                                 <h3> Fee Details </h3>
                                                                 <br></br>
                                                         {this.state.selectedPayoutFee.feeBookingId&&this.state.selectedPayoutFee.feeBookingId.length>0?
                                                                 <div className="row">
                                                                    <div className="col-md-6 text-right pr-5"><h4> Booking Id </h4></div>
                                                                    <div className="col-md-6 text-left pl-5"><h4> {this.state.selectedPayoutFee.feeBookingId} </h4></div>
                                                                 </div>:<div className="row">
                                                                 <div className="col-md-12 pr-5"><h4> Fee settlement is pending</h4></div>

                                                             </div>}
                                                                 <br></br>
                                                               </Modal.Title>
                                                        {/*<div className='text-center'>*/}
                                                        {/*    <div className='row'>*/}
                                                        {/*        <div className='col-md-6 text-right pr-5'>*/}
                                                        {/*            <h5>Stylist Paid Amount</h5>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className='col-md-6 text-left pl-5'>50</div>*/}
                                                        {/*    </div>*/}

                                                        {/*    <div className='row'>*/}
                                                        {/*        <div className='col-md-6 text-right pr-5'>*/}
                                                        {/*            <h5>Payout Fee</h5>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className='col-md-6 text-left pl-5'>{this.state.currencySymbol}{(this.state.selectedPayoutFee.payoutFee/100).toFixed(2)}</div>*/}
                                                        {/*    </div>*/}

                                                        {/*    <div className='row'>*/}
                                                        {/*        <div className='col-md-6 text-right pr-5'>*/}
                                                        {/*            <h5>Final Transfer Amount</h5>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className='col-md-6 text-left pl-5'>45</div>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                     </Modal.Body>
                                                </Modal>
                                        </div>
                                    </div>}

                                </div>
                            </div>

                    </div>
                </div>
            </div>
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(PayoutAccount);
