import { userConstants } from '../_constants';
import { productConstants } from '../_constants/productConstants';


const initialState = {};
const products = [];

export function selectService(state = initialState, action) {
  switch (action.type) {
    case productConstants.SELECT_PRODUCT:
      let productData = action.data;
      return productData;
    default:
      return state
  }
}


export function selectedServiceList(state = products, action) {
  switch (action.type) {
    case productConstants.SELECTED_SERVICE_LIST:
      let productData = action.data;
      return productData;

    default:
      return state
  }
}
