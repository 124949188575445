import React, { Component } from 'react';
import Popup from "reactjs-popup";
import {connect} from "react-redux";
import HomeLeft from "../hairdresser/HairdresserLeft";
import HomeRight from "../hairdresser/HairdresserRight";

class Pagenotfound extends Component{
    constructor(props){
        super(props);
          this.state = {
            SigninPopup:false,
            CreateAccountPopup:false,

          }
    }
    componentDidMount() {
        document.body.classList.add('login-home-temp');
    }
    render(){
        return(
            <React.Fragment>
                <div className="container gradient-bg">
                <div className="notfound">
                    <div className="notfound-404">
                        <h3>Oops! Page not found</h3>
                        <h1><span>4</span><span>0</span><span>4</span></h1>
                    </div>
                    <h2>we are sorry, but the page you requested was not found</h2>
                </div>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { hairdresser,salon,hairdresserReview } = state;
    return {
        hairdresser,
        salon,
        hairdresserReview
    };
}

export default connect(mapStateToProps)(Pagenotfound);
