import React,{useState} from 'react';
import {Modal} from 'react-bootstrap';
import PhoneInput,{isValidPhoneNumber} from 'react-phone-number-input';
import dateFormat from "dateformat";

import {connect} from "react-redux";
const ConfirmBookingPopup = (props) => {
     var lastIndexServiceTime = [0,0]
  

    // const getslotTime=(time)=> {
    //     let hr = parseInt(time / 60);
    //     let min = time - (hr * 60)
    //     return hr + ':' + (min === 0 ? '00' : min<=9?'0'+min:min);
    // }

    //need to add procecing time 
    const getServiceTime = (startTime, endTime, duration, processingTime=0, index) => {
    
        const transformTime = (hr, min) => `${hr}:${min < 10 ? '0' + min : min}`;
        let startHr, startMin, endHr, endMin;
        if (index === 0) {
            startHr = Math.floor(startTime / 60);
            startMin = startTime % 60;
            endHr = startHr + Math.floor(duration / 60);
            endMin = startMin + (duration % 60);
            endHr += Math.floor(endMin / 60);
            endMin = endMin  % 60;
            if((processingTime!=null && processingTime!=undefined) && processingTime>0){
                let processingHr = (startHr + Math.floor((duration+processingTime) / 60));
                let processingMin = (startMin + Math.floor((duration+processingTime) % 60));
                processingHr += Math.floor(processingMin / 60);
                processingMin = processingMin  % 60;
                lastIndexServiceTime = [processingHr, processingMin];
            }else{
                lastIndexServiceTime = [endHr, endMin];
            }
     
        } else {
            startHr = lastIndexServiceTime[0];
            startMin = lastIndexServiceTime[1];
            endHr = startHr + Math.floor(duration / 60);
            endMin = startMin + (duration % 60);
            endHr += Math.floor((endMin) / 60);
            endMin = (endMin) % 60;

            if((processingTime!=null && processingTime!=undefined) && processingTime>0){
                let processingHr = (startHr + Math.floor((duration+processingTime) / 60));
                let processingMin = (startMin + Math.floor((duration+processingTime) % 60));
                   processingHr += Math.floor(processingMin / 60);
                   processingMin = processingMin  % 60;
                lastIndexServiceTime = [processingHr, processingMin];
            }else{
                lastIndexServiceTime = [endHr, endMin];
            }
        }
    
        const transformStartTime = transformTime(startHr, startMin);
        const transformEndTime = transformTime(endHr, endMin);
    
        return `${transformStartTime}-${transformEndTime}`;
    };

    


    const convertTime=(totalDuration)=>{
        if(totalDuration>59){
          const hours = Math.floor(totalDuration / 60); 
          const minutes = totalDuration % 60; 
          if(minutes==0){
            return hours+'h ';
          }else{
            return hours+'h '+ minutes+'m'
          }
        }else{
          return totalDuration+'m'
        }
      }

    
    return(
    <>
   <div className="cm_modal booking_bg popupscroll new-confirm-booking booking_popup" >
        <div className="top-banner text-center">
            <img alt="confirm-popup" src={require('../../assets/images/BookingConfirmed.png')} className="img-fluid "/>
            {/* <div className="services_img"><img className="cm_team-round-img" src="https://beuapp.s3.eu-west-2.amazonaws.com/user_avatars/e473f8534795f59793a5f4234a7af878"/></div> */}
        </div>
        <div className="">
                    <div className="booking-info pt-4 ml-3 mr-3 ">
                        <h2 className="text-center mb-5">Booking Confirmed</h2>
                        <h5 className="">{props.booking.bookings[0].hairdresser.hairdresser.salon.salonName}</h5>
                        <p className="">{props.booking.bookings[0].hairdresser.hairdresser.salon.formattedAddress}</p>
                    </div>
            {props.booking.bookings[0].servicesBooked.map((service, index) => (
                <>
                {console.log('check data booking',props.booking.bookings[0])}
                    <div className="booking-detail booking-timming-detail" key={'service_1_' + index} >
                        <div className="b_dtls">
                        <div className="date mr-3">
                            <h5>{dateFormat(props.booking.bookings[0].bookingDate, "dd")}</h5>
                            <p> {dateFormat(props.booking.bookings[0].bookingDate, "mmm")}</p>
                        </div>

                                <div className="service"  key={'service_' + index} >
                                    <h5>{service.service.serviceName}</h5>
                                    {console.log('check the service',props.booking.bookings)}
                                    <p>{getServiceTime(props.booking.bookings[0].bookingSlot[0],props.booking.bookings[0].bookingSlot[1],service.serviceTime,props.booking.bookings[0].isServiceProcessingTime===1?service.processingTime:0,index)} , {convertTime(service.serviceTime)}</p>
                                </div>

                    </div>
                        <div className="server-name">
                            <p>{props.booking.bookings[0].hairdresser.name}</p>
                            <p>{props.booking.bookings[0]?.currencySymbol}{service.servicePrice.toFixed(2)}</p>
                        </div>
                    </div>
                </>
            ))}

        </div>
        <div className="confrim-msz">
            <p>You have been sent an email with your booking confirmation and a link to download the Beu app, which will allow you to manage your appointment and make further bookings from anywhere.</p>
            {/*<h3>Add Phone Number</h3>*/}
            {/*<p>Please add your phone number to confirm your booking. This may be used to send you an appointment reminder, or update you regarding your booking.</p>*/}
            {/*<br/>*/}
            {/*<div className="form-group cm_PhoneInput">*/}
            {/*        <label>Phone Number</label>*/}
            {/*        <PhoneInput*/}
            {/*                                            international={true}*/}
            {/*                                            className="form-control"*/}
            {/*                                            placeholder="Phone Number"*/}
            {/*                                            name="phoneNumber"*/}
            {/*                                            placeholder="Enter phone number"*/}
            {/*                                            // value={this.state.phoneNumber?this.state.phoneNumber:""}*/}
            {/*                                            // onChange={this.handlePhoneChange}*/}
            {/*                                            />*/}
            {/*                                        /!*<input type="number" className="form-control" name="phoneNumber" value={this.state.phoneNumber} onChange={this.onChanged} max={10} min={10}/>*!/*/}
            {/*                                      */}
            {/*</div>*/}
            <div className="text-center">
                <button type="button" className="btn booknow-btn col-md-6" onClick={props.toggleBookingConf.bind(this,props?.slug?props?.slug:'')}>Done</button>
            </div>
        </div>
        
   </div>
    </>)
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(ConfirmBookingPopup);