import React, { Component } from 'react';
import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import { Link } from 'react-router-dom';
import SettingSidebar from './SettingSidebar';


class AddPayoutAccount extends Component {

    constructor(props){
        super(props);
        this.state = {

        }
    }



    
    render() {
        return (
            <div className="h_n">
            <div className="body_container cm_bg_white setting_page">

                <TopHeader title="SalonSettings" {...this.props}/>
                <SideNav {...this.props}/>
                <div className='new-setting-wrapper'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <SettingSidebar />
                            </div>
                            <div className='col-md-8'>
                                <div className='salon-body-main-wrp account--password-temp'>
                                    <div className='new-stng-cmn saloon-settings-wrp'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='addAcnt-img'>
                                                    <img src={require('../../assets/images/addAcnt-bg.jpg')} className="img-responsive" />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='payout-balance-crd-desc addBank-Accnount pl-md-2'>
                                                    <h4 className='heading-30'>Add Bank Account</h4>
                                                    <p>Add your bank account to unlock Beu’s full functionality, take deposits and receive payment with the Beu card reader.</p>
                                                    <p className='text-center'>
                                                        <Link to="/settings/payout-account" className='btn btn-inf w-100'>Add Bank Account</Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        )
    }

}




export default AddPayoutAccount;





