import React, { Component } from 'react';
import moment from 'moment';
import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import ClientCheckout from './ClientCheckout';
import { connect } from "react-redux";
import { bookingActions, hairdresserActions } from "../../_actions";
import siteSetting from "../../config/env";
import toast from "react-toast-notification";
import Checkout from "./Checkout";
import {bookingService,bopaymentService} from "../../_services";
import ReactDatePicker from 'react-datepicker';
import { Select } from 'react-select/dist/Select-a783e33f.cjs.prod';
import { Close } from '../SvgIcon';
import { GrClose } from 'react-icons/gr';
import {PendingIcon} from '../SvgIcon';
// import { loadStripeTerminal } from "@stripe/terminal-js";
var _ = require('lodash');
const isMobile = (window.innerWidth <= 768);
let userIdHolder = [];
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];


// let StripeTerminal = await loadStripeTerminal();

function getslotTime(time) {
    let hr = parseInt(time / 60);
    let min = time - (hr * 60)
    return (hr <= 9 ? '0' + hr : hr) + ':' + (min == 0 ? '00' : (min <= 9 ? '0' + min : min)) + ":00";
}

class ViewBooking extends Component {

    constructor(props) {
        super(props);

        this.state = {
            bookingDate: new Date(),
            selectSlot: '',
            showOptionsModal: false,
            showTipModal: false,
            latitude: siteSetting.default_latitude,
            longitude: siteSetting.default_longitude,
            hairdresserName: '',
            selectedService: [],
            finalBookingSlots: [],
            includeTimes: [],
            slotInfo: {},
            totalAmount: 0,
            totalTime: 0,
            hairdresserId: '',
            selectedClientId: '',
            selectedClient: {},
            isSaveBooking: 0,
            bookingSlots: [],
            paymentType: '',
            isBookingAllowed: false,
            searchKey: '',
            bookingType:'past', //upcoming
            pendingAmount:0,
            transaction:[],
            paymentAmount:0,
            totalPaymentAmount:0,
            isCheckout:0,
            userId:'',
            isInvoice:0,
            currencySymbol:"£",
            transactions:[],
            invoiceNumber:0,
            selectedPaymentType:null,
            selectedAmount:'',
            oldTransaction:[],
            checkOutAmountType:true,
            selectedFixedAmount:0,
            isPaymentComplete:0,
            beuFeeAmount:0,
            booking:{},
            cancelBookingModal:false,
            noShowModal:false,
            rescheduleRequestModal:false,
            editBookingModal:false,
            bookingStatus:0,
            noShowFeeAmount:0,
            noShowPolicyStatus:0,
            noShowSelectedOption:2,
            noShowErrorStatus:false,
            noShowFeeInPercentage:0,
            noShowBookingProcess:false,
          
        }
        this.checkoutBooking=this.checkoutBooking.bind(this);
        this.serPaymentType=this.serPaymentType.bind(this);
        this.cancelBooking=this.cancelBooking.bind(this);
        this.noShowBooking=this.noShowBooking.bind(this);
        this.rescheduleBooking=this.rescheduleBooking.bind(this);
        this.editBooking = this.editBooking.bind(this);
    }


    handleOptionModal = () => this.setState({ showOptionsModal: !this.state.showOptionsModal })

    handleTipModal = () => {
        this.setState({
            showOptionsModal: false,
            showTipModal: !this.state.showTipModal
        })
    }

    getslotTime=(time)=> {
        let hr = parseInt(time / 60);
        let min = time - (hr * 60)
        return (hr <= 9 ? '0' + hr : hr) + ':' + (min == 0 ? '00' : (min <= 9 ? '0' + min : min));
    }


    componentDidMount() {
        var self = this;
        let params = {};

        if (localStorage.getItem('accessToken')&&this.props.match.params.bookingId!=='') {
            //self.props.dispatch(userActions.profile(params))
            let getparams = {
                bookingID:this.props.match.params.bookingId,
                getBookingsOfType:'updates'
            }

           
            //let noShowFeeInPercentage = this.props?.hairdresserBooking?.noShowCancelPolicy?.noShowFeeInPercentage;
            //let noShowStatus = this.props?.hairdresserBooking?.noShowCancelPolicy?.noShowStatus;

         
           
            self.props.dispatch(bookingActions.getHairdresserBooking(getparams))
                .then(function (response) {
                    self.setState({
                        selectedClient:response.data.data.bookings[0].user,
                        selectedClientId:response.data.data.bookings[0].userId,
                        booking:response.data.data.bookings[0],
                        bookings:response.data.data.bookings[0],
                        bookingStatus:response?.data?.data?.bookings[0]?.bookingStatus,
                    })
                    let totalTime=0,totalAmount=0;
                    response.data.data.bookings[0].servicesBooked.map((services, i) => {
                        //totalTime=totalTime+services.serviceTime;
                        totalAmount=totalAmount+parseFloat(services.servicePrice);
                    })
                    totalTime = response.data.data.bookings[0].bookingSlot[1]-response.data.data.bookings[0].bookingSlot[0];
                    let totalPaidAmount=0;
                    if(response.data.data.bookings[0].transaction&&response.data.data.bookings[0].transaction.length>0) {
                        response.data.data.bookings[0].transaction.map((transaction, i) => {
                            if(transaction.paymentType!==5) {
                                totalPaidAmount = totalPaidAmount + parseFloat(transaction.amount)
                            }
                        })
                    }
                    if(response.data.data.bookings[0].transactions&&response.data.data.bookings[0].transactions.length>0) {
                        response.data.data.bookings[0].transactions.map((transaction, i) => {
                            totalPaidAmount=totalPaidAmount+parseFloat(transaction.pgTransactionAmount);
                        })
                    }
                    let totalPaymentAmount=totalPaidAmount;
                    let totalTipAmount = response.data.data.bookings[0]?.transactions[0]?.tipAmount;
                    let beuFeeAmount=response.data.data.bookings[0].beuFeeAmount;
                    let pendingAmount=(totalTipAmount!=undefined && totalTipAmount!=0)?(totalAmount+totalTipAmount)-(totalPaidAmount):totalAmount-(totalPaidAmount);
                    let paymentAmount=pendingAmount;

                    let noShowFeeInPercentage=response.data.data.bookings[0].noShowFeeInPercentage;

                    console.log('getHairdresserBooking',response.data.data.bookings[0])
                    self.setState({
                        selectSlot:self.getslotTime(response.data.data.bookings[0].bookingSlot[0]),
                        slotInfo:{
                            startTime:self.getslotTime(response.data.data.bookings[0].bookingSlot[0]),
                            endTime:self.getslotTime(response.data.data.bookings[0].bookingSlot[1]),
                        },
                        invoiceNumber:response.data.data.bookings[0].invoiceNumber,
                        transaction:response.data.data.bookings[0].transaction,
                        transactions:response.data.data.bookings[0].transactions,
                        totalAmount: totalAmount.toFixed(2),
                        totalTime: totalTime,
                        pendingAmount:pendingAmount,
                        beuFeeAmount:response.data.data.bookings[0].beuFeeAmount,
                        paymentAmount,
                        totalPaymentAmount,
                        noShowFeeInPercentage,
                        oldTransaction:response.data.data.bookings[0].transaction,
                        hairdresserId:response.data.data.bookings[0].hairdresserId,
                        hairdresserName:response.data.data.bookings[0].hairdresser.name,
                        userId:response.data.data.bookings[0].userId,
                        currencySymbol:response.data.data.bookings[0].hairdresser.hairdresser&&response.data.data.bookings[0].hairdresser.hairdresser.currencySymbol&&response.data.data.bookings[0].hairdresser.hairdresser.currencySymbol!==""&&response.data.data.bookings[0].hairdresser.hairdresser.currencySymbol!=="undefined"?response.data.data.bookings[0].hairdresser.hairdresser.currencySymbol:"£"
                    })

                    if(noShowFeeInPercentage&&noShowFeeInPercentage>0){
                     let noShowAmount = (paymentAmount * noShowFeeInPercentage) / 100;
                     self.setState({
                        noShowFeeAmount:noShowAmount.toFixed(2),
                        noShowPolicyStatus:noShowFeeInPercentage&&noShowFeeInPercentage>0?1:0,
                        noShowSelectedOption:(noShowAmount>0&&response.data.data.bookings[0]?.stripePaymentMethodId!=undefined)?1:2
                     });
                    }

                    self.props.dispatch(hairdresserActions.getHairdresserClientDetail({
                        'userId': response.data.data.bookings[0].userId,
                        'hairdresserId':response.data.data.bookings[0].hairdresserId,
                        'bookingType':self.state.bookingType
                    }))
                })
        } else {
            this.props.history.push('/')
        }
        document.body.classList.add('booking-temp');
    }



    changeClient() {
        this.setState({
            selectedClientId: '',
            selectedClient: {},
            isSaveBooking: 0,
            bookingSlots: [],
            paymentType: '',
            searchKey: ''
        })
    }

    handleEvent(e, d) {
        if (e === "eventStartTime") {
            let m = moment(d);
            let minutes = (m.hour() * 60) + m.minute();
            this.setState({ eventTime: d, eventStartTime: minutes })
        }
        if (e === "eventEndTime") {
            let m = moment(d);
            let minutes = (m.hour() * 60) + m.minute();
            this.setState({ endTime: d, eventEndTime: minutes })
        }
        if (typeof e === "object" && e.target.id === "eventTitle") {
            this.setState({ eventTitle: e.target.value })
        }
        if (typeof e === "object" && e.target.id === "isBookingAllowed") {
            this.setState({ isBookingAllowed: !this.state.isBookingAllowed })
        }
    }

    checkoutBooking(){
        this.setState({
            isCheckout:1
        })
    }

    serPaymentType(paymentType) {
        let transaction=this.state.transaction;
        transaction.push({
            paymentType:paymentType,
            amount:parseFloat(this.state.paymentAmount)
        })
        let totalPaymentAmount=parseFloat(this.state.totalPaymentAmount)+parseFloat(this.state.paymentAmount);
        let pendingAmount=parseFloat(this.state.totalAmount)-totalPaymentAmount;
        this.setState({
            totalPaymentAmount,
            transaction: transaction,
            pendingAmount:pendingAmount,
            paymentAmount:pendingAmount
        })
    }

    noShowBooking(){
        let self=this;
        let params={}
        params.bookingId= this.props.match.params.bookingId;
        params.deviceToken= "dfdsfdsfsd";
       
        self.setState({
            noShowBookingProcess:true
        })

        params.noShowAmount= +this?.state?.noShowFeeAmount;
        params.noShowChargeStatus= +this?.state?.noShowSelectedOption;

        if((params?.noShowAmount<=0 && params?.noShowChargeStatus===1)){
            toast.error('Invalid Amount');
            return;
        }

        if(params.noShowChargeStatus===2){
            params.noShowAmount=0;
        }
       // bookingActions.hairdresserNoShowBooking;
        self.props.dispatch(bookingActions.noShowBooking(params))
            .then(function (response) {
                if (response?.data?.statusCode==0) {
                    if(response?.data?.error?.responseMessage) {
                        toast.error(response?.data?.error?.responseMessage);
                    }else{
                        toast.error("Please try again later");
                    }
                    self.setState({
                        noShowBookingProcess:false
                    })
                } else {
                    if(response?.data?.statusCode===1){
                        toast.success(response?.data?.responseData?.message);
                        self.setState({
                            booking:{
                                ...self.state.booking,
                                bookingStatus:6,
                                bookingHairdresserAction:6,
                                noShowModal:false,
                                noShowBookingProcess:false,
                            },
                            bookingStatus:6,
                            noShowModal:false,
                            noShowBookingProcess:false,
                        }) 
                    }
                    //self.props.history.push('/diary/' + localStorage.getItem('salonId'))
                   // window.location.reload();
                }


          
            }).catch((err)=>{
                self.setState({
                    noShowBookingProcess:false
                }) 
                toast.error(err.messages);
            })
    }

    
    noShowPopup=()=>{
        let self = this;
        // console.log('checkkkkk',88);
        self.setState({
            noShowModal:!this.state.noShowModal,
        })
    }


    cancelBooking(){
        let self=this;
        let params={}
        params.hairdresserId = self.state.hairdresserId;
        params.bookingID=this.props.match.params.bookingId;
        params.deviceToken = "dfdsfdsfsd";

        self.props.dispatch(bookingActions.hairdresserCancelBooking(params))
            .then(function (response) {
                console.log('booking response', response);

                if (response.data.errorCode) {
                    self.setState({
                        error: response.data.messages
                    })
                } else {
                    self.props.history.push('/diary/' + localStorage.getItem('salonId'))
                    //window.location.reload();
                }
            })
    }

    rescheduleBooking(){
        let self=this;
        let params={}
        params.hairdresserId = this.state.hairdresserId;
        params.bookingID=this.props.match.params.bookingId;
        params.deviceToken = "dfdsfdsfsd";

        self.props.dispatch(bookingActions.hairdresserRescheduleBooking(params))
            .then(function (response) {
                console.log('booking response', response);

                if (response.data.errorCode) {
                    self.setState({
                        error: response.data.messages
                    })
                } else {
                    //self.props.history.push('/diary/' + localStorage.getItem('salonId'))
                    window.location.reload();
                }
            })
    }

    setPaymentType=(paymentType)=> {
        this.setState({
            cardReaderConnectError:"",
            payoutError:"",
            selectedPaymentType: paymentType
        })
    }

    selectBookingAmount=(type)=>{
        let self=this;
        if(type==1){
            self.setState({
                checkOutAmountType:true
            })
        }else{
            self.setState({
                checkOutAmountType:false
            })
        }
    }
    removeTransaction=(transaction)=>{
        let transactionList=this.state.transaction;
        let newList=_.pull(transactionList,transaction);
        let totalPaymentAmount = parseFloat(this.state.totalPaymentAmount) - parseFloat(transaction.amount);
        let pendingAmount = parseFloat(this.state.totalAmount) - totalPaymentAmount;
        this.setState({
            totalPaymentAmount,
            pendingAmount,
            transaction:newList
        })
    }
    componentWillUnmount() {

        document.body.classList.remove('booking-temp');
    }

    showModalHendler=(type)=>{
        let self=this;
        switch(type){
            case 'cancelBookingModal':
                if(self.state.selectedClient?._id!='64f5c7789a069cda0188fea3'){
                    self.setState({
                        cancelBookingModal:true
                    })
                }else{
                    this.cancelBooking();
                }
             break;
             case 'rescheduleRequestModal':
                self.setState({
                    rescheduleRequestModal:true
                })
             break;
             case 'editBookingModal':
                 this.editBooking();
                // if(self.state.selectedClient?._id!='64f5c7789a069cda0188fea3'){
                //     self.setState({
                //         editBookingModal:true
                //     })
                // }else{
                //     this.editBooking();
                // }
            break;
            case 'noShowModal':
                self.setState({
                    noShowModal:true
                })
             break;
             default:
                self.setState({
                    noShowModal:true
                })
        } 
    }

    editBooking=()=>{
        const stateObj = { pageLoadingStatus: 1};
        const url = '/new-booking-edit/' + this.props.hairdresserBooking.bookings[0]?._id;
        this.props.history.push({
            pathname: url,
            state: stateObj,
            });
    }

    noShowOnChangeNoShow=(e,type)=>{
        let self = this;
        console.log("e,type",e,type);
        if(type==='amount'){
            let errorStatus ;
            let noShowSetFee = (self?.state?.paymentAmount * self.state.noShowFeeInPercentage) / 100;
            let inputValue = e?.target?.value;
            if(noShowSetFee>=inputValue){
                errorStatus= false;
            }else{
                errorStatus= true;
            }
           
            console.log('check the total amount',noShowSetFee, inputValue,noShowSetFee<=inputValue);
            self.setState({
                noShowFeeAmount:inputValue,
                noShowErrorStatus:errorStatus
               })
        }else{
            self.setState({
                noShowSelectedOption:e
               })
        }
      
    }
  

    noShowOnChange=(status)=>{
        let self = this;
        
        switch(status){
            case 4:
                return 'Completed Booking';
                break;
            case 3:
                return 'Cancelled Booking'
                break;

            case 6:
                return  'No Show Booking'
                break;

            default:
                return 'View Booking';
        }
    }
    

    render() {
        console.log("this.props",this.props);
        console.log("this.state", this.state);
        return (
            <div className="h_n">
                    <div className="body_container bg_white newbooking-for-mobile booking-for-mobile">
                    {this?.state?.selectedClient?<TopHeader 
                        title={this.props.hairdresserBooking.bookings?this.noShowOnChange(this.state.bookingStatus):""}
                        viewMoreOption={true}
                        checkoutButton={true}
                        propData={this.props}
                        headerButtonStatus={true}
                        noShowBooking={this.noShowBooking}
                        cancelBooking={this.cancelBooking}
                        rescheduleBooking={this.rescheduleBooking}
                        selectedClient={this?.state?.selectedClient}
                        bookingStatus={this.state.bookingStatus}
                        bookingId={this.props.match.params.bookingId}
                        booking={this.state.booking}
                        history={this?.props?.history}
                        noShowButtonStatus={this.state?.booking?.bookingHairdresserAction==6||this?.state?.booking.bookingStatus==6}
                        noShowBookingProcess={this.state.noShowBookingProcess}
                        showModalHendler={this?.showModalHendler}
                         />:null}
                        <SideNav {...this.props} />
                        <div className="booking-parent view-booking-parent">

                        <>
                        {this.props.hairdresserBooking.bookings ?
                        <>
                        <div className="service_flex_div service_flex_div service_flex__space ml-0">
                            <div className="main_service_div view_booking_new">
                                <div className='row'>
                                {console.log('checkbooking',this.props.hairdresserBooking.bookings[0])}
                                    {this.props.hairdresserBooking.bookings && this.props.hairdresserBooking.bookings[0].servicesBooked.length > 0 ?
                                        this.props.hairdresserBooking.bookings[0].servicesBooked?.map((services, i) => (
                                            <>
                                    <div className={`col-md-12 col-lg-12 service_div bg-white rounded mt-0 ${i!==0&&this.props.hairdresserBooking.bookings[0].servicesBooked[i-1]?.processingTime !==0&&this.props.hairdresserBooking.bookings[0].servicesBooked[i-1]?.processingTime !==null ?'':"mt-4"}`} key={"bsp"+i}>
                                        <div className="row vertical-top">
                                            <div className="col-md-12 text-left mt-1 mb-2">
                                                <span className="service_1 ml-0">Service {i+1}</span>
                                                {(services?.processingTime && services?.processingTime !== 0 && ((i + 1) !== this.props.hairdresserBooking.bookings[0].servicesBooked.length)) ?
                                                    <span
                                                        className="service_1 processing_time ml-3">Processing Time</span>
                                                :""}
                                                {this?.props?.hairdresserBooking?.bookings[0]?.stripePaymentMethodId!=undefined && this?.props?.hairdresserBooking?.bookings[0]?.stripePaymentMethodId!='' && i==0?
                                                    <span className="service_1 card_captured ml-3">Card captured</span>:''}
                                                
                                            </div>
                                        </div>
                                        {i===0?
                                        <div className="row service_row">
                                            <div className="col-md-6">
                                                <div className="pt-0 bg-white service_select ___service border-1 w-100 p-0">
                                                <div className="service_checkout_datepicker _datepickr"><div className="react-datepicker-wrapper">
                                                    <div className="react-datepicker__input-container hide__arrow" >
                                                        <input type="text" className="__datepicker" disabled />
                                                        <p className="show__date mt-2">{moment(this.props.hairdresserBooking.bookings[0].bookingDate).format('dddd DD MMM YYYY')}</p>
                                                        </div></div>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 pl-0">
                                                <div className="fix_time_box" style={{ display: "flex" }}>
                                                    <div className="__time time_box active  __time time_box" >
                                                    <h6 className="start_time mb-0 __datepicker_input_height">Start Time</h6>
                                                    <h6 style={{ fontWeight: 400, fontSize: "17px" }}>{this.state?.slotInfo?.startTime ? this.state?.slotInfo?.startTime : ""}</h6>
                                                    </div>
                                                    <div className="__time time_box active">
                                                    <h6 className="start_time mb-0 __datepicker_input_height">End Time</h6>
                                                    <h6 style={{ fontWeight: 400, fontSize: "17px" }}>{this.state?.slotInfo?.endTime ? this.state?.slotInfo?.endTime : ""}</h6>

                                                </div>
                                                    <div className="__time time_box active">
                                                    <h6 className="start_time" style={{ marginBottom: '4px'}}>Duration</h6>
                                                    <h6 style={{ fontWeight: 400, fontSize: "17px" }}>{this.state.totalTime}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>:""}
                                        <div className="row">
                                             <div className="col-md-6  ">
                                                <div className='input_group_u __Client '>
                                                <span className="_field_label_vk">Staff Member</span>
                                                <div className={`service_selected1 mt-0 w-100 disabled_div  hide__arrow`} >
                                                    {this.props.hairdresserBooking.bookings[0].hairdresser.name}</div>

                                                </div>
                                            </div>
                                           <div className="col-md-6 pl-0">
                                                <div className='input_group_u __Client '>
                                                <span className="_field_label_vk">Client</span>
                                                <div className={`service_selected1 mt-0 w-100 disabled_div hide__arrow `} >{this.props.hairdresserBooking.bookings[0].user.name}</div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-3 pr-0">
                                                <div className={"input_group_u"}>
                                                <span className="_field_label_vk">Price</span>
                                                <div className="currency_div mt-0 ml-0">
                                                    <div className="text-center px-3 currency_sml">{this.state.currencySymbol}</div>
                                                    <div className="text-center pl-3 currency_sml1">
                                                        {parseFloat(services?.servicePrice).toFixed(2)}
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="col-md-9 ">
                                            {/* hide__arrow */}
                                                <div className={`input_group_u __service hide__arrow `}>
                                                <span className="_field_label_vk">Service</span>
                                                <div className={`mt-0 mb-4 bg-white service_selected1 border-1 w-100 `} >{services.service?.serviceName}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='_processing_time'>
                                        {(services?.processingTime&&services?.processingTime!==0&&((i+1)!==this.props.hairdresserBooking.bookings[0].servicesBooked.length))?
                                        <div className='processing_time_msz'><span className='_icon mr-2'><PendingIcon/></span> {services?.processingTime} mins processing time</div>
                                            :""}
                                    </div>

                                            </>
                                        )):""
                                    }
                                </div>

                            </div>
                            
                            {(this?.state?.selectedClient && this?.state?.hairdresserId!='')?
                                <div className="checkout_page ">
                                    <ClientCheckout
                                        bookingState={this.state}
                                        borderr="borderemove"
                                        cancelBookingModal={this.state.cancelBookingModal}
                                        noShowModal={this.state.noShowModal}
                                        editBookingModal={this.state.editBookingModal}
                                        rescheduleRequestModal={this.state.rescheduleRequestModal}
                                        selectedClientId={this.state.selectedClientId}
                                        selectedClient={this?.state?.selectedClient}
                                        currencySymbol={this.state.currencySymbol}
                                        pendingAmount={this.state.pendingAmount}
                                        checkoutBooking={this.checkoutBooking}
                                        userId={this.state.userId}
                                        hairdresserId={this.state.hairdresserId}
                                        noShowBooking={this.noShowBooking}
                                        noShowBookingProcess={this.state.noShowBookingProcess}
                                        cancelBooking={this.cancelBooking}
                                        rescheduleBooking={this.rescheduleBooking}
                                        calculateEndTime={this.calculateEndTime}
                                        bookings={this.state.booking}
                                        history={this.props.history}
                                        selectedTime={this.state.selectSlot}
                                        totalAmount={this.state.totalAmount}
                                        paymentType={this.state.paymentType}
                                        noShowFeeAmount={this.state.noShowFeeAmount}
                                        noShowPolicyStatus={this.state.noShowPolicyStatus}
                                        noShowOnChangeNoShow={this.noShowOnChangeNoShow}
                                        noShowPopup={this.noShowPopup}
                                        noShowButtonStatus={this.state?.booking?.bookingHairdresserAction==6||this?.state?.booking.bookingStatus==6}
                                        noShowSelectedOption={this.state.noShowSelectedOption}
                                        noShowErrorStatus={this.state.noShowErrorStatus}
                                    />
                                </div>
                                :null}
                        </div>

                        </>
                            : <div className="cm_loader"><div className="loader"></div></div>}
                            </>
                    </div>
                    </div>

            </div>

        )
    }

}

function mapStateToProps(state) {
    return {
        ...state
    };
}


export default connect(mapStateToProps)(ViewBooking);

