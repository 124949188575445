import axios from 'axios';
import API from "../config/API";

export const productService = {
    listProduct,
    addProduct,
    updateProduct,
    deleteProduct,
    getProduct,
    getProductsList
};

function listProduct(params) {
    let accessToken = localStorage.getItem('accessToken');
    let headers = {
      'Content-Type': 'application/json',
      'authorization': "Basic YmV1YXBwOmJldWFwcA==",
      'accessToken': `${accessToken}`
    };
  
    if (!params.search && !params.page && !params.limit) {
      return axios.get(API.LIST_PRODUCT, {
        headers: headers
      });
    } else {
      return axios.get(API.LIST_PRODUCT, {
        headers: headers,
        params: {
          search: params.search || undefined,
          page: params.page,
          limit: params.limit
        }
      });
    }
  }

  function getProductsList(params) {
    let accessToken = localStorage.getItem('accessToken');
    let headers = {
      'Content-Type': 'application/json',
      'authorization': "Basic YmV1YXBwOmJldWFwcA==",
      'accessToken': `${accessToken}`
    };
  
    if (params?.salonId!=='') {
      return axios.get(API.GET_SALON_PRODUCT, {
        headers: headers,
        params: {
          salonId: params.salonId 
        }
      });
    } 
  }

  
  

function getProduct(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    // console.log("headers",headers, params);
    return axios.get(API.GET_PRODUCT,{headers:headers})
}

function addProduct(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    // console.log("headers",headers, params);
    return axios.post(API.ADD_PRODUCT, params,{headers:headers})
}

function updateProduct(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    // console.log("headers",headers, params);
    return axios.put(API.UPDATE_PRODUCT, params,{headers:headers})
}

function deleteProduct(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    // console.log("headers",headers, params);
    return axios.post(API.DELETE_PRODUCT, params, {headers:headers})
}

