import React, { useEffect } from 'react';
import {Tab, Tabs, Dropdown, Accordion, Card, Button} from 'react-bootstrap';
import moment from "moment";
import {Close} from "../SvgIcon";
const isMobile = (window.innerWidth <= 768);
const Checkout = (propes) => {
    console.log("clientclient",propes);
    let { borderr } =propes;
    // console.log("borderrborderr",borderr)
    const getslotTime=(time)=> {
        let hr = parseInt(time / 60);
        let min = time - (hr * 60)
        return hr + ':' + (min === 0 ? '00' : min);
    }
    useEffect(()=>{
        console.log("clientclient",propes);
    })

    const checkEmail=(email)=>{
        if(email) {
            let emailArr = email.split('@');
            if (emailArr[1] === 'beuapp.com') {
                return false;
            } else {
                return true;
            }
        }else{
            return false;
        }
    }

    return(
        
        propes.selectedClient?
            (<div className="cm_right_panal client_checkout newclient-checkout ">
                <h1>{borderr}</h1>
                <div className="backgroun-shadow" style={{width:"100%"}}>
                    <div className="cm_bao">
                        <img
                            src={propes.selectedClient.localAvatar != propes.selectedClient.localAvatar ? "" : require('../../assets/images/client-plachoder.jpg')}/>
                        <h3>{propes.selectedClient.name}</h3>
                        {checkEmail(propes.selectedClient.email)?<p>{propes.selectedClient.email}</p>:""}
                        <p>{propes.selectedClient.phoneNumber}</p>
                    </div>
                    <div className="cm_body checkout-parent">
                        <div className="bobking_detail ">
                            {propes.bookingState.isPaymentComplete==1?
                                <div className="text-center">
                                    <img src={require('../../assets/images/payment-taken.png')} className="img-fluid"/>

                                    <h4 className="mt-4">Payment Taken</h4>
                                    <p>Complete checkout to generate an invoice and finish this booking.</p>
                                </div>
                                :
                                <>
                                    {isMobile?
                            <div className="three_box three_boxthree">
                                <div className="cm_b_details">
                                    <p>Start Time</p>
                                    <h5>{propes.bookingState.slotInfo.startTime}</h5>
                                </div>
                                <div className="cm_b_details">
                                    <p>Duration</p>
                                    <h5>{propes.bookingState.totalTime}</h5>
                                </div>
                                <div className="cm_b_details">
                                    <p>Cost</p><h5>{propes.currencySymbol}{parseFloat(propes.totalAmount).toFixed(2)}</h5>
                                </div>
                            </div>
                                        :""}
                            <div className="payment-bill m-0 p-0">
                                <div className="tble">
                                    <p>Total</p>
                                    <p>{propes.currencySymbol}{parseFloat(propes.totalAmount).toFixed(2)}</p>
                                </div>
                                {propes.bookingState.transactions.length>0?propes.bookingState.transactions.map((transaction, index) => (

                                    transaction.pgObjectType=='deposit'?
                                    <div className="tble green" key={"t_"+index}>
                                        <p>Deposit Paid</p>
                                        <p>{propes.currencySymbol}{transaction.pgTransactionAmount.toFixed(2)}</p>
                                    </div>
                                            :""
                                )):<div className="tble green disabled">
                                    <p>Deposit Paid</p>
                                    <p>£0.00</p>
                                    </div>}
                                {propes.bookingState.transaction.map((transaction, index) => (
                                    <div className="tble" key={"t_"+index}>
                                        <p><span className="mr-3" onClick={()=>propes.removeTransaction(transaction)}><Close/></span>{transaction.paymentType === 1 ? 'Pay by App' : ''}{transaction.paymentType === 2 ? 'Card' : ''}{transaction.paymentType === 3 ? 'Cash' : ''}{transaction.paymentType === 4 ? 'Other' : ''}</p>
                                        <p>{propes.currencySymbol}{transaction.amount.toFixed(2)}</p>
                                    </div>
                                ))}
                            </div>
                                    <div className="border-div detl flex-just-align-center pointer-event" onClick={propes.selectBookingAmount.bind(this,1)}>
                                        <div >
                                            <label className="radio-container">Balance Remaining
                                                <input type="radio" checked={propes.bookingState.checkOutAmountType} name="radi1" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <h5>{propes.currencySymbol}{propes.bookingState.pendingAmount.toFixed(2)}</h5>
                                    </div>
                                    <div className="border-div detl flex-just-align-center pointer-event" onClick={propes.selectBookingAmount.bind(this,2)}>
                                        <div >
                                            <label className="radio-container">Custom Amount
                                                <input type="radio" name="radi" checked={!propes.bookingState.checkOutAmountType} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>

                                        <div>
                                            <span className="currency-input">{propes.currencySymbol}</span>
                                            <input type="number" name="selectedAmount" value={propes.bookingState.selectedAmount} disabled={propes.bookingState.checkOutAmountType} placeholder="0.00" className="" onChange={propes.onChangedValue}/>

                                        </div>

                                    </div>
                            </>}
                            {propes.paymentAmountError ?
                                <span className="cm_error_msg">{propes.paymentAmountError}</span> : ''}

                        </div>

                    </div>
                </div>
                {propes.bookingState.isPaymentComplete==0?
                    <div className="backgroun-shadow checkout-parent">
                        <h5>Payment Method</h5>
                        <div className="border-div detl pointer-event" onClick={() => propes.setPaymentType(2)}>
                            <div>
                                <label className="radio-container">Card
                                    <input type="radio"  name="radio3" checked={propes.bookingState.selectedPaymentType==2?true:false} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div className="border-div detl pointer-event" onClick={() => propes.setPaymentType(3)}>
                            <div>
                                <label className="radio-container">Cash
                                    <input type="radio"  name="radio2" checked={propes.bookingState.selectedPaymentType==3?true:false} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div className="border-div detl pointer-event" onClick={() => propes.setPaymentType(4)}>
                            <div>
                                <label className="radio-container">Other
                                    <input type="radio"  name="radio1" checked={propes.bookingState.selectedPaymentType==4?true:false} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    :""}
                <div className="backgroun-shadow checkout-parent noshadow">
                    <div className="d-flex footer-btn">
                        {/*<button className="btn btn-bhite btn-block" onClick={() =>propes.resetBooking()} >Cancel</button>*/}
                        <button className="btn btn-blue btn-block" onClick={() =>propes.completeSale()} disabled={propes.checkCompleteSale()} >
                            {/*Continue*/}
                            Confirm
                        </button>
                    </div>
                </div>
            </div>):""
    )
}

export default Checkout;
