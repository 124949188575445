import React, { useEffect, useState,useRef } from "react";
import { useHistory } from "react-router-dom";
import siteSetting from "../../config/env";
import toast from "react-toast-notification";
import TopHeader from "../template/TopHeader";
import SideNav from "../template/SideNav";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { marketingAction } from "../../_actions/marketing.actions";

function UpdateCustomReminder(props) {
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reminderTimeTypeOption, setReminderTimeTypeOption] = useState(
    siteSetting?.reminderTimeOptions
  );
  const [triggerTypeOptions, setTriggerTypeOptions] = useState(
    siteSetting?.triggerTypeOptions
  );
  const [timeoutId, setTimeoutId] = useState(null);
  const [customReminderError, setCustomReminderError] = useState({});
  const [customReminderData, setCustomReminderData] = useState({
    automationText:siteSetting?.SMSText?.customReminderText
  });
  const router = useHistory();
  const editContent = useRef();

  const updateMarketingData = useSelector(
    (state) => state?.updateMarketingData
  );

  const dispatch = useDispatch();

  useEffect(() => {
    console.log('check data',updateMarketingData);
    if (updateMarketingData?.reminder) {
      const _triggerType =  triggerTypeOptions.find((triggerType => triggerType?.value==updateMarketingData?.reminder?.triggerType))
      const _reminderTimeType =  reminderTimeTypeOption.find((reminderTimeType => reminderTimeType?.value==updateMarketingData?.reminder?.reminderTimeType))
      const updateData = {
        reminderTime:updateMarketingData?.reminder?.reminderTime,
        title:updateMarketingData?.reminder?.title,
        description:updateMarketingData?.reminder?.description,
        reminderTimeType: _reminderTimeType,
        automationText: updateMarketingData?.reminder?.smsText,
        statusSMSreminder: updateMarketingData?.reminder?.statusSMSreminder,
        statusEmailReminder: updateMarketingData?.reminder?.statusEmailReminder,
        triggerType:_triggerType,
      };
      setCustomReminderData(updateData);
      setIsEdit(updateMarketingData?.editStatus);
    }

    return () => {
      if (timeoutId != null) {
        clearTimeout(timeoutId);
      }
    };
    /* eslint-disable-next-line */
  }, []);

  const showPreview = (type) => {
    let message = "";
    if (type === "SMS") {
      message = customReminderData?.automationText;
      router.push({
        pathname: "/preview",
        state: {
          previousUrl: router?.location?.pathname,
          previewText: message,
          type: type,
        },
      });
    } else {
      if (type === "Email") {
        console.log('check trigger type',customReminderData?.triggerType);
        let selectedType 
        switch(customReminderData?.triggerType.value){
          case 1:
            selectedType ='New Booking';
            break;
          case 2:
            selectedType ='Reminder to rebook'
            break;
          default:
            selectedType ='Happy birthday'
          break;
        }

        message = siteSetting?.SMSText?.reminder;
        router.push({
          pathname: "/email-preview",
          state: {
            previousUrl: router?.location?.pathname,
            previewText: message,
            type: selectedType,
          },
        });
      }
    }
  };


  const onChange = (data, type) => {
    setCustomReminderError({
      ...customReminderError,
      [type]:``,
    });

    setIsLoading(true);
    switch (type) {
      case "title":
        setCustomReminderData({
          ...customReminderData,
          title: data?.target?.value,
        });
        break;

      case "description":
        setCustomReminderData({
          ...customReminderData,
          description: data?.target?.value,
        });
        break;

      case "automationText":
        setCustomReminderData({
          ...customReminderData,
          automationText: data?.target?.value,
        });
        break;

      case "triggerType":
        setCustomReminderData({
          ...customReminderData,
          triggerType: data,
        });
        break;
      case "reminderTime":
        console.log('check this data',data?.target?.value)
        const regex = /^[0-9\b]+$/;
        if (data?.target?.value === "" || regex.test(data?.target?.value)) {
          setCustomReminderError({
            ...customReminderError,
            reminderTime: null,
          });
          setCustomReminderData({
            ...customReminderData,
            reminderTime: data?.target?.value,
          });
        } else {
          setCustomReminderError({
            ...customReminderError,
            reminderTime: "Enter valid time in number.",
          });
        }

        break;
      case "reminderTimeType":
        setCustomReminderData({ ...customReminderData, reminderTimeType: data });
        break;

      default:
        data = data ? 1 : 0;
        setCustomReminderData({ ...customReminderData, [type]: data });
        break;
    }
  };

  function validate(data) {
    const requiredFields = ["title", "description", "automationText", "triggerType", "reminderTime", "reminderTimeType", "statusSMSreminder"];
    for (const field of requiredFields) {
      console.log('check new data',field);
      if (!(field in data) || data[field]=='') {
        setCustomReminderError({
          ...customReminderError,
          [field]:`Field is required.`,
        });
        return false;
      }
    }

    if (typeof data.title !== "string" || data.title.length === 0 ) {
      return false;
    }
  
    if (typeof data.description !== "string" || data.description.length === 0) {
      return false;
    }
  
    if (typeof data.automationText !== "string" || data.automationText.length === 0 ) {
      return false;
    }
  
    if (!data.triggerType || typeof data.triggerType !== "object" || typeof data.triggerType.value !== "number" ) {
      return false;
    }
  
    if (!/^[1-9][0-9]*$/.test(data.reminderTime)) {
      return false;
    }
  
    if (!data.reminderTimeType || typeof data.reminderTimeType !== "object" || typeof data.reminderTimeType.value !== "number") {
      return false;
    }
  
    if (typeof data.statusSMSreminder !== "number" || ![0, 1].includes(data.statusSMSreminder)) {
      return false;
    }
  
    return true;
  }



  const deleteReminder =()=>{
    setIsLoading(false);
    if (updateMarketingData?.reminder) {
      const data = {
        reminderId: updateMarketingData?.reminder?._id,
        type: 5,
        salonId: updateMarketingData?.salonId,
      };
    dispatch(marketingAction.removeReminder(data)).then((res) => {
      if (res?.data?.statusCode === 1) {
        setIsLoading(true);
        dispatch(marketingAction.marketingUpdateSettingData({}));
        setIsEdit(false);
        router.push(updateMarketingData?.previousUrl);
        toast.success(res?.data?.responseData?.message);
        let timerId = setTimeout(()=>{
            router.push(updateMarketingData?.previousUrl);
        },500)
        setTimeoutId(timerId);
      }
    });
  }
  }

  const updateSetting = () => {
    setIsLoading(false);
    let newReminder;
  
    console.log('check validation data',customReminderData,validate(customReminderData));
    if(!validate(customReminderData)){
      return;
    }

    newReminder = {
      reminders: [{
        _id:updateMarketingData?.reminder?._id,
        reminderTime: +customReminderData?.reminderTime,
        reminderTimeType: customReminderData?.reminderTimeType?.value,
        title: customReminderData?.title,
        description: customReminderData?.description,
        smsText: customReminderData?.automationText,
        statusSMSreminder: customReminderData?.statusSMSreminder?customReminderData?.statusSMSreminder:0,
        statusEmailReminder: customReminderData?.statusEmailReminder?customReminderData?.statusEmailReminder:0,
        triggerType:customReminderData?.triggerType?.value,
      }],
      type:5,
      salonId:updateMarketingData?.salonId
    };
    
    dispatch(marketingAction.updateSetting(newReminder)).then((res) => {
      if (res?.data?.statusCode === 1) {
        setIsLoading(true);
        newReminder.reminder = newReminder.reminders[0];
        dispatch(marketingAction.marketingUpdateSettingData(newReminder));
        setIsEdit(false);
        router.push(updateMarketingData?.previousUrl);
        toast.success(res?.data?.responseData?.message);
        let timerId = setTimeout(()=>{
            router.push(updateMarketingData?.previousUrl);
        },500)
        setTimeoutId(timerId);
      }
    });
  };


  const addVariable =(text)=>{
    let prevText =  customReminderData.automationText?customReminderData.automationText:'';
    setCustomReminderData({
      ...customReminderData,
      automationText:prevText+' '+text+' '
    });

    editContent.current.focus();
  }


  return (
    <div className="h_n">
      <div className="body_container cm_bg_white">
      <TopHeader
      type='reminder'
      isLoadingStatus={isLoading}
      previousPageUrl={updateMarketingData?.previousUrl}
      headerButtonStatus={true}
      deleteReminder={deleteReminder}
      deleteReminderButton={isEdit}
      cancelButton={true}
      saveReminderButton={true}
      saveReminder={updateSetting}
      cancelButtonText={"Back"}
      title={'Update '+updateMarketingData?.reminder?.title}
      {...props} 
      />
      <SideNav {...props} />
        <div className="new-setting-wrapper __reminder">
          <div className="container">
            <div className="row">
              <div className="_reminder_section ">
                <div className="new-stng-cmn _remind">
                  <h5 className="">Update {updateMarketingData?.reminder?.title} Automation</h5>
                  <p className="mt-2 mb-3">
                    {updateMarketingData?.reminder?.description}
                  </p>
                  
                  <form className="">
                    <div className={`form-group input_group_u  _staff_member`}>
                      <span className="_field_label_vk">Automation name</span>
                      <input
                        type="text"
                        onChange={(e) => onChange(e, "title")}
                        value={customReminderData?.title}
                        className="form-control"
                      />
                      {(customReminderError?.title!==''&&customReminderError?.title!==undefined)? (
                        <p className="text-danger">
                          {customReminderError?.title}
                        </p>
                      ) : null}
                    </div>
                    <div className={`form-group input_group_u  _staff_member`}>
                      <span className="_field_label_vk">
                        Automation Description
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => onChange(e, "description")}
                        value={customReminderData?.description}
                      />
                      {(customReminderError?.description!==''&&customReminderError?.description!==undefined)? (
                        <p className="text-danger">
                          {customReminderError?.description}
                        </p>
                      ) : null}
                    </div>
                    <div className={`form-group input_group_u  _staff_member`}>
                    <span className="_field_label_vk">Automation Text</span>
                    <div className="mt-2 editable-border">
                      <div className="editable_span_prent"> 
                      <span className="editableContentButton bg_1" onClick={()=>addVariable("{{SalonName}}")} >Salon Name</span>
                    <span className="editableContentButton bg_2" onClick={()=>addVariable("{{Client}}")}>Client’s Name</span> 
                    <span className="editableContentButton bg_3" onClick={()=>addVariable("{{StylistName}}")}>Stylist’s Name</span> 
                    <span className="editableContentButton bg_4" onClick={()=>addVariable("{{DateTime}}")}>Next Appointment Date</span>
                    {/*<span className="editableContentButton bg_5" onClick={()=>addVariable("{{BookingLink}}")}>Booking Link</span>*/}
                      </div>
                   
                    <textarea
                    ref={editContent}
                    value={customReminderData?.automationText}
                    onChange={(e) => onChange(e, "automationText")}
                    className={'newEditableContent'}
                    />
                    </div>
            
                    {(customReminderError?.automationText!==''&&customReminderError?.automationText!==undefined)? (
                      <p className="text-danger">
                        {customReminderError?.automationText}
                      </p>
                    ) : null}
                  </div>
                  <div
                  className={`form-group input_group_u  _staff_member border_blue`}
                >
                  <span className="_field_label_vk">Trigger</span>
                  <Select
                    defaultValue={null}
                    options={triggerTypeOptions}
                    value={customReminderData?.triggerType}
                    onChange={(e) =>onChange(e, "triggerType")}
                  />
                  {(customReminderError?.triggerType!==''&&customReminderError?.triggerType!==undefined)? (
                    <p className="text-danger">
                      {customReminderError?.triggerType}
                    </p>
                  ) : null}
                </div>
                    <div className="row _update_custom">
                      <div className="col-sm-4">
                        <div className="input_group_u  _staff_member">
                          <span className="_field_label_vk">Trigger Time</span>
                          <input
                            type="number"
                            onChange={(e) => onChange(e, "reminderTime")}
                            value={customReminderData?.reminderTime}
                            className="form-control text-center"
                          />
                          {(customReminderError?.triggerType!==''&&customReminderError?.triggerType!==undefined)? (
                            <p className="text-danger">
                              {customReminderError?.triggerType}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div
                          className={`input_group_u  _staff_member border_blue`}
                        >
                          <span className="_field_label_vk">
                            Minutes, Hours, Days, Weeks, Month
                          </span>
                          <Select
                            defaultValue={null}
                            value={customReminderData?.reminderTimeType}
                            options={reminderTimeTypeOption}
                            onChange={(e) => onChange(e, "reminderTimeType")}
                          />
                          {(customReminderError?.reminderTimeType!==''&&customReminderError?.reminderTimeType!==undefined)? (
                            <p className="text-danger">
                              {customReminderError?.reminderTimeType}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="new-stng-cmn _sms_reminder">
                  <div className="_image">
                    <img
                      src={require("../../assets/images/SMSPreviewSVG.svg")}
                      className="img-fluid mb-3"
                    />
                  </div>
                  <div className="_text">
                    <div className="_head d-flex">
                      <h4>SMS Reminder</h4>
                      <div className="salon_switch_container">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              onChange(e?.target?.checked, "statusSMSreminder")
                            }
                            checked={customReminderData?.statusSMSreminder ? true : false}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <p className="mt-2 mb-4">£0.04/ message</p>
                    <p>
                    SMS messages are sent to <b>all clients</b> to the phone number you have saved on client record.
                    </p>
                    <p onClick={() => showPreview("SMS")} className="_link">
                      Preview SMS
                    </p>
                  </div>
                </div>

              {/* <div className="new-stng-cmn _sms_reminder">
              //     <div className="_image">
                    //  <img
                    //   src={require("../../assets/images/email_imag00.svg")}
                    //   className="img-fluid mb-3"
                    //   alt="email"
                    // />
              //     </div>
              //     <div className="_text">
              //       <div className="_head d-flex">
              //         <h4>Email</h4>
              //         <div className="salon_switch_container">
              //           <label className="switch">
              //             <input
              //               type="checkbox"
              //               onChange={(e) =>
              //                 onChange(
              //                   e?.target?.checked,
              //                   "statusEmailReminder"
              //                 )
              //               }
              //               checked={
              //                 customReminderData?.statusEmailReminder ? true : false
              //               }
              //             />
              //             <span className="slider round"></span>
              //           </label>
              //         </div>
              //       </div>
              //       <p className="mt-2 mb-4">Free</p>
              //       <p>
              //       Emails are sent to <b>all clients</b> to the email address you have saved on their client record.
              //       </p>
              //       <p onClick={() => showPreview("Email")} className="_link">
              //         Preview Email
              //       </p>
              //     </div>
                           </div> */}
              </div>
              <div className="cm_btns withcheckoutbtn mt-4 checkout__save_btn_mobile">
              <button className="btn btn-danger ml-3 mr-3 pl-4 pr-4" onClick={()=>deleteReminder()}>Delete</button>
              <button className="btn btn-dark-blue" onClick={()=>updateSetting()}>Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateCustomReminder;
