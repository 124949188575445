import React, { Component } from 'react';
import { connect } from 'react-redux';

import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import { Link } from 'react-router-dom';


class EditBankAccount extends Component {

    constructor(props){
        super(props);


    }

    closeEdit=()=>{
        this.props.history.push('/settings/account')
    }

    render() {
        return (
            <div className="h_n">
            <div className="body_container cm_bg_white setting_page">

                <TopHeader title="SalonSettings" {...this.props}/>
                <SideNav {...this.props}/>
                


                    <nav className="nav nav-tabs">
                        <Link className="nav-item nav-link" to="/settings">Salon SalonSettings</Link>
                        <a className="nav-item nav-link active" href="javascript:void(0);">Account SalonSettings</a>
                        <Link className="nav-item nav-link" to="/settings/profile">Profile SalonSettings</Link>
                    </nav>
                            <div className="seetings_wrap_box">
                                <div className="row">
                                   <div className="col-md-3" />
                                    <div className="col-md-6">

                                        <div className="text-center link_img">
                                        <img src={require('../../assets/images/bank-bg.png')} width="250px" />
                                            <h2 className="cm_fw_b">Edit Bank Account</h2>
                                        </div>


                                        <div className="form-group position-relative">
                                            <label>Salon Bank Account</label>
                                            <input type="text" className="form-control" value="" />
                                            <img src={require('../../assets/images/pass-icon.png')} className="input__icon" />
                                        </div>

                                        <div className="form-group">
                                            <label>New Account Number</label>
                                            <input type="text" className="form-control" />
                                        </div>

                                        <div className="form-group">
                                            <label>New Sort Code</label>
                                            <input type="text" className="form-control" />
                                        </div>

                                        <div className="text-center pr-5 mt-4">
                                            <button className="btn btn-outline-dark pl-4 pr-4 ml-3" onClick={this.closeEdit}>Cancel</button>
                                            <button type="button" className="btn btn-dark ml-3 pl-4 pr-4" >Save</button>
                                        </div>

                                        
                                    </div>
                                </div>
                            </div>

                            

              

            </div>






            
            

        </div>
        )
    }

}



function mapStateToProps(state) {
    return {
        state
    };
}

export default connect(mapStateToProps)(EditBankAccount);





