import React, { Component } from 'react';
import { connect } from 'react-redux';

import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import siteSetting from "../../config/env";
import {hairdresserActions, paymentActions} from "../../_actions";
import dateFormat from "dateformat";
import Select from 'react-select';
import PhoneInput,{isValidPhoneNumber} from 'react-phone-number-input';
import ReactCountryFlagsCurrencySelect from "react-country-flag-currency-picker";
import Switch from "react-switch";
import { Modal } from 'react-bootstrap';
import Lottie from 'react-lottie';
import animationData from '../../assets/animation-data/tick.json';
import { Plus, WeatheStrom ,Tick, InfoIcon, Close} from '../SvgIcon';
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
const options = [
    { value: '1', label: 'Employed' },
    { value: '2', label: 'Self-Employed' },
];
console.log("siteSetting",siteSetting);
class AddStaff extends Component {

    constructor(props){
        super(props);

        this.state = {
            name:'',
            first_name:'',
            last_name:'',
            email:'',
            phoneNumber:'',
            jobTitle:'',
            shortBio:'',
            circleImagePrev:require('../../assets/images/user-placeholder.png'),
            circleImage:require('../../assets/images/user-placeholder.png'),
            countryId:'217',
            salonId:localStorage.getItem('salonId'),
            employmentTypeId:this.props.location.state && this.props.location.state.employmentTypeId?this.props.location.state.employmentTypeId:1,
            employmentType:this.props.location.state && this.props.location.state.employmentTypeId&&(this.props.location.state.employmentTypeId===2||this.props.location.state.employmentTypeId==="2")?{ value: '2', label: 'Self-Employed' }:{ value: '1', label: 'Employed' },
            currencyCountry:"GB",
            currencySymbol:"£",
            currency:"GBP",
            salonCommissionRate:'',
            salonCommissionRateStatus:false,
            // activationCode:this.props.location.state && this.props.location.state.activationCode?this.props.location.state.activationCode:"",
            addStaffModal: false,
            addedStaffModalStatus: false,
            discount:0,
            applyCoupon:false,
            activatationCodeError:"",
            activationCode:"",
            error:"",
            activatationCodeSuccess:"",
            totalPayment:siteSetting.stripe.salonMonthlyPrice+siteSetting.stripe.oneSelfEmpPrice,
            totalPaymentBefore:siteSetting.stripe.salonMonthlyPrice+siteSetting.stripe.oneSelfEmpPrice,
            planPrice:siteSetting.stripe.salonMonthlyPrice,
            planType:"month",
            isApplyedPromocode: false,
            subscriptionInfo:{},
            oneSelfEmpPrice:siteSetting.stripe.oneSelfEmpPrice,
            oneSelfEmpPriceBefore:siteSetting.stripe.oneSelfEmpPrice,
            quantity:0,
            productCommissionRate:'',
            selfEmpCount:0,
        }
        this.addStaff=this.addStaff.bind(this);
        this.onChanged=this.onChanged.bind(this);
        this.onDataChange=this.onDataChange.bind(this);
        this.cancel=this.cancel.bind(this);
    }

    componentDidMount() {
        var self = this;
        let selfEmpCountData = self?.props?.location?.state?.selfEmpCount;
        if(selfEmpCountData>0){
          self.setState({
            selfEmpCount:selfEmpCountData
          })
        }
        console.log('selfEmpCountData',selfEmpCountData);
        if (localStorage.getItem('accessToken')) {
            let params={
                hairdresserId:this.props.match.params.id,
                lastName:'',
                email:'',
                jobTitle:'',
                phoneNumber:'',
                shortBio:''
            }
            params.latitude = siteSetting.default_latitude;
            params.longitude = siteSetting.default_longitude;
            self.subscriptionGet();
        }else{
            this.props.history.push('/')
        }
    }

    subscriptionGet=()=>{
        let self=this;
        let data={

        }
        console.log('checkkkkkk',self.state.selfEmpCount)
        self.props.dispatch(paymentActions.subscriptionGet(data))
            .then(function (response) {
                console.log("response",response);
                if (response.data.statusCode===1) {
                    let subscriptionInfo=response.data.responseData.result
                    if(subscriptionInfo?.plan?.interval==="year"){
                        self.setState({
                            subscriptionInfo: subscriptionInfo,
                            planPrice:siteSetting.stripe.salonYearlyPrice,
                            totalPayment:siteSetting.stripe.salonYearlyPrice,
                            totalPaymentBefore:siteSetting.stripe.salonYearlyPrice,
                            planType:"year",
                            quantity:response.data.responseData?.selfEmpPlan?.quantity?response.data.responseData?.selfEmpPlan?.quantity:self.state.selfEmpCount
                            //if quantity is undefined then total of selfemp selfEmpCount
                        })
                    }else{
                        self.setState({
                            subscriptionInfo: subscriptionInfo,
                            quantity:response.data.responseData?.selfEmpPlan?.quantity?response.data.responseData?.selfEmpPlan?.quantity:self.state.selfEmpCount
                            
                        })
                    }

                    // self.props.history.push('/staff')
                }else{
                    if (response.data.error.errorCode===3) {
                        self.setState({
                            emailError: response.data.error.responseMessage
                        })
                    }else{
                        self.setState({
                            emailError: response.data.error.responseMessage
                        })
                    }
                }
            })
    }

    addStaff(){
        this.setState({
            error:'',
            emailError:'',
            fNameError:'',
            phoneNumberError:'',
            jobError:''
        })
        let self = this;
        let params = {};
        params.name=this.state.first_name+" "+this.state.last_name;
        params.first_name=this.state.first_name;
        params.last_name=this.state.last_name;
        params.email=this.state.email;
        params.phoneNumber=this.state.phoneNumber;
        params.jobTitle=this.state.jobTitle;
        params.shortBio=this.state.shortBio;
        params.employmentTypeId=this.state.employmentTypeId;
        params.salonId=this.state.salonId;
        params.countryId=this.state.countryId;
        params.activationCode=this.state.activationCode;
        if (this.state.first_name === "" || this.state.first_name === undefined) {
            this.setState({
                fNameError: 'Please enter first name'
            })
        }
        else if (this.state.email === "" || this.state.email === undefined) {
            this.setState({
                emailError: "Please enter email address"
            })
        } else if (!this.validateEmail(this.state.email)) {
            this.setState({
                emailError: 'Please enter a valid email address'
            })
        } else if (this.state.phoneNumber === "" || this.state.phoneNumber === undefined ) {  //|| !isValidPhoneNumber(this.state.phoneNumber)
            this.setState({
                phoneNumberError: 'Please enter a valid phone number'
            })
        } else if (this.state.jobTitle === "" || this.state.jobTitle === undefined) {
            this.setState({
                jobError: 'Please enter a job title'
            })
        }else if (this.state.salonCommissionRateStatus === true && (this.state.salonCommissionRate === '' || this.state.salonCommissionRate === null || this.state.salonCommissionRate < 0 || this.state.salonCommissionRate >100)) {
                this.setState({
                    salonCommissionRateError: 'Commission should be between 1 to 100'
                })
        }
        // else if (this.state.shortBio === "" || this.state.shortBio === undefined) {
        //     this.setState({
        //         shortBioError: 'Please enter a short bio'
        //     })
        // }
        else {
            let data = new FormData();
            data.append('name', this.state.first_name+" "+this.state.last_name);
            data.append('first_name', this.state.first_name);
            data.append('last_name', this.state.last_name);
            data.append('email', this.state.email);
            data.append('phoneNumber', this.state.phoneNumber);
            data.append('jobTitle', this.state.jobTitle);
            data.append('shortBio', this.state.shortBio);
            data.append('employmentTypeId', this.state.employmentTypeId);
            data.append('salonId', this.state.salonId);
            data.append('countryId', this.state.countryId);
            data.append('circleImage', this.state.circleImage);
            data.append('rectangleImage', this.state.circleImage);
            data.append('currency', this.state.currency);
            data.append('currencySymbol', this.state.currencySymbol);
            data.append('currencyCountry', this.state.currencyCountry);
            data.append('salonCommissionRate', this.state.salonCommissionRate!=''?this.state.salonCommissionRate:0);
            data.append('productCommissionRate', this.state.productCommissionRate!=''?this.state.productCommissionRate:0);
            data.append('activationCode', this.state.activationCode);
            self.props.dispatch(hairdresserActions.addStaff(data))
                .then(function (response) {
                    console.log('client response', response);
                    if (response.data.statusCode===1) {
                        self.setState({
                            addedStaffModalStatus: true ,
                            addStaffModal:false
                        })
                        // self.props.history.push('/staff')
                    }else{
                        if (response.data.error.errorCode===3) {
                            self.setState({
                                emailError: response.data.error.responseMessage
                            })
                        }else{
                            self.setState({
                                emailError: response.data.error.responseMessage
                            })
                        }
                    }
                })
                .catch((err) => {
                    console.log("errr",err);
                    //dispatch(failure(err));

                })
        }
    }

    onChanged(e) {
        const { name, value, files } = e.target;
        let stateUpdates = {};
        
        switch (name) {
          case 'activationCode':
            const yearlyPrice = siteSetting.stripe.salonYearlyPrice;
            const monthlyPrice = siteSetting.stripe.salonMonthlyPrice + siteSetting.stripe.oneSelfEmpPrice;
            const totalPayment = this.state.planType === 'year' ? yearlyPrice : monthlyPrice;
      
            stateUpdates = {
              isApplyedPromocode: false,
              discount: 0,
              activatationCodeSuccess: "",
              totalPayment,
              totalPaymentBefore: totalPayment,
            };
            break;
      
          case 'email':
            stateUpdates.emailError = this.validateEmail(value) ? '' : 'Please enter valid email address';
            break;
      
          case 'first_name':
            stateUpdates.fNameError = value === '' || value === null ? 'Please enter a First Name' : '';
            break;
      
          case 'phoneNumber':
            stateUpdates.phoneNumberError = value.length !== 5 ? 'Please enter a valid phone number' : '';
            break;
      
          case 'jobTitle':
            stateUpdates.jobError = value === '' || value === null ? '' : '';
            break;
      
          case 'salonCommissionRate':
            stateUpdates.salonCommissionRateError = value === '' || value === null || value < 0 || value > 100
              ? 'Commission should be between 1 to 100'
              : '';
            break;
      
          case 'circleImage':
            const reader = new FileReader();
            const file = files[0];
      
            reader.onloadend = () => {
              this.setState({ circleImagePrev: reader.result });
            };
            reader.readAsDataURL(file);
      
            stateUpdates.circleImagePrev = file;
            stateUpdates.circleImage = file;
            break;
      
          default:
            break;
        }
      
        this.setState({
          [name]: value,
          copied: false,
          ...stateUpdates,
        });
      }
      

    openAddStaffmodal =()=>{
        const self = this.state;
        let arr =["first_name","last_name","email","phoneNumber","jobTitle" ]
        for(let field in self){
            if(arr.includes(field)){
                if(field==="first_name"){
                    this.setState({
                        fNameError:self?.first_name!==''?'':'Please enter a First Name',
                    })
                }
                else if(field==="email"){
                    this.setState({
                        emailError:this.validateEmail(self?.email)?'':'Please enter a valid email',

                    })
                }
                else if(field==="phoneNumber"){
                    this.setState({
                        phoneNumberError:(self?.phoneNumber!==''&& self?.phoneNumber.length !== 5)?'':'Please enter a valid phone number',
                    })
                }
                else if(field==="jobTitle"){
                    this.setState({
                        jobError:self?.jobTitle!==''?'':'Please enter a Job Title'
                    })
                }
            }
        }

        if(self.first_name!=='' && self.email!=='' && self.phoneNumber!=='' && self.jobTitle!==''){
            this.setState({
                addStaffModal: true
            })
        }
    }

    onDataChange(value, action){
        this.setState({
            [action.name]: value,
            employmentTypeId:value.value
        })
    }

    validateEmail(email) {
        const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const result = pattern.test(email);
        return result;
    }

    cancel(){
        this.props.history.push('/staff')
    }

    handlePhoneChange = val => {
        this.setState({phoneNumber: val})
        // if(!isValidPhoneNumber(val)) {
        //     this.setState({
        //         phoneNumberError: 'Please enter a valid phone number'
        //     })
        // }
    }

    getPhoneNumber(phone){
        //console.log("phone",phone);
        if(phone.length===10){
            //console.log("phone1",phone);
            if (phone[0] === 0) {
                //console.log("phone1.1",phone);
                return "+44"+phone.toString().substr(1)
            }else{
                //console.log("phone1.2",phone);
                return "+44"+phone.toString()
            }
        }else {
            //console.log("phone2",phone);
            if (phone[0] === 0) {
                //console.log("phone2.1",phone);
                return "+44"+phone.toString().substr(1)
            } else if (phone[0] === 4) {
                //console.log("phone2.2",phone);
                return "+" + phone.toString()
            } else {
                //console.log("phone2.3",phone);
                return "+44"+phone.toString()
            }
        }
    }

    onSelectCurrency=(country)=>{
        //console.log("country",country);
        this.setState({
            currency:country.currency,
            currencySymbol:country.symbol,
            currencyCountry:country.countryCode,
        })
    }

     onChangeSalonCommissionRate = (checked) => {
        console.log(`switch to ${checked}`);
         this.setState({
             salonCommissionRateStatus:checked
         })
         if(!checked){
             this.setState({
                 salonCommissionRate:''
             })
         }
    };

    handleClose = ()=>{
        this.setState({
            addStaffModal: false,
            activationCode:"",
            applyCoupon:false,
            activatationCodeError:"",
            error:"",
            activatationCodeSuccess:"",
            discount:0,
            employmentTypeId:"1",
        })
    }
    showActivationCode = ()=>{
        this.setState({
            applyCoupon: !this.state.applyCoupon,
        })
    }
    hideActivationCode = ()=>{
        let self = this;
        if(self.state.planType==="year"){
            self.setState({
                applyCoupon: false,
                activatationCodeError: "",
                error: "",
                activatationCodeSuccess: "",
                activationCode: "",
                totalPayment:siteSetting.stripe.salonYearlyPrice,
                totalPaymentBefore:siteSetting.stripe.salonYearlyPrice,
                discount:0,
                oneSelfEmpPrice:self.state.oneSelfEmpPriceBefore
            })
        }else {
            this.setState({
                applyCoupon: false,
                activatationCodeError: "",
                error: "",
                activatationCodeSuccess: "",
                activationCode: "",
                totalPayment: siteSetting.stripe.salonMonthlyPrice + siteSetting.stripe.oneSelfEmpPrice,
                totalPaymentBefore: siteSetting.stripe.salonMonthlyPrice + siteSetting.stripe.oneSelfEmpPrice,
                discount:0,
                oneSelfEmpPrice:self.state.oneSelfEmpPriceBefore
            })
        }
    }
    handleRadioChange=(event)=>{
        let self=this;
        this.setState({
            employmentTypeId: event.target.value,
            activationCode:"",
            applyCoupon:false,
            activatationCodeError:"",
            error:"",
            activatationCodeSuccess:"",
            discount:0,
            oneSelfEmpPrice:self.state.oneSelfEmpPriceBefore
        });
    }

    redirectToStaff =()=>{
        let self = this;
        self.props.history.push('/staff')
    }

    applyCoupan=()=>{
        this.setState({
            activatationCodeError:"",
            error:"",
            activatationCodeSuccess:""
        });
        let self = this;
        if(this.state.activationCode.trim()!="") {
            self.props.dispatch(paymentActions.activationCode({activationCode: self.state.activationCode.trim()}))
                .then(function (response) {
                    if (response.data.statusCode == 0) {
                        if (response.data.error.errorCode === 10) {
                            self.setState({
                                activatationCodeError: 'Activation Code “'+self.state.activationCode+'” does not exist'
                            })
                        } else {
                            self.setState({
                                activatationCodeError: 'Activation Code “'+self.state.activationCode+'” does not exist'
                            })
                        }
                    } else {
                        let discount = self.state.discount, totalPayment = self.state.oneSelfEmpPriceBefore;
                        if (response.data.responseData.result.percent_off) {
                            discount = (response.data.responseData.result.percent_off / 100) * totalPayment;
                            totalPayment = totalPayment - discount
                        }
                        if (response.data.responseData.result.amount_off) {
                            discount = response.data.responseData.result.amount_off/100;
                            totalPayment = totalPayment - discount
                        }
                        self.setState({
                            discount,
                            oneSelfEmpPrice:totalPayment,
                            isApplyedPromocode: true,
                            activatationCodeSuccess: "Activation code applied successfully"
                        })
                    }
                })
        }else{
            self.setState({
                activatationCodeError: "Please enter activation code",
            })
        }
    }
    showTooltip=()=>{
        this.setState({
            showTooltip:true
        })
     }
     hideTooltip=()=>{
        this.setState({
            showTooltip:false
        })
     }

    onChangeProductCommissionRate = (checked) => {
        console.log(`switch to ${checked}`);
        this.setState({
            productCommissionRateStatus:checked
        })
        if(!checked){
            this.setState({
                productCommissionRate:''
            })
        }
    };

    render() {
        console.log("state",this.state);
        console.log("props",this.props);
        let { addStaffModal ,addedStaffModalStatus} = this.state;
        return (
            <div className="h_n">
            <div className="body_container cm_bg_white staff_page">

                <TopHeader title="Add Staff" {...this.props}/>
                <SideNav {...this.props}/>


                <div className="container">

                        <div className="add_staff_form">
                            {this.state.error ?
                                <span className="cm_error_msg">{this.state.error}</span> : ''}
                            <div className="row">

                                <div className="col-md-6 form-group">
                                    <div className="file_upload" >
                                        <img src={this.state.circleImagePrev} alt="staff" onClick={(e) => this.circleImage.click()}/>
                                        <input ref={(ref) => this.circleImage = ref} aria-hidden="true" type="file" name="circleImage" id="circleImage" onChange={this.onChanged}/>
                                        <i className="fa fa-plus"  />
                                    </div>
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>Currency</label>
                                    <ReactCountryFlagsCurrencySelect className="form-control CurrencySelect"
                                         defaultCountry={this.state.currencyCountry}
                                         showOptionLabel={false}
                                         showCurrency={true}
                                         showCurrencySymbol={true}
                                         showSelectedLabel={false}
                                         onSelect={this.onSelectCurrency}
                                         ref="userCurrency"
                                    />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>First Name</label>
                                    <input type="text" className="form-control" name="first_name" value={this.state.first_name} onChange={this.onChanged}/>
                                    {this.state.fNameError ?
                                        <span className="cm_error_msg">{this.state.fNameError}</span> : ''}
                                </div>

                                <div className="col-md-6 form-group">
                                    <label>Surname</label>
                                    <input type="text" className="form-control" name="last_name" value={this.state.last_name} onChange={this.onChanged}/>
                                   {/*{this.state.lNameError ?
                                   <span className="cm_error_msg">{this.state.lNameError}</span> : ''} */} 
                                </div>

                                <div className="col-md-6 form-group">
                                    <label>Email</label>
                                    <input type="email" className="form-control" name="email" value={this.state.email} onChange={this.onChanged}/>
                                    {this.state.emailError ?
                                        <span className="cm_error_msg">{this.state.emailError}</span> : ''}
                                </div>

                                <div className="col-md-6 form-group cm_PhoneInput">
                                    <label>Mobile Number</label>
                                    {/*<input type="number" className="form-control" name="phoneNumber" value={this.state.phoneNumber} onChange={this.onChanged} max={10} min={10}/>*/}
                                    <PhoneInput
                                        international={true}
                                        defaultCountry="GB"
                                        country={"GB"}
                                        className="form-control"
                                        placeholder="Phone Number"
                                        name="phoneNumber"
                                        value={this.state.phoneNumber}
                                        onChange={this.handlePhoneChange}/>
                                    {this.state.phoneNumberError ?
                                        <span className="cm_error_msg">{this.state.phoneNumberError}</span> : ''}
                                </div>

                                <div className="col-md-6 form-group">
                                    <label>Job Title (viewable when making online booking)</label>
                                    <input type="text" className="form-control" value={this.state.jobTitle} name="jobTitle" onChange={this.onChanged}/>
                                    {this.state.jobError ?
                                        <span className="cm_error_msg">{this.state.jobError}</span> : ''}
                                </div>

                                <div className="col-md-6 form-group">
                                    <label>Employment Type</label>
                                    {/*<input type="text" className="form-control" />*/}
                                    <Select
                                        options={options}
                                        classNamePrefix="custom"
                                        name="employmentType"
                                        value={this.state.employmentType}
                                        onChange={this.onDataChange}
                                    />
                                </div>

                               
                                <div className="col-md-12 form-group">
                                    <label>Bio</label>
                                    <textarea className="form-control" value={this.state.shortBio} name="shortBio" onChange={this.onChanged}/>
                                    {this.state.shortBioError ?
                                        <span className="cm_error_msg">{this.state.shortBioError}</span> : ''}
                                </div>

                                {this.state.employmentTypeId!=1?
                                <div className="col-md-12 mt-3 mb-4"><h4 className=''>Commissions &nbsp; 
                                    {/* <AiOutlineInfoCircle onClick={this.openCommissionPopup} /> */}
                                    <div className='tooltip_parent  _edit_staff_tooltip'>
                                        
                                        <span className='info' onClick={() =>this.showTooltip()}><InfoIcon/></span>
                                        {this.state.showTooltip===true?
                                        <div className='tooltip-body'>
                                            <span className='close' onClick={() =>this.hideTooltip()}><Close/></span>
                                            {/* <h5>Access Settings</h5>
                                            <p>Enter the admin pin 07139 to access settings.</p> */}
                                            <h6>Commission Info</h6>
                      <p className='about_comm'><b>Service Commission</b> - this is the commission paid to the salon manager by the self-employed staff member.<br></br><br></br>  e.g. if the self-employed stylist has a commission value of 30% set, then the payment will be split at point of sale, with 30% of the service value sent to the salon manager. </p>
                      <p className='about_comm'><b>Product Commission</b> - this is the commission on the profit that will be paid to the self-employed staff member if they sell a product.<br></br> <br></br>  e.g. if the staff member has a commission value of 30% set and they sell a product, then 30% of the profit from that product will be sent to the self-employed staff members bank account.<br></br> <br></br>  The PROFIT is calculated as the RETAIL PRICE - STOCK PRICE, as set in the products info.<br></br> <br></br>  NOTE: if Product Commission is set at 100%, then all the profit goes to the self-employed staff member.</p>
                      
                                        </div>:''}
                                    </div></h4>
                                </div>
                                
                                :""}
                                {this.state.employmentTypeId!=1?
                                <div className="col-md-6 form-group">
                                    <label>Booking Commission <small className='without__b'>(paid to salon manager)</small></label>
                                    <div className="d-flex align-items-center">
                                        <p  className="text-center toggle ml-4 mr-4 mb-0">
                                            {/*<label className="switch mb-0">*/}
                                            {/*    <input type="checkbox"/>*/}
                                            {/*    <span className="slider round"></span>*/}
                                            {/*</label>*/}
                                            <Switch onColor='#007EA0' onChange={this.onChangeSalonCommissionRate} checked={this.state.salonCommissionRateStatus} />
                                        </p>
                                        <input type="number" className="form-control text-center" value={this.state.salonCommissionRate} name="salonCommissionRate" onChange={this.onChanged} disabled={!this.state.salonCommissionRateStatus} />

                                    </div>
                                    {this.state.salonCommissionRateError ?
                                        <span className="cm_error_msg">{this.state.salonCommissionRateError}</span> : ''}
                                </div>:""}

                                {this.state.employmentTypeId!=1?
                                <div className="col-md-6 form-group">
                                    <label>Product Commission <small className='without__b'>(on profits, paid to staff member)</small></label>
                                    <div className="d-flex align-items-center">
                                        <p  className="text-center toggle ml-4 mr-4 mb-0">
                                            <Switch onColor='#007EA0' onChange={this.onChangeProductCommissionRate} checked={this.state.productCommissionRateStatus} />
                                        </p>
                                        <input type="number" className="form-control text-center" value={this.state.productCommissionRate} name="productCommissionRate" onChange={this.onChanged} disabled={!this.state.productCommissionRateStatus} />

                                    </div>
                                    {this.state.productCommissionRateError ?
                                        <span className="cm_error_msg">{this.state.productCommissionRateError}</span> : ''}
                                </div>:""}


                            </div>
                        </div>

                        <div className="text-right">
                            <button className="btn btn-outline-dark pl-4 pr-4" onClick={this.cancel.bind(this)}>Cancel</button>
                            {/* <button type="submit" className="btn btn-dark ml-3 pl-4 pr-4" onClick={this.addStaff.bind(this)}>Save</button> */}
                            <button type="submit" className="btn btn-dark ml-3 pl-4 pr-4" onClick={()=>this.openAddStaffmodal()}>Save</button>
                        </div>

                </div>
{/* --modal start--- */}
        <Modal className="add-staff-modal-wrp" size="xl" show={addStaffModal} centered>
                <i className="fa fa-times cm_hide_mod" onClick={()=>this.handleClose()} />
                <div  className="top-header-setting-title d-lg-none">
                    <span className='btn' onClick={()=>this.handleClose()}>
                        <i  className="fa fa-chevron-left"></i>
                    </span>
                    <h5>Add Staff</h5>
                </div>
                <Modal.Body>
                    <div className='staff-modal-wrapper'>
                        <div className='row'>
                            <div className='col-lg-7'>
                                <div className='staff-modal-wrap-left'>
                                    <div className='staff-top-mdl'>
                                        <h1 className='text-blue-light'> Supercharge your <br/> salon/ barbershop </h1>
                                        <WeatheStrom />
                                    </div>
                                    <h3>Self Employed & Employed Staff</h3>
                                    <p>Beu allows employed and self-employed staff to work seamlessly together on the same platform. Self-employed staff are paid into their own account, and have ownership of their client data. Self-employed staff can either pay for their own account for £25 a month, or a salon owner can add them to the salon account for just £5 a month per additional staff member.</p>
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <div className='staff-modal-wrap-right'>
                                    <div className='emlpyed-bx'>
                                        <label className='d-block'>
                                            <input type="radio" name='employed' value={2}
                                                   checked={this.state.employmentTypeId === "2"}
                                                   onChange={(e)=>this.handleRadioChange(e)}/>
                                            <div className='epluyed-with-radio'>
                                                <div className='epluyed-with-radio-topbar'>
                                                    <h3>Self Employed</h3>
                                                    <span> + £{siteSetting.stripe.oneSelfEmpPrice} /mo</span>
                                                </div>
                                                <div className='epluyed-with-radio-body'>
                                                    <p>Paid directly into their own bank account</p>
                                                    <p>Any commissions set are split at point of transaction</p>
                                                    <p>Have ownership of their client data, keeping your business legal</p>
                                                </div>
                                            </div>
                                        </label>
                                    </div>

                                    <div className='emlpyed-bx'>
                                        <label className='d-block'>
                                            <input type="radio" name='employed' value={1}
                                                   checked={this.state.employmentTypeId === "1"}
                                                   onChange={(e)=>this.handleRadioChange(e)} />
                                            <div className='epluyed-with-radio'>
                                                <div className='epluyed-with-radio-topbar'>
                                                    <h3>Employed</h3>
                                                    <span>Free</span>
                                                </div>
                                                <div className='epluyed-with-radio-body'>
                                                    <p>Paid into the salon bank account</p>
                                                    <p>All data processed through staff member, belongs to the business</p>
                                                    <p>No extra charge for adding additional employed staff</p>
                                                </div>
                                            </div>
                                        </label>
                                    </div>

                                    <div className='staff-emplyed-plan'>
                                        {this.state.employmentTypeId === "2"?<>
                                        <div className='activation-code-wrp text-right'>
                                            <span className={this.state.applyCoupon ? 'd-none' : ''} onClick={()=>this.showActivationCode()}>Activation Code</span>
                                            {this.state.applyCoupon && <>
                                            <div className='add-acivation-field pt-2 mb-2'>
                                                <div className='add-acvt-input'>
                                                    <input className='form-control' placeholder='Add Activation Code…' name="activationCode" onChange={this.onChanged} />
                                                    <span onClick={()=>this.hideActivationCode()}><i className='fa fa-times'></i></span>
                                                </div>
                                                <button className='btn btn-dark' type='button' onClick={this.applyCoupan} disabled={this.state.isApplyedPromocode}>Apply</button>
                                                {this.state.activatationCodeError ?<div className='codeErr'>{this.state.activatationCodeError}</div>:""}
                                            </div>
                                            {this.state.activatationCodeSuccess ?
                                            <div className='apply-activation-code'>
                                                <div className='applyCode'>
                                                    <p className='mb-0'>Activation Code: {this.state.activationCode}</p>
                                                    <span onClick={()=>this.hideActivationCode()}><i className='fa fa-times'></i></span>
                                                </div>
                                                <p className='mb-0'>Activation code applied successfully</p>
                                            </div>:""}
                                            </>}
                                        </div>
                                        <div className='staff-emplyed-plan'>
                                            <table className='table table-borderless'>
                                                <thead>
                                                    <tr>
                                                        <th>Current Plan</th>
                                                        <th className='text-right'><span className='d-block d-lg-none'>New Subscription Price</span> <span className='d-none d-lg-block'>New Plan</span> </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{this.state.quantity>0?`Salon + ${this.state.quantity} Self Employed`:"Just Salon"}</td>
                                                        <td className='text-right'>Salon + {this.state.quantity+1} Self Employed </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{this.state.quantity>0?<>£{this.state.planPrice+this.state.oneSelfEmpPrice*(this.state.quantity)} a {this.state.planType}</>:<>£{this.state.planPrice} a {this.state.planType}</>}</td>
                                                        <td className='text-right code-aply'><>£{this.state.totalPayment + this.state.oneSelfEmpPrice*(this.state.quantity) } a {this.state.planType}</>  {this.state.discount>0?<strike>£{this.state.oneSelfEmpPriceBefore} a month</strike>:""} </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </>
                                        :""}
                                        <div className='staff-btn-btm'>
                                            <button className='btn btn-inf w-100 mb-3' onClick={this.addStaff.bind(this)}>Confirm</button>
                                            {this.state.employmentTypeId === "2"?<p>The additional self-employed staff member is billed monthly, and can be cancelled at any time.</p>:""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
</Modal>
{/* --modal end--- */}
{/* --Self-Employed-Staff Added modal start--- */}
{console.log("addedStaffModal addedStaffModal",this.state.addedStaffModalStatus)}

<Modal className="added-staff-modal-wrp " size="xs" show={addedStaffModalStatus} centered>
                <div  className="top-header-setting-title d-lg-none">
                    <span className='btn' onClick={()=>this.handleClose()}>
                        <i  className="fa fa-chevron-left"></i>
                    </span>
                    <h5>Add Staff</h5>
                </div>
                <Modal.Body>
                    <div className='added-staff-modal'>
                        <div className="text-center">
                            <Lottie options={defaultOptions}
                                height={100}
                                width={100}
                                />
                            <h4 className="">{this.state.employmentTypeId === "1" ? "Employed ":"Self Employed "} Staff Added</h4>
                            <p>Congratulations, your new  {this.state.employmentTypeId === "1" ? "employed ":"self employed "} staff member has been added to Beu</p>
                        </div>
                        <div className="cm_btns mobile-change-btn mt-5 mb-3">
                            <button className="btn btn-blue btn-block" onClick={()=>this.redirectToStaff()}>Continue</button>
                        </div>

                    </div>
                </Modal.Body>
</Modal>
{/* --Self-Employed-Staff Added modal end--- */}

            </div>

        </div>
        )
    }

}



function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(AddStaff);





