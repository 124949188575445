import promise from 'bluebird'
import {settingService} from "../_services";



export const settingActions = {
    updateCancellationPolicy,
    updateNoShowProtectionFee,
    getCancellationPolicy
};

/************************************** Marketing Action ******************************************************/

function updateCancellationPolicy(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            settingService.updateCancellationPolicy(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}


function updateNoShowProtectionFee(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            settingService.updateNoShowProtectionFee(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function getCancellationPolicy(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            settingService.getCancellationPolicy(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}
