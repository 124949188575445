import React, { Component } from 'react';
import { connect } from "react-redux";
import {Modal} from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import Popup from "reactjs-popup";
import ConfirmBookingPopup from "../hairdresser/ConfirmBookingPopup"
import Calendar from 'react-calendar';

import {Addicon, Close, MySpinner} from '../SvgIcon';

import siteSetting from '../../config/env'
import {hairdresserActions, bookingActions, paymentActions} from "../../_actions";
import ImageGallery from "react-image-gallery";
import { bookingConstants, settingConstants } from "../../_constants";
import ReactWeeklyDayPicker from "react-weekly-day-picker"
import dateFormat from "dateformat";
import StarRatings from "react-star-ratings";
import { createBooking } from "../../_reducers/booking.reducer";
import moment from 'moment';
import { loadStripe } from '@stripe/stripe-js';
import config from "../../config/env";


var _ = require('lodash');
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

const weekDays=["sun","mon", "tue", "wed", "thu", "fri", "sat"]
const stripePromise = loadStripe(config.stripeKey);

function getslotTime(time) {
    let hr = parseInt(time / 60);
    let min = time - (hr * 60)
    return hr + ':' + (min === 0 ? '00' : min);
}
let userIdHolder = [];

class HairdresserLeft extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.state = {
            CreateImageashowPopup: false,
            PaymentFailedPopup:false,
            date: new Date(),
            isOpenCalender: false,
            selectedService: [],
            isBookingButton: false,
            selectedDay: new Date(),
            bookingDate: null,
            month: monthNames[new Date().getMonth()],
            year: new Date().getFullYear(),
            bookingSlots: this.props.bookingSlots,
            finalBookingSlots: [],
            latitude: siteSetting.default_latitude,
            longitude: siteSetting.default_longitude,
            totalAmount: 0,
            totalTime: 0,
            selectedBookingSlots: '',
            selectedBookingSlotsIndex: '',
            slotInfo: {},
            morningCount: 0,
            afternoonCount: 0,
            eveningCount: 0,
            error: '',
            unavailablesDays:[],
            nextWorking:moment(),
            weekStart:moment().startOf('week'),
            currencySymbol:this.props.hairdresser.userInfo&&this.props.hairdresser.userInfo.hairdresser.currencySymbol&&this.props.hairdresser.userInfo.hairdresser.currencySymbol!=="undefined"&&this.props.hairdresser.userInfo.hairdresser.currencySymbol!==null?this.props.hairdresser.userInfo.hairdresser.currencySymbol:"£",
            paymentType:'Pay at Salon',
            totalDeposit:0,
            isFormLoader:false
        }
        this.toggleBooking = this.toggleBooking.bind(this);
        this.toggleBookingConf = this.toggleBookingConf.bind(this);
        this.serviceSelect = this.serviceSelect.bind(this);
        this.checkSelectedService = this.checkSelectedService.bind(this);
        this.openCreateImageashowPopup = this.openCreateImageashowPopup.bind(this);
        this.openPaymentFailedPopup = this.openPaymentFailedPopup.bind(this);
        this.selectDay = this.selectDay.bind(this);
        this.unselectDay = this.unselectDay.bind(this);
        this.PrevClick = this.PrevClick.bind(this);
        this.NextClick = this.NextClick.bind(this);
        this.toggleBookingService = this.toggleBookingService.bind(this);
        this.getslotTime = this.getslotTime.bind(this);
        this.selectSlot = this.selectSlot.bind(this);
        this.checkSelectedBookingSlots = this.checkSelectedBookingSlots.bind(this);
        this.bookingSlotCreate = this.bookingSlotCreate.bind(this);
        // this.createBooking = this.createBooking.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closePopUp = this.closePopUp.bind(this);
        this.toggleBookingPopup=this.toggleBookingPopup.bind(this);
        this.selectNextDate=this.selectNextDate.bind(this);
    }

    closeModal() {
        this.setState({
            CreateImageashowPopup: false,
            PaymentFailedPopup:false
        })
    }

    closePopUp() {
        this.props.dispatch({ type: settingConstants.TOGGLE_SERVICE_POP, value: 2 });
    }

    bookingSlotCreate(index) {
        let self = this;
        //console.log("#####",this.props.hairdresser.rotaInfo, this.props.hairdresser.rotaInfo.rota.schedule[0], this.props.hairdresser.rotaInfo.rota.schedule[0].profiles)
        if(this.props.hairdresser.rotaInfo&&this.props.hairdresser.rotaInfo.rota.schedule[0]&&this.props.hairdresser.rotaInfo.rota.schedule[0].profiles){
            let workingRota=this.props.hairdresser.rotaInfo.rota.schedule[0].profiles;
            //console.log("#####bookingSlotCreate",workingRota);
            let unavailablesDays=[];

            let nextWorking;
            _.map(weekDays, function (days,k) {
                let isCheck = _.find(workingRota, function (o) {
                    return days === o.day;
                })
                if(!isCheck){
                    unavailablesDays.push(k);
                }
                if(moment(self.state.selectedDay).format('d') >= k ){
                    nextWorking=moment(self.state.selectedDay).day("Sunday").add((k+1),"days")
                }
            })
            //console.log("#####bookingSlotCreate nextWorking",nextWorking)
            this.setState({
                unavailablesDays:unavailablesDays,
                nextWorking:nextWorking
            })
            //nextWorking
        }

        let finalBookingSlots = [];
        let pre;
        let next;
        let tempArr = []
        let flag = false;
        let k = index;
        let arrslots = [];
        let bookingSlots = self.state.bookingSlots;
        //bookingSlots.map(function(slots, i) {
        for (let i = index; i < bookingSlots.length; i++) {
            // console.log(index,bookingSlots.length,i, self.state.totalTime, bookingSlots[i],bookingSlots[i].status);
            let check = false;
            if (bookingSlots[i].status === 0) {
                tempArr.push(bookingSlots[i])
                // console.log('tempArr', tempArr);
                if (((tempArr[tempArr.length - 1]["endTime"]) - (tempArr[0]["startTime"])) >= self.state.totalTime) {

                    finalBookingSlots.push(tempArr[0])

                    for (let m = 0; m < bookingSlots.length; m++) {
                        if (tempArr[0]["startTime"] === bookingSlots[m]["startTime"]) {
                            k = m + 1;
                            flag = true;
                            break;
                        }
                    }
                    tempArr = []
                }
            } else {
                tempArr = []
            }

            if (flag === true) {
                break
            }
        }
        if (flag && k < bookingSlots.length) {
            self.bookingSlotCreate(k)
        }
        // console.log('finalBookingSlots', finalBookingSlots);
        if (finalBookingSlots[0]) {


            let finalslot = this.state.finalBookingSlots;
            finalslot.unshift(finalBookingSlots[0]);
            // console.log('finalslot', finalslot);
            let morningCount = 0;
            let afternoonCount = 0;
            let eveningCount = 0;
            for (let i = 0; i < finalslot.length; i++) {
                let bookingSlot = finalslot[i];
                if (bookingSlot) {
                    if (bookingSlot.status === 0 && bookingSlot.startTime < 720) {
                        morningCount = 1
                    }
                    if (bookingSlot.status === 0 && (bookingSlot.startTime >= 720 && bookingSlot.startTime < 1020)) {
                        afternoonCount = 1
                    }
                    if (bookingSlot.status === 0 && bookingSlot.startTime >= 1020) {
                        eveningCount = 1
                    }
                }

            }


            this.setState({
                finalBookingSlots: finalslot,
                morningCount,
                afternoonCount,
                eveningCount,
                slotInfo: {}
            })
        }else{
            this.setState({
                morningCount:0,
                afternoonCount:0,
                eveningCount:0,
            })
        }
    }

    toggleBooking(value, service, selection) {
        //console.log('value', value, service, selection);
        let self = this;
        let totalAmount;
        let totalTime;
        let totalDeposit;
        let selectedService = this.state.selectedService;
        if (value === 1) {
            if (selection === true) {
                selectedService.push(service);
                totalAmount = service.servicePrice + this.state.totalAmount;
                totalTime = this.state.totalTime + service.serviceTime;
                totalDeposit = (service.depositPrice&&service.depositPrice!=""?parseFloat(service.depositPrice):0 )+ this.state.totalDeposit;

            } else {
                // selectedService.pop(service);
                _.remove(selectedService, item => item === service);
                totalAmount = this.state.totalAmount - service.servicePrice;
                totalTime = this.state.totalTime - service.serviceTime;
                totalDeposit = this.state.totalDeposit-(service.depositPrice&&service.depositPrice!=""?parseFloat(service.depositPrice):0);
            }
            //console.log('totalAmount',totalAmount);
            this.setState({
                selectedService,
                totalAmount,
                totalTime,
                totalDeposit,
                selectedBookingSlotsIndex: '',
                finalBookingSlots: []
            }, function () {
                self.bookingSlotCreate(0);
                if (self.state.selectedService.length > 0 && self.state.slotInfo.startTime) {
                    self.setState({
                        isBookingButton: false,
                    })
                } else {
                    self.setState({
                        isBookingButton: false,
                    })
                }
            })
        } else {
            self.setState({
                finalBookingSlots: [],
                selectedService: [],
                totalAmount: 0,
                totalTime: 0,
                totalDeposit:0
            })
        }
        if (value === 2) {
            this.props.dispatch({ type: settingConstants.TOGGLE_BOOKING, value: 2 });
        } else if (value === 1) {
            if(selection===true){
                this.props.dispatch({ type: settingConstants.TOGGLE_BOOKING, value: 1 });
            }
        }
    }

    toggleBookingPopup(value) {
        this.props.dispatch({ type: settingConstants.TOGGLE_BOOKING, value: value });
    }

    toggleBookingService(value) {
        this.props.dispatch({ type: settingConstants.TOGGLE_BOOKING_SERVICE, value: value });
    }

    PrevClick(e) {
        //console.log('PrevClick',e);
        let date = new Date(e._d);
        this.setState({
            weekStart:new Date(e._d),
            month: monthNames[date.getMonth()],
            year: date.getFullYear()
        }, function () {
            //console.log('@@@@@@@@@@@@@');
        })
    }

    NextClick(e) {
        //console.log('NextClick',e);
        let date = new Date(e._d)
        this.setState({
            weekStart:new Date(e._d),
            month: monthNames[date.getMonth()],
            year: date.getFullYear()
        }, function () {
            //console.log('$$$$$$$$$$$$$$$$$$');
        })
    }

    toggleBookingConf() {
        console.log("Hi");
        localStorage.removeItem('isBooking');
        localStorage.removeItem('bookingId');
        let self = this;
        self.setState({
            date: new Date(),
            selectedService: [],
            isBookingButton: false,
            selectedDay: new Date(),
            bookingDate: null,
            month: monthNames[new Date().getMonth()],
            year: new Date().getFullYear(),
            totalAmount: 0,
            totalTime: 0,
            totalDeposit:0,
            selectedBookingSlots: '',
            selectedBookingSlotsIndex: '',
            finalBookingSlots: []
        })
        let params = {}
        this.props.dispatch({ type: settingConstants.TOGGLE_CONF_BOOKING });
        let hairdresserId = this.props.match.params.hairdresserId;
        params.hairdresserId = hairdresserId;
        params.latitude = this.props.latitude
        params.longitude = this.props.longitude
        self.props.dispatch(hairdresserActions.getHairdresserInfoWeb(params))
            .then(function (response) {

                if (response.data.errorCode) {
                    self.setState({
                        error: response.data.error.messages
                    })
                } else {
                    self.setState({
                        bookingSlots: response.data.data.bookingSlots,
                        date: new Date(),
                        selectedService: [],
                        isBookingButton: false,
                        selectedDay: new Date(),
                        bookingDate: null,
                        month: monthNames[new Date().getMonth()],
                        year: new Date().getFullYear(),
                        totalAmount: 0,
                        totalTime: 0,
                        totalDeposit:0,
                        selectedBookingSlots: '',
                        selectedBookingSlotsIndex: '',
                        finalBookingSlots: []
                    })
                }
            });
    }

    serviceSelect(service, selection) {
        let self = this;
        let totalAmount;
        let totalTime;
        let totalDeposit;
        let selectedService = this.state.selectedService;
        // console.log('hiiiiiiiiiiii',service);
        //console.log('selectedService', selectedService);
        self.setState({
            isBookingButton: false
        })

        if (selection === true) {
            selectedService.push(service);
            totalAmount = service.servicePrice + this.state.totalAmount;
            totalTime = this.state.totalTime + service.serviceTime;
            totalDeposit = (service.depositPrice&&service.depositPrice!=""?parseFloat(service.depositPrice):0 )+ this.state.totalDeposit;

        } else {
            // selectedService.pop(service);
            _.remove(selectedService, item => item === service);
            totalAmount = this.state.totalAmount - service.servicePrice;
            totalTime = this.state.totalTime - service.serviceTime;
            totalDeposit = this.state.totalDeposit-(service.depositPrice&&service.depositPrice!=""?parseFloat(service.depositPrice):0)
        }
        //console.log('totalAmount',totalAmount);
        this.setState({
            selectedService,
            totalAmount,
            totalTime,
            totalDeposit,
            selectedBookingSlotsIndex: ''
        })
    }

    checkSelectedService(serviceId) {
        let selectedService = this.state.selectedService;
        let isCheck = _.find(selectedService, function (o) {
            return o._id === serviceId;
        })
        //console.log('isCheck',serviceId,isCheck)
        if (isCheck) {
            return true;
        } else {
            return false;
        }
    }

    checkSelectedBookingSlots(index) {

        if (this.state.selectedBookingSlotsIndex === index) {
            return true;
        } else {
            return false;
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        // this.setState({
        //     currencySymbol:this.props.hairdresser.userInfo&&this.props.hairdresser.userInfo.hairdresser.currencySymbol?this.props.hairdresser.userInfo.hairdresser.currencySymbol:"£"
        // })
    }

    componentDidMount(){
        let self=this;
        if(this.props.match.path=="/salon/:salonId/professional/:hairdresserId/success"){
            if(localStorage.getItem('bookingId')&&localStorage.getItem('bookingId')!=="") {
                let getparams = {
                    bookingId: localStorage.getItem('bookingId'),
                    getBookingsOfType: 'upcoming',
                    deviceToken: 'sfsadfsdfds',
                    error: ''
                }
                self.props.dispatch(bookingActions.getBooking(getparams))
                    .then(function (response) {
                        // self.toggleBookingConf();
                    })
                let paymentData={
                    transactionId: localStorage.getItem('transactionId'),
                }
                self.props.dispatch(paymentActions.bookingPaymentDane(paymentData))
                    .then(function (response) {

                    })
                self.setState({
                    paymentType:"Paid Online"
                })

                //localStorage.removeItem('bookingId');
            }
        }
        if(this.props.match.path=="/salon/:salonId/professional/:hairdresserId/fail"){
            self.setState({
                PaymentFailedPopup:true
            })
        }
    }

    createBooking= async() => {
        let self = this;
        let bookingId;
        const stripe = await stripePromise;
        // console.log('createBooking');
        // console.log(localStorage.getItem('accessToken'))
        if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== '') {
            //console.log(this.state.selectedService);
            self.setState({
                isFormLoader: true,
                isBookingButton:false,
            });
            let params = {};
            params.servicesToBook = _.map(this.state.selectedService, function (o) {
                return o.serviceId;
            })
            params.userId = this.props.match.params.hairdresserId;
            params.bookingSlot = [this.state.slotInfo.startTime, (this.state.slotInfo.startTime + this.state.totalTime)]
            params.bookingDate = dateFormat(this.state.bookingDate, 'yyyy-mm-dd')
            params.deviceToken = "dfdsfdsfsd"
            params.isPayAtSalonBooking = 1;
            params.clientId = '';
            params.accessToken = self.props.user.tokenInfo?self.props.user.tokenInfo.accessToken:'';
            self.props.dispatch(bookingActions.createBooking(params))
                .then(function (response) {
                    self.setState({
                        isFormLoader: false,
                        isBookingButton:true
                    });
                    localStorage.removeItem('isBooking');
                    //console.log('booking response', response);
                    if (response.data.errorCode) {
                        self.setState({
                            error: response.data.messages
                        })
                    } else {
                        bookingId=response.data.data.bookingInfo.id;
                        if(self.state.totalDeposit>0){
                            let paymentParam={
                                bookingId: response.data.data.bookingInfo.id
                            }
                            self.setState({
                                isFormLoader: true,
                                isBookingButton:false
                            });
                            self.props.dispatch(paymentActions.bookingPayment(paymentParam))
                                .then(function (response) {
                                    self.setState({
                                        isFormLoader: false,
                                        isBookingButton:true
                                    });
                                    console.log('payment response', response);
                                    if (response.data.errorCode) {
                                        self.setState({
                                            error: response.data.messages
                                        })
                                    } else {
                                        let transactionId=response.data.responseData.result.transactionId;
                                        localStorage.setItem('bookingId',bookingId);
                                        localStorage.setItem('transactionId',transactionId);
                                        let sessionId=response.data.responseData.result.id;
                                        stripe.redirectToCheckout({
                                            sessionId: sessionId,
                                        }).then(function (res){
                                            console.log("stripe res=",res);
                                            self.setState({
                                                paymentType:"Paid Online"
                                            })
                                            let getparams = {
                                                bookingId: bookingId,
                                                getBookingsOfType: 'upcoming',
                                                deviceToken: 'sfsadfsdfds',
                                                error: ''
                                            }
                                            self.props.dispatch(bookingActions.getBooking(getparams))
                                                .then(function (response) {
                                                })
                                            self.toggleBookingConf();
                                        }).catch(function (error){
                                            console.log("stripe error=",error);
                                        })
                                    }
                                })
                        }else{
                            let getparams = {
                                bookingId: bookingId,
                                getBookingsOfType: 'upcoming',
                                deviceToken: 'sfsadfsdfds',
                                error: ''
                            }
                            self.props.dispatch(bookingActions.getBooking(getparams))
                                .then(function (response) {
                                })
                            self.toggleBookingConf();
                        }
                    }
                })
        } else {
            localStorage.setItem('isBooking', 1);
            self.props.dispatch({ type: bookingConstants.IS_BOOKING, value: 1});
            // self.props.dispatch({ type: settingConstants.TOGGLE_LOGIN});
            self.props.dispatch({ type: settingConstants.TOGGLE_SOCIAL_LOGIN});
        }
    }
    onChange = date => this.setState({ date })

    openCreateImageashowPopup() {
        this.setState({
            CreateImageashowPopup: !this.state.CreateImageashowPopup
        })
    }
    openPaymentFailedPopup() {
        this.setState({
            PaymentFailedPopup: !this.state.PaymentFailedPopup
        })
    }

    changeHairdresser(salonId, hairdresserId,hairdresser) {
        this.props.history.push("/salon/" + salonId + "/professional/" + hairdresserId);
        let params = {};
        let self = this;
        params.hairdresserId = hairdresserId;
        params.latitude = this.props.latitude
        params.longitude = this.props.longitude
        self.props.dispatch(hairdresserActions.getHairdresserInfoWeb(params))
            .then(function (response) {
                if (response.data.errorCode) {
                    self.setState({
                        error: response.data.error.messages
                    })
                } else {
                    self.setState({
                        bookingSlots: response.data.data.bookingSlots,
                        date: new Date(),
                        selectedService: [],
                        isBookingButton: false,
                        selectedDay: new Date(),
                        bookingDate: null,
                        month: monthNames[new Date().getMonth()],
                        year: new Date().getFullYear(),
                        totalAmount: 0,
                        totalTime: 0,
                        totalDeposit:0,
                        selectedBookingSlots: '',
                        selectedBookingSlotsIndex: '',
                        finalBookingSlots: [],
                        currencySymbol:hairdresser.hairdresserInfo&&hairdresser.hairdresserInfo.currencySymbol&&hairdresser.hairdresserInfo.currencySymbol!=""&&hairdresser.hairdresserInfo.currencySymbol!="undefined"?hairdresser.hairdresserInfo.currencySymbol:"£"
                    })
                }
            });
        self.props.dispatch(hairdresserActions.getHairdresserReview(params))
            .then(function (response) {

                if (response.data.error) {
                    self.setState({
                        message: response.data.error.message
                    })
                } else {

                }
            });
    }

    selectDay(e) {
        console.log('selectDay', e);
        this.state.bookingDate = e[0];
        this.state.selectedDay = e[0];
        //console.log(this.state.bookingDate)
        //this.setState({bookingDate:e[0]})
        let params = {};
        let self = this;
        let sdate = new Date(e[0])
        self.setState({
            month: monthNames[sdate.getMonth()],
            year: sdate.getFullYear()
        })
        let salonId = this.props.match.params.salonId;
        let hairdresserId = this.props.match.params.hairdresserId;
        params.hairdresserId = hairdresserId;
        params.latitude = this.props.latitude
        params.longitude = this.props.longitude
        params.slotsForBookingDate = dateFormat(e[0], 'yyyy-mm-dd');
        self.props.dispatch(hairdresserActions.getHairdresserInfoWeb(params))
            .then(function (response) {
               
                if (response.data.error) {
                    self.setState({
                        message: response.data.error.message
                    })
                } else {
                   
                    self.setState({
                        bookingSlots: response.data.data.bookingSlots,
                        selectedBookingSlotsIndex: '',
                        finalBookingSlots: [],
                        isBookingButton:false
                    }, function () {
                        self.bookingSlotCreate(0);
                    })
                }
            });
    }

    unselectDay(e) {
        console.log('unselectDay', e);
    }

    getslotTime(time) {
        let hr = parseInt(time / 60);
        let min = time - (hr * 60)
        return hr + ':' + (min === 0 ? '00' : (min<10? '0'+min:min));
    }

    selectSlot(slotInfo, index, isSelect) {
        let self = this;
        this.setState({
            finalBookingSlots: []
        }, function () {
            self.bookingSlotCreate(0);
            //console.log('slotInfo,index',slotInfo,index,isSelect);
            let finalBookingSlots = self.state.finalBookingSlots;
            let totalTime = self.state.totalTime;

            if (isSelect === 1) {
                let start = index + 1;
                let res = _.remove(finalBookingSlots, function (o) {
                    return o.startTime <= slotInfo.startTime + totalTime && o.startTime > slotInfo.startTime
                });
                //finalBookingSlots=res;
                //console.log('finalBookingSlots',finalBookingSlots);
                self.setState({
                    selectedBookingSlotsIndex: index,
                    slotInfo: slotInfo,
                    finalBookingSlots
                }, function () {
                    if (self.state.selectedService.length > 0 && self.state.slotInfo.startTime) {
                        // console.log(self.state.selectedService.length > 0 && self.state.slotInfo.startTime,'ssssssssssss')
                        self.setState({
                            isBookingButton: true,
                        })
                    } else {
                        self.setState({
                            isBookingButton: false,
                        })
                    }
                })
            } else {
                self.setState({
                    selectedBookingSlotsIndex: '',
                    slotInfo: {}
                }, function () {
                    if (self.state.selectedService.length > 0 && self.state.slotInfo.startTime) {
                        self.setState({
                            isBookingButton: true,
                        })
                    } else {
                        self.setState({
                            isBookingButton: false,
                        })
                    }
                })
            }
        })
    }

    selectNextDate(){
        let sdate=[];
        sdate.push(this.state.nextWorking)
        this.selectDay(sdate);
    }

    openCalender = () => this.setState({ isOpenCalender: !this.state.isOpenCalender })

    render() {
        console.log("state ###", this.state);
        console.log('this.props###', this.props);

        let { hairdresser, salon } = this.props;
        let hairdresserId = this.props.match.params.hairdresserId;
        let { isOpenCalender, devHight } = this.state;
        const images = []
        if (hairdresser.userInfo) {
            hairdresser.userInfo.hairdresser.portfolio.map((portfolio, i) => (
                images.push({
                    original: portfolio.imageUrl,
                    thumbnail: portfolio.imageUrl
                })
            ))
        }
        console.log("abababbbb",salon)

        _.uniqBy(salon.salonInfo.salons.rota, function (e) {
            _.uniqBy(salon.salonInfo.salons.rota, '_id');

            userIdHolder.push(e.userId);
        });
        let uniq = [...new Set(userIdHolder)];
        let displayData = [];
        _.forEach(salon.salonInfo.salons.hairdresser, function (value, index) {
            if (uniq.indexOf(value._id) > -1) {
                displayData.push(salon.salonInfo.salons.hairdresser[index]);
            }
        })
        let colorState = false
        let color = colorState ? 'pink' : 'red'
        const classNames = {
            // container : 'red',
            // prevWeekArrow: 'red',
            // nextWeekArrow: 'red',
            // dayBox: 'gray-holiday',
            // dayCircleContainer: 'gray-holiday',
            // dayCicle: 'gray-holiday',
            dayCircleTodayText: 'rwdp-DayCircle rwdp-flex-box rwdp-flex-column rwdp-justify-content-center rwdp-align-items-center pink',
            dayCircleUnavailable: 'rwdp-DayCircle rwdp-flex-box rwdp-flex-column rwdp-justify-content-center rwdp-align-items-center White',
            //dayCircleUnavailableText: 'gray-holiday',
            dayCicleSelected: 'rwdp-DayCircle rwdp-flex-box rwdp-flex-column rwdp-justify-content-center rwdp-align-items-center darkblue',

        };
        // console.log("jhigubuihbugy",dayCircleTodayText)

        var date = new Date().getDate(); //Current Date
        return (
            <React.Fragment>
            
                {salon.salonInfo && hairdresser.userInfo ?
                    <div>
                        <div className={this.props.setting.booking === true ? "blure_bg" : " "}>
                            <div>
                                <h2>{salon.salonInfo.salons.salonName}</h2>
                                <img
                                    src={salon.salonInfo.salons.image ? salon.salonInfo.salons.image : require('../../assets/images/salonpic.png')}
                                    className="img-fluid hairdreser_img" />
                            </div>

                            <div className="our_team">
                                <h3>Our Team</h3>

                                {/*<Tabs>*/}
                                <div className="team_member">
                                    <TabList>
                                        {/*<Tab>*/}
                                        {/*    <div className="round-img">*/}
                                        {/*        <img  src={require('../../assets/images/home-img.png')} className="circle-img"/>*/}
                                        {/*        <p>Any Stylist</p>*/}
                                        {/*    </div>*/}
                                        {/*</Tab>*/}
                                        {salon.salonInfo.salons.hairdresser ? (
                                            <React.Fragment>
                                                {displayData.map((hairdresser, i) => (
                                                    hairdresser.name !== 'Unknown' && hairdresser.email !== 'Unknown' ?
                                                        <Tab key={hairdresser._id}>
                                                            <div
                                                                onClick={this.changeHairdresser.bind(this, salon.salonInfo.salons._id, hairdresser._id,hairdresser)}
                                                                className="round-img">
                                                                <img
                                                                    src={hairdresser.userImage.circleImage ? hairdresser.userImage.circleImage : require('../../assets/images/Pixie.png')}
                                                                    className={hairdresser._id === hairdresserId ? "circle-img active" : "circle-img "} />
                                                                <p>{hairdresser.name}</p></div>
                                                            {/*</a>*/}
                                                        </Tab> : ''
                                                ))}
                                            </React.Fragment>) : ''}
                                    </TabList>
                                </div>
                                {hairdresser.userInfo ?
                                    <div>
                                        <br />
                                        <h5 className="text-center">{hairdresser.userInfo.hairdresser.jobTitle}</h5>
                                        {/*<div className="row member_discription">*/}
                                        {/*    <p>{hairdresser.userInfo.hairdresser.shortBio}</p>*/}

                                        {/*    {hairdresser.userInfo.hairdresser.portfolio ?*/}
                                        {/*        <div className="member_work">*/}
                                        {/*            {hairdresser.userInfo.hairdresser.portfolio.map((portfolio, i) => (*/}
                                        {/*                <div key={portfolio._id}><img*/}
                                        {/*                    onClick={this.openCreateImageashowPopup}*/}

                                        {/*                    src={portfolio.imageUrl ? portfolio.imageUrl : require('../../assets/images/Pixie1.png')}*/}
                                        {/*                    className="" /></div>*/}
                                        {/*            ))}*/}
                                        {/*        </div>*/}
                                        {/*        : ''}*/}
                                        {/*</div>*/}
                                    </div>
                                    : ""}
                                {/*</Tabs>*/}

                            </div>
                            <div className="services-offered" id="table_div">
                                <h3>Services Offered </h3>
                                <br />
                                {hairdresser.hairdresserOnlineServices && hairdresser.hairdresserOnlineServices.length > 0 ?
                                    hairdresser.hairdresserOnlineServices.map((category, i) => (
                                        <div key={category._id}>
                                            <h5>{category.categoryName}</h5>
                                            <div className="table-responsive  cm_table">
                                                <table>
                                                    <tbody>
                                                        {category.hairdresserOnlineServices.map((services, i) => (
                                                            <tr key={services._id} onClick={this.toggleBooking.bind(this, 1, services, !this.checkSelectedService(services._id))}>
                                                                <td>{services.serviceName}</td>
                                                                <td>{services.serviceTime}m</td>
                                                                <td>{this.state.currencySymbol}{services.servicePrice.toFixed(2)}</td>
                                                                {/*<td onClick={this.serviceSelect.bind(this, services, !this.checkSelectedService(services._id))}>*/}
                                                                <td>
                                                                    {this.checkSelectedService(services._id) ? (
                                                                        <i className="fa fa-check-circle"></i>
                                                                    ) : (
                                                                            <Addicon />
                                                                        )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <div className="rating-header nodiv">
                                        <p>No Services Offered </p>
                                    </div>
                                }
                            </div>
                        </div>

                        {/* --------------------------------Booking Popup---------------------------------------- */}
                        <Modal show={this.props.setting.booking} onHide={this.toggleBooking.bind(this, 2)} className="calender_popupbg">
                        {/* <Popup open={this.props.setting.booking} closeOnDocumentClick={false} closeOnEscape={false}
                        //onClose={this.toggleBooking.bind(this,2)}
                        className="calender_popupbg"> */}
                            <div className="cm_calendar cm_modal popupscroll">

                                <div className="modal-header" style={{ borderBottom: 'none' }}>

                                    <button type="button" className="close"
                                        onClick={this.toggleBooking.bind(this, 2)}>&times;</button>
                                </div>
                                <div className="modal-body">
                                    <div className="">
                                    <h5 className="month">{this.state.month} {this.state.year}</h5>
                                    <ReactWeeklyDayPicker
                                        daysCount={7}
                                        classNames={classNames}
                                        //startDay={new Date()}
                                        startDay={this.state.weekStart} // First day as Date Object or 22 June 2016
                                        multipleDaySelect={false}
                                        selectedDays={[this.state.selectedDay]} // Selected days list
                                        selectDay={this.selectDay.bind(this)}
                                        todayText={date}
                                        //unselectDay={this.unselectDay.bind(this)}
                                        //format={'YYYY-MM-DD'} //format of dates that handled in selectDay and unselectDay functions
                                        firstLineFormat={'ddd'} // format for the first line of the day button
                                        secondLineFormat={'D'} // format for the second line of the day button
                                        //firstLineMobileFormat={'dddd'} // format for the first line of the day button mobile
                                        //secondLineMobileFormat={'MMMM D, Y'} // format for the second line
                                        onPrevClick={this.PrevClick.bind(this)} // called with the new startDay
                                        onNextClick={this.NextClick.bind(this)} // called with the new startDay
                                        unavailables={{
                                            //dates:['22 July 2017'],  //unavailable dates list
                                            //relative:[0,1],  //unavailable dates list relative to today (0:today, 1:tomorrow, -1:yesterday)
                                            weekly: this.state.unavailablesDays //unavailable dates list for each week (0:Sunday, 1:Monday ...)
                                        }}
                                    />
                                        {this.state.finalBookingSlots&&this.state.finalBookingSlots.length>0?
                                            (<div className="col-sm-12">
                                        <div className="row timeslot">
                                            <div className="col-12 timeslot-date">
                                                {this.state.morningCount ? (<h3>Morning</h3>) : ''}
                                                <ul>
                                                    {this.state.finalBookingSlots ? this.state.finalBookingSlots.map((bookingSlot, i) => (
                                                        bookingSlot ? bookingSlot.status === 0 ? bookingSlot.startTime < 720 ?
                                                            <React.Fragment key={i}>

                                                                <li className={this.checkSelectedBookingSlots(i) ? 'cal-active' : ''} onClick={this.selectSlot.bind(this, bookingSlot, i, this.checkSelectedBookingSlots(i) ? 2 : 1)}>

                                                                {this.getslotTime(bookingSlot.startTime)}
                                                                </li>
                                                                {/*{this.checkSelectedBookingSlots(i) ? <li className={this.checkSelectedBookingSlots(i) ? 'cal-active' : ''} onClick={this.selectSlot.bind(this, bookingSlot, i, this.checkSelectedBookingSlots(i) ? 2 : 1)}>{this.getslotTime(bookingSlot.startTime + this.state.totalTime)}</li> : ''}*/}
                                                            </React.Fragment> : '' : '' : ''
                                                    )) : ''}
                                                </ul>
                                                {this.state.afternoonCount ? (<h3>Afternoon</h3>) : ''}
                                                <ul>
                                                    {this.state.finalBookingSlots ? this.state.finalBookingSlots.map((bookingSlot, i) => (
                                                        bookingSlot ? bookingSlot.status === 0 ? (bookingSlot.startTime >= 720 && bookingSlot.startTime < 1020) ?
                                                            <React.Fragment key={i}>
                                                                <li className={this.checkSelectedBookingSlots(i) ? 'cal-active' : ''} onClick={this.selectSlot.bind(this, bookingSlot, i, this.checkSelectedBookingSlots(i) ? 2 : 1)}>{this.getslotTime(bookingSlot.startTime)}</li>
                                                                {/*{this.checkSelectedBookingSlots(i) ? <li className={this.checkSelectedBookingSlots(i) ? 'cal-active' : ''} onClick={this.selectSlot.bind(this, bookingSlot, i, this.checkSelectedBookingSlots(i) ? 2 : 1)}>{this.getslotTime(bookingSlot.startTime + this.state.totalTime)}</li> : ''}*/}
                                                            </React.Fragment> : '' : '' : ''
                                                    )) : ''}
                                                </ul>
                                                {this.state.eveningCount ? (<h3>Evening</h3>) : ''}
                                                <ul>
                                                    {this.state.finalBookingSlots ? this.state.finalBookingSlots.map((bookingSlot, i) => (
                                                        bookingSlot ? bookingSlot.status === 0 ? bookingSlot.startTime >= 1020 ?
                                                            <React.Fragment key={i}>
                                                                <li className={this.checkSelectedBookingSlots(i) ? 'cal-active' : ''} onClick={this.selectSlot.bind(this, bookingSlot, i, this.checkSelectedBookingSlots(i) ? 2 : 1)}>{this.getslotTime(bookingSlot.startTime)}</li>
                                                                {/*{this.checkSelectedBookingSlots(i) ? <li className={this.checkSelectedBookingSlots(i) ? 'cal-active' : ''} onClick={this.selectSlot.bind(this, bookingSlot, i, this.checkSelectedBookingSlots(i) ? 2 : 1)}>{this.getslotTime(bookingSlot.startTime + this.state.totalTime)}</li> : ''}*/}
                                                            </React.Fragment> : '' : '' : ''
                                                    )) : ''}
                                                </ul>
                                            </div>

                                        </div>
                                    </div>)
                                            :
                                            (<div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-12 ">
                                                    {this.props.hairdresser&&this.props.hairdresser.userInfo?this.props.hairdresser.userInfo.name:""} is all booked up for this day, but you can book for {moment(this.state.nextWorking).format("DD-MM-YYYY")}
                                                </div>

                                            </div>
                                            <div className="text-center">
                                                <button type="button" className="btn booknow-btn col-md-6" onClick={this.selectNextDate.bind(this)}>View {moment(this.state.nextWorking).format("DD-MM-YYYY")} </button>
                                            </div>
                                        </div>)}
                                        {this.state.finalBookingSlots&&this.state.finalBookingSlots.length>0?
                                            (<>
                                                <table className="confirm-book">
                                        <tbody>
                                            {this.state.selectedService.map((services, i) => (
                                                <tr key={"popup_" + services._id}>
                                                    <td>{services.serviceName}</td>
                                                    <td>{services.serviceTime}m</td>
                                                    <td>{this.state.currencySymbol}{services.servicePrice.toFixed(2)}</td>
                                                    <td><i className="fa fa-close" onClick={this.serviceSelect.bind(this, services, !this.checkSelectedService(services._id))}></i></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <br />
                                    <h6 className="text-center  pad-14 cursorP" onClick={this.toggleBookingService.bind(this, 1)}><span >Add another service</span>&nbsp;&nbsp;<span className="addservices"><Addicon /> </span></h6>
                                    <div className="col-sm-12 text-center">
                                        <span className="cm_error_msg">{this.state.error}</span>
                                    </div>
                                    <div className="text-center">
                                        <button type="button" className="btn booknow-btn col-md-6" disabled={this.state.isBookingButton ? false : true} onClick={this.createBooking.bind(this)}>
                                            &nbsp;{this.state.isFormLoader?<MySpinner/> :""}&nbsp;
                                            {this.state.totalDeposit>0?'Pay Deposit, '+this.state.currencySymbol+this.state.totalDeposit.toFixed(2):
                                                "Book Now "
                                                // +this.state.currencySymbol+this.state.totalAmount.toFixed(2)
                                            }
                                        </button>
                                    </div>
                                    <br/></>)
                                    :""}
                                    </div>
                                </div>

                            </div>
                        {/* </Popup> */}
                        </Modal>


                        {/* --------------------------------Booking confirm Popup---------------------------------------- */}
                        <Modal show={this.props.setting.bookingConf}   blurClass="blure_bg" >
                            {this.props.booking.bookings?
                                <ConfirmBookingPopup bookingState={this.state} toggleBookingConf={this.toggleBookingConf}/>
                                :""}
                        </Modal>


                        <Modal show={this.props.setting.bookingService} onHide={this.toggleBooking.bind(this, 2)}>
                            <div className="cm_modal popupscroll">
                                <div className="modal-header " style={{ borderBottom: 'none' }}>
                                    <span className="modealPrev" onClick={this.toggleBookingPopup.bind(this, 1)}><i className="fa fa-angle-left" aria-hidden="true"></i></span>
                                    {/*<h5 className="serviceheader">Select Service</h5>*/}
                                    <button type="button" onClick={this.toggleBooking.bind(this, 2)} className="close">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <br />
                                    <div className="services-offered" >
                                        {hairdresser.hairdresserOnlineServices && hairdresser.hairdresserOnlineServices.length > 0 ?
                                            hairdresser.hairdresserOnlineServices.map((category, i) => (
                                                <div key={"popup_" + category._id}>
                                                    <h5>{category.categoryName}</h5>
                                                    <div className="table-responsive  cm_table">
                                                        <table>
                                                            <tbody>
                                                                {category.hairdresserOnlineServices.map((services, i) => (
                                                                    <tr key={services._id} onClick={this.toggleBooking.bind(this, 1, services, !this.checkSelectedService(services._id))}>
                                                                        <td><span className="cm_three_dots">{services.serviceName}</span></td>
                                                                        <td>{services.serviceTime}m</td>
                                                                        <td>{this.state.currencySymbol}{services.servicePrice.toFixed(2)}</td>
                                                                        <td >
                                                                            {this.checkSelectedService(services._id) ? (
                                                                                <i className="fa fa-check-circle"></i>
                                                                            ) : (
                                                                                    <Addicon />
                                                                                )}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <div className="rating-header nodiv">
                                                <p>No Services Offered </p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        {/* <!------------------------image gallary popup-----------------------------> */}
                        <Popup
                            open={this.state.CreateImageashowPopup}
                            closeOnDocumentClick={true} closeOnEscape={true}
                            onClose={this.closeModal} className="image_modal">
                            <div className="cm_modal popupscroll">
                                <div className="modal-header" style={{ borderBottom: 'none' }}>

                                    <button type="button" className="close float-left"
                                        onClick={this.closeModal}>&times;</button>
                                </div>
                                {/* <span className="cm_modal_close" >&times;</span> */}
                                <div className="modal-body loginPopup">
                                    <ImageGallery items={images} className="aana"/>
                                </div>
                            </div>
                        </Popup>
                    </div>
                    : ''}

                    {/* <---payment failed modal--> */}
                    <Modal   blurClass="blure_bg"
                    contentClassName="p-0 payment-failed"
                    show={this.state.PaymentFailedPopup}>
                       <div className="cm_modal booking_bg popupscroll ">
                                <div className="modal-header closesvg" style={{ borderBottom: 'none' }}>
                                    <p className="text-right w-100"><Close  onClick={this.closeModal}/></p>

                                </div>
                            <div className="p-5 text-center">
                            <img src={require('../../assets/images/paymentfailed.png')} className="img-fluid mb-3" />
                            <h3 style={{fontSize: '38px', fontWeight: '200'}} className="mb-4">Uh-Oh</h3>
                            <p style={{fontWeight: '200'}}>Something Happened, but Don't worry you haven't been charged. Please try again</p>

                            <div className="stap-action mt-5">
                                <button className="btn btn-info btn-block" onClick={()=>this.openPaymentFailedPopup()}>Continue</button>
                            </div>
                            </div>

                            </div>
                        </Modal>
                {/* <---payment failed modal--> */}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const {
        hairdresser, salon, hairdresserReview, user,
        setting,
        booking,
        createBooking
    } = state;
    return {
        hairdresser,
        salon,
        hairdresserReview,
        user,
        setting,
        booking,
        createBooking
    };
}

export default connect(mapStateToProps)(HairdresserLeft);
