import React, { useState,useEffect } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { GrClose } from "react-icons/gr";
import { Link, Redirect,useLocation } from "react-router-dom";
import Select from 'react-select'

const BookingService = (props) => {
  const [item,setItem]=useState({});
  const [staffError,setStaffError]=useState('');
  const [selectedStaff,setSelectedStaff]=useState('');
  const [servicePrice,setServicePrice]=useState(0);
  const [updatePrice,setUpdatePrice]=useState(0);
  const [priceError,setPriceError]=useState('');

const handleClickService = (indexId) => {
    if(props?.selectedStaff?._id!==undefined){
      setStaffError('');
      const hairdresserId = props?.selectedStaff?._id;
      let currentPageUrl = props.propData.history.location.pathname;
      const stateObj = { pageLoadingStatus: 0, previousPageUrl:currentPageUrl};
      const url =  "/new-selectService/" + hairdresserId + "/" + indexId;
       props.propData.history.push({
        pathname: url,
        state: stateObj,
      });
    }else{
      setStaffError('Staff Member is required.');
    }
  };
 

  useEffect(()=>{
   setItem(props?.item);
   setSelectedStaff(props?.selectedStaff);
   setServicePrice(props?.item.servicePrice);
  },[props])

  const handleClient = (hairdresserId,indexId) => {
    if(hairdresserId && hairdresserId!==''&& indexId && indexId!==''){
      setStaffError('');
      let currentPageUrl = props.propData.history.location.pathname;
      const stateObj = { pageLoadingStatus: 0, previousPageUrl:currentPageUrl };
      const url =   `/select-client/${hairdresserId}/${indexId}`
       props.propData.history.push({
        pathname: url,
        state: stateObj,
      });
    }else{
      setStaffError('Staff Member is required.');
    }
  };

  useEffect(()=>{
    var timerValue;
    let err = '';
    let testValidPrice = /^\d{0,8}(\.\d{1,2})?$/.test(updatePrice);
    setServicePrice(updatePrice);
    if(updatePrice!==0){
      if(!isNaN(updatePrice) && updatePrice>=0.00 && testValidPrice){
        setPriceError('')
        err='';
      }else{
        setPriceError('Enter valid price.');
        err='Enter valid price.';
      }
    timerValue= setTimeout(()=>{
      props.changeItemPrice(item,'service',updatePrice,err);
    },2000);
    }
    return ()=>{
      clearTimeout(timerValue);
    }
   },[updatePrice]);

  return (
    <div className="row">

      <div className="col-md-11">
      <div className="service_div bg-white rounded">
        <div className="row vertical-top">
          <div className="col-md-6 text-left mt-1 mb-3">
            <span className="service_1">Service {props?.index}</span>
            {props?.bookings?.[0]?.stripePaymentMethodId!==undefined && props?.bookings?.[0]?.stripePaymentMethodId!=='' && (props?.index-1)==0?
                <span className="service_1 card_captured ml-3">Card captured</span>:''}
          </div>
          <div className="col-md-6 text-right mb-3">
          {(props?.isPaymentComplete==0)?<GrClose
              className="gr_close"
              onClick={()=>props.removeService('service',item?.indexId)}
            />:null}
          </div>
        </div>
        {(props?.index===1)?<div className="row">
          <div className="col-md-6 pr-2 ">
            <div className={'input_group_u'}>
           <div className="service_selected1 _disabled_input">{props.bookings && moment(props.bookingDate).format("dddd DD MMM YYYY")}</div>
            </div>
            
          </div>
          <div className="col-md-6 pl-2">
          <div className="row">
            <div className="col-4 pr-0">
            <div className="time_box_dis">
               <h6 className="start_time">Start Time</h6>
               <h6 className="mb-0">
            {/* {moment(props?.selectSlot).format('hh:mm')} */}
            {props?.selectedTime?.substring(0, 5)}
                {props.startTimeError ? (
                  <span className="cm_error_msg">{props.startTimeError}</span>
                ) : (
                  ""
                )}</h6>
               {/* style="font-weight: 400; font-size: 17px;" */}
            </div>
            </div>

              <div className="col-4 pr-0">
            <div className="time_box_dis">
               <h6 className="start_time">End Time</h6>
               <h6 className="mb-0">{props.calculateEndTime()}</h6>
               {/* style="font-weight: 400; font-size: 17px;" */}
            </div>
            </div>

              <div className="col-4 ">
            <div className="time_box_dis">
               <h6 className="start_time">Duration</h6>
               <h6 className="mb-0">{props.totalTime + " m"}</h6>
               {/* style="font-weight: 400; font-size: 17px;" */}
            </div>
            </div>
          </div>
          </div>
        </div>:null}
        <div className="row">
          <div className="col-md-6 pr-2 ">
            <div className={'input_group_u'}>
            <span className="_field_label_vk">Staff Member</span>
            <div className="service_selected1 _disabled_input">{selectedStaff?.name
!==undefined?selectedStaff?.name:'Select staff'}</div>
            {staffError!==''?<span className={'text-danger'}>{staffError}</span>:null}
          </div>
          </div>
          <div className="col-md-6 pl-2">
          <div className={'input_group_u'}>
            <span className="_field_label_vk">Client</span>
             <div className="service_selected1 _disabled_input">{props?.clientDetail?.name}</div>
          </div>
        </div>
        </div>
        <div className="row">
          <div className="col-md-3 ">
          <div className={'input_group_u'}>
          <span className="_field_label_vk">Price</span>
            <div className={`currency_div mt-0 ml-0 ${item?.serviceName?'currency_div_edit':''}`}>
              <div className="text-center px-3 currency_sml">£</div>
              {(item?.serviceName&&props?.isPaymentComplete!==1)?<input type="text" className="text-center pl-3 currency_sml1 currency_sml_input edit_price_input" placeholder={'00.00'}  onChange={(e)=>setUpdatePrice(e.target.value)} value={servicePrice!==undefined?Number.isInteger(servicePrice)?servicePrice+'.00':servicePrice:`0.00`} />:<input type="text" className="text-center pl-3 currency_sml1 currency_sml_input edit_price_input" value={servicePrice!==undefined?Number.isInteger(servicePrice)?servicePrice+'.00':servicePrice:`0.00`} disabled={true}/>
              }
          
            </div>
             {priceError!=''?<span className="text-danger">{priceError}</span>:null}
            </div>
          </div>
          <div className="col-md-9">
          <div className={`input_group_u __service ${props?.isPaymentComplete===1?'hide__arrow':''}`}>
          <span className="_field_label_vk">Service</span>
          {(props?.isPaymentComplete==0)?<div
              className="mb-4 bg-white service_selected1 border-1"
              onClick={(e)=>handleClickService(item?.indexId)}
            >
              {item?.serviceName!==undefined?item?.serviceName:'Select service...'}
            </div>:<div
              className="mb-4 bg-white service_selected1 border-1 disabled_div"
            >
              {item?.serviceName!==undefined?item?.serviceName:'Select service...'}
            </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default BookingService;