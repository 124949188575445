import React from 'react';
import {connect} from "react-redux";
import moment from 'moment';

const NewInvoice = (props) => {
    const getslotTime=(time)=> {
        let hr = parseInt(time / 60);
        let min = time - (hr * 60)
        return hr + ':' + (min === 0 ? '00' : min);
    }
    const checkEmail=(email)=>{
        if(email) {
            let emailArr = email.split('@');
            if (emailArr[1] === 'beuapp.com') {
                return false;
            } else {
                return true;
            }
        }else{
            return false;
        }
    }
    return(
    <>
    <div className="invoice_card_wrapper new_invoice">
        <div className="cm_head">
            <div className="user-dtls">
                <div className="text">
                    <h3>{props.bookingState.selectedClient.name}</h3>
                    {checkEmail(props.bookingState.selectedClient.email)?<p>{props.bookingState.selectedClient.email}</p>:""}
                    <p>{props.bookingState.selectedClient.phoneNumber}</p>
                </div>
                <img src={props.bookingState.selectedClient.localAvatar&&props.bookingState.selectedClient.localAvatar!=""?props.bookingState.selectedClient.localAvatar:require('../../assets/images/client-plachoder.jpg')} className=""/>
            </div>
        </div>
        <div className="cm_body">
            <div className="bobking_detail">
                <h5>{moment(props.bookingState.bookingDate).format("dddd, MMM Do YY")}</h5>
                <>
                <div className="cm_b_details">
                  <p>Staff member</p><h5>{props.bookingState.hairdresserName}</h5>
                </div>
                    {props.bookingState.selectedService.map((service, index) => (
                <div className="cm_b_details" key={"s-"+index} >
                  <p>Service</p>

                        <h5 className="d-flex justify-content-between"><span>{service.serviceName} </span><span> {props.bookingState.currencySymbol}{service.servicePrice.toFixed(2)}</span></h5>

                </div>
                    ))}
                <div className="three_box">
                    <div className="cm_b_details">
                    <p>Start Time</p><h5>{getslotTime(props.bookingState.slotInfo.startTime)}</h5>
                    </div>
                    <div className="cm_b_details">
                    <p>Duration</p><h5>{props.bookingState.totalTime}</h5>
                    </div>
                    {/*<div className="cm_b_details">*/}
                    {/*<p>Cost</p><h5>$99</h5>*/}
                    {/*</div> */}
                </div>
                </>
                <h5 className="text-center m-4">Invoice  - {localStorage.getItem("invoiceNumber")}</h5>

            </div>
            <div className="invoice-bill-parent">
                   <div className="invoice-bill-detls"><p>Subtotal</p><p>{props.bookingState.currencySymbol}{props.bookingState.totalAmount.toFixed(2)}</p></div>
                   <div className="invoice-bill-detls totls"><p>Total</p><p>{props.bookingState.currencySymbol}{props.bookingState.totalAmount.toFixed(2)}</p></div>
                   {props.bookingState.transaction.map((transaction, index) => (
                       <div key={"sp-"+index} className="invoice-bill-detls"><p>{transaction.paymentType === 1 ? 'Pay by App' : ''}{transaction.paymentType === 2 ? 'Card' : ''}{transaction.paymentType === 3 ? 'Cash' : ''}{transaction.paymentType === 4 ? 'Other' : ''} </p><p> {props.bookingState.currencySymbol}{transaction.amount.toFixed(2)}</p></div>
                    ))}
                   <div className="invoice-bill-detls totls"><p>Balance</p><p>{props.bookingState.currencySymbol}{props.bookingState.pendingAmount.toFixed(2)}</p></div>
                   {/*<div className="invoice-bill-detls"><p>Paid Card</p><p>£40</p></div>*/}
            </div>

        </div>

    </div>

    </>)
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(NewInvoice);
