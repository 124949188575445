import axios from 'axios';
import API from "../config/API";

export const marketingService = {
    createMarketingSetting,
    getMarketingSetting,
    getSettings,
    updateSetting,
    addReminder,
    removeReminder,
    updateMarketingSetting,
};

  function getSettings() {
    let accessToken = localStorage.getItem('accessToken');
    let headers = {
      'Content-Type': 'application/json',
      'authorization': "Basic YmV1YXBwOmJldWFwcA==",
      'accessToken': `${accessToken}`
    };

      return axios.get(API.GET_MARKETING_SETTING, {
        headers: headers });
  
  }
  

function createMarketingSetting(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    // console.log("headers",headers, params);
    return axios.post(API.CREATE_MARKETING_SETTING, params,{headers:headers})
}

function updateSetting(params) {
    let accessToken = localStorage.getItem('accessToken');
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    // console.log("headers",headers, params);
    return axios.put(API.UPDATE_SETTING, params,{headers:headers})
}

function removeReminder(params) {
  let accessToken = localStorage.getItem('accessToken');
  let  headers= {
      'Content-Type' : 'application/json',
      'authorization': "Basic YmV1YXBwOmJldWFwcA==",
      'accessToken': `${accessToken}`
  };
  // console.log("headers",headers, params);
  return axios.put(API.REMOVE_REMINDER, params,{headers:headers})
}

function addReminder(params) {
  let accessToken = localStorage.getItem('accessToken');
  let  headers= {
      'Content-Type' : 'application/json',
      'authorization': "Basic YmV1YXBwOmJldWFwcA==",
      'accessToken': `${accessToken}`
  };
  // console.log("headers",headers, params);
  return axios.put(API.CREATE_REMINDER, params,{headers:headers})
}


function getMarketingSetting() {
  let accessToken = localStorage.getItem('accessToken');
  let headers = {
    'Content-Type': 'application/json',
    'authorization': "Basic YmV1YXBwOmJldWFwcA==",
    'accessToken': `${accessToken}`
  };
    return axios.get(API.GET_MARKETING_SALON_SETTING, {
      headers: headers,
    });
}

function updateMarketingSetting(params) {
  let accessToken = localStorage.getItem('accessToken');
  let  headers= {
      'Content-Type' : 'application/json',
      'authorization': "Basic YmV1YXBwOmJldWFwcA==",
      'accessToken': `${accessToken}`
  };
  console.log("headers", params);
  return axios.put(API.UPDATE_MARKETING_SETTING, params,{headers:headers})
}
