import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Draggable from 'react-draggable';
import Summary from '../Summery/Summary';
import { connect } from "react-redux";
import { hairdresserActions } from '../../../_actions';
import siteSetting from '../../../config/env';
import ServiceBox from './ServiceBox';
import { ServiceLoader } from '../SkletonLoader';
import SmoothCollapse from 'react-smooth-collapse';
import { BackArrow } from '../../SvgIcon';
// import { DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

class Service extends Component {
  constructor(props) {
    super(props);
    this.state={
      showMobileRightPannel:false,
      currencySymbol:this?.props?.hairdresser?.userInfo&&this?.props?.hairdresser?.userInfo?.hairdresser?.currencySymbol&&this?.props?.hairdresser?.userInfo?.hairdresser?.currencySymbol!=="undefined"&&this?.props?.hairdresser?.userInfo?.hairdresser?.currencySymbol!==null?this?.props?.hairdresser?.userInfo?.hairdresser?.currencySymbol:"£",
      dragableDisabled:false     ,
      latitude: siteSetting.default_latitude,
      longitude: siteSetting.default_longitude,
      hairdresserDetails:{},
      activeServiceCategory: this?.state?.hairdDraggableDraggableDraggableDraggableDraggableresserDetails?.hairdresserOnlineServices[0]?.categoryName,
      selectedServices:[],
      totalPrice:0,
      totalTime:0,
      loading:false,
      expanded: false,
      showSimilarSummaryBtn:false
    }
   
    this.calculateTotalPriceAndTime =this.calculateTotalPriceAndTime.bind(this);
    this.selectService = this.selectService.bind(this);
    this.getHairdressersDetail = this?.getHairdressersDetail.bind(this);
    this.getServiceStatus = this.getServiceStatus.bind(this);
    // this.scrollToTop = this.scrollToTop.bind(this);
    this.scrollDivRef = React.createRef(); // Create ref for the scroll div
   
    
  }

componentDidMount() {
    let self = this; 
    // debugger;
    document.body.classList.add('no-topbar');
    console.log('checkDataUrl',self?.props?.location?.state);
    let addedServices = self?.props?.userBookingState?.selectedServices;
    if(addedServices?.length>0){
      const { totalPrice, totalTime } = this.calculateTotalPriceAndTime(addedServices);
      self.setState({
        selectedServices:addedServices,
        totalPrice:totalPrice.toFixed(2),
        totalTime:totalTime,
      }) 
    }
    this.getHairdressersDetail()

    // ---for scroll start---

  //  let _scrollWrapper = document.querySelector('._service_parent_body');
  //  console.log("_scrollWrapper",_scrollWrapper)
  //  _scrollWrapper.addEventListener('scroll',(e)=>{
  //   setTimeout(()=>{alert("hiradha")},1000)
  //  })

   // ---for scroll end---
   
}

componentWillUnmount() {
  let self = this; 

}

handleScroll = () => {
  let self = this; 
  // debugger;
  const scrollDiv = self.scrollDivRef.current;
  // alert('Page is scrolling');
  if(self.state.expanded==true){
    if (scrollDiv.scrollTop > 100) {
      console.log('let see when Page is scrolling');
      self.setState({expanded: false});
    }
  }
  
};
handleItemClick = (category,categoryId) => {
  this.setState({ activeServiceCategory: category,dragableDisabled:true }); 
};

selectService(service,status){
  let self = this;
  let allSelectedServices=[];
  // alert(this.state.expanded)
 if(status==='add'){
  allSelectedServices = [...self.state.selectedServices,service];
  
 }else{
  allSelectedServices= self.state.selectedServices.filter(serviceData=>serviceData?._id!==service?._id);
 }
 
 console.log('checkSelected',allSelectedServices);

 const { totalPrice, totalTime } = this.calculateTotalPriceAndTime(allSelectedServices);
  self.setState({
    totalPrice:totalPrice.toFixed(2),
    totalTime:totalTime,
    selectedServices:allSelectedServices,
  },()=>{
    if(status==='add'){
      setTimeout(this.setState({expanded: true}), 1000);
    }
    else if (self.state.selectedServices.length==0){
      setTimeout(this.setState({expanded: false}), 1000);
    }
  })
}


getHairdressers(){
  let params = {};
  let self = this;
  params.salonId = this.props.match.params.salonId
  params.latitude =  siteSetting.default_latitude;
  params.longitude =  siteSetting.default_longitude;
  self.props.dispatch(hairdresserActions.getSalonProfile(params))
      .then(function (response) {
        console.log('check the data nowsdsdsdsd',response)
      })
}




getHairdressersDetail(){
  let params = {};
  let self = this;
  let hairdresserId = this.props.match.params.hairdresserId;
        params.hairdresserId = hairdresserId;
        params.latitude =  siteSetting.default_latitude;
       params.longitude =  siteSetting.default_longitude;
       self.setState({loading:true})
        self.props.dispatch(hairdresserActions.getHairdresserInfoWeb(params))
            .then(function (response) {
                if (response.data.errorCode) {
                    self.setState({
                        error: response.data.error.messages
                    })
                } else {
                    self.setState({
                      hairdresserDetails: response.data.data,
                      activeServiceCategory: response.data.data.hairdresserOnlineServices[0]?.categoryName,
                    })
                }
                self.setState({loading:false})
            });
}

calculateTotalPriceAndTime(services) {
  let totalPrice = 0;
  let totalTime = 0;
  for (const service of services) {
    if (service.servicePrice) {
      totalPrice += service.servicePrice;
    }

    if (service.serviceTime) {
      totalTime += service.serviceTime;
    }
  }
  return { totalPrice, totalTime };
}
toggleRightPannel =()=>{
  this.setState({showMobileRightPannel:!this.state.showMobileRightPannel})
  
}
_toggle() {
  let self = this;
  console.log("expanded value before set",self.state.expanded)
  self.setState({expanded: !self.state.expanded},()=>{
    // console.log("inside expanded value after set", this.state.expanded)
  });
  console.log("outside expanded value after set",self.state.expanded)
}



getServiceStatus(serviceData){
  let self= this;
 let checkSelectedStatus =false;
 if(self.state.selectedServices.length>0){
 let service= self.state.selectedServices.filter(item=>item._id===serviceData._id);
 if(service.length>0){
  checkSelectedStatus = true;
 }
 }
return checkSelectedStatus;
}




render() {
    let { hairdresser, salon } = this.props;
    let salonId = this.props.match.params.salonId
    let{dragableDisabled,showSimilarSummaryBtn,loading,showMobileRightPannel,expanded} = this.state
  return (
    <div className='home_main'>
        <div className='__left _left_service p-4' >
            <ul className="__left_breadcrumb mb-5 service_breadcrum">
              <li className="arrow_li" onClick={()=>{
                this.props.history.push({
                  pathname:`/salon/${this.props.match.params.salonId}/staff`,
                  state:{...this.props.history.location?.state}
              });
              }}>
                 <BackArrow/>
              {/* <img alt="img" src={require('../../../assets/images/down-arrow-icon1.png')} className="img-fluid mb-3 client-booking-back-arrow" /> */}
              </li>
              <li><Link to={{pathname:`/salon/${salonId}/staff`,
              state: { 
                salonId:salonId , 
                ...this.props.history.location?.state,
              },
              }}>Staff</Link></li>
              <li>Services</li>
            </ul>
            {loading ? 
            <ServiceLoader/>
            :
            <>
            <ul className='services_tab pl-0'>
              {hairdresser?.hairdresserOnlineServices?.map((category, i) =>
              (
                <li key={'category_tab_'+i}
                   className={this.state.activeServiceCategory === category.categoryName ? 'active' : ''}>
                  {/* <p  onClick={() => this.handleItemClick(category.categoryName ,category._id)} >{category.categoryName}</p> */}
               
                <a href={`#${category._id}`} onClick={() => this.handleItemClick(category.categoryName ,category._id)} >{category.categoryName}</a>
                </li>
              ))}
            </ul>
            <div className={`_service_parent_body ${dragableDisabled ? 'add_bottom_pading' : ''}`} id="_service_parent_body"
            ref={this.scrollDivRef}
            onScroll={this.handleScroll}
            >
              {
                  hairdresser?.hairdresserOnlineServices?.map((category, i) => 
                  (
                    <div id={category._id} className='_service_parent' key={'category_item'+i}>
                    <h3>{category.categoryName}</h3>
                    <div className='_service_body'>
                    {category?.hairdresserOnlineServices?.map((services, i) => (
                       <ServiceBox service={services} key={'new_data'+i} 
                       currencySymbol ={this.state.currencySymbol}
                       serviceStatus={this.getServiceStatus(services)}
                       selectService={this.selectService}
                      //  onClick={() => this._toggle()} 
                       />
                     ))}
                    </div>
                  </div>
                  )
                  )
                  }
            </div>
            </>
          }
        
        </div>
        
        {Object.keys(this.state.hairdresserDetails).length>0?<>
          {/* <div className={`__right  align-items-start _service __mobile  hide-desktop ${dragableDisabled==true?'dragble_disabled':''}`}> */}
        <>
        {/* {console.log("this.state.selectedServices.length",this.state.selectedServices.length)} */}
        {this.state.selectedServices.length>0 ?
        <>
        <div className='phone_right_panel_parent hide-desktop'>
          <div className={`invisible_button ${expanded ? 'Hide _full_height_transparent' : 'Show'}`} 
          onClick={() => this._toggle()} ></div>
          {/* <p className='mb-0'> </p> */}
          <img src={require('../../../assets/images/down-arrow-icon1.png')} className={`icon_arrow ${expanded?'':'closed'}`}/>
            

          <SmoothCollapse 
          expanded={expanded} 
          // className={`smooth_scroll_body ${expanded ? 'hi':'height_70'}`}
          heightTransition='.8s ease-in-out'
          collapsedHeight = {this.state.selectedServices.length>0?'58px':'0px'}
          closing = {false}
          eagerRender = {false}
          >
          
                <Summary 
                selectedServices={this.state.selectedServices}
                currencySymbol ={this.state.currencySymbol}
                totalPrice={this.state.totalPrice}
                totalTime={this.state.totalTime}
                params={this.props.match.params}
                hairdresserData={this.state.hairdresserDetails}
                page="service"
                {...this.props}
                />
          </SmoothCollapse>
        </div>
     
        </>

         :
         ''
        }
        </>
       
        <div className='__right  align-items-start _service __mobile  hide-mobile'>
          <Summary 
          selectedServices={this.state.selectedServices}
          currencySymbol ={this.state.currencySymbol}
          totalPrice={this.state.totalPrice}
          totalTime={this.state.totalTime}
          params={this.props.match.params}
          hairdresserData={this.state.hairdresserDetails}
          page="service"
          {...this.props}
          />
        </div>
        
        </>:null}


        {/* <div className='hide-desktop __only_for_mobile'>
           <ul className="__left_breadcrumb mb-5 service_breadcrum">
              <li><Link to={`/salon/${salonId}/staff`}>Staff</Link></li>
              <li>Services</li>
            </ul>
            {loading ? 
            <ServiceLoader/>
            :
            <div className='service_top'>
              <ul className='services_tab pl-0'>
                {hairdresser?.hairdresserOnlineServices?.map((category, i) =>
                (
                  <li key={'category_tab_'+i}
                    className={this.state.activeServiceCategory === category.categoryName ? 'active' : ''}>
                  <a href={`#${category._id}`} onClick={() => this.handleItemClick(category.categoryName ,category._id)} >{category.categoryName}</a>
                  </li>
                ))}
              </ul>
              <div className={`_service_parent_body ${dragableDisabled ? 'add_bottom_pading' : ''}`} id="_service_parent_body">
                {
                    hairdresser?.hairdresserOnlineServices?.map((category, i) => 
                    (
                      <div id={category._id} className='_service_parent' key={'category_item'+i}>
                      <h3>{category.categoryName}</h3>
                      <div className='_service_body'>
                      {category?.hairdresserOnlineServices?.map((services, i) => (
                        <ServiceBox service={services} key={'new_data'+i} 
                        currencySymbol ={this.state.currencySymbol}
                        selectService={this.selectService}
                        />
                      ))}
                      </div>
                    </div>
                    )
                    )
                    }
              </div>
              <div className={`bottom_box ${showMobileRightPannel ? 'show_full_drag_panel':''}`} onClick={this.state.selectedServices.length>0?this.toggleRightPannel :''}>
                   <Summary 
                     selectedServices={this.state.selectedServices}
                     currencySymbol ={this.state.currencySymbol}
                     totalPrice={this.state.totalPrice}
                     totalTime={this.state.totalTime}
                     params={this.props.match.params}
                     hairdresserData={this.state.hairdresserDetails}
                     page="service"
                     {...this.props}
                     />
              </div>
            </div>

            }

        </div> */}
    </div>
  );
};
}


function mapStateToProps(state) {
  const {
      hairdresser, salon, hairdresserReview, user,
      setting,
      booking,
      createBooking,
      userBookingState,
  } = state;
  return {
      hairdresser,
      salon,
      hairdresserReview,
      user,
      setting,
      booking,
      createBooking,
      userBookingState
  };
}
export default connect(mapStateToProps)(Service);
