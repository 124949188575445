import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { EllipseIcon } from '../../assets/SVG';
import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import LoaderSetting from './Loader';
import SettingHeadingTitle from './SettingHeadingTitle';
import SettingSidebar from './SettingSidebar';
import {loadStripeTerminal} from "@stripe/terminal-js";
import {paymentService} from "../../_services";
var terminal;


    loadStripeTerminal().then(function (StripeTerminal) {
        terminal = StripeTerminal.create({
            onFetchConnectionToken: fetchConnectionToken,
            onUnexpectedReaderDisconnect: unexpectedDisconnect,
        })
        console.log("terminal", terminal);
    })

function fetchConnectionToken(){
    let self=this;
    return paymentService.fetchConnectionToken()
        .then(function (res){
            console.log("res",res.data);
            return res.data.responseData.result.secret;
        })
}

function unexpectedDisconnect(){

}

class DiscoverCardReader extends Component{
    constructor(props){
        super(props);
        this.state = {
            activeCLass: false,
            connectDevice: false,
            isSearchReader:false,
            cardReaderFound:true,
        }
    }

    componentDidMount() {
        //this.discoverReaders();
        // document.body.classList.add('setting-temps-pages');
    }
    componentWillUnmount(){
    }

    handleDiscoverCardReader = ()=>{
        this.setState({activeCLass: !this.state.activeCLass})
    }
    handleConnectDevice = ()=>{
        this.setState({connectDevice: !this.state.connectDevice})
    }
    handleCancel = ()=>{
        this.setState({
            activeCLass: false,
            connectDevice: false
        })
    }

    discoverReaders=()=>{
        this.setState({
            isSearchReader:true
        })
        const config = {simulated: false, location: this.state.locationId};
        console.log("terminal",terminal);
        terminal.discoverReaders(config).then(function(discoverResult) {
            if (discoverResult.error) {
                console.log('Failed to discover: ', discoverResult.error);
            } else if (discoverResult.discoveredReaders.length === 0) {
                console.log('No available readers.');
            } else {
                // You should show the list of discoveredReaders to the
                // cashier here and let them select which to connect to (see below).
                this.connectReader(discoverResult);
            }
        });
    }

    connectReader=(discoverResult)=> {
        let self=this;
        // Just select the first reader here.
        var selectedReader = discoverResult.discoveredReaders[0];

        terminal.connectReader(selectedReader).then(function (connectResult) {
            if (connectResult.error) {
                console.log('Failed to connect: ', connectResult.error);
            } else {
                console.log('Connected to reader: ', connectResult.reader.label);
                self.setState({
                    isSearchReader:true
                })
            }
        });
    }

    render(){
        return(
            <>
                <div className='new-stng-cmn card-reader-list mb-3 mb-md-4 order-2'>
                    <div className='card-discover-top card-discoversDes'>
                        <h4>Discovered Card Readers</h4>
                        {this.state.isSearchReader===true?<p>Searching for card readers… <LoaderSetting /></p>:""}
                        {this.state.isSearchReader===false?<button className='btn btn-inf' onClick={()=>this.discoverReaders()}>Discover</button>:""}
                    </div>
                    {this.state.cardReaderFound===true?
                    <div onClick={()=>this.handleDiscoverCardReader()} className={`discover-card-reader-lst ${this.state.activeCLass ? 'active-card' : ''}`}>
                        <div className='row align-items-center'>
                            <div className='col-md-3 col-5'>
                                <div className='disccover-crd-redr-img'>
                                    <img className='img-responsive' src={require('../../assets/images/card-readerImg.png')}  />
                                </div>
                            </div>
                            <div className='col-md-9 col-7'>
                                <div className='disccover-crd-redr-descr'>
                                    <h4>BBPOS WisePOS E</h4>
                                    <p>Device 2316</p>
                                </div>
                            </div>
                            {this.state.connectDevice && <div className='col-md-12 col-12'>
                                <div className='card-discover-access-code pt-3 pt-md-4'>
                                 <p> Turn on your BBPOS WisePOS E and connect it to the same WIFI network as the device running Beu.</p>
                                 <p className='li-tag'><b>1.)</b> Once connected, swipe from the left and go into settings</p>
                                 <p className='li-tag'><b>2.)</b> Enter this admin pin onto the reader <b>07139</b> and then select generate pairing code </p>
                                 <p className='li-tag'><b>3.)</b> Enter the 3 word pairing code displayed on your BBPOS WisePOS E</p>
                                    {/* <p>Turn on your BBPOS WisePOS E and connect it to the same WIFI network as the device running Beu. Once connected, please enter the access code displayed on BBPOS WisePOS E.</p> */}
                                    <div className='form-group'>
                                        <label>Access Code</label>
                                        <input className='form-control' type="text" />
                                    </div>
                                    <div className='form-group mb-0 text-center text-lg-right btn-Readerss pt-md-3'>
                                        <button className='btn mr-md-3 btn-line-blck' onClick={()=>this.handleCancel()}>Cancel </button>
                                        <button className='btn btn-inf px-md-5' disabled>Confirm</button>
                                    </div>
                                </div>
                            </div>}
                        </div>
                       {this.state.activeCLass && (this.state.connectDevice ? '' : <div className='tick-Check'>
                            <img className='img-responsive' src={require('../../assets/images/tick-check.png')}  />
                        </div>) }
                    </div>:""}
                    {this.state.activeCLass && (this.state.connectDevice ? '' : <div className='conec-dvce-btn text-center text-lg-right mt-4'>
                        <button className='btn btn-inf' onClick={()=>this.handleConnectDevice()}>Connect Device</button>
                    </div>)}
                </div>
            </>
        )
    }

}

export default DiscoverCardReader
