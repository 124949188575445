import React, { Component } from "react";
import TopHeader from "../template/TopHeader";
import SideNav from "../template/SideNav";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import {
  hairdresserActions,
  userActions,
  paymentActions,
} from "../../_actions";
import { Addicon, Close, SortIcon, DownloadIcon, HomeIcon } from "../SvgIcon";
import moment from "moment";
import Pagination from "react-js-pagination";
import _ from "lodash";
import DatePicker from "react-datepicker";
import siteSetting from "../../config/env";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import Lottie from "react-lottie";
import animationData from "../../assets/animation-data/loader.json";
import toast from 'react-toast-notification';
// var ObjectId = require('mongoose').Types.ObjectId;
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
var date = new Date();
const Option = (props) => {
  // console.log("props option",props,props.isSelected)
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        {props.value == 0 ? (
          <label className="select-first-input">{props.label}</label>
        ) : (
          <label>{props.label}</label>
        )}
      </components.Option>
    </div>
  );
};

class Analytics extends Component {
  constructor(props) {
    super(props);
    this.myMultiselectRef = React.createRef();
    this.state = {
      PaymentDetailPopup: false,
      latitude: siteSetting.default_latitude,
      longitude: siteSetting.default_longitude,
      hairdresserList: [],
      selectedHairdresserId: "",
      selectedHairdresser: {},
      itemsCountPerPage: 10,
      accessToken: "",
      number: "",
      limit: "10",
      sortType: "2",
      isDownload: "0",
      payment_number: 0,
      paymentList: [],
      activePage: "1",
      search: "",
      filterClass: "filter",
      sortField: "date",
      hairdresserId: "",
      search_number: "",
      nameSortStatus: false,
      dateSortStatus: true,
      stylistSortStatus: false,
      transactionSortStatus:false,
      isSelectopen: false,
      bookings: 0,
      revenue: 0,
      missedRevenue:0,
      commission: 0,
      tipAmount: 0,
      totalSalonCommissionAmount: 0,
      stylistRevenue: 0,
      selectedIndex:0,
      toggleSearch: false,
      startDate: new Date(date.getFullYear(), date.getMonth(), 1),
      endDate: new Date(),
      myData: [
        { label: "Payment Type", value: "0" },
        { label: "Cash", value: "1" },
        { label: "Beu Card Readers", value: "2" },
        { label: "Other Card Readers", value: "3" },
        { label: "Other", value: "4" },
      ],
      paymentOption: [
        { label: "All", value: "0" },
        { label: "Pending", value: "2" },
        { label: "Paid", value: "4" },
      ],
      selectedBookingStatus:[4],
      selectedOption: [],
      selectedValue: [],
      entireSalon: true,
      exportPopup: false,
      allFeePopUp : false,
      selectedTransaction:{}
    };

    this.openPaymentDetailPopup = this.openPaymentDetailPopup.bind(this);
    this.searchClient = this.searchClient.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.changeHairdresser = this.changeHairdresser.bind(this);
    this.selectBookingStatus = this.selectBookingStatus.bind(this);
  }

  openPaymentDetailPopup() {
    this.setState({
      PaymentDetailPopup: !this.state.PaymentDetailPopup,
    });
  }
  closeModal = () =>
    this.setState({
      PaymentDetailPopup: false,
    });

  getBookingDate(dateString) {
    return moment.utc(dateString).format("DD.MM.YYYY");
  }

  componentDidMount() {
    var self = this;
    let params = {};
    if (localStorage.getItem("accessToken")) {
      //self.props.dispatch(userActions.profile(params))
      // let queryParams = new URLSearchParams(window.location.search);
      // console.log('check new data',queryParams.size>0);

      // if()


      let urlQueryParamsData = new URLSearchParams(window.location.search);
      let search = urlQueryParamsData.get('search');
      let activePage = urlQueryParamsData.get('activePage');
      let __hairdresserId = urlQueryParamsData.get('hairdresserId');
      let sortField = urlQueryParamsData.get('sortField');
      let sortType = urlQueryParamsData.get('sortType');
      let startDate = urlQueryParamsData.get('startDate');
      let endDate = urlQueryParamsData.get('endDate');
      let paymentType = urlQueryParamsData.get('paymentType');
      let selectedBookingStatus = urlQueryParamsData.get('selectedBookingStatus');

      //console.log('hhhhhhhhhh');
      if((selectedBookingStatus!=null && selectedBookingStatus!=undefined)){
         selectedBookingStatus  = selectedBookingStatus?.split(',')?.map((item)=>Number(item));
         self.setState({
          selectedBookingStatus:selectedBookingStatus,
         })
      }

      if((paymentType!=null && paymentType!==undefined)){
        paymentType = paymentType?.split(',')?.map((item)=>String(item));
        let selectedOptionsData = this.state.myData.filter(item=>paymentType.includes(item.value));
        urlQueryParamsData.set('paymentType', paymentType.join(','));
        self.setState({
          selectedValue:paymentType,
          selectedOption: selectedOptionsData,
        })
     }

      document.body.classList.add("login-home-temp");
      
      if(!(__hairdresserId!=null && __hairdresserId!==undefined)){
        __hairdresserId = self?.state?.hairdresserId;
      }

      console.log('check user', this.props.user.userInfo)
    
      if(this.props.user.userInfo?.hairdresser?.isSalonOwner == 0 &&
        this.props.user.userInfo?.hairdresser?.employmentTypeId == 2){
        __hairdresserId = localStorage.getItem('userId');
      }


     
      if(startDate!=null && startDate!==undefined){
        self.setState({
          startDate: new Date(startDate),
        })
      }

      if(endDate!=null && endDate!==undefined){
        self.setState({
          endDate: new Date(endDate),
        })
      }

      if(activePage!=null && activePage!==undefined){
        self.setState({
          activePage: +activePage,
        })
      }
      
      if(search!=null && search!==undefined){
        self.setState({
          search: activePage,
        })
      }


      if (
        this.props.user &&
        this.props.user.userInfo &&
        this.props.user.userInfo.hairdresser.isSalonOwner === 1
      ) {
        this.updateQueryParam('hairdresserId',__hairdresserId)
        this._getDataVal(self.state);
      } else {
        setTimeout(() => {
          if (
            (this.props.user.userInfo?.hairdresser?.isSalonOwner == 0 &&
              this.props.user.userInfo?.hairdresser?.employmentTypeId == 1) ||
            (this.props.user.userInfo?.hairdresser?.isSalonOwner != 1 &&
              this.props.user.userInfo?.hairdresser?.employmentTypeId == 1) ||
            (this.props.user.userInfo?.hairdresser?.isSalonOwner == 0 &&
              this.props.user.userInfo?.hairdresser?.employmentTypeId == 2)
          ) {
            self.setState(
              {
                hairdresserId: __hairdresserId,
                selectedHairdresserId: __hairdresserId,
              },
              function () {
                this.updateQueryParam('hairdresserId',__hairdresserId)
                self._getDataVal(self.state);
              }
            );
          } else {
            self.setState(
              {
                hairdresserId: "",
                sortType: "2",
                isDownload: "0",
                activePage: "1",
                entireSalon: true,
                search: "",
              },
              () => {
                this._getDataVal(this.state);
              }
            );
          }
        }, 600);
      }

      let { dispatch } = this.props;

      let params1 = {
        searchKey: this.state.searchKey,
        hairdresserId: __hairdresserId,
        page: 1,
        listType: 1,
      };
      self.props
        // .dispatch(hairdresserActions.hairdresserStaffService(params1))
          .dispatch(hairdresserActions.hairdresserStaffRota(params1))
        .then(function (response) {
          //console.log("response", response)
          //self.state.selectedHairdresserId===''||
          if (
            self.props.match.params.hairdresserId &&
            self.props.match.params.hairdresserId !== ""
          ) {
            let selectedHairdresser = _.find(
              response.data.data.total,
              function (o) {
                return o._id === self.props.match.params.hairdresserId;
              }
            );
            self.setState({
              selectedHairdresserId: self.props.match.params.hairdresserId,
              selectedHairdresser: selectedHairdresser,
            });
            params.hairdresserId = self.props.match.params.hairdresserId;
            self.props.dispatch(hairdresserActions.getHairdresserService(params))
              .then(function (response) {});
          } else {
           
            // self.setState({
            //   selectedHairdresserId: response.data.data.rota[0]._id,
            //   selectedHairdresser: response.data.data.rota[0],
            // });
            // params.hairdresserId = response.data.data.rota[0]._id;

            let __selectedIndex = response?.data?.data.rota?.findIndex(findSelectedHairdresser=>findSelectedHairdresser?._id===__hairdresserId);
            let selectedData = response?.data?.data.rota?.find(findSelectedHairdresser=>findSelectedHairdresser?._id===__hairdresserId);
            let usertype =1;
            if(self.props.user.userInfo?.hairdresser?.isSalonOwner == 0 &&
              self.props.user.userInfo?.hairdresser?.employmentTypeId == 2){
                 usertype = 2;
              }

            //need to fix data 
            self.setState({
              selectedHairdresserId: __hairdresserId,
              selectedHairdresser: selectedData,
              selectedIndex:__hairdresserId===''?0:usertype==1?__selectedIndex+1:__selectedIndex,
              activePage: "1",
              entireSalon: false,
              search: "",
            });

            console.log('check data here rrrr',{
              selectedHairdresserId: __hairdresserId,
              selectedHairdresser: selectedData,
              selectedIndex:__hairdresserId===localStorage.getItem("userId")?__selectedIndex:__selectedIndex+1,
              activePage: "1",
              entireSalon: false,
              search: "",
            })
            params.hairdresserId = __hairdresserId==''?localStorage.getItem("userId"):__hairdresserId;
            self.props.dispatch(
              hairdresserActions.getHairdresserService(params)
            );
          }
        });
    } else {
      this.props.history.push("/");
    }
  }

  handlePageChange(eve) {
    var self = this;
    self.setState({ activePage: eve }, () => {
      this.updateQueryParam('activePage',eve);
      self._getDataVal(self.state);
    });
  }

  _getDataVal(val) {
    var self = this;
    let { dispatch } = self.props;
    self.setState({ showLoader: true });
    dispatch(paymentActions.paymentList(self.state)).then(function (response) {
      self.setState({ showLoader: false });
      if (response.data.statusCode === 1) {
       
        const {
          result,
          totalCount,
          commission,
          revenue,
          tipAmount,
          totalSalonCommissionAmount,
          stylistRevenue,
          missedRevenue
        } = response.data.responseData;

        self.setState({ paymentList: result });
        self.setState({
          payment_number: totalCount,
          bookings: totalCount,
          revenue: revenue,
          missedRevenue:missedRevenue,
          commission: commission,
          tipAmount: tipAmount,
          totalSalonCommissionAmount: totalSalonCommissionAmount,
          stylistRevenue: stylistRevenue,
        });
      } else {
        self.setState({ paymentList: [] });
        self.setState({ payment_number: 0 });
      }
    });
  }

  changeHairdresser(hairdresserId, Hairdresser) {
    let self = this;
    console.log(
      "check_selected_hairdresser",
      Hairdresser?.hairdresserInfo?.isSalonOwner != 1 &&
        Hairdresser?.hairdresserInfo?.employmentTypeId == 1
        ? Hairdresser
        : "_"
    );

    self.setState(
      {
        hairdresserId: hairdresserId,
        selectedHairdresserId: hairdresserId,
        selectedHairdresser: Hairdresser,
        activePage: "1",
        entireSalon: false,
        search: "",
      },
      () => {
        self.updateQueryParam('hairdresserId',hairdresserId)
        self._getDataVal(self.state);
      }
    );

    let params = {};
    params.hairdresserId = hairdresserId;

    // self.props.dispatch(hairdresserActions.getHairdresserService(params))
    //     .then(function (response) {
    //
    //     });
  }

  getServices(bookedServices) {
    let clientServices;
    if (bookedServices.length > 0) {
      clientServices = bookedServices.map((service, i) => (
        <div key={"ss-" + i} style={{ display: "block" }}>
          {service?.serviceInfo?.serviceName}
        </div>
      ));
    }else{
      return "--";
    }
    return clientServices;
  }

  getProduct=(client)=>{
    let productName;
     if(client.products&&client.products.length>0){
       productName = client.products.map((pro, i) => (
           <div key={"pp-" + i} style={{ display: "block" }}>
             {pro?.productName}
           </div>
       ));
     }else {
       return "--";
     }
     return productName;
  }

  toggleSearchbtn() {
    this.setState({ toggleSearch: !this.state.toggleSearch });
  }
  toggleSearchbtnClose() {
    this.setState({ toggleSearch: false });
  }

  getTransactionType(transactions) {
    let clientTransactions=[];
    if (transactions?.transaction != null && transactions?.transaction != "[]" && transactions?.transaction != "") {
     
      if(this.getDiposit(transactions.transactionsInfo)>0){
        clientTransactions.push('Online');
      }

    transactions.transaction.forEach(transaction => {
      let paymentMethod = "";
      if (transaction.paymentType === 1) {
        paymentMethod = "Pay by App";
      } else if (transaction.paymentType === 2) {
        paymentMethod = "Other Card Readers";
      } else if (transaction.paymentType === 3) {
        paymentMethod = "Cash";
      } else if (transaction.paymentType === 4) {
        paymentMethod = "Other";
      } else if (transaction.paymentType === 5) {
        paymentMethod = "Beu Card Readers";
      } else if(transaction.paymentType === 10){
        paymentMethod ="Saved card"
      }

      if (paymentMethod !== "") {
        clientTransactions.push(paymentMethod);
      }
    });

    } else {
      if(this.getDiposit(transactions.transactionsInfo)>0){
        clientTransactions.push('Online');
      }else {
        clientTransactions.push("__");
      }
    }
   
    return <div style={{ textAlign: "center" }}>{clientTransactions.join(',')}</div>;
  }

  getInvoiceNumber(booking) {
    return booking._id;
    // if (booking.invoiceNumber) {
    //   return booking.invoiceNumber;
    // } else {
    //   let invoceNumber = new Date(booking.bookingDate).getTime();
    //   return invoceNumber;
    // }
  }

  getServiceCost(serviceData) {
    let serviceCost = 0;
    if (serviceData.length > 0) {
      for (let service of serviceData) {
        if(service.serviceOriginalPrice){
          serviceCost += Number(service.serviceOriginalPrice);
        }else {
          serviceCost += Number(service.servicePrice);
        }
      }
    }
    return serviceCost>0?serviceCost.toFixed(2):0;
  }

  getDiposit(depositData) {
    let depositCost = 0;
    if (depositData.length > 0) {
      for (let service of depositData) {
        if (service.pgObjectType == "deposit") {
          depositCost += Number(service.pgTransactionAmount);
        }
      }
      if(depositCost==0){
        depositCost = "__";
      }else{
        depositCost=depositCost.toFixed(2)
      }
    } else {
      depositCost = "__";
    }
    return depositCost;
  }

  searchClient(e) {
    let searchDataValue = e?.target?.value;
    this.setState(
      {
        search: searchDataValue,
        activePage: "1",
      },
      () => {
        this.updateQueryParam('search',searchDataValue)
        this._getDataVal(this.state);
      }
    );
  }

  exportCSV() {
    let self = this;
    let { dispatch } = self.props;
    this.setState(
      {
        isDownload: "1",
      },
      () => {
        self.updateQueryParam('isDownload',1)
        dispatch(paymentActions.paymentList(self.state)).then(function (
          response
        ) {
          console.log('check errorrrrrrrrrr',response);
          if(response?.data?.statusCode===0){
            toast.error(response?.data?.error?.responseMessage)
          }

          self.updateQueryParam('isDownload',0);
          self.setState({isDownload: "0"});
          const { file } = response.data.responseData;
          window.open(file);
        });
      }
    );
  }

  getStatus = (transactionData) => {
    let totalAmount = 0;
    if (transactionData.servicesBooked.length > 0) {
      for (let service of transactionData.servicesBooked) {
        if(service.servicePrice) {
          totalAmount += Number(service.servicePrice);
        }
      }
    }
    //console.log("totalAmount",totalAmount);
    let totalProductPrice=transactionData.totalProductPrice?transactionData.totalProductPrice:0;
    //console.log("totalProductPrice",totalProductPrice);
    totalAmount=totalAmount+totalProductPrice;
    let depositCost = 0;
    if (transactionData && transactionData.transactionsInfo.length > 0) {
      for (let service of transactionData.transactionsInfo) {
        if (service.pgObjectType == "deposit") {
          depositCost += Number(service.pgTransactionAmount);
          depositCost.toFixed(2);
        }
        if (service.pgObjectType == "charge") {
          depositCost += Number(service.pgTransactionAmount);
          depositCost.toFixed(2);
        }
      }
    }
    let paidAmount = 0;
    if (
      transactionData &&
      transactionData.transaction &&
      transactionData.transaction.length > 0
    ) {
      for (let service of transactionData.transaction) {
        if (service.paymentType !== 5) {
          paidAmount += service.amount;
        }
        //paidAmount.toFixed(2);
      }
    }
    let pendingAmount =
      parseFloat(totalAmount) -
      (parseFloat(depositCost) + parseFloat(paidAmount));
    //console.log("totalAmount,pendingAmount,depositCost paidAmount", totalAmount, pendingAmount, depositCost, paidAmount)
    if (pendingAmount <= 0) {
      return <a className="tble-btn">Paid</a>;
    } else {
      // if(depositCost!==0){
      //     return <a className="tble-btn">Deposit Paid</a>
      // }else{
      return <a className="tble-btn">Pending</a>;
      //}
    }
  };

  getPendingAmount = (transactionData) => {
    //console.log("transactionData",transactionData);
    let totalAmount = 0;
    if (transactionData.servicesBooked.length > 0) {
      for (let service of transactionData.servicesBooked) {
        if(service.servicePrice) {
          totalAmount += Number(service.servicePrice);
        }
      }
    }
    //console.log("totalAmount",totalAmount);
    let totalProductPrice=transactionData.totalProductPrice?transactionData.totalProductPrice:0;
    //console.log("totalProductPrice",totalProductPrice);
    totalAmount=totalAmount+totalProductPrice;
    let depositCost = 0;
    if (transactionData && transactionData.transactionsInfo.length > 0) {
      for (let service of transactionData.transactionsInfo) {
        if (service.pgObjectType == "deposit") {
          depositCost += Number(service.pgTransactionAmount);
          depositCost.toFixed(2);
        }
        if (service.pgObjectType == "charge") {
          depositCost += Number(service.pgTransactionAmount);
          depositCost.toFixed(2);
        }
      }
    }
    let paidAmount = 0;
    if (
        transactionData &&
        transactionData.transaction &&
        transactionData.transaction.length > 0
    ) {
      for (let service of transactionData.transaction) {
        if (service.paymentType !== 5) {
          paidAmount += service.amount;
        }
        //paidAmount.toFixed(2);
      }
    }
    let pendingAmount = parseFloat(totalAmount) - (parseFloat(depositCost) + parseFloat(paidAmount));
   //console.log("totalAmount,pendingAmount,depositCost paidAmount", totalAmount, pendingAmount, depositCost, paidAmount)

    return pendingAmount;
  };

  openBooking = (transactionData) => {
    if(transactionData.isCheckout===1){
      this.props.history.push("/invoice/" + transactionData._id);
    }else {
      
      if(transactionData?.bookingStatus===4){
        this.props.history.push("/invoice/" + transactionData._id);
      }else{
        this.props.history.push("/booking/" + transactionData._id);
      }
    }
  };

  dateFilter = (e, field) => {
    console.log(e, field);
    this.setState(
      {
        [field]: e,
      },
      function () {
        this.updateQueryParam(field,moment(e).format('YYYY-MM-DD'))
        this._getDataVal(this.state);
      }
    );
  };

  updateQueryParam(paramName, paramValue) {
    // Get the current URL
    let currentURL = window.location.href;
    
    // Check if the query parameter already exists
    if (currentURL.includes('?')) {
        // Query parameter exists
        let queryParams = currentURL.split('?')[1];
        let queryParamsArray = queryParams.split('&');
        let updatedParams = [];

        // Loop through existing parameters and update the specified one
        for (let i = 0; i < queryParamsArray.length; i++) {
            let param = queryParamsArray[i].split('=');
            if (param[0] === paramName) {
                updatedParams.push(paramName + '=' + paramValue);
            } else {
                updatedParams.push(queryParamsArray[i]);
            }
        }

        // Update the URL with the updated query parameters
        let updatedURL = currentURL.split('?')[0] + '?' + updatedParams.join('&');
        window.history.replaceState({}, '', updatedURL);
    } else {
        // No query parameter exists, so just append the new one
        let updatedURL = currentURL + '?' + paramName + '=' + paramValue;
        window.history.replaceState({}, '', updatedURL);
    }
  }

  paymentDetails = (client) => {
    // console.log("client",client);
    return {
      invoice: this.getInvoiceNumber(client),
      userId: client.userId,
      name: client.userInfo.name,
      email: client.userInfo.email,
      servicesBooked: client.servicesBooked,
      hairdresserName: client.hairdresserInfo.name,
      hairdresserId: client.hairdresserId,
      bookingDate: client.bookingDate,
      //payment :this.getTransactionType(client.transaction),
      cost: this.getServiceCost(client.servicesBooked),
      currency:
        client.currencySymbol && client.currencySymbol !== null
          ? client.currencySymbol
          : "£",
      desposit: this.getDiposit(client.transactionsInfo),
      commission: client.salonCommissionAmount
        ? client.salonCommissionAmount.toFixed(2)
        : 0,
      //status:this.getStatus(client),
      total: client.totalAmount
        ? client.totalAmount.toFixed(2)
        : this.getServiceCost(client.servicesBooked),
    };
  };

  feeCalculation = (client) => {
    if (
      (client.beuFeeAmount && client.beuFeeAmount > 0) ||
      (client.stripeFeeAmount && client.stripeFeeAmount > 0)||(client.beuFeeAmountDeposit && client.beuFeeAmountDeposit > 0)||(client.stripeFeeAmountDeposit && client.stripeFeeAmountDeposit > 0)
    ) {
      let currencySymbol =
        client.currencySymbol && client.currencySymbol !== null
          ? client.currencySymbol
          : "£";
      let fee =
        (client.beuFeeAmount ? client.beuFeeAmount : 0) +
        (client.stripeFeeAmount ? client.stripeFeeAmount : 0)+(client.beuFeeAmountDeposit ? client.beuFeeAmountDeposit : 0)+(client.stripeFeeAmountDeposit ? client.stripeFeeAmountDeposit : 0);
      return currencySymbol + fee.toFixed(2);
    } else {
      return "--";
    }
  };

  payoutFee = (client) =>{
    console.log("client",client);
    let currencySymbol =
        client.currencySymbol && client.currencySymbol !== null
            ? client.currencySymbol
            : "£";
    // let fee =client.payoutTotalFeeAmount?client.payoutTotalFeeAmount:0;
    // if(fee){
    //   return currencySymbol+fee.toFixed(2);
    // }else{
    //   return "--"
    // }
    if(client.payoutFeeSattled&&client.payoutFeeSattled.length>0){
      let fee=0;
      client.payoutFeeSattled.map(function (obj){
        if(obj?.hairdresserId===localStorage.getItem('userId')){
          fee=fee+obj.payoutFee
        }
      })
      if(fee>0) {
        return currencySymbol + (fee / 100).toFixed(2);
      }else{
        return "--"
      }
    }else{
      return "--"
    }

  }

  totalAmount = (client) =>{
    //console.log("client",client);
    if(!_.isEmpty(client)) {
      let currencySymbol =
          client.currencySymbol && client.currencySymbol !== null
              ? client.currencySymbol
              : "£";
      let total = client.totalAmount ? client.totalAmount : parseFloat(this.getServiceCost(client.servicesBooked));
      if (total) {
        return currencySymbol + total.toFixed(2);
      } else {
        return "--"
      }
    }
  }

  salonCommissionCalculation = (client) => {
    let currencySymbol = client.currencySymbol && client.currencySymbol !== null ? client.currencySymbol : "£";
    if(client.bookingStatus===3){   //booking cancel
        let amount=0;
        if(client.salonAmountCancel&&client.salonAmountCancel!==null){
           amount=client.salonAmountCancel;
        }
        return currencySymbol+amount.toFixed(2);
    }else if(client.bookingStatus===6){   //booking No show
      let amount=0;
      if(client.salonAmountNoShow&&client.salonAmountNoShow!==null){
        amount=client.salonAmountNoShow;
      }
      return currencySymbol+amount.toFixed(2);
    }else {     // pending or complete booking
      let fee;
      let transactionFeeType = client?.transactionFeeType;

      if ((client.beuFeeAmount && client.beuFeeAmount > 0) || (client.stripeFeeAmount && client.stripeFeeAmount > 0)) {
        fee = (client.beuFeeAmount ? client.beuFeeAmount : 0) + (client.stripeFeeAmount ? client.stripeFeeAmount : 0);
      }
      let salonProductAmount = client.salonProductAmount ? client.salonProductAmount : 0;
      if (client.salonCommissionAmount) {
        fee = fee == undefined ? 0 : fee;
        let salonCommissionAmountDeposit = client.salonCommissionAmountDeposit ? client.salonCommissionAmountDeposit : 0;
        return currencySymbol + (client.salonCommissionAmount + salonCommissionAmountDeposit + salonProductAmount).toFixed(2);
      } else {
        // if (
        //   this.state.selectedHairdresser?.hairdresserInfo?.isSalonOwner === 0 && this.state.selectedHairdresser?.hairdresserInfo?.employmentTypeId == 1
        // ) {
        //   if (client.totalAmount) {
        //     return currencySymbol + (client.totalAmount+salonProductAmount).toFixed(2);
        //   } else {
        //     return currencySymbol + (parseFloat(this.getServiceCost(client.servicesBooked))+salonProductAmount).toFixed(2);
        //   }
        // } else {
        //console.log("@@@@",(client.hairdresserIsSalonOwner===0&&client.hairdresserUserType===1)||client.hairdresserIsSalonOwner===1)
        if ((client.hairdresserIsSalonOwner === 0 && client.hairdresserUserType === 1) || client.hairdresserIsSalonOwner === 1) {
          if (client.totalAmount) {
            return currencySymbol + (salonProductAmount + client.totalAmount).toFixed(2);
          } else {
            return currencySymbol + (salonProductAmount + parseFloat(this.getServiceCost(client.servicesBooked))).toFixed(2);
          }
        } else {
          let salonCommissionAmountDeposit = client.salonCommissionAmountDeposit ? client.salonCommissionAmountDeposit : 0;
          //console.log("@@@@",(client.salonCommissionAmount+salonCommissionAmountDeposit+salonProductAmount))
          if (client.totalAmount) {
            return currencySymbol + (client.salonCommissionAmount + salonCommissionAmountDeposit + salonProductAmount).toFixed(2);
          } else {
            return currencySymbol + (salonProductAmount + parseFloat(this.getServiceCost(client.servicesBooked)) + salonCommissionAmountDeposit).toFixed(2);
          }
        }

      }
    }
  };

  stylistCommissionCalculation = (client) => {
    let currencySymbol = client.currencySymbol && client.currencySymbol !== null ? client.currencySymbol : "£";
    if(client.bookingStatus===3){   //booking cancel
      let amount=0;
      if(client.hairdresserAmountCancel&&client.hairdresserAmountCancel!==null){
        amount=client.hairdresserAmountCancel;
      }
      return currencySymbol+amount.toFixed(2);
    }else if(client.bookingStatus===6){   //booking No show
      let amount=0;
      if(client.hairdresserAmountNoShow&&client.hairdresserAmountNoShow!==null){
        amount=client.hairdresserAmountNoShow;
      }
      return currencySymbol+amount.toFixed(2);
    }else {     // pending or complete booking

      let hairdresserProductCommission = client.hairdresserProductCommission ? client.hairdresserProductCommission : 0;
      if (client?.hairdresserIsSalonOwner == 1) {
        return "__";

      } else if (client?.hairdresserIsSalonOwner == 0 && client?.hairdresserUserType == 2) {
        if (hairdresserProductCommission + client.stylistCommission > 0) {
          return currencySymbol + (hairdresserProductCommission + client.stylistCommission).toFixed(2);
        } else {
          return "__";
        }
      } else {
        if (
            this.state.selectedHairdresser?.hairdresserInfo?.isSalonOwner != 1 && this.state.selectedHairdresser?.hairdresserInfo?.employmentTypeId == 1
        ) {
          if (hairdresserProductCommission > 0) {
            return currencySymbol + (hairdresserProductCommission).toFixed(2);
          } else {
            return "__";
          }
        } else {
          if (hairdresserProductCommission > 0) {
            return currencySymbol + (hairdresserProductCommission).toFixed(2);
          } else {
            return "__";
          }
        }
      }
    }
  };

  feePaidStatus=(client)=>{
    if(client?.bookingStatus===6||client?.bookingStatus===3){
      if (client?.noShowFeePaidBy == 1) {
        return "Self Emp"
      } else {
        return "Salon"
      }
    }else {
      if(client?.bookingStatus===4&&client?.noShowFee>0){
        if (client?.noShowFeePaidBy == 1) {
          return "Self Emp"
        } else {
          return "Salon"
        }
      }else {
        if (client?.transactionFeeType == 1) {
          return "Self Emp"
        } else {
          return "Salon"
        }
      }
    }
  }

  handleSelectChange = (value, event) => {
    let self = this;

    if (event.action === "select-option" && event.option.value == "0") {
      this.setState(
        {
          selectedValue: ["1", "2", "3", "4"],
          selectedOption: this.state.myData,
          pageNo:1
        },
        function () {
          self.updateQueryParam('paymentType', ["1", "2", "3", "4"].join(','));
          self._getDataVal(self.state);
        }
      );
    } else if (
      event.action === "deselect-option" &&
      event.option.value == "0"
    ) {
      this.setState(
        {
          selectedValue: [],
          selectedOption: [],
          pageNo:1
        },
        function () {
          self._getDataVal(self.state);
        }
      );
    } else if (event.action === "deselect-option") {
      let selectedValue = [];
      value.map((obj) => {
        if (obj.value != "0") {
          selectedValue.push(obj.value);
        }
      });
      this.setState(
        {
          selectedValue,
          selectedOption: value.filter((o) => o.value !== "0"),
          pageNo:1
        },
        function () {
          self.updateQueryParam('paymentType', selectedValue.join(','));
          self._getDataVal(self.state);
        }
      );
    } else if (value.length === this.state.myData.length - 1) {
      this.setState(
        {
          selectedValue: ["1", "2", "3", "4"],
          selectedOption: this.state.myData,
          pageNo:1
        },
        function () {
          self.updateQueryParam('paymentType', ["1", "2", "3", "4"].join(','));
          self._getDataVal(self.state);
        }
      );
    } else {
      let selectedValue = [];
      value.map((obj) => {
        //if(obj.value!="0"){
        selectedValue.push(obj.value);
        // }
      });
      this.setState(
        {
          selectedValue,
          selectedOption: value,
          pageNo:1
        },
        function () {
          self.updateQueryParam('paymentType', selectedValue.join(','));
          self._getDataVal(self.state);
        }
      );
    }
  };

  handleSelectOpen = () => {
    this.setState({
      isSelectopen: true,
    });
  };

  handleSelectClose = () => {
    this.setState({
      isSelectopen: false,
    });
  };

  handleExport = () => {
    const { startDate, endDate } = this.state;
    const diffInWeeks = moment(endDate).diff(startDate, "days", true);
    if (diffInWeeks > 31) {
      toast.error("The selected date range is more than 1 month.")
      // this.setState({ exportPopup: true });
    } else {
      this.exportCSV();
    }
  };

  closeFeePopup = () =>{
    let self = this;
    self.setState({ selectedTransaction:{},allFeePopUp: false});
}
feeModal = (data) =>{
    let self = this;
    if(data.payoutTotalFeeAmount&&data.payoutTotalFeeAmount>0&&this.payoutFee(data)!=='--') {
      self.setState({selectedTransaction: data, allFeePopUp: true});
    }
}

  transactionDate=(client)=>{
    if(client?.transaction[0]?._id){
      //let dateInfo=ObjectId(client?.transaction[0]?._id).getTimestamp();
       // console.log(moment(dateInfo).format("DD.MM.YYYY HH:mm"));
      let timeStamp1 = parseInt(client?.transaction[0]?._id.toString().substr(0,8), 16)*1000
      let dateInfo = new Date(timeStamp1)
      return moment(dateInfo).format("DD.MM.YYYY HH:mm");
    }else{
      return "__";
    }
  }

  // onExportConfirmed = () => {
  //   this.setState({ exportPopup: false });
  //   this.exportCSV();
  // };

  // onExportCancelled = () => {
  //   this.setState({ exportPopup: false });
  // };

  // closeExportModal = () =>
  //   this.setState({
  //     exportPopup: false,
  //   });

  getProductCost=(client)=>{
    let currencySymbol =client.currencySymbol !== null ? client.currencySymbol : "£";
    if(client.totalProductPrice&&client.totalProductPrice>0){
      return currencySymbol+(client.totalProductPrice).toFixed(2);
    }else{
      return "__";
    }
  }

  getTotalCost=(client)=>{
    let currencySymbol= client.currencySymbol !== null ? client.currencySymbol : "£";
    let totalAmount=0;
    if(client.totalAmount) {
      totalAmount = client.totalAmount;
    }else{
      totalAmount=parseFloat(this.getServiceCost(client.servicesBooked));
    }
    console.log(client._id,"totalAmount",totalAmount);
    totalAmount=totalAmount+(client.totalProductPrice?client.totalProductPrice:0);

   return currencySymbol+totalAmount.toFixed(2);
  }

  getServiceStripeCharges=(client)=>{

    let serviceCost = 0;
    let currencySymbol= client.currencySymbol !== null ? client.currencySymbol : "£";

    if(client.bookingStatus===4) {
      let serviceData = client.servicesBooked;
      if (serviceData.length > 0) {
        for (let service of serviceData) {
          serviceCost += Number(service.servicePrice);
        }
      }
    }
    if(client.bookingStatus===3) {
      if(client.cancelFee&&client.cancelFee>0) {
        serviceCost += Number(client.cancelFee);
      }
    }
    if(client.bookingStatus===6) {
      if(client.noShowAmount&&client.noShowAmount>0) {
        serviceCost += Number(client.noShowAmount);
      }
    }
    if(serviceCost>0) {
      return currencySymbol + serviceCost.toFixed(2);
    }else{
      return "__";
    }

  }


  selectBookingStatus(data){
    let self = this;
    let statusData;
    if(self.state.selectedBookingStatus.includes(data)){
      statusData = self.state.selectedBookingStatus.filter((bookingStatus)=>bookingStatus!==data);
     }else{
      statusData = [...self.state.selectedBookingStatus,data];
    }
    self.setState({
      selectedBookingStatus:statusData
    },()=>{
      self.updateQueryParam('selectedBookingStatus', statusData?.join(','));
      self._getDataVal(self.state)
    })
  }


 formatCurrencyUK(amount) {
    return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP'
    }).format(amount);
}
 

  render() {
    console.log("this.prop", this.props);
    console.log("this.state", this.state);
    return (
      <div className="h_n transaction-dashboard-temo-new">
        <div className="body_container cm_bg_white">
          <TopHeader title="Transactions" {...this.props} />
          <SideNav {...this.props} />
          <div className="transaction-temp--new">
            <Tabs onSelect={(index) => this.setState({selectedIndex:index})} selectedIndex={this.state.selectedIndex} className="toptab-parent analytics-tabsHead--wrap new--tabs-transactios">
              <TabList>
                <ul className="">
                  {this.props.user &&
                  this.props.user.userInfo &&
                  this.props.user.userInfo.hairdresser.isSalonOwner === 1 ? (
                    <Tab>
                      <div
                        className="toptab first _black_tab"
                        onClick={() => {
                          this.setState(
                            {
                              hairdresserId: "",
                              sortType: "2",
                              isDownload: "0",
                              activePage: "1",
                              entireSalon: true,
                              search: "",
                            },
                            () => {
                              this.updateQueryParam('hairdresserId',"");
                              this._getDataVal(this.state);
                            }
                          );
                        }}
                      >
                        <h6>Entire Salon</h6>
                        <div className="circle-img">
                          <HomeIcon />
                          {/* <img src={require('../../assets/images/Pixie.png')} className="img-fluid circle-img" /> */}
                        </div>
                      </div>
                    </Tab>
                  ) : (
                    ""
                  )}
                  {this.props.staffRota &&
                  this.props.staffRota.rota &&
                  this.props.staffRota.rota.length > 0
                    ? this.props.staffRota.rota.map((hairdresser, i) => (
                        <Tab
                          key={hairdresser._id}
                          onClick={this.changeHairdresser.bind(
                            this,
                            hairdresser._id,
                            hairdresser
                          )}
                        >
                          <div className="toptab _black_tab">
                            <h6>
                              {hairdresser.name.length > 13
                                ? `${hairdresser.name.substring(0, 13)}...`
                                : hairdresser.name}
                            </h6>
                            <div className="circle-img">
                              <img
                                alt=""
                                src={
                                  hairdresser.userImage.circleImage
                                    ? hairdresser.userImage.circleImage
                                    : require("../../assets/images/Pixie.png")
                                }
                                className="img-fluid circle-img"
                              />
                            </div>
                          </div>
                        </Tab>
                      ))
                    : ""}
                </ul>
              </TabList>
              <div className="container-fluid graph-shadow new-tranctions-body-wrpp">

                <div className="row">
                  <div className="top-container col-sm-12">
                    <div className="transacion-container transaction-new--wrp">
                      <div className="transacion-box">
                        <div className="head">
                          <h2>{this.state.bookings}</h2>
                        </div>
                        <div className="text">
                          {" "}
                          <p>Transactions</p>{" "}
                        </div>
                      </div>
                      <div className="transacion-box">
                        <div className="head">
                          <h2>{this.formatCurrencyUK(this.state.revenue.toFixed(2))}</h2>
                        </div>
                        <div className="text">
                          <p>Total Revenue</p>{" "}
                        </div>
                      </div>

                      <div className="transacion-box">
                        <div className="head">
                          <h2>
                          {this.formatCurrencyUK(this.state.selectedHairdresser?.hairdresserInfo
                            ?.isSalonOwner != 1 &&
                          this.state.selectedHairdresser?.hairdresserInfo
                            ?.employmentTypeId == 1
                            ? !this.state.entireSalon
                              ? this.state.revenue.toFixed(2)
                              : this.state.totalSalonCommissionAmount.toFixed(
                                  2
                                )
                            : this.state.totalSalonCommissionAmount.toFixed(
                                2
                              ))}
                          </h2>
                        </div>
                        <div className="text">
                          <p> Total Salon Revenue</p>{" "}
                        </div>
                      </div>

                      {(this.state?.selectedHairdresser?.hairdresserInfo
                        ?.isSalonOwner == 1 &&
                        this.state?.selectedHairdresser?.hairdresserInfo
                          ?.employmentTypeId == 1) ||
                      (this.state.selectedHairdresser?.hairdresserInfo
                        ?.isSalonOwner == 0 &&
                        this.state?.selectedHairdresser?.hairdresserInfo
                          ?.employmentTypeId == 2) ? (
                        <div className="transacion-box">
                          <div className="head">
                            <h2>{this.formatCurrencyUK(this.state.stylistRevenue.toFixed(2))}</h2>
                          </div>
                          <div className="text">
                            <p>Stylist Revenue</p>{" "}
                          </div>
                        </div>
                      ) : null}
                      <div className="transacion-box">
                        <div className="head">
                          <h2>{this.formatCurrencyUK(this.state.commission.toFixed(2))}</h2>
                        </div>
                        <div className="text">
                          <p>Commission Owed</p>
                        </div>
                      </div>
                      <div className="transacion-box" style={{background: "#c1ffc9"}}>
                        <div className="head">
                          <h2>£{this.state?.tipAmount.toFixed(2)}</h2>
                        </div>
                        <div className="text">
                          <p>Tips</p>
                        </div>
                      </div>
                      {this.state.selectedBookingStatus.includes(3)||this.state.selectedBookingStatus.includes(6)?
                          <div className="transacion-box" style={{background: "#FFE5D5"}}>
                            <div className="head">
                              <h2>{this.formatCurrencyUK(this.state.missedRevenue.toFixed(2))}</h2>
                            </div>
                            <div className="text">
                              <p>Missed Revenue </p>{" "}
                            </div>
                          </div>:""}
                    </div>

                    <div className="right-container">
                      <div
                        className={`d-md-flex align-items-flex-start mob-btnss-div _new_transaction_change ${
                          this.state.toggleSearch ? "showSecrhbar" : ""
                        }`}
                      >
                        {/* First need to complete from backend export large excel file the 
                         button */}

                        {/* <button
                          onClick={this.exportCSV}
                          className="btn btn-dark-blue mr-md-2 right-container-block"
                        >
                          <span>
                            <DownloadIcon />
                          </span>
                          Export
                        </button> */}

                        <button
                          onClick={this.handleExport}
                          className="btn btn-dark-blue mr-md-2 right-container-block"
                        >
                          <span>
                            <DownloadIcon />
                          </span>
                          Export
                        </button>

                        <div className="start-end-datepicker right-container-block">
                          <div className="form-group mb-0 sm_date_picker ">
                            <DatePicker
                              popperPlacement="bottom-start"
                              selected={this.state.startDate}
                              onChange={(e) => this.dateFilter(e, "startDate")}
                              dateFormat={"dd-MM-yyyy"}
                            />
                          </div>
                          <span className="dash">-</span>
                          <div className="form-group mb-0 sm_date_picker">
                            <DatePicker
                              popperPlacement="bottom-end"
                              selected={this.state.endDate}
                              onChange={(e) => this.dateFilter(e, "endDate")}
                              dateFormat={"dd-MM-yyyy"}
                            />
                          </div>
                        </div>
                        {/* <Modal
                          blurClass="blure_bg"
                          centered
                          show={this.state.exportPopup}
                          onHide={this.onExportCancelled}
                          size="xl"
                        >
                          <Modal.Header closeButton>
                            <Modal.Title style={{font:"bolder"}}>Export</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <p style={{fontSize:"22px"}}>
                              The selected date range is more than 4 weeks old.
                              Do you want to export?
                            </p>
                          </Modal.Body>
                          <Modal.Footer>
                            <button
                            className="export_btn"
                              onClick={this.onExportCancelled}
                            >
                              Cancel
                            </button>
                            <button
                            className="export_btn2"
                              onClick={this.onExportConfirmed}
                            >
                              Export
                            </button>
                          </Modal.Footer>
                        </Modal> */}

                        <div
                          className={`filter right-container-block ${
                            this.state.isSelectopen
                              ? "openSelect"
                              : "closeSelect"
                          }`}
                        >
                          <ReactSelect
                            isSearchable={false}
                            options={this.state.myData}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ Option }}
                            onChange={this.handleSelectChange}
                            allowSelectAll={true}
                            //ref={this.myMultiselectRef}
                            // value={this.state.optionSelected}
                            // menuIsOpen
                            onMenuOpen={this.handleSelectOpen}
                            onMenuClose={this.handleSelectClose}
                            menuPlacement="bottom"
                            value={this.state.selectedOption}
                            // menuIsOpen={true}
                          />
                        </div>

                       
                        <div
                          className={`right-container-block cm_search_wrapper client_search m-0 analytics-search-bar ${
                            this.state.toggleSearch
                              ? "seacrh-mob--view"
                              : "seacrh-mob--hide"
                          }`}
                        >
                      
                      
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.search}
                            placeholder="Search…"
                            name="searchKey"
                            onChange={this.searchClient}
                          />
                          <i
                            className="fa fa-search"
                            onClick={() => this.toggleSearchbtn()}
                          />
                          <i
                            className="fa fa-close"
                            onClick={() => this.toggleSearchbtnClose()}
                          />
                          
                        </div>
                      <div className="booking_status_filter">
                      <button className={this?.state?.selectedBookingStatus?.includes(1)?"btn btn-dark":"btn"} 
                       onClick={()=>this.selectBookingStatus(1)} 
                       >Booked</button>
                      <button className={this?.state?.selectedBookingStatus?.includes(4)?"btn btn-dark":"btn"}
                       onClick={()=>this.selectBookingStatus(4)}
                       >Complete</button>
                      <button className={this?.state?.selectedBookingStatus?.includes(6)?"btn btn-dark":"btn"}
                        onClick={()=>this.selectBookingStatus(6)}
                       >No Show</button>
                       <button className={this?.state?.selectedBookingStatus?.includes(3)?"btn btn-dark":"btn"}
                        onClick={()=>this.selectBookingStatus(3)}
                       >Cancelled</button>
                      </div>
                      </div>
                      
                    </div>
                  </div>
                  {/*<div className="col-sm-12 heading-withbtn-search">
                                        <h4>Payments  </h4>
                                        <div className="d-flex align-items-baseline">
                                            <button onClick={this.exportCSV} className="btn btn-dark-blue mr-3">
                                                <span><DownloadIcon /></span>Export as CSV
                                            </button>
                                            <div className="cm_search_wrapper client_search m-0 analytics-search-bar">
                                                <input type="text" className="form-control" value={this.state.search} placeholder="Search…" name="searchKey" onChange={this.searchClient} />
                                                <i className="fa fa-search" />
                                            </div>
                                        </div>
                                    </div> */}
                  <div className="col-sm-12 transction-tble">
                    <div className="payment-table mt-2">
                      <div className="table-responsive cm_table">
                        <table>
                          <thead>
                            <tr>
                              <th>
                                Invoice<span className="bottom-corner"></span>
                              </th>
                              <th
                                className={"sort_col clr-blue"}
                                onClick={() => {
                                  this.setState(
                                    {
                                      sortField: this.state.nameSortStatus
                                        ? "date"
                                        : "name",
                                    },
                                    () => {
                                      this.setState(
                                        {
                                          nameSortStatus:
                                            !this.state.nameSortStatus,
                                          sortType:
                                            this.state.sortType == "2"
                                              ? "1"
                                              : "2",
                                          activePage: "1",
                                        },
                                        () => {
                                          this._getDataVal(this.state);
                                        }
                                      );
                                    }
                                  );
                                }}
                              >
                                Name{" "}
                                <span
                                  className={
                                    this.state.nameSortStatus
                                      ? "top-corner"
                                      : "bottom-corner"
                                  }
                                >
                                  <SortIcon />
                                </span>
                              </th>
                              <th>
                                Service<span className="bottom-corner"></span>
                              </th>
                              <th>
                                Product<span className="bottom-corner"></span>
                              </th>
                              <th
                                className={"sort_col"}
                                onClick={() => {
                                  this.setState(
                                    {
                                      sortField: this.state.stylistSortStatus
                                        ? "stylist"
                                        : "date",
                                    },
                                    () => {
                                      this.setState(
                                        {
                                          stylistSortStatus:
                                            !this.state.stylistSortStatus,
                                          sortType:
                                            this.state.sortType == "2"
                                              ? "1"
                                              : "2",
                                          activePage: "1",
                                        },
                                        () => {
                                          this._getDataVal(this.state);
                                        }
                                      );
                                    }
                                  );
                                }}
                              >
                                Stylist
                                <span
                                  className={
                                    this.state.stylistSortStatus
                                      ? "top-corner"
                                      : "bottom-corner"
                                  }
                                >
                                  <SortIcon />
                                </span>
                              </th>
                              <th
                                className={"sort_col"}
                                onClick={() => {
                                  this.setState(
                                    {
                                      sortField: this.state.dateSortStatus
                                        ? "date"
                                        : "name",
                                    },
                                    () => {
                                      this.setState(
                                        {
                                          dateSortStatus:
                                            !this.state.dateSortStatus,
                                          sortType:
                                            this.state.sortType == "2"
                                              ? "1"
                                              : "2",
                                          activePage: "1",
                                        },
                                        () => {
                                          this._getDataVal(this.state);
                                        }
                                      );
                                    }
                                  );
                                }}
                              >
                                Booking Date
                                <span
                                  className={
                                    this.state.dateSortStatus
                                      ? "top-corner"
                                      : "bottom-corner"
                                  }
                                >
                                  <SortIcon />
                                </span>
                              </th>
                              <th className="text-center">
                                Payment <span className="bottom-corner"></span>
                              </th>
                              <th className={"sort_col"} onClick={() => {
                                this.setState(
                                    {
                                      sortField: this.state.transactionSortStatus
                                          ? "transaction"
                                          : "date",
                                    },
                                    () => {
                                      this.setState(
                                          {
                                            transactionSortStatus:
                                                !this.state.transactionSortStatus,
                                            sortType:
                                                this.state.sortType == "2"
                                                    ? "1"
                                                    : "2",
                                            activePage: "1",
                                          },
                                          () => {
                                            this._getDataVal(this.state);
                                          }
                                      );
                                    }
                                );
                              }}>
                                Transaction Date <span
                                  className={
                                    this.state.transactionSortStatus
                                        ? "top-corner"
                                        : "bottom-corner"
                                  }
                              >
                                  <SortIcon />
                                </span>
                              </th>
                              <th>
                                Service{" "}
                                <span className="bottom-corner">Price</span>
                              </th>
                              <th>
                                Product{" "}
                                <span className="bottom-corner">Cost</span>
                              </th>
                              <th className="text-center">
                                Charges for service<span className="bottom-corner"></span>
                              </th>
                              <th className="text-center">
                                Deposit <span className="bottom-corner"></span>
                              </th>
                              <th className="text-center">
                                Tips <span className="bottom-corner"></span>
                              </th>
                              <th className="text-center text-nowrap">
                                To Salon
                                <span className="bottom-corner"></span>
                              </th>
                              <th className="text-center text-nowrap">
                                To Stylist
                                <span className="bottom-corner"></span>
                              </th>
                              <th className="text-nowrap">Fee Paid By</th>
                              <th className="text-center">
                                Fee<span className="bottom-corner"></span>
                              </th>
                              {/*<th className="text-center">*/}
                              {/* Payout Fee<span className="bottom-corner"></span>*/}
                              {/*</th>*/}
                              {/* <th>Card <span className="top-corner"><SortIcon/></span><br/> Charges</th> */}
                              <th style={{ textAlign: "center" }}>
                                Total<span className="bottom-corner"></span>
                              </th>
                              <th className="text-center">
                                Status
                                <span className="top-corner">
                                  {/*<SortIcon/>*/}
                                </span>
                              </th>
                            </tr>
                          </thead>
                          {!this.state.showLoader ? (
                            this.state.payment_number > 0 ? (
                              <tbody>
                                {this.state.paymentList &&
                                  this.state.paymentList.map((client) => (
                                    <tr key={client._id}>
                                      {/*{console.log("client_data:", client)}*/}
                                      <td
                                        style={{ display: "none" }}
                                        data={JSON.stringify(
                                          this.paymentDetails(client)
                                        )}
                                      ></td>
                                      <td className="clr-blue">
                                        {this.getInvoiceNumber(client)}
                                      </td>
                                      <td>
                                        <p className="clr-blue">
                                          {client.userInfo.name}
                                        </p>
                                        {client?.userId!=="64f5c7789a069cda0188fea3"?<a>{client.userInfo.email}</a>:""}
                                      </td>
                                      <td
                                        className="pointer-event"
                                        onClick={() => this.openBooking(client)}
                                      >
                                        <div className="fix-with-4line">
                                          {this.getServices(
                                            client.servicesBooked
                                          )}
                                        </div>
                                      </td>
                                      <td
                                          className="pointer-event"
                                          onClick={() => this.openBooking(client)}
                                      >
                                        <div className="fix-with-4line">
                                          {this.getProduct(
                                              client
                                          )}
                                        </div>
                                      </td>
                                      <td>{client.hairdresserInfo.name}</td>
                                      <td>
                                        {this.getBookingDate(
                                          client.bookingDate
                                        )}
                                      </td>
                                      <td>
                                        <div className="fix-with-4line">
                                          {this.getTransactionType(
                                            client
                                          )}
                                        </div>
                                      </td>
                                      <td className="text-center">
                                        {this.transactionDate(
                                            client
                                        )}
                                      </td>
                                      <td className="text-center">
                                        {this.getServiceCost(
                                        client.servicesBooked
                                        )>0? (client.currencySymbol &&
                                        client.currencySymbol !== null
                                          ? client.currencySymbol
                                          : "£")+(this.getServiceCost(client.servicesBooked)):"--"}
                                      </td>
                                      <td className="text-center">
                                        {this.getProductCost(
                                            client
                                        )}
                                      </td>
                                      <td className="text-center">
                                        {this.getServiceStripeCharges(
                                            client
                                        )}
                                      </td>
                                      <td className="text-center">
                                        {this.getDiposit(
                                          client.transactionsInfo
                                        ) > 0
                                          ? client.currencySymbol &&
                                            client.currencySymbol !== null
                                            ? client.currencySymbol
                                            : "£"
                                          : ""}
                                        {this.getDiposit(
                                          client.transactionsInfo
                                        )}
                                      </td>

                                      <td className="text-center">
                                        {client?.tipAmount != undefined &&
                                        client?.tipAmount > 0
                                          ? client.currencySymbol &&
                                            client.currencySymbol !== null
                                            ? client.currencySymbol
                                            : "£"
                                          : ""}
                                        {client?.tipAmount
                                          ? client?.tipAmount.toFixed(2)
                                          : "__"}
                                      </td>
                                      <td className="text-center">
                                        {this.salonCommissionCalculation(
                                          client
                                        )}
                                      </td>

                                      <td className="text-center">
                                        {this.stylistCommissionCalculation(
                                          client
                                        )}
                                      </td>
                                      <td className="text-center">
                                        {this.feePaidStatus(client)}
                                      </td>

                                      <td className="text-center">
                                        {this.feeCalculation(client)}
                                      </td>
                                      {/*<td className="text-center" onClick={()=>this.feeModal(client)}>*/}
                                      {/*  {this.payoutFee(client)}*/}
                                      {/*</td>*/}
                                      <td className="text-center">

                                        {this.getTotalCost(client)}
                                      </td>
                                      <td className="text-center">
                                      {client?.bookingStatus===4?<a className="tble-btn tb_paid_color">Complete</a>:client?.bookingStatus===6?<a className="tble-btn tb_noshow_color">No Show</a>:client?.bookingStatus===3?<a className="tble-btn tb_noshow_color">Cancelled</a>:<a className="tble-btn tb_pending_color">Booked</a>}
                                         {/* this.getStatus(client) */}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td className="text-center" colSpan={15}>
                                    No transactions made
                                  </td>
                                </tr>
                              </tbody>  
                            )
                          ) : (
                            <tbody>
                              <tr>
                                <td className="text-center" colSpan={15}>
                                  <Lottie
                                    options={defaultOptions}
                                    height={50}
                                    width={200}
                                  />
                                  <h4 className="text-center">
                                    Loading Data...
                                  </h4>
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </table>
                      {/* <Pagination size="sm">{items}</Pagination> */}
                      </div>
                      {this.state.payment_number > 0 ? (
                        <div className="text-right pagination-wrapper px-2">
                          <h6 className="d-none d-md-block">
                            Row per page : 10
                          </h6>
                          <Pagination
                            activePage={
                              this.state.activePage > 1
                                ? this.state.activePage
                                : this.props.activePage
                            }
                            itemsCountPerPage={this.state.itemsCountPerPage}
                            totalItemsCount={
                              this.state.payment_number
                                ? this.state.payment_number
                                : this.props.payment_number
                            }
                            pageRangeDisplayed={4}
                            onChange={this.handlePageChange}
                          />{" "}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Tabs>
          </div>
        </div>
        <Modal
          blurClass="blure_bg"
          centered
          contentClassName="p-0 payment-failed analytics-popup"
          show={this.state.PaymentDetailPopup}
          onHide={this.closeModal}
          size="xl"
        >
          <div className="cm_modal booking_bg popupscroll ">
            {/* <div className="modal-header closesvg" style={{ borderBottom: 'none' }}>
                                    <p className="text-right w-100"><Close  onClick={this.closeModal}/></p>

                                </div> */}
            <div className="p-5 text-center">
              <div className="payment-table mt-2">
                <div className="table-responsive cm_table">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          Name{" "}
                          <span className="top-corner">
                            <SortIcon />
                          </span>
                        </th>
                        <th>
                          Service
                          <span className="bottom-corner">
                            <SortIcon />
                          </span>
                        </th>
                        <th>
                          Stylist
                          <span className="top-corner">
                            <SortIcon />
                          </span>
                        </th>
                        <th>
                          Date
                          <span className="top-corner">
                            <SortIcon />
                          </span>
                        </th>
                        <th>
                          Invoice
                          <span className="top-corner">
                            <SortIcon />
                          </span>
                        </th>
                        <th>
                          Payment{" "}
                          <span className="top-corner">
                            <SortIcon />
                          </span>
                        </th>
                        <th>
                          Service Cost
                          <span className="top-corner">
                            <SortIcon />
                          </span>
                        </th>
                        <th>
                          Tips
                          <span className="top-corner">
                            <SortIcon />
                          </span>
                        </th>
                        <th>
                          Card Charges
                          <span className="top-corner">
                            <SortIcon />
                          </span>
                        </th>
                        <th>
                          Total
                          <span className="top-corner">
                            <SortIcon />
                          </span>
                        </th>
                        <th>
                          Status
                          <span className="top-corner">
                            <SortIcon />
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <p>Aaron Milbank</p>
                          <a>aaronm12@gmail.com</a>
                        </td>
                        <td>Cut & Finish</td>
                        <td>Katherine Balza</td>
                        <td>03.08.20</td>
                        <td>#101</td>
                        <td>Card</td>
                        <td>£5.00</td>
                        <td>£5.00</td>
                        <td>£5.00</td>
                        <td>£5.00</td>
                        <td>
                          <a className="tble-btn pending-btn">Paid Out</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="checkout-parent">
                  <div className="refund-parent bobking_detail">
                    <h4 className="text-left">Refund</h4>
                    <div className="border-div detl flex-just-align-center">
                      <div>
                        <label className="radio-container">
                          Balance Remaining
                          <input type="radio" checked="checked" name="radi1" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <h5>£42.00</h5>
                    </div>
                    <div className="border-div detl flex-just-align-center">
                      <div>
                        <label className="radio-container">
                          Custom Amount
                          <input type="radio" name="radi" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div>
                        <input
                          type="number"
                          name="selectedAmount"
                          placeholder="0.00"
                          className=""
                        />
                      </div>
                    </div>
                    <div className="d-flex footer-btn">
                      <button className="btn btn-bhite btn-block">
                        Cancel{" "}
                      </button>
                      <button className="btn btn-blue btn-block mr-0">
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>

                {/* <img src={require('../../assets/images/paymentfailed.png')} className="img-fluid mb-3" />
                            <h3 style={{fontSize: '38px', fontWeight: '200'}} className="mb-4">Uh-Oh</h3>
                            <p style={{fontWeight: '200'}}>Something Happened, but Don't worry you haven't been charged. Please try again</p>

                            <div className="stap-action mt-5">
                                <button className="btn btn-info btn-block" onClick={()=>this.openPaymentDetailPopup()}>Continue</button>
                            </div> */}
              </div>
            </div>
          </div>
        </Modal>

                                              <Modal
                                                     blurClass="blure_bg" 
                                                     centered
                                                     show={this.state.allFeePopUp}
                                                     onHide={this.closeFeePopup}
                                                     size="lg"
                                                     className='fee_modal'
                                                   >
                                                     <Modal.Header closeButton>
                                                             </Modal.Header>
                                                     <Modal.Body>
                                                     <Modal.Title className="text-center">
                                                                 <h3>Payout Fee Details </h3>
                                                                 <br></br>
                                                                 <div className="row">
                                                                  <div className="col-md-6 text-right pr-5"><h4> Booking Id </h4></div>
                                                                  <div className="col-md-6 text-left pl-5"><h4> {this.state.selectedTransaction._id} </h4></div>
                                                                 </div>
                                                                 <div className="row">
                                                                  <div className="col-md-6 text-right pr-5"><h4> Total </h4></div>
                                                                  <div className="col-md-6 text-left pl-5"><h4> {this.totalAmount(this.state.selectedTransaction)} </h4></div>
                                                                 </div>
                                                                 <div className="row">
                                                                  <div className="col-md-6 text-right pr-5"><h4> Payout Fee </h4></div>
                                                                  <div className="col-md-6 text-left pl-5"><h4> {this.payoutFee(this.state.selectedTransaction)} </h4></div>
                                                                 </div>
                                                                 <br></br>
                                                               </Modal.Title>

                                                           {this.state.selectedTransaction&&this.state.selectedTransaction.payoutFeeSattled&&this.state.selectedTransaction.payoutFeeSattled.map((data, i) => (
                                                            <div className='text-center'>
                                                                <div className='row'>
                                                                    <div className='col-md-6 text-right pr-5'>
                                                                        <h5>Payout Id</h5>
                                                                    </div>
                                                                    <div className='col-md-6 text-left pl-5'>{data.payoutId}</div>
                                                                </div>

                                                                <div className='row'>
                                                                    <div className='col-md-6 text-right pr-5'>
                                                                        <h5>Old Payout Fee</h5>
                                                                    </div>
                                                                    <div className='col-md-6 text-left pl-5'>{this.state.selectedTransaction.currencySymbol &&
                                                                    this.state.selectedTransaction.currencySymbol !== null
                                                                        ? this.state.selectedTransaction.currencySymbol
                                                                        : "£"}{(data.payoutFee/100).toFixed(2)}</div>
                                                                </div>

                                                                {/*<div className='row'>*/}
                                                                {/*    <div className='col-md-6 text-right pr-5'>*/}
                                                                {/*        <h5>Final Transfer Amount</h5>*/}
                                                                {/*    </div>*/}
                                                                {/*    <div className='col-md-6 text-left pl-5'>40</div>*/}
                                                                {/*</div>*/}
                                                            </div>))}

                                                        <br></br>

                                                        {/*<div className='text-center'>*/}
                                                        {/*    <div className='row'>*/}
                                                        {/*        <div className='col-md-6 text-right pr-5'>*/}
                                                        {/*            <h5>Salon Paid Amount</h5>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className='col-md-6 text-left pl-5'>50</div>*/}
                                                        {/*    </div>*/}

                                                        {/*    <div className='row'>*/}
                                                        {/*        <div className='col-md-6 text-right pr-5'>*/}
                                                        {/*            <h5>Old Payout Fee</h5>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className='col-md-6 text-left pl-5'>5 &nbsp; &nbsp; <span>(Payout Transaction Id Salon)</span></div>*/}
                                                        {/*    </div>*/}

                                                        {/*    <div className='row'>*/}
                                                        {/*        <div className='col-md-6 text-right pr-5'>*/}
                                                        {/*            <h5>Final Transfer Amount</h5>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className='col-md-6 text-left pl-5'>45</div>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                     </Modal.Body>
                                                </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(Analytics);
